async function convertBlobToJPEG(blobUrl, filename) {
  try {
    // Fetch the blob from the provided URL
    const response = await fetch(blobUrl);
    const blobData = await response.blob();

    // Create a FileReader to read the blob as a base64 encoded string
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      // Handle successful reading
      reader.onloadend = () => {
        const base64 = reader.result.split(',')[1]; // Extract base64 string
        const bytes = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
        const blob = new Blob([bytes], { type: 'image/jpeg' });
        const file = new File([blob], filename, { type: 'image/jpeg' });
        resolve(file);
      };

      // Handle error
      reader.onerror = () => {
        reject(new Error('Failed to convert blob to JPEG'));
      };

      // Start reading the fetched blob as a data URL
      reader.readAsDataURL(blobData);
    });
  } catch (error) {
    throw new Error(`Failed to fetch and convert blob: ${error.message}`);
  }
}

export default convertBlobToJPEG;
