import { useEffect, useState } from 'react';

//hooks
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//utils

const EmailPreferencesToggles = ({
  Account,
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //hooks
  const {
    accountEmailPreferencesMyndFullNewsletter: loadMyndFullNewsletter,
    accountEmailPreferencesPromotions: loadPromotions,
  } = useAccountReduxHandlers();
  const { EmailPreferences } = useLanguageRegistration();

  //state
  const [emailPreferencesSaveValid, setEmailPreferencesSaveValid] =
    useState(false);
  const [myndFullNewsletter, setMyndFullNewsletter] = useState(
    loadMyndFullNewsletter
  );
  const [promotions, setPromotions] = useState(loadPromotions);

  useEffect(() => {
    if (
      myndFullNewsletter !== loadMyndFullNewsletter ||
      promotions !== loadPromotions
    ) {
      setEmailPreferencesSaveValid(true);
    } else {
      setEmailPreferencesSaveValid(false);
    }
  }, [myndFullNewsletter, loadMyndFullNewsletter, promotions, loadPromotions]);

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="emailPreferencesLabel"
        >
          {Account.emailPreferences.emailPreferences}
        </label>

        <div className="flex-column mrg-t24 full-width space-between border-reg br--standard pad-24">
          <p className="flex-row fs18 fwsb mrg-b12 nowrap full-width">
            {EmailPreferences.myndfullNewsletter}
          </p>

          <div className="flex-row align-center space-between full-width">
            <p className="fs18 fwn">
              {EmailPreferences.myndfullNewsletterDescription}
            </p>
            <ButtonSwitch
              handleSwitch={setMyndFullNewsletter}
              id={'myndfullNewsletter'}
              loadTrigger={true}
              loadValue={myndFullNewsletter}
              horizontal={true}
              customWrapper={'mrg-l24'}
            />
          </div>
        </div>

        <div className="flex-column mrg-t24 full-width space-between border-reg br--standard pad-24">
          <p className="flex-row fs18 fwsb mrg-b12 nowrap full-width">
            {EmailPreferences.promotions}
          </p>

          <div className="flex-row align-center space-between full-width">
            <p className="fs18 fwn">{EmailPreferences.promotionsDescription}</p>
            <ButtonSwitch
              handleSwitch={setPromotions}
              id={'promotions'}
              loadTrigger={true}
              loadValue={promotions}
              horizontal={true}
              customWrapper={'mrg-l24'}
            />
          </div>
        </div>
        <div className="h24" />
        <SaveButton
          saveValid={emailPreferencesSaveValid}
          saveAriaLanguage={Account.account.appSettings}
          handler={() =>
            saveAccountChange(
              {
                emailPreferences: {
                  myndFullNewsletter,
                  promotions,
                },
              },
              'emailPreferences'
            )
          }
          isLoading={loadingSaveBtnUI === 'appSettings'}
        />

        <div className="h36" />
      </div>
    </>
  );
};

export default EmailPreferencesToggles;
