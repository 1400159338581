import { useEffect, useRef, useState } from 'react';

//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useApiStatus from '../../../../context/useApiStatus';
import useGenericModal from '../../../../context/useGenericModal';

//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import ParticipantListItem from './ParticipantSections/ParticipantListItem/ParticipantListItem';
import ParticipantCreated from './ParticipantSections/ParticipantCreated';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';
import ViewContactedSpeakersModal from '../../../../components/Modal/ModalComponents/FolderModals/ViewContactedSpeakersModal';
import ParticipantInviteContact from './ParticipantSections/ParticipantInviteContact';
import ParticipantEmailInvitation from './ParticipantSections/ParticipantEmailInvitation';
import ItemButton from '../../../../components/ItemButton/ItemButton';

//Utils
import {
  img_messages,
  img_plus,
  img_social,
  return_curved_arrow,
} from '../../../../assets/icons/index';
import ScrollTo from '../../../../utils/UI/ScrollTo';
import getDBImageBlob from '../../../../utils/images/getDBImageBlob';

//note that images and data are handled separately. participant data used in redux; images held in useState

const EventParticipants = ({
  CreateEvent,
  skipToId,
  skipToLabel,

  //edit
  editEventMode,
  setParticipantChangeTracker,
  setParticipantImagesChangeTracker,

  //participants
  pauseCrop,
  participants,
  participantImagesCropped,
  setParticipantImagesCropped,
  handleSetParticipants,

  //dispatches
  removedParticipantsWithDispatchedInvites,
  setRemovedParticipantsWithDispatchedInvites,
  //translations
  translationsRoomLanguages,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { EventParticipant } = useLanguageOrganize();
  const { IconAlts } = useLanguageComponents();
  const { setGenericModal } = useGenericModal();

  //Component state
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [showParticipantProfile, setShowParticipantProfile] = useState(false);
  const [selectedParticipantImage, setSelectedParticipantImage] = useState();
  const [addParticipant, setAddParticipant] = useState(false);

  //edit
  const [trackImgChangesActive, setTrackImgChangesActive] = useState(false);
  const [
    initialParticipantsDataForChangeList,
    setInitialParticipantsDataForChangeList,
  ] = useState(false);
  const [
    initialParticipantsImagesForChangeList,
    setInitialParticipantsImagesForChangeList,
  ] = useState(false);

  const effectRan = useRef(false);
  const initializeParticipantsImagesRan = useRef(false);
  const initializeParticipantsDataRan = useRef(false);

  //initialize
  useEffect(() => {
    if (effectRan.current === false && participants) {
      effectRan.current = true;
      if (participants) {
        handleCreateBlobsForLoadedParticipantImages();
      }
    }
  }, [participants]); //creates image blobs for loaded create participant images so that they can be adjusted in the cropper;

  async function handleCreateBlobsForLoadedParticipantImages() {
    let newParticipantImages = [];

    for (const participant of participants) {
      function handleLoadImage({ imagePreview, croppedImage }) {
        let newImageObj = {
          id: participant.id,
          index: participant.index,
          croppedImage: croppedImage,
          imagePreview: imagePreview,
          crop: {
            x: 0,
            y: 0,
          },
          zoom: 1,
        };

        newParticipantImages.push(newImageObj);
      }

      if (participant.organizedAs === 'create') {
        if (
          participant?.participantData?.personal?.profileImage !==
            'account_img_default.png' &&
          participant?.participantData?.personal?.profileImage
        ) {
          await getDBImageBlob(
            participant?.participantData?.personal?.profileImage,
            'participant',
            handleLoadImage
          );
        } else {
          let newParticipantImage = {
            id: participant.id,
            index: participant.index,
            croppedImage: '',
            imagePreview: '',
            crop: {
              x: 0,
              y: 0,
            },
            zoom: 1,
          };

          newParticipantImages.push(newParticipantImage);
        }
      }
    }
    setParticipantImagesCropped(newParticipantImages);
  }

  //Edit Event Change List
  useEffect(() => {
    if (editEventMode) {
      let timeout1 = null;

      timeout1 = setTimeout(() => {
        setTrackImgChangesActive(true);
      }, 1500);

      return () => {
        if (timeout1 !== null) {
          clearTimeout(timeout1);
        }
      };
    }
  }, [editEventMode]); //prevents initializing components to trigger adding image change to change list for edit event

  useEffect(() => {
    if (editEventMode && participants) {
      if (initializeParticipantsImagesRan.current === false) {
        initializeParticipantsImagesRan.current = true;

        let initialParticipantsImagesData = [];

        if (participants?.length > 0) {
          for (const participant of participants) {
            if (participant?.organizedAs === 'create') {
              let newInitData = {
                id: participant.id,
                crop: {
                  x: 0,
                  y: 0,
                },
                zoom: 1,
              };
              initialParticipantsImagesData.push(newInitData);
            }
          }
        }
        setInitialParticipantsImagesForChangeList(
          initialParticipantsImagesData
        );
      }
    }
  }, [participants]); //Sets initial images data for change list

  useEffect(() => {
    if (editEventMode && participants) {
      if (initializeParticipantsDataRan.current === false) {
        initializeParticipantsDataRan.current = true;
        let initialParticipantsData = JSON.stringify(participants);
        setInitialParticipantsDataForChangeList(initialParticipantsData);
      }
    }
  }, [participants]); //Sets initial data for change list for images

  useEffect(() => {
    if (
      editEventMode &&
      trackImgChangesActive &&
      setParticipantImagesChangeTracker
    ) {
      let newParticipantImagesData = [];

      for (const participantImage of participantImagesCropped) {
        let newInitData = {
          id: participantImage.id,
          crop: {
            x: participantImage.crop.x,
            y: participantImage.crop.y,
          },
          zoom: participantImage.zoom,
        };

        newParticipantImagesData.push(newInitData);
      }

      newParticipantImagesData = JSON.stringify(newParticipantImagesData);
      let stringifiedInitialImages = JSON.stringify(
        initialParticipantsImagesForChangeList
      );

      if (stringifiedInitialImages !== newParticipantImagesData) {
        setParticipantImagesChangeTracker(true);
      } else {
        setParticipantImagesChangeTracker(false);
      }
    }
  }, [participantImagesCropped, participants]); //checks for changes for change list

  useEffect(() => {
    if (editEventMode && trackImgChangesActive && setParticipantChangeTracker) {
      let newParticipantData = JSON.stringify(participants);

      if (newParticipantData !== initialParticipantsDataForChangeList) {
        setParticipantChangeTracker(true);
      } else {
        setParticipantChangeTracker(false);
      }
    }
  }, [participantImagesCropped, participants]); //checks for changes for change list

  //GENERAL Participant actions
  function handleSelectParticipant(participantId) {
    const selectedParticipant = participants.find(
      (p) => participantId === p?.id
    );
    const selectedImg = participantImagesCropped.find(
      (img) => participantId === img?.id
    );

    if (selectedParticipant?.organizedAs === 'create') {
      let newImg = {
        ...selectedImg,
        crop: { x: selectedImg?.crop?.x || 0, y: selectedImg?.crop?.y || 0 },
      };
      setSelectedParticipantImage(newImg);
    }

    setSelectedParticipant(selectedParticipant);

    setShowParticipantProfile(true);
    ScrollTo('participantsLabel', 'smooth', false, 'participantsLabel');
  }

  function handleRemoveParticipant(participantId) {
    //handle removal within Event Participants
    let newParticipants = participants?.filter((p) => p?.id !== participantId);

    newParticipants = newParticipants?.map((p, index) => {
      let newP = { ...p, participantData: { ...p.participantData } };
      newP.index = index;
      return newP;
    });

    let newParticipantImages = participantImagesCropped?.filter(
      (i) => i?.id !== participantId
    );

    newParticipantImages = newParticipantImages?.map((p, index) => {
      let newP = { ...p, crop: { ...p?.crop } };
      newP.index = index;
      return newP;
    });

    handleSetParticipants(newParticipants);
    setParticipantImagesCropped(newParticipantImages);
    setSelectedParticipant();

    //track removed participant for save -- ensure this logic doesnt rely on completion status, only dispatched.
    const removedParticipant = participants?.find(
      (p) => p?.id === participantId
    );
    if (
      removedParticipant?.organizedAs === 'inviteContact' &&
      (removedParticipant?.dispatches?.inviteDispatched ||
        removedParticipant?.dispatches?.translationsDispatched)
    ) {
      let newRemovedParticipantsWithDispatchedInvites = [];
      newRemovedParticipantsWithDispatchedInvites.push(removedParticipant);

      removedParticipantsWithDispatchedInvites?.map((r) =>
        newRemovedParticipantsWithDispatchedInvites.push(r)
      );

      setRemovedParticipantsWithDispatchedInvites(
        newRemovedParticipantsWithDispatchedInvites
      );
    }
  }

  function handleSaveAndReturn(participant) {
    //only update relevant data to avoid stale data issues when data was loaded (dispatches/translations can change)
    const foundParticipant = participants.find((p) => p.id === participant.id);
    let newParticipant = JSON.parse(JSON.stringify(foundParticipant));

    newParticipant.dispatches = participant.dispatches;
    newParticipant.emailInvitation = participant.emailInvitation;
    newParticipant.participantData = participant.participantData;
    newParticipant.completionStatus = participant.completionStatus;

    handleParticipantChange(newParticipant);

    //handle cropped images for create
    if (selectedParticipant.organizedAs === 'create') {
      let newParticipantImages = [];

      if (participantImagesCropped?.length > 0) {
        newParticipantImages = participantImagesCropped?.map((img) => {
          if (img?.id === selectedParticipant?.id) {
            return selectedParticipantImage;
          } else {
            return img;
          }
        });
      } else if (selectedParticipantImage) {
        newParticipantImages = [selectedParticipantImage];
      }

      setParticipantImagesCropped(newParticipantImages);
      setSelectedParticipantImage();
    }

    //clean up
    setSelectedParticipant();
    setShowParticipantProfile(false);
    ScrollTo('fieldset-participants', 'smooth', false, 'fieldset-participants');
  }

  function handleParticipantChange(participant) {
    let newParticipants = participants?.map((p) => {
      if (p?.id === participant?.id) {
        return participant;
      } else {
        return p;
      }
    });

    if (participant?.organizedAs === 'emailInvitation') {
      const email = participant?.emailInvitation?.email;
      const duplicateFound = participants.some(
        (p) => p.emailInvitation?.email === email && p.id !== participant.id
      );
      participant.emailInvitation.duplicateEmail = duplicateFound;
    }

    handleSetParticipants(newParticipants);
    setSelectedParticipant(participant);
  }

  function checkTranslationRoomLanguages() {
    if (
      translationsRoomLanguages?.length === 0 ||
      translationsRoomLanguages.includes('select')
    ) {
      return true;
    } else {
      return false;
    }
  }

  //CREATE
  function handleCreateNewParticipant() {
    if (participants?.length >= 6) {
      return handleWarning({
        message: 'limitReached',
        origin: 'EventParticipants.js/handleNewParticipant',
        id: Date.now(),
      });
    }

    let newParticipants = [];
    let newParticipantImages = [];

    if (participants?.length > 0) {
      newParticipants = [...participants];
    }

    if (participantImagesCropped?.length > 0) {
      newParticipantImages = [...participantImagesCropped];
    }

    let newId = Math.floor(Math.random() * 9000000000) + 1000000000;
    let newIndex = participants?.length;
    const requiresRoomLanguageStatus = checkTranslationRoomLanguages();

    let newParticipant = {
      id: newId,
      index: newIndex,
      organizedAs: 'create',
      completionStatus: 'incomplete',
      translationsCompletionStatus: 'incomplete',
      translationsLanguageConflict: false,
      translationsRoomRequiresLanguage: requiresRoomLanguageStatus,
      participantData: {
        participantAccountId: null,
        eventRole: '',
        personal: {
          bio: '',
          firstName: '',
          lastName: '',
          prefix: '',
          pronouns: '',
          profileImage: 'account_img_default.png',
        },
      },
      translationsSpeakerData: {
        name: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        discussionContext: '',
      },
    };

    let newParticipantImage = {
      id: newId,
      index: newIndex,
      croppedImage: '',
      imagePreview: '',
      crop: {
        x: 0,
        y: 0,
      },
      zoom: 1,
    };

    newParticipants.push(newParticipant);
    newParticipantImages.push(newParticipantImage);

    handleSetParticipants(newParticipants);
    setParticipantImagesCropped(newParticipantImages);
    setAddParticipant(false);
  }

  function handleParticipantImageChange(newImgData) {
    let newImg = {
      ...newImgData,
      crop: { ...newImgData.crop },
      croppedImage: { ...newImgData.croppedImage },
    };
    setSelectedParticipantImage(newImg);
  }

  //INVITE CONTACT
  async function handleInviteContactSelection(selection) {
    if (participants?.length >= 6) {
      return handleWarning({
        message: 'limitReached',
        origin: 'EventParticipants.js/handleNewParticipant',
        id: Date.now(),
      });
    }

    let newParticipants = [];

    if (participants?.length > 0) {
      newParticipants = [...participants];
    }

    //ensure contact is not already selected
    for (const p of participants) {
      if (p?.participantData?.participantAccountId === selection?._id) {
        return handleWarning({
          message: 'alreadySelected',
          id: Date.now(),
          origin: 'EventParticipants.js/ handleInviteContactSelection',
        });
      }
    }

    let newId = Math.floor(Math.random() * 9000000000) + 1000000000;
    let newIndex = participants?.length;
    const requiresRoomLanguageStatus = checkTranslationRoomLanguages();

    let newParticipant = {
      id: newId,
      index: newIndex,
      organizedAs: 'inviteContact',
      completionStatus: 'complete',
      translationsCompletionStatus: 'incomplete',
      translationsLanguageConflict: false,
      translationsRoomRequiresLanguage: requiresRoomLanguageStatus,
      dispatches: {
        invitationStatus: 'notDispatched',
        translationsStatus: 'notDispatched',
        inviteDispatched: false,
        translationsDispatched: false,
      },
      participantData: {
        participantAccountId: selection?._id,
        eventRole: '',
        personal: {
          bio: selection?.personal?.bio || '',
          firstName: selection?.personal?.firstName,
          lastName: selection?.personal?.lastName,
          prefix: selection?.personal?.prefix,
          pronouns: selection?.personal?.pronouns,
          profileImage: selection?.personal?.profileImage,
        },
      },
      translationsSpeakerData: {
        name: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        discussionContext: '',
      },
    };

    newParticipants.push(newParticipant);
    handleSetParticipants(newParticipants);
    setAddParticipant(false);
  }

  //EMAIL INVITATION
  function handleEmailInvitation() {
    if (participants?.length >= 6) {
      return handleWarning({
        message: 'limitReached',
        origin: 'EventParticipants.js/handleNewParticipant',
        id: Date.now(),
      });
    }
    // add inviteContactToken property to newParticipant
    let newParticipants = [];

    if (participants?.length > 0) {
      newParticipants = [...participants];
    }

    let newId = Math.floor(Math.random() * 9000000000) + 1000000000;
    let newIndex = participants?.length;
    const requiresRoomLanguageStatus = checkTranslationRoomLanguages();

    let newParticipant = {
      id: newId,
      index: newIndex,
      organizedAs: 'emailInvitation',
      completionStatus: 'incomplete',
      translationsCompletionStatus: 'incomplete',
      translationsLanguageConflict: false,
      translationsRoomRequiresLanguage: requiresRoomLanguageStatus,
      dispatches: {
        invitationStatus: 'notDispatched',
        translationsStatus: 'notDispatched',
        inviteDispatched: false,
        translationsDispatched: false,
      },
      emailInvitation: {
        email: '',
        emailLanguage: 'select',
        emailValid: false,
        duplicateEmail: false,
        token: '',
        tokenExpires: '',
      },
      participantData: {
        eventRole: '',
        personal: {
          bio: '',
          firstName: '',
          lastName: '',
          prefix: '',
          pronouns: '',
          profileImage: '',
        },
      },
      translationsSpeakerData: {
        name: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        discussionContext: '',
      },
    };

    newParticipants.push(newParticipant);
    handleSetParticipants(newParticipants);
    setAddParticipant(false);
  }

  return (
    <fieldset
      aria-describedby="aria-fieldset-instructions-participants"
      className="fieldset"
      id="fieldset-participants"
    >
      <legend id="aria-fieldset-instructions-participants">
        {CreateEvent.participants.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="participantsLabel"
        aria-describedby="aria-fieldset-instructions-participants"
        tabIndex="0"
      >
        {CreateEvent.participants.participants}
      </label>

      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      {!addParticipant && !selectedParticipant ? (
        <ItemButton
          handler={() => setAddParticipant(true)}
          text={CreateEvent.participants.addParticipant}
          fullWidth={true}
        />
      ) : (
        <>
          {!selectedParticipant && (
            <>
              <label
                className="content-heading-styled mrg-auto-lr text-gray flex-row mrg-b36"
                tabIndex="0"
                id="selectAddParticipantLabel"
              >
                {CreateEvent.participants.selectAddParticipantType}
              </label>

              <div
                className={`button-meta__container space-evenly`}
                id="participant-action-btns"
              >
                <button
                  className="highlight-i-theme"
                  onClick={() => setAddParticipant(!addParticipant)}
                >
                  <img
                    src={return_curved_arrow}
                    alt={IconAlts.iconArrow}
                    className="filter-lightgray"
                  />
                </button>
                <ButtonMeta
                  handleClick={handleCreateNewParticipant}
                  btnText={EventParticipant.create}
                  ariaLabel={EventParticipant.create}
                  icon={img_plus}
                  iconAlt={'+'}
                />

                <ButtonMeta
                  handleClick={() => {
                    setGenericModal(
                      <ViewContactedSpeakersModal
                        handler={handleInviteContactSelection}
                        showAcceptedListOnly={true}
                      />
                    );
                  }}
                  btnText={EventParticipant.inviteContact}
                  ariaLabel={EventParticipant.inviteContact}
                  icon={img_social}
                  iconAlt={IconAlts.iconCommunity}
                />

                <ButtonMeta
                  handleClick={handleEmailInvitation}
                  btnText={EventParticipant.emailInvitation}
                  ariaLabel={EventParticipant.emailInvitation}
                  icon={img_messages}
                  iconAlt={IconAlts.iconMessage}
                />
              </div>
            </>
          )}
        </>
      )}

      {selectedParticipant && showParticipantProfile ? (
        <>
          {selectedParticipant?.organizedAs === 'create' && (
            <ParticipantCreated
              prevParticipant={selectedParticipant}
              handleSaveAndReturn={handleSaveAndReturn}
              handleRemoveParticipant={handleRemoveParticipant}
              //image
              pauseCrop={pauseCrop}
              participantImage={selectedParticipantImage}
              handleParticipantImageChange={handleParticipantImageChange}
            />
          )}

          {selectedParticipant?.organizedAs === 'inviteContact' && (
            <ParticipantInviteContact
              prevParticipant={selectedParticipant}
              handleSaveAndReturn={handleSaveAndReturn}
              handleRemoveParticipant={handleRemoveParticipant}
            />
          )}

          {selectedParticipant?.organizedAs === 'emailInvitation' && (
            <ParticipantEmailInvitation
              prevParticipant={selectedParticipant}
              handleSaveAndReturn={handleSaveAndReturn}
              handleRemoveParticipant={handleRemoveParticipant}
            />
          )}
        </>
      ) : (
        !addParticipant && (
          <ul
            className={`community__container ${
              participants?.length > 0 ? 'mrg-t12' : ''
            }`}
          >
            {participants?.length > 0 && (
              <>
                {participants?.map((participant, index) => {
                  const participantPreviewImage =
                    participantImagesCropped?.find((p) => {
                      if (p?.id === participant?.id) {
                        return p;
                      }
                    });
                  return (
                    <li className="flex-row" key={`communityContact-${index}`}>
                      <ParticipantListItem
                        participant={participant}
                        handler={handleSelectParticipant}
                        previewImage={participantPreviewImage}
                        customWrapper={'mrg-t12'}
                        listItemMode={'eventParticipant'}
                      />
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        )
      )}
    </fieldset>
  );
};

export default EventParticipants;
