import useSettings from '../../context/useSettings';

const useLanguageTranslationRoom = () => {
  const { contentLanguage } = useSettings();

  const TranslationInitialize = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        joinRoom: 'Join Room',
        createRoom: 'Create Room',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        joinRoom: 'Rejoindre la salle',
        createRoom: 'Créer une salle',
      };
    }

    return translation;
  };

  const TranslationJoinRoom = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        roomCode: 'Room Code',
        usePrevious: 'Use Previous:',
        exit: 'Exit',
        joinRoom: 'Join Room',
        submit: 'Submit',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        roomCode: 'Code de la salle',
        usePrevious: 'Utiliser le précédent:',
        exit: 'Sortie',
        joinRoom: 'Rejoindre la salle',
        submit: 'Soumettre',
      };
    }

    return translation;
  };

  const TranslationCreateRoomAuth = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        enterRoomCode: 'Enter Room Code',
        usePrevious: 'Use Previous:',
        exit: 'Exit',
        roomCode: 'Room Code',
        generate: 'Generate',
        back: 'Back',
        password: 'Password',
        submit: 'Submit',
        createRoom: 'Create Room',
        noRoomCode: `Don't have a Room Code?`,
        chromeIsRequired: 'Chrome is required for hosting',
        mobileDevicesNotPermittedToHost:
          'Mobile devices can preview hosting setup but cannot host rooms.',
        outstandingBalance: `This Translation Room is locked. More details are available in the account holder's Room Code Manager.`,
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        enterRoomCode: 'Entrez le code de la salle',
        usePrevious: 'Utiliser le précédent:',
        exit: 'Sortie',
        roomCode: 'Code de la salle',
        generate: 'Générer',
        back: 'Retour',
        password: 'Mot de passe',
        submit: 'Soumettre',
        createRoom: 'Créer une salle',
        noRoomCode: `Vous n'avez pas de code de la salle ?`,
        chromeIsRequired: `Chrome est requis pour l'hébergement`,
        mobileDevicesNotPermittedToHost: `Les appareils mobiles peuvent prévisualiser la configuration de l'hébergement mais ne peuvent pas héberger de salles`,
        outstandingBalance: `Cette salle de traduction est verrouillée. Plus de détails sont disponibles dans le gestionnaire de codes de salle du titulaire du compte.`,
      };
    }

    return translation;
  };

  const CreateRoomSpeakersAutocorrect = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exit: 'Exit',
        autoCorrectKeywords: 'Autocorrect Keywords',
        submit: 'Submit',
        back: 'Back',
        discussionContext: 'Discussion Context',
        aiAutocorrectionDescription:
          'Tell the AI about your discussion for accurate Autocorrections.',
        autocorrectionForSpeakers: 'Autocorrections for Speakers',
        previous: 'Previous',
        checkmark: 'checkmark',
        speaker: 'Speaker',
        createRoom: 'Create Room',
        minimumCharacterRequirements: '(Minimum 25 characters required)',
        mobileDevicesNotPermittedToHost:
          'Mobile devices can preview hosting setup but cannot host rooms.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        discussionContext: `Contexte des discussions`,
        exit: 'Sortie',
        submit: 'Soumettre',
        autoCorrectKeywords: 'Mots clés à correction automatique',
        back: 'Retour',
        aiAutocorrectionDescription: `Informez l'IA de votre discussion pour des corrections automatiques précises.`,
        autocorrectionForSpeakers: 'Corrections automatiques pour les orateurs',
        next: 'Suivant',
        previous: 'Précédent',
        checkmark: 'coche',
        speaker: 'Intervenant',
        createRoom: 'Créer une salle',
        minimumCharacterRequirements: '( Minimum 25 caractères requis )',
        mobileDevicesNotPermittedToHost: `Les appareils mobiles peuvent prévisualiser la configuration de l'hébergement mais ne peuvent pas héberger de salles`,
      };
    }

    return translation;
  };

  const TranslationRoom = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exit: 'Exit',
        enter: 'Enter',
        roomCode: 'Room Code',
        speakerLanguage: 'Speaker Language',
        translationLanguage: 'Translation Language',
        yourLanguage: 'Your Language',
        attendance: 'Attendance',
        hostIsReady: 'Host is Ready',
        waitingForHost: 'Waiting for Host',
        voiceSelection: 'Voice Selection',
        testVoice: 'Test Voice',
        textLanguage: 'Text Language',
        activateSpeechRecognition: 'Activate Speech Recognition',
        expandToFullscreen: 'Expand to Full Screen',
        pressF11ToMaximizeFullScreen: 'Press F11 for Maximum Full Screen',
        expandedMode: 'Expanded Mode',
        report: 'Report',
        ariaVolumeToggle: 'Volume Toggle.',
        ariaExpandTextAreaToFullScreen: 'Expand text area to full screen.',
        ariaSpeechRecognitionActive: 'Speech recognition is active.',
        ariaSpeechRecognitionInactive: 'Speech recognition is not active.',
        selectLanguage: 'Select a translation language.',
        quickGuide: 'Quick Guide',
        beginListening: 'Begin Listening',
        currentSpeaker: 'Current Speaker',
        speaker: 'Speaker',
        speakersLanguage: `Speaker's Language`,
        noActiveSpeaker: 'No Active Speaker',
        downloadTranscript: 'Download Transcript',
        ariaLightModeActive: 'Light Mode is active.',
        ariaDarkModeActive: 'Dark Mode is active.',
        lightMode: 'Light Mode',
        darkMode: 'Dark Mode',
        openSpeakerDetails: 'Open Speaker Details',
        noScreenTimeout: 'No Screen Timeout',

        checkmark: 'Checkmark',
        microphonePermission: 'Microphone Permission',
        grantAccess: 'Grant Access',
        granted: 'Granted',
        denied: 'Denied',
        active: 'active',
        inactive: 'inactive',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        exit: 'Sortie',
        enter: 'Entrer',
        roomCode: 'Code de la salle',
        speakerLanguage: `Langue de l'intervenant`,
        translationLanguage: 'Langue de traduction',
        yourLanguage: 'Votre langue',

        attendance: 'Présence',
        hostIsReady: `L'hôte est prêt`,
        waitingForHost: `En attente d'un hôte`,
        voiceSelection: 'Sélection vocale',
        testVoice: 'Tester la voix',
        textLanguage: 'Langue du texte',
        activateSpeechRecognition: 'Activer la reconnaissance vocale',
        expandToFullscreen: 'Développer en plein écran',
        pressF11ToMaximizeFullScreen:
          'Appuyez sur F11 pour un plein écran maximum',
        expandedMode: 'Mode étendu',
        report: 'Signaler',
        ariaVolumeToggle: 'Basculement du volume.',
        ariaExpandTextAreaToFullScreen:
          'Agrandissez la zone de texte en plein écran.',
        ariaSpeechRecognitionActive: 'La reconnaissance vocale est active.',
        ariaSpeechRecognitionInactive: `La reconnaissance vocale n'est pas active.`,
        selectLanguage: 'Sélectionnez une langue de traduction.',
        quickGuide: 'Guide rapide',
        grantAudioPlayback: `Accorder l'autorisation de lecture audio (obligatoire pour Safari)`,
        beginListening: 'Commencez à écouter',
        currentSpeaker: 'Actuel intervenant',
        speaker: 'Intervenant',
        speakersLanguage: `Langue du l'intervenant`,
        noActiveSpeaker: 'Aucun intervenant actif',
        downloadTranscript: 'Télécharger la transcription',
        ariaLightModeActive: 'Le mode lumière est actif.',
        ariaDarkModeActive: 'Le mode sombre est actif.',
        lightMode: 'Mode lumière',
        darkMode: 'Mode sombre',
        openSpeakerDetails: `Ouvrir les détails de l'Intervenant`,
        noScreenTimeout: `Pas de mise en veille de l'écran`,
        checkmark: 'Coche',
        microphonePermission: 'Autorisation du microphone',
        grantAccess: `Accorder l'accès`,
        granted: 'accordé',
        denied: 'refusé',
        active: 'activé',
        inactive: 'désactivé',
      };
    }

    return translation;
  };

  const TranslationRoomGuide = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        translationRoomGuide: 'Translation Room Guide',
        technicalChecklist: 'Technical Checklist',
        supportedBrowsers: 'Your internet browser has Speech Recognition.',
        microphoneAccess: `MyndFull has permission to use the browser's microphone.`,
        basics: 'Basics',
        roomCode: 'Room Code',
        roomCode2: 'is used by attendees to connect to the Translation Room.',
        attendance: 'Attendance',
        attendance2: `tracks the number of live connections to the Translation Room. The
        host counts as 1.`,
        attendees: 'Attendees',
        attendeeSelectTranslation: `select a Translation Language. If it does not match the host's Speech Language, the attendee will receive an audio and text translation.`,
        translationText1: 'Translation Text',
        translationText2: `sets the language of the text in the host's text box. This text can be used to display to an audience. It is also used to populate the downloadable transcript.`,
        tips: 'Tips',
        tips1: 'Keep the microphone within range at all times.',
        tips2:
          'Pause briefly at the end of sentences to allow the audio to transfer.',
        tips3:
          'Very long sentences have a higher chance of causing translation errors.',
        tips4: 'Ensure minimal background noise.',
        tips5:
          'Avoid words that were not added as Keywords and are difficult to translate (ex. slang and abbreviations).',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        translationRoomGuide: `Guide de la salle de traduction`,
        technicalChecklist: `Liste de contrôle technique`,
        supportedBrowsers: `Votre navigateur Internet dispose de la reconnaissance vocale.`,
        microphoneAccess: `MyndFull a la permission d'utiliser le microphone du navigateur.`,
        basics: 'Les bases',
        roomCode: 'Code de la salle',
        roomCode2: `est utilisé par les participants pour se connecter à la salle de traduction.`,
        attendance: 'Présence',
        attendance2: `suit le nombre de connexions en direct à la salle de traduction. L'hôte compte pour 1.`,
        attendees: 'Les participants',
        attendeeSelectTranslation: `sélectionnez une langue de traduction. Si la langue parlée ne correspond pas à celle de l'hôte, le participant recevra une traduction audio et textuelle.`,
        translationText1: 'Texte de traduction',
        translationText2: `définit la langue du texte dans la zone de texte de l'hôte. Ce texte peut être utilisé pour être affiché à un public. Il est également utilisé pour remplir la transcription téléchargeable.`,
        tips: 'Conseils',

        tips1: 'Gardez le microphone à portée à tout moment.',
        tips2: `Faites une brève pause à la fin des phrases pour permettre le transfert de l'audio.`,
        tips3: `Les phrases très longues ont plus de chances de provoquer des erreurs de traduction.`,
        tips4: 'Garantit un bruit de fond minimal.',
        tips5: `Évitez les mots qui n'ont pas été ajoutés comme mots-clés et qui sont difficiles à traduire (ex. argot et abréviations).`,
      };
    }

    return translation;
  };

  const TranslationRoomCreateRoomSpeakers = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        continue: 'Continue',
        back: 'Back',
        selectSpeakers: 'Select Speakers',
        speaker: 'Speaker',
        speakerName: 'Name',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        continue: 'Continuer',
        back: 'Retour',
        selectSpeakers: 'Sélectionnez les orateurs',
        speaker: 'Orateur',
        speakerName: `Nom`,
      };
    }

    return translation;
  };

  const TranslationRoomCreateRoomLanguages = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        selectRoomLanguages: 'Select Room Languages',
        imageByPCH: 'Image by pch.vector on Freepik',
        roomLanguages: 'Room Languages',
        continue: 'Continue',
        manual: 'Manual',
        allAvailableLanguages: 'All Languages*',
        back: 'Back',
        addLanguages: 'Add Languages',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        selectRoomLanguages: 'Sélectionner les langues de la salle',
        imageByPCH: 'Image par pch.vector sur Freepik',
        roomLanguages: 'Langues des salles',
        continue: 'Continuer',
        manual: 'Manuel',
        back: 'Retour',
        allAvailableLanguages: 'Toutes les langues*',
        addLanguages: 'Ajouter des langues',
      };
    }

    return translation;
  };

  const CreateRoomSpeakersVoiceAndLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        exit: 'Exit',
        continue: 'Continue',
        back: 'Back',
        speakerLanguageAndVoice: 'Speaker Language & Voice',
        voiceSelection: 'Voice Selection',
        testVoice: 'Test Voice',
        speakersLanguage: `Speaker's Language`,
        playing: 'Playing . . .',
        speaker: 'Speaker',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        exit: 'Sortie',
        continue: 'Continuer',
        back: 'Retour',
        speakerLanguageAndVoice: `La langue et la voix de l'orateur.`,
        voiceSelection: 'Sélection vocale',
        testVoice: 'Tester la voix',
        speakersLanguage: `Langue de l'orateur`,
        playing: `L'audio joue . . .`,
        speaker: 'Orateur',
      };
    }

    return translation;
  };

  const CreateRoomRestoreData = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        exit: 'Exit',
        restorePreviousSession: 'Restore Previous Session?',
        ignore: 'Ignore',
        restore: 'Restore',
        previousSessionLastSavedAt: 'Previous session last saved at',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        exit: 'Sortie',
        restorePreviousSession: 'Restaurer la session précédente?',
        ignore: 'Ignorer',
        restore: 'Restaurer',
        previousSessionLastSavedAt:
          'Session précédente enregistrée pour la dernière fois à',
      };
    }

    return translation;
  };

  const TranslationSpeakerModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        currentSpeakerDetails: 'Current Speaker Details',
        currentSpeaker: 'Current Speaker:',
        discussionContext: 'Discussion Context:',
        keywordsList: 'Keywords List',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        currentSpeakerDetails: `Détails de l'intervenant actuel`,
        currentSpeaker: `L'intervenant actuel:`,
        discussionContext: 'Contexte des discussions:',
        keywordsList: 'Liste de mots-clés',
      };
    }

    return translation;
  };

  const TranslationCreateRoomPassword = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        passwordOptions: 'Password Options',
        attendeesRequirePassword: 'Attendees Require Password',
        password: 'Password',
        exit: 'Exit',
        continue: 'Continue',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        passwordOptions: 'Options de mot de passe',
        attendeesRequirePassword: `Les participants ont besoin d'un mot de passe`,
        password: 'Mot de passe',
        exit: 'Sortie',
        continue: 'Continuer',
      };
    }

    return translation;
  };

  return {
    TranslationInitialize: TranslationInitialize(),
    TranslationCreateRoomAuth: TranslationCreateRoomAuth(),
    TranslationRoom: TranslationRoom(),
    TranslationRoomGuide: TranslationRoomGuide(),
    TranslationRoomCreateRoomLanguages: TranslationRoomCreateRoomLanguages(),
    CreateRoomSpeakersAutocorrect: CreateRoomSpeakersAutocorrect(),
    TranslationJoinRoom: TranslationJoinRoom(),
    TranslationRoomCreateRoomSpeakers: TranslationRoomCreateRoomSpeakers(),
    CreateRoomSpeakersVoiceAndLanguage: CreateRoomSpeakersVoiceAndLanguage(),
    CreateRoomRestoreData: CreateRoomRestoreData(),
    TranslationSpeakerModal: TranslationSpeakerModal(),
    TranslationCreateRoomPassword: TranslationCreateRoomPassword(),
  };
};

export default useLanguageTranslationRoom;
