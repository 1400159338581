import { useEffect, useState } from 'react';

//hooks
import useLanguageData from '../../../../language/useLanguageData';
import useSettings from '../../../../context/useSettings';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import NavigatePages from '../../../../components/NavigationArrows/NavigatePages';

//utils
import {
  dropdown_arrow,
  img_clock2,
  img_empty_box,
  img_hourglass,
  img_language,
  search_dark,
  settings,
  star,
} from '../../../../assets/icons';
import { numInFront } from '../../../../utils/UI/numInFront';

const FindSpeakersQueryResults = ({
  handlePageDecrease,
  handlePageIncrease,
  handleSpeakerSelection,
  page,
  searchHasRun,
  viewSpeakerId,
  paginatedData,
  queryIsLoading,
}) => {
  const { FindSpeakersQueryResults } = useLanguageOrganize();
  const { IconAlts, Generic } = useLanguageComponents();
  const { contentLanguage, handleMobileTap, width } = useSettings();

  const { TimeZoneOpts, LanguageSimpleOpts, BigTagsOpts } = useLanguageData();

  //state
  const [currentPageData, setCurrentPageData] = useState([]);

  //UI
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [displayTagMatchDetails, setDisplayTagMatchDetails] = useState(false);
  const [highlightSpeakerTap, setHighlightSpeakerTap] = useState();
  const [highlightSettingsTap, setHighlightSettingsTap] = useState(false);

  function speakerSelection(id) {
    handleMobileTap(
      [() => setHighlightSpeakerTap(id), () => setHighlightSpeakerTap()],
      [() => handleSpeakerSelection(id)]
    );
  }

  useEffect(() => {
    if (paginatedData && page) {
      const pageData = paginatedData[page - 1];
      setCurrentPageData(pageData);
    }
  }, [paginatedData, page]); //set current page data to render

  return (
    <div className="pos-rel">
      <label
        className="fs-search-results--label fs-search-results--label-results"
        id="findSpeakersQueryFiltersLabel"
        aria-describedby="aria-fieldset-instructions-participants"
        tabIndex="0"
      >
        {FindSpeakersQueryResults.searchResults}
      </label>

      <div className="find-speaker-search-results__wrapper">
        <div className="find-speaker-search-results__container">
          <div className="fs-search-results-settings">
            <div
              type="button"
              aria-pressed={settingsOpened}
              className={`fs-search-results-settings--toggle highlight-b2-theme ${
                highlightSettingsTap ? 'highlight-b2-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setHighlightSettingsTap(true),
                    () => setHighlightSettingsTap(false),
                  ],
                  [() => setSettingsOpened(!settingsOpened)]
                )
              }
              title={
                settingsOpened
                  ? FindSpeakersQueryResults.closeSettings
                  : FindSpeakersQueryResults.openSettings
              }
            >
              <div className="flex-row full-width flex-center mrg-tb12">
                <img
                  src={dropdown_arrow}
                  alt={IconAlts.arrow}
                  className={`dropdown-img filter-gray svg mrg-r24 ${
                    settingsOpened ? '' : 'reverse-vertical'
                  }`}
                />
                <img
                  src={settings}
                  alt={IconAlts.settings}
                  className="settings-img filter-gray svg"
                />
              </div>

              {settingsOpened && (
                <div className="fs-search-results-settings__btn-container">
                  <ButtonSwitch
                    handleSwitch={() => {
                      setDisplayTagMatchDetails(!displayTagMatchDetails);
                    }}
                    loadTrigger={true}
                    loadValue={displayTagMatchDetails}
                    id={'displayTagMatchDetailsSwitch'}
                    title={FindSpeakersQueryResults.displayTagMatchDetails}
                    horizontal={true}
                    customWrapper={'mrg-auto-right'}
                  />
                </div>
              )}
            </div>
          </div>

          <>
            {!searchHasRun && (
              <div className="flex-column flex-center mrg-t24 fs21 fwb color-gray mrg-auto-lr">
                <p className="mrg-tb24">
                  {FindSpeakersQueryResults.searchRequired}
                </p>
                <img
                  src={search_dark}
                  style={{ height: '48px' }}
                  alt={IconAlts.iconSearch}
                  className="svg filter-gray"
                />
              </div>
            )}

            {!queryIsLoading &&
            searchHasRun &&
            (currentPageData?.length === 0 || !currentPageData) ? (
              <div className="flex-column flex-center fs21 fwb color-gray mrg-t24">
                <p className="mrg-b24">{FindSpeakersQueryResults.noResults}</p>
                <img
                  src={img_empty_box}
                  style={{ height: '64px' }}
                  alt={IconAlts.iconSearch}
                  className="svg filter-gray"
                />
              </div>
            ) : (
              !queryIsLoading &&
              searchHasRun && (
                <>
                  <div className="full-width mrg-t24">
                    <NavigatePages
                      handlePageDecrease={handlePageDecrease}
                      handlePageIncrease={handlePageIncrease}
                      firstArrow={page === 1}
                      finalArrow={page === paginatedData?.length - 1}
                      customWrapper={width > 480 ? 'mrg-b24' : ''}
                    />
                  </div>
                  <ul className="fs-results__container">
                    {currentPageData?.map((speaker, index) => {
                      return (
                        <li
                          className="fs-results__container--result"
                          key={`fs-result-${index}`}
                        >
                          {speaker.isContact && (
                            <div className="fs-result-contact">
                              <img
                                src={star}
                                className="svg filter-theme"
                                alt={IconAlts.iconStar}
                                title={FindSpeakersQueryResults.contact}
                              />
                            </div>
                          )}

                          {speaker.isRequested && (
                            <div className="fs-result-contact">
                              <img
                                src={img_hourglass}
                                className="svg filter-theme"
                                alt={IconAlts.iconHourglass}
                                title={
                                  FindSpeakersQueryResults.requestedContact
                                }
                              />
                            </div>
                          )}

                          <button
                            className={`fs-result access-ob ${
                              highlightSpeakerTap === speaker._id
                                ? 'highlight-b-theme--tap fs-result--click'
                                : 'highlight-bthin-theme '
                            } ${
                              viewSpeakerId === speaker._id
                                ? 'fs-result--selected'
                                : ''
                            }`}
                            onClick={() => speakerSelection(speaker._id)}
                            tabIndex="0"
                            id={`speaker-result-${speaker._id}`}
                          >
                            <div className="flex-column full-width">
                              <div className="flex-row">
                                <LoadBucketImage
                                  imagePath={speaker?.personal?.profileImage}
                                  imgClassName="fs-account-profile mrg-r12"
                                  backupPath={'account_img_default.png'}
                                  partialPath={'myndfull_account_images'}
                                />
                                <div className="flex-column">
                                  <div
                                    className="flex-row fwsb access-ob"
                                    tabIndex="0"
                                  >
                                    <p className="mrg-r6">
                                      {speaker.personal.prefix}
                                    </p>
                                    <p className="mrg-r6">
                                      {speaker.personal.firstName}
                                    </p>
                                    <p>{speaker.personal.lastName}</p>
                                  </div>

                                  <div className="flex-row align-center mrg-t6">
                                    <img
                                      src={img_clock2}
                                      alt={IconAlts.iconClock}
                                      title={
                                        FindSpeakersQueryResults.speakerLocation
                                      }
                                      style={{ height: '21px' }}
                                      className="svg mrg-r6 filter-theme"
                                    />
                                    <p className="access-ob" tabIndex="0">
                                      {' '}
                                      {
                                        TimeZoneOpts[
                                          speaker.appSettings.timeZone
                                        ]
                                      }
                                    </p>
                                  </div>
                                  <div className="flex-row align-center mrg-t6">
                                    <img
                                      src={img_language}
                                      alt={IconAlts.iconLanguage}
                                      title={
                                        FindSpeakersQueryResults.speakerLanguages
                                      }
                                      style={{ height: '18px' }}
                                      className="svg filter-theme mrg-r6"
                                    />

                                    <p className="access-ob" tabIndex="0">
                                      {speaker.speaker?.speakerLanguages?.map(
                                        (lang, index) => {
                                          let lastItem;
                                          if (
                                            index ===
                                              speaker.speaker?.speakerLanguages
                                                ?.length -
                                                1 &&
                                            speaker.speaker
                                              ?.speakerLanguagesNotListed
                                              ?.length === 0
                                          ) {
                                            lastItem = true;
                                          }
                                          if (lang !== 'notListed') {
                                            return `${
                                              LanguageSimpleOpts[lang]
                                            }${lastItem ? '' : ', '}`;
                                          }
                                        }
                                      )}
                                      {speaker.speaker?.speakerLanguagesNotListed?.map(
                                        (nL, index) => {
                                          let lastItem;
                                          if (
                                            index ===
                                            speaker.speaker
                                              ?.speakerLanguagesNotListed
                                              ?.length -
                                              1
                                          ) {
                                            lastItem = true;
                                          }
                                          return `${nL.language}${
                                            lastItem ? '' : ', '
                                          }`;
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {displayTagMatchDetails ? (
                                <div className="fs-results-tags__wrapper color-gray fwsb fs16">
                                  <div className="divider mrg-tb12 opacity-05 full-width" />
                                  <div className="fs-results-tags__container">
                                    {speaker?.matchingBigTags?.length > 0 && (
                                      <div
                                        className="access-ob"
                                        aria-label={Generic.bigTags}
                                        tabIndex="0"
                                      >
                                        <ul className="fs-results-tag access-ob">
                                          {speaker?.matchingBigTags?.map(
                                            (tag, index) => {
                                              return (
                                                <li
                                                  className="fs-result--tag"
                                                  key={`fs-result-bT-${index}`}
                                                >
                                                  <p>{BigTagsOpts[tag]}</p>
                                                </li>
                                              );
                                            }
                                          )}
                                          {speaker?.matchingTinyTags?.map(
                                            (tag, index) => {
                                              return (
                                                <li
                                                  className="fs-result--tag"
                                                  key={`fs-result-bT-${index}`}
                                                >
                                                  <p>{tag}</p>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="divider mrg-tb12 opacity-05" />
                                  <div
                                    className="mrg-auto-right fwsb color-gray fs16 access-ob"
                                    tabIndex="0"
                                  >
                                    {numInFront(
                                      contentLanguage,
                                      FindSpeakersQueryResults.tagMatches,
                                      speaker?.matchingBigTags?.length +
                                        speaker?.matchingTinyTags?.length
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default FindSpeakersQueryResults;

{
  /* {hasMore ? (
              <ItemButton
                handler={getNextSpeakersPage}
                text={'More'}
                fullWidth={true}
                icon={img_plus}
                iconOn={true}
                unavailable={!hasMore}
                customWrapper={'mrg-t24 fs18'}
                iconStyle={{ maxHeight: '18px' }}
                isLoading={findSpeakersQueryisLoading}
              />
            ) : (
              <p className="flex-center mrg-t36 fs18 full-width italic">
                {FindSpeakersQueryResults.searchComplete}
              </p>
            )} */
}
