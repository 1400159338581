import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import TextArea from '../../../../../components/TextArea/TextArea';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//utils
import {
  cancel,
  checkmark,
  facebook,
  globe2,
  instagram,
  linkedin,
  x_twitter,
} from '../../../../../assets/icons';

const SocialLinks = ({ resetSpeaker, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { Account, SocialLinks } = useLanguageAccount();
  const { IconAlts } = useLanguageComponents();
  const {
    accountPersonalLinkFacebook: loadLinkFacebook,
    accountPersonalLinkInstagram: loadLinkInstagram,
    accountPersonalLinkLinkedIn: loadLinkLinkedIn,
    accountPersonalLinkPersonalWebsite: loadLinkPersonalWebsite,
    accountPersonalLinkX: loadLinkX,
    accountPersonal,
  } = useAccountReduxHandlers();

  //Component state
  const [selectedLink, setSelectedLink] = useState('personalWebsite');
  const [incorrectFormat, setIncorrectFormat] = useState(false);

  //post sign up
  const [saveValid, setSaveValid] = useState(false);
  const [newLinkX, setNewLinkX] = useState(loadLinkX);
  const [newLinkFacebook, setNewLinkFacebook] = useState(loadLinkFacebook);
  const [newLinkInstagram, setNewLinkInstagram] = useState(loadLinkInstagram);
  const [newLinkLinkedIn, setNewLinkLinkedIn] = useState(loadLinkLinkedIn);
  const [newLinkPersonalWebsite, setNewLinkPersonalWebsite] = useState(
    loadLinkPersonalWebsite
  );

  useEffect(() => {
    if (
      (newLinkX?.length > 0 && !newLinkX.startsWith('@')) ||
      (newLinkFacebook?.length > 0 &&
        !newLinkFacebook.startsWith('https://')) ||
      (newLinkInstagram?.length > 0 && !newLinkInstagram.startsWith('@')) ||
      (newLinkLinkedIn?.length > 0 &&
        !newLinkLinkedIn.startsWith('https://')) ||
      (newLinkPersonalWebsite?.length > 0 &&
        !newLinkPersonalWebsite.startsWith('https://'))
    ) {
      setIncorrectFormat(true);
    } else {
      setIncorrectFormat(false);
    }
  }, [
    loadLinkFacebook,
    loadLinkInstagram,
    loadLinkLinkedIn,
    loadLinkPersonalWebsite,
    loadLinkX,
    newLinkFacebook,
    newLinkInstagram,
    newLinkLinkedIn,
    newLinkPersonalWebsite,
    newLinkX,
  ]);

  useEffect(() => {
    if (loadLinkFacebook !== newLinkFacebook) {
      setSaveValid(true);
    } else if (loadLinkInstagram !== newLinkInstagram) {
      setSaveValid(true);
    } else if (loadLinkLinkedIn !== newLinkLinkedIn) {
      setSaveValid(true);
    } else if (loadLinkPersonalWebsite !== newLinkPersonalWebsite) {
      setSaveValid(true);
    } else if (loadLinkX !== newLinkX) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [
    newLinkFacebook,
    newLinkInstagram,
    newLinkLinkedIn,
    newLinkPersonalWebsite,
    newLinkX,
    loadLinkFacebook,
    loadLinkInstagram,
    loadLinkLinkedIn,
    loadLinkPersonalWebsite,
    loadLinkX,
  ]);

  useEffect(() => {
    if (loadLinkX !== newLinkX) {
      setNewLinkX(loadLinkX);
    } else if (loadLinkPersonalWebsite !== newLinkPersonalWebsite) {
      setNewLinkPersonalWebsite(loadLinkPersonalWebsite);
    } else if (loadLinkFacebook !== newLinkFacebook) {
      setNewLinkFacebook(loadLinkFacebook);
    } else if (loadLinkInstagram !== newLinkInstagram) {
      setNewLinkInstagram(loadLinkInstagram);
    } else if (loadLinkLinkedIn !== newLinkLinkedIn) {
      setNewLinkLinkedIn(loadLinkLinkedIn);
    }
  }, [
    loadLinkFacebook,
    loadLinkInstagram,
    loadLinkLinkedIn,
    loadLinkPersonalWebsite,
    loadLinkX,
  ]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="socialLinksLabel"
        tabIndex="0"
      >
        {SocialLinks.socialLinks}

        {incorrectFormat && (
          <>
            <p className="fs14 mrg-auto-left">{SocialLinks.incorrectFormat}</p>
            <img src={cancel} alt={'X'} className="mrg-l6 filter-red" />
          </>
        )}
      </label>
      <div className="h24" />
      {selectedLink === 'personalWebsite' && (
        <TextArea
          limit={200}
          id={'personalWebsite'}
          labelText={Account.account.personalWebsite}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setNewLinkPersonalWebsite}
          loadValue={loadLinkPersonalWebsite}
          loadTrigger={true}
          onBlurHandlerOff={true}
          placeHolder={'e.g. https://myndfull.com'}
          ariaText={`${Account.account.ariaSocialLinks}`}
          hideWordCount={true}
          reload={resetSpeaker}
        />
      )}
      {selectedLink === 'linkedIn' && (
        <TextArea
          limit={200}
          id={'linkedIn'}
          labelText={'LinkedIn'}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setNewLinkLinkedIn}
          loadValue={loadLinkLinkedIn}
          loadTrigger={true}
          onBlurHandlerOff={true}
          placeHolder={'e.g. https://linkedin.com/company/myndfullplatforms'}
          hideWordCount={true}
          reload={resetSpeaker}
        />
      )}
      {selectedLink === 'x' && (
        <TextArea
          limit={200}
          id={'x'}
          labelText={'X'}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setNewLinkX}
          loadValue={loadLinkX}
          loadTrigger={true}
          onBlurHandlerOff={true}
          placeHolder={'e.g. @MyndFullApp'}
          hideWordCount={true}
          reload={resetSpeaker}
        />
      )}
      {selectedLink === 'facebook' && (
        <TextArea
          limit={200}
          id={'facebook'}
          labelText={'Facebook'}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setNewLinkFacebook}
          loadValue={loadLinkFacebook}
          loadTrigger={true}
          onBlurHandlerOff={true}
          placeHolder={'e.g. https://facebook.com/myndfullapp'}
          hideWordCount={true}
          reload={resetSpeaker}
        />
      )}
      {selectedLink === 'instagram' && (
        <TextArea
          limit={200}
          id={'instagram'}
          labelText={'Instagram'}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setNewLinkInstagram}
          loadValue={loadLinkInstagram}
          loadTrigger={true}
          onBlurHandlerOff={true}
          placeHolder={'e.g. @MyndFullApp'}
          hideWordCount={true}
          reload={resetSpeaker}
        />
      )}
      <div className="h24" />
      {(selectedLink === 'facebook' ||
        selectedLink === 'personalWebsite' ||
        selectedLink === 'linkedIn') && (
        <div className="banners__notice align-center full-width fs16 mrg-b12">
          {SocialLinks.mustStartWithHTTPS}
        </div>
      )}

      {(selectedLink === 'x' || selectedLink === 'instagram') && (
        <div className="banners__notice align-center full-width fs16 mrg-b12">
          {SocialLinks.handleMustStartWithAt}
        </div>
      )}

      <ul className="social-media-links">
        <li>
          <button
            onClick={() => {
              setSelectedLink('personalWebsite');
            }}
            className={`social-media-btn access-ob access-o6`}
            aria-pressed={selectedLink === 'personalWebsite'}
          >
            <img
              src={globe2}
              alt={Account.account.personalWebsite}
              className={`social-media-logo ${
                newLinkPersonalWebsite ? 'filter-theme' : 'filter-lightgray'
              }`}
              title={Account.account.personalWebsite}
            />
            {selectedLink === 'personalWebsite' ? (
              <div className="highlight" />
            ) : newLinkPersonalWebsite?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setSelectedLink('linkedIn');
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'linkedIn'}
          >
            <img
              src={linkedin}
              alt="linkedin"
              className={`social-media-logo ${
                newLinkLinkedIn ? 'filter-theme' : 'filter-lightgray'
              }`}
              title="LinkedIn"
            />
            {selectedLink === 'linkedIn' ? (
              <div className="highlight" />
            ) : newLinkLinkedIn?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setSelectedLink('x');
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'x'}
          >
            <img
              src={x_twitter}
              alt="x"
              className={`social-media-logo ${
                newLinkX ? 'filter-theme' : 'filter-lightgray'
              }`}
              title="X"
            />

            {selectedLink === 'x' ? (
              <div className="highlight" />
            ) : newLinkX?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setSelectedLink('instagram');
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'instagram'}
          >
            <img
              src={instagram}
              alt="instagram"
              className={`social-media-logo ${
                newLinkInstagram ? 'filter-theme' : 'filter-lightgray'
              }`}
              title="Instagram"
            />
            {selectedLink === 'instagram' ? (
              <div className="highlight" />
            ) : newLinkInstagram?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setSelectedLink('facebook');
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'facebook'}
          >
            <img
              src={facebook}
              alt="facebook"
              className={`social-media-logo ${
                newLinkFacebook ? 'filter-theme' : 'filter-lightgray'
              }`}
              title="Facebook"
            />
            {selectedLink === 'facebook' ? (
              <div className="highlight" />
            ) : newLinkFacebook?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
      </ul>

      <div className="h36" />
      <SaveButton
        saveValid={saveValid && !incorrectFormat}
        saveAriaLanguage={SocialLinks.socialLinks}
        handler={() =>
          saveAccountChange(
            {
              personal: {
                ...accountPersonal,
                links: {
                  facebook: newLinkFacebook,
                  instagram: newLinkInstagram,
                  linkedIn: newLinkLinkedIn,
                  personalWebsite: newLinkPersonalWebsite,
                  x: newLinkX,
                },
              },
            },
            'socialLinks'
          )
        }
        isLoading={loadingSaveBtnUI === 'socialLinks'}
      />

      <div className="h36" />
    </div>
  );
};

export default SocialLinks;
