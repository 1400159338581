import { useEffect, useState } from 'react';

//hooks
import useLanguageData from '../../../../../../language/useLanguageData';

//components

import { MapContainerStatic } from '../../../../../../components/MapContainer/MapContainerStatic';

//utils
import { dropdown_arrow } from '../../../../../../assets/icons';
import { convertDistanceToKmOrMi } from '../../../../../../utils/spatial/DistanceCalc';

const InfoCardContainerAttendee = ({ attendeeData }) => {
  //hooks
  const { LanguageOpts, CountriesOpts } = useLanguageData();
  //state
  const [distanceMeasurement, setDistanceMeasurement] = useState();
  const [showMap, setShowMap] = useState(false);

  //initialize
  useEffect(() => {
    if (attendeeData) {
      const convertedDistance = convertDistanceToKmOrMi(
        attendeeData.distanceMeasurement,
        attendeeData.distanceFilter
      );
      setDistanceMeasurement(convertedDistance);
    }
  }, [attendeeData]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Attendee</p>
      <div className="mrg-t6">
        Distance Filter: &nbsp;
        {distanceMeasurement} {attendeeData?.distanceMeasurement}
      </div>
      <ul className="mrg-t6">
        Event Search Online Countries:{' '}
        {attendeeData?.eventSearchOnlineCountries?.length > 0 ? (
          <>
            {attendeeData?.eventSearchOnlineCountries?.map((country, index) => {
              return (
                <li className="mrg-l24" key={`${country}-${index}`}>
                  {CountriesOpts[country]}
                </li>
              );
            })}
          </>
        ) : (
          'N/A'
        )}
      </ul>
      <div className="flex-row mrg-t6">
        Search Languages:
        {attendeeData?.searchLanguages?.length > 0 ? (
          <>
            {attendeeData?.searchLanguages?.map((language, index) => {
              return (
                <p className="mrg-l6" key={`${language}-${index}`}>
                  {LanguageOpts[language]}
                  {index !== attendeeData?.searchLanguages?.length - 1 && ','}
                </p>
              );
            })}
          </>
        ) : (
          'N/A'
        )}
      </div>

      <div className="mrg-t6">
        Coords: {attendeeData?.geoPosition?.coordinates[1].toFixed(6)}, &nbsp;
        {attendeeData?.geoPosition?.coordinates[0].toFixed(6)}
      </div>

      <div className="flex-row">
        <button
          className={
            'flex-center pad-tb6 pad-lr12 mrg-t24 br--standard highlight-btreg-theme highlight-i-gt'
          }
          onClick={() => setShowMap(!showMap)}
        >
          <p className="fs16 fwsb mrg-r12">Show Map</p>
          <img
            src={dropdown_arrow}
            alt="arrow"
            className={
              showMap ? 'dropdown-arrow' : 'dropdown-arrow reverse-vertical'
            }
            style={{ height: '10px' }}
          />
        </button>
      </div>
      {showMap && (
        <>
          <div className="h24" />
          <MapContainerStatic
            loadValue={attendeeData?.geoPosition?.coordinates}
          />
        </>
      )}
    </div>
  );
};

export default InfoCardContainerAttendee;
