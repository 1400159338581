//Hooks

//Components

//Utility
import { checkmark_green } from '../../../../assets/icons';
import { textUI } from '../../../../utils/UI/textUI';

const AccessibilityContent = ({ genericData, EventPage }) => {
  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="accessibilityLabel"
      >
        {EventPage.accessibility}
      </label>
      <div className="flex-column">
        {genericData.wheelchairAccessibility && (
          <div className="info-row access-ob access-o6" tabIndex="0">
            <img
              src={checkmark_green}
              alt=""
              className="mrg-r12"
              style={{ height: '21px' }}
            />
            <p>{EventPage.wheelchairAccessible}</p>
          </div>
        )}
        {genericData.signLanguageInterpreter && (
          <div className="info-row access-ob access-o6" tabIndex="0">
            <img
              src={checkmark_green}
              alt=""
              className="mrg-r12"
              style={{ height: '21px' }}
            />
            <p>{EventPage.signLanguageInterpreter}</p>
          </div>
        )}
        {genericData.accessibilityNotes?.length > 0 && (
          <div className="info-row mrg-t24 access-ob access-o6" tabIndex="0">
            {EventPage.notes}: &nbsp;{textUI(genericData.accessibilityNotes)}
          </div>
        )}
      </div>

      <div className="h48" />
    </>
  );
};

export default AccessibilityContent;

// {genericData.accessibilityNotes?.length > 0 && (
//   <div className="mrg-t24 border-thin br--standard pad-12">
//     <p className="mrg-b12">{EventPage.notes}:</p>
//     <p className="mrg-b12">{genericData.accessibilityNotes}</p>
//   </div>
// )}
