import { useEffect, useState } from 'react';

//Hooks
import useReCaptcha from '../../hooks/useReCaptcha';
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
import { useLazyCommunityAccountSearchQuery } from '../../features/main/account/accountCommunityApiSlice';
import useGenericModal from '../../context/useGenericModal';

//Components
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';
import ProfilePersonalModal from '../Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//Utility
import { search_dark } from '../../assets/icons';

//need to add languge + aria
//add recaptcha test

const SearchCommunityInput = ({
  accountId,
  handleSelection,
  accountFollowing,
  accountFollowingGroups,
}) => {
  //Hooks
  const { SearchCommunityInput } = useLanguageComponents();
  const { dismissKeypad } = useSettings();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { setGenericModal } = useGenericModal();

  //api
  const [searchCommunityAccount] = useLazyCommunityAccountSearchQuery();

  //Component state
  const [searchInput, setSearchInput] = useState();
  const [queryResults, setQueryResults] = useState([]);
  const [noMatches, setNoMatches] = useState(false);
  const [initialAccountFollowing, setInitialAccountFollowing] = useState();

  //UI state
  const [selectionIndex, setSelectionIndex] = useState(0);
  const [dropDownCounter, setDropDownCounter] = useState(0);
  const [preventReset, setPreventReset] = useState(false);

  //Component variables
  const inputElement = document.getElementById('search-community-input');
  let dropdownList;
  let selectAccountObj;

  //Initialize functions
  useEffect(() => {
    let initialIds = [];
    accountFollowing.map((account) => initialIds.push(account._id));

    accountFollowingGroups.map((accountGroup) =>
      accountGroup.following.map((account) => initialIds.push(account._id))
    );

    setInitialAccountFollowing(initialIds);
  }, [accountFollowing, accountFollowingGroups]);

  //API functions
  async function handleInput(e) {
    if (e.key === 'Enter') dismissKeypad(e);

    const recaptchaVerified = await handleReCaptchaVerify(
      'ACCOUNT_SEARCH_ORGANIZERS',
      false
    );

    if (recaptchaVerified !== true) return false;

    if (e.target.value !== searchInput) {
      setSearchInput(e.target.value);
      if (e.target.value?.length > 0) {
        const res = await searchCommunityAccount({
          accountId,
          searchName: e.target.value,
          unsavedIdsArr: initialAccountFollowing,
        });

        if (res?.data?.status === 'success') {
          if (res?.data?.data?.length > 0) {
            setQueryResults(res?.data?.data);
          } else {
            setQueryResults([]);
            setNoMatches(true);
          }
        }
      }
    }

    if (!e.target.value) {
      setQueryResults([]);
      setNoMatches(false);
    }
  }

  //Component functions
  function handleItemInput(e) {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (selectAccountObj) {
        selectionClick(selectAccountObj, e);
      }
      handleReset(e);
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (selectionIndex < dropDownCounter - 1) {
        setSelectionIndex(selectionIndex + 1);
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selectionIndex > 0) {
        setSelectionIndex(selectionIndex - 1);
      }
    }
  } //for dropdown menu

  function selectionClick(accountObj, e) {
    handleReset(e);
    handleSelection(accountObj);
  }

  function handleBlur(e) {
    if (preventReset) {
      return null;
    }
    handleReset(e);
  }

  function handleReset(e) {
    setSearchInput();
    setQueryResults([]);
    setSelectionIndex(0);
    setDropDownCounter(0);
    if (noMatches) {
      setNoMatches(false);
    }
    if (e) {
      dismissKeypad(e);
    }
    inputElement.value = '';
  }

  //JSX conditional
  if (queryResults?.length > 0) {
    dropdownList = (
      <ul className="search-db-dropdown">
        {queryResults?.map((accountObj, index) => {
          if (index === selectionIndex) {
            selectAccountObj = accountObj;
          }

          if (dropDownCounter <= index) {
            setDropDownCounter(dropDownCounter + 1);
          }

          return (
            <li
              key={`communityQuery-${index}`}
              className="search-db-dropdown-item"
              onMouseEnter={() => {
                setPreventReset(true);
                setSelectionIndex(index);
              }}
              onMouseLeave={() => setPreventReset(false)}
            >
              <div
                className={`search-result ${
                  selectionIndex === index ? 'highlight-bg-theme' : ''
                }`}
                id={`communityQuery-${index}`}
              >
                <button
                  className={`search-result-add fs16 fwsb ${
                    selectionIndex === index ? 'color-white' : ''
                  } mrg-r12 access-ob`}
                  tabIndex="0"
                  onClick={(e) => selectionClick(accountObj, e)}
                >
                  <LoadBucketImage
                    imagePath={accountObj.personal?.profileImage}
                    imgClassName="tag-profile-img tag-profile-community mrg-r6"
                    backupPath={'account_img_default.png'}
                    partialPath={'myndfull_account_images'}
                  />
                  {accountObj.personal.firstName} {accountObj.personal.lastName}
                </button>

                <button
                  className="search-result-add-btn access-ob access-o-6"
                  tabIndex="0"
                  onClick={() => {
                    setGenericModal(
                      <ProfilePersonalModal
                        closeModalFocusId={`communityQuery-${index}`}
                        profileData={accountObj}
                        interactionsOff={true}
                      />
                    );
                  }}
                >
                  <p>{SearchCommunityInput.viewProfile}</p>
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else if (noMatches) {
    dropdownList = (
      <ul className="search-db-dropdown" onClick={(e) => handleReset(e)}>
        <li className="search-db-dropdown-item">
          <p className="search-result flex-row flex-center fs16 fwsb access-ot">
            {SearchCommunityInput.noMatches}
          </p>
        </li>
      </ul>
    );
  }

  //jsx list "no matches if query ran"
  return (
    <>
      <div
        className={`search-db-input no-select mrg-b24 ${
          queryResults?.length > 0 || noMatches ? 'search-db-input--active' : ''
        }`}
      >
        <img
          src={search_dark}
          alt="search-icon"
          className="search-icon filter-lightgray"
        />
        <input
          autoComplete="off"
          id="search-community-input"
          className="fs18 fwn"
          maxLength="120"
          type="text"
          onChange={(e) => handleInput(e)}
          aria-label={SearchCommunityInput.ariaInstructions}
          enterKeyHint="search"
          onKeyDown={(e) => handleItemInput(e)}
          onBlur={(e) => handleBlur(e)}
        />
        {dropdownList}
      </div>
    </>
  );
};

export default SearchCommunityInput;
