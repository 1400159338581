import { useState, useEffect } from 'react';

//hooks
import useLanguageComponents from '../../../language/useLanguageComponents';
import useApiStatus from '../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../../features/main/account/useAccountHandlers';
import { useSettingsHandlers } from '../../../features/main/settings/useSettingsHandlers';
import {
  useBlockAccountMutation,
  useRemoveContactMutation,
  useRequestContactMutation,
  useUpdateAccountFollowingMutation,
} from '../../../features/main/account/accountCommunityApiSlice';

//components
import ButtonTextSimple from '../../ButtonTextSimple/ButtonTextSimple';

//utils
import {
  checkmark_green,
  eventcard_follow,
  flag,
  img_add_account,
  img_block,
  star,
} from '../../../assets/icons';

//keep acct status logic within component for rerendering
//customProfileInteractionHandler, used for getAccountCommunityList function so it will refetch data; ideally would use invalidateTags on getAccountCommunity + providesTags on getAccountCommunityLists but that hasnt worked reliably.

const ProfileInteractions = ({
  profile,
  isViewerAccountHolder,
  previewMode,
  customProfileInteractionHandler,
}) => {
  const { handleWarning } = useApiStatus();
  const { IconAlts, ProfileInteractions } = useLanguageComponents();
  const {
    accountId,
    accountAttendeeModeActive,
    accountCommunityContactsRequested,
    accountCommunityContacts,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
    accountCommunityBlockedAccounts,
    accountOrganizerOrganizerModeActive,
  } = useAccountReduxHandlers();
  const {
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  //API
  const [
    updateAccountFollowing,
    { isLoading: updateAccountFollowingIsLoading },
  ] = useUpdateAccountFollowingMutation();

  const [blockAccount, { isLoading: blockAccountIsLoading }] =
    useBlockAccountMutation();

  const [requestContact, { isLoading: requestContactIsLoading }] =
    useRequestContactMutation();

  const [removeContact, { isLoading: removeContactIsLoading }] =
    useRemoveContactMutation();

  //state
  const [isContact, setIsContact] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  //ui
  const [showAdditionalBtns, setShowAdditionalBtns] = useState(false);

  //initialize
  useEffect(() => {
    setShowAdditionalBtns(false);
  }, [profile]); //used in find speakers when cycling between profile views

  useEffect(() => {
    if (profile && accountId) {
      let followingIds = [];

      accountCommunityFollowing?.map((obj) => {
        return followingIds.push(obj._id);
      });

      accountCommunityFollowingGroups?.map((groupObj) => {
        groupObj?.following?.map((acct) => followingIds.push(acct._id));
      });
      setIsFollowing(followingIds.includes(profile._id));
    }
  }, [
    profile,
    accountId,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  ]); // set isFollowing

  useEffect(() => {
    if (profile && accountId) {
      if (accountCommunityContacts?.includes(profile?._id)) {
        setIsContact(true);
      } else {
        setIsContact(false);
      }

      if (accountCommunityContactsRequested?.includes(profile?._id)) {
        setIsRequested(true);
      } else {
        setIsRequested(false);
      }

      if (accountCommunityBlockedAccounts?.includes(profile?._id)) {
        setIsBlocked(true);
      } else {
        setIsBlocked(false);
      }
    }
  }, [
    accountCommunityContactsRequested,
    accountCommunityContacts,
    accountCommunityBlockedAccounts,
    profile,
  ]); //determine interaction status

  ///
  function handleWarningNoInteractions() {
    return handleWarning({
      message: 'cannotInteractWithYourOwnAccount',
      origin: 'ProfileInteractions.js/handleWarningNoInteractions',
      id: Date.now(),
    });
  }
  function handleWarningAccountIsBlocked() {
    return handleWarning({
      message: 'cannotInteractWithBlockedAccount',
      origin: 'ProfileInteractions.js/handleWarningAccountIsBlocked',
      id: Date.now(),
    });
  }
  async function handleFollowAccount() {
    if (isViewerAccountHolder) return handleWarningNoInteractions();
    if (isBlocked) return handleWarningAccountIsBlocked();

    if (!updateAccountFollowingIsLoading) {
      setIsFollowing(!isFollowing);

      const res = await updateAccountFollowing({
        accountId,
        followingId: profile?._id,
      });

      if (customProfileInteractionHandler) {
        customProfileInteractionHandler();
      }

      let updatedAccountsFollowingIds = [];
      let updatedAccountsFollowingGroupIds = [];

      if (res?.data?.community) {
        res.data.community?.following?.map((following) =>
          updatedAccountsFollowingIds.push(following?._id)
        );

        handleSettingsSetSelectedAccountsFollowingIds(
          updatedAccountsFollowingIds
        );

        res.data.community?.followingGroups.map((group) =>
          updatedAccountsFollowingGroupIds.push(group?.id)
        );

        handleSettingsSetSelectedAccountsFollowingGroupIds(
          updatedAccountsFollowingGroupIds
        );
      }
    }
  }

  async function handleRemoveContact() {
    if (isViewerAccountHolder) return handleWarningNoInteractions();
    if (isBlocked) return handleWarningAccountIsBlocked();
    if (!removeContactIsLoading) {
      setIsContact(!isContact);
      await removeContact({
        accountId,
        removeContactId: profile?._id,
      });
      if (customProfileInteractionHandler) {
        customProfileInteractionHandler();
      }
    }
  }

  async function handleRequestContact() {
    if (isViewerAccountHolder) return handleWarningNoInteractions();
    if (isBlocked) return handleWarningAccountIsBlocked();
    if (!requestContactIsLoading) {
      setIsRequested(!isRequested);
      await requestContact({
        accountId,
        requestContactId: profile?._id,
      });
      if (customProfileInteractionHandler) {
        customProfileInteractionHandler();
      }
    }
  }

  async function handleBlockAccount() {
    if (isViewerAccountHolder) return handleWarningNoInteractions();
    if (!blockAccountIsLoading) {
      setIsBlocked(!isBlocked);
      await blockAccount({
        accountId,
        blockId: profile?._id,
      });
      if (customProfileInteractionHandler) {
        customProfileInteractionHandler();
      }
    }
  }

  async function handleReportContact() {
    if (isViewerAccountHolder) return handleWarningNoInteractions();
    // if(customProfileInteractionHandler){
    //   customProfileInteractionHandler()
    // }
  }

  return (
    <div className={`account-profile-interaction-btns__wrapper`}>
      <div className="account-profile-interaction-btns__container">
        {/*Speaker must allow followers + viewer must have attendee activated */}
        {profile?.speaker?.communitySettings?.attendeeFollowRequests &&
          (accountAttendeeModeActive ||
            accountOrganizerOrganizerModeActive) && (
            <ButtonTextSimple
              customWrapper={'mrg-r12 text-darkgray'}
              icon={isFollowing ? checkmark_green : eventcard_follow}
              iconAlt={
                isFollowing ? IconAlts.iconCheckmark : IconAlts.iconSpeaker
              }
              iconClass={`mrg-r12  ${isFollowing ? '' : 'filter-gray'}`}
              iconOn={true}
              text={
                isFollowing
                  ? ProfileInteractions.following
                  : ProfileInteractions.follow
              }
              handler={handleFollowAccount}
              isLoading={updateAccountFollowingIsLoading}
              title={
                isFollowing
                  ? ProfileInteractions.clickToUnfollow
                  : ProfileInteractions.clickToFollow
              }
            />
          )}

        {
          <>
            {isContact ? (
              <ButtonTextSimple
                customWrapper={'mrg-r12 text-darkgray'}
                icon={star}
                iconAlt={IconAlts.iconStar}
                iconClass={'mrg-r12 filter-theme'}
                iconOn={true}
                text={ProfileInteractions.contact}
                handler={handleRemoveContact}
                isLoading={removeContactIsLoading}
                title={ProfileInteractions.clickToRemoveContact}
              />
            ) : isRequested ? (
              <ButtonTextSimple
                customWrapper={'mrg-r12 text-darkgray'}
                icon={checkmark_green}
                iconAlt={IconAlts.checkmark_green}
                iconClass={'mrg-r12'}
                iconOn={true}
                iconStyle={{ maxHeight: '21px' }}
                text={ProfileInteractions.requested}
                handler={handleRequestContact}
                isLoading={requestContactIsLoading}
                title={ProfileInteractions.clickToRevokeRequest}
              />
            ) : (
              <ButtonTextSimple
                customWrapper={'mrg-r12 text-darkgray'}
                icon={img_add_account}
                iconAlt={IconAlts.iconAccount}
                iconClass={'mrg-r12 filter-gray'}
                iconOn={true}
                text={ProfileInteractions.requestContact}
                handler={handleRequestContact}
                isLoading={requestContactIsLoading}
                title={ProfileInteractions.clickToRequestContact}
              />
            )}
          </>
        }
      </div>

      <div className="account-profile-interaction-btns__container">
        {!previewMode && showAdditionalBtns && (
          <ButtonTextSimple
            customWrapper={'mrg-r12 text-darkgray'}
            icon={isBlocked ? checkmark_green : img_block}
            iconAlt={IconAlts.iconBlock}
            iconClass={`mrg-r12 ${isBlocked ? '' : 'filter-gray'}`}
            iconOn={true}
            text={
              isBlocked
                ? ProfileInteractions.blocked
                : ProfileInteractions.block
            }
            handler={handleBlockAccount}
            isLoading={blockAccountIsLoading}
            title={
              isBlocked
                ? ProfileInteractions.clickToUnblock
                : ProfileInteractions.clickToBlock
            }
          />
        )}

        {!previewMode && showAdditionalBtns && (
          <ButtonTextSimple
            customWrapper={'mrg-r12 text-darkgray'}
            icon={flag}
            iconAlt={IconAlts.iconFlag}
            iconClass={`mrg-r12 filter-gray`}
            iconOn={true}
            text={ProfileInteractions.report}
            handler={handleReportContact}
            title={ProfileInteractions.clickToReport}
          />
        )}
      </div>

      {!previewMode && !showAdditionalBtns && (
        <ButtonTextSimple
          customWrapper={
            'account-profile-interaction-additionalOpts mrg-r12 fwb text-darkgray'
          }
          text={'. . .'}
          handler={() => setShowAdditionalBtns(true)}
          title={ProfileInteractions.showAdditionalOptions}
        />
      )}
    </div>
  );
};

export default ProfileInteractions;
