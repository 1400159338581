import { useEffect, useRef, useState } from 'react';

//hooks
import useLanguageRegistration from '../../language/features/useLanguageRegistration';
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//components
import ButtonMeta from '../ButtonMeta/ButtonMeta';

//utils
import handleRoomCodeSubscriptionValidityCheck from '../../features/main/translations/functions/handleRoomCodeSubscriptionValidityCheck';
import { cancel, checkmark_green, img_arrow_stubby } from '../../assets/icons';
import handleInputClick from '../TextArea/handleInputClick';

const AddTranslationRoomInput = ({
  handleEnter,
  setRoomCode,
  roomCode,
  roomCodeLabelText,
  roomCodeBtnText,
  setValid,
  reset,
  customWrapper,
  asterisk,
  roomCodeBtnIcon,
  roomCodeButtonOff,
}) => {
  //hooks
  const { Generic } = useLanguageComponents();
  const { TranslationRoomCodeRegistration } = useLanguageRegistration();
  const { dismissKeypad } = useSettings();

  //state
  const [roomCodeValidLength, setRoomCodeValidLength] = useState(false);
  const [roomCodeValidChar, setRoomCodeValidChar] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);

  //variables
  const roomCodeInput = useRef();
  const wrapperEl = useRef();

  useEffect(() => {
    const validityObj = handleRoomCodeSubscriptionValidityCheck(roomCode);
    setRoomCodeValidLength(validityObj.roomCodeValidLength);
    setRoomCodeValidChar(validityObj.roomCodeValidChar);

    if (validityObj.roomCodeValidLength && validityObj.roomCodeValidChar) {
      setValid(true);
    } else {
      setValid(false);
    }
    /*eslist-disble-next-line*/
  }, [roomCode]);

  useEffect(() => {
    handleReset();
  }, [reset]);

  function handleReset() {
    setRoomCodeValidLength(false);
    setRoomCodeValidChar(false);
    setFocusHighlight(false);
    setValid(false);
    roomCodeInput.current.value = '';
  }

  function handleKeyDown(e) {
    if (roomCodeInput.current?.value?.length === 0) {
      return dismissKeypad(e);
    }

    if (e.key === 'Enter') {
      dismissKeypad(e);
      setFocusHighlight(false);
      handleEnter(roomCodeInput.current.value);
    }
  } //UI + add tr code

  return (
    <>
      <div
        className={`textarea__wrapper ${
          focusHighlight ? 'textarea__wrapper--focus' : ''
        } ${customWrapper ? customWrapper : ''} mrg-b12`}
        onClick={() => {
          handleInputClick(roomCodeInput.current);
        }}
        ref={wrapperEl}
      >
        <span
          className="textarea-label no-select"
          onClick={() => handleInputClick(roomCodeInput.current)}
        >
          <p>
            {roomCodeLabelText}
            {asterisk && <span className="highlight">*</span>}
          </p>
        </span>

        <input
          id="roomCodeInput"
          name="roomCodeInput"
          type="text"
          className={`textarea`}
          ref={roomCodeInput}
          defaultValue=""
          onChange={(e) => setRoomCode(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          aria-label={TranslationRoomCodeRegistration.roomCode}
          maxLength="12"
          tabIndex="0"
          enterKeyHint="enter"
          onBlur={() => setFocusHighlight(false)}
          autoComplete="off"
          onFocus={() => {
            setFocusHighlight(true);
          }}
        />
      </div>
      <ul
        className="inputfield-validation-list access-ob access-o6"
        tabIndex="0"
        aria-label={`${
          roomCodeValidChar
            ? TranslationRoomCodeRegistration.ariaCharsSuccess
            : TranslationRoomCodeRegistration.ariaCharsFail
        } 
        ${
          roomCodeValidLength
            ? TranslationRoomCodeRegistration.ariaValidLengthSuccess
            : TranslationRoomCodeRegistration.ariaValidLengthFail
        } `}
      >
        <li className="flex-row">
          {roomCodeValidChar ? (
            <img
              src={checkmark_green}
              alt={Generic.valid}
              aria-label="hidden"
            />
          ) : (
            <img
              src={cancel}
              alt={Generic.invalid}
              aria-label="hidden"
              className="filter-gray"
            />
          )}
          <p className={`${roomCodeValidChar ? 'valid' : 'color-gray'} `}>
            {TranslationRoomCodeRegistration.validCharTypes}
          </p>
        </li>
        <li className="flex-row">
          {roomCodeValidLength ? (
            <img
              src={checkmark_green}
              alt={Generic.valid}
              aria-label="hidden"
            />
          ) : (
            <img
              src={cancel}
              alt={Generic.invalid}
              aria-label="hidden"
              className="filter-gray"
            />
          )}
          <p className={`${roomCodeValidLength ? 'valid' : 'color-gray'} `}>
            {TranslationRoomCodeRegistration.validCharLength}
          </p>
        </li>
      </ul>
      {!roomCodeButtonOff && (
        <ButtonMeta
          handleClick={() => handleEnter(roomCodeInput.current.value)}
          btnText={roomCodeBtnText}
          icon={roomCodeBtnIcon}
          customClassName={`full-width mrg-t36 mrg-auto-lr`}
          iconAlt={'+'}
          fullWidth={true}
          unavailable={!roomCodeValidChar || !roomCodeValidLength}
          availabilityToggle={true}
          customIconStyle={{ height: '21px' }}
        />
      )}
    </>
  );
};

export default AddTranslationRoomInput;
