//Hooks
import { useLazyMyEventsQuery } from '../events/eventsApiSlice.js';
import {
  useLazyMyOrganizedEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
} from '../organize/organizeApiSlice';
import { useLazyMyDraftEventsQuery } from '../organize/draftEventApiSlice';
import { useAccountReduxHandlers } from '../account/useAccountHandlers.js';

//Components

//Utility

export const useNoticeHandlers = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();

  //API
  const [getMyEvents] = useLazyMyEventsQuery();
  const [getMyOrganizedEvents] = useLazyMyOrganizedEventsQuery();
  const [getMyOrganizedScheduledEvents] =
    useLazyMyOrganizedScheduledEventsQuery();
  const [getMyDraftEvents] = useLazyMyDraftEventsQuery();

  //variables
  const getMyEventNoticeTypes = [
    'eventParticipationPublished',
    'draftParticipationDraftRemoved',
    'eventParticipationEventRemoved',
    'removalFromEventParticipation',
    'removalFromDraftParticipation',
  ];

  const getMyDraftEventsNoticeTypes = [
    'draftParticipationInviteAccepted',
    'draftParticipationInviteRejected',
    'translationsDataRequestRejected',
    'translationsDataRequestSubmitted',
  ];

  const getMyOrganizedEventsNoticeTypes = [
    'eventParticipationInviteAccepted',
    'eventParticipationInviteRejected',
    'translationsDataRequestRejected',
    'translationsDataRequestSubmitted',
    'emailInvitationCompleted',
  ];

  const noticeTypesWithActionsArr = [
    'contactRequest',
    'eventParticipationInvite',
    'eventParticipationTranslationsRequest',
  ];

  function noticeReceivedRecently(notice) {
    const now = Date.now();
    const createdAt = new Date(notice.createdAt).getTime();
    const timeDifferenceCreatedAt = now - createdAt;

    if (timeDifferenceCreatedAt <= 10 * 500) {
      return true;
    }

    if (notice?.updatedAt) {
      const updatedAt = new Date(notice.updatedAt).getTime();
      const timeDifferenceUpdatedAt = now - updatedAt;

      if (timeDifferenceUpdatedAt <= 10 * 500) {
        return true;
      }
    }

    return false;
  }

  async function checkNoticeDataForDataRefresh(accountNotices) {
    try {
      //need to handle api endpoint refreshes for incoming changes (rather than the outgoing changes which can trigger refreshes via invalidatesTags)
      //Dont await; dont want to delay the time comparison

      if (accountNotices?.length > 0) {
        for (const notice of accountNotices) {
          const recent = noticeReceivedRecently(notice);

          if (recent) {
            if (notice.noticeStatus === 'unseen') {
              //Incoming Participant Refresh Checks//
              if (getMyEventNoticeTypes.includes(notice.noticeType)) {
                getMyEvents(accountId);
              }

              if (getMyDraftEventsNoticeTypes.includes(notice.noticeType)) {
                getMyDraftEvents(accountId);
              }

              if (getMyOrganizedEventsNoticeTypes.includes(notice.noticeType)) {
                getMyDraftEvents(accountId);
                getMyOrganizedEvents(accountId);
                getMyOrganizedScheduledEvents(accountId);
              }

              if (notice.noticeType === 'participantWithdrew') {
                if (notice.eventNoticeId?.draftId) {
                  getMyDraftEvents(accountId);
                }
                if (notice.eventNoticeId?.eventId) {
                  getMyOrganizedEvents(accountId);
                }
                if (notice.eventNoticeId?.scheduledEventId) {
                  getMyOrganizedScheduledEvents(accountId);
                }
              }
            }
          }
        }
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('useNoticeHandlers error', error);
      }
    }
  }

  return { checkNoticeDataForDataRefresh, noticeTypesWithActionsArr };
};
