import { useRef } from 'react';

//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import ProfilePhoto from './ProfilePhoto';
import Name from './Name';
import Password from './Password';
import Email from './Email';
import Phone from './Phone';
import PersonalBio from './PersonalBio';
import ProfileBanner from './ProfileBanner';
import ProfilePreview from '../ProfilePreview/ProfilePreview';

// import Badges from './Badges';

//Utility
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';
import SocialLinks from './SocialLinks';

const AccountContent = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { accountAccountReference } = useAccountReduxHandlers();

  //variables
  const bannerImageRef = useRef(null);

  return (
    <div className="account-content" id="account-content">
      <label className="account-code access-ob access-o6" tabIndex="0">
        {Account.account.accountCode} #{accountAccountReference}
      </label>

      <ProfilePreview previewMode={true} />
      <div className="h36" />

      <ProfileBanner ref={bannerImageRef} />
      <div className="h36" />

      <ProfilePhoto Account={Account} />
      <div className="h36" />

      <PersonalBio
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <SocialLinks
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <Email Account={Account} />
      <div className="h36" />

      <Name
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <Password Account={Account} saveAccountChange={saveAccountChange} />
      <div className="h36" />

      <Phone
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <ScrollToTop focusId={'profilePhotoLabel'} />
      <div className="h48" />
    </div>
  );
};

export default AccountContent;
