import { useEffect, useState } from 'react';

//hooks

import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import ParticipantListItemName from './Generic/ParticipantListItemName';
import LoadBucketImage from '../../../../../../components/LoadBucketImage/LoadBucketImage';
import ParticipantListItemStatusParticipant from './EventParticipants/ParticipantListItemStatusParticipant';
import ParticipantListItemStatusTranslations from './Translations/ParticipantListItemStatusTranslations';
import ParticipantListItemStatusDispatches from './Dispatches/ParticipantListItemStatusDispatches';

//utils
import { search_dark } from '../../../../../../assets/icons';

const ParticipantListItem = ({
  participant,
  handler,
  previewImage,
  customWrapper,
  listItemMode,

  //UI
  turnOffAnimation,
  borderBottomOff,

  //dispatches
  totalDispatchesAvailable,
  totalCurrentDispatches,
  allDispatchesIssued,
  translationsEnabled,
}) => {
  //hooks
  const { Generic, CommunityListAccountPersonal } = useLanguageComponents();

  //ui
  const [contactHoverUI, setContactHoverUI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    });
    return () => clearTimeout(timer);
  }, []); //prevents hover effect from appearing while loading

  return (
    <div className={`flex-row full-width`}>
      {participant && !isLoading && (
        <div
          id={`contact-${participant?.id}`}
          className={`participant-contact__wrapper full-width access-ob access-o6 
            ${
              borderBottomOff
                ? ''
                : 'participant-contact__wrapper--border-bottom'
            }
            ${
              turnOffAnimation || isLoading
                ? ''
                : 'participant-contact__wrapper--hover'
            }
            ${customWrapper ? customWrapper : ''}
            `}
          tabIndex="0"
          onFocus={
            turnOffAnimation || isLoading ? null : () => setContactHoverUI(true)
          }
          onBlur={
            turnOffAnimation || isLoading
              ? null
              : () => setContactHoverUI(false)
          }
          onMouseEnter={
            turnOffAnimation || isLoading ? null : () => setContactHoverUI(true)
          }
          onMouseLeave={
            turnOffAnimation || isLoading
              ? null
              : () => setContactHoverUI(false)
          }
          aria-label={`${participant?.participantData?.personal?.prefix} ${participant?.participantData?.personal?.firstName} ${participant?.participantData?.personal?.lastName}. ${CommunityListAccountPersonal.openProfileView}.`}
        >
          <button
            className="participant-contact__container"
            onClick={handler ? () => handler(participant?.id) : null}
          >
            <div className="flex-row full-width align-center">
              <div className="pos-rel">
                <LoadBucketImage
                  imagePath={
                    participant?.participantData?.personal?.profileImage
                  }
                  imgClassName={'participant-contact--profile mrg-r6'}
                  backupPath={'account_img_default.png'}
                  partialPath={
                    participant?.organizedAs !== 'inviteContact'
                      ? 'myndfull_participant_images'
                      : 'myndfull_account_images'
                  }
                  croppedImage={previewImage?.croppedImage?.croppedImage}
                />

                <div
                  className={`participant-contact-view pos-abs ${
                    contactHoverUI ? 'opacity-1' : 'opacity-0'
                  }`}
                >
                  <img src={search_dark} alt={Generic.arrow} />
                </div>
              </div>

              <div className="participant-name-status mrg-l6">
                <ParticipantListItemName participant={participant} />

                {listItemMode === 'eventParticipant' && (
                  <ParticipantListItemStatusParticipant p={participant} />
                )}

                {listItemMode === 'translations' && (
                  <ParticipantListItemStatusTranslations p={participant} />
                )}

                {listItemMode === 'dispatches' && (
                  <ParticipantListItemStatusDispatches
                    p={participant}
                    totalDispatchesAvailable={totalDispatchesAvailable}
                    totalCurrentDispatches={totalCurrentDispatches}
                    allDispatchesIssued={allDispatchesIssued}
                    translationsEnabled={translationsEnabled}
                  />
                )}
              </div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default ParticipantListItem;
