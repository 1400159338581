import { useState } from 'react';

//hooks

//components
import ButtonNewWindow from '../../../../../../components/ButtonNewWindow/ButtonNewWindow';

//utils
import { account_reg_personal } from '../../../../../../assets/art';

const OrganizerCurrentPlan = () => {
  const [accountType, setAccountType] = useState('personal');
  const [isImage01Loaded, setIsImage01Loaded] = useState(false);
  // const [isImage02Loaded, setIsImage02Loaded] = useState(false);

  function handleAccountTypeSelection() {}

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="organizerCurrentPlanLabel"
        tabIndex="0"
      >
        Current Plan
      </label>
      <div className="flex-row align-center mrg-t24">
        <ul className="account-org-plan-card__wrapper">
          <li className={`flex-column ${isImage01Loaded ? '' : 'none'}`}>
            <button
              className={`mrg-b24 access-ob access-o6
                  ${
                    accountType === 'personal'
                      ? 'account-org-plan-card highlight-b2-theme'
                      : 'account-org-plan-card highlight-b2-theme'
                  } 
                }`}
              onClick={() => handleAccountTypeSelection('personal')}
              type="button"
              tabIndex="0"
              aria-pressed={accountType === 'personal'}
            >
              <img
                className="svg mrg-t24"
                src={account_reg_personal}
                alt="img"
                onLoad={() => setIsImage01Loaded(true)}
              />
              <p className="fs21 fwb mrg-t12">Personal (Free)</p>

              <ul className="plan-summary mrg-t12">
                <li>Create and Manage Events</li>
                <li>Access Find Speakers Platform</li>
                <li>Schedule Events</li>
              </ul>
              <div className="h24" />
            </button>
            <ButtonNewWindow btnText={'Explore More'} center={true} />
          </li>
        </ul>
      </div>
      <div className="h36" />
    </>
  );
};

export default OrganizerCurrentPlan;
