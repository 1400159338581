//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../language/useLanguageData';

//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import Counter from '../../../../components/Counter/Counter';
import CheckBox from '../../../../components/Selections/CheckBox';
import ButtonSwitchLOrder from '../../../../components/ButtonSwitch/ButtonSwitchLOrder';
import ButtonSwitchHOrder from '../../../../components/ButtonSwitch/ButtonSwitchHOrder';

//Utility

const Registration = ({
  initialize,
  CreateEvent,
  skipToId,
  skipToLabel,
  //state
  translationsEnabled,
  contactEnabled,
  loadHostingOption,
  loadMaxRegistrationsInPersonEnabled,
  loadMaxRegistrationsVirtualEnabled,
  loadEnablePrivateDetails,
  loadRegistrationsActive,
  loadApproveRegistrations,
  loadMaxRegistrationsInPersonAmount,
  loadMaxRegistrationsVirtualAmount,
  loadPrivateDetails,

  //handlers
  handleSetApproveRegistrations,
  handleSetMaxRegistrationsInPersonEnabled,
  handleSetMaxRegistrationsVirtualEnabled,
  handleSetEnablePrivateDetails,
  handleSetMaxRegistrationsInPersonAmount,
  handleSetMaxRegistrationsVirtualAmount,
  handleSetPrivateDetails,
  handleSetRegistrations,
}) => {
  //Hooks
  const { PrivateDetailsOpts } = useLanguageData();

  return (
    <fieldset
      className="fieldset"
      id="fieldset-registration"
      onMouseDown={(e) => e.preventDefault()} //prevent focus
    >
      <legend id="aria-fieldset-instructions-registration">
        {CreateEvent.registration.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="registrationLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-registration"
      >
        {CreateEvent.registration.registration}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      <ButtonSwitchHOrder
        id={'registrations'}
        title={CreateEvent.registration.requireRegistration}
        handler={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        horizontal={true}
      />
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.requireRegistrationsDescription}
      </p>

      {loadRegistrationsActive && (
        <>
          <div className="h24-ph36" />
          <ButtonSwitchLOrder
            id={'approveRegistrations'}
            title={CreateEvent.registration.approveRegistrations}
            handler={handleSetApproveRegistrations}
            handleHOrderCondition={handleSetRegistrations}
            stateHO={loadRegistrationsActive}
            loadTrigger={initialize}
            loadValue={loadApproveRegistrations}
            horizontal={true}
          />
          <div className="h12" />
          <p className="description-text" tabIndex="0">
            {CreateEvent.registration.approveRegistrationsDescription}
          </p>
          <div className="h24-ph36" />
          <ButtonSwitchLOrder
            id={'maxRegistrationsInPerson'}
            title={CreateEvent.registration.maxRegistrationsInPerson}
            handler={handleSetMaxRegistrationsInPersonEnabled}
            handleHOrderCondition={handleSetRegistrations}
            stateHO={loadRegistrationsActive}
            loadTrigger={initialize}
            secondaryTrigger={
              loadHostingOption === 'inPerson' || loadHostingOption === 'hybrid'
            }
            loadValue={loadMaxRegistrationsInPersonEnabled}
            horizontal={true}
            temporaryDisable={
              loadHostingOption !== 'hybrid' && loadHostingOption !== 'inPerson'
                ? true
                : false
            }
            temporaryDisableMessage={'incompatibleHostingOption'}
          />

          <div className="h12" />

          <p className="description-text" tabIndex="0">
            {CreateEvent.registration.maxRegistrationsDescriptionsInPerson}
          </p>

          {loadMaxRegistrationsInPersonEnabled && (
            <>
              <div className="h24" />
              <Counter
                handleCounter={handleSetMaxRegistrationsInPersonAmount}
                id={'maxRegistrationCounter'}
                loadValue={loadMaxRegistrationsInPersonAmount}
                increment={10}
                ariaCounterInputLabel={
                  CreateEvent.registration
                    .ariaMaxRegistrationsCounterInputInPerson
                }
                minValue={0}
                maxValue={9999}
              />
            </>
          )}

          <div className="h24-ph36" />

          <ButtonSwitchLOrder
            id={'maxRegistrationsVirtual'}
            title={CreateEvent.registration.maxRegistrationsVirtual}
            handler={handleSetMaxRegistrationsVirtualEnabled}
            handleHOrderCondition={handleSetRegistrations}
            stateHO={loadRegistrationsActive}
            loadTrigger={initialize}
            secondaryTrigger={
              loadHostingOption === 'online' || loadHostingOption === 'hybrid'
            }
            loadValue={loadMaxRegistrationsVirtualEnabled}
            horizontal={true}
            temporaryDisable={
              loadHostingOption !== 'hybrid' && loadHostingOption !== 'online'
                ? true
                : false
            }
            temporaryDisableMessage={'incompatibleHostingOption'}
          />

          <div className="h12" />

          <p className="description-text" tabIndex="0">
            {CreateEvent.registration.maxRegistrationsDescriptionsVirtual}
          </p>

          {loadMaxRegistrationsVirtualEnabled && (
            <>
              <div className="h24" />
              <Counter
                handleCounter={handleSetMaxRegistrationsVirtualAmount}
                id={'maxRegistrationCounterVirtual'}
                loadValue={loadMaxRegistrationsVirtualAmount}
                increment={10}
                ariaCounterInputLabel={
                  CreateEvent.registration
                    .ariaMaxRegistrationsCounterInputVirtual
                }
                minValue={0}
                maxValue={9999}
              />
            </>
          )}

          <div className="h24-ph36" />

          <ButtonSwitchLOrder
            id={'privateDetails'}
            title={CreateEvent.registration.privateDetails}
            handler={handleSetEnablePrivateDetails}
            handleHOrderCondition={handleSetRegistrations}
            stateHO={loadRegistrationsActive}
            loadTrigger={initialize}
            loadValue={loadEnablePrivateDetails}
            horizontal={true}
          />
          <div className="h12" />
          <p className="description-text" tabIndex="0">
            {CreateEvent.registration.privateDetailsDescription}
          </p>
          {loadHostingOption && loadEnablePrivateDetails && (
            <div className="h24" />
          )}

          {/* PRIVATE DETAILS */}
          {loadEnablePrivateDetails && (
            <div className="checkbox__wrapper mrg-t12">
              <div className="flex-column mrg-t12 mrg-r36">
                <p className="flex-center fs18 fwsb mrg-tb12">
                  {CreateEvent.registration.hostingInPerson}
                </p>
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.room}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.room}
                  temporaryDisable={
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.room &&
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.locationName}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.locationName}
                  temporaryDisable={
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.locationName &&
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.streetAddress}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.streetAddress}
                  temporaryDisable={
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.streetAddress &&
                    loadHostingOption !== 'inPerson' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
              </div>

              <div className="flex-column mrg-t12 mrg-r36">
                <p className="flex-center fs18 fwsb mrg-tb12">
                  {CreateEvent.registration.hostingVirtual}
                </p>
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.virtualMeetingId}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingId}
                  temporaryDisable={
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.virtualMeetingId &&
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.virtualMeetingPasscode}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingPasscode}
                  temporaryDisable={
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.virtualMeetingPasscode &&
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.virtualMeetingLink}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingLink}
                  temporaryDisable={
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                  temporaryDisableMessage={'incompatibleHostingOption'}
                  secondaryTrigger={
                    loadPrivateDetails.virtualMeetingLink &&
                    loadHostingOption !== 'online' &&
                    loadHostingOption !== 'hybrid'
                      ? true
                      : false
                  }
                />
              </div>

              <div className="flex-column mrg-t12">
                <p className="flex-center fs18 fwsb mrg-tb12">
                  {' '}
                  {CreateEvent.registration.translations}
                </p>
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.translationsRoomCode}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.translationsRoomCode}
                  temporaryDisable={!translationsEnabled}
                  temporaryDisableMessage={'requiresTranslations'}
                  secondaryTrigger={
                    loadPrivateDetails.translationsRoomCode &&
                    !translationsEnabled
                      ? true
                      : false
                  }
                />

                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.translationsRoomPassword}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.translationsRoomPassword}
                  temporaryDisable={!translationsEnabled}
                  temporaryDisableMessage={'requiresTranslations'}
                  secondaryTrigger={
                    loadPrivateDetails.translationsRoomPassword &&
                    !translationsEnabled
                      ? true
                      : false
                  }
                />
                <p className="flex-center fs18 fwsb mrg-tb12">
                  {CreateEvent.registration.contact}
                </p>
                <CheckBox
                  visible={true}
                  selectionObj={PrivateDetailsOpts.contact}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.contact}
                  temporaryDisable={!contactEnabled}
                  temporaryDisableMessage={'requiresContact'}
                  secondaryTrigger={
                    loadPrivateDetails.contact && !contactEnabled ? true : false
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </fieldset>
  );
};

export default Registration;
