//hooks
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils
import {
  edit,
  refresh,
  return_curved_arrow,
} from '../../../../../assets/icons';

const SpeakerActionBtns = ({
  speaker,
  handleSaveAndReturn,
  setEditObj,
  editObj,
}) => {
  //hooks
  const { EventParticipant } = useLanguageOrganize();

  return (
    <div className="flex-row full-width space-evenly">
      <ButtonTextSimple
        handler={() => {
          setEditObj();
          handleSaveAndReturn({ ...speaker });
        }}
        icon={return_curved_arrow}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={
          speaker?.organizedAs === 'create' || editObj
            ? EventParticipant.saveAndReturn
            : EventParticipant.return
        }
        customWrapper={'mrg-auto-lr text-gray highlight-it-theme'}
      />

      {speaker?.organizedAs !== 'create' &&
        speaker?.dispatches?.translationsStatus === 'accepted' &&
        !editObj && (
          <ButtonTextSimple
            handler={() => setEditObj(speaker)}
            icon={edit}
            iconOn={true}
            iconClass={'filter-gray mrg-r12'}
            iconStyle={{ height: '18px' }}
            text={EventParticipant.editMode}
            customWrapper={'mrg-auto-lr text-gray highlight-it-theme'}
          />
        )}
      {editObj && (
        <ButtonTextSimple
          handler={() => handleSaveAndReturn({ ...editObj })}
          icon={refresh}
          iconOn={true}
          iconClass={'filter-gray mrg-r12'}
          iconStyle={{ height: '18px' }}
          text={EventParticipant.resetToPreviousData}
          customWrapper={'mrg-auto-lr text-gray highlight-it-theme'}
        />
      )}
    </div>
  );
};

export default SpeakerActionBtns;
