import { useEffect, useRef, useState } from 'react';
//hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useApiStatus from '../../../../context/useApiStatus';
import useSettings from '../../../../context/useSettings';
import { useEventHandlers } from '../../events/useEventHandlers';
import { useOrganizeHandlers } from '../useOrganizeHandlers';
import { useEventParticipantsHandlers } from '../eventParticipants/useEventParticipantsHandlers';

//components
import Spinner from '../../../../components/Spinner/Spinner';
import TranslationsSpeakerBase from '../eventSections/EventTranslationsSections/TranslationsSpeakerBase';

//utils

const TranslationsParticipationFormInviteContact = () => {
  //hooks
  const { eventId, noticeId } = useParams();
  const { accountId, accountFirstName, accountLastName } =
    useAccountReduxHandlers();
  const { handleWarning } = useApiStatus();
  const { setNavbar } = useSettings();
  const { handleGetEvent } = useEventHandlers();
  const { handleTranslationsSpeakerDataValidation } = useOrganizeHandlers();
  const { handleSendEventTranslationsInviteContactData } =
    useEventParticipantsHandlers();
  const navigate = useNavigate();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [eventData, setEventData] = useState();

  //ui
  const [page, setPage] = useState(0);

  //var
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false && eventId && accountId) {
      setNavbar('');
      effectRan.current = true;
      if (eventId) {
        handleGetEvent({ setEventData, setIsLoading, isLoading, eventId });
      }
    }
  }, [eventId, accountId]);

  useEffect(() => {
    if (!isLoading && page === 0) {
      const animationTimer = setTimeout(() => {
        setPage(1);
      }, 1000);
      return () => clearTimeout(animationTimer);
    }
  }, [isLoading]);

  async function handleSubmit(speakerData) {
    if (!submitIsLoading) {
      setSubmitIsLoading(true);

      try {
        const completeData = handleTranslationsSpeakerDataValidation(
          speakerData.translationsSpeakerData
        );
        if (!completeData) {
          return handleWarning({
            message: 'incompleteInformation',
            origin: 'TranslationsParticipationForm.js/handleSubmit',
            id: Date.now(),
          });
        }

        await handleSendEventTranslationsInviteContactData({
          participantId: accountId,
          eventId,
          speakerData: speakerData.translationsSpeakerData,
          noticeId,
        });

        navigate('/');
      } catch (error) {
        setSubmitIsLoading(false);
      }
    }
  }

  return (
    <div className="mrg-auto-lr min-full-height">
      {page === 0 && <Spinner minHeight={'100%'} />}
      {page === 1 && (
        <div className="mrg-t48">
          <p className="fwb fs21 flex-center">Participant Translations Form</p>
          <TranslationsSpeakerBase
            participantFormSubmissionView={true}
            participantFormSubmissionHandler={handleSubmit}
            roomLanguages={eventData?.translations?.roomLanguages}
            prevSpeaker={{
              id: Date.now(),
              translationsSpeakerData: {
                name: `${accountFirstName} ${accountLastName}`,
                keywordsList: [],
                languageOpt: 'select',
                voiceOpt: 'select',
                discussionContext: '',
              },
            }}
            submitIsLoading={submitIsLoading}
          />
          <div className="h128" />
        </div>
      )}
    </div>
  );
};

export default TranslationsParticipationFormInviteContact;
