import useSettings from '../context/useSettings';

const useLanguageData = () => {
  const { contentLanguage } = useSettings();

  const CountryCodeOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        canada: {
          country: 'Canada',
          code: '+1',
        },
        unitedStates: {
          country: 'United States',
          code: '+1',
        },
        mexico: {
          country: 'Mexico',
          code: '+52',
        },
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        canada: {
          country: 'Canada',
          code: '+1',
        },
        unitedStates: {
          country: 'États-Unis',
          code: '+1',
        },
        mexico: {
          country: 'Mexique',
          code: '+52',
        },
      };
    }
    return translation;
  };

  const GetFlag = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        flag: 'flag',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        flag: 'drapeau',
      };
    }
    return translation;
  };

  const ContentLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = 'English';
    }
    if (contentLanguage === 'fr-CA') {
      translation = 'Français';
    }
    return translation;
  }; //remove?

  const LanguageAppSettingsOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
      };
    }
    return translation;
  };

  const CountriesOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        canada: 'Canada',
        unitedStates: 'United States',
        mexico: 'Mexico',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        canada: 'Canada',
        unitedStates: 'États-Unis',
        mexico: 'Mexique',
      };
    }
    return translation;
  };

  const SearchLanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
      };
    }
    return translation;
  };

  const EmailLanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English',
        'fr-CA': 'French',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais ',
        'fr-CA': 'Français',
      };
    }
    return translation;
  };

  const BigTagsOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        anth: 'Anthropology',
        asci: 'Applied Science',
        astu: 'Area Studies',
        astr: 'Astronomy',
        bus: 'Business Studies',
        chem: 'Chemistry',
        clas: 'Classical Studies',
        csci: 'Computer Science',
        cula: 'Culinary Arts',
        easci: 'Earth Science',
        econ: 'Economics',
        edu: 'Education',
        eng: 'Engineering',
        gend: 'Gender Studies',
        hsci: 'Health Science',
        hist: 'History',
        hgeo: 'Human Geography',
        lang: 'Languages',
        law: 'Law',
        lisci: 'Life Science',
        ling: 'Linguistics',
        lit: 'Literary Arts',
        math: 'Mathematics',
        med: 'Media Studies',
        perf: 'Performing Arts',
        phil: 'Philosophy',
        phys: 'Physics',
        poli: 'Political Science',
        psy: 'Psychology',
        rel: 'Religion',
        soci: 'Sociology',
        stat: 'Statistics',
        visa: 'Visual Arts',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        anth: 'Anthropologie',
        cula: 'Arts Culinaires',
        perf: 'Arts de la Scène',
        lit: 'Arts Littéraires',
        visa: 'Arts Visuels',
        astr: 'Astronomie',
        chem: 'Chimie',
        law: 'Droit',
        econ: 'Économie',
        edu: 'Éducation',
        gend: 'Études de Genre',
        med: 'Études des Médias',
        clas: 'Études Classiques',
        bus: 'Études Commerciales',
        astu: 'Études Régionales',
        hgeo: 'Géographie Humaine',
        hist: 'Histoire',
        eng: 'Ingénierie',
        lang: 'Langues',
        ling: 'Linguistique',
        csci: `L'Informatique`,
        math: 'Mathématiques',
        phil: 'Philosophie',
        phys: 'Physique',
        psy: 'Psychologie',
        rel: 'Religion',
        asci: 'Sciences Appliquées',
        easci: 'Sciences de la Terre',
        hsci: 'Sciences de la Santé',
        lisci: 'Sciences de la Vie',
        poli: 'Science Politique',
        soci: 'Sociologie',
        stat: 'Statistiques',
      };
    }
    return translation;
  };

  const BigTagsOptsWithSubText = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        anth: { mainText: 'Anthropology', subText: '' },
        asci: { mainText: 'Applied Science', subText: '' },
        astu: { mainText: 'Area Studies', subText: 'ex. Canadian Studies' },
        astr: { mainText: 'Astronomy', subText: '' },
        bus: { mainText: 'Business Studies', subText: '' },
        chem: { mainText: 'Chemistry', subText: '' },
        clas: { mainText: 'Classical Studies', subText: '' },
        csci: { mainText: 'Computer Science', subText: '' },
        cula: { mainText: 'Culinary Arts', subText: '' },
        easci: { mainText: 'Earth Science', subText: '' },
        econ: { mainText: 'Economics', subText: '' },
        edu: { mainText: 'Education', subText: '' },
        eng: { mainText: 'Engineering', subText: '' },
        gend: { mainText: 'Gender Studies', subText: '' },
        hsci: { mainText: 'Health Science', subText: '' },
        hist: { mainText: 'History', subText: '' },
        hgeo: { mainText: 'Human Geography', subText: '' },
        lang: { mainText: 'Languages', subText: '' },
        law: { mainText: 'Law', subText: '' },
        lisci: { mainText: 'Life Science', subText: '' },
        ling: { mainText: 'Linguistics', subText: '' },
        lit: { mainText: 'Literary Arts', subText: '' },
        math: { mainText: 'Mathematics', subText: '' },
        med: { mainText: 'Media Studies', subText: '' },
        perf: { mainText: 'Performing Arts', subText: '' },
        phil: { mainText: 'Philosophy', subText: '' },
        phys: { mainText: 'Physics', subText: '' },
        poli: { mainText: 'Political Science', subText: '' },
        psy: { mainText: 'Psychology', subText: '' },
        rel: { mainText: 'Religion', subText: '' },
        soci: { mainText: 'Sociology', subText: '' },
        stat: { mainText: 'Statistics', subText: '' },
        visa: { mainText: 'Visual Arts', subText: '' },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        anth: { mainText: 'Anthropologie', subText: '' },
        cula: { mainText: 'Arts Culinaires', subText: '' },
        perf: { mainText: 'Arts de la Scène', subText: '' },
        lit: { mainText: 'Arts Littéraires', subText: '' },
        visa: { mainText: 'Arts Visuels', subText: '' },
        astr: { mainText: 'Astronomie', subText: '' },
        chem: { mainText: 'Chimie', subText: '' },
        law: { mainText: 'Droit', subText: '' },
        econ: { mainText: 'Économie', subText: '' },
        edu: { mainText: 'Éducation', subText: '' },
        gend: { mainText: 'Études de Genre', subText: '' },
        med: { mainText: 'Études des Médias', subText: '' },
        clas: { mainText: 'Études Classiques', subText: '' },
        bus: { mainText: 'Études Commerciales', subText: '' },
        astu: {
          mainText: 'Études Régionales',
          subText: 'ex. Études canadiennes',
        },
        hgeo: { mainText: 'Géographie Humaine', subText: '' },
        hist: { mainText: 'Histoire', subText: '' },
        eng: { mainText: 'Ingénierie', subText: '' },
        lang: { mainText: 'Langues', subText: '' },
        ling: { mainText: 'Linguistique', subText: '' },
        csci: { mainText: `L'Informatique`, subText: '' },
        math: { mainText: 'Mathématiques', subText: '' },
        phil: { mainText: 'Philosophie', subText: '' },
        phys: { mainText: 'Physique', subText: '' },
        psy: { mainText: 'Psychologie', subText: '' },
        rel: { mainText: 'Religion', subText: '' },
        asci: { mainText: 'Sciences Appliquées', subText: '' },
        easci: { mainText: 'Sciences de la Terre', subText: '' },
        hsci: { mainText: 'Sciences de la Santé', subText: '' },
        lisci: { mainText: 'Sciences de la Vie', subText: '' },
        poli: { mainText: 'Science Politique', subText: '' },
        soci: { mainText: 'Sociologie', subText: '' },
        stat: { mainText: 'Statistiques', subText: '' },
      };
    }
    return translation;
  };
  const AudienceTypes = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general: 'General',
        academic: 'Academic',
        scientific: 'Scientific',
        youth: 'Youth',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        general: 'Général',
        academic: 'Académique',
        scientific: 'Scientifique',
        youth: 'Jeunesse',
      };
    }

    return translation;
  };

  const AudienceTypesEventPage = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general: 'General Audience',
        academic: 'Academic Audience',
        scientific: 'Scientific Audience',
        youth: 'Youth Audience',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        general: 'Audience générale',
        academic: 'Audience académique',
        scientific: 'Audience Scientifique',
        youth: 'Audience Jeunesse',
      };
    }

    return translation;
  };

  const EventTypes = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        lecture: 'Lecture',
        workshop: 'Workshop',
        roundtable: 'Roundtable',
        panel: 'Panel',
        forum: 'Forum',
        symposium: 'Symposium',
        launch: 'Launch',
        awards: 'Awards',
        social: 'Social',
        other: 'Other',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        lecture: 'Cours Magistral',
        workshop: 'Atelier',
        roundtable: 'Table Ronde',
        panel: 'Panel',
        forum: 'Forum',
        symposium: 'Symposium',
        launch: 'Lancement',
        awards: 'Prix',
        social: 'Social',
        other: 'Authre',
      };
    }

    return translation;
  };

  const AudienceTypesInfo = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general:
          'General audience has varied levels of knowledge and expertise. Content should be accessible and engaging.',
        academic:
          'Academic audience has in-depth knowledge of a scholarly subject. Content should be well-researched and can be expected to include specialized language.',

        scientific:
          'Scientific audience includes researchers, scholars, and professionals who have expertise in a particular scientific field. Appropriate for highly specialized content which includes technical language.',

        youth:
          'Youth audience can range from children to young adults. Event should be engaging, educational and use accessible language that is tailored to the age group.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        general: `Le grand public a des niveaux variés de connaissances et d'expertise. Le contenu doit être accessible et engageant.`,
        academic: `Le public académique a une connaissance approfondie d'un sujet scientifique. Le contenu doit être bien documenté et on peut s'attendre à ce qu'il inclue un langage spécialisé.`,
        scientific: `Le public scientifique comprend les chercheurs, les universitaires et les professionnels qui ont une expertise dans un domaine scientifique particulier. Convient aux contenus hautement spécialisés qui incluent un langage technique.`,
        youth: `Le public des jeunes peut aller des enfants aux jeunes adultes. L'événement doit être engageant, éducatif et utiliser un langage accessible adapté au groupe d'âge.`,
      };
    }

    return translation;
  };

  const EventTypesInfo = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        Lecture:
          'A presentation given by one or more speakers on a defined topic to educate and inform the audience.',

        Workshop:
          'An interactive event where participants actively engage in learning a new skill or concept. An emphasis is placed on practical applications and experiential learning.',

        Roundtable:
          'A small group discussion where participants share perspectives on a defined topic. The goal is to encourage dialogue and collaboration among participants.',

        Panel:
          'A moderated discussion by subject matter experts on a defined topic. Typically, each panelist will have a unique perspective on the topic.',

        Forum:
          'An open debate or discussion among a group of people with differing opinions or perspectives. The goal is to explore different viewpoints on a particular issue, stimulate discussion and directly engage the audience.',

        Symposium:
          'A formal academic gathering where experts present research on a specific topic. Symposiums may involve multiple events with the objective of sharing ideas and fostering scholarly dialogue.',

        Launch:
          'An announcement or celebration of a new book, study, project, product, or service. The objective is to generate interest or awareness among the audience.',

        Awards:
          'A formal ceremony to recognize and celebrate the achievements or contributions of individuals or organizations in a particular field.',

        Social:
          'A gathering where people come together to socialize and connect. The goal is to create a relaxed and engaging environment for attendees who share academic or educational interests.',

        Other:
          'This event type does not fall into any of the pre-set categories. Ensure there is an extensive event description.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        lecture: `Une présentation donnée par un ou plusieurs orateurs sur un sujet défini pour éduquer et informer le public.`,
        workshop: `Un événement interactif où les participants s'engagent activement dans l'apprentissage d'une nouvelle compétence ou d'un nouveau concept. L'accent est mis sur les applications pratiques et l'apprentissage expérientiel.`,
        roundtable: `Une petite discussion de groupe où les participants partagent leurs perspectives sur un sujet défini. Le but est d'encourager le dialogue et la collaboration entre les participants.`,
        panel:
          'Une discussion modérée par des experts en la matière sur un sujet défini. Typiquement, chaque panéliste aura une perspective unique sur le sujet.',
        forum: `Un débat ou une discussion ouverte entre un groupe de personnes ayant des opinions ou des perspectives différentes. L'objectif est d'explorer différentes perspectives sur une question particulière, de stimuler la discussion et d'engager directement le public.`,
        symposium:
          'Une rencontre académique formelle où des experts présentent des recherches sur un sujet spécifique. Les symposiums peuvent impliquer plusieurs événements avec pour objectif de partager des idées et de favoriser le dialogue universitaire.',
        launch: `Une annonce ou une célébration d'un nouveau livre, d'une étude, d'un projet, d'un produit ou d'un service. L'objectif est de susciter l'intérêt ou la sensibilisation du public.`,
        awards: `Une cérémonie formelle pour reconnaître et célébrer les réalisations ou les contributions d'individus ou d'organisations dans un domaine particulier.`,
        social:
          'Une rencontre où les gens se réunissent pour socialiser et se connecter. Le but est de créer un environnement détendu et engageant pour les participants qui partagent des intérêts académiques ou éducatifs.',
        other: `Ce type d'événement n'entre dans aucune des catégories prédéfinies. Veillez à ce qu'il y ait une description détaillée de l'événement.`,
      };
    }

    return translation;
  };

  const HostingOptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inPerson: 'In Person',
        online: 'Online',
        hybrid: 'Hybrid',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        inPerson: 'En personne',
        online: 'En ligne',
        hybrid: 'Hybride',
      };
    }

    return translation;
  };

  const OnlinePlatformOptions = () => {
    return {
      zoom: 'Zoom',
      teams: 'Teams',
    };
  };

  const PrivateDetailsOpts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        room: {
          room: 'Room',
        },
        locationName: {
          locationName: 'Location Name',
        },
        streetAddress: {
          streetAddress: 'Street Address',
        },
        virtualMeetingId: {
          virtualMeetingId: 'Virtual Meeting Id',
        },
        virtualMeetingPasscode: {
          virtualMeetingPasscode: `Virtual Meeting Passcode`,
        },
        virtualMeetingLink: {
          virtualMeetingLink: 'Virtual Meeting Link',
        },
        translationsRoomCode: {
          translationsRoomCode: 'Translations Room Code',
        },
        translationsRoomPassword: {
          translationsRoomPassword: 'Translations Room Password',
        },
        contact: {
          contact: 'Contact Information',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        room: {
          room: 'Salle',
        },
        locationName: {
          locationName: `Nom de l'emplacement`,
        },
        streetAddress: {
          streetAddress: 'Adresse',
        },
        virtualMeetingId: {
          virtualMeetingId: 'ID de la réunion virtuelle',
        },
        virtualMeetingPasscode: {
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
        },
        virtualMeetingLink: {
          virtualMeetingLink: 'Lien de la réunion virtuelle ',
        },
        translationsRoomCode: {
          translationsRoomCode: 'Traductions Code de la salle',
        },
        translationsRoomPassword: {
          translationsRoomPassword: 'Mot de passe de la salle de traduction',
        },
        contact: {
          contact: 'Informations de contact',
        },
      };
    }

    return translation;
  };

  const ReportEventOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        spam: 'Spam',
        falseInfo: 'False Information',
        unlawfulActivity: 'Unlawful activity or promoting unlawful activity',
        defamDiscrim: 'Defamatory, discriminatory, or mean-spirited content',
        infringingRight:
          'Infringing on 3rd party rights (patent, trademark, copyright etc.)',
        violatingPrivacy: 'Violating the privacy of another person',
        other: 'Other',
        technical: 'Technical',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        spam: 'Courrier indésirable',
        falseInfo: 'Fausse information',
        unlawfulActivity: `Activité illégale ou promotion d'une activité illégale`,
        defamDiscrim: 'Contenu diffamatoire, discriminatoire ou mesquin',
        infringingRight: `Violation des droits de tiers (brevet, marque, droit d'auteur, etc.)`,
        violatingPrivacy: `Violer la vie privée d'une autre personne`,
        other: 'Autre',
        technical: 'Technique',
      };
    }
    return translation;
  };

  const TimeZoneOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        canadaAtlanticTime: 'Canada/Atlantic',
        canadaCentralTime: 'Canada/Central',
        canadaEasternTime: 'Canada/Eastern',
        canadaMountainTime: 'Canada/Mountain',
        canadaNewfoundlandTime: 'Canada/Newfoundland',
        canadaPacificTime: 'Canada/Pacific',
        canadaYukonTime: 'Canada/Yukon',
        mexicoCentralTime: 'Mexico/Central',
        mexicoEasternTime: 'Mexico/Eastern',
        mexicoMountainTime: 'Mexico/Mountain',
        mexicoPacificTime: 'Mexico/Pacific',
        unitedStatesAlaskaTime: 'United States/Alaska',
        unitedStatesCentralTime: 'United States/Central',
        unitedStatesEasternTime: 'United States/Eastern',
        unitedStatesHawaiiAleutianTime: 'United States/Aleutian',
        unitedStatesMountainTime: 'United States/Mountain',
        unitedStatesPacificTime: 'United States/Pacific',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        canadaAtlanticTime: 'Canada/Atlantique',
        canadaCentralTime: 'Canada/Centre',
        canadaEasternTime: 'Canada/Est',
        canadaMountainTime: 'Canada/Montagne',
        canadaNewfoundlandTime: 'Canada/Terre-Neuve',
        canadaPacificTime: 'Canada/Pacifique',
        canadaYukonTime: 'Canada/Yukon',
        mexicoCentralTime: 'Mexico/Centre',
        mexicoEasternTime: 'Mexico/Est',
        mexicoMountainTime: 'Mexico/Montagne',
        mexicoPacificTime: 'Mexico/Pacifique',
        unitedStatesAlaskaTime: 'United States/Alaska',
        unitedStatesCentralTime: 'United States/Centre',
        unitedStatesEasternTime: 'United States/Est',
        unitedStatesHawaiiAleutianTime: 'United States/Aléoutiennes',
        unitedStatesMountainTime: 'United States/Montagne',
        unitedStatesPacificTime: 'United States/Pacifique',
      };
    }
    return translation;
  };

  const VoiceOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        voiceBob: 'Voice Bob',
        voiceJudy: 'Voice Judy',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        voiceBob: 'Voix Bob',
        voiceJudy: 'Voix Judy',
      };
    }
    return translation;
  };

  const BrowserOpts = () => {
    let translation = {
      chrome: 'Chrome',
      edge: 'Edge',
      fireFox: 'Firefox',
      opera: 'Opera',
      Safari: 'Safari',
      chromeAndroid: 'Chrome Android',
      fireFoxAndroid: 'Firefox Android',
      operaAndroid: 'Opera Android',
      safariIOS: 'Safari IOS',
      samsungInternet: 'Samsung Internet',
      webViewAndroid: 'Webview Android',
    };

    return translation;
  };

  const TranslationLanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
        es: 'Spanish (Español)',
        it: 'Italian (Italiano)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
        es: 'Espagnol (Español)',
        it: 'Italien (Italiano)',
      };
    }
    return translation;
  };

  const TranslationLanguagesSingular = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English',
        'fr-CA': 'French',
        es: 'Spanish',
        it: 'Italian',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais',
        'fr-CA': 'Français',
        es: 'Espagnol',
        it: 'Italien',
      };
    }
    return translation;
  };

  const SalesTaxTitles = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        GST: 'GST',
        HST: 'HST',
        PST: 'PST',
        QST: 'QST',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        GST: 'TPS',
        HST: 'TVH',
        PST: 'TVP',
        QST: 'TVQ',
      };
    }
    return translation;
  };

  const Months = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'Decemeber',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        january: 'Janvier',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Août',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Décembre',
      };
    }
    return translation;
  };

  const MonthsFromNumber = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        10: 'October',
        11: 'November',
        12: 'Decemeber',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        '01': 'Janvier',
        '02': 'Février',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juillet',
        '08': 'Août',
        '09': 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre',
      };
    }
    return translation;
  };

  const InvoiceStatus = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        draft: 'Draft',
        open: 'Open',
        paid: 'Paid',
        uncollectable: 'Uncollectable',
        void: 'Void',
        pending: 'Pending',
        deleted: 'Deleted',
        unpaid: 'Unpaid',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        draft: 'Brouillon',
        open: 'Ouverte',
        paid: 'Payée',
        uncollectible: 'Incollectable',
        void: 'Annulée',
        pending: 'En attente',
        deleted: 'Supprimée',
        unpaid: 'Non rémunéré',
      };
    }
    return translation;
  };

  const RoomCodeManagerActions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        hostPassword: 'Host Password',
        attendeePassword: 'Attendee Password',
        changeRoomCode: 'Change Room Code',
        remove: 'Remove',
        restoreSubscription: 'Restore Subscription',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        hostPassword: `Mot de passe de l'hôte`,
        attendeePassword: 'Mot de passe du participant',
        changeRoomCode: 'Changer le code de la salle',
        remove: 'Retirer',
        restoreSubscription: 'Restore Subscription',
      };
    }

    return translation;
  };

  const RoomCodeManagerActionDescriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        hostPassword: `( Mandatory ) Set the password required to host a translation room with this code. Host passwords means you can host a room without having to log into your account.`,
        attendeePassword: `When hosts create rooms, they can require attendees to provide a password. If you intend to reuse the same attendee password frequently, you can set a default. This option still allows you to set a different password each time you host a room.`,
        remove: 'Remove this room code subscription from your account.',
        changeRoomCode:
          'Change the room code used in this subscription. No charges are applied.',
        restoreSubscription:
          'This room code subscription is scheduled to be cancelled at the end of your billing cycle. By restoring subscription you will uncancel your subscription at no cost.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        hostPassword: `( Obligatoire ) Définissez le mot de passe requis pour héberger une salle de traduction avec ce code. Les mots de passe d'hôte signifient que vous pouvez héberger une salle sans avoir à vous connecter à votre compte.`,
        attendeePassword: `Lorsque les hôtes créent des salles, ils peuvent demander aux participants de fournir un mot de passe. Si vous avez l'intention de réutiliser fréquemment le même mot de passe de participant, vous pouvez définir un mot de passe par défaut. Cette option vous permet toujours de définir un mot de passe différent à chaque fois que vous hébergez une salle.`,
        remove: 'Supprimez cet abonnement au code de la salle de votre compte.',
        changeRoomCode: `Modifiez le code de la salle utilisé dans cet abonnement. Aucun frais n'est appliqué.`,
        restoreSubscription: `Cet abonnement au code de la salle doit être annulé à la fin de votre cycle de facturation. En rétablissant l'abonnement, vous annulerez votre abonnement sans frais.`,
      };
    }

    return translation;
  };

  const CredentialOpts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        artisticActivities: 'Artistic Activities',
        certificatesLicenses: 'Certificates / Licenses',
        education: 'Education',
        employment: 'Employment',
        honorsAwards: 'Honours / Awards',
        jobExperience: 'Job Experience',
        patentDesigns: 'Patents / Designs',
        presentations: 'Presentations',
        projects: 'Projects',
        publications: 'Publications',
        skillsExpertise: 'Skills / Expertise',
        volunteerExperience: 'Volunteer Experience',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        artisticActivities: 'Activités artistiques',
        certificatesLicenses: 'Certificats / Licences',
        education: 'Éducation',
        employment: 'Emploi',
        honorsAwards: 'Distinctions / Récompenses',
        jobExperience: 'Expérience professionnelle',
        patentDesigns: 'Brevets / Conceptions',
        presentations: 'Présentations',
        projects: 'Projets',
        publications: 'Publications',
        skillsExpertise: 'Compétences / Expertise',
        volunteerExperience: 'Expérience bénévole',
      };
    }

    return translation;
  };

  const LanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        notListed: 'Not Listed',
        'en-CA': 'English',
        'fr-CA': 'French (Français)',
        'af-ZA': 'Afrikaans (Afrikaans)',
        sq: 'Albanian (Shqip)',
        am: 'Amharic (አማርኛ)',
        'ar-XA': 'Arabic (العربية)',
        hy: 'Armenian (Հայերեն)',
        as: 'Assamese (অসমীয়া)',
        ay: 'Aymara (Aymar aru)',
        az: 'Azerbaijani (Azərbaycan dili)',
        bm: 'Bambara (Bamanankan)',
        'eu-ES': 'Basque (Euskara)',
        be: 'Belarusian (Беларуская)',
        'bn-IN': 'Bengali (বাংলা)',
        bho: 'Bhojpuri (भोजपुरी)',
        bs: 'Bosnian (Bosanski)',
        'bg-BG': 'Bulgarian (Български)',
        my: 'Burmese (မြန်မာစာ)',
        'ca-ES': 'Catalan (Català)',
        ceb: 'Cebuano (Sinugbuanong Binisaya)',
        'yue-HK': 'Chinese (中文)',
        co: 'Corsican (Corsu)',
        hr: 'Croatian (Hrvatski)',
        'cs-CZ': 'Czech (Čeština)',
        'da-DK': 'Danish (Dansk)',
        dv: 'Dhivehi (ދިވެހިބަސް)',
        doi: 'Dogri (डोगरी)',
        nl: 'Dutch (Nederlands)',
        eo: 'Esperanto (Esperanto)',
        et: 'Estonian (Eesti keel)',
        ee: 'Ewe (Eʋegbe)',
        'fil-PH': 'Filipino (Filipino)',
        'fi-FI': 'Finnish (Suomi)',
        fy: 'Frisian (Frysk)',
        'gl-ES': 'Galician (Galego)',
        ka: 'Georgian (ქართული)',
        'de-DE': 'German (Deutsch)',
        'el-GR': 'Greek (Ελληνικά)',
        gn: "Guarani (Avañe'ẽ)",
        'gu-IN': 'Gujarati (ગુજરાતી)',
        ht: 'Haitian Creole (Kreyòl Ayisyen)',
        ha: 'Hausa (Hausa)',
        haw: 'Hawaiian (ʻŌlelo Hawaiʻi)',
        'he-IL': 'Hebrew (עברית)',
        'hi-IN': 'Hindi (हिंदी)',
        hmn: 'Hmong (Hmoob)',
        'hu-HU': 'Hungarian (Magyar)',
        'is-IS': 'Icelandic (Íslenska)',
        ig: 'Igbo (Asụsụ Igbo)',
        ilo: 'Ilocano (Ilokano)',
        'id-ID': 'Indonesian (Bahasa Indonesia)',
        ga: 'Irish (Gaeilge)',
        'it-IT': 'Italian (Italiano)',
        'ja-JP': 'Japanese (日本語)',
        'kn-IN': 'Kannada (ಕನ್ನಡ)',
        kk: 'Kazakh (Қазақ тілі)',
        km: 'Khmer (ភាសាខ្មែរ)',
        rw: 'Kinyarwanda (Ikinyarwanda)',
        gom: 'Konkani (कोंकणी)',
        'ko-KR': 'Korean (한국어)',
        kri: 'Krio (Krio)',
        ku: 'Kurmanji (Kurdî)',
        ky: 'Kyrgyz (Кыргызча)',
        lo: 'Lao (ພາສາລາວ)',
        la: 'Latin (Lingua Latina)',
        'lv-LV': 'Latvian (Latviešu)',
        ln: 'Lingala (Lingála)',
        'lt-LT': 'Lithuanian (Lietuvių)',
        lg: 'Luganda (Oluganda)',
        lb: 'Luxembourgish (Lëtzebuergesch)',
        mk: 'Macedonian (Македонски)',
        mai: 'Maithili (मैथिली)',
        mg: 'Malagasy (Malagasy)',
        'ms-MY': 'Malay (Bahasa Melayu)',
        'ml-IN': 'Malayalam (മലയാളം)',
        mt: 'Maltese (Malti)',
        'cmn-CN': 'Mandarin (普通话)',
        mi: 'Maori (Te Reo Māori)',
        'mr-IN': 'Marathi (मराठी)',
        'mni-Mtei': 'Meiteilon (মেইতেই লোন)',
        lus: 'Mizo (Mizo tawng)',
        mn: 'Mongolian (Монгол хэл)',
        ne: 'Nepali (नेपाली)',
        'nb-NO': 'Norwegian (Norsk)',
        ny: 'Nyanja (ChiNyanja)',
        or: 'Odia (ଓଡ଼ିଆ)',
        om: 'Oromo (Afaan Oromoo)',
        ps: 'Pashto (پښتو)',
        fa: 'Persian (فارسی)',
        'pl-PL': 'Polish (Polski)',
        pt: 'Portuguese (Português)',
        'pa-IN': 'Punjabi (ਪੰਜਾਬੀ)',
        qu: 'Quechua (Runa Simi)',
        'ro-RO': 'Romanian (Română)',
        'ru-RU': 'Russian (Русский)',
        sm: 'Samoan (Gagana faʻa Sāmoa)',
        sa: 'Sanskrit (संस्कृतम्)',
        gd: 'Scots Gaelic (Gàidhlig)',
        nso: 'Sepedi (Sesotho sa Leboa)',
        'sr-RS': 'Serbian (Српски)',
        st: 'Sesotho (Sesotho)',
        sn: 'Shona (chiShona)',
        sd: 'Sindhi (سنڌي)',
        si: 'Sinhala (සිංහල)',
        'sk-SK': 'Slovak (Slovenčina)',
        sl: 'Slovenian (Slovenščina)',
        so: 'Somali (Soomaali)',
        ckb: 'Sorani (کوردیی ناوەندی)',
        es: 'Spanish (Español)',
        su: 'Sundanese (Basa Sunda)',
        sw: 'Swahili (Kiswahili)',
        'sv-SE': 'Swedish (Svenska)',
        tg: 'Tajik (Тоҷикӣ)',
        'ta-IN': 'Tamil (தமிழ்)',
        tt: 'Tatar (Татарча)',
        'te-IN': 'Telugu (తెలుగు)',
        'th-TH': 'Thai (ไทย)',
        ti: 'Tigrinya (ትግርኛ)',
        ts: 'Tsonga (Xitsonga)',
        'tr-TR': 'Turkish (Türkçe)',
        tk: 'Turkmen (Türkmençe)',
        ak: 'Twi (Twi)',
        'uk-UA': 'Ukrainian (Українська)',
        ur: 'Urdu (اردو)',
        ug: 'Uyghur (ئۇيغۇرچە)',
        uz: 'Uzbek (Oʻzbekcha)',
        'vi-VN': 'Vietnamese (Tiếng Việt)',
        cy: 'Welsh (Cymraeg)',
        xh: 'Xhosa (isiXhosa)',
        yi: 'Yiddish (ייִדיש)',
        yo: 'Yoruba (Yorùbá)',
        zu: 'Zulu (isiZulu)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        notListed: 'Non répertorié',
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français',
        'af-ZA': 'Afrikaans (Afrikaans)',
        sq: 'Albanais (Shqip)',
        am: 'Amharique (አማርኛ)',
        'ar-XA': 'Arabe (العربية)',
        hy: 'Arménien (Հայերեն)',
        as: 'Assamese (অসমীয়া)',
        ay: 'Aymara (Aymar aru)',
        az: 'Azerbaïdjanais (Azərbaycan dili)',
        bm: 'Bambara (Bamanankan)',
        'eu-ES': 'Basque (Euskara)',
        be: 'Biélorusse (Беларуская)',
        'bn-IN': 'Bengali (বাংলা)',
        bho: 'Bhojpuri (भोजपुरी)',
        bs: 'Bosniaque (Bosanski)',
        'bg-BG': 'Bulgare (Български)',
        my: 'Birman (မြန်မာစာ)',
        'ca-ES': 'Catalan (Català)',
        ceb: 'Cebuano (Sinugbuanong Binisaya)',
        'yue-HK': 'Chinois (中文)',
        co: 'Corse (Corsu)',
        hr: 'Croate (Hrvatski)',
        'cs-CZ': 'Tchèque (Čeština)',
        'da-DK': 'Danois (Dansk)',
        dv: 'Dhivehi (ދިވެހިބަސް)',
        doi: 'Dogri (डोगरी)',
        nl: 'Néerlandais (Nederlands)',
        eo: 'Espéranto (Esperanto)',
        et: 'Estonien (Eesti keel)',
        ee: 'Éwé (Eʋegbe)',
        'fil-PH': 'Philippin (Filipino)',
        'fi-FI': 'Finnois (Suomi)',
        fy: 'Frison (Frysk)',
        'gl-ES': 'Galicien (Galego)',
        ka: 'Géorgien (ქართული)',
        'de-DE': 'Allemand (Deutsch)',
        'el-GR': 'Grec (Ελληνικά)',
        gn: "Guarani (Avañe'ẽ)",
        'gu-IN': 'Gujarati (ગુજરાતી)',
        ht: 'Créole haïtien (Kreyòl Ayisyen)',
        ha: 'Haoussa (Hausa)',
        haw: 'Hawaïen (ʻŌlelo Hawaiʻi)',
        'he-IL': 'Hébreu (עברית)',
        'hi-IN': 'Hindi (हिंदी)',
        hmn: 'Hmong (Hmoob)',
        'hu-HU': 'Hongrois (Magyar)',
        'is-IS': 'Islandais (Íslenska)',
        ig: 'Igbo (Asụsụ Igbo)',
        ilo: 'Ilocano (Ilokano)',
        'id-ID': 'Indonésien (Bahasa Indonesia)',
        ga: 'Irlandais (Gaeilge)',
        'it-IT': 'Italien (Italiano)',
        'ja-JP': 'Japonais (日本語)',
        'kn-IN': 'Kannada (ಕನ್ನಡ)',
        kk: 'Kazakh (Қазақ тілі)',
        km: 'Khmer (ភាសាខ្មែរ)',
        rw: 'Kinyarwanda (Ikinyarwanda)',
        gom: 'Konkani (कोंकणी)',
        'ko-KR': 'Coréen (한국어)',
        kri: 'Krio (Krio)',
        ku: 'Kurmanji (Kurdî)',
        ky: 'Kirghiz (Кыргызча)',
        lo: 'Laotien (ພາສາລາວ)',
        la: 'Latin (Lingua Latina)',
        'lv-LV': 'Letton (Latviešu)',
        ln: 'Lingala (Lingála)',
        'lt-LT': 'Lituanien (Lietuvių)',
        lg: 'Luganda (Oluganda)',
        lb: 'Luxembourgeois (Lëtzebuergesch)',
        mk: 'Macédonien (Македонски)',
        mai: 'Maithili (मैथिली)',
        mg: 'Malgache (Malagasy)',
        'ms-MY': 'Malais (Bahasa Melayu)',
        'ml-IN': 'Malayalam (മലയാളം)',
        mt: 'Maltais (Malti)',
        'cmn-CN': 'Mandarin (普通话)',
        mi: 'Maori (Te Reo Māori)',
        'mr-IN': 'Marathi (मराठी)',
        'mni-Mtei': 'Meiteilon (মেইতেই লোন)',
        lus: 'Mizo (Mizo tawng)',
        mn: 'Mongol (Монгол хэл)',
        ne: 'Népali (नेपाली)',
        'nb-NO': 'Norvégien (Norsk)',
        ny: 'Nyanja (ChiNyanja)',
        or: 'Odia (ଓଡ଼ିଆ)',
        om: 'Oromo (Afaan Oromoo)',
        ps: 'Pachto (پښتو)',
        fa: 'Persan (فارسی)',
        'pl-PL': 'Polonais (Polski)',
        pt: 'Portugais (Português)',
        'pa-IN': 'Pendjabi (ਪੰਜਾਬੀ)',
        qu: 'Quechua (Runa Simi)',
        'ro-RO': 'Roumain (Română)',
        'ru-RU': 'Russe (Русский)',
        sm: 'Samoan (Gagana faʻa Sāmoa)',
        sa: 'Sanskrit (संस्कृतम्)',
        gd: 'Gaélique écossais (Gàidhlig)',
        nso: 'Sepedi (Sesotho sa Leboa)',
        'sr-RS': 'Serbe (Српски)',
        st: 'Sesotho (Sesotho)',
        sn: 'Shona (chiShona)',
        sd: 'Sindhi (سنڌي)',
        si: 'Cinghalais (සිංහල)',
        'sk-SK': 'Slovaque (Slovenčina)',
        sl: 'Slovène (Slovenščina)',
        so: 'Somali (Soomaali)',
        ckb: 'Sorani (کوردیی ناوەندی)',
        es: 'Espagnol (Español)',
        su: 'Soundanais (Basa Sunda)',
        sw: 'Swahili (Kiswahili)',
        'sv-SE': 'Suédois (Svenska)',
        tg: 'Tadjik (Тоҷикӣ)',
        'ta-IN': 'Tamoul (தமிழ்)',
        tt: 'Tatar (Татарча)',
        'te-IN': 'Télougou (తెలుగు)',
        'th-TH': 'Thaïlandais (ไทย)',
        ti: 'Tigrigna (ትግርኛ)',
        ts: 'Tsonga (Xitsonga)',
        'tr-TR': 'Turc (Türkçe)',
        tk: 'Turkmène (Türkmençe)',
        ak: 'Twi (Twi)',
        'uk-UA': 'Ukrainien (Українська)',
        ur: 'Ourdou (اردو)',
        ug: 'Ouïghour (ئۇيغۇرچە)',
        uz: 'Ouzbek (Oʻzbekcha)',
        'vi-VN': 'Vietnamien (Tiếng Việt)',
        cy: 'Gallois (Cymraeg)',
        xh: 'Xhosa (isiXhosa)',
        yi: 'Yiddish (ייִדיש)',
        yo: 'Yoruba (Yorùbá)',
        zu: 'Zoulou (isiZulu)',
      };
    }
    return translation;
  };
  const LanguageSimpleOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        notListed: 'Not Listed',
        'en-CA': 'English',
        'fr-CA': 'French',
        'af-ZA': 'Afrikaans',
        sq: 'Albanian',
        am: 'Amharic',
        'ar-XA': 'Arabic',
        hy: 'Armenian',
        as: 'Assamese',
        ay: 'Aymara',
        az: 'Azerbaijani',
        bm: 'Bambara',
        'eu-ES': 'Basque',
        be: 'Belarusian',
        'bn-IN': 'Bengali',
        bho: 'Bhojpuri',
        bs: 'Bosnian',
        'bg-BG': 'Bulgarian',
        my: 'Burmese',
        'ca-ES': 'Catalan',
        ceb: 'Cebuano',
        'yue-HK': 'Chinese',
        co: 'Corsican',
        hr: 'Croatian',
        'cs-CZ': 'Czech',
        'da-DK': 'Danish',
        dv: 'Dhivehi',
        doi: 'Dogri',
        nl: 'Dutch',
        eo: 'Esperanto',
        et: 'Estonian',
        ee: 'Ewe',
        'fil-PH': 'Filipino',
        'fi-FI': 'Finnish',
        fy: 'Frisian',
        'gl-ES': 'Galician',
        ka: 'Georgian',
        'de-DE': 'German',
        'el-GR': 'Greek',
        gn: 'Guarani',
        'gu-IN': 'Gujarati',
        ht: 'Haitian Creole',
        ha: 'Hausa',
        haw: 'Hawaiian',
        'he-IL': 'Hebrew',
        'hi-IN': 'Hindi',
        hmn: 'Hmong',
        'hu-HU': 'Hungarian',
        'is-IS': 'Icelandic',
        ig: 'Igbo',
        ilo: 'Ilocano',
        'id-ID': 'Indonesian',
        ga: 'Irish',
        'it-IT': 'Italian',
        'ja-JP': 'Japanese',
        'kn-IN': 'Kannada',
        kk: 'Kazakh',
        km: 'Khmer',
        rw: 'Kinyarwanda',
        gom: 'Konkani',
        'ko-KR': 'Korean',
        kri: 'Krio',
        ku: 'Kurmanji',
        ky: 'Kyrgyz',
        lo: 'Lao',
        la: 'Latin',
        'lv-LV': 'Latvian',
        ln: 'Lingala',
        'lt-LT': 'Lithuanian',
        lg: 'Luganda',
        lb: 'Luxembourgish',
        mk: 'Macedonian',
        mai: 'Maithili',
        mg: 'Malagasy',
        'ms-MY': 'Malay',
        'ml-IN': 'Malayalam',
        mt: 'Maltese',
        'cmn-CN': 'Mandarin',
        mi: 'Maori',
        'mr-IN': 'Marathi',
        'mni-Mtei': 'Meiteilon',
        lus: 'Mizo',
        mn: 'Mongolian',
        ne: 'Nepali',
        'nb-NO': 'Norwegian',
        ny: 'Nyanja',
        or: 'Odia',
        om: 'Oromo',
        ps: 'Pashto',
        fa: 'Persian',
        'pl-PL': 'Polish',
        pt: 'Portuguese',
        'pa-IN': 'Punjabi',
        qu: 'Quechua',
        'ro-RO': 'Romanian',
        'ru-RU': 'Russian',
        sm: 'Samoan',
        sa: 'Sanskrit',
        gd: 'Scots Gaelic',
        nso: 'Sepedi',
        'sr-RS': 'Serbian',
        st: 'Sesotho',
        sn: 'Shona',
        sd: 'Sindhi',
        si: 'Sinhala',
        'sk-SK': 'Slovak',
        sl: 'Slovenian',
        so: 'Somali',
        ckb: 'Sorani',
        es: 'Spanish',
        su: 'Sundanese',
        sw: 'Swahili',
        'sv-SE': 'Swedish',
        tg: 'Tajik',
        'ta-IN': 'Tamil',
        tt: 'Tatar',
        'te-IN': 'Telugu',
        'th-TH': 'Thai',
        ti: 'Tigrinya',
        ts: 'Tsonga',
        'tr-TR': 'Turkish',
        tk: 'Turkmen',
        ak: 'Twi',
        'uk-UA': 'Ukrainian',
        ur: 'Urdu',
        ug: 'Uyghur',
        uz: 'Uzbek',
        'vi-VN': 'Vietnamese',
        cy: 'Welsh',
        xh: 'Xhosa',
        yi: 'Yiddish',
        yo: 'Yoruba',
        zu: 'Zulu',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        notListed: 'Non répertorié',
        'en-CA': 'Anglais',
        'fr-CA': 'Français',
        'af-ZA': 'Afrikaans',
        sq: 'Albanais',
        am: 'Amharique',
        'ar-XA': 'Arabe',
        hy: 'Arménien',
        as: 'Assamese',
        ay: 'Aymara',
        az: 'Azerbaïdjanais',
        bm: 'Bambara',
        'eu-ES': 'Basque',
        be: 'Biélorusse',
        'bn-IN': 'Bengali',
        bho: 'Bhojpuri',
        bs: 'Bosniaque',
        'bg-BG': 'Bulgare',
        my: 'Birman',
        'ca-ES': 'Catalan',
        ceb: 'Cebuano',
        'yue-HK': 'Chinois',
        co: 'Corse',
        hr: 'Croate',
        'cs-CZ': 'Tchèque',
        'da-DK': 'Danois',
        dv: 'Dhivehi',
        doi: 'Dogri',
        nl: 'Néerlandais',
        eo: 'Espéranto',
        et: 'Estonien',
        ee: 'Éwé',
        'fil-PH': 'Philippin',
        'fi-FI': 'Finnois',
        fy: 'Frison',
        'gl-ES': 'Galicien',
        ka: 'Géorgien',
        'de-DE': 'Allemand',
        'el-GR': 'Grec',
        gn: 'Guarani',
        'gu-IN': 'Gujarati',
        ht: 'Créole haïtien',
        ha: 'Haoussa',
        haw: 'Hawaïen',
        'he-IL': 'Hébreu',
        'hi-IN': 'Hindi',
        hmn: 'Hmong',
        'hu-HU': 'Hongrois',
        'is-IS': 'Islandais',
        ig: 'Igbo',
        ilo: 'Ilocano',
        'id-ID': 'Indonésien',
        ga: 'Irlandais',
        'it-IT': 'Italien',
        'ja-JP': 'Japonais',
        'kn-IN': 'Kannada',
        kk: 'Kazakh',
        km: 'Khmer',
        rw: 'Kinyarwanda',
        gom: 'Konkani',
        'ko-KR': 'Coréen',
        kri: 'Krio',
        ku: 'Kurmanji',
        ky: 'Kirghiz',
        lo: 'Laotien',
        la: 'Latin',
        'lv-LV': 'Letton',
        ln: 'Lingala',
        'lt-LT': 'Lituanien',
        lg: 'Luganda',
        lb: 'Luxembourgeois',
        mk: 'Macédonien',
        mai: 'Maithili',
        mg: 'Malgache',
        'ms-MY': 'Malais',
        'ml-IN': 'Malayalam',
        mt: 'Maltais',
        'cmn-CN': 'Mandarin',
        mi: 'Maori',
        'mr-IN': 'Marathi',
        'mni-Mtei': 'Meiteilon',
        lus: 'Mizo',
        mn: 'Mongol',
        ne: 'Népali',
        'nb-NO': 'Norvégien',
        ny: 'Nyanja',
        or: 'Odia',
        om: 'Oromo',
        ps: 'Pachto',
        fa: 'Persan',
        'pl-PL': 'Polonais',
        pt: 'Portugais',
        'pa-IN': 'Pendjabi',
        qu: 'Quechua',
        'ro-RO': 'Roumain',
        'ru-RU': 'Russe',
        sm: 'Samoan',
        sa: 'Sanskrit',
        gd: 'Gaélique écossais',
        nso: 'Sepedi',
        'sr-RS': 'Serbe',
        st: 'Sesotho',
        sn: 'Shona',
        sd: 'Sindhi',
        si: 'Cinghalais',
        'sk-SK': 'Slovaque',
        sl: 'Slovène',
        so: 'Somali',
        ckb: 'Sorani',
        es: 'Espagnol',
        su: 'Soundanais',
        sw: 'Swahili',
        'sv-SE': 'Suédois',
        tg: 'Tadjik',
        'ta-IN': 'Tamoul',
        tt: 'Tatar',
        'te-IN': 'Télougou',
        'th-TH': 'Thaïlandais',
        ti: 'Tigrigna',
        ts: 'Tsonga',
        'tr-TR': 'Turc',
        tk: 'Turkmène',
        ak: 'Twi',
        'uk-UA': 'Ukrainien',
        ur: 'Ourdou',
        ug: 'Ouïghour',
        uz: 'Ouzbek',
        'vi-VN': 'Vietnamien',
        cy: 'Gallois',
        xh: 'Xhosa',
        yi: 'Yiddish',
        yo: 'Yoruba',
        zu: 'Zoulou',
      };
    }
    return translation;
  };

  const LanguageSimpleNoNotListedOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English',
        'fr-CA': 'French',
        'af-ZA': 'Afrikaans',
        sq: 'Albanian',
        am: 'Amharic',
        'ar-XA': 'Arabic',
        hy: 'Armenian',
        as: 'Assamese',
        ay: 'Aymara',
        az: 'Azerbaijani',
        bm: 'Bambara',
        'eu-ES': 'Basque',
        be: 'Belarusian',
        'bn-IN': 'Bengali',
        bho: 'Bhojpuri',
        bs: 'Bosnian',
        'bg-BG': 'Bulgarian',
        my: 'Burmese',
        'ca-ES': 'Catalan',
        ceb: 'Cebuano',
        'yue-HK': 'Chinese',
        co: 'Corsican',
        hr: 'Croatian',
        'cs-CZ': 'Czech',
        'da-DK': 'Danish',
        dv: 'Dhivehi',
        doi: 'Dogri',
        nl: 'Dutch',
        eo: 'Esperanto',
        et: 'Estonian',
        ee: 'Ewe',
        'fil-PH': 'Filipino',
        'fi-FI': 'Finnish',
        fy: 'Frisian',
        'gl-ES': 'Galician',
        ka: 'Georgian',
        'de-DE': 'German',
        'el-GR': 'Greek',
        gn: 'Guarani',
        'gu-IN': 'Gujarati',
        ht: 'Haitian Creole',
        ha: 'Hausa',
        haw: 'Hawaiian',
        'he-IL': 'Hebrew',
        'hi-IN': 'Hindi',
        hmn: 'Hmong',
        'hu-HU': 'Hungarian',
        'is-IS': 'Icelandic',
        ig: 'Igbo',
        ilo: 'Ilocano',
        'id-ID': 'Indonesian',
        ga: 'Irish',
        'it-IT': 'Italian',
        'ja-JP': 'Japanese',
        'kn-IN': 'Kannada',
        kk: 'Kazakh',
        km: 'Khmer',
        rw: 'Kinyarwanda',
        gom: 'Konkani',
        'ko-KR': 'Korean',
        kri: 'Krio',
        ku: 'Kurmanji',
        ky: 'Kyrgyz',
        lo: 'Lao',
        la: 'Latin',
        'lv-LV': 'Latvian',
        ln: 'Lingala',
        'lt-LT': 'Lithuanian',
        lg: 'Luganda',
        lb: 'Luxembourgish',
        mk: 'Macedonian',
        mai: 'Maithili',
        mg: 'Malagasy',
        'ms-MY': 'Malay',
        'ml-IN': 'Malayalam',
        mt: 'Maltese',
        'cmn-CN': 'Mandarin',
        mi: 'Maori',
        'mr-IN': 'Marathi',
        'mni-Mtei': 'Meiteilon',
        lus: 'Mizo',
        mn: 'Mongolian',
        ne: 'Nepali',
        'nb-NO': 'Norwegian',
        ny: 'Nyanja',
        or: 'Odia',
        om: 'Oromo',
        ps: 'Pashto',
        fa: 'Persian',
        'pl-PL': 'Polish',
        pt: 'Portuguese',
        'pa-IN': 'Punjabi',
        qu: 'Quechua',
        'ro-RO': 'Romanian',
        'ru-RU': 'Russian',
        sm: 'Samoan',
        sa: 'Sanskrit',
        gd: 'Scots Gaelic',
        nso: 'Sepedi',
        'sr-RS': 'Serbian',
        st: 'Sesotho',
        sn: 'Shona',
        sd: 'Sindhi',
        si: 'Sinhala',
        'sk-SK': 'Slovak',
        sl: 'Slovenian',
        so: 'Somali',
        ckb: 'Sorani',
        es: 'Spanish',
        su: 'Sundanese',
        sw: 'Swahili',
        'sv-SE': 'Swedish',
        tg: 'Tajik',
        'ta-IN': 'Tamil',
        tt: 'Tatar',
        'te-IN': 'Telugu',
        'th-TH': 'Thai',
        ti: 'Tigrinya',
        ts: 'Tsonga',
        'tr-TR': 'Turkish',
        tk: 'Turkmen',
        ak: 'Twi',
        'uk-UA': 'Ukrainian',
        ur: 'Urdu',
        ug: 'Uyghur',
        uz: 'Uzbek',
        'vi-VN': 'Vietnamese',
        cy: 'Welsh',
        xh: 'Xhosa',
        yi: 'Yiddish',
        yo: 'Yoruba',
        zu: 'Zulu',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        notListed: 'Non répertorié',
        'en-CA': 'Anglais',
        'fr-CA': 'Français',
        'af-ZA': 'Afrikaans',
        sq: 'Albanais',
        am: 'Amharique',
        'ar-XA': 'Arabe',
        hy: 'Arménien',
        as: 'Assamese',
        ay: 'Aymara',
        az: 'Azerbaïdjanais',
        bm: 'Bambara',
        'eu-ES': 'Basque',
        be: 'Biélorusse',
        'bn-IN': 'Bengali',
        bho: 'Bhojpuri',
        bs: 'Bosniaque',
        'bg-BG': 'Bulgare',
        my: 'Birman',
        'ca-ES': 'Catalan',
        ceb: 'Cebuano',
        'yue-HK': 'Chinois',
        co: 'Corse',
        hr: 'Croate',
        'cs-CZ': 'Tchèque',
        'da-DK': 'Danois',
        dv: 'Dhivehi',
        doi: 'Dogri',
        nl: 'Néerlandais',
        eo: 'Espéranto',
        et: 'Estonien',
        ee: 'Éwé',
        'fil-PH': 'Philippin',
        'fi-FI': 'Finnois',
        fy: 'Frison',
        'gl-ES': 'Galicien',
        ka: 'Géorgien',
        'de-DE': 'Allemand',
        'el-GR': 'Grec',
        gn: 'Guarani',
        'gu-IN': 'Gujarati',
        ht: 'Créole haïtien',
        ha: 'Haoussa',
        haw: 'Hawaïen',
        'he-IL': 'Hébreu',
        'hi-IN': 'Hindi',
        hmn: 'Hmong',
        'hu-HU': 'Hongrois',
        'is-IS': 'Islandais',
        ig: 'Igbo',
        ilo: 'Ilocano',
        'id-ID': 'Indonésien',
        ga: 'Irlandais',
        'it-IT': 'Italien',
        'ja-JP': 'Japonais',
        'kn-IN': 'Kannada',
        kk: 'Kazakh',
        km: 'Khmer',
        rw: 'Kinyarwanda',
        gom: 'Konkani',
        'ko-KR': 'Coréen',
        kri: 'Krio',
        ku: 'Kurmanji',
        ky: 'Kirghiz',
        lo: 'Laotien',
        la: 'Latin',
        'lv-LV': 'Letton',
        ln: 'Lingala',
        'lt-LT': 'Lituanien',
        lg: 'Luganda',
        lb: 'Luxembourgeois',
        mk: 'Macédonien',
        mai: 'Maithili',
        mg: 'Malgache',
        'ms-MY': 'Malais',
        'ml-IN': 'Malayalam',
        mt: 'Maltais',
        'cmn-CN': 'Mandarin',
        mi: 'Maori',
        'mr-IN': 'Marathi',
        'mni-Mtei': 'Meiteilon',
        lus: 'Mizo',
        mn: 'Mongol',
        ne: 'Népali',
        'nb-NO': 'Norvégien',
        ny: 'Nyanja',
        or: 'Odia',
        om: 'Oromo',
        ps: 'Pachto',
        fa: 'Persan',
        'pl-PL': 'Polonais',
        pt: 'Portugais',
        'pa-IN': 'Pendjabi',
        qu: 'Quechua',
        'ro-RO': 'Roumain',
        'ru-RU': 'Russe',
        sm: 'Samoan',
        sa: 'Sanskrit',
        gd: 'Gaélique écossais',
        nso: 'Sepedi',
        'sr-RS': 'Serbe',
        st: 'Sesotho',
        sn: 'Shona',
        sd: 'Sindhi',
        si: 'Cinghalais',
        'sk-SK': 'Slovaque',
        sl: 'Slovène',
        so: 'Somali',
        ckb: 'Sorani',
        es: 'Espagnol',
        su: 'Soundanais',
        sw: 'Swahili',
        'sv-SE': 'Suédois',
        tg: 'Tadjik',
        'ta-IN': 'Tamoul',
        tt: 'Tatar',
        'te-IN': 'Télougou',
        'th-TH': 'Thaïlandais',
        ti: 'Tigrigna',
        ts: 'Tsonga',
        'tr-TR': 'Turc',
        tk: 'Turkmène',
        ak: 'Twi',
        'uk-UA': 'Ukrainien',
        ur: 'Ourdou',
        ug: 'Ouïghour',
        uz: 'Ouzbek',
        'vi-VN': 'Vietnamien',
        cy: 'Gallois',
        xh: 'Xhosa',
        yi: 'Yiddish',
        yo: 'Yoruba',
        zu: 'Zoulou',
      };
    }
    return translation;
  };

  const FindSpeakersLocationOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        locationMap: 'Map',
        locationCountry: 'Country',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        locationMap: 'Carte',
        locationCountry: 'Pays',
      };
    }
    return translation;
  };

  const CountryISOCodes = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        AF: 'Afghanistan',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CO: 'Colombia',
        KM: 'Comoros',
        CD: 'Congo (Democratic Republic of the)',
        CG: 'Congo (Republic of the)',
        CR: 'Costa Rica',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GR: 'Greece',
        GD: 'Grenada',
        GT: 'Guatemala',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HN: 'Honduras',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: "Korea (Democratic People's Republic of)",
        KR: 'Korea (Republic of)',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: "Lao People's Democratic Republic",
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MR: 'Mauritania',
        MU: 'Mauritius',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PL: 'Poland',
        PT: 'Portugal',
        QA: 'Qatar',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        AF: 'Afghanistan',
        AL: 'Albanie',
        DZ: 'Algérie',
        AS: 'Samoa américaines',
        AD: 'Andorre',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctique',
        AG: 'Antigua-et-Barbuda',
        AR: 'Argentine',
        AM: 'Arménie',
        AW: 'Aruba',
        AU: 'Australie',
        AT: 'Autriche',
        AZ: 'Azerbaïdjan',
        BS: 'Bahamas',
        BH: 'Bahreïn',
        BD: 'Bangladesh',
        BB: 'Barbade',
        BY: 'Bélarus',
        BE: 'Belgique',
        BZ: 'Belize',
        BJ: 'Bénin',
        BM: 'Bermudes',
        BT: 'Bhoutan',
        BO: 'Bolivie',
        BA: 'Bosnie-Herzégovine',
        BW: 'Botswana',
        BR: 'Brésil',
        BN: 'Brunéi Darussalam',
        BG: 'Bulgarie',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cap-Vert',
        KH: 'Cambodge',
        CM: 'Cameroun',
        CA: 'Canada',
        KY: 'Îles Caïmans',
        CF: 'République centrafricaine',
        TD: 'Tchad',
        CL: 'Chili',
        CN: 'Chine',
        CO: 'Colombie',
        KM: 'Comores',
        CD: 'Congo (Rép. dém.)',
        CG: 'Congo (Rép.)',
        CR: 'Costa Rica',
        HR: 'Croatie',
        CU: 'Cuba',
        CY: 'Chypre',
        CZ: 'République tchèque',
        DK: 'Danemark',
        DJ: 'Djibouti',
        DM: 'Dominique',
        DO: 'République dominicaine',
        EC: 'Équateur',
        EG: 'Égypte',
        SV: 'Salvador',
        GQ: 'Guinée équatoriale',
        ER: 'Érythrée',
        EE: 'Estonie',
        SZ: 'Eswatini',
        ET: 'Éthiopie',
        FJ: 'Fidji',
        FI: 'Finlande',
        FR: 'France',
        GA: 'Gabon',
        GM: 'Gambie',
        GE: 'Géorgie',
        DE: 'Allemagne',
        GH: 'Ghana',
        GR: 'Grèce',
        GD: 'Grenade',
        GT: 'Guatemala',
        GN: 'Guinée',
        GW: 'Guinée-Bissau',
        GY: 'Guyana',
        HT: 'Haïti',
        HN: 'Honduras',
        HU: 'Hongrie',
        IS: 'Islande',
        IN: 'Inde',
        ID: 'Indonésie',
        IR: 'Iran',
        IQ: 'Irak',
        IE: 'Irlande',
        IL: 'Israël',
        IT: 'Italie',
        JM: 'Jamaïque',
        JP: 'Japon',
        JO: 'Jordanie',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: 'Corée du Nord',
        KR: 'Corée du Sud',
        KW: 'Koweït',
        KG: 'Kirghizistan',
        LA: 'Laos',
        LV: 'Lettonie',
        LB: 'Liban',
        LS: 'Lesotho',
        LR: 'Libéria',
        LY: 'Libye',
        LI: 'Liechtenstein',
        LT: 'Lituanie',
        LU: 'Luxembourg',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaisie',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malte',
        MH: 'Îles Marshall',
        MR: 'Mauritanie',
        MU: 'Maurice',
        MX: 'Mexique',
        FM: 'Micronésie',
        MD: 'Moldavie',
        MC: 'Monaco',
        MN: 'Mongolie',
        ME: 'Monténégro',
        MA: 'Maroc',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibie',
        NR: 'Nauru',
        NP: 'Népal',
        NL: 'Pays-Bas',
        NZ: 'Nouvelle-Zélande',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigéria',
        NO: 'Norvège',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palaos',
        PA: 'Panama',
        PG: 'Papouasie-Nouvelle-Guinée',
        PY: 'Paraguay',
        PE: 'Pérou',
        PH: 'Philippines',
        PL: 'Pologne',
        PT: 'Portugal',
        QA: 'Qatar',
        RO: 'Roumanie',
        RU: 'Russie',
        RW: 'Rwanda',
        KN: 'Saint-Christophe-et-Niévès',
        LC: 'Sainte-Lucie',
        VC: 'Saint-Vincent-et-les-Grenadines',
        WS: 'Samoa',
        SM: 'Saint-Marin',
        ST: 'Sao Tomé-et-Principe',
        SA: 'Arabie saoudite',
        SN: 'Sénégal',
        RS: 'Serbie',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapour',
        SK: 'Slovaquie',
        SI: 'Slovénie',
        SB: 'Îles Salomon',
        SO: 'Somalie',
        ZA: 'Afrique du Sud',
        SS: 'Soudan du Sud',
        ES: 'Espagne',
        LK: 'Sri Lanka',
        SD: 'Soudan',
        SR: 'Suriname',
        SE: 'Suède',
        CH: 'Suisse',
        SY: 'Syrie',
        TW: 'Taïwan',
        TJ: 'Tadjikistan',
        TZ: 'Tanzanie',
        TH: 'Thaïlande',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TO: 'Tonga',
        TT: 'Trinité-et-Tobago',
        TN: 'Tunisie',
        TR: 'Turquie',
        TM: 'Turkménistan',
        TV: 'Tuvalu',
        UG: 'Ouganda',
        UA: 'Ukraine',
        AE: 'Émirats arabes unis',
        GB: 'Royaume-Uni',
        US: 'États-Unis',
        UY: 'Uruguay',
        UZ: 'Ouzbékistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viêt Nam',
        YE: 'Yémen',
        ZM: 'Zambie',
        ZW: 'Zimbabwe',
      };
    }
    return translation;
  };

  const CountryISOCodesSupported = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        CA: 'Canada',
        MX: 'Mexico',
        US: 'United States of America',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        CA: 'Canada',
        MX: 'Mexique',
        US: 'États-Unis',
      };
    }
    return translation;
  };

  const NoticeTypes = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        contactRequest: 'Request for Contact Info',
        contactRequestAccepted: 'Contact Request Accepted',
        contactRequestRejected: 'Contact Request Rejected',
        eventParticipationInvite: 'Event Participation Invite',
        eventParticipationInviteAccepted: 'Event Invite Accepted',
        eventParticipationInviteRejected: 'Event Invite Rejected',
        draftParticipationInviteAccepted: 'Draft Invite Accepted',
        draftParticipationInviteRejected: 'Draft Invite Rejected',
        eventParticipationPublished: 'Participation Event Published',
        draftParticipationDraftRemoved: 'Draft Removed',
        eventParticipationEventRemoved: 'Event Removed',
        removalFromEventParticipation: 'Removed from Event Participation',
        removalFromDraftParticipation: 'Removed from Draft Participation',
        participantWithdrew: 'Participant Withdrew',
        scheduledEventPublished: 'Scheduled Event Published',
        emailInvitationCompleted: 'Participant Email Invitation Completed',

        //translations
        eventParticipationTranslationsRequest:
          'Event Translations Data Request',
        translationsDataRequestRejected: 'Translations Data Request Rejected',
        translationsDataRequestSubmitted: 'Translations Data Request Submitted',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        contactRequest: `Demande d'informations de contact`,
        contactRequestAccepted: `Demande de contact acceptée`,
        contactRequestRejected: `Demande de contact rejetée`,
        eventParticipationInvite: `Invitation à la participation à l'événement`,
        eventParticipationInviteAccepted: `Invitation à l'événement acceptée`,
        eventParticipationInviteRejected: `Invitation à l'événement rejetée`,
        draftParticipationInviteAccepted: 'Invitation au projet acceptée',
        draftParticipationInviteRejected: 'Invitation au projet rejetée',
        eventParticipationPublished: 'Événement de participation publié',
        draftParticipationDraftRemoved: 'Brouillon supprimé',
        eventParticipationEventRemoved: 'Événement supprimé',
        removalFromEventParticipation: `Supprimé de la participation à l'événement`,
        removalFromDraftParticipation: `Supprimé de la participation au brouillon`,
        participantWithdrew: 'Participant retiré',
        scheduledEventPublished: 'Événement programmé publié',
        emailInvitationCompleted:
          'Invitation par courriel du participant terminée',

        //translations
        eventParticipationTranslationsRequest: `Demande de données de traduction d'événements`,
        translationsDataRequestRejected:
          'Demande de données de traduction rejetée',
        translationsDataRequestSubmitted:
          'Demande de données de traduction soumise',
      };
    }
    return translation;
  };

  const CommunityViewOpts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        followers: 'Followers',
        following: 'Following',
        contacts: 'Contacts',
        blockedAccounts: 'Blocked',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        followers: 'Abonnés',
        following: 'Abonnements',
        contacts: 'Contacts',
        blockedAccounts: 'Bloqués',
      };
    }

    return translation;
  };

  const PersonalRolesOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        attendee: 'Attendee',
        organizer: 'Organizer',
        speaker: 'Speaker',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        attendee: 'Participant(e)',
        organizer: 'Organisateur',
        speaker: 'Orateur',
      };
    }

    return translation;
  };

  const ImageAltRadialSelections = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        alt: 'Screen Reader Alt Text Only',
        displayCaption: 'Display as Caption & Alt Text',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        alt: `Texte alternatif du lecteur d'écran uniquement`,
        displayCaption: 'Afficher sous forme de légende et de texte alternatif',
      };
    }

    return translation;
  };

  return {
    AudienceTypes: AudienceTypes(),
    AudienceTypesEventPage: AudienceTypesEventPage(),
    AudienceTypesInfo: AudienceTypesInfo(),
    BigTagsOpts: BigTagsOpts(),
    ContentLanguage: ContentLanguage(),
    CountriesOpts: CountriesOpts(),
    CountryCodeOpts: CountryCodeOpts(),
    EventTypes: EventTypes(),
    EventTypesInfo: EventTypesInfo(),
    GetFlag: GetFlag(),
    HostingOptions: HostingOptions(),
    LanguageAppSettingsOpts: LanguageAppSettingsOpts(),
    OnlinePlatformOptions: OnlinePlatformOptions(),
    SearchLanguageOpts: SearchLanguageOpts(),
    PrivateDetailsOpts: PrivateDetailsOpts(),
    ReportEventOpts: ReportEventOpts(),
    TimeZoneOpts: TimeZoneOpts(),
    VoiceOpts: VoiceOpts(),
    BrowserOpts: BrowserOpts(),
    TranslationLanguageOpts: TranslationLanguageOpts(),
    TranslationLanguagesSingular: TranslationLanguagesSingular(),
    SalesTaxTitles: SalesTaxTitles(),
    Months: Months(),
    MonthsFromNumber: MonthsFromNumber(),
    InvoiceStatus: InvoiceStatus(),
    RoomCodeManagerActions: RoomCodeManagerActions(),
    RoomCodeManagerActionDescriptions: RoomCodeManagerActionDescriptions(),
    BigTagsOptsWithSubText: BigTagsOptsWithSubText(),
    CredentialOpts: CredentialOpts(),
    LanguageOpts: LanguageOpts(),
    LanguageSimpleOpts: LanguageSimpleOpts(),
    FindSpeakersLocationOpts: FindSpeakersLocationOpts(),
    CountryISOCodes: CountryISOCodes(),
    CountryISOCodesSupported: CountryISOCodesSupported(),
    NoticeTypes: NoticeTypes(),
    CommunityViewOpts: CommunityViewOpts(),
    PersonalRolesOpts: PersonalRolesOpts(),
    LanguageSimpleNoNotListedOpts: LanguageSimpleNoNotListedOpts(),
    ImageAltRadialSelections: ImageAltRadialSelections(),
    EmailLanguageOpts: EmailLanguageOpts(),
  };
};

export default useLanguageData;

// const hostingOptions = [
//   { 'In Person': 'Event will be held in person.' },
//   { Online: 'Event will be held online.' },
//   {
//     Hybrid: 'Event will be held in person and provide live attendance online.',
//   },
// ];
