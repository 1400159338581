import { useState } from 'react';

//hooks
import useAdminTechnicalHandlers from '../../../technical/useAdminTechnicalHandlers';
import useApiStatus from '../../../../../context/useApiStatus';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import TextArea from '../../../../../components/TextArea/TextArea';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';

//ui
import { cancel, edit, img_archived } from '../../../../../assets/icons';

const InfoCardActionsTechnicalLog = ({ idData, metaData }) => {
  //hooks
  const {
    handleRemoveTechnicalLog,
    handleCreateTechnicalLogArchive,
    createTechnicalLogArchiveIsLoading,
    removeTechnicalLogIsLoading,
    handleAdminTechnicalLogRemoveTechnicalLogById,
  } = useAdminTechnicalHandlers();
  const { handleWarning } = useApiStatus();
  //state
  const [notes, setNotes] = useState();
  const [archivePath, setArchivePath] = useState();
  const [adminActionSelection, setAdminActionSelection] = useState('');

  //functions
  async function handleAdminActionSubmit() {
    if (adminActionSelection === 'archive') {
      if (!archivePath) {
        return handleWarning({
          message: 'requiresArchivePath',
          origin: 'InfoCardActionsTechnicalLog.js/handleAdminActionSubmit',
          id: Date.now(),
        });
      }

      const response = await handleCreateTechnicalLogArchive(
        idData,
        archivePath,
        notes
      );
      if (response === 'success') {
        setArchivePath();
        setNotes();
      }
    }

    if (adminActionSelection === 'delete') {
      await handleRemoveTechnicalLog(idData);
      handleAdminTechnicalLogRemoveTechnicalLogById(idData);
    }

    setAdminActionSelection('');
  }

  return (
    <>
      {createTechnicalLogArchiveIsLoading || removeTechnicalLogIsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex-row">
            <div
              className={`support-ticket__actions ${
                adminActionSelection !== 'archive' ? 'mrg-b24' : 'mrg-b48'
              }`}
            >
              <ButtonMeta
                handleClick={() => {
                  if (adminActionSelection === 'archive') {
                    return setAdminActionSelection('');
                  } else {
                    setAdminActionSelection('archive');
                  }
                }}
                activeStateDependency={adminActionSelection === 'archive'}
                btnText={'Archive'}
                customClassName={'mrg-r24'}
                iconCustomClassName={'filter-grey'}
                icon={img_archived}
              />
              <ButtonMeta
                handleClick={() => {
                  if (adminActionSelection === 'delete') {
                    return setAdminActionSelection('');
                  } else {
                    setAdminActionSelection('delete');
                  }
                }}
                activeStateDependency={adminActionSelection === 'delete'}
                btnText={'Delete'}
                icon={cancel}
              />
            </div>
          </div>

          {adminActionSelection && (
            <div className="flex-column mrg-b48">
              {adminActionSelection === 'archive' && (
                <>
                  <div className="flex-row full-width mrg-b24">
                    <img
                      src={img_archived}
                      alt="editIcon"
                      className="mrg-r12 filter-gray mrg-auto-bottom"
                      style={{ height: '28px' }}
                    />
                    <TextArea
                      id={'archivePath'}
                      limit={255}
                      hideWordCount={true}
                      labelText={'Archive Path'}
                      placeHolder={`ex. buildv${metaData?.serverBuild}/registrationController/validationBug`}
                      handleChange={setArchivePath}
                      disableEnter={true}
                    />
                  </div>

                  <div className="flex-row full-width">
                    <img
                      src={edit}
                      alt="editIcon"
                      className="mrg-r12 filter-gray mrg-auto-bottom"
                    />
                    <TextArea
                      id={'reportNotes'}
                      limit={10000}
                      hideWordCount={true}
                      labelText={'Action Notes'}
                      handleChange={setNotes}
                    />
                  </div>
                </>
              )}
              <ConfirmSelection
                btn1Text={'Cancel'}
                btn1Color={'neutral'}
                btn1Handler={() => setAdminActionSelection('')}
                btn2Text={'Confirm'}
                btn2Color={'pos'}
                btn2Handler={handleAdminActionSubmit}
                ignoreMaxWidth={true}
                customWrapper={'mrg-t24'}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfoCardActionsTechnicalLog;
