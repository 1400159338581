import { apiSlice } from '../../../app/api/apiSlice';
import {
  setCommunity,
  setCommunityFollowing,
  setCommunityFollowingGroups,
} from './accountSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';

//note: community data in the account slice should remain account ids. do not fill with populated data.

export const accountCommunityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountCommunity: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/community/account/getAccountCommunity',
          method: 'GET',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setCommunity(data?.community));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log({
              id: Date.now(),
              status: 'error',
              error: error,
              origin: 'accountCommunityApiSlice/getAccountCommunity',
            });
          }
        }
      },
    }),
    getAccountCommunityLists: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/community/account/getAccountCommunityLists',
          method: 'GET',
          params: { accountId },
        };
      },
      //populated on server but dont set in account slice; getAccountCommunity provides populated data
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log({
              id: Date.now(),
              status: 'error',
              error: error,
              origin: 'accountCommunityApiSlice/getAccountCommunityLists',
            });
          }
        }
      },
    }),
    updateAccountFollowing: builder.mutation({
      query: ({ accountId, followingId }) => ({
        url: `/api/community/attendee/updateFollowing`,
        method: 'PATCH',
        body: { accountId, followingId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(
        { accountId, followingId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setCommunityFollowing(data.followingData.following));
          dispatch(
            setCommunityFollowingGroups(data.followingData.followingGroups)
          );
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/updateAccountFollowing',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    updateCommunityFollowing: builder.mutation({
      query: ({ accountId, newFollowing, newFollowingGroups }) => ({
        url: `/api/community/attendee/updateCommunityFollowing`,
        method: 'PATCH',
        body: { accountId, newFollowing, newFollowingGroups },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setCommunityFollowing(data.followingData.following));
          dispatch(
            setCommunityFollowingGroups(data.followingData.followingGroups)
          );
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/updateCommunityFollowing',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    blockAccount: builder.mutation({
      query: ({ accountId, blockId }) => ({
        url: `/api/community/account/blockAccount`,
        method: 'PATCH',
        body: { accountId, blockId },
      }),

      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/blockAccount',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    requestContact: builder.mutation({
      query: (body) => ({
        url: `/api/community/account/requestContact`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/requestContact',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    acceptContactRequest: builder.mutation({
      query: (body) => ({
        url: `/api/community/account/acceptContactRequest`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/acceptRequestContact',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeContact: builder.mutation({
      query: (body) => ({
        url: `/api/community/account/removeContact`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'accountCommunityApiSlice/removeContact',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    communityAccountSearch: builder.query({
      query: ({ accountId, searchName, unsavedIdsArr }) => {
        return {
          url: '/api/community/search',
          params: { accountId, searchName, unsavedIdsArr },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AccountApiSlice/organizerAccountSearch',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  useUpdateAccountFollowingMutation,
  useLazyGetAccountCommunityQuery,
  useAcceptContactRequestMutation,
  useRequestContactMutation,
  useBlockAccountMutation,
  useRemoveContactMutation,
  useLazyGetAccountCommunityListsQuery,
  useLazyCommunityAccountSearchQuery,
  useUpdateCommunityFollowingMutation,
} = accountCommunityApiSlice;
