import { useEffect, useRef, useState } from 'react';

//hooks
import useApiStatus from '../../../../../context/useApiStatus';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';
import useSettings from '../../../../../context/useSettings';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useGenericModal from '../../../../../context/useGenericModal';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import RoomContentDesktop from './RoomContentDesktop';
import RoomContentMobile from './RoomContentMobile';
import TranslationTextBox from './TranslationTextBox';
import TranslationsRoomGuideModal from '../../../../../components/Modal/ModalComponents/TranslationsModals/TranslationsRoomGuideModal';
import AttendeeStagingRoom from './AttendeeStagingRoom';
import TranslationSpeakerModal from '../../../../../components/Modal/ModalComponents/TranslationsModals/TranslationSpeakerModal';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const TranslationRoom = ({ isHost, setPage }) => {
  //hooks
  const {
    sendTranslationData,
    closeTranslationRoomSocket,
    occupantsNum,
    translationData,
    isHostPresent,
    setTranslationData,
    roomCode,
    roomLanguages,
    keywordsList,
    currentSpeaker,
    speakersData,
    changeSpeaker,
    changeHostTextLanguage,
    hostTranslationData,
    translationRoomSocketStatus,
    translationLang,
    setTranslationLang,
    subPage,
    setSubPage,
    userSessionIdTranslations,
  } = useTranslationRoomSocket();
  const { handleWarning, apiStatus } = useApiStatus();
  const { width } = useSettings();
  const { setGenericModal, setCloseModalFocusId } = useGenericModal();
  const { Generic } = useLanguageComponents();
  const { TranslationLanguageOpts } = useLanguageData();

  //component state
  const [isInitialized, setIsInitialized] = useState(false);
  const [hostTranslationDataRestored, setHostTranslationDataRestored] =
    useState(false);
  const [speechLang, setSpeechLang] = useState('select');
  const [voiceOption, setVoiceOption] = useState('select');
  const [microphoneAccess, setMicrophoneAccess] = useState(false);

  //Text
  const [originalInputText, setOriginalInputText] = useState([]);

  //Audio
  const [isListening, setIsListening] = useState(false);
  const [audioContentQueue, setAudioContentQueue] = useState([]);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [volumeOn, setVolumeOn] = useState(true);

  const [speechRecognitionWarning, setSpeechRecognitionWarning] =
    useState(false);

  //data
  const [roomLanguageOpts, setRoomLanguageOpts] = useState();
  //nav

  //safari
  const [SRGS, setSRGS] = useState();

  //variables
  const recognitionRef = useRef(null);
  const translationTextBoxRef = useRef();
  const audioElementRef = useRef();
  const effectRan = useRef(false);

  //Initialize
  useEffect(() => {
    setIsInitialized(true);
    ScrollTo('', 'auto', true);
    setAudioContentQueue([]);
  }, []);

  //NAV
  useEffect(() => {
    if (translationRoomSocketStatus === 'closed') {
      setPage(1);
    }
  }, [translationRoomSocketStatus]);

  //UI
  useEffect(() => {
    if (apiStatus?.length === 0 && speechRecognitionWarning) {
      setSpeechRecognitionWarning(false);
    }
  }, [apiStatus]); //not allowed warning

  function handleOpenSpeakerDetails() {
    setCloseModalFocusId('openSpeakerDetailsBtn');
    setGenericModal(
      <TranslationSpeakerModal currentSpeaker={currentSpeaker} />
    );
  } //open speaker details modal

  //Language
  useEffect(() => {
    if (roomLanguages?.length > 0) {
      const translationOpts = TranslationLanguageOpts;
      const filteredOpts = Object.keys(translationOpts)
        .filter((key) => roomLanguages.includes(key))
        .reduce((obj, key) => {
          obj[key] = translationOpts[key];
          return obj;
        }, {});
      setRoomLanguageOpts(filteredOpts);
    } else {
      setRoomLanguageOpts();
    }
  }, [roomLanguages]); //receives roomLanguages set by host and provides them as options to the attendee before joining the room

  useEffect(() => {
    if (isHost && translationLang !== 'select' && translationLang) {
      changeHostTextLanguage(translationLang);
    }
  }, [translationLang]); //connects to web socket, provides translationLang for text translation

  useEffect(() => {
    if (currentSpeaker?.languageOpt) {
      setSpeechLang(currentSpeaker?.languageOpt);
    } else {
      setSpeechLang('select');
    }
  }, [currentSpeaker]); //sets language for speech recognition API

  //Nav
  function handleExit() {
    stopRecognition();
    setSubPage(0);
    setPage(1);
    closeTranslationRoomSocket();
  }

  function handleGuide() {
    setGenericModal(<TranslationsRoomGuideModal />);
  }

  //TRANSLATION TEXT
  useEffect(() => {
    if (translationData?.originalText && isInitialized) {
      if (translationLang !== 'select' && translationLang) {
        if (translationTextBoxRef?.current) {
          translationTextBoxRef.current.insertAdjacentHTML(
            'beforeend',
            `<li tabIndex="0" name="translation-li" id="translation-li-${
              translationData?.length
            }">
              <p>${
                translationLang === translationData.speakerLang
                  ? translationData.originalText
                  : translationData.translatedText
              }</p>
            </li> `
          );

          translationTextBoxRef.current.scrollTop =
            translationTextBoxRef.current.scrollHeight;
        }

        if (
          translationLang !== 'select' &&
          translationLang !== translationData?.speakerLang
        ) {
          if (translationData?.audioContent) {
            addAudioToQueue(translationData.audioContent);
          }
        }
        setTranslationData([]);
      }
    }
  }, [translationData]); //Add text to textbox + audio is added to queue

  //HOST TEXT BOX
  useEffect(() => {
    if (!hostTranslationDataRestored && effectRan.current === false) {
      effectRan.current = true;
      if (hostTranslationData?.length > 0) {
        hostTranslationData.forEach((translationObj) => {
          handleHostTranslationData(translationObj);
        });
      }

      if (translationTextBoxRef?.current) {
        translationTextBoxRef.current.scrollTop =
          translationTextBoxRef.current.scrollHeight;
      }

      setHostTranslationDataRestored(true);
    }
  }, [hostTranslationData]); // initialize restoration of host text, hostTranslationData

  useEffect(() => {
    if (isHost && hostTranslationData?.length > 0) {
      let numberOfLiElements = 0;

      if (translationTextBoxRef.current) {
        numberOfLiElements =
          translationTextBoxRef.current.querySelectorAll('li').length;
      }

      if (
        numberOfLiElements < hostTranslationData?.length &&
        hostTranslationDataRestored
      ) {
        const lastTranslationObj =
          hostTranslationData[hostTranslationData.length - 1];

        handleHostTranslationData(lastTranslationObj);
      }
    }
  }, [hostTranslationData, translationLang]); //adds text for host restore as speakers talks

  function handleHostTranslationData(translationObj) {
    const translationText = Object.values(translationObj.translationObj);

    translationTextBoxRef.current.insertAdjacentHTML(
      'beforeend',
      `<li tabIndex="0" name="translation-li" id="translation-li-${translationData?.length}">
              <p>${translationText}</p>
            </li> `
    );

    translationTextBoxRef.current.scrollTop =
      translationTextBoxRef.current.scrollHeight;
  }

  //MICROPHONE
  useEffect(() => {
    if (isHost) {
      const checkMicrophonePermission = async () => {
        try {
          const permissionStatus = await navigator.permissions.query({
            name: 'microphone',
          });
          if (permissionStatus.state === 'granted') {
            if (!microphoneAccess) {
              setMicrophoneAccess(true);
            }
          } else {
            if (microphoneAccess) {
              setMicrophoneAccess(false);
            }
          }
        } catch (error) {
          console.error('Error checking microphone permission:', error);
        }
      };

      checkMicrophonePermission();

      const permissionInterval = setInterval(checkMicrophonePermission, 1000);

      return () => {
        clearInterval(permissionInterval);
      };
    }
  }, [isHost]);

  function promptMicrophonePermission() {
    const constraints = {
      audio: true,
      video: false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log('Microphone access granted.');
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((error) => {
        console.error('Microphone access denied:', error);
      });
  }

  //AUDIO
  useEffect(() => {
    if (!isHost) {
      //Volume off - remove audio
      if (!volumeOn && audioContentQueue?.length > 0) {
        return setAudioContentQueue([]);
      }

      //audio in queue & no audio is playing so play audio
      if (audioContentQueue?.length > 0 && !isAudioPlaying) {
        setIsAudioPlaying(true);

        // Extract the oldest entry from the queue
        const oldestEntry = audioContentQueue[0];

        audioElementRef.current.src = `data:audio/mp3;base64,${oldestEntry}`;
        audioElementRef.current.play();
      }

      //audio
    }
  }, [audioContentQueue, isAudioPlaying]);

  const handleAudioEnded = () => {
    // Remove the oldest entry from the queue after it finishes playing
    setAudioContentQueue((prevQueue) => {
      if (prevQueue?.length > 1) {
        setIsAudioPlaying(false);
        return prevQueue.slice(1);
      } else {
        setIsAudioPlaying(false);
        return [];
      }
    });
  };

  const addAudioToQueue = (audioBase64) => {
    setAudioContentQueue((prevQueue) => [...(prevQueue || []), audioBase64]);
  };

  //SPEECH RECOGNITION FUNCTIONS
  useEffect(() => {
    if (isHost && isListening && currentSpeaker?.languageOpt) {
      if (recognitionRef.current === null) {
        startRecognition(speechLang, voiceOption);
      }
    } else {
      stopRecognition();
    }
  }, [isListening, recognitionRef.current, currentSpeaker]);

  //update recognitionRef when properties change
  useEffect(() => {
    if (isHost && recognitionRef.current) {
      recognitionRef.current.lang = speechLang;
    }
    if ((!speechLang || speechLang === 'select') && voiceOption !== 'select') {
      setVoiceOption('select');
    }
  }, [speechLang]);

  useEffect(() => {
    if (isHost && keywordsList?.length > 0) {
      const grammarHeader = '#JSGF V1.0; grammar keywords;';
      let grammarBody = 'public <keyword> = ';
      const formattedKeywords = keywordsList
        .map((keyword) => {
          return keyword;
        })
        .join(' | ');
      const grammar = `${grammarHeader} ${grammarBody} ${formattedKeywords};`;
      setSRGS(grammar);
    }
  }, [keywordsList, isHost]); // web browser autocorrect

  useEffect(() => {
    if (recognitionRef.current !== null) {
      recognitionRef.current.lang = speechLang;
      recognitionRef.current.onresult = (event) =>
        handleRecognitionResult(event, speechLang, voiceOption);
    }
  }, [speechLang, voiceOption, isListening]);

  function toggleListening() {
    if (isListening) return setIsListening(false); //add first to ensure user can stop recording

    if (!microphoneAccess) {
      if (!speechRecognitionWarning) {
        setSpeechRecognitionWarning(true);
        return handleWarning({
          message: 'microphoneNotAllowed',
          origin: 'TranslationRoom/startRecognition',
          id: Date.now(),
        });
      }
    }

    if (translationRoomSocketStatus !== 'connected') {
      return handleWarning({
        message: 'waitForConnection',
        origin: 'TranslationRoom/toggleListening',
        id: Date.now(),
      });
    }
    if (!currentSpeaker?.id) {
      return handleWarning({
        message: 'currentSpeakerRequired',
        origin: 'TranslationRoom/toggleListening',
        id: Date.now(),
      });
    } else if (!translationLang || translationLang === 'select') {
      return handleWarning({
        message: 'textTranslationRequired',
        origin: 'TranslationRoom/toggleListening',
        id: Date.now(),
      });
    } else {
      return setIsListening((prev) => !prev);
    }
  }

  function startRecognition(speechLang, voiceOption) {
    if (!microphoneAccess) {
      if (!speechRecognitionWarning) {
        setSpeechRecognitionWarning(true);
        return handleWarning({
          message: 'microphoneNotAllowed',
          origin: 'TranslationRoom/startRecognition',
          id: Date.now(),
        });
      }
    }

    if (!recognitionRef.current || recognitionRef === null) {
      if (
        'SpeechRecognition' in window ||
        'webkitSpeechRecognition' in window
      ) {
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        const SpeechGrammarList =
          window.SpeechGrammarList || window.webkitSpeechGrammarList;

        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = false;
        recognitionRef.current.lang = `${speechLang}`;

        if (SRGS) {
          // console.log('SRGS', SRGS);
          let speechRecognitionList = new SpeechGrammarList();
          speechRecognitionList.addFromString(SRGS, 1);
          recognitionRef.current.grammars = speechRecognitionList;
        }

        recognitionRef.current.onend = () => {
          if (isListening && recognitionRef.current !== null) {
            recognitionRef.current.start();
          } else {
            setIsListening(false);
          }
        };

        recognitionRef.current.onerror = (error) => {
          if (
            process.env.REACT_APP_ENV === 'development' &&
            error.error !== 'no-speech'
          ) {
            console.error('TranslationRoom Error:', error);
          }
        };

        recognitionRef.current.onresult = (event) =>
          handleRecognitionResult(event, speechLang, voiceOption);
      }

      if (recognitionRef.current !== null) {
        recognitionRef.current.start();
      }
    }
  }

  function stopRecognition() {
    if (isHost && recognitionRef.current !== null) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
  }

  async function handleRecognitionResult(event) {
    let finalTranscripts = '';

    for (let i = event.resultIndex; i < event.results.length; i++) {
      let transcript = event.results[i][0].transcript;

      transcript.replace('\n', '<br>');

      if (event.results[i].isFinal) {
        finalTranscripts += transcript;
      }
    }
    //web socket
    const data = finalTranscripts;

    sendTranslationData(data);
    setOriginalInputText((prevText) => [...prevText, finalTranscripts]);
  }

  return (
    <>
      {subPage === 0 && !isHost && (
        <AttendeeStagingRoom
          roomCode={roomCode}
          translationRoomSocketStatus={translationRoomSocketStatus}
          handleExit={handleExit}
          setTranslationLang={setTranslationLang}
          translationLang={translationLang}
          isHostPresent={isHostPresent}
          roomLanguageOpts={roomLanguageOpts}
        />
      )}

      {(subPage === 1 || isHost) && (
        <div className="translations-room">
          {width > 1280 ? (
            <RoomContentDesktop
              changeSpeaker={changeSpeaker}
              currentSpeaker={currentSpeaker}
              Generic={Generic}
              handleExit={handleExit}
              handleGuide={handleGuide}
              handleOpenSpeakerDetails={handleOpenSpeakerDetails}
              isHost={isHost}
              isHostPresent={isHostPresent}
              isListening={isListening}
              microphoneAccess={microphoneAccess}
              occupantsNum={occupantsNum}
              promptMicrophonePermission={promptMicrophonePermission}
              roomCode={roomCode}
              roomLanguageOpts={roomLanguageOpts}
              setAudioContentQueue={setAudioContentQueue}
              setTranslationLang={setTranslationLang}
              speakersData={speakersData}
              toggleListening={toggleListening}
              translationLang={translationLang}
              translationRoomSocketStatus={translationRoomSocketStatus}
            />
          ) : (
            <RoomContentMobile
              changeSpeaker={changeSpeaker}
              currentSpeaker={currentSpeaker}
              Generic={Generic}
              handleGuide={handleGuide}
              handleOpenSpeakerDetails={handleOpenSpeakerDetails}
              isHost={isHost}
              isHostPresent={isHostPresent}
              isListening={isListening}
              microphoneAccess={microphoneAccess}
              occupantsNum={occupantsNum}
              promptMicrophonePermission={promptMicrophonePermission}
              roomCode={roomCode}
              roomLanguageOpts={roomLanguageOpts}
              setTranslationLang={setTranslationLang}
              speakersData={speakersData}
              toggleListening={toggleListening}
              translationLang={translationLang}
              translationRoomSocketStatus={translationRoomSocketStatus}
            />
          )}

          <TranslationTextBox
            isHost={isHost}
            translationTextBoxRef={translationTextBoxRef}
            width={width}
            volumeOn={volumeOn}
            setVolumeOn={setVolumeOn}
            roomCode={roomCode}
            translationLang={translationLang}
            isListening={isListening}
            audioElementRef={audioElementRef}
            handleAudioEnded={handleAudioEnded}
            setAudioContentQueue={setAudioContentQueue}
            handleExit={handleExit}
            hostTranslationData={hostTranslationData}
            userSessionIdTranslations={userSessionIdTranslations}
            speakersData={speakersData}
          />
        </div>
      )}
    </>
  );
};

export default TranslationRoom;
