import { useEffect, useState } from 'react';

//hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageData from '../../../../../language/useLanguageData';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//components
import Selections from '../../../../../components/Selections/Selections';
import CommunityListAccountPersonal from './CommunityListAccountPersonal';

//utils

const CommunityAccounts = ({
  accountCommunityPopulated,
  handleRefreshAccountCommunityLists,
}) => {
  //hooks
  const { Account } = useLanguageAccount();
  const { CommunityViewOpts } = useLanguageData();
  const {
    accountSpeakerSpeakerModeActive,
    accountOrganizerOrganizerModeActive,
    accountAttendeeModeActive,
  } = useAccountReduxHandlers();

  //state
  const [viewCommunitySelection, setViewCommunitySelection] = useState();
  const [communityOpts, setCommunityOpts] = useState({});

  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [blocked, setBlocked] = useState([]);

  useEffect(() => {
    const newAccountCommunityPopulated =
      accountCommunityPopulated?.accountCommunityPopulated?.community;

    //following
    let newFollowingArr = [];

    newAccountCommunityPopulated?.following?.map((followingAcct) =>
      newFollowingArr.push(followingAcct)
    );
    newAccountCommunityPopulated?.followingGroups?.map((group) => {
      group?.following?.map((followingAcct) =>
        newFollowingArr.push(followingAcct)
      );
    });
    setFollowing(newFollowingArr);

    //followers
    let newFollowersArr = [];

    newAccountCommunityPopulated?.followers?.map((acct) =>
      newFollowersArr.push(acct)
    );
    setFollowers(newFollowersArr);

    //contacts + contacts requested
    let newContactsArr = [];

    newAccountCommunityPopulated?.contacts?.map((acct) =>
      newContactsArr.push(acct)
    );
    newAccountCommunityPopulated?.contactsRequested?.map((acct) =>
      newContactsArr.push(acct)
    );
    setContacts(newContactsArr);

    //blocked
    let newBlockedArr = [];
    newAccountCommunityPopulated?.blockedAccounts?.map((acct) =>
      newBlockedArr.push(acct)
    );

    setBlocked(newBlockedArr);
  }, [accountCommunityPopulated]);

  useEffect(() => {
    let newCommunityOpts = {};

    newCommunityOpts.blockedAccounts = `${CommunityViewOpts.blockedAccounts} (${blocked?.length})`;

    if (
      accountSpeakerSpeakerModeActive ||
      accountOrganizerOrganizerModeActive
    ) {
      newCommunityOpts.contacts = `${CommunityViewOpts.contacts} (${contacts?.length})`;
    }

    if (
      accountSpeakerSpeakerModeActive ||
      accountOrganizerOrganizerModeActive
    ) {
      newCommunityOpts.followers = `${CommunityViewOpts.followers} (${followers?.length})`;
    }

    if (accountAttendeeModeActive) {
      newCommunityOpts.following = `${CommunityViewOpts.following} (${following?.length})`;
    }

    setCommunityOpts(newCommunityOpts);
  }, [
    accountSpeakerSpeakerModeActive,
    following,
    followers,
    contacts,
    blocked,
  ]); //sets community selections list

  return (
    <div className="section">
      <p
        className="banners__notice align-center full-width fs16 mrg-tb24"
        tabIndex="0"
      >
        {Account.community.followersNotPublic}
      </p>

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="communityAccountsLabel"
      >
        {Account.community.communityAccounts}
      </label>
      <div className="h24" />

      <Selections
        id={'viewCommunitySelections'}
        options={communityOpts}
        handleSelection={setViewCommunitySelection}
        customWrapper={'mrg-t12'}
        viewHorizontal={true}
      />

      {viewCommunitySelection === 'contacts' ? (
        <>
          <ul className="community__container">
            {contacts?.map((acct, index) => {
              return (
                <li key={`communityContact-${index}`}>
                  <CommunityListAccountPersonal
                    profile={acct}
                    customProfileInteractionHandler={
                      handleRefreshAccountCommunityLists
                    }
                  />
                </li>
              );
            })}
          </ul>
          <div className="h36" />
        </>
      ) : viewCommunitySelection === 'followers' ? (
        <>
          <ul className="community__container">
            {followers?.map((acct, index) => {
              return (
                <li key={`communityFollowers-${index}`}>
                  <CommunityListAccountPersonal
                    profile={acct}
                    customProfileInteractionHandler={
                      handleRefreshAccountCommunityLists
                    }
                  />
                </li>
              );
            })}
          </ul>
          <div className="h36" />
        </>
      ) : viewCommunitySelection === 'following' ? (
        <>
          <ul className="community__container">
            {following?.map((acct, index) => {
              return (
                <li key={`communityFollowing-${index}`}>
                  <CommunityListAccountPersonal
                    profile={acct}
                    customProfileInteractionHandler={
                      handleRefreshAccountCommunityLists
                    }
                  />
                </li>
              );
            })}
          </ul>
          <div className="h36" />
        </>
      ) : (
        viewCommunitySelection === 'blockedAccounts' && (
          <>
            <ul className="community__container">
              {blocked?.map((acct, index) => {
                return (
                  <li key={`communityBlocked-${index}`}>
                    <CommunityListAccountPersonal
                      profile={acct}
                      customProfileInteractionHandler={
                        handleRefreshAccountCommunityLists
                      }
                    />
                  </li>
                );
              })}
            </ul>
            <div className="h36" />
          </>
        )
      )}

      <div className="h48" />
    </div>
  );
};

export default CommunityAccounts;
