import { useState } from 'react';

//Hooks
import useApiStatus from '../../../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useUpdateAccountSpeakerMutation } from '../../accountApiSlice';

//Components
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';
import SpeakerSignUp from './SpeakerSignUp';
import SpeakerManager from './SpeakerManager';

//Utility
import { getCountryISO } from '../../../../../utils/spatial/getCountryISO';

const SpeakerContent = ({
  setSpeakerSignUpIsLoading,
  speakerSignUpIsLoading,
}) => {
  //Hooks
  const {
    accountSpeakerSignUpComplete,
    accountId,
    accountSpeakerSpeakerModeActive,
  } = useAccountReduxHandlers();
  const { handleError } = useApiStatus();

  //api
  const [updateSpeaker, { isLoading: updateSpeakerIsLoading }] =
    useUpdateAccountSpeakerMutation();

  //state
  const [speakerLoadingSaveBtnUI, setSpeakerLoadingSaveBtnUI] = useState();

  async function saveAccountSpeakerUpdate(updateData, updateCategory) {
    //need to use different save function because passing entire speaker object, as per the normal saveAccount function, becomes complex, ex. saving with the speaker coords becomes messy when it hasnt changed but its still being passed (need to reverse with new coords but not old.)
    if (!updateSpeakerIsLoading) {
      try {
        setSpeakerLoadingSaveBtnUI(updateCategory);
        let newData = updateData;

        if (updateCategory === 'speakerGeoPositionCoordinates') {
          const newCoords = updateData?.speakerGeoPosition?.coordinates;
          const newSpeakerCountryISO = await getCountryISO(newCoords);
          if (!newSpeakerCountryISO) throw new Error();
          newData = {
            ...updateData,
            speakerCountryISO: newSpeakerCountryISO,
          };
        }

        await updateSpeaker({
          accountId,
          newData,
          updateCategory,
        });

        setSpeakerLoadingSaveBtnUI();
      } catch (error) {
        setSpeakerLoadingSaveBtnUI();
        handleError({
          error,
          id: Date.now(),
          origin: 'SpeakerContent.js/saveAccountSpeakerChange',
        });
      }
    }
  }

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <div className={`section min-full-height `}>
        {speakerSignUpIsLoading ? (
          <LoadingScreenContent
            numberOfLoadChecks={1}
            loadCheck1={true}
            setLoadComplete={() => setSpeakerSignUpIsLoading(false)}
          />
        ) : !accountSpeakerSpeakerModeActive &&
          !accountSpeakerSignUpComplete ? (
          <SpeakerSignUp
            setSpeakerSignUpIsLoading={setSpeakerSignUpIsLoading}
          />
        ) : (
          <SpeakerManager
            saveAccountSpeakerUpdate={saveAccountSpeakerUpdate}
            speakerLoadingSaveBtnUI={speakerLoadingSaveBtnUI}
          />
        )}
      </div>
      <div className="h48" />
    </div>
  );
};

export default SpeakerContent;
