import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//hooks
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useApiStatus from '../../../../../../context/useApiStatus';
import useSanitize from '../../../../../../hooks/useSanitize';

//components
import TextArea from '../../../../../../components/TextArea/TextArea';
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//utils
import {
  checkmark_green,
  img_language,
  trash,
} from '../../../../../../assets/icons';
import { areArraysEqualIgnoreOrder } from '../../../../../../utils/logic/arrayEqualIgnoreOrder';

const SpeakerLanguages = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { Generic } = useLanguageComponents();
  const { handleWarning } = useApiStatus();
  const { stringCheck } = useSanitize();
  const { LanguageOpts } = useLanguageData();
  const { SpeakerLanguages } = useLanguageAccount();
  const {
    accountSpeakerLanguages,
    accountSpeakerLanguagesNotListed,
    handleAccountSpeakerSetLanguages,
    handleAccountSpeakerSetLanguagesNotListed,
    accountSpeakerSignUpComplete,
  } = useAccountReduxHandlers();

  //state
  const [saveValid, setSaveValid] = useState(false);
  const [newSpeakerLanguages, setNewSpeakerLanguages] = useState(
    accountSpeakerLanguages || []
  );
  const [newSpeakerLanguagesNotListed, setNewSpeakerLanguagesNotListed] =
    useState(accountSpeakerLanguagesNotListed || []);

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      if (newSpeakerLanguages.includes('notListed')) {
        if (
          newSpeakerLanguagesNotListed?.length === 0 ||
          !newSpeakerLanguagesNotListed
        ) {
          setNewSpeakerLanguagesNotListed([
            {
              id: uuidv4(),
              language: '',
            },
          ]);
        }
      } else if (
        !newSpeakerLanguages.includes('notListed') &&
        newSpeakerLanguagesNotListed?.length > 0
      ) {
        setNewSpeakerLanguagesNotListed([]);
      }
    } else {
      if (accountSpeakerLanguages.includes('notListed')) {
        if (
          accountSpeakerLanguagesNotListed?.length === 0 ||
          !accountSpeakerLanguagesNotListed
        ) {
          handleAccountSpeakerSetLanguagesNotListed([
            {
              id: uuidv4(),
              language: '',
            },
          ]);
        }
      } else if (
        !accountSpeakerLanguages.includes('notListed') &&
        accountSpeakerLanguagesNotListed?.length > 0
      ) {
        handleAccountSpeakerSetLanguagesNotListed([]);
      }
    }
  }, [newSpeakerLanguages, accountSpeakerLanguages]); //sets textarea component for registered

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      const speakerLanguagesAreEqual = areArraysEqualIgnoreOrder(
        accountSpeakerLanguages,
        newSpeakerLanguages
      );
      const speakerLanguageNotListedAreEqual = areArraysEqualIgnoreOrder(
        accountSpeakerLanguagesNotListed,
        newSpeakerLanguagesNotListed
      );

      if (!speakerLanguagesAreEqual || !speakerLanguageNotListedAreEqual) {
        if (newSpeakerLanguages.includes('select')) {
          return setSaveValid(false);
        }
        if (
          newSpeakerLanguages?.length === 0 &&
          newSpeakerLanguagesNotListed?.length === 0
        ) {
          return setSaveValid(false);
        }

        if (newSpeakerLanguagesNotListed?.length > 0) {
          for (const notListedLang of newSpeakerLanguagesNotListed) {
            const typeNotString = stringCheck(notListedLang.language);
            if (typeNotString) {
              return setSaveValid(false);
            }
          }
        }
        setSaveValid(true);
      } else {
        setSaveValid(false);
      }
    }
  }, [
    accountSpeakerLanguages,
    accountSpeakerLanguagesNotListed,
    newSpeakerLanguages,
    newSpeakerLanguagesNotListed,
  ]); //save validation for registered speaker

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      const speakerLanguagesAreEqual = areArraysEqualIgnoreOrder(
        accountSpeakerLanguages,
        newSpeakerLanguages
      );
      const speakerLanguageNotListedAreEqual = areArraysEqualIgnoreOrder(
        accountSpeakerLanguagesNotListed,
        newSpeakerLanguagesNotListed
      );

      if (!speakerLanguagesAreEqual) {
        setNewSpeakerLanguages(accountSpeakerLanguages);
      }
      if (!speakerLanguageNotListedAreEqual) {
        setNewSpeakerLanguagesNotListed(accountSpeakerLanguagesNotListed);
      }
    }
  }, [accountSpeakerLanguages, accountSpeakerLanguagesNotListed]); //set new values after save

  function handleNotListedLanguages(text, langId) {
    if (accountSpeakerSignUpComplete) {
      let newNotListedLanguages = newSpeakerLanguagesNotListed.map((lang) => {
        if (lang.id === langId) {
          return { ...lang, language: text?.trim() };
        }
        return lang;
      });
      setNewSpeakerLanguagesNotListed(newNotListedLanguages);
    } else {
      let newNotListedLanguages = accountSpeakerLanguagesNotListed.map(
        (lang) => {
          if (lang.id === langId) {
            return { ...lang, language: text?.trim() };
          }
          return lang;
        }
      );
      handleAccountSpeakerSetLanguagesNotListed(newNotListedLanguages);
    }
  }

  function addNotListedLanguage() {
    if (accountSpeakerSignUpComplete) {
      if (newSpeakerLanguagesNotListed?.length > 5)
        return handleWarning({
          message: 'limitReached',
          origin: 'SpeakerLanguages/addNotListedLanguage',
          id: Date.now(),
        });

      let newArr = [...newSpeakerLanguagesNotListed];
      newArr.push({ id: uuidv4(), language: '' });
      setNewSpeakerLanguagesNotListed(newArr);
    } else {
      if (accountSpeakerLanguagesNotListed?.length > 5)
        return handleWarning({
          message: 'limitReached',
          origin: 'SpeakerLanguages/addNotListedLanguage',
          id: Date.now(),
        });

      let newArr = [...accountSpeakerLanguagesNotListed];
      newArr.push({ id: uuidv4(), language: '' });
      handleAccountSpeakerSetLanguagesNotListed(newArr);
    }
  }

  function handleRemoveNotListedLanguage(id) {
    if (accountSpeakerSignUpComplete) {
      let newNotListedLanguages = newSpeakerLanguagesNotListed.filter(
        (lang) => lang.id !== id
      );
      setNewSpeakerLanguagesNotListed(newNotListedLanguages);
      if (newNotListedLanguages?.length === 0) {
        let newArr = [...accountSpeakerLanguages];

        newArr = newArr.filter((lang) => lang !== 'notListed');
        setNewSpeakerLanguages(newArr);
      }
    } else {
      let newNotListedLanguages = accountSpeakerLanguagesNotListed.filter(
        (lang) => lang.id !== id
      );
      handleAccountSpeakerSetLanguagesNotListed(newNotListedLanguages);
      if (newNotListedLanguages?.length === 0) {
        let newArr = [...accountSpeakerLanguages];

        newArr = newArr.filter((lang) => lang !== 'notListed');
        handleAccountSpeakerSetLanguages(newArr);
      }
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="speakerLanguagesLabel"
        tabIndex="0"
      >
        {SpeakerLanguages.languages}
        <div className="flex-row mrg-auto-left">
          {accountSpeakerSignUpComplete ? (
            <>
              {(newSpeakerLanguages?.length >= 2 &&
                newSpeakerLanguages.includes('select')) ||
              (newSpeakerLanguagesNotListed?.length > 0 &&
                newSpeakerLanguagesNotListed.some(
                  (entry) => !entry.language
                )) ? (
                <p className="fs14 mrg-auto-left">{Generic.incomplete}</p>
              ) : (
                <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
              )}
            </>
          ) : (
            <>
              {(accountSpeakerLanguages?.length >= 2 &&
                accountSpeakerLanguages.includes('select')) ||
              (accountSpeakerLanguagesNotListed?.length > 0 &&
                accountSpeakerLanguagesNotListed.some(
                  (entry) => !entry.language
                )) ? (
                <p className="fs14 mrg-auto-left">{Generic.incomplete}</p>
              ) : (
                <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
              )}
            </>
          )}

          {accountSpeakerSignUpComplete ? (
            <>
              {newSpeakerLanguages?.length > 0 &&
                !newSpeakerLanguages.includes('select') &&
                (newSpeakerLanguagesNotListed?.length === 0 ||
                  (newSpeakerLanguagesNotListed?.length > 0 &&
                    newSpeakerLanguagesNotListed.every(
                      (entry) =>
                        entry.language && entry.language.trim().length > 0
                    ))) && (
                  <img
                    src={checkmark_green}
                    alt={Generic.checkmark}
                    className="mrg-l6"
                  />
                )}
            </>
          ) : (
            <>
              {accountSpeakerLanguages?.length > 0 &&
                !accountSpeakerLanguages.includes('select') &&
                (accountSpeakerLanguagesNotListed?.length === 0 ||
                  (accountSpeakerLanguagesNotListed?.length > 0 &&
                    accountSpeakerLanguagesNotListed.every(
                      (entry) =>
                        entry.language && entry.language.trim().length > 0
                    ))) && (
                  <img
                    src={checkmark_green}
                    alt={Generic.checkmark}
                    className="mrg-l6"
                  />
                )}
            </>
          )}
        </div>
      </label>

      <div className="h12" />

      <p className="description-text" tabIndex="0">
        {SpeakerLanguages.description}
      </p>

      <div className="mrg-auto-bottom mrg-t24">
        <SelectionsModal
          addButtonText={SpeakerLanguages.addLanguages}
          DataOpts={LanguageOpts}
          fullWidth={true}
          iconModal={img_language}
          iconModalClass={'filter-theme'}
          iconOff={true}
          modalTitle={SpeakerLanguages.languages}
          selectionState={
            accountSpeakerSignUpComplete
              ? newSpeakerLanguages
              : accountSpeakerLanguages
          }
          setSelectionState={
            accountSpeakerSignUpComplete
              ? setNewSpeakerLanguages
              : handleAccountSpeakerSetLanguages
          }
          subTextOn={false}
          id={'searchLanguagesSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />
      </div>
      {accountSpeakerSignUpComplete ? (
        <>
          {newSpeakerLanguagesNotListed?.length > 0 && (
            <div className="mrg-t36">
              {newSpeakerLanguagesNotListed?.map((languageObj) => {
                return (
                  <div
                    className={`flex-row align-center ${
                      newSpeakerLanguagesNotListed?.length > 1 ? 'mrg-b24' : ''
                    }`}
                    key={languageObj.id}
                  >
                    <TextArea
                      limit={100}
                      id={languageObj.id}
                      labelText={`${SpeakerLanguages.language}`}
                      disableEnter={true}
                      customWidth={'100%'}
                      handleChange={(text) =>
                        handleNotListedLanguages(text, languageObj.id)
                      }
                      loadValue={languageObj.language}
                      loadTrigger={true}
                      onBlurHandlerOff={true}
                      ariaText={SpeakerLanguages.ariaNotListedLanguageTextInput}
                      hideWordCount={true}
                    />
                    <button
                      className="mrg-l24 min44 highlight-i-lgr align-center access-ob access-o6 "
                      onClick={() =>
                        handleRemoveNotListedLanguage(languageObj.id)
                      }
                    >
                      <img src={trash} alt="X" className="filter-gray" />
                    </button>
                  </div>
                );
              })}
              <ItemButton
                handler={addNotListedLanguage}
                text={SpeakerLanguages.addAnotherNonListedLanguage}
                fullWidth={true}
                customWrapper={'mrg-tb24'}
                unavailable={accountSpeakerLanguagesNotListed?.length > 5}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {accountSpeakerLanguagesNotListed?.length > 0 && (
            <div className="mrg-t36">
              {accountSpeakerLanguagesNotListed?.map((languageObj) => {
                return (
                  <div
                    className={`flex-row align-center ${
                      accountSpeakerLanguagesNotListed?.length > 1
                        ? 'mrg-b24'
                        : ''
                    }`}
                    key={languageObj.id}
                  >
                    <TextArea
                      limit={100}
                      id={languageObj.id}
                      labelText={`${SpeakerLanguages.language}`}
                      disableEnter={true}
                      customWidth={'100%'}
                      handleChange={(text) =>
                        handleNotListedLanguages(text, languageObj.id)
                      }
                      loadValue={languageObj.language}
                      loadTrigger={true}
                      onBlurHandlerOff={true}
                      ariaText={SpeakerLanguages.ariaNotListedLanguageTextInput}
                      hideWordCount={true}
                    />
                    <button
                      className="mrg-l24 min44 highlight-i-lgr align-center access-ob access-o6 "
                      onClick={() =>
                        handleRemoveNotListedLanguage(languageObj.id)
                      }
                    >
                      <img src={trash} alt="X" className="filter-gray" />
                    </button>
                  </div>
                );
              })}
              <ItemButton
                handler={addNotListedLanguage}
                text={SpeakerLanguages.addAnotherNonListedLanguage}
                fullWidth={true}
                customWrapper={'mrg-tb24'}
                unavailable={accountSpeakerLanguagesNotListed?.length > 5}
              />
            </div>
          )}
        </>
      )}

      {accountSpeakerSignUpComplete && (
        <>
          <div className="h24" />
          <SaveButton
            saveValid={saveValid}
            saveAriaLanguage={SpeakerLanguages.languages}
            handler={() =>
              saveAccountChange(
                {
                  speakerLanguages: newSpeakerLanguages,
                  speakerLanguagesNotListed: newSpeakerLanguagesNotListed,
                },
                'speakerLanguages'
              )
            }
            isLoading={loadingSaveBtnUI === 'speakerLanguages'}
          />
        </>
      )}
      <div className="h36" />
    </>
  );
};

export default SpeakerLanguages;
