import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../../language/useLanguageData';

//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//Utility
import { bigtag_nobg, checkmark_green } from '../../../../../../assets/icons';

const BigTags = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountBigTags: loadBigTags,
    accountAttendeeTags,
    accountAttendee,
  } = useAccountReduxHandlers();
  const { Generic } = useLanguageComponents();
  const { BigTagsOpts, BigTagsOptsWithSubText } = useLanguageData();

  //Component state
  const [bigTags, setBigTags] = useState(loadBigTags);
  const [bigTagsSaveValid, setBigTagsSaveValid] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadBigTags) {
      setBigTags(loadBigTags);
    }
  }, [loadBigTags]);

  //Component functions
  useEffect(() => {
    const sameContents =
      bigTags?.length === loadBigTags?.length &&
      bigTags?.every((value, index) => value === loadBigTags[index]);
    if (!sameContents && bigTags?.length > 0) {
      setBigTagsSaveValid(true);
    } else {
      setBigTagsSaveValid(false);
    }
  }, [bigTags, loadBigTags]);

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray flex-row align-center full-width"
          tabIndex="0"
          id="bigTagsLabel"
        >
          {Account.attendee.bigTags}

          <div className="flex-row mrg-auto-left">
            <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

            {bigTags?.length > 0 && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>

        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={Account.attendee.ariaBigTagsInputInstructions}
        >
          {Account.attendee.bigTagsDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={`${Generic.add} ${Generic.bigTags}`}
          DataOpts={BigTagsOptsWithSubText}
          DataOptsSubTextTranslations={BigTagsOpts}
          fullWidth={true}
          iconModal={bigtag_nobg}
          iconOff={true}
          limit={20}
          modalTitle={Generic.bigTags}
          selectionState={bigTags}
          setSelectionState={setBigTags}
          subTextOn={true}
          id={'bigTagSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />

        <div className="h24" />
        <SaveButton
          saveValid={bigTagsSaveValid}
          handler={() =>
            saveAccountChange(
              {
                attendee: {
                  ...accountAttendee,
                  tags: {
                    ...accountAttendeeTags,
                    bigTags,
                  },
                },
              },
              'bigTags'
            )
          }
          saveAriaLanguage={Account.attendee.bigTags}
          isLoading={loadingSaveBtnUI === 'bigTags'}
        />
        <div className="h36" />
      </div>
    </>
  );
};

export default BigTags;
