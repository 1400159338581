import { useEffect, useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import TextArea from '../../../../../components/TextArea/TextArea';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import { return_curved_arrow } from '../../../../../assets/icons';
import { image_lockscreen } from '../../../../../assets/images';

const CreateRoomPassword = ({ setPage }) => {
  //hooks
  const {
    defaultAttendeePassword,
    attendeeJoinRoomPassword,
    setAttendeeJoinRoomPassword,
    joinRoomPasswordToggle,
    setJoinRoomPasswordToggle,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    closeTranslationRoomSocket,
  } = useTranslationRoomSocket();
  const { TranslationCreateRoomPassword } = useLanguageTranslationRoom();
  //state

  //UI
  const [renderSpinner, setRenderSpinner] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  useEffect(() => {
    if (joinRoomPasswordToggle && defaultAttendeePassword?.length > 0) {
      setAttendeeJoinRoomPassword(defaultAttendeePassword);
    }
  }, [joinRoomPasswordToggle]);

  return (
    <div
      className={`translations-selection__container ${
        !imgLoaded ? 'remove' : ''
      } `}
    >
      <img
        src={image_lockscreen}
        alt="img"
        style={{ height: '200px' }}
        className="svg mrg-t24"
        aria-hidden={true}
        onLoad={() => setImgLoaded(true)}
      />
      <div className="translations-selections">
        <p
          className="fs21 fwsb mrg-tb24 text-center access-ob access-o6"
          tabIndex="0"
          aria-label={TranslationCreateRoomPassword.passwordOptions}
          id={'createRoom'}
        >
          {TranslationCreateRoomPassword.passwordOptions}
        </p>

        <ButtonSwitch
          id={'passwordToggle'}
          title={TranslationCreateRoomPassword.attendeesRequirePassword}
          handleSwitch={setJoinRoomPasswordToggle}
          loadValue={joinRoomPasswordToggle}
          loadTrigger={true}
          customWrapper={'mrg-auto-right'}
          horizontal={true}
        />
        {joinRoomPasswordToggle && (
          <TextArea
            handleChange={setAttendeeJoinRoomPassword}
            hideWordCount={true}
            labelText={TranslationCreateRoomPassword.password}
            id={'password'}
            limit={100}
            onBlurHandlerOff={true}
            customWrapper={'full-width mrg-t24'}
            loadValue={attendeeJoinRoomPassword}
            loadTrigger={true}
          />
        )}
        <ItemButton
          text={TranslationCreateRoomPassword.continue}
          handler={() => handleCreatePageChange(3)}
          customWrapper={'full-width mrg-t24'}
          iconOn={true}
          icon={return_curved_arrow}
          iconCustomClass={'reverse'}
          unavailable={joinRoomPasswordToggle && !attendeeJoinRoomPassword}
        />

        <div className="divider full-width mrg-t36 mrg-b12" />
        <div className="h24" />

        <ItemButton
          text={TranslationCreateRoomPassword.exit}
          handler={() => {
            closeTranslationRoomSocket();
            setPage(1);
          }}
          fullWidth={true}
        />
        <div className="h72" />
      </div>
    </div>
  );
};

export default CreateRoomPassword;
