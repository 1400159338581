//hooks

//components
import ProfilePersonalBase from '../../../../components/Profiles/ProfilePersonalBase';
import ProfilePersonalSkeleton from '../../../../components/Profiles/ProfilePersonalSkeleton';

//utils

const FindSpeakersSelection = ({ viewSpeaker, setViewSpeaker }) => {
  return (
    <div className="fs-selection__wrapper">
      {viewSpeaker ? (
        <>
          <ProfilePersonalBase
            profileData={viewSpeaker}
            bordersOn={true}
            customExitHandler={setViewSpeaker}
          />
        </>
      ) : (
        <ProfilePersonalSkeleton />
      )}
    </div>
  );
};

export default FindSpeakersSelection;
