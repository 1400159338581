import { createContext, useState, useEffect } from 'react';
import {
  selectApiStatus,
  removeReduxApiStatus,
} from '../features/main/apiStatus/apiStatusSlice';
import { useSelector, useDispatch } from 'react-redux';
//Hooks
import useLanguageApiStatus from '../language/useLanguageApiStatus';

//utils
import { axiosLimited } from '../axios/axios';

const ApiStatus = createContext({});

export const ApiStatusProvider = ({ children }) => {
  //Hooks
  const dispatch = useDispatch();
  const reduxApiStatus = useSelector(selectApiStatus);
  const { ErrorTranslations, SuccessTranslations, WarningTranslations } =
    useLanguageApiStatus();

  const [apiStatus, setApiStatus] = useState([]); //translated error message to pass to ApiStatusModal

  //Variables
  const timers = {};

  //Context functions
  function setModalTimer(apiStatusObj) {
    if (apiStatusObj.id) {
      // Set the timer for clearing the dismissed status
      timers[apiStatusObj.id] = setTimeout(() => {
        // Update the apiStatus state to mark the object as dismissed
        setApiStatus((prevApiStatus) =>
          prevApiStatus.map((item) =>
            item.id === apiStatusObj.id ? { ...item, dismissed: true } : item
          )
        );

        // Clear the timer
        clearTimeout(timers[apiStatusObj.id]);
      }, 3550);
    }
  } //sets dismissed property to true, to trigger child ApiStatusModal to call handleClearApiStatus + animations

  function handleClearApiStatus(apiStatusObjId) {
    // Clear the timer associated with apiStatusObjId
    if (timers[apiStatusObjId]) {
      clearTimeout(timers[apiStatusObjId]);
      delete timers[apiStatusObjId];
    }

    //set states
    dispatch(removeReduxApiStatus(apiStatusObjId));
    setApiStatus((prevApiStatus) =>
      prevApiStatus.filter((item) => item.id !== apiStatusObjId)
    );
  }

  useEffect(() => {
    if (reduxApiStatus) {
      reduxApiStatus.map((apiStatusObj) => {
        const isIdIncluded = apiStatus.some(
          (item) => item.id === apiStatusObj.id
        );
        if (!isIdIncluded) {
          if (apiStatusObj?.status === 'error') {
            handleError(apiStatusObj, true);
          } else {
            handleSuccess(apiStatusObj);
          }
        }
      });
    }

    /*eslint-disable-next-line*/
  }, [reduxApiStatus]);

  async function handleError(errorObj, fromRedux) {
    if (process.env.REACT_APP_ENV === 'development') {
      console.log(
        'Dev apiStatusProvider/handleError: handleError/origin:',
        errorObj?.origin,
        ', errorObj:',
        errorObj
      );
    }

    let errMessage;
    let errStatusCode;

    if (errorObj?.response) {
      errMessage = errorObj?.response?.data?.message;
      errStatusCode = errorObj?.response?.data?.statusCode;
    } else {
      if (fromRedux) {
        errMessage = errorObj?.message || errorObj?.error?.message;
        errStatusCode = errorObj?.error?.statusCode;
      } else {
        errMessage =
          errorObj?.message || errorObj?.error?.message || errorObj?.error;
        errStatusCode = errorObj?.statusCode;
      }
    }

    let translatedMessage;
    translatedMessage = ErrorTranslations[errMessage];

    if (!translatedMessage) {
      translatedMessage = ErrorTranslations['error'];
    }

    const newErrorObj = {
      message: translatedMessage,
      statusCode: errStatusCode,
      status: 'error',
      id: errorObj?.id || Date.now(),
    };

    setModalTimer(newErrorObj);

    setApiStatus((prevApiStatus) => [...prevApiStatus, newErrorObj]);

    //Create Client Log
    if (errorObj?.createClientLog) {
      function serializeError(error) {
        return {
          message: error.message,
          name: error.name,
          stack: error.stack,
          userAgent: navigator.userAgent,
          fnId: errorObj?.origin,
        };
      }
      const errorLog = serializeError(errorObj.createClientLog);
      try {
        await axiosLimited.post('/api/log/createClientLog', {
          errorLog,
        });
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('apiStatusProvider/handleError/createClientLog', error);
        }
      }
    }
  }

  function handleSuccess(successObj) {
    let successMessage = successObj?.message || 'success';

    if (process.env.REACT_APP_ENV === 'development') {
      console.log('DEV: handleSuccess/functionOrigin', successObj?.origin);
    }

    let translatedMessage;
    translatedMessage = SuccessTranslations[successMessage];

    if (!translatedMessage) {
      translatedMessage = SuccessTranslations['success'];
    }
    const newSuccessObj = {
      message: translatedMessage,
      status: 'success',
      id: successObj?.id || Date.now(),
    };

    setModalTimer(newSuccessObj);

    setApiStatus((prevApiStatus) => [...prevApiStatus, newSuccessObj]);
  }

  function handleWarning(warningObj) {
    if (process.env.REACT_APP_ENV === 'development') {
      console.log('DEV: handleWarning/functionOrigin', warningObj?.origin);
    }

    let warningMessage = warningObj?.message || 'warning';

    let translatedMessage;
    translatedMessage = WarningTranslations[warningMessage];

    if (!translatedMessage) {
      translatedMessage = WarningTranslations['warning'];
    }
    const newWarningObj = {
      message: translatedMessage,
      status: 'warning',
      id: warningObj?.id || Date.now(),
    };

    setModalTimer(newWarningObj);

    setApiStatus((prevApiStatus) => [...prevApiStatus, newWarningObj]);
  }

  return (
    <ApiStatus.Provider
      value={{
        apiStatus,
        handleClearApiStatus,
        handleError,
        handleSuccess,
        handleWarning,
      }}
    >
      {children}
    </ApiStatus.Provider>
  );
};

export default ApiStatus;
