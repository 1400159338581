import { useState, useRef, useEffect } from 'react';

//hooks
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';

//components
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import TextArea from '../../../../../components/TextArea/TextArea';
import ParticipantActionBtns from './ParticipantActionBtns';

//utils
import { textUI } from '../../../../../utils/UI/textUI';

const ParticipantInviteContact = ({
  prevParticipant,
  handleSaveAndReturn,
  handleRemoveParticipant,
  displayInvitationStatus,
}) => {
  //hooks
  const { ParticipantInviteContact } = useLanguageOrganize();

  //state
  const [participant, setParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  //variable
  const effectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (effectRan.current === false && prevParticipant?.id) {
      effectRan.current = true;
      setParticipant(prevParticipant);
    }
  }, [prevParticipant]);

  useEffect(() => {
    if (participant) {
      setIsLoading(false);
    }
  }, [participant]);

  //functions
  function handleEventRoleChange(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
      },
    };
    newParticipantData.participantData.eventRole = val;

    if (val?.length > 0) {
      newParticipantData.completionStatus = 'complete';
    } else if (val?.length === 0) {
      newParticipantData.completionStatus = 'incomplete';
    }

    setParticipant(newParticipantData);
  }

  return (
    <>
      <ParticipantActionBtns
        participant={participant}
        handleSaveAndReturn={handleSaveAndReturn}
        handleRemoveParticipant={handleRemoveParticipant}
      />
      <div className="flex-column border-reg br--standard pad-24 bg-color-light  mrg-t24">
        {!isLoading && (
          <>
            <div className={`participant__wrapper`}>
              <div className="participant-img__container mrg-r24">
                <LoadBucketImage
                  imagePath={
                    participant?.participantData?.personal?.profileImage
                  }
                  containerClassName={'ev-display-container'}
                  imgClassName={'participant-img'}
                  backupPath={'account_img_default.png'}
                  partialPath={'myndfull_account_images'}
                />
              </div>

              <div className="participant-info full-width">
                <div className="flex-column full-width">
                  <p className="fs18 fwsb access-ob" tabIndex="0">
                    {participant?.participantData?.personal?.prefix &&
                      `${participant?.participantData?.personal?.prefix} `}
                    {`${participant?.participantData?.personal?.firstName} `}
                    {participant?.participantData?.personal?.lastName}
                    {participant?.participantData?.eventRole &&
                      ` | ${participant?.participantData?.eventRole}`}
                  </p>

                  <p className="italic fs16">
                    {participant?.participantData?.personal?.pronouns &&
                      `${participant?.participantData?.personal?.pronouns}`}
                  </p>
                </div>

                {participant?.participantData?.personal?.bio && (
                  <div className="fs16 mrg-t6 access-ob" tabIndex="0">
                    {textUI(participant?.participantData?.personal?.bio)}
                  </div>
                )}
              </div>
            </div>
            <div className="h24" />
            <TextArea
              disableEnter={true}
              handleChange={handleEventRoleChange}
              hideWordCount={true}
              id={'participantEventRole'}
              labelText={ParticipantInviteContact.eventRole}
              limit={50}
              loadTrigger={true}
              loadValue={participant?.participantData?.eventRole}
              placeHolder={ParticipantInviteContact.eventRolePlaceholder}
              onBlurHandlerOff={true}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ParticipantInviteContact;
