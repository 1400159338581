const useSanitize = () => {
  function stringCheck(input) {
    if (typeof input !== 'string') {
      return false;
    }

    let check = input.replaceAll(' ', '');
    if (!check) {
      return true;
    }
    return false;
  }

  return { stringCheck };
};

export default useSanitize;
