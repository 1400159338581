import { useEffect, useState } from 'react';

//hooks
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import useLanguageAdmin from '../../../language/features/useLanguageAdmin';

//components
import RadialButtonList from '../../../components/RadialButtonList/RadialButtonList';
import CheckBox from '../../../components/Selections/CheckBox';
import ButtonSwitch from '../../../components/ButtonSwitch/ButtonSwitch';
import TextArea from '../../../components/TextArea/TextArea';
import DateInput from '../../../components/TextArea/DateInput';
import ConfirmSelection from '../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../components/Spinner/Spinner';

//utility
const AdminManagementTicketTableFilters = ({
  handleFilteredSearchTickets,
  isLoading,
}) => {
  //hooks
  const { TicketTableFilters } = useLanguageAdmin();
  const {
    adminSettingsManagementTicketsTableStatus,
    adminSettingsManagementTicketsTableIssue,
    adminSettingsManagementTicketsTableType,
    handleAdminSettingsManagementTicketsTableStatus,
    handleAdminSettingsManagementTicketsTableIssue,
    handleAdminSettingsManagementTicketsTableType,

    //current support
    adminSettingsManagementTicketsTableSupportUser,
    adminSettingsManagementTicketsTableSupportUserSearchType,
    handleAdminSettingsManagementTicketsTableSupportUser,
    handleAdminSettingsManagementTicketsTableSupportUserInput,
    handleAdminSettingsManagementTicketsTableSupportUserSearchType,

    //submit user
    adminSettingsManagementTicketsTableSubmitUser,
    adminSettingsManagementTicketsTableSubmitUserSearchType,
    adminSettingsManagementTicketsTableSubmitUserFilter,
    handleAdminSettingsManagementTicketsTableSubmitUser,
    handleAdminSettingsManagementTicketsTableSubmitUserSearchType,
    handleAdminSettingsManagementTicketsTableSubmitUserInput,
    handleAdminSettingsManagementTicketsTableSubmitUserFilter,
    //dates
    adminSettingsManagementTicketsTableDateStart,
    adminSettingsManagementTicketsTableDateEnd,
    handleAdminSettingsManagementTicketsTableDateStart,
    handleAdminSettingsManagementTicketsTableDateEnd,

    //page
    adminSettingsManagementTicketsTableResultsPerPage,
    handleAdminSettingsManagementTicketsTablePage,
    handleAdminSettingsManagementTicketsTableResultsPerPage,
  } = useAdminSettingsHandlers();

  //state
  const [reset, setReset] = useState(false);

  //Initialize
  useEffect(() => {
    handleAdminSettingsManagementTicketsTableSupportUserInput('');
    handleAdminSettingsManagementTicketsTableSubmitUserInput('');
    handleAdminSettingsManagementTicketsTableDateStart('');
    handleAdminSettingsManagementTicketsTableDateEnd('');
    handleAdminSettingsManagementTicketsTablePage(1);
  }, []);

  //Functions
  function handleSetStatus(newStatus) {
    if (adminSettingsManagementTicketsTableStatus?.includes(newStatus[0])) {
      let arr = [...adminSettingsManagementTicketsTableStatus];
      let newArr = arr.filter((item) => item !== newStatus[0]);
      handleAdminSettingsManagementTicketsTableStatus(newArr);
    } else {
      const newArr = [
        ...adminSettingsManagementTicketsTableStatus,
        newStatus[0],
      ];
      handleAdminSettingsManagementTicketsTableStatus(newArr);
    }
  }

  function handleSetIssue(newIssue) {
    if (adminSettingsManagementTicketsTableIssue?.includes(newIssue[0])) {
      let arr = [...adminSettingsManagementTicketsTableIssue];
      let newArr = arr.filter((item) => item !== newIssue[0]);
      handleAdminSettingsManagementTicketsTableIssue(newArr);
    } else {
      const newArr = [...adminSettingsManagementTicketsTableIssue, newIssue[0]];
      handleAdminSettingsManagementTicketsTableIssue(newArr);
    }
  }

  function handleSetType(newType) {
    if (adminSettingsManagementTicketsTableType?.includes(newType[0])) {
      let arr = [...adminSettingsManagementTicketsTableType];
      let newArr = arr.filter((item) => item !== newType[0]);
      handleAdminSettingsManagementTicketsTableType(newArr);
    } else {
      const newArr = [...adminSettingsManagementTicketsTableType, newType[0]];
      handleAdminSettingsManagementTicketsTableType(newArr);
    }
  }

  function handleSetSubmitUserFilter(newSubmitUserFilter) {
    if (
      adminSettingsManagementTicketsTableSubmitUserFilter ===
      newSubmitUserFilter[0]
    ) {
      handleAdminSettingsManagementTicketsTableSubmitUserFilter('');
    } else {
      handleAdminSettingsManagementTicketsTableSubmitUserFilter(
        newSubmitUserFilter[0]
      );
    }
  }

  function handleClearFilters() {
    setReset(true);
    handleAdminSettingsManagementTicketsTableStatus([]);
    handleAdminSettingsManagementTicketsTableIssue([]);
    handleAdminSettingsManagementTicketsTableType([]);
    handleAdminSettingsManagementTicketsTableSupportUser(false);
    handleAdminSettingsManagementTicketsTableSupportUserInput('');
    handleAdminSettingsManagementTicketsTableSupportUserSearchType('name');
    handleAdminSettingsManagementTicketsTableSubmitUser(false);
    handleAdminSettingsManagementTicketsTableSubmitUserSearchType('name');
    handleAdminSettingsManagementTicketsTableSubmitUserInput('');
    handleAdminSettingsManagementTicketsTableSubmitUserFilter('');
    handleAdminSettingsManagementTicketsTableDateStart('');
    handleAdminSettingsManagementTicketsTableDateEnd('');
    handleAdminSettingsManagementTicketsTableResultsPerPage(20);

    let timeout = null;
    timeout = setTimeout(() => {
      setReset(false);
    }, 500);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }

  function handleFilteredSearch() {
    if (!isLoading) {
      handleAdminSettingsManagementTicketsTablePage(1);
      handleFilteredSearchTickets();
    }
  }
  //need to implement pagination functionality after initial query success// could add next / prev page buttons.

  return (
    <div className="ticket-table-filters__wrapper">
      <div className="ticket-table-filters mrg-b48">
        {reset ? (
          <Spinner />
        ) : (
          <>
            <div className="flex-row mrg-b24">
              <div className="flex-column mrg-r24">
                <p className="fs18 fwsb">Ticket Status</p>
                <div className="h12" />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableStatus.includes('open')
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.status.open}
                  handler={handleSetStatus}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableStatus.includes(
                      'pendingUserAction'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.status.pendingUserAction}
                  handler={handleSetStatus}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableStatus.includes(
                      'resolved'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.status.resolved}
                  handler={handleSetStatus}
                />

                <div className="flex-column mrg-t24">
                  <p className="fs18 fwsb">Ticket Type</p>
                  <div className="h12" />
                  <CheckBox
                    loadTrigger={true}
                    loadValue={adminSettingsManagementTicketsTableType.includes(
                      'event'
                    )}
                    visible={true}
                    selectionObj={TicketTableFilters.type.event}
                    handler={handleSetType}
                  />
                  <CheckBox
                    loadTrigger={true}
                    loadValue={adminSettingsManagementTicketsTableType.includes(
                      'account'
                    )}
                    visible={true}
                    selectionObj={TicketTableFilters.type.account}
                    handler={handleSetType}
                  />
                </div>
              </div>

              <div className="flex-column mrg-r24">
                <p className="fs18 fwsb">Ticket Issue</p>
                <div className="h12" />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes('other')
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.other}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes('spam')
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.spam}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes(
                      'falseInfo'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.falseInfo}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes(
                      'unlawfulActivity'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.unlawfulActivity}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes(
                      'defamDiscrim'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.defamDiscrim}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes(
                      'infringingRight'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.infringingRight}
                  handler={handleSetIssue}
                />
                <CheckBox
                  loadTrigger={true}
                  loadValue={
                    adminSettingsManagementTicketsTableIssue.includes(
                      'violatingPrivacy'
                    )
                      ? true
                      : false
                  }
                  visible={true}
                  selectionObj={TicketTableFilters.issues.violatingPrivacy}
                  handler={handleSetIssue}
                />
              </div>

              <div
                className="flex-column mrg-r24"
                style={{ minWidth: '296px' }}
              >
                <ButtonSwitch
                  handleSwitch={
                    handleAdminSettingsManagementTicketsTableSupportUser
                  }
                  loadTrigger={true}
                  loadValue={adminSettingsManagementTicketsTableSupportUser}
                  id={'supportSwitch'}
                  title={'Support User'}
                  semiboldTitle={true}
                />
                {adminSettingsManagementTicketsTableSupportUser && (
                  <>
                    <div className="h24" />

                    <RadialButtonList
                      id={'supportType'}
                      onChange={
                        handleAdminSettingsManagementTicketsTableSupportUserSearchType
                      }
                      options={{
                        name: 'Search by Name',
                        reference: 'Search by Reference',
                      }}
                      loadTrigger={true}
                      loadValue={
                        adminSettingsManagementTicketsTableSupportUserSearchType
                      }
                    />
                    {adminSettingsManagementTicketsTableSupportUserSearchType && (
                      <TextArea
                        limit={100}
                        disableEnter={true}
                        id={'supportField'}
                        handleChange={
                          handleAdminSettingsManagementTicketsTableSupportUserInput
                        }
                        customWidth={'296px'}
                        labelText={
                          adminSettingsManagementTicketsTableSupportUserSearchType ===
                          'name'
                            ? 'Name'
                            : 'Admin Reference'
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div
                className="flex-column mrg-r24"
                style={{ minWidth: '296px' }}
              >
                <ButtonSwitch
                  handleSwitch={
                    handleAdminSettingsManagementTicketsTableSubmitUser
                  }
                  loadTrigger={true}
                  loadValue={adminSettingsManagementTicketsTableSubmitUser}
                  id={'submitUserSwitch'}
                  title={'Submit User'}
                  semiboldTitle={true}
                />
                {adminSettingsManagementTicketsTableSubmitUser && (
                  <>
                    <div className="h24" />
                    <RadialButtonList
                      id={'submitUserSearchType'}
                      onChange={
                        handleAdminSettingsManagementTicketsTableSubmitUserSearchType
                      }
                      options={{
                        name: 'Search By Name',
                        reference: 'Search By Reference',
                      }}
                      loadTrigger={true}
                      loadValue={
                        adminSettingsManagementTicketsTableSubmitUserSearchType
                      }
                    />
                    {adminSettingsManagementTicketsTableSubmitUserSearchType && (
                      <TextArea
                        limit={100}
                        disableEnter={true}
                        id={'submitUserField'}
                        handleChange={
                          handleAdminSettingsManagementTicketsTableSubmitUserInput
                        }
                        customWidth={'296px'}
                        labelText={
                          adminSettingsManagementTicketsTableSubmitUserSearchType ===
                          'name'
                            ? 'Name'
                            : 'Account Reference'
                        }
                      />
                    )}
                  </>
                )}

                <div className="flex-column mrg-t24">
                  <p className="fs18 fwsb">Submit User Filter</p>
                  <div className="h12" />
                  <CheckBox
                    loadTrigger={true}
                    loadValue={adminSettingsManagementTicketsTableSubmitUserFilter.includes(
                      'myndFullOnly'
                    )}
                    visible={true}
                    selectionObj={
                      TicketTableFilters.submitUserFilter.myndFullOnly
                    }
                    handler={handleSetSubmitUserFilter}
                    maxWidth={'296px'}
                    reload={true}
                  />
                  <CheckBox
                    loadTrigger={true}
                    loadValue={adminSettingsManagementTicketsTableSubmitUserFilter.includes(
                      'nonMyndFull'
                    )}
                    visible={true}
                    selectionObj={
                      TicketTableFilters.submitUserFilter.nonMyndFull
                    }
                    handler={handleSetSubmitUserFilter}
                    maxWidth={'296px'}
                    reload={true}
                  />
                </div>
              </div>
            </div>
            <div className="ticket-table-dates">
              <div className="mrg-r24">
                <DateInput
                  id={'searchDateStart'}
                  labelText={'Start Date'}
                  dateInput={adminSettingsManagementTicketsTableDateStart}
                  setDateInput={
                    handleAdminSettingsManagementTicketsTableDateStart
                  }
                />
              </div>
              <DateInput
                id={'searchDateEnd'}
                labelText={'End Date'}
                dateInput={adminSettingsManagementTicketsTableDateEnd}
                setDateInput={handleAdminSettingsManagementTicketsTableDateEnd}
              />
            </div>

            <div className="ticket-table-results flex-column mrg-t24">
              <label className="fs18 fwsb mrg-b6">Results Per Page</label>
              <input
                type="number"
                enterKeyHint="done"
                className="number-input"
                onChange={(e) =>
                  handleAdminSettingsManagementTicketsTableResultsPerPage(
                    e.target.value
                  )
                }
                defaultValue={adminSettingsManagementTicketsTableResultsPerPage}
              />
            </div>

            <div className="flex-column mrg-t36">
              <ConfirmSelection
                btn1Text={'Clear Filters'}
                btn1Handler={handleClearFilters}
                btn1Color={'neg'}
                btn1Id={'clearFiltersBtn'}
                btn2Text={'Search'}
                btn2Handler={handleFilteredSearch}
                btn2Color={'pos'}
                btn2Id={'filteredSearchBtn'}
                noMrg={true}
                ignoreMaxWidth={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminManagementTicketTableFilters;
