import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useOrganizeHandlers } from '../../features/main/organize/useOrganizeHandlers';
import useApiStatus from '../useApiStatus';

const OrganizeEventsSocketContext = createContext({});

export const OrganizeEventsSocketProvider = ({ children }) => {
  // Hooks
  const { accountId } = useAccountReduxHandlers();
  const { handleSocketOrganizedEventData } = useOrganizeHandlers();
  const { handleError } = useApiStatus();

  const [organizeEventsSocketStatus, setOrganizeEventsSocketStatus] =
    useState('closed');

  // Refs
  const socketRef = useRef(null);
  const statusRef = useRef(organizeEventsSocketStatus);

  useEffect(() => {
    statusRef.current = organizeEventsSocketStatus;
  }, [organizeEventsSocketStatus]);

  const connectOrganizeEventsSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && organizeEventsSocketStatus !== 'connected') {
      let newSocket;

      const socketOptions = {
        query: { accountId },
        reconnection: false,
      };

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io(
          'http://localhost:5000/ws/organizeEvents',
          socketOptions
        );
      } else {
        newSocket = io('https://myndfull.com/ws/organizeEvents', {
          ...socketOptions,
          withCredentials: true,
        });
      }

      let reconnectAttempts = 0;
      const maxReconnectAttempts = 10;

      const reconnectWithBackoff = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000); // Exponential backoff, max 30 seconds
          reconnectAttempts += 1;

          console.log(
            `Reconnecting attempt ${reconnectAttempts} in ${delay / 1000}s...`
          );

          setTimeout(() => {
            connectOrganizeEventsSocket(); // Retry connection
          }, delay);
        } else {
          console.error('Max reconnection attempts reached.');
          setOrganizeEventsSocketStatus('failed');
        }
      };

      newSocket.on('connect', () => {
        console.log(
          `Connected to OrganizeEvents WebSocket, id: ${newSocket.id}`
        );
        reconnectAttempts = 0; // Reset attempts on successful connection
        setOrganizeEventsSocketStatus('connected');
      });

      newSocket.on('organizeEventsData', (organizeEventsData) => {
        handleSocketOrganizedEventData(organizeEventsData);
      });

      newSocket.on('connect_error', (error) => {
        console.error('Connection error:', error);
        setOrganizeEventsSocketStatus('closed');
        reconnectWithBackoff();
      });

      newSocket.on('disconnect', (reason) => {
        console.error(`Disconnected: ${reason}`);
        setOrganizeEventsSocketStatus('closed');
        if (reason !== 'io client disconnect') {
          reconnectWithBackoff();
        }
      });

      newSocket.on('error', (error) => {
        console.error('Socket error:', error);
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'OrganizeEventsSocketProvider.js',
        });
        setOrganizeEventsSocketStatus('closed');
      });

      return (socketRef.current = newSocket);
    }
  };

  const getOrganizeEventsSocket = () => {
    return socketRef.current;
  };

  const closeOrganizeEventsSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setOrganizeEventsSocketStatus('closed');

      console.error('Closed Organize Events Socket');
    }
  };

  return (
    <OrganizeEventsSocketContext.Provider
      value={{
        connectOrganizeEventsSocket,
        closeOrganizeEventsSocket,
        getOrganizeEventsSocket,
        organizeEventsSocketStatus,
      }}
    >
      {children}
    </OrganizeEventsSocketContext.Provider>
  );
};

export const useOrganizeEventsSocket = () => {
  return useContext(OrganizeEventsSocketContext);
};
