import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import ParticipantListItem from './ParticipantSections/ParticipantListItem/ParticipantListItem';
import CheckBox from '../../../../components/Selections/CheckBox';

//utils
import {
  cancel_red,
  checkmark_green,
  img_exclamation,
  img_hourglass,
} from '../../../../assets/icons';

const Dispatches = ({
  CreateEvent,
  skipToId,
  skipToLabel,
  //state
  participants,
  translationsEnabled,
  //handlers
  handleSetParticipants,
}) => {
  //hooks
  const { IconAlts, Generic } = useLanguageComponents();

  //state
  const [dispatchableParticipants, setDispatchableParticipants] = useState([]);

  useEffect(() => {
    if (participants) {
      let newDispatchableParticipants = [];

      for (const participant of participants) {
        if (
          participant?.organizedAs === 'inviteContact' ||
          participant?.organizedAs === 'emailInvitation'
        ) {
          if (participant.completionStatus === 'complete') {
            newDispatchableParticipants.push(participant);
          }
        }
      }

      setDispatchableParticipants(newDispatchableParticipants);
    }
  }, [participants]);

  function handleInvitationReadyDispatch(selection, val, participantId) {
    let newParticipants = [];
    let newP;

    participants?.map((p) => {
      if (p?.id === participantId) {
        newP = JSON.parse(JSON.stringify(p));
        if (val) {
          newP.dispatches.invitationStatus = 'dispatching';
        } else {
          newP.dispatches.invitationStatus = 'notDispatched';
        }
        newParticipants.push(newP);
      } else {
        newParticipants.push(p);
      }
    });

    handleSetParticipants(newParticipants);
  }

  function handleTranslationsReadyDispatch(selection, val, participantId) {
    let newParticipants = [];
    let newP;

    participants?.map((p) => {
      if (p?.id === participantId) {
        newP = JSON.parse(JSON.stringify(p));
        if (val) {
          newP.dispatches.translationsStatus = 'dispatching';
        } else {
          newP.dispatches.translationsStatus = 'notDispatched';
        }
        newParticipants.push(newP);
      } else {
        newParticipants.push(p);
      }
    });

    handleSetParticipants(newParticipants);
  }

  function getDispatchesCount(participant) {
    let totalDispatchesAvailable = 0;
    let totalCurrentDispatches = 0;
    let allDispatchesIssued = false;

    if (
      translationsEnabled &&
      !participant.dispatches?.translationsDispatched
    ) {
      totalDispatchesAvailable++;
    }

    if (!participant.dispatches?.inviteDispatched) {
      totalDispatchesAvailable++;
    }

    if (
      translationsEnabled &&
      participant.dispatches?.translationsStatus === 'dispatching'
    ) {
      totalCurrentDispatches++;
    }

    if (participant.dispatches?.invitationStatus === 'dispatching') {
      totalCurrentDispatches++;
    }

    if (totalDispatchesAvailable === 0) {
      if (translationsEnabled) {
        if (
          participant?.dispatches?.translationsDispatched &&
          participant?.dispatches?.inviteDispatched
        ) {
          allDispatchesIssued = true;
        }
      } else {
        if (participant?.dispatches?.inviteDispatched) {
          allDispatchesIssued = true;
        }
      }
    }

    return {
      allDispatchesIssued,
      totalDispatchesAvailable,
      totalCurrentDispatches,
    };
  }

  return (
    <fieldset
      aria-describedby="aria-fieldset-instructions-dispatches"
      className="fieldset"
      id="fieldset-dispatches"
    >
      <label
        className="label"
        id="dispatchesLabel"
        aria-describedby="aria-fieldset-instructions-dispatches"
        tabIndex="0"
      >
        {CreateEvent.dispatches.participantDispatches}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />

      {dispatchableParticipants?.length === 0 ? (
        <p className="fs21 fwn">
          {CreateEvent.dispatches.noDispatchesAvailable}
        </p>
      ) : (
        <>
          <div className="banners__notice mrg-b24 fs18">
            {CreateEvent.dispatches.participantDispatchesDescription}
          </div>

          <table className="dispatches-table">
            <thead>
              <tr>
                <th>{CreateEvent.dispatches.completedParticipants}</th>
                <th>{CreateEvent.dispatches.participantInvite}</th>
                {translationsEnabled && (
                  <th>{CreateEvent.dispatches.translationRequest}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {dispatchableParticipants?.map((participant, index) => {
                const {
                  totalDispatchesAvailable,
                  totalCurrentDispatches,
                  allDispatchesIssued,
                } = getDispatchesCount(participant);
                return (
                  <tr key={`communityContact-${index}`}>
                    <td className="full-width">
                      <ParticipantListItem
                        participant={participant}
                        listItemMode={'dispatches'}
                        //ui
                        turnOffAnimation={true}
                        borderBottomOff={true}
                        //dispatches
                        totalDispatchesAvailable={totalDispatchesAvailable}
                        totalCurrentDispatches={totalCurrentDispatches}
                        allDispatchesIssued={allDispatchesIssued}
                        translationsEnabled={translationsEnabled}
                      />
                    </td>
                    <td>
                      {participant?.dispatches?.invitationStatus ===
                        'pending' && (
                        <img
                          src={img_hourglass}
                          alt={IconAlts.iconHourglass}
                          className="filter-theme"
                          style={{ height: '28px' }}
                        />
                      )}

                      {participant?.dispatches?.invitationStatus ===
                        'accepted' && (
                        <img
                          src={checkmark_green}
                          alt={IconAlts.iconCheckmark}
                          className=""
                          style={{ height: '21px' }}
                        />
                      )}

                      {(participant?.dispatches?.invitationStatus ===
                        'rejected' ||
                        participant?.dispatches?.invitationStatus ===
                          'withdrew') && (
                        <img
                          src={cancel_red}
                          alt={'X'}
                          className=""
                          style={{ height: '21px' }}
                        />
                      )}

                      {participant?.dispatches?.invitationStatus ===
                        'error' && (
                        <img
                          src={img_exclamation}
                          alt={IconAlts.iconExclamation}
                          className="filter-red"
                          style={{ height: '24px' }}
                        />
                      )}

                      {!participant?.dispatches?.inviteDispatched &&
                        participant.completionStatus === 'complete' && (
                          <CheckBox
                            visible={true}
                            selectionObj={{ dispatchReady: false }}
                            handler={handleInvitationReadyDispatch}
                            customWrapper={'mrg-auto-lr'}
                            accountId={participant.id}
                            handleAccountId={true}
                            loadTrigger={true}
                            loadValue={
                              participant.dispatches.invitationStatus ===
                              'dispatching'
                            }
                            labelOff={true}
                          />
                        )}
                    </td>
                    {translationsEnabled && (
                      <td>
                        {participant?.dispatches?.translationsStatus ===
                          'pending' && (
                          <img
                            src={img_hourglass}
                            alt={IconAlts.iconHourglass}
                            className="filter-theme"
                            style={{ height: '28px' }}
                          />
                        )}

                        {participant?.dispatches?.translationsStatus ===
                          'accepted' && (
                          <img
                            src={checkmark_green}
                            alt={IconAlts.iconCheckmark}
                            className=""
                            style={{ height: '21px' }}
                          />
                        )}

                        {(participant?.dispatches?.translationsStatus ===
                          'rejected' ||
                          participant?.dispatches?.translationsStatus ===
                            'withdrew') && (
                          <img
                            src={cancel_red}
                            alt={'X'}
                            className=""
                            style={{ height: '21px' }}
                          />
                        )}

                        {participant?.dispatches?.translationsStatus ===
                          'error' && (
                          <>
                            <img
                              src={img_exclamation}
                              alt={IconAlts.iconExclamation}
                              className="filter-red"
                              style={{ height: '24px' }}
                            />
                            <p className="fs18 text-red fwsb">
                              {Generic.error}
                            </p>
                          </>
                        )}

                        {(participant.translationsLanguageConflict ||
                          participant.translationsRoomRequiresLanguage) && (
                          <>
                            <img
                              src={img_exclamation}
                              alt={IconAlts.iconExclamation}
                              className="filter-red"
                              style={{ height: '24px' }}
                            />
                            <p className="fs18 text-red fwsb">
                              {Generic.conflict}
                            </p>
                          </>
                        )}

                        {!participant?.dispatches?.translationsDispatched &&
                          !participant.translationsLanguageConflict &&
                          !participant.translationsRoomRequiresLanguage && (
                            <CheckBox
                              visible={true}
                              selectionObj={{ dispatchReady: false }}
                              handler={handleTranslationsReadyDispatch}
                              customWrapper={'mrg-auto-lr'}
                              accountId={participant.id}
                              handleAccountId={true}
                              loadTrigger={true}
                              loadValue={
                                participant.dispatches.translationsStatus ===
                                'dispatching'
                              }
                              labelOff={true}
                            />
                          )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </fieldset>
  );
};

export default Dispatches;
