import { useEffect, useState } from 'react';
//Hooks
import useLanguageData from '../../../../language/useLanguageData';
//Components

//Utility

const AddressContent = ({
  eventData,
  accountRegistrationsInPerson,
  accountRegistrationsVirtual,
}) => {
  //hooks
  const { CountriesOpts } = useLanguageData();
  //component state
  const [isRegistered, setIsRegistered] = useState();
  const [country, setCountry] = useState();

  //Initialize functions
  useEffect(() => {
    if (
      accountRegistrationsInPerson?.includes(eventData._id) ||
      accountRegistrationsVirtual?.includes(eventData._id)
    ) {
      setIsRegistered(true);
    } else {
      setIsRegistered(false);
    }
  }, [accountRegistrationsInPerson, accountRegistrationsVirtual]);

  //component variables
  let addressContent;
  let virtualContent;
  let inPersonContent;

  let virtualPlatform =
    eventData.hosting?.virtual?.platform?.charAt(0).toUpperCase() +
    eventData.hosting?.virtual?.platform?.slice(1);

  let locationName = eventData.hosting?.inPerson?.location?.locationName;
  let streetAddress = eventData.hosting?.inPerson?.location?.streetAddress;
  let city = eventData.hosting?.inPerson?.location?.city;

  let isEventDetailsLocked =
    eventData.registrationData?.privateDetails?.enabled;
  let isLocationNameLocked =
    eventData.registrationData?.privateDetails?.locationName;
  let isStreetAddressLocked =
    eventData.registrationData?.privateDetails?.streetAddress;

  useEffect(() => {
    if (eventData?.hosting?.inPerson?.location?.country) {
      let newCountry =
        CountriesOpts[eventData?.hosting?.inPerson?.location?.country];
      setCountry(newCountry);
    }
  }, [eventData]);

  if (
    eventData.hosting.hostingOption === 'online' ||
    eventData.hosting.hostingOption === 'hybrid'
  ) {
    virtualContent = <span>{virtualPlatform}</span>;
  }

  if (
    eventData.hosting.hostingOption === 'inPerson' ||
    eventData.hosting.hostingOption === 'hybrid'
  ) {
    if (isRegistered || !isEventDetailsLocked) {
      if (locationName) {
        inPersonContent = (
          <span>
            {locationName},<br />
            {city}, {country}
          </span>
        );
      } else if (streetAddress) {
        inPersonContent = (
          <span>
            {streetAddress},<br />
            {city}, {country}
          </span>
        );
      } else {
        inPersonContent = (
          <span>
            {city},{country}
          </span>
        );
      }
    } else if (isEventDetailsLocked) {
      if (
        locationName &&
        isLocationNameLocked &&
        streetAddress &&
        isStreetAddressLocked
      ) {
        inPersonContent = (
          <span>
            {city}, {country}
          </span>
        );
      } else if (locationName && !isLocationNameLocked) {
        inPersonContent = <span>{locationName}</span>;
      } else if (streetAddress && !isStreetAddressLocked) {
        inPersonContent = (
          <span>
            {streetAddress}, {city}
          </span>
        );
      }
    }
  }

  if (eventData.hosting.hostingOption === 'online') {
    addressContent = virtualContent;
  } else if (eventData.hosting.hostingOption === 'inPerson') {
    addressContent = inPersonContent;
  } else if (eventData.hosting.hostingOption === 'hybrid') {
    addressContent = (
      <>
        {virtualContent}
        <br />
        {inPersonContent}
      </>
    );
  }

  return addressContent;
};

export default AddressContent;
