import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    _id: '',
    accountEvents: {
      archivedEvents: [],
      draftEvents: [],
      ignoredEvents: [],
      organizedArchivedEvents: [],
      organizedEvents: [],
      participantDrafts: [],
      participantEvents: [],
      pendingApprovalInPerson: [],
      pendingApprovalVirtual: [],
      registrationsInPerson: [],
      registrationsVirtual: [],
      savedEvents: [],
    },
    affiliations: [],
    appSettings: {
      contentLanguage: '',
      soundEffects: true,
      timeZone: '',
    },
    attendee: {
      attendeeModeActive: false,
      eventSearchOnlineCountries: [],
      distanceFilter: null,
      distanceMeasurement: '',
      geoPosition: {
        type: '',
        coordinates: [0, 0],
      },
      searchLanguages: [],
      tags: {
        bigTags: [],
        tinyTags: [],
        tinyTagGroups: [],
      },
    },
    community: {
      blockedAccounts: [],
      contacts: [],
      contactsRequested: [],
      followers: [],
      following: [],
      followingGroups: [],
    },
    billing: {
      customerId: '',
      paymentMethodIds: [],
      roomCodeSubscriptions: [],
      myInvoices: [],
      failedInvoices: [],
    },
    emailPreferences: {
      myndFullNewsletter: false,
      promotions: false,
    },
    meta: {
      accountReference: '',
    },
    notices: [],
    organizer: {
      communitySettings: {
        displayContact: false,
      },
      organizerModeActive: false,
    },
    personal: {
      badges: [],
      bannerImage: '',
      bio: '',
      countryCode: '',
      email: '',
      firstName: '',
      lastName: '',
      links: {
        facebook: '',
        instagram: '',
        linkedIn: '',
        personalWebsite: '',
        x: '',
      },
      phoneNumber: '',
      prefix: '',
      profileImage: '',
      pronouns: '',
    },
    speaker: {
      communitySettings: {
        attendeeFollowRequests: false,
      },
      credentials: {
        artisticActivities: '',
        certificatesLicenses: '',
        education: '',
        employment: '',
        honorsAwards: '',
        jobExperience: '',
        patentDesigns: '',
        presentations: '',
        projects: '',
        publications: '',
        skillsExpertise: '',
        volunteerExperience: '',
      },
      lastModified: '',
      speakerCountryISO: '',
      speakerLanguages: [],
      speakerLanguagesNotListed: [],
      speakerModeActive: false,
      speakerGeoPosition: {
        type: '',
        coordinates: [0, 0],
      },
      signUpComplete: false,
      tags: {
        speakerBigTags: [],
        speakerTinyTags: [],
      },
    },
    translationRooms: {
      roomCodes: [],
    },
  },
  reducers: {
    //General
    setAccount: (state, action) => {
      const {
        _id,
        accountEvents,
        affiliations,
        appSettings,
        attendee,
        billing,
        community,
        emailPreferences,
        meta,
        notices,
        organizer,
        personal,
        speaker,
        translationRooms,
      } = action.payload;
      state._id = _id;
      state.accountEvents = {
        archivedEvents: accountEvents?.archivedEvents || [],
        draftEvents: accountEvents?.draftEvents || [],
        ignoredEvents: accountEvents?.ignoredEvents || [],
        organizedEvents: accountEvents?.organizedEvents || [],
        organizedArchivedEvents: accountEvents?.organizedArchivedEvents || [],
        participantDrafts: accountEvents?.participantDrafts || [],
        participantEvents: accountEvents?.participantEvents || [],
        pendingApprovalInPerson: accountEvents?.pendingApprovalInPerson || [],
        pendingApprovalVirtual: accountEvents?.pendingApprovalVirtual || [],
        savedEvents: accountEvents?.savedEvents || [],
        registrationsInPerson: accountEvents?.registrationsInPerson || [],
        registrationsVirtual: accountEvents?.registrationsVirtual || [],
      };
      state.appSettings = {
        contentLanguage: appSettings?.contentLanguage,
        soundEffects: appSettings?.soundEffects,
        timeZone: appSettings?.timeZone,
      };
      state.affiliations = affiliations;
      state.attendee = {
        attendeeModeActive: attendee?.attendeeModeActive,
        eventSearchOnlineCountries: attendee?.eventSearchOnlineCountries,
        distanceFilter: attendee?.distanceFilter,
        distanceMeasurement: attendee?.distanceMeasurement,
        geoPosition: {
          type: 'Point',
          coordinates: [
            attendee?.geoPosition?.coordinates[1],
            attendee?.geoPosition?.coordinates[0],
          ],
        },
        searchLanguages: attendee?.searchLanguages,
        tags: {
          bigTags: attendee?.tags?.bigTags,
          tinyTags: attendee?.tags?.tinyTags,
          tinyTagGroups: attendee?.tags?.tinyTagGroups,
        },
      };
      state.community = {
        blockedAccounts: community?.blockedAccounts || [],
        contacts: community?.contacts || [],
        contactsRequested: community?.contactsRequested || [],
        followers: community?.followers || [],
        following: community?.following || [],
        followingGroups: community?.followingGroups || [],
      };
      state.billing = {
        customerId: billing?.customerId,
        paymentMethodIds: billing?.paymentMethodIds,
        roomCodeSubscriptions: billing?.roomCodeSubscriptions,
        failedInvoices: billing?.failedInvoices,
      };
      state.emailPreferences = {
        myndFullNewsletter: emailPreferences?.myndFullNewsletter,
        promotions: emailPreferences?.promotions,
      };
      state.meta = {
        accountReference: meta?.accountReference,
      };
      state.notices = notices;
      state.organizer = {
        communitySettings: {
          displayContact: organizer?.communitySettings?.displayContact,
        },
        organizerModeActive: organizer?.organizerModeActive,
      };
      state.personal = {
        badges: personal?.badges,
        bannerImage: personal?.bannerImage || 'banner_img_default.png',
        bio: personal?.bio || '',
        countryCode: personal?.countryCode,
        email: personal?.email,
        firstName: personal?.firstName,
        lastName: personal?.lastName,
        links: {
          facebook: personal?.links?.facebook || '',
          instagram: personal?.links?.instagram || '',
          linkedIn: personal?.links?.linkedIn || '',
          personalWebsite: personal?.links?.personalWebsite || '',
          x: personal?.links?.x || '',
        },
        phoneNumber: personal?.phoneNumber,
        prefix: personal?.prefix,
        profileImage: personal?.profileImage,
        pronouns: personal?.pronouns,
      };
      state.speaker = {
        communitySettings: {
          attendeeFollowRequests:
            speaker?.communitySettings?.attendeeFollowRequests || false,
        },
        credentials: {
          artisticActivities: speaker?.credentials?.artisticActivities || '',
          certificatesLicenses:
            speaker?.credentials?.certificatesLicenses || '',
          education: speaker?.credentials?.education || '',
          employment: speaker?.credentials?.employment || '',
          honorsAwards: speaker?.credentials?.honorsAwards || '',
          jobExperience: speaker?.credentials?.jobExperience || '',
          patentDesigns: speaker?.credentials?.patentDesigns || '',
          presentations: speaker?.credentials?.presentations || '',
          projects: speaker?.credentials?.projects || '',
          publications: speaker?.credentials?.publications || '',
          skillsExpertise: speaker?.credentials?.skillsExpertise || '',
          volunteerExperience: speaker?.credentials?.volunteerExperience || '',
        },
        lastModified: speaker.lastModified || '',
        speakerCountryISO: speaker?.speakerCountryISO || '',
        speakerLanguages: speaker?.speakerLanguages || [],
        speakerLanguagesNotListed: speaker?.speakerLanguagesNotListed || [],
        speakerModeActive: speaker?.speakerModeActive || false,
        speakerGeoPosition: {
          type: 'Point',
          coordinates: [
            speaker?.speakerGeoPosition?.coordinates[1],
            speaker?.speakerGeoPosition?.coordinates[0],
          ],
        },
        signUpComplete: speaker?.signUpComplete,
        tags: {
          speakerBigTags: speaker?.tags?.speakerBigTags || [],
          speakerTinyTags: speaker?.tags?.speakerTinyTags || [],
        },
      };
      state.translationRooms = {
        roomCodes: translationRooms?.roomCodes,
      };
    },
    emptyAccountData: (state, action) => {
      state._id = null;
      state.accountEvents = {
        archivedEvents: [],
        draftEvents: [],
        ignoredEvents: [],
        organizedEvents: [],
        organizedArchivedEvents: [],
        participantDrafts: [],
        participantEvents: [],
        pendingApprovalInPerson: [],
        pendingApprovalVirtual: [],
        savedEvents: [],
        registrationsInPerson: [],
        registrationsVirtual: [],
      };
      state.affiliations = [];
      state.attendee = {
        attendeeModeActive: false,
        eventSearchOnlineCountries: [],
        distanceFilter: null,
        distanceMeasurement: '',
        geoPosition: {},
        searchLanguages: [],
        tags: {
          bigTags: [],
          tinyTags: [],
          tinyTagGroups: [],
        },
      };
      state.appSettings = {
        contentLanguage: '',
        soundEffecs: true,
        timeZone: '',
      };
      state.billing = {
        customerId: '',
        paymentMethodIds: [],
        roomCodeSubscriptions: [],
        myInvoices: [],
        failedInvoices: [],
      };
      state.community = {
        blockedAccounts: [],
        contacts: [],
        contactsRequested: [],
        followers: [],
        following: [],
        followingGroups: [],
      };
      state.emailPreferences = {
        myndFullNewsletter: false,
        promotions: false,
      };
      state.meta = {
        accountReference: '',
      };
      state.notices = [];
      state.organizer = {
        communitySettings: {
          displayContact: false,
        },
        organizerModeActive: false,
      };
      state.personal = {
        badges: [],
        bannerImage: '',
        bio: '',
        countryCode: '',
        email: '',
        firstName: '',
        lastName: '',
        links: {
          Facebook: '',
          instagram: '',
          linkedIn: '',
          personalWebsite: '',
          x: '',
        },
        phoneNumber: '',
        prefix: '',
        profileImage: '',
        pronouns: '',
      };
      state.speaker = {
        communitySettings: {
          attendeeFollowRequests: false,
        },
        credentials: {
          artisticActivities: '',
          certificatesLicenses: '',
          education: '',
          employment: '',
          honorsAwards: '',
          jobExperience: '',
          patentDesigns: '',
          presentations: '',
          projects: '',
          publications: '',
          skillsExpertise: '',
          volunteerExperience: '',
        },
        lastModified: '',
        speakerCountryISO: '',
        speakerLanguages: [],
        speakerLanguagesNotListed: [],
        speakerModeActive: false,
        speakerGeoPosition: {},
        speakerSignUpComplete: false,
        tags: {
          speakerBigTags: [],
          speakerTinyTags: [],
        },
      };
      state.translationRooms = {
        roomCodes: [],
      };
    },
    //AccountEvents
    setAccountEvents: (state, action) => {
      state.accountEvents = action.payload;
    },
    setArchivedEvents: (state, action) => {
      state.accountEvents.archivedEvents = action.payload || [];
    },
    setIgnoredEvents: (state, action) => {
      state.accountEvents.ignoredEvents = action.payload || [];
    },
    setOrganizedEvents: (state, action) => {
      state.accountEvents.organizedEvents = action.payload || [];
    },
    setParticipantDrafts: (state, action) => {
      state.accountEvents.participantDrafts = action.payload || [];
    },
    setParticipantEvents: (state, action) => {
      state.accountEvents.participantEvents = action.payload || [];
    },
    setPendingApprovalInPerson: (state, action) => {
      state.accountEvents.pendingApprovalInPerson = action.payload || [];
    },
    setPendingApprovalVirtual: (state, action) => {
      state.accountEvents.pendingApprovalVirtual = action.payload || [];
    },
    setRegistrationsInPerson: (state, action) => {
      state.accountEvents.registrationsInPerson = action.payload || [];
    },
    setRegistrationsVirtual: (state, action) => {
      state.accountEvents.registrationsVirtual = action.payload || [];
    },
    setSavedEvents: (state, action) => {
      state.accountEvents.savedEvents = action.payload || [];
    },

    //App Settings
    setContentLanguage: (state, action) => {
      state.appSettings.contentLanguage = action.payload;
    },
    setSoundEffects: (state, action) => {
      state.appSettings.soundEffects = action.payload;
    },
    setTimeZone: (state, action) => {
      state.appSettings.timeZone = action.payload;
    },

    //Billing
    setCustomerId: (state, action) => {
      state.billing.customerId = action.payload || '';
    },
    setMyInvoices: (state, action) => {
      state.billing.myInvoices = action.payload || [];
    },
    setPaymentMethodIds: (state, action) => {
      state.billing.paymentMethodIds = action.payload || [];
    },
    setRoomCodeSubscriptions: (state, action) => {
      state.billing.roomCodeSubscriptions = action.payload || [];
    },
    setFailedInvoices: (state, action) => {
      state.billing.failedInvoices = action.payload || [];
    },

    //Affiliations

    //Attendee
    setAttendeeModeActive: (state, action) => {
      state.attendee.attendeeModeActive = action.payload;
    },
    setEventSearchOnlineCountries: (state, action) => {
      state.attendee.eventSearchOnlineCountries = action.payload;
    },
    setDistanceFilter: (state, action) => {
      state.attendee.distanceFilter = action.payload;
    },
    setDistanceMeasurement: (state, action) => {
      state.attendee.distanceMeasurement = action.payload;
    },
    setSearchLanguages: (state, action) => {
      state.attendee.searchLanguages = action.payload || [];
    },
    setBigTags: (state, action) => {
      state.attendee.tags.bigTags = action.payload || [];
    },
    setTinyTags: (state, action) => {
      state.attendee.tags.tinyTags = action.payload || [];
    },
    setTinyTagGroups: (state, action) => {
      state.attendee.tags.tinyTagGroups = action.payload || [];
    },

    //Community
    setCommunity: (state, action) => {
      state.community = action.payload || [];
    },
    addCommunityFollowing: (state, action) => {
      state.community.following.push(action.payload);
    },
    removeCommunityFollowing: (state, action) => {
      state.community.following = state.following.filter(
        (id) => id !== action.payload
      );
    },
    setCommunityFollowing: (state, action) => {
      state.community.following = action.payload;
    },
    setCommunityFollowingGroups: (state, action) => {
      state.community.followingGroups = action.payload;
    },
    setCommunityBlockedAccounts: (state, action) => {
      state.community.blockedAccounts = action.payload;
    },
    setCommunityContacts: (state, action) => {
      state.community.contacts = action.payload;
    },
    setCommunityContactsRequested: (state, action) => {
      state.community.contactsRequested = action.payload;
    },
    setCommunityFollowers: (state, action) => {
      state.community.followers = action.payload;
    },
    //Email Preferences
    setEmailPreferencesMyndFullNewsletter: (state, action) => {
      state.emailPreferences.myndFullNewsletter = action.payload;
    },
    setEmailPreferencesPromotions: (state, action) => {
      state.emailPreferences.promotions = action.payload;
    },

    //Notices
    setNotices: (state, action) => {
      state.notices = action.payload || [];
    },

    //Organizer
    setAccountOrganizerCommunitySettingsDisplayContact: (state, action) => {
      state.organizer.communitySettings.displayContact = action.payload;
    },
    setAccountOrganizerOrganizerModeActive: (state, action) => {
      state.organizer.organizerModeActive = action.payload;
    },
    //Personal
    setCountryCode: (state, action) => {
      state.personal.countryCode = action.payload;
    },
    setAccountPersonalBannerImage: (state, action) => {
      state.personal.bannerImage = action.payload;
      state.personal.lastModified = Date.now();
    },
    setAccountPersonalBio: (state, action) => {
      state.personal.bio = action.payload;
      state.personal.lastModified = Date.now();
    },
    setEmail: (state, action) => {
      state.personal.email = action.payload;
    },
    setFirstName: (state, action) => {
      state.personal.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.personal.lastName = action.payload;
    },
    setAccountPersonalLinkFacebook: (state, action) => {
      state.personal.links.facebook = action.payload;
      state.personal.lastModified = Date.now();
    },
    setAccountPersonalLinkInstagram: (state, action) => {
      state.personal.links.instagram = action.payload;
      state.personal.lastModified = Date.now();
    },
    setAccountPersonalLinkLinkedIn: (state, action) => {
      state.personal.links.linkedIn = action.payload;
      state.personal.lastModified = Date.now();
    },
    setAccountPersonalLinkPersonalWebsite: (state, action) => {
      state.personal.links.personalWebsite = action.payload;
      state.personal.lastModified = Date.now();
    },
    setAccountPersonalLinkX: (state, action) => {
      state.personal.links.x = action.payload;
      state.personal.lastModified = Date.now();
    },
    setPhoneNumber: (state, action) => {
      state.personal.phoneNumber = action.payload;
    },
    setPrefix: (state, action) => {
      state.personal.prefix = action.payload;
    },
    setProfileImage: (state, action) => {
      state.personal.profileImage = action.payload;
    },
    setPronouns: (state, action) => {
      state.personal.pronouns = action.payload;
    },

    //Speaker
    setAccountSpeakerCommunitySettingsAttendeeFollowRequests: (
      state,
      action
    ) => {
      state.speaker.communitySettings.attendeeFollowRequests = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCountryISO: (state, action) => {
      state.speaker.speakerCountryISO = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerSpeakerModeActive: (state, action) => {
      state.speaker.speakerModeActive = action.payload;
    },
    setAccountSpeakerLanguages: (state, action) => {
      state.speaker.speakerLanguages = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerLanguagesNotListed: (state, action) => {
      state.speaker.speakerLanguagesNotListed = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerGeoPosition: (state, action) => {
      state.speaker.speakerGeoPosition = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerGeoPositionCoordinates: (state, action) => {
      state.speaker.speakerGeoPosition.coordinates = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentials: (state, action) => {
      state.speaker.credentials = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsArtisticActivities: (state, action) => {
      state.speaker.credentials.artisticActivities = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsCertificatesLicenses: (state, action) => {
      state.speaker.credentials.certificatesLicenses = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsEducation: (state, action) => {
      state.speaker.credentials.education = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsEmployment: (state, action) => {
      state.speaker.credentials.employment = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsHonorsAwards: (state, action) => {
      state.speaker.credentials.honorsAwards = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsJobExperience: (state, action) => {
      state.speaker.credentials.jobExperience = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsPresentations: (state, action) => {
      state.speaker.credentials.patentDesigns = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsPatentDesigns: (state, action) => {
      state.speaker.credentials.presentations = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsProjects: (state, action) => {
      state.speaker.credentials.projects = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsPublications: (state, action) => {
      state.speaker.credentials.publications = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsSkillsExpertise: (state, action) => {
      state.speaker.credentials.skillsExpertise = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerCredentialsVolunteerExperience: (state, action) => {
      state.speaker.credentials.volunteerExperience = action.payload;
      state.speaker.lastModified = Date.now();
    },

    setAccountSpeakerBigTags: (state, action) => {
      state.speaker.tags.speakerBigTags = action.payload;
      state.speaker.lastModified = Date.now();
    },
    setAccountSpeakerTinyTags: (state, action) => {
      state.speaker.tags.speakerTinyTags = action.payload;
      state.speaker.lastModified = Date.now();
    },
    resetAccountSpeaker: (state, action) => {
      state.speaker = {
        communitySettings: {
          attendeeFollowRequests: false,
        },
        credentials: {
          artisticActivities: '',
          certificatesLicenses: '',
          education: '',
          employment: '',
          honorsAwards: '',
          jobExperience: '',
          patentDesigns: '',
          presentations: '',
          projects: '',
          publications: '',
          skillsExpertise: '',
          volunteerExperience: '',
        },
        lastModified: '',
        speakerCountryISO: '',
        speakerModeActive: false,
        speakerLanguages: [],
        speakerLanguagesNotListed: [],
        speakerGeoPosition: {
          type: '',
          coordinates: [0, 0],
        },
        signUpComplete: false,
        tags: {
          speakerBigTags: [],
          speakerTinyTags: [],
        },
      };
    },
    //Translations
    setTranslationRoomsRoomCodes: (state, action) => {
      state.translationRooms.roomCodes = action.payload || [];
    },
    setTranslationRoomsRoomCode: (state, action) => {
      const newRoomCode = action.payload;
      const index = state.translationRooms.roomCodes.findIndex(
        (roomCode) => roomCode.roomCode === newRoomCode.roomCode
      );
      if (index !== -1) {
        state.translationRooms.roomCodes[index] = newRoomCode;
      }
    },
  },
});

export const {
  //utils
  emptyAccountData,
  setAccount,

  //account events
  setAccountEvents,
  setArchivedEvents,
  setIgnoredEvents,
  setOrganizedEvents,
  setParticipantDrafts,
  setParticipantEvents,
  setPendingApprovalInPerson,
  setPendingApprovalVirtual,
  setRegistrationsInPerson,
  setRegistrationsVirtual,
  setSavedEvents,

  //app settings
  setContentLanguage,
  setSoundEffects,
  setTimeZone,

  //affiliations

  //attendee
  setAttendeeModeActive,
  setDistanceFilter,
  setDistanceMeasurement,
  setEventSearchOnlineCountries,
  setSearchLanguages,
  setBigTags,
  setTinyTags,
  setTinyTagGroups,

  //biiling
  setCustomerId,
  setPaymentMethodIds,
  setRoomCodeSubscriptions,
  setMyInvoices,
  setFailedInvoices,

  //community
  setCommunity,
  setCommunityFollowing,
  setCommunityFollowingGroups,
  setCommunityBlockedAccounts,
  setCommunityContacts,
  setCommunityContactsRequested,
  setCommunityFollowers,
  addCommunityFollowing,
  removeCommunityFollowing,

  //email preferences
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,

  //meta

  //notices
  setNotices,

  //organizer
  setAccountOrganizerCommunitySettingsDisplayContact,
  setAccountOrganizerOrganizerModeActive,

  //personal
  setCountryCode,
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setPrefix,
  setProfileImage,
  setPronouns,
  setAccountPersonalBannerImage,
  setAccountPersonalBio,
  setAccountPersonalLinkFacebook,
  setAccountPersonalLinkInstagram,
  setAccountPersonalLinkLinkedIn,
  setAccountPersonalLinkPersonalWebsite,
  setAccountPersonalLinkX,

  //speaker
  resetAccountSpeaker,
  setAccountSpeakerBigTags,
  setAccountSpeakerCommunitySettingsAttendeeFollowRequests,
  setAccountSpeakerCredentials,
  setAccountSpeakerCredentialsArtisticActivities,
  setAccountSpeakerCredentialsCertificatesLicenses,
  setAccountSpeakerCredentialsEducation,
  setAccountSpeakerCredentialsEmployment,
  setAccountSpeakerCredentialsHonorsAwards,
  setAccountSpeakerCredentialsJobExperience,
  setAccountSpeakerCredentialsPatentDesigns,
  setAccountSpeakerCredentialsPresentations,
  setAccountSpeakerCredentialsProjects,
  setAccountSpeakerCredentialsPublications,
  setAccountSpeakerCredentialsSkillsExpertise,
  setAccountSpeakerCredentialsVolunteerExperience,
  setAccountSpeakerGeoPosition,
  setAccountSpeakerGeoPositionCoordinates,
  setAccountSpeakerCountryISO,
  setAccountSpeakerLanguages,
  setAccountSpeakerLanguagesNotListed,
  setAccountSpeakerSpeakerModeActive,
  setAccountSpeakerTinyTags,

  //translationRooms
  setTranslationRoomsRoomCodes,
  setTranslationRoomsRoomCode,
} = accountSlice.actions;

export default accountSlice.reducer;

//General
export const selectId = (state) => state.account?._id;
export const selectAccount = (state) => state.account;
export const selectAccountAppSettings = (state) => state.account?.appSettings;
export const selectAccountBilling = (state) => state.account?.billing;
export const selectAccountMeta = (state) => state.account?.meta;
export const selectAccountPersonal = (state) => state.account?.personal;
export const selectAccountSpeaker = (state) => state.account?.speaker;
export const selectAccountAttendee = (state) => state.account?.attendee;
export const selectAccountAttendeeTags = (state) =>
  state.account?.attendee?.tags;
export const selectAccountCommunity = (state) => state.account?.community;

//Account Events
export const selectArchivedEvents = (state) =>
  state.account?.accountEvents?.archivedEvents;
export const selectIgnoredEvents = (state) =>
  state.account?.accountEvents?.ignoredEvents;
export const selectParticipantDrafts = (state) =>
  state.account?.accountEvents?.participantDrafts;
export const selectParticipantEvents = (state) =>
  state.account?.accountEvents?.participantEvents;
export const selectPendingApprovalInPerson = (state) =>
  state.account?.accountEvents?.pendingApprovalInPerson;
export const selectPendingApprovalVirtual = (state) =>
  state.account?.accountEvents?.pendingApprovalVirtual;
export const selectRegistrationsInPerson = (state) =>
  state.account?.accountEvents?.registrationsInPerson;
export const selectRegistrationsVirtual = (state) =>
  state.account?.accountEvents?.registrationsVirtual;
export const selectSavedEvents = (state) =>
  state.account?.accountEvents?.savedEvents;
export const selectOrganizedEvents = (state) =>
  state.account?.accountEvents?.organizedEvents;

//Affiliations
export const selectAffiliations = (state) => state.account?.affiliations;

//AppSettings
export const selectSoundEffects = (state) =>
  state.account?.appSettings?.soundEffects;
export const selectContentLanguage = (state) =>
  state.account?.appSettings?.contentLanguage;
export const selectTimeZone = (state) => state.account?.appSettings?.timeZone;

//Attendee
export const selectAttendeeModeActive = (state) =>
  state.account?.attendee?.attendeeModeActive;
export const selectDistanceFilter = (state) =>
  state.account?.attendee?.distanceFilter;
export const selectDistanceMeasurement = (state) =>
  state.account?.attendee?.distanceMeasurement;
export const selectEventSearchOnlineCountries = (state) =>
  state.account?.attendee?.eventSearchOnlineCountries;
export const selectGeoPosition = (state) =>
  state.account?.attendee?.geoPosition;
export const selectGeoPositionCoordinates = (state) =>
  state.account?.attendee?.geoPosition?.coordinates;
export const selectSearchLanguages = (state) =>
  state.account?.attendee?.searchLanguages;
export const selectBigTags = (state) => state.account?.attendee?.tags?.bigTags;
export const selectTinyTags = (state) =>
  state.account?.attendee?.tags?.tinyTags;
export const selectTinyTagGroups = (state) =>
  state.account?.attendee?.tags?.tinyTagGroups;

//Billing
export const selectCustomerId = (state) => state.account?.billing?.customerId;
export const selectMyInvoices = (state) => state.account?.billing?.myInvoices;
export const selectPaymentMethodIds = (state) =>
  state.account?.billing?.paymentMethodIds;
export const selectRoomCodeSubscriptions = (state) =>
  state.account?.billing?.roomCodeSubscriptions;
export const selectFailedInvoices = (state) =>
  state.account?.billing?.failedInvoices;

//Community
export const selectCommunityFollowing = (state) =>
  state.account?.community?.following;
export const selectCommunityFollowingGroups = (state) =>
  state.account?.community?.followingGroups;
export const selectCommunityBlockedAccounts = (state) =>
  state.account?.community?.blockedAccounts;
export const selectCommunityContacts = (state) =>
  state.account?.community?.contacts;
export const selectCommunityContactsRequested = (state) =>
  state.account?.community?.contactsRequested;
export const selectCommunityFollowers = (state) =>
  state.account?.community?.followers;

//Email Preferences
export const selectEmailPreferencesMyndFullNewsletter = (state) =>
  state.account?.emailPreferences?.myndFullNewsletter;
export const selectEmailPreferencesPromotions = (state) =>
  state.account?.emailPreferences?.promotions;

//Meta
export const selectAccountReference = (state) =>
  state.account?.meta?.accountReference;

//Notices
export const selectNotices = (state) => state.account?.notices;

//Organizer
export const selectAccountOrganizerDisplayContact = (state) =>
  state.account?.organizer?.communitySettings?.displayContact;
export const selectAccountOrganizerOrganizerModeActive = (state) =>
  state.account?.organizer?.organizerModeActive;

//Personal
export const selectCountryCode = (state) =>
  state.account?.personal?.countryCode;
export const selectPersonalBio = (state) => state.account?.personal?.bio;
export const selectPersonalBannerImage = (state) =>
  state.account?.personal?.bannerImage;
export const selectEmail = (state) => state.account?.personal?.email;
export const selectFirstName = (state) => state.account?.personal?.firstName;
export const selectLastName = (state) => state.account?.personal?.lastName;
export const selectPersonalLinkFacebook = (state) =>
  state.account?.personal?.links?.facebook;
export const selectPersonalLinkInstagram = (state) =>
  state.account?.personal?.links?.instagram;
export const selectPersonalLinkLinkedIn = (state) =>
  state.account?.personal?.links?.linkedIn;
export const selectPersonalLinkPersonalWebsite = (state) =>
  state.account?.personal?.links?.personalWebsite;
export const selectPersonalLinkX = (state) => state.account?.personal?.links?.x;
export const selectProfileImage = (state) =>
  state.account?.personal?.profileImage;
export const selectPhoneNumber = (state) =>
  state.account?.personal?.phoneNumber;
export const selectPrefix = (state) => state.account?.personal?.prefix;
export const selectPronouns = (state) => state.account?.personal?.pronouns;

//Speaker
export const selectSpeakerCommunitySettingsAttendeeFollowRequests = (state) =>
  state.account?.speaker?.communitySettings?.attendeeFollowRequests;
export const selectSpeakerLastModified = (state) =>
  state.account?.speaker?.lastModified;
export const selectSpeakerCountryISO = (state) =>
  state.account?.speaker?.speakerCountryISO;
export const selectSpeakerLanguages = (state) =>
  state.account?.speaker?.speakerLanguages;
export const selectSpeakerLanguagesNotListed = (state) =>
  state.account?.speaker?.speakerLanguagesNotListed;
export const selectSpeakerSpeakerModeActive = (state) =>
  state.account?.speaker?.speakerModeActive;
export const selectSpeakerGeoPosition = (state) =>
  state.account?.speaker?.speakerGeoPosition;
export const selectSpeakerGeoPositionCoordinates = (state) =>
  state.account?.speaker?.speakerGeoPosition?.coordinates;
export const selectSpeakerSignUpComplete = (state) =>
  state.account?.speaker?.signUpComplete;

export const selectSpeakerCredentials = (state) =>
  state.account?.speaker?.credentials;
export const selectSpeakerCredentialsArtisticActivities = (state) =>
  state.account?.speaker?.credentials?.artisticActivities;
export const selectSpeakerCredentialsCertificatesLicenses = (state) =>
  state.account?.speaker?.credentials?.certificatesLicenses;
export const selectSpeakerCredentialsEducation = (state) =>
  state.account?.speaker?.credentials?.education;
export const selectSpeakerCredentialsEmployment = (state) =>
  state.account?.speaker?.credentials?.employment;
export const selectSpeakerCredentialsHonorsAwards = (state) =>
  state.account?.speaker?.credentials?.honorsAwards;
export const selectSpeakerCredentialsJobExperience = (state) =>
  state.account?.speaker?.credentials?.jobExperience;
export const selectSpeakerCredentialsPatentDesigns = (state) =>
  state.account?.speaker?.credentials?.patentDesigns;
export const selectSpeakerCredentialsPresentations = (state) =>
  state.account?.speaker?.credentials?.presentations;
export const selectSpeakerCredentialsProjects = (state) =>
  state.account?.speaker?.credentials?.projects;
export const selectSpeakerCredentialsPublications = (state) =>
  state.account?.speaker?.credentials?.publications;
export const selectSpeakerCredentialsSkillsExpertise = (state) =>
  state.account?.speaker?.credentials?.skillsExpertise;
export const selectSpeakerCredentialsVolunteerExperience = (state) =>
  state.account?.speaker?.credentials?.volunteerExperience;

export const selectSpeakerTags = (state) => state.account?.speaker?.tags;
export const selectSpeakerBigTags = (state) =>
  state.account?.speaker?.tags?.speakerBigTags;
export const selectSpeakerTinyTags = (state) =>
  state.account?.speaker?.tags?.speakerTinyTags;

//Translation Rooms
export const selectTranslationRoomsRoomCodes = (state) =>
  state.account?.translationRooms?.roomCodes;

export const selectMyEventsAll = (state) => {
  let myEvents = [];
  if (state.account?.accountEvents?.savedEvents) {
    myEvents = myEvents.concat(state.account.accountEvents.savedEvents);
  }
  if (state.account?.accountEvents?.registrationsInPerson) {
    myEvents = myEvents.concat(
      state.account.accountEvents.registrationsInPerson
    );
  }

  if (state.account?.accountEvents?.registrationsVirtual) {
    myEvents = myEvents.concat(
      state.account.accountEvents.registrationsVirtual
    );
  }

  if (state.account?.accountEvents?.pendingApprovalInPerson) {
    myEvents = myEvents.concat(
      state.account.accountEvents.pendingApprovalInPerson
    );
  }

  if (state.account?.accountEvents?.pendingApprovalVirtual) {
    myEvents = myEvents.concat(
      state.account.accountEvents.pendingApprovalVirtual
    );
  }

  if (state.account?.accountEvents?.archivedEvents) {
    myEvents = myEvents.concat(state.account.accountEvents.archivedEvents);
  }

  if (state.account?.accountEvents?.participantEvents) {
    myEvents = myEvents.concat(state.account.accountEvents.participantEvents);
  }

  if (state.account?.accountEvents?.participantDrafts) {
    myEvents = myEvents.concat(state.account.accountEvents.participantDrafts);
  }

  return myEvents;
};
