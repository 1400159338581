import { useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useGenericModal from '../../../../context/useGenericModal';

//Components
import Spinner from '../../../Spinner/Spinner';

//Utility
import { cancel, trash, warning } from '../../../../assets/icons';

const SelectDropdownModal = ({
  id,
  selectionKey,
  selectionValue,
  handleModalSelection,
  SelectionOpts,
  modalLabel,
  index,
  multiDropdown,
  multiDropdownRemove,
}) => {
  //Hooks
  const { Generic, SelectDropdownModal } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //Component state
  const [filteredList, setFilteredList] = useState();

  //UI state
  const [tapHighlightRemoveBtn, setTapHighlightRemoveBtn] = useState(false);
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //Component variables
  let tagsList;

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId(`${id}-dropdown-main`);
  }, []);

  //Component functions
  useEffect(() => {
    if (selectionKey !== null && SelectionOpts[selectionKey]) {
      const filteredList = { ...SelectionOpts };
      delete filteredList[selectionKey];
      setFilteredList(filteredList);
    } else {
      setFilteredList(SelectionOpts);
    }
  }, [selectionKey, SelectionOpts]); //removes selected tags

  function handleRemove(removeKey) {
    if (multiDropdownRemove) {
      handleMobileTap(
        [
          () => setTapHighlightRemoveBtn(true),
          () => setTapHighlightRemoveBtn(false),
        ],
        [() => multiDropdownRemove(removeKey), () => handleCloseModal()]
      );
    } else {
      handleMobileTap(
        [
          () => setTapHighlightRemoveBtn(true),
          () => setTapHighlightRemoveBtn(false),
        ],
        [() => handleModalSelection('select'), () => handleCloseModal()]
      );
    }
  }

  useEffect(() => {
    if (filteredList) {
      document.getElementById(`selectionDropdownModalLabel-${id}`).focus();
    }
  }, [filteredList]);

  //JSX conditional
  if (filteredList) {
    if (Object.keys(filteredList)?.length > 0) {
      let lastItemIndex = Object.entries(filteredList).length - 1;

      tagsList = (
        <>
          {Object.entries(filteredList).map(([key, value], index) => {
            const isLastItem = index === lastItemIndex;
            if (key === 'select') return null;

            return (
              <li
                key={`${key}`}
                style={isLastItem ? { marginBottom: '160px' } : null}
              >
                <button
                  className={`item fs16 fwsb ${
                    tapHighlightSelectedKey === key ? 'item-selected' : ''
                  }`}
                  key={`list-item-${index}`}
                  onClick={() =>
                    handleMobileTap(
                      [
                        () => setTapHighlightSelectedKey(key),
                        () => setTapHighlightSelectedKey(),
                      ],
                      [
                        () => handleModalSelection(key),
                        () => handleCloseModal(),
                      ]
                    )
                  }
                  type="button"
                  tabIndex="0"
                  aria-label={value.country}
                  id={`list-item-${index}`}
                >
                  {value}
                </button>
              </li>
            );
          })}
        </>
      );
    } else {
      tagsList = (
        <>
          <div className="h24" />
          <div className="flex-center flex-column align-center">
            <img
              src={warning}
              alt={Generic.warning}
              className="svg"
              style={{ maxHeight: '40px' }}
            />
            <p className="fs18 fwn flex-center text-center mrg-tb24">
              {SelectDropdownModal.noMoreSelections}
            </p>
          </div>
          <div className="h24" />
        </>
      );
    }
  } else {
    tagsList = <Spinner minHeight={'100%'} />;
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="selectDropdownModal"
      key={`selectdropdownmodal-${index}`}
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`selectionDropdownModalLabel-${id}`}
          >
            {modalLabel}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob ${
            tapHighlightCloseBtn ? 'filter-img-red' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBtn(true),
                () => setTapHighlightCloseBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={Generic.close} className="svg" />
        </button>
      </div>
      <ul
        className="modal-basic__container list-style-none"
        id="selectDropdownList"
      >
        {tagsList}
      </ul>
      <div className="modal-basic__footer">
        <button
          type="button"
          className={`min-full-width highlight-i-lgr color-black access-ob ${
            tapHighlightRemoveBtn ? 'filter-img-red' : 'filter-img-lightgray'
          }`}
          aria-label={Generic.clearSelection}
          onClick={() => handleRemove(selectionKey)}
        >
          {selectionValue && selectionKey !== 'select' ? (
            <>
              <div className="flex-row item fwsb fs16">{selectionValue}</div>
              <img
                src={trash}
                alt={Generic.remove}
                className="trashbin svg mrg-l12"
              />
            </>
          ) : multiDropdown ? (
            <div className="flex-row align-center">
              <img
                src={trash}
                alt={Generic.remove}
                className="trashbin svg mrg-l12"
              />
            </div>
          ) : (
            <div className="flex-row">
              <p
                className={`fs18 fwsb access-ob access-o6 ${
                  tapHighlightRemoveBtn ? 'color-theme' : 'color-black'
                }`}
              >
                {Generic.close}
              </p>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default SelectDropdownModal;
