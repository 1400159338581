import { useState, useEffect, useRef } from 'react';
//Hooks
import useSettings from '../../context/useSettings';

//Components
import WhatsThis from '../WhatsThis/WhatsThis';

//Utility

const Selections = ({
  id,
  options,
  handleSelection,
  whatsThis,
  whatsThisType,
  whatsThisDescriptions,
  loadTrigger,
  loadValue,
  customWrapper,

  //views
  viewHorizontal,
  viewVertical,
  viewSingleColumn,
}) => {
  //Component state
  const [selectedOption, setSelectedOption] = useState(null);

  //UI state
  const { handleMobileTap } = useSettings();
  const [tapHighlight, setTapHighlight] = useState();

  //Component variables
  const effectRan = useRef(false);
  let whatsThisSelectedDescription;

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          setSelectedOption(loadValue);
        }
        effectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  if (whatsThisDescriptions) {
    Object.entries(whatsThisDescriptions).map(([key, value]) => {
      if (key === selectedOption) {
        return (whatsThisSelectedDescription = value);
      }
    });
  }

  //Component functions
  function setOption(selectionKey) {
    if (selectionKey === selectedOption) {
      setSelectedOption();
      handleSelection();
    } else {
      setSelectedOption(selectionKey);
      handleSelection(selectionKey);
    }
  }
  return (
    <div
      id={`Selections-${id}`}
      className={`selections__wrapper ${customWrapper ? customWrapper : ''}`}
    >
      <ul
        className={`selections__container ${
          viewHorizontal ? 'selections__container--horizontal-view' : ''
        } ${viewVertical ? 'selections__container--vertical-view' : ''} ${
          viewSingleColumn ? 'selections__container--single-column' : ''
        }`}
      >
        {Object.entries(options).map(([key, value], index) => (
          <li key={`${id}-${key}`}>
            <button
              id={key}
              type="button"
              className={`selection-btn access-ob access-o6 ${
                selectedOption === key ? 'selection-btn--active' : ''
              } ${tapHighlight === index ? 'highlight-tb-theme--tap' : ''}`}
              key={index}
              onClick={() =>
                handleMobileTap(
                  [() => setTapHighlight(index), () => setTapHighlight()],
                  [() => setOption(key)]
                )
              }
              aria-pressed={selectedOption === key}
            >
              {value}
            </button>
          </li>
        ))}
      </ul>
      {/* {whatsThis && selectedOption && (
        <WhatsThis
          type={whatsThisType}
          description={whatsThisSelectedDescription}
        />
      )} */}
    </div>
  );
};

export default Selections;
