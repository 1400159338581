//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useSettings from '../../../../context/useSettings';
//Components
import Views from './Views';
import SearchSummary from './SearchSummary';
import Filters from './Filters';
import TagsList from './TagsList';
import FiltersMobile from './FiltersMobile';
import TagsToggle from './TagsToggle';

//Utility

const SettingsEvents = () => {
  //Hooks
  const { width } = useSettings();
  const {
    settingsEventSearchView: view,
    settingsBigTagsListOpened,
    settingsTinyTagsListOpened,
    settingsMyEventsListOpened,
    settingsAccountsFollowingListOpened,
  } = useSettingsHandlers();

  //Component variables
  let settingsEventsComponent;

  //JSX conditional
  if (width > 1280) {
    settingsEventsComponent = (
      <>
        <div className="search-sort no-select mrg-t12">
          <Views view={view} />
          <Filters />
        </div>
        <div className="summary-heading color-gray fs18 fwsb mrg-t16">
          <TagsToggle view={view} />
          <SearchSummary width={width} />
        </div>
        {view === 'myevents' && settingsMyEventsListOpened && <TagsList />}
        {view === 'bigtags' && settingsBigTagsListOpened && <TagsList />}
        {view === 'tinytags' && settingsTinyTagsListOpened && <TagsList />}
        {view === 'community' && settingsAccountsFollowingListOpened && (
          <TagsList />
        )}
      </>
    );
  } else {
    settingsEventsComponent = (
      <div className="search-sort--mobile no-select mrg-t12">
        <Views view={view} />
        <SearchSummary width={width} />
        <FiltersMobile />
      </div>
    );
  }

  return (
    <div role="complementary">
      <div className="h24" />
      {settingsEventsComponent}
    </div>
  );
};

export default SettingsEvents;
