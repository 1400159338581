import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

//hooks
import useApiStatus from '../../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useSettings from '../../../../context/useSettings';

//components
import EventPage from './EventPage';

//utils
import { useEventHandlers } from '../useEventHandlers';

const StandaloneEventPage = () => {
  //hooks
  const { eventId } = useParams();
  const { accountId } = useAccountReduxHandlers();
  const { handleError } = useApiStatus();
  const { setNavbar } = useSettings();
  const navigate = useNavigate();
  const { handleGetEvent } = useEventHandlers();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState();

  //var
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false && eventId && accountId) {
      effectRan.current = true;
      setNavbar('');
      if (eventId) {
        handleGetEvent({ setEventData, setIsLoading, isLoading, eventId });
      }
    }
  }, [eventId, accountId]);

  return (
    <div className="flex-center mrg-auto-lr">
      {eventData && (
        <EventPage
          previewEventData={eventData}
          participantPreviewOn={true}
          handleCustomReturn={() => {
            navigate('/');
          }}
        />
      )}
    </div>
  );
};

export default StandaloneEventPage;
