import { useState, useEffect } from 'react';
//Hooks
import { useUpdatePhotoMutation } from '../../accountApiSlice';
import useApiStatus from '../../../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import ChangeButton from '../../../../../components/ChangeButton/ChangeButton';
import Spinner from '../../../../../components/Spinner/Spinner';

//Utility
import convertBase64ToJPEG from '../../../../../utils/images/convertBase64ToJPEG';
import getDBImageBlob from '../../../../../utils/images/getDBImageBlob';

const ProfilePhoto = ({ Account }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const { accountId, accountProfileImage } = useAccountReduxHandlers();
  const [updateAccountPhoto, { isLoading: updateAccountPhotoIsLoading }] =
    useUpdatePhotoMutation();

  //Component state
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });
  const [convertingImage, setConvertingImage] = useState(false);
  const [changeProfileImage, setChangeProfileImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //UI state

  //Initialize functions
  useEffect(() => {
    if (
      accountProfileImage !== 'account_img_default.png' &&
      changeProfileImage
    ) {
      getDBImageBlob(accountProfileImage, 'account', setCroppedImg);
    }
  }, [accountProfileImage, changeProfileImage]); //gets new blob for new profileImage or if user cancels change

  useEffect(() => {
    return () => {
      handleCancelSaveImage();
    };
  }, []);

  async function saveAccountProfilePhotoChange(e) {
    setIsLoading(false);
    e.preventDefault();

    if (!updateAccountPhotoIsLoading) {
      const formData = new FormData();
      const profileImage = croppedImg;
      let profileImgJPG;

      if (profileImage.croppedImage.croppedImage) {
        profileImgJPG = await convertBase64ToJPEG(
          profileImage.croppedImage.croppedImage,
          'profileImage.jpg'
        );
      }

      //5 mb max.
      if (profileImgJPG?.size >= 5242880) {
        return handleError({
          message: 'imageSizeTooLarge',
          id: Date.now(),
          origin: 'ProfilePhoto.js/saveAccountProfilePhotoChange',
        });
      }

      formData.append('profileImage', profileImgJPG);
      formData.append('accountId', accountId);

      await updateAccountPhoto(formData);
      setChangeProfileImage(false);
    }
    setIsLoading(false);
  }

  //Component functions
  function handleProfileImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  function handleCancelSaveImage() {
    if (croppedImg.imagePreview) {
      URL.revokeObjectURL(croppedImg.imagePreview);
    }
    setCroppedImg({
      croppedImage: '',
      fileName: '',
      imagePreview: '',
    });
    setChangeProfileImage(false);
  }

  return (
    <section className="section">
      <label
        className="content-heading-styled text-gray "
        tabIndex="0"
        id="profilePhotoLabel"
      >
        {Account.account.profilePhoto}
      </label>
      {changeProfileImage && (
        <>
          <div className="h24" />
          <ImageCropper
            ariaImageDescription={Account.account.profilePhoto}
            center={true}
            cover={'horizontal-cover'}
            handleImage={handleProfileImg}
            height={264}
            id={'profile'}
            imagePreview={croppedImg.imagePreview}
            profileFrame={true}
            setHOActionBtnsUnavailable={setConvertingImage}
            width={264}
            customFileUploaderWrapper={'file-uploader__wrapper--profile'}
            customFileUploaderContainer={'file-uploader__container--profile'}
            spinnerMinWidth={'314px'}
            spinnerMinHeight={'314px'}
          />
        </>
      )}
      <div className="h24" />
      {changeProfileImage && !convertingImage && !isLoading && (
        <ConfirmSelection
          btn1Text={Account.generic.cancel}
          btn1Color={'neutral'}
          btn1Handler={handleCancelSaveImage}
          btn2Text={Account.generic.save}
          btn2Color={'pos'}
          btn2Handler={saveAccountProfilePhotoChange}
          center={true}
          customWrapper={'mrg-auto-lr'}
        />
      )}

      <div className="flex-center flex-column">
        {!changeProfileImage && (
          <>
            <LoadBucketImage
              imagePath={accountProfileImage}
              imgClassName={'profile-image'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
            <div className="h24" />
            {isLoading ? (
              <Spinner minHeight={'45px'} maxHeight={'45px'} />
            ) : (
              <ChangeButton handler={setChangeProfileImage} />
            )}
          </>
        )}
      </div>
      <div className="h36" />
    </section>
  );
};

export default ProfilePhoto;
