import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
  useLazyMyEventsArchivedQuery,
  useMyEventsArchivedSelectors,
} from '../../../../events/eventsApiSlice';

//hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useSettings from '../../../../../../context/useSettings';
import { useSettingsHandlers } from '../../../../settings/useSettingsHandlers';
//components
import Spinner from '../../../../../../components/Spinner/Spinner';
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import { refresh } from '../../../../../../assets/icons';

const ArchivedEvents = ({ Account, width }) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { handleMobileTap } = useSettings();
  const { handleSettingsSetViewEventDetails } = useSettingsHandlers();
  const { sortedMyEventsArchived } = useMyEventsArchivedSelectors();
  const myEventsArchivedSorted = useSelector(sortedMyEventsArchived);

  //UI
  const [highlightViewTap, setHighlightViewTap] = useState(false);

  //API
  const [
    getMyEventsArchived,
    {
      isLoading: myEventsArchivedisLoading,
      isSuccess: myEventsArchivedisSuccess,
    },
  ] = useLazyMyEventsArchivedQuery();

  //var
  let myArchivedEventsList;

  if (myEventsArchivedSorted?.length > 0) {
    myArchivedEventsList = (
      <div id="fetched-list">
        <div className="h24" />
        <ul
          className="ignored-event__container access-ob access-o6"
          tabIndex="0"
          aria-label={Account.attendee.ariaIgnoredList}
        >
          {[...myEventsArchivedSorted].reverse().map((ev) => {
            return (
              <li key={ev._id} className="ignored-event access-ob" tabIndex="0">
                {width > 580 ? (
                  <p
                    className="fs18 fwn access-ob access-o6"
                    tabIndex="0"
                    aria-label={`${ev.generic.title}.`}
                  >
                    {ev.generic.title.substring(0, 100)}
                    {ev.generic.title.length > 100 && ' ...'}
                  </p>
                ) : (
                  <p
                    className="fs18 fwn access-ob access-o6"
                    tabIndex="0"
                    aria-label={`${ev.title}.`}
                  >
                    {ev.generic.title.substring(0, 28)}
                    {ev.generic.title.length > 28 && ' ...'}
                  </p>
                )}
                <div className="flex-row space-between mrg-t6">
                  <button
                    className={`fs16 highlight-it-theme fwn access-ob ${
                      highlightViewTap ? 'highlight-it-theme--tap' : ''
                    }`}
                    aria-label={`${Account.attendee.viewEvent}.`}
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setHighlightViewTap(true),
                          () => setHighlightViewTap(false),
                        ],
                        [() => handleSettingsSetViewEventDetails(ev)]
                      )
                    }
                  >
                    {Account.attendee.viewEvent}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="archivedEventsLabel"
      >
        {Account.attendee.archivedEvents}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.archivedEventsDescription}
      </p>

      <div className="flex-column flex-center">
        {myEventsArchivedisLoading && (
          <>
            <div className="h24" />
            <Spinner />
          </>
        )}
      </div>

      {myEventsArchivedisSuccess && myEventsArchivedSorted?.length === 0 ? (
        <p className="fs18 italic mrg-t36 mrg-b24 flex-center border-thin br--small pad-12">
          {Account.attendee.noEventsFound}
        </p>
      ) : (
        myEventsArchivedisSuccess &&
        myEventsArchivedSorted?.length > 0 && <>{myArchivedEventsList}</>
      )}

      {!myEventsArchivedisSuccess && !myEventsArchivedisLoading && (
        <>
          <div className="h24" />
          <ItemButton
            altText={Account.attendee.fetchList}
            ariaLabel={Account.attendee.fetchList}
            handler={() => getMyEventsArchived(accountId)}
            icon={refresh}
            iconOn={true}
            text={Account.attendee.fetchList}
            fullWidth={true}
            iconStyle={{ height: '24px' }}
          />
        </>
      )}

      <div className="h36" />
    </div>
  );
};

export default ArchivedEvents;
