import { useEffect, useRef, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import Calendar from 'react-calendar';

//utility
import { warning } from '../../assets/icons';
import {
  checkLoadDateExpiration,
  useDateTimeCalculations,
} from '../../utils/dateTime/DateCalc';

const CalendarSimple = ({ initialize, handleSetDate, loadDate }) => {
  //hooks
  const { accountContentLanguage } = useAccountReduxHandlers();
  const { CalendarSimple } = useLanguageComponents();
  const { getDateLongMonth } = useDateTimeCalculations();
  //state
  const [selectedDateFormatted, setSelectedDateFormatted] = useState();
  const [loadDateFormatted, setLoadDateFormatted] = useState(
    loadDate ? loadDate.split('-') : null
  );

  //variables
  const effectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        handleCalendarSummaryDate();
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //api
  //functions
  function handleCalendarSummaryDate() {
    if (loadDate && accountContentLanguage) {
      const date = new Date(loadDate);
      const loadDateIsExpired = checkLoadDateExpiration(loadDate);

      if (loadDateIsExpired) {
        setLoadDateFormatted();
        handleSetDate(null);
      } else {
        const dateSlice = date.toJSON().slice(0, 10);
        const formattedDate = getDateLongMonth(
          dateSlice,
          accountContentLanguage
        );
        setSelectedDateFormatted(formattedDate);
      }
    }
  }

  function handleCalendar(e) {
    const selectedDate = e instanceof Date ? e : new Date(e);
    const reduxDate = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10);

    handleSetDate(reduxDate);
    ////display below calendar
    const formattedDate = getDateLongMonth(reduxDate, accountContentLanguage);
    setSelectedDateFormatted(formattedDate);
  }

  return (
    <div className="calendar__wrapper">
      <Calendar
        tabIndex="0"
        onChange={(e) => handleCalendar(e)}
        minDetail={'month'}
        view={'month'}
        next2Label={null}
        prev2Label={null}
        minDate={new Date()}
        calendarType={'US'}
        locale={accountContentLanguage}
        defaultValue={
          loadDateFormatted
            ? new Date(
                loadDateFormatted[0],
                loadDateFormatted[1] - 1,
                loadDateFormatted[2]
              )
            : null
        }
      />
      {selectedDateFormatted ? (
        <h2
          className="flex-center access-ob fit-content mrg-auto-left mrg-auto-right mrg-t12"
          tabIndex="0"
          id="calendar-sum"
        >
          {selectedDateFormatted}
        </h2>
      ) : (
        <div className="flex-row mrg-auto-lr mrg-t12 align-center">
          <h2 className="access-ob" tabIndex="0" id="calendar-sum">
            {CalendarSimple.calendarSelectDay}
            <span className="color-theme"> *</span>
          </h2>
        </div>
      )}
    </div>
  );
};

export default CalendarSimple;
