//Hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageData from '../../../../language/useLanguageData';

//Components
import CustomTagsList from '../../../../components/CustomTagsList/CustomTagsList';
import SelectionsModal from '../../../../components/Selections/SelectionsModal';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';

//Utility
import { bigtag_nobg } from '../../../../assets/icons';

const EventTags = ({
  CreateEvent,
  skipToId,
  skipToLabel,
  //state
  loadTinyTags,
  loadBigTags,
  //handlers
  handleSetBigTags,
  handleSetTinyTags,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { BigTagsOpts, BigTagsOptsWithSubText } = useLanguageData();

  return (
    <fieldset className="fieldset" id="fieldset-tags">
      <legend id="aria-fieldset-instructions-tags">
        {CreateEvent.tags.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="tagsLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-tags"
      >
        {CreateEvent.tags.tags}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />

      <div className="flex-row space-between">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="bigTagsLabel"
        >
          {CreateEvent.tags.bigTags}
          <p className="color-theme">&nbsp;*</p>
        </label>
      </div>
      <div className="h12" />
      <p
        className="description-text"
        tabIndex="0"
        aria-label={CreateEvent.tags.ariaBigTagsInputInstructions}
      >
        {CreateEvent.tags.bigTagsDescription}
      </p>
      <div className="h12" />

      <SelectionsModal
        addButtonText={`${Generic.add} ${Generic.bigTags}`}
        DataOpts={BigTagsOptsWithSubText}
        DataOptsSubTextTranslations={BigTagsOpts}
        fullWidth={true}
        iconModal={bigtag_nobg}
        iconOff={true}
        limit={5}
        modalTitle={Generic.bigTags}
        selectionState={loadBigTags}
        setSelectionState={handleSetBigTags}
        subTextOn={true}
        id={'bigTagSelections'}
        itemButtonCustomWrapper={'item-button-standard'}
      />
      <div className="h48" />

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="tinyTagsLabel"
      >
        {CreateEvent.tags.tinyTags}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.tags.tinyTagsDescription}
      </p>
      <div className="h12" />
      <CustomTagsList
        limit={20}
        id={'tinyTags'}
        tinyTags={loadTinyTags}
        tagGroupsOff={true}
        bigTags={loadBigTags}
        setTinyTags={handleSetTinyTags}
      />
    </fieldset>
  );
};

export default EventTags;
