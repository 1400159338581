import { useEffect, useState } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import { useEventParticipantsHandlers } from '../../organize/eventParticipants/useEventParticipantsHandlers';

//Components
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import HeadingSummary from './HeadingSummary';
import ActionButtons from './ActionButtons';
import RegistrationButtons from './RegistrationButtons';
import GeneralContent from './GeneralContent';
import HostingInPersonContent from './HostingInPersonContent';
import HostingVirtualContent from './HostingVirtualContent';
import AgendaContent from './AgendaContent';
import AccessibilityContent from './AccessibilityContent';
import TagsContent from '../../../../components/TagsContent/TagsContent';
import ReportEvent from './ReportEvent';
import LoadingScreenContent from '../../../../components/LoadingScreenContent/LoadingScreenContent';
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import ParticipantsContent from './ParticipantsContent';
import EventAdditionalImages from './EventAdditionalImages';
import EventPageTranslations from './EventPageTranslations';
import Spinner from '../../../../components/Spinner/Spinner';
import EventPageContact from './EventPageContact';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';
import { img_exit } from '../../../../assets/icons';

/*eslint-disable*/

const EventPage = ({
  getNewCommunityEventsList,
  handleCustomReturn,
  organizerViewOn,
  participantPreviewOn,
  previewEventData,
  //
  eventDisplayImageCropped,
  participantImagesCropped,
  eventAdditionalImagesCropped,
}) => {
  //Hooks
  const { width, handleMobileTap } = useSettings();
  const {
    accountSavedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountIgnoredEvents,
    accountArchivedEvents,
    accountId,
    accountParticipantDrafts,
    accountParticipantEvents,
  } = useAccountReduxHandlers();
  const { handleSettingsSetViewEventDetails, settingsViewEventDetails } =
    useSettingsHandlers();
  const { EventPage } = useLanguageEvents();
  const { Generic } = useLanguageComponents();
  const { handleWithdrawAsParticipant } = useEventParticipantsHandlers();

  //Component state
  const [eventStatus, setEventStatus] = useState('neutral');
  const [eventData, setEventData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isParticipant, setIsParticipant] = useState(false);

  //UI
  const [withdrawAsParticipantIsLoading, setWithdrawAsParticipantIsLoading] =
    useState(false);
  const [tapHighlightWithdrawFromEvent, setTapHighlightWithdrawFromEvent] =
    useState();

  //Initialize functions
  useEffect(() => {
    ScrollTo('navbar', 'instant', true);
  }, []);

  useEffect(() => {
    if (previewEventData) {
      setEventData(previewEventData);
    } else {
      setEventData(settingsViewEventDetails);
    }
  }, [settingsViewEventDetails, previewEventData]);

  useEffect(() => {
    if (
      accountParticipantDrafts.includes(eventData?._id) ||
      accountParticipantEvents.includes(eventData?._id)
    ) {
      setIsParticipant(true);
    } else {
      setIsParticipant(false);
    }
  }, [eventData, accountParticipantDrafts, accountParticipantEvents]);

  //Component functions
  useEffect(() => {
    //Generic status
    if (accountSavedEvents?.includes(eventData?._id)) {
      return setEventStatus('saved');
    }

    if (accountIgnoredEvents?.includes(eventData?._id)) {
      return setEventStatus('ignored');
    }
    if (accountArchivedEvents?.includes(eventData?._id)) {
      return setEventStatus('archived');
    }

    //InPerson
    if (accountPendingApprovalInPerson?.includes(eventData?._id)) {
      if (accountRegistrationsVirtual.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('pendingApprovalInPerson');
      }
    }

    if (accountRegistrationsInPerson.includes(eventData?._id)) {
      if (accountPendingApprovalVirtual.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('registeredInPerson');
      }
    }

    //Virtual
    if (accountPendingApprovalVirtual?.includes(eventData?._id)) {
      if (accountRegistrationsInPerson.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('pendingApprovalVirtual');
      }
    }

    if (accountRegistrationsVirtual.includes(eventData?._id)) {
      if (accountPendingApprovalInPerson.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('registeredVirtual');
      }
    }

    /*eslint-disable-next-line*/
  }, [
    accountSavedEvents,
    accountIgnoredEvents,
    accountArchivedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  async function handleWithdrawFromEvent() {
    setWithdrawAsParticipantIsLoading(true);
    let updateEventData = {
      eventId: eventData?._id,
      eventType: '',
    };

    if (eventData.meta.isDraft) {
      updateEventData.eventType = 'draft';
    } else if (eventData?.schedule?.enabled) {
      updateEventData.eventType = 'scheduledEvent';
    } else {
      updateEventData.eventType = 'event';
    }

    await handleWithdrawAsParticipant({
      accountId,
      updateEventData,
      organizerId: eventData?.organizers?.organizerId,
    });

    const timer = setTimeout(() => {
      setWithdrawAsParticipantIsLoading(false);
      setIsParticipant(false);
    }, 500);

    return () => clearTimeout(timer);
  }

  return (
    <>
      {eventData && (
        <>
          {isLoading && (
            <LoadingScreenContent
              numberOfLoadChecks={1}
              setLoadComplete={() => {
                setIsLoading(false);
              }}
              loadCheck1={eventData ? true : false}
              customWrapper={'min-full-height'}
            />
          )}

          <div className={`ev ${isLoading ? 'remove' : ''}`}>
            <LoadBucketImage
              altAriaText={eventData?.generic?.eventImageDisplay?.alt}
              backupPath={'event_img_default.png'}
              containerClassName={'ev-display-container'}
              croppedImage={
                previewEventData && eventDisplayImageCropped
                  ? eventDisplayImageCropped?.croppedImage?.croppedImage
                  : null
              }
              imagePath={eventData?.generic?.eventImageDisplay?.imagePath}
              imgClassName={'ev-display-image'}
              partialPath={'myndfull_event_images'}
            />

            <div className="ev-main-container">
              <div
                className="ev-divider-bottom full-width flex-center fs21 fwsb  mrg-auto-lr pad-b24 access-ob"
                tabIndex="0"
              >
                {eventData?.generic?.title}
              </div>
              <HeadingSummary
                eventData={eventData}
                width={width}
                EventPage={EventPage}
                getNewCommunityEventsList={getNewCommunityEventsList}
                previewMode={previewEventData}
                organizerViewOn={organizerViewOn}
              />

              <ActionButtons
                eventData={eventData}
                eventStatus={eventStatus}
                handleMobileTap={handleMobileTap}
                EventPage={EventPage}
                handleReturn={
                  handleCustomReturn
                    ? handleCustomReturn
                    : handleSettingsSetViewEventDetails
                }
                setEventStatus={setEventStatus}
                organizerViewOn={organizerViewOn}
                registrationsComponentOn={
                  eventData.registrationData.registrationsActive
                }
                participantPreviewOn={participantPreviewOn}
              />

              {eventData.registrationData.registrationsActive && (
                <RegistrationButtons
                  eventData={eventData}
                  eventStatus={eventStatus}
                  EventPage={EventPage}
                  Generic={Generic}
                  handleMobileTap={handleMobileTap}
                  setEventStatus={setEventStatus}
                  organizerViewOn={organizerViewOn}
                  participantPreviewOn={participantPreviewOn}
                />
              )}

              {isParticipant && (
                <div className={`registration-action-row  flex-center`}>
                  <button
                    className={`rar-btn flex-column highlight-i-theme ${
                      tapHighlightWithdrawFromEvent ? `click-scaledown-95` : ''
                    } ${
                      organizerViewOn || participantPreviewOn ? 'opacity-3' : ''
                    }`}
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setTapHighlightWithdrawFromEvent(true),
                          () => setTapHighlightWithdrawFromEvent(false),
                        ],
                        [() => handleWithdrawFromEvent()]
                      )
                    }
                  >
                    {withdrawAsParticipantIsLoading ? (
                      <Spinner />
                    ) : (
                      <div className="flex-row full-width ">
                        <img
                          className="filter-lightgray"
                          src={img_exit}
                          alt={EventPage.registrationIcon}
                        />
                        <div className="flex-column flex-center full-width">
                          <p className="">{EventPage.withdrawAsParticipant}</p>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              )}

              <div className="flex-column fs18 mrg-b24 pad-t24">
                <GeneralContent eventData={eventData} EventPage={EventPage} />

                {eventData?.contact?.provideContact && (
                  <EventPageContact
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                  />
                )}
                <EventAdditionalImages
                  eventData={previewEventData ? previewEventData : eventData}
                  eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                  previewMode={previewEventData ? true : false}
                />

                {(eventData.hosting.hostingOption === 'inPerson' ||
                  eventData.hosting.hostingOption === 'hybrid') && (
                  <HostingInPersonContent
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    handleMobileTap={handleMobileTap}
                    organizerViewOn={organizerViewOn}
                    previewMode={previewEventData}
                  />
                )}
                {(eventData.hosting.hostingOption === 'online' ||
                  eventData.hosting.hostingOption === 'hybrid') && (
                  <HostingVirtualContent
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                  />
                )}

                {eventData?.translations?.translationsEnabled && (
                  <EventPageTranslations
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                  />
                )}
                <ParticipantsContent
                  participantsData={eventData?.participants}
                  participantImagesCropped={participantImagesCropped}
                  EventPage={EventPage}
                  previewMode={previewEventData ? true : false}
                />

                {eventData.generic?.agenda?.items?.length > 0 && (
                  <AgendaContent
                    eventData={eventData}
                    EventPage={EventPage}
                    agendaData={eventData.generic.agenda}
                  />
                )}
                {(eventData.generic.wheelchairAccessibility ||
                  eventData.generic.signLanguageInterpreter ||
                  eventData.generic.accessibilityNotes?.length) > 0 && (
                  <AccessibilityContent
                    genericData={eventData.generic}
                    EventPage={EventPage}
                  />
                )}

                <TagsContent
                  bigTagsContent={eventData.tags.bigTags}
                  tinyTagsContent={eventData.tags.tinyTags}
                />
                <div className="h72" />
              </div>

              <ScrollToTop focusId={'actionBtns'} />
              <div className="h48" />
              <ReportEvent
                handleMobileTap={handleMobileTap}
                EventPage={EventPage}
                eventData={eventData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventPage;
