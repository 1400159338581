import { useEffect, useState } from 'react';

//hooks
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAuthReduxHandlers } from '../../../../auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import { useUpdateRolesMutation } from '../../../accountApiSlice';

//components
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utility

const SpeakerRoleToggle = () => {
  //hooks
  const { accountId, accountSpeakerSpeakerModeActive: loadSpeakerModeActive } =
    useAccountReduxHandlers();
  const { SpeakerRoleToggle } = useLanguageAccount();
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();

  //api
  const [updateRoles, { isLoading: updateAccountRolesIsLoading }] =
    useUpdateRolesMutation();

  //component state
  const [rolesChangeValid, setRolesChangeValid] = useState(false);
  const [newSpeakerModeActive, setNewSpeakerModeActive] = useState(
    loadSpeakerModeActive
  );

  //Component functions
  async function handleSaveRoleChange() {
    if (rolesChangeValid) {
      if (!updateAccountRolesIsLoading) {
        await updateRoles({
          accountId,
          newData: {
            speakerRoleChange: newSpeakerModeActive,
          },
        });
        setRolesChangeValid(false);
      }
    }
  }

  useEffect(() => {
    if (newSpeakerModeActive !== loadSpeakerModeActive) {
      setRolesChangeValid(true);
    } else {
      setRolesChangeValid(false);
    }
  }, [newSpeakerModeActive, loadSpeakerModeActive]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="speakerRoleToggleLabel"
      >
        {SpeakerRoleToggle.speakerActivation}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {SpeakerRoleToggle.description}
      </p>

      <ButtonSwitch
        id={'speakerToggle'}
        loadValue={loadSpeakerModeActive}
        loadTrigger={true}
        handleSwitch={() => setNewSpeakerModeActive(!newSpeakerModeActive)}
        reset={loadRoles}
        customWrapper={'mrg-t12'}
      />
      <div className="h24" />
      <SaveButton
        saveValid={rolesChangeValid}
        handler={handleSaveRoleChange}
        isLoading={updateAccountRolesIsLoading}
        saveAriaLanguage={SpeakerRoleToggle.speakerActivation}
      />
      <div className="h36" />
    </div>
  );
};

export default SpeakerRoleToggle;
