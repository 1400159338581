import { useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import {
  useArchiveNoticeMutation,
  useRemoveMyNoticeMutation,
} from '../../noticesApiSlice';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';
import Spinner from '../../../../../components/Spinner/Spinner';

//utils
import { img_archived, trash } from '../../../../../assets/icons';

const NoticeResponse = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { IconAlts, NoticeSpeakerComponents } = useLanguageComponents();

  //api
  const [archiveNotice, { isLoading: archiveNoticeIsLoading }] =
    useArchiveNoticeMutation();
  const [removeNotice, { isLoading: removeNoticeIsLoading }] =
    useRemoveMyNoticeMutation();

  //ui
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container mrg-t12">
              <div className="divider-dotted mrg-b24" />
              {n?.noticeHistory ? (
                <>
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.archiveNotice}
                    handler={() => {
                      if (
                        !archiveNoticeIsLoading &&
                        !removeNoticeIsLoading &&
                        !isLoading
                      ) {
                        setIsLoading(true);
                        archiveNotice({ accountId, noticeId: n._id });
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={img_archived}
                    iconRight={true}
                    iconClass={'mrg-l6  filter-gray'}
                    customClassText={'color-darkgray'}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                  />
                  <button
                    className="notice-history-summary color-gray highlight-t-theme mrg-t12 access-ob"
                    tabIndex="0"
                  >
                    <img
                      src={trash}
                      alt={IconAlts.iconTrash}
                      className="filter-gray svg mrg-r12"
                    />
                    <p className="fwsb">{NoticeSpeakerComponents.remove}</p>
                  </button>
                </>
              ) : (
                <>
                  {n?.noticeStatus !== 'archived' && (
                    <ButtonTextSimple
                      text={NoticeSpeakerComponents.archive}
                      handler={() => {
                        if (
                          !archiveNoticeIsLoading &&
                          !removeNoticeIsLoading &&
                          !isLoading
                        ) {
                          setIsLoading(true);
                          archiveNotice({ accountId, noticeId: n._id });
                          setOpenedNotice();
                        }
                      }}
                      iconOn={true}
                      icon={img_archived}
                      iconRight={true}
                      iconClass={'mrg-l12 filter-gray'}
                      customClassText={'color-darkgray'}
                      customWrapper={
                        'noticeOverlay-action-btn min-full-width flex-center'
                      }
                    />
                  )}
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.remove}
                    handler={() => {
                      if (
                        !archiveNoticeIsLoading &&
                        !removeNoticeIsLoading &&
                        !isLoading
                      ) {
                        setIsLoading(true);
                        removeNotice({ accountId, noticeId: n._id });
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={trash}
                    iconRight={true}
                    iconClass={'mrg-l12 filter-gray'}
                    customClassText={'color-darkgray'}
                    customWrapper={`
                      noticeOverlay-action-btn min-full-width flex-center ${
                        n?.noticeStatus !== 'archived' ? 'mrg-t12' : ''
                      }`}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeResponse;
