import { useEffect, useState } from 'react';

const RadialButtonList = ({
  id,
  options,
  onChange,
  maxWidth,
  loadTrigger,
  loadValue,
  reset,
  customWrapper,
  preventNoSelection,
}) => {
  //hooks
  //state
  const [selectedOption, setSelectedOption] = useState(null);

  //Component functions
  const handleOptionChange = (key) => {
    setSelectedOption(key);
    if (selectedOption === key) {
      if (preventNoSelection) return null;
      setSelectedOption();
      onChange(null, id); //recent change to include null and id
    } else {
      setSelectedOption(key);
      onChange(key, id);
    }
  };

  useEffect(() => {
    if (loadTrigger && loadValue) {
      setSelectedOption(loadValue);
    }
  }, [loadTrigger, loadValue]);

  useEffect(() => {
    setSelectedOption(loadValue || null);
  }, [reset]);

  return (
    <div
      className={`radial-button-list__wrapper  ${
        customWrapper ? customWrapper : ''
      }`}
      style={{ maxWidth: `${maxWidth}` }}
    >
      {Object.entries(options).map(([key, value], index) => {
        return (
          <div
            key={`radial-button-${id}-${index}`}
            className="radial-button__container highlight-t-theme access-ob access-o6"
            tabIndex="0"
            onKeyDown={(e) =>
              e.key === 'Enter' ? handleOptionChange(key) : null
            }
            onClick={() => handleOptionChange(key)}
          >
            <input
              type="radio"
              name={`radio-${id}`}
              value={key}
              checked={selectedOption === key}
              onChange={() => handleOptionChange(key)}
              tabIndex="-1"
            />
            <label className="no-select flex-center">{value}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadialButtonList;
