import { useEffect, useState } from 'react';

//Utility
import { retryWrapper } from '../../utils/logic/retryFns';
// import {
//   account_img_default,
//   event_img_default,
//   banner_img_default,
// } from '../../assets/images/index'; // add direct backups

const LoadBucketImage = ({
  imagePath,
  imgClassName,
  partialPath,
  backupPath,
  title,
  index,
  croppedImage,
  containerClassName,
  setImageLoadConfirmation,
  imagePreview,
  altAriaText,
}) => {
  //Component State
  const [displayImage, setDisplayImage] = useState(false);
  const [displayImageURL, setDisplayImageURL] = useState();

  useEffect(() => {
    if (croppedImage?.length) {
      setDisplayImageURL(croppedImage);
      setDisplayImage(true);
    } else if (imagePreview) {
      setDisplayImageURL(imagePreview);
    } else {
      setDisplayImageURL(
        `https://storage.googleapis.com/${partialPath}${
          process.env.REACT_APP_ENV === 'development' ? '_development' : ''
        }/${imagePath || backupPath}`
      );
    }
  }, [imagePath, croppedImage, imagePreview]);

  useEffect(() => {
    if (displayImageURL && !croppedImage) {
      loadImage();
    }
  }, [displayImageURL]);

  async function loadImage() {
    let img;
    try {
      async function getImage() {
        try {
          img = new Image();
          img.src = displayImageURL;
          img.onload = (e) => {
            setDisplayImage(true);

            if (setImageLoadConfirmation) {
              setImageLoadConfirmation(true); // use on parent container to toggle 'remove' if imageLoadConfirmation is false
            }
          };
        } catch (error) {
          if (process.env.REACT_APP_ENV) {
            console.log('error', error);
          }
          throw error;
        }
      }
      await retryWrapper(getImage);
    } catch (error) {
      setDisplayImageURL(
        `https://storage.googleapis.com/${partialPath}${
          process.env.REACT_APP_ENV === 'development' ? '_development' : ''
        }/${backupPath}`
      );
    }
  }
  return (
    <div
      className={`flex-row ${containerClassName ? containerClassName : ''}`}
      aria-label={altAriaText || ''}
      tabIndex={altAriaText ? '0' : '-1'}
    >
      {!displayImage && <div className={`${imgClassName} skeleton`} />}

      <img
        src={displayImageURL}
        crossOrigin="https://storage.googleapis.com"
        alt={altAriaText ? altAriaText : 'Loading Img...'}
        className={displayImage ? `${imgClassName}` : 'remove'}
        title={title}
        onLoad={() => setDisplayImage(true)}
        key={index ? `display-image-${index}` : null}
      />
    </div>
  );
};

export default LoadBucketImage;
