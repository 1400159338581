import { useEffect, useState } from 'react';
//hooks

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//utils
import { textUI } from '../../../../utils/UI/textUI';

const ParticipantsContent = ({
  participantsData,
  EventPage,
  participantImagesCropped,
  previewMode,
}) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (participantImagesCropped?.length > 0) {
      let newArr = [];

      participantImagesCropped?.map((croppedObj) => {
        let newParticipant;

        if (previewMode) {
          newParticipant = participantsData?.find((p) => {
            if (p?.id === croppedObj?.id) {
              return p;
            }
          });
          newParticipant = { ...newParticipant, ...croppedObj };
        }
        newArr.push(newParticipant);
      });

      for (const p of participantsData) {
        if (newArr.some((pCropped) => pCropped.id !== p.id)) {
          newArr.push(p);
        }
      }

      setParticipants(newArr);
    } else if (participantsData?.length > 0) {
      setParticipants(participantsData);
    }
  }, [participantsData, participantImagesCropped]);

  return (
    <>
      {participants?.length > 0 && (
        <div className="flex-column">
          <label
            className="content-heading-styled text-gray mrg-b12"
            tabIndex="0"
            id="participantsLabel"
          >
            {EventPage.eventParticipants}
          </label>
          <div className="h24" />
          {participants?.map((p, index) => {
            let participantData = p?.participantData;
            let partialPath =
              p?.organizedAs === 'create' ||
              p?.organizedAs === 'emailInvitation'
                ? 'myndfull_participant_images'
                : 'myndfull_account_images';

            let invitePendingInPreviewMode = false;

            if (
              (p?.organizedAs === 'inviteContact' ||
                p?.organizedAs === 'emailInvitation') &&
              p?.dispatches?.invitationStatus !== 'accepted'
            ) {
              invitePendingInPreviewMode = true;

              if (!previewMode) {
                return null;
              }
            }

            return (
              <div key={`participant-${index}`}>
                {index === 0 && (
                  <div className="divider mrg-b24 opacity-05 full-width" />
                )}

                <div
                  className={`participant__wrapper ${
                    index !== 0 ? 'mrg-t24' : ''
                  } ${
                    invitePendingInPreviewMode
                      ? 'previewMode-border outline-offset-12'
                      : ''
                  }`}
                  key={`participant-${index}`}
                >
                  <div className="participant-img__container mrg-r24">
                    <LoadBucketImage
                      croppedImage={p?.croppedImage?.croppedImage}
                      imagePath={participantData?.personal?.profileImage}
                      containerClassName={'ev-display-container'}
                      imgClassName={'participant-img'}
                      backupPath={'account_img_default.png'}
                      partialPath={partialPath}
                      imagePreview={p?.imagePreview}
                    />
                  </div>

                  <div className="participant-info full-width">
                    <div className="flex-column full-width">
                      <p className="fs18 fwsb access-ob" tabIndex="0">
                        {participantData?.personal?.prefix &&
                          `${participantData?.personal?.prefix} `}
                        {participantData?.personal?.firstName &&
                          `${participantData?.personal?.firstName} `}
                        {participantData?.personal?.lastName &&
                          `${participantData?.personal?.lastName}`}
                        {participantData?.eventRole &&
                          ` | ${participantData?.eventRole}`}
                      </p>

                      <p className="italic fs16">
                        {participantData?.personal?.pronouns &&
                          `${participantData?.personal?.pronouns}`}
                      </p>
                    </div>

                    {participantData?.personal?.bio && (
                      <div className="fs16 mrg-t6 access-ob" tabIndex="0">
                        {textUI(participantData?.personal?.bio)}
                      </div>
                    )}
                  </div>
                </div>

                {invitePendingInPreviewMode && (
                  <div className="banners__notice align-center full-width fs16 mrg-b24 mrg-t24">
                    {EventPage.inviteContactPreviewOnly}
                  </div>
                )}

                <div className="divider mrg-t24 opacity-05 full-width" />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ParticipantsContent;
