import { useEffect, useRef, useState } from 'react';

//hooks
import useSettings from '../../../../../context/useSettings.js';
import useLanguageData from '../../../../../language/useLanguageData.js';
import useLanguageComponents from '../../../../../language/useLanguageComponents.js';

//components
import SpeakerActionBtns from './SpeakerActionBtns.js';
import TranslationsSpeakerData from './TranslationsSpeakerData.js';
import ItemButton from '../../../../../components/ItemButton/ItemButton.js';
import TranslationsSpeakerInputs from './TranslationsSpeakerInputs.js';

//utility

const TranslationsSpeakerBase = ({
  prevSpeaker,
  handleSaveAndReturn,
  roomLanguages,
  speakerNameOn,
  participantFormSubmissionView,
  participantFormSubmissionHandler,
  submitIsLoading,
}) => {
  const { contentLanguage } = useSettings();
  const { TranslationLanguageOpts } = useLanguageData();
  const { Generic } = useLanguageComponents();

  //state
  const [speaker, setSpeaker] = useState({});
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);

  const [editModeAvailable, setEditModeAvailable] = useState(false);
  const [editObj, setEditObj] = useState();

  //UI
  const effectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (effectRan.current === false && prevSpeaker?.id) {
      effectRan.current = true;
      setSpeaker(prevSpeaker);

      if (
        prevSpeaker.organizedAs === 'inviteContact' ||
        prevSpeaker.organizedAs === 'emailInvitation'
      ) {
        setEditModeAvailable(true);
      }
    }
  }, [prevSpeaker]); //set initialize state

  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, roomLanguages]); //set language options for speaker.

  return (
    <div className="flex-column full-width">
      {speaker && (
        <>
          {!participantFormSubmissionView && (
            <SpeakerActionBtns
              speaker={speaker}
              handleSaveAndReturn={handleSaveAndReturn}
              setEditObj={setEditObj}
              editObj={editObj}
            />
          )}
          <div className="h12" />

          {speaker?.translationsSpeakerData?.name &&
            (speaker.organizedAs === 'create' || editObj) && (
              <p className="fs21 fwsb flex-center mrg-t12">
                {speaker.translationsSpeakerData.name}
              </p>
            )}
          {editModeAvailable && !editObj ? (
            <TranslationsSpeakerData
              speakerData={speaker?.translationsSpeakerData}
              roomLanguages={roomLanguages}
            />
          ) : (
            <TranslationsSpeakerInputs
              speaker={speaker}
              roomLanguageOpts={roomLanguageOpts}
              speakerNameOn={speakerNameOn}
              setSpeaker={setSpeaker}
              roomLanguages={roomLanguages}
            />
          )}
        </>
      )}

      {participantFormSubmissionView && (
        <ItemButton
          fullWidth={true}
          handler={() => participantFormSubmissionHandler(speaker)}
          text={Generic.submit}
          id={'translationsFormSubmissionBtn'}
          customWrapper={'mrg-t48'}
          isLoading={submitIsLoading}
        />
      )}
    </div>
  );
};

export default TranslationsSpeakerBase;
