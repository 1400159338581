import { useEffect, useState } from 'react';
//hooks
import { useAdminCreateTicketMutation } from '../support/adminSupportApiSlice';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import useLanguageData from '../../../language/useLanguageData';
import useLanguageComponents from '../../../language/useLanguageComponents';

//components
import TextArea from '../../../components/TextArea/TextArea';
import ItemButton from '../../../components/ItemButton/ItemButton';
import RadialButtonList from '../../../components/RadialButtonList/RadialButtonList';
import CheckBox from '../../../components/Selections/CheckBox';

//utils
import { dropdown_arrow } from '../../../assets/icons';

const AdminCreateTicket = () => {
  //hooks
  const { ReportEventOpts } = useLanguageData();
  const { ReportModal } = useLanguageComponents();
  const { adminAccountId } = useAdminAccountReduxHandlers();

  //API
  const [
    adminCreateTicket,
    {
      isLoading: adminCreateTicketisLoading,
      isFetching: adminCreateTicketisFetching,
    },
  ] = useAdminCreateTicketMutation();

  //state
  const [createTicketOpen, setCreateTicketOpen] = useState(false);
  const [ticketType, setTicketType] = useState();
  const [ticketReference, setTicketReference] = useState();

  const [ticketKey, setTicketKey] = useState();
  const [otherTicketDescription, setOtherTicketDescription] = useState();
  const [ticketDetailedDescription, setTicketDetailedDescription] = useState();
  const [claimAssignment, setClaimAssignment] = useState(false);

  //
  const [suspendEvent, setSuspendEvent] = useState(false);
  const [lockAccount, setLockAccount] = useState(false);

  //ui
  const [submitValid, setSubmitValid] = useState(false);

  function handleTicketType(selection) {
    if (ticketType === selection) {
      setTicketType('');
    } else {
      setTicketType(selection);
    }
  }

  useEffect(() => {
    if (
      ticketType &&
      ticketReference?.length > 8 &&
      ticketKey &&
      ticketDetailedDescription?.length > 1
    ) {
      if (ticketKey === 'other' && otherTicketDescription?.length === 0) {
        setSubmitValid(false);
      } else {
        setSubmitValid(true);
      }
    } else {
      setSubmitValid(false);
    }
  }, [
    ticketType,
    ticketReference,
    ticketKey,
    otherTicketDescription,
    ticketDetailedDescription,
    claimAssignment,
  ]);

  async function handleCreateTicket() {
    if (!adminCreateTicketisLoading && !adminCreateTicketisFetching) {
      const res = await adminCreateTicket({
        adminAccountId,
        ticketType,
        ticketReference,
        ticketKey,
        otherTicketDescription,
        ticketDetailedDescription,
        claimAssignment,
      });
      if (res.data?.status === 'success') {
        setTicketType();
        setTicketReference();
        setTicketKey();
        setOtherTicketDescription();
        setClaimAssignment(false);
        setSuspendEvent(false);
        setCreateTicketOpen(false);
      }
    }
  }

  return (
    <div className="admin-search-tool mrg-b24 mrg-t12 mrg-l24">
      <button
        className="search-btn flex-row align-center  full-width highlight-bthin-theme mrg-b12"
        onClick={() => setCreateTicketOpen(!createTicketOpen)}
      >
        <p className="align-center fs21 fwsb mrg-r24 ">Create Ticket</p>
        <img
          src={dropdown_arrow}
          alt="arrow"
          className={`pad-12 ${createTicketOpen ? 'reverse-vertical' : ''}`}
        />
      </button>

      {createTicketOpen && (
        <div className="admin-search-tool__container">
          <div
            className={`flex-row space-evenly full-width ${
              ticketType ? ' mrg-b24' : ''
            }`}
          >
            <button
              type="button"
              className={`fs16 fwsb access-ob access-o6 ${
                ticketType === 'account' ? 'selection item-active' : 'selection'
              }`}
              onClick={() => handleTicketType('account')}
            >
              Account
            </button>
            <button
              type="button"
              className={`fs16 fwsb access-ob access-o6 ${
                ticketType === 'event' ? 'selection item-active' : 'selection'
              }`}
              onClick={() => handleTicketType('event')}
            >
              Event
            </button>
          </div>

          {ticketType && (
            <div className="flex-column">
              <TextArea
                id={'ticketReference'}
                limit={14}
                hideWordCount={true}
                labelText={
                  ticketType === 'event'
                    ? 'Event Reference'
                    : ticketType === 'account'
                    ? 'Account Reference'
                    : 'Select Reference'
                }
                handleChange={setTicketReference}
                disableEnter={true}
                onBlurHandlerOff={true}
                asterisk={true}
              />

              <div className="h24" />

              <RadialButtonList
                onChange={setTicketKey}
                options={ReportEventOpts}
                id={'reportViolations'}
              />
              {ticketKey === 'other' && (
                <>
                  <div className="h12" />
                  <TextArea
                    customWidth={'100%'}
                    disableEnter={true}
                    handleChange={setOtherTicketDescription}
                    hideWordCount={true}
                    id={'otherViolationTitle'}
                    labelText={ReportModal.otherViolationTitle}
                    limit={100}
                    onBlurHandlerOff={true}
                    asterisk={true}
                  />
                </>
              )}

              <div className="h12" />
              <TextArea
                customWidth={'100%'}
                handleChange={setTicketDetailedDescription}
                hideWordCount={true}
                labelText={ReportModal.description}
                id={'ticketDetailedDescription'}
                limit={2000}
                large={true}
                customMinHeight={'360px'}
                onBlurHandlerOff={true}
                asterisk={true}
              />
              <div className="h24" />
              <CheckBox
                visible={true}
                handler={() => setClaimAssignment(!claimAssignment)}
                selectionObj={{ claimAssignment: 'Claim Assignment' }}
                loadTrigger={false}
                mrgCenter={true}
                handleAccountId={true}
              />
              {ticketType === 'event' && (
                <CheckBox
                  visible={true}
                  handler={() => setSuspendEvent(!suspendEvent)}
                  selectionObj={{ suspendEvent: 'Suspend Event' }}
                  loadTrigger={false}
                  mrgCenter={true}
                  handleAccountId={true}
                />
              )}

              <div className="h12" />
              <ItemButton
                text={'Submit'}
                handler={() => handleCreateTicket()}
                fullWidth={true}
                isLoading={
                  adminCreateTicketisLoading && adminCreateTicketisFetching
                }
                unavailable={!submitValid}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminCreateTicket;
