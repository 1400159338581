import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useLanguageData from '../../../../../../language/useLanguageData';

//components
import CustomTagsList from '../../../../../../components/CustomTagsList/CustomTagsList';
import ButtonTextSimple from '../../../../../../components/ButtonTextSimple/ButtonTextSimple';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//utils
import {
  bigtag_nobg,
  checkmark_green,
  img_download,
} from '../../../../../../assets/icons';
import { areArraysEqualIgnoreOrder } from '../../../../../../utils/logic/arrayEqualIgnoreOrder';
import { arrayIncludesAll } from '../../../../../../utils/logic/arrayIncludesAll';

const SpeakerTags = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { Generic } = useLanguageComponents();
  const { BigTagsOpts, BigTagsOptsWithSubText } = useLanguageData();
  const { SpeakerTags } = useLanguageAccount();
  const {
    accountSpeakerBigTags,
    accountSpeakerTinyTags,
    handleAccountSpeakerSetBigTags,
    handleAccountSpeakerSetTinyTags,
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountSpeakerSignUpComplete,
  } = useAccountReduxHandlers();

  //state
  const [saveValidBigTags, setSaveValidBigTags] = useState(false);
  const [saveValidTinyTags, setSaveValidTinyTags] = useState(false);

  const [speakerHasAllAttendeeBigTags, setSpeakerHasAllAttendeeBigTags] =
    useState(false);
  const [speakerHasAllAttendeeTinyTags, setSpeakerHasAllAttendeeTinyTags] =
    useState(false);

  const [newBigTags, setNewBigTags] = useState(accountSpeakerBigTags);
  const [newTinyTags, setNewTinyTags] = useState(accountSpeakerTinyTags);

  //initialize
  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      const bigTagsAreEqual = areArraysEqualIgnoreOrder(
        newBigTags,
        accountSpeakerBigTags
      );

      if (!bigTagsAreEqual) {
        setNewBigTags(accountSpeakerBigTags);
      }

      const tinyTagsAreEqual = areArraysEqualIgnoreOrder(
        newTinyTags,
        accountSpeakerTinyTags
      );
      if (tinyTagsAreEqual) {
        setNewTinyTags(accountSpeakerTinyTags);
      }
    }
  }, [accountSpeakerBigTags, accountSpeakerTinyTags]); //load with new

  useEffect(() => {
    const bigTagsAreEqual = areArraysEqualIgnoreOrder(
      newBigTags,
      accountSpeakerBigTags
    );
    if (!bigTagsAreEqual && newBigTags?.length > 0) {
      setSaveValidBigTags(true);
    } else {
      setSaveValidBigTags(false);
    }
    const tinyTagsAreEqual = areArraysEqualIgnoreOrder(
      newTinyTags,
      accountSpeakerTinyTags
    );
    if (!tinyTagsAreEqual && newTinyTags?.length > 0) {
      setSaveValidTinyTags(true);
    } else {
      setSaveValidTinyTags(false);
    }
  }, [newBigTags, newTinyTags, accountSpeakerBigTags, accountSpeakerTinyTags]); //check save valid

  useEffect(() => {
    //check BT
    const arrayHasAllBigTags = arrayIncludesAll(accountBigTags, newBigTags);
    setSpeakerHasAllAttendeeBigTags(arrayHasAllBigTags);

    //check tT
    let allAccountTinyTags = [];
    accountTinyTags?.map((tag) => allAccountTinyTags.push(tag));
    accountTinyTagGroups?.map((group) => {
      for (const tag of group?.tags) {
        allAccountTinyTags.push(tag);
      }
    });

    const arrayHasAllTinyTags = arrayIncludesAll(
      allAccountTinyTags,
      newTinyTags
    );

    setSpeakerHasAllAttendeeTinyTags(arrayHasAllTinyTags);
  }, [
    newBigTags,
    newTinyTags,
    accountSpeakerBigTags,
    accountSpeakerTinyTags,
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
  ]); //check import tags

  function transferBigTags() {
    if (accountSpeakerSignUpComplete) {
      let newArr = [...newBigTags];

      accountBigTags.forEach((tag) => {
        if (!newBigTags.includes(tag)) {
          newArr.push(tag);
        }
      });

      newArr.sort((a, b) => a.localeCompare(b)).reverse();
      setNewBigTags(newArr);
    } else {
      let newArr = [...accountSpeakerBigTags];

      accountBigTags.forEach((tag) => {
        if (!newArr.includes(tag)) {
          newArr.push(tag);
        }
      });

      newArr.sort((a, b) => a.localeCompare(b)).reverse();
      handleAccountSpeakerSetBigTags(newArr);
      setNewBigTags(newArr); //required for import comparison check
    }
  }

  function transferTinyTags() {
    if (accountSpeakerSignUpComplete) {
      let newArr = [...newTinyTags];

      accountTinyTags.forEach((tag) => {
        if (!newArr.includes(tag)) {
          newArr.push(tag);
        }
      });

      if (accountTinyTagGroups.length > 0) {
        accountTinyTagGroups.map((tG) => {
          if (tG.tags?.length > 0) {
            const tGTags = tG.tags;
            for (const tag of tGTags) {
              if (!newArr.includes(tag)) {
                newArr.push(tag);
              }
            }
          }
        });
      }

      newArr.sort((a, b) => a.localeCompare(b)).reverse();

      setNewTinyTags(newArr);
    } else {
      let newArr = [...accountSpeakerTinyTags];

      accountTinyTags.forEach((tag) => {
        if (!newArr.includes(tag)) {
          newArr.push(tag);
        }
      });

      if (accountTinyTagGroups.length > 0) {
        accountTinyTagGroups.map((tG) => {
          if (tG.tags?.length > 0) {
            const tGTags = tG.tags;
            for (const tag of tGTags) {
              if (!newArr.includes(tag)) {
                newArr.push(tag);
              }
            }
          }
        });
      }

      newArr.sort((a, b) => a.localeCompare(b)).reverse();
      handleAccountSpeakerSetTinyTags(newArr);
      setNewTinyTags(newArr); //required for import comparison check
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="speakerBigTagsLabel"
        tabIndex="0"
      >
        {SpeakerTags.bigTags}

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
          {accountSpeakerBigTags?.length > 0 && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {SpeakerTags.bigTagsDescription}
      </p>

      {!speakerHasAllAttendeeBigTags && (
        <ButtonTextSimple
          iconOn={true}
          icon={img_download}
          iconClass={'filter-gray mrg-r12 mrg-t2'}
          iconStyle={{ height: '18px' }}
          text={SpeakerTags.transferTags}
          handler={transferBigTags}
          customClassText={'color-gray'}
          customWrapper={'mrg-t12'}
        />
      )}

      <div className="h24" />

      <SelectionsModal
        addButtonText={`${Generic.add} ${Generic.bigTags}`}
        DataOpts={BigTagsOptsWithSubText}
        DataOptsSubTextTranslations={BigTagsOpts}
        fullWidth={true}
        iconModal={bigtag_nobg}
        iconOff={true}
        limit={10}
        modalTitle={Generic.bigTags}
        selectionState={
          accountSpeakerSignUpComplete ? newBigTags : accountSpeakerBigTags
        }
        setSelectionState={
          accountSpeakerSignUpComplete
            ? setNewBigTags
            : handleAccountSpeakerSetBigTags
        }
        subTextOn={true}
        id={'bigTagSelections'}
        itemButtonCustomWrapper={'item-button-full'}
      />

      {accountSpeakerSignUpComplete && (
        <>
          <div className="h24" />
          <SaveButton
            saveValid={saveValidBigTags}
            saveAriaLanguage={SpeakerTags.transferTags}
            handler={() =>
              saveAccountChange(
                {
                  tags: {
                    speakerTinyTags: accountSpeakerTinyTags,
                    speakerBigTags: newBigTags,
                  },
                },
                'speakerBigTags'
              )
            }
            isLoading={loadingSaveBtnUI === 'speakerBigTags'}
          />
        </>
      )}

      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="speakerTinyTagsLabel"
        tabIndex="0"
      >
        {SpeakerTags.tinyTags}
        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
          {accountSpeakerTinyTags?.length > 0 && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {SpeakerTags.tinyTagsDescription}
      </p>

      {!speakerHasAllAttendeeTinyTags && (
        <ButtonTextSimple
          iconOn={true}
          icon={img_download}
          iconClass={'filter-gray mrg-r12 mrg-t2'}
          iconStyle={{ height: '18px' }}
          text={SpeakerTags.transferTags}
          handler={transferTinyTags}
          customClassText={'color-gray'}
          customWrapper={'mrg-t12'}
        />
      )}

      <div className="h24" />
      <CustomTagsList
        limit={100}
        id={'tinyTags'}
        tinyTags={
          accountSpeakerSignUpComplete ? newTinyTags : accountSpeakerTinyTags
        }
        setTinyTags={
          accountSpeakerSignUpComplete
            ? setNewTinyTags
            : handleAccountSpeakerSetTinyTags
        }
        twoTagRows={true}
        bigTags={
          accountSpeakerSignUpComplete ? newBigTags : accountSpeakerBigTags
        }
        tagGroupsOff={true}
      />

      <div className="h24" />
      {accountSpeakerSignUpComplete && (
        <>
          <div className="h24" />
          <SaveButton
            saveValid={saveValidTinyTags}
            saveAriaLanguage={SpeakerTags.tinyTags}
            handler={() =>
              saveAccountChange(
                {
                  tags: {
                    speakerTinyTags: newTinyTags,
                    speakerBigTags: accountSpeakerBigTags,
                  },
                },
                'speakerTinyTags'
              )
            }
            isLoading={loadingSaveBtnUI === 'speakerTinyTags'}
          />
        </>
      )}
    </>
  );
};

export default SpeakerTags;
