import { useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import useSettings from '../../../../../context/useSettings';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import NoticeResponse from './NoticeResponse';
import NoticeContactRequest from './NoticeContactRequest';
import NoticeInviteParticipant from './NoticeInviteParticipant';
import NoticeInviteParticipantTranslations from './NoticeInviteParticipantTranslations';

//utils
import { getEventInvitationType } from '../functions/getEventInvitationType';
import { formatDateFromCreatedAt } from '../../../../../utils/dateTime/DateCalc';
import { useNoticeHandlers } from '../../useNoticeHandlers';
import { dropdown_arrow, img_exclamation } from '../../../../../assets/icons';

const NoticeBase = ({ n, noticeOverlayIsOpened, setNoticeOverlayIsOpened }) => {
  //hooks
  const { handleMobileTap, contentLanguage } = useSettings();
  const { accountTimeZone } = useAccountReduxHandlers();
  const { NoticeOverlay, GetEventInvitationType, IconAlts } =
    useLanguageComponents();
  const { NoticeTypes } = useLanguageData();
  const { noticeTypesWithActionsArr } = useNoticeHandlers();

  //state
  const [openedNotice, setOpenedNotice] = useState();

  //UI
  const [highlightNoticeEntryTap, setHighlightNoticeEntryTap] = useState(false);

  const noticeTypesWithNoResponseComponent = [
    'contactRequest',
    'eventParticipationInvite',
    'eventParticipationTranslationsRequest',
  ];

  function handleNoticeEntryClick(n) {
    const noticeId = n?._id;

    if (openedNotice === noticeId) {
      setOpenedNotice();
    } else {
      setOpenedNotice(noticeId);
    }
    if (!noticeOverlayIsOpened) {
      setNoticeOverlayIsOpened(true);
    }
  }

  return (
    <li
      className={`pos-rel ${
        highlightNoticeEntryTap === n?._id ? 'highlight-bg-lg--tap' : ''
      } `}
    >
      {!noticeTypesWithActionsArr.includes(n?.noticeType) &&
        n?.noticeStatus === 'unseen' && (
          <div
            className="noticeOverlay--seen"
            title={NoticeOverlay.unseenNotice}
            aria-hidden={true}
          />
        )}
      {noticeTypesWithActionsArr.includes(n?.noticeType) &&
        !n?.noticeHistory && (
          <div
            className="noticeOverlay--action pos-rel"
            title={NoticeOverlay.actionRequired}
          >
            <div className="flex-row align-center">
              <img
                src={img_exclamation}
                alt={IconAlts.iconAlert}
                className="exclamation filter-red"
                style={{ height: '18px' }}
              />
              <img
                src={dropdown_arrow}
                alt={IconAlts.iconArrow}
                style={{ height: '12px' }}
                className={`filter-gray mrg-l12 ${
                  openedNotice ? 'reverse-vertical' : ''
                }`}
              />
            </div>
          </div>
        )}
      <button
        className="msg-notice-entry-btn access-ob access-o-6"
        tabIndex="0"
        onClick={() =>
          handleMobileTap(
            [
              () => setHighlightNoticeEntryTap(n?._id),
              () => setHighlightNoticeEntryTap(),
            ],
            [() => handleNoticeEntryClick(n)]
          )
        }
        aria-expanded={openedNotice === n?._id ? true : false}
      >
        <LoadBucketImage
          imagePath={n?.from?.account?.personal?.profileImage}
          imgClassName={'noticeOverlay-profile mrg-r12'}
          backupPath={'account_img_default.png'}
          partialPath={'myndfull_account_images'}
          containerClassName={'mrg-auto-bottom mrg-t4'}
        />
        <div className="flex-column full-width">
          <div
            className="flex-row text-left fs18 fwsb full-width"
            style={{ maxWidth: '274px' }}
          >
            {n?.noticeType === 'eventParticipationInvite' ? (
              <>{getEventInvitationType(n, GetEventInvitationType)}</>
            ) : (
              <>{NoticeTypes[n?.noticeType]}</>
            )}
          </div>

          <p className="fs16 color-gray text-left">
            {formatDateFromCreatedAt(
              n.createdAt,
              contentLanguage,
              accountTimeZone
            )}
            {n?.eventInfo?.eventTitle && <>, {n?.eventInfo?.eventTitle}</>}
          </p>

          <p className="flex-row full-width fs16 text-left">
            {NoticeOverlay.from}&nbsp;
            {n?.fromMyndFull && 'MyndFull'}
            {n?.from?.account?.personal?.prefix && (
              <>{n?.from?.account?.personal?.prefix}&nbsp;</>
            )}
            {n?.from?.account?.personal?.firstName}
            &nbsp;
            {n?.from?.account?.personal?.lastName}
          </p>

          {n?.eventInfo?.eventTitle && (
            <p className="flex-row full-width fs16 text-left">
              {`Event  ${n?.eventInfo?.eventTitle}`}
            </p>
          )}
        </div>
      </button>

      {n.noticeType === 'eventParticipationInvite' && (
        <NoticeInviteParticipant
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
        />
      )}

      {n.noticeType === 'eventParticipationTranslationsRequest' && (
        <NoticeInviteParticipantTranslations
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
        />
      )}

      {n.noticeType === 'contactRequest' && (
        <NoticeContactRequest
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
        />
      )}

      {!noticeTypesWithNoResponseComponent.includes(n.noticeType) &&
        (n?.noticeHistory || n?.noticeStatus === 'archived') && (
          <NoticeResponse
            n={n}
            openedNotice={openedNotice}
            setOpenedNotice={setOpenedNotice}
          />
        )}
    </li>
  );
};

export default NoticeBase;
