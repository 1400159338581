import { useEffect, useRef, useState } from 'react';

//hooks
import useSettings from '../../../../context/useSettings';
import useLanguageAccount from '../../../../language/features/useLanguageAccount';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import { AccountSubMenuNav } from './AccountSubMenuNav';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';
import {
  arrow_circular_hallow,
  dropdown_arrow,
  exchange,
  help,
  icon_profile,
  img_credit_card,
  img_messages,
  img_social,
  organize,
  search_dark,
  settings,
  speaker_dark,
} from '../../../../assets/icons';

const AccountNav = () => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { Generic, IconAlts } = useLanguageComponents();
  const { windowSettingsSize } = useSettings();
  const { handleSettingsSetAccountSettingsView, settingsAccountSettingsView } =
    useSettingsHandlers();

  //UI
  const [scrollAtTop, setScrollAtTop] = useState(false);
  const [scrollAtBottom, setScrollAtBottom] = useState(false);
  const [repositionSelectionsContainer, setRepositionSelectionsContainer] =
    useState(80);
  const [submenuOpened, setSubmenuOpened] = useState(true);

  //variables
  const navMenuRef = useRef();
  const scrolledToHideNavBar = useRef(false);

  //Initialize & UI functions
  useEffect(() => {
    ScrollTo('', 'instant', true);
  }, [settingsAccountSettingsView]);

  useEffect(() => {
    function handleScroll() {
      let distanceScrolled = window.scrollY;
      if (distanceScrolled > 70) {
        if (scrolledToHideNavBar.current === false) {
          setRepositionSelectionsContainer(0);
          scrolledToHideNavBar.current = true;
        }
      } else {
        scrolledToHideNavBar.current = false;
        setRepositionSelectionsContainer(80);
      }
    }

    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow' ||
      windowSettingsSize === 'mobile'
    ) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [windowSettingsSize]); // sets ui to hide navbar when scrolling down

  useEffect(() => {
    const navMenuContainer = navMenuRef.current;

    const handleScroll = (e) => {
      const scrollTop = navMenuContainer.scrollTop;
      const scrollHeight = navMenuContainer.scrollHeight;
      const height = navMenuContainer.clientHeight;
      // Prevent scroll propagation if necessary
      const delta = e.deltaY;
      if (delta < 0 && scrollTop === 0) {
        e.preventDefault(); // Prevent scrolling up if already at the top
      }

      if (delta > 0 && scrollTop + height >= scrollHeight) {
        e.preventDefault(); // Prevent scrolling down if already at the bottom
      }
    };

    navMenuContainer.addEventListener('wheel', handleScroll);

    return () => {
      navMenuContainer.removeEventListener('wheel', handleScroll);
    };
  }, []); //prevents scrolling from propagating to the main window scrollbar

  useEffect(() => {
    const navMenuContainer = navMenuRef.current;

    const handleScroll = (e) => {
      const scrollTop = navMenuContainer.scrollTop;
      const scrollHeight = navMenuContainer.scrollHeight;
      const height = navMenuContainer.clientHeight;
      // console.log('scrollTop', scrollTop); //100
      // console.log('height', height); //1021
      //combined 1121
      // console.log('scrollTop + height + 2', scrollTop + height + 2);
      // console.log('scrollHeight', scrollHeight); //1122
      setScrollAtTop(scrollTop <= 0);
      setScrollAtBottom(scrollTop + height + 2 >= scrollHeight);
    };

    navMenuContainer.addEventListener('scroll', handleScroll);

    return () => {
      navMenuContainer.removeEventListener('scroll', handleScroll);
    };
  }, []); //UI for navigation arrows displaying scrollable to top/bottom, readjustment based on scroll event

  useEffect(() => {
    const navMenuContainer = navMenuRef.current;
    const handleScroll = (e) => {
      const scrollTop = navMenuContainer.scrollTop;
      const scrollHeight = navMenuContainer.scrollHeight;
      const height = navMenuContainer.clientHeight;

      setScrollAtTop(scrollTop <= 0);
      setScrollAtBottom(scrollTop + height >= scrollHeight);
    };
    handleScroll();
  }, [
    submenuOpened,
    settingsAccountSettingsView,
    repositionSelectionsContainer,
  ]); //UI for navigation arrows displaying scrollable to top/bottom, readjustment based on nav menu toggles

  return (
    <div
      role="navigation"
      className="nav-menu__wrapper"
      aria-label={Account.generic.ariaMainMenu}
    >
      <ul
        className="nav-menu__container access-ob access-o-6"
        style={{
          top: `${repositionSelectionsContainer}px`,
          paddingBottom: `${repositionSelectionsContainer}px`,
        }}
        tabIndex="0"
        ref={navMenuRef}
      >
        {!scrollAtTop && (
          <div
            className={`${
              repositionSelectionsContainer === 0
                ? 'more-arrow-btn--top more-arrow-btn--top--repositionSelectionContainerActive'
                : 'more-arrow-btn--top'
            }`}
          >
            <img
              src={arrow_circular_hallow}
              alt={IconAlts.iconArrow}
              className="filter-lightgray"
            />
          </div>
        )}
        {!scrollAtBottom && (
          <div className={'more-arrow-btn--bottom'}>
            <img
              src={arrow_circular_hallow}
              alt={IconAlts.iconArrow}
              className="filter-lightgray reverse-vertical"
            />
          </div>
        )}

        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
              ${settingsAccountSettingsView === 'account' ? ' selected' : ''}`}
            onClick={() => {
              if (settingsAccountSettingsView === 'account') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('account');
            }}
            tabIndex="0"
            aria-label={`${Account.account.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'account' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'account' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.account.account}
            <img
              src={icon_profile}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'account' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'billing' ? ' selected' : ''}`}
            onClick={() => {
              if (settingsAccountSettingsView === 'billing') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('billing');
            }}
            tabIndex="0"
            aria-label={`${Account.billing.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'billing' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'billing' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.billing.billing}
            <img
              src={img_credit_card}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'billing' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
              ${settingsAccountSettingsView === 'general' ? ' selected' : ''}`}
            onClick={() => {
              if (settingsAccountSettingsView === 'general') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('general');
            }}
            tabIndex="0"
            aria-label={`${Account.account.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'general' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'general' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.general.general}
            <img
              src={settings}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'general' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>

        <div className="flex-row align-center full-width">
          <div className="divider full-width opacity-5 mrg-r24" />
          <p className="fs21 color-gray mrg-l6 mrg-r6">
            {Account.generic.roles}
          </p>
          <div className="divider full-width opacity-5 mrg-l24" />
        </div>

        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'attendee' ? ' selected' : ''
               }`}
            onClick={() => {
              if (settingsAccountSettingsView === 'attendee') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('attendee');
            }}
            tabIndex="0"
            aria-label={`${Account.attendee.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'attendee' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'attendee' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.attendee.attendee}
            <img
              src={search_dark}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'attendee' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'speaker' ? ' selected' : ''}`}
            onClick={() => {
              if (settingsAccountSettingsView === 'speaker') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('speaker');
            }}
            tabIndex="0"
            aria-label={`${Account.speaker.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'speaker' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'speaker' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.speaker.speaker}
            <img
              src={speaker_dark}
              alt={Generic.icon}
              className="primary-heading-icon--speaker filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'speaker' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'organizer' ? ' selected' : ''
               }`}
            onClick={() => {
              if (settingsAccountSettingsView === 'organizer') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('organizer');
            }}
            tabIndex="0"
            aria-label={`${Account.organizer.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'organizer' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'organizer' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.organizer.organizer}
            <img
              src={organize}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'organizer' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>

        <div className="flex-row align-center full-width">
          <div className="divider full-width opacity-5 mrg-r24" />
          <p className="fs21 color-gray mrg-l6 mrg-r6">
            {Account.generic.tools}
          </p>
          <div className="divider full-width opacity-5 mrg-l24" />
        </div>

        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'community' ? ' selected' : ''
               }`}
            onClick={() => {
              if (settingsAccountSettingsView === 'community') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('community');
            }}
            tabIndex="0"
            aria-label={`${Account.community.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'community' ? true : false
            }
          >
            {!submenuOpened && settingsAccountSettingsView === 'community' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.community.community}
            <img
              src={img_social}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'community' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'emailPreferences'
                   ? ' selected'
                   : ''
               }`}
            onClick={() => {
              if (settingsAccountSettingsView === 'emailPreferences') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('emailPreferences');
            }}
            tabIndex="0"
            aria-label={`${Account.emailPreferences.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'emailPreferences' ? true : false
            }
          >
            {!submenuOpened &&
              settingsAccountSettingsView === 'emailPreferences' && (
                <img
                  src={dropdown_arrow}
                  alt={IconAlts.arrow}
                  className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
                />
              )}
            {Account.emailPreferences.email}
            <img
              src={img_messages}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'emailPreferences' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'help' ? ' selected' : ''}`}
            tabIndex="0"
            aria-label={`${Account.help.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'help' ? true : false
            }
            onClick={() => {
              if (settingsAccountSettingsView === 'help') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('help');
            }}
          >
            {!submenuOpened && settingsAccountSettingsView === 'help' && (
              <img
                src={dropdown_arrow}
                alt={IconAlts.arrow}
                className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
              />
            )}
            {Account.help.help}
            <img
              src={help}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'help' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
        <li style={{ marginBottom: '48px' }}>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'translationRooms'
                   ? 'selected'
                   : ''
               }`}
            onClick={() => {
              if (settingsAccountSettingsView === 'translationRooms') {
                setSubmenuOpened(!submenuOpened);
              } else {
                setSubmenuOpened(true);
              }
              handleSettingsSetAccountSettingsView('translationRooms');
            }}
            tabIndex="0"
            aria-label={`${Account.translationRooms.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'translationRooms' ? true : false
            }
          >
            {!submenuOpened &&
              settingsAccountSettingsView === 'translationRooms' && (
                <img
                  src={dropdown_arrow}
                  alt={IconAlts.arrow}
                  className="mrg-auto-left mrg-r24 filter-gray reverse-vertical"
                />
              )}
            {Account.translationRooms.translations}
            <img
              src={exchange}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
          </button>
          {settingsAccountSettingsView === 'translationRooms' && (
            <AccountSubMenuNav
              windowSettingsSize={windowSettingsSize}
              submenuOpened={submenuOpened}
            />
          )}
        </li>
      </ul>
    </div>
  );
};

export default AccountNav;
