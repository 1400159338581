//Hooks

//Components
import OrganizerCommunitySettings from './sections/OrganizerCommunitySettings';
import OrganizerCurrentPlan from './sections/OrganizerCurrentPlan';
import OrganizerRoleToggle from './sections/OrganizerRoleToggle';
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';

//Utility

const OrganizerContent = ({ saveAccountChange, loadingSaveBtnUI }) => {
  return (
    <div className="account-content" id="account-content">
      <div className="h24" />
      <div className={`section min-full-height`}>
        <OrganizerRoleToggle />
        <div className="h36" />

        <OrganizerCurrentPlan />
        <div className="h36" />

        <OrganizerCommunitySettings
          saveAccountChange={saveAccountChange}
          loadingSaveBtnUI={loadingSaveBtnUI}
        />

        <div className="h48" />
        <ScrollToTop focusId={'organizeToggleLabel'} />
        <div className="h48" />
      </div>
    </div>
  );
};

export default OrganizerContent;
