//hooks
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from './useAccountHandlers';
import {
  useUpdateAccountFollowingMutation,
  useBlockAccountMutation,
  useRequestContactMutation,
  useAcceptContactRequestMutation,
  useRemoveContactMutation,
} from './accountCommunityApiSlice';

//components
//utils

export const useAccountCommunityHandlers = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const {
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  //API
  const [
    updateAccountFollowing,
    { isLoading: updateAccountFollowingIsLoading },
  ] = useUpdateAccountFollowingMutation();

  const [blockAccount, { isLoading: blockAccountIsLoading }] =
    useBlockAccountMutation();

  const [requestContact, { isLoading: requestContactIsLoading }] =
    useRequestContactMutation();

  const [removeContact, { isLoading: removeContactIsLoading }] =
    useRemoveContactMutation();

  const [acceptContactRequest, { isLoading: acceptContactRequestIsLoading }] =
    useAcceptContactRequestMutation();

  async function handleFollowAccount(followingId) {
    if (!updateAccountFollowingIsLoading) {
      const res = await updateAccountFollowing({
        accountId,
        followingId,
      });

      let updatedAccountsFollowingIds = [];
      let updatedAccountsFollowingGroupIds = [];

      if (res?.data?.community) {
        res.data.community?.following?.map((following) =>
          updatedAccountsFollowingIds.push(following?._id)
        );

        handleSettingsSetSelectedAccountsFollowingIds(
          updatedAccountsFollowingIds
        );

        res.data.community?.followingGroups.map((group) =>
          updatedAccountsFollowingGroupIds.push(group?.id)
        );

        handleSettingsSetSelectedAccountsFollowingGroupIds(
          updatedAccountsFollowingGroupIds
        );
      }
    }
  }

  async function handleRequestContact(requestContactId) {
    if (!requestContactIsLoading) {
      await requestContact({
        accountId,
        requestContactId,
      });
    }
  }

  async function handleAcceptContactRequest(accountId, noticeId) {
    if (!acceptContactRequestIsLoading) {
      await acceptContactRequest({
        accountId,
        noticeId,
      });
    }
  }

  async function handleRemoveContact(accountId, removeContactId) {
    if (!removeContactIsLoading) {
      await removeContact({
        accountId,
        removeContactId,
      });
    }
  }

  async function handleBlockAccount(blockId) {
    if (!blockAccountIsLoading) {
      await blockAccount({
        accountId,
        blockId,
      });
    }
  }

  return {
    handleFollowAccount,
    handleRequestContact,
    handleBlockAccount,
    handleAcceptContactRequest,
    handleRemoveContact,
  };
};
