//Components

//Utility
import { fr_voiceBob } from './audio/fr_voiceBob.js';
import { fr_voiceJudy } from './audio/fr_voiceJudy.js';
import { en_voiceBob } from './audio/en_voiceBob.js';
import { en_voiceJudy } from './audio/en_voiceJudy.js';
import { es_voiceBob } from './audio/es_voiceBob.js';
import { es_voiceJudy } from './audio/es_voiceJudy.js';

export const useTranslationsHandlers = () => {
  //Hooks

  function handleTestPlayBtn({
    speechLang,
    voiceOption,
    testAudioIsPlaying,
    setTestAudioIsPlaying,
  }) {
    if (!testAudioIsPlaying) {
      setTestAudioIsPlaying(true);

      if (speechLang === 'en-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'en-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceJudy}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceJudy}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceBob}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceJudy}`;
        audio.play();
      }
    }
  }

  return {
    handleTestPlayBtn,
  };
};
