import { useState } from 'react';

//hooks
import {
  useIgnoreRequestSpeakerContactMutation,
  useRemoveMyNoticeMutation,
} from '../../noticesApiSlice';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import useGenericModal from '../../../../../context/useGenericModal';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useSettings from '../../../../../context/useSettings';
import { useAccountCommunityHandlers } from '../../../account/useAccountCommunityHandlers';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';
import Spinner from '../../../../../components/Spinner/Spinner';
import ProfilePersonalModal from '../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
import { cancel, checkmark, trash, visible } from '../../../../../assets/icons';

const NoticeContactRequest = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { setGenericModal } = useGenericModal();
  const { IconAlts, NoticeSpeakerComponents } = useLanguageComponents();
  const { setOpenMobileNotificationsMenu, handleMobileTap } = useSettings();
  const { handleAcceptContactRequest } = useAccountCommunityHandlers();

  //api
  const [removeNotice] = useRemoveMyNoticeMutation();
  const [ignoreRequestSpeakerContact] =
    useIgnoreRequestSpeakerContactMutation();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [highlightViewProfileBtnTap, setHighlightViewProfileBtnTap] =
    useState(false);

  return (
    <>
      {isLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container mrg-t12">
              {n?.noticeHistory ? (
                <>
                  <div className="divider-dotted mrg-b24" />
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  {n?.noticeHistory === 'ignored' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.ignored}
                    </p>
                  )}
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.removeFromArchive}
                    iconOn={true}
                    icon={trash}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                    customClassText={'color-darkgray'}
                    iconRight={true}
                    iconStyle={{ height: '18px' }}
                    iconClass={'mrg-l12 filter-gray highlight-i-hover--red'}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        removeNotice({ accountId, noticeId: n._id });
                        setOpenedNotice();
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.accept}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        handleAcceptContactRequest(accountId, n._id);
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={checkmark}
                    iconRight={true}
                    iconClass={'mrg-l12 filter-gray'}
                    customClassText={'color-gray'}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                  />

                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.ignore}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        ignoreRequestSpeakerContact({
                          accountId,
                          noticeId: n._id,
                        });
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={cancel}
                    iconRight={true}
                    iconClass={'mrg-l12 filter-gray'}
                    customClassText={'color-gray'}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                  />
                  <button
                    className={`notice-history-summary color-gray highlight-t-theme access-ob access-o6 ${
                      highlightViewProfileBtnTap ? 'color-theme' : ''
                    }`}
                    tabIndex="0"
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setHighlightViewProfileBtnTap(true),
                          () => setHighlightViewProfileBtnTap(false),
                        ],
                        [
                          () => {
                            setOpenMobileNotificationsMenu(false);
                            setGenericModal(
                              <ProfilePersonalModal
                                closeModalFocusId={`noticeContactRequest-${n?.from?.account?._id}`}
                                profileData={n?.from?.account}
                                reopenNotices={true}
                              />
                            );
                          },
                        ]
                      )
                    }
                    id={`noticeContactRequest-${n?.from?.account?._id}`}
                  >
                    <img
                      src={visible}
                      alt={IconAlts.iconView}
                      className="filter-gray svg mrg-r12"
                    />
                    <p className="fwsb">
                      {NoticeSpeakerComponents.viewProfile}
                    </p>
                  </button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeContactRequest;
