import { useEffect, useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility
import { cancel, checkmark_green, warning } from '../../assets/icons';

//removal of modal hinges on the animationFade property, this is to ensure that the animation occurs before the modal is removed.
const ApiStatusModal = ({ apiStatus, handleClearApiStatus }) => {
  //Hooks
  const { ApiStatusModal } = useLanguageComponents();
  const { handleMobileTap, width } = useSettings();

  //UI state
  const [animationFade, setAnimationFade] = useState(false);
  // const [initialAnimationHasRan, setInitialAnimationHasRan] = useState(false);
  const [tapHighlightDismiss, setTapHighlightDismiss] = useState(false);

  //Component variables
  const errorStr = `${
    apiStatus.message !== 'Error' ? apiStatus?.message : ApiStatusModal.error
  }`;

  //Initialize & UI functions
  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setInitialAnimationHasRan(true);
  //   }, 1000);

  //   return () => clearTimeout(timerId);
  // }, []); //remove animation css for slide in

  useEffect(() => {
    if (apiStatus?.dismissed) {
      setAnimationFade(true);
    }
  }, [apiStatus]); //if apiStatus changes from parent timer in ApiStatusProvider, setting dismissed: true, then animateFade triggered, which will call function to clearApiStatus

  useEffect(() => {
    if (animationFade) {
      const timerId = setTimeout(() => {
        handleClearApiStatus(apiStatus.id);
      }, 1500);

      return () => clearTimeout(timerId);
    }
  }, [animationFade]);

  //Component functions
  function handleDismiss() {
    handleMobileTap(
      [() => setTapHighlightDismiss(true), () => setTapHighlightDismiss(false)],
      [() => setAnimationFade(true), () => handleClearApiStatus(apiStatus.id)]
    );
  } //handle direct clicks to trigger animationFade true + click animation for mobile

  let countdownNumberEl = document.getElementById('countdown-number');
  let countdown = 10;

  if (countdownNumberEl) {
    countdownNumberEl.textContent = countdown;

    const interval = setInterval(function () {
      countdown = --countdown <= 0 ? 10 : countdown;

      countdownNumberEl.textContent = countdown;
      if (countdown === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  return (
    <div
      className={`modal ${
        animationFade ? 'modal-fadeOut modal-disable' : ''
      } modal-slideIn-top`}
      // className={`modal ${animationFade ? 'modal-fadeOut modal-disable' : ''} ${
      //   width >= 1280 && !initialAnimationHasRan ? 'modal-slideIn-bottom' : ''
      // }  ${width < 1280 && !initialAnimationHasRan ? 'modal-slideIn-top' : ''}`}
      aria-live="assertive"
      id={`apiStatus-modal-${apiStatus?.id}`}
      key={`apiStatus-modal-${apiStatus?.id}`}
    >
      <div className="modal__container modal-close-btn fs18 fs14-phn fwb color-black">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleDismiss();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
            if (e.key === 'Enter') {
              handleDismiss();
            }
          }}
          id="modalDismiss"
          type="button"
          className={`mrg-r12 ${
            tapHighlightDismiss ? 'highlight-bg-transparent-lg--tap' : ''
          }`}
        >
          {apiStatus.status === 'error' && (
            <div className="modal-icon__container">
              <div className="countdown-timer__container">
                <div className="countdown-timer stroke-red">
                  <svg>
                    <circle r="18" cx="20" cy="20" />
                  </svg>
                </div>
              </div>

              <img
                className="modal-error-icon filter-red"
                src={cancel}
                alt={ApiStatusModal.error}
              />
            </div>
          )}
          {apiStatus.status === 'success' && (
            <div className="modal-icon__container">
              <div className="countdown-timer__container">
                <div className="countdown-timer stroke-green">
                  <svg>
                    <circle r="18" cx="20" cy="20" />
                  </svg>
                </div>
              </div>

              <img
                className="modal-checkmark-icon"
                src={checkmark_green}
                alt={ApiStatusModal.success}
              />
            </div>
          )}
          {apiStatus.status === 'warning' && (
            <div className="modal-icon__container">
              <div className="countdown-timer__container">
                <div className="countdown-timer stroke-lightgray">
                  <svg>
                    <circle r="18" cx="20" cy="20" />
                  </svg>
                </div>
              </div>
              <img
                className="modal-warning-icon"
                src={warning}
                alt={ApiStatusModal.warning}
              />
            </div>
          )}
        </button>

        {apiStatus.status === 'error' && <p>{errorStr}</p>}
        {apiStatus.status === 'success' && <p>{apiStatus?.message}</p>}
        {apiStatus.status === 'warning' && <p>{apiStatus?.message}</p>}
      </div>
    </div>
  );
};

export default ApiStatusModal;

// //////remove focus of all elements; add focus to dismiss button
// const modalDismissBtn = document.getElementById('modalDismiss');
// useEffect(() => {
//   if (modalDismissBtn) {
//     modalDismissBtn.focus();
//   }
// }, [modalDismissBtn]);

// const focusableElements = document.querySelectorAll(
//   'a, button, input, textarea, select'
// );
// focusableElements.forEach((element) => {
//   element.blur();
// });
