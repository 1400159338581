import { useEffect } from 'react';

//hooks
import useGenericModal from '../../../../context/useGenericModal';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components

//utils
import {
  img_muffin,
  img_drink,
  img_signing,
  img_clock2,
  img_microphone_on,
  img_pie_chart,
  img_social,
  img_lightbulb,
  star,
  img_play,
  img_enter,
  img_tools,
  img_comment,
  exchange,
  img_hourglass,
  img_question,
  img_beaker,
  img_award,
} from '../../../../assets/icons';

const IconSelectModal = ({
  closeModalFocusId,
  handleSelectIcon,
  itemIndex,
  currentItemTitle,
}) => {
  //Hooks
  const { IconSelectModal, AgendaIconAlts } = useLanguageComponents();
  const { setCloseModalFocusId, setGenericModalMobileFloat } =
    useGenericModal();

  //Initialize functions
  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }

    setCloseModalFocusId(closeModalFocusId);
  }, []);

  function handleSelection(iconCode) {
    handleSelectIcon(iconCode, itemIndex);
    setGenericModalMobileFloat();
  }

  return (
    <div
      className="float-modal access-ow access-o12"
      tabIndex="0"
      id="float-modal"
    >
      <p className="flex-center fs21 mrg-b12">
        {IconSelectModal.selectAgendaIcon}
      </p>
      {currentItemTitle?.length > 0 && (
        <>
          <p className="flex-center fs21 ">"{currentItemTitle}"</p>
          <div className="divider mrg-tb12" />
        </>
      )}

      <div className="flex-center mrg-auto-lr mrg-t12">
        <div className="select-icons-modal">
          <button
            className=" highlight-b-theme"
            onClick={() => handleSelection('muffin', itemIndex)}
          >
            <img
              src={img_muffin}
              alt={AgendaIconAlts.muffin}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('hourglass')}
          >
            <img
              src={img_hourglass}
              alt={AgendaIconAlts.hourglass}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('drink')}
          >
            <img
              src={img_drink}
              alt={AgendaIconAlts.drink}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('speechBubble')}
          >
            <img
              src={img_comment}
              alt={AgendaIconAlts.speechBubble}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('exchangeArrows')}
          >
            <img
              src={exchange}
              alt={AgendaIconAlts.exchangeArrows}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('penSigning')}
          >
            <img
              src={img_signing}
              alt={AgendaIconAlts.penSigning}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('clock')}
          >
            <img
              src={img_clock2}
              alt={AgendaIconAlts.clock}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('microphone')}
          >
            <img
              src={img_microphone_on}
              alt={AgendaIconAlts.microphone}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('pieChart')}
          >
            <img
              src={img_pie_chart}
              alt={AgendaIconAlts.pieChart}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('people')}
          >
            <img
              src={img_social}
              alt={AgendaIconAlts.people}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('lightBulb')}
          >
            <img
              src={img_lightbulb}
              alt={AgendaIconAlts.lightBulb}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('star')}
          >
            <img
              src={star}
              alt={AgendaIconAlts.star}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('playButton')}
          >
            <img
              src={img_play}
              alt={AgendaIconAlts.playButton}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('enterDoor')}
          >
            <img
              src={img_enter}
              alt={AgendaIconAlts.enterDoor}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('tools')}
          >
            <img
              src={img_tools}
              alt={AgendaIconAlts.tools}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('questionMark')}
          >
            <img
              src={img_question}
              alt={AgendaIconAlts.questionMark}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('beaker')}
          >
            <img
              src={img_beaker}
              alt={AgendaIconAlts.beaker}
              className="select-agenda-icon filter-gray"
            />
          </button>
          <button
            className="highlight-b-theme"
            onClick={() => handleSelection('award')}
          >
            <img
              src={img_award}
              alt={AgendaIconAlts.award}
              className="select-agenda-icon filter-gray"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default IconSelectModal;
