import { useEffect, useRef, useState } from 'react';

//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useLazyMyTranslationsBillingQuery } from '../../accountApiSlice';

//Components
import BillingPaymentMethods from './BillingPaymentMethods';
import BillingSummary from './BillingSummary';
import TranslationRoomsUsage from './TranslationRoomsUsage';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../components/Spinner/Spinner';

//Utility
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';
import { img_plus } from '../../../../../assets/icons';
import { retryWrapper } from '../../../../../utils/logic/retryFns';

//invoices retrieved in Account.js to minimize loading ui
const BillingContent = ({ saveAccountChange }) => {
  //Hooks
  const { BillingPaymentMethods: BillingPaymentMethodsLang } =
    useLanguageAccount();
  const {
    accountBillingCustomerId,
    accountId,
    handleAccountFetchCardDetails,
    accountFailedInvoices,
  } = useAccountReduxHandlers();

  //api
  const [getTranslationsBilling, { data: myTranslationsBillingData }] =
    useLazyMyTranslationsBillingQuery();

  //state
  const [cardDetails, setCardDetails] = useState([]);
  const [newCardPending, setNewCardPending] = useState(false);

  const [translationsBillingData, setTranslationsBillingData] = useState();

  //ui
  const [isLoadingCardDetails, setIsLoadingCardDetails] = useState(false);
  //state
  const [
    noticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
    setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
  ] = useState(false);

  //variables
  const fetchCardDetailsRan = useRef(false);
  const fetchTranslationsBillingRan = useRef(false);

  //initialize
  useEffect(() => {
    if (accountId && accountBillingCustomerId) {
      retryWrapper(getTranslationsBilling, [{ accountId }], 3, 2000);
    }
  }, [accountId, accountBillingCustomerId]); //load stripe promise + get invoices

  useEffect(() => {
    if (fetchCardDetailsRan.current === false) {
      fetchCardDetailsRan.current = true;
      if (accountBillingCustomerId)
        retryWrapper(handleAccountFetchCardDetails, [
          isLoadingCardDetails,
          setIsLoadingCardDetails,
          setCardDetails,
        ]);
    }
  }, [accountBillingCustomerId]);

  useEffect(() => {
    if (
      fetchTranslationsBillingRan.current === false &&
      myTranslationsBillingData
    ) {
      fetchTranslationsBillingRan.current = true;
      setTranslationsBillingData(
        myTranslationsBillingData?.myTranslationsBilling?.roomCodeBilling
      );
    }
  }, [myTranslationsBillingData]);

  //functs
  function handleAddNewEmptyCreditCard() {
    setNewCardPending(true);
    let newCardData = [...cardDetails];
    newCardData = [...newCardData, {}];
    setCardDetails(newCardData);
  }

  function handleCancelNewCard() {
    setNewCardPending(false);
    let newCardData = [...cardDetails];
    newCardData = newCardData.slice(0, -1);
    setCardDetails(newCardData);
    handleResetPaymentCards();
  }

  function handleResetPaymentCards() {
    setCardDetails([]);
    setNewCardPending(false);
    setIsLoadingCardDetails(false);

    const resetTimer = setTimeout(() => {
      handleAccountFetchCardDetails(
        isLoadingCardDetails,
        setIsLoadingCardDetails,
        setCardDetails
      );
    }, 1000);

    return () => clearTimeout(resetTimer);
  }

  return (
    <div className="account-content" id="account-content">
      <div className="section mrg-t24 full-width">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="paymentMethodsLabel"
        >
          {BillingPaymentMethodsLang.paymentMethods}
        </label>

        <ItemButton
          handler={handleAddNewEmptyCreditCard}
          text={BillingPaymentMethodsLang.addNewPaymentMethod}
          fullWidth={true}
          icon={img_plus}
          iconOn={true}
          unavailable={newCardPending || cardDetails?.length >= 2}
          customWrapper="mrg-t24"
        />
        {noticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices && (
          <div className="banners__notice align-center full-width fs18 mrg-t24">
            {BillingPaymentMethodsLang.noticeWaitForPaymentToProcess}
          </div>
        )}

        {accountFailedInvoices?.length > 0 &&
          !noticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices && (
            <div className="banners__notice align-center full-width fs18 mrg-t24">
              {BillingPaymentMethodsLang.noticePrimaryCardOutstandingInvoices}
            </div>
          )}

        {isLoadingCardDetails ? (
          <Spinner minHeight={'400px'} />
        ) : (
          <BillingPaymentMethods
            cardDetails={cardDetails}
            saveAccountChange={saveAccountChange}
            handleCancelNewCard={handleCancelNewCard}
            reset={handleResetPaymentCards}
            setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices={
              setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices
            }
          />
        )}
      </div>
      <div className="h48" />
      <BillingSummary />
      <div className="h48" />
      <TranslationRoomsUsage
        translationsBillingData={translationsBillingData}
      />

      <div className="h48" />
      <ScrollToTop focusId={'paymentMethodsLabel'} />

      <div className="h48" />
    </div>
  );
};

export default BillingContent;
