import { createContext, useState, useEffect } from 'react';

//Hooks
import useWindowSize from '../hooks/useWindowSize';
import { useAccountReduxHandlers } from '../features/main/account/useAccountHandlers';
import useTabNavigationHeading from '../hooks/useTabNavigationHeading';
import { useLocation } from 'react-router-dom';
import { useAdminAccountReduxHandlers } from '../features/admin/account/useAdminAccountHandlers';

const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
  //Hooks
  const { width, height } = useWindowSize();
  const location = useLocation();
  const { accountContentLanguage } = useAccountReduxHandlers();
  const { adminAccountContentLanguage } = useAdminAccountReduxHandlers();
  const { setTabNavigationHeading } = useTabNavigationHeading();

  //Context state
  const [nonce, setNonce] = useState('');
  const [openedDropdownMenuEl, setOpenedDropdownMenuEl] = useState();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [cards, setCards] = useState(4);

  const [contentLanguage, setContentLanguage] = useState('en-CA');

  //browser
  const [isSafari, setIsSafari] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  //UI state
  const [windowSettingsSize, setWindowSettingsSize] = useState('desktop');
  const [navbar, setNavbar] = useState('');
  const [openSlideMenu, setOpenSlideMenu] = useState(false);
  const [openMobileNotificationsMenu, setOpenMobileNotificationsMenu] =
    useState(false);

  //Context variables
  let today = new Date();
  let year = today.getFullYear();
  const nonceScript = document.querySelector('script[name="nonce"]').nonce;

  //Initialize & UI functions
  useEffect(() => {
    if (!accountContentLanguage) {
      const browserLanguage = navigator.language.toLowerCase();

      if (
        browserLanguage === 'en' ||
        browserLanguage === 'en-us' ||
        browserLanguage === 'en-ca' ||
        browserLanguage === 'en-CA' ||
        browserLanguage === 'en-US'
      ) {
        setContentLanguage('en-CA');
      }
      if (browserLanguage === 'fr' || browserLanguage === 'fr-ca') {
        setContentLanguage('fr-CA');
      }
    }
  }, []); //detect browser language en/fr

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isChrome = /chrome|crios/i.test(userAgent) && !/edg/i.test(userAgent);
    const isSafari = !isChrome && /safari/i.test(userAgent);
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    setIsMobileDevice(isMobile);
    setIsSafari(isSafari);
    setIsChrome(isChrome);
  }, []);

  useEffect(() => {
    if (accountContentLanguage || adminAccountContentLanguage) {
      setContentLanguage(accountContentLanguage || adminAccountContentLanguage);
    }
  }, [accountContentLanguage, adminAccountContentLanguage]);

  useEffect(() => {
    if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
      setIsTouchDevice(true);
    }
  }, [width]);

  useEffect(() => {
    if (nonceScript) {
      setNonce(nonceScript);
    }
  }, [nonceScript]); ////set nonce, used by imageCropper

  useEffect(() => {
    if (width >= 1920) {
      setWindowSettingsSize('desktop');
    }
    if (width < 1919) {
      setWindowSettingsSize('desktop--narrow');
    }
    if (width <= 1585) {
      setCards(3);
    }
    if (width <= 1280) {
      setWindowSettingsSize('mobile');
    }
    if (width <= 1193) {
      setCards(2);
    }
    if (width <= 801) {
      setCards(1);
    }
    if (width <= 480) {
      setWindowSettingsSize('phone');
    }
    if (width <= 320) {
      setWindowSettingsSize('phone--narrow');
    }
  }, [width]);

  useEffect(() => {
    setOpenMobileNotificationsMenu(false);
  }, [openSlideMenu]);

  //Context functions
  function dismissKeypad(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (width <= 1280) {
        e.target.blur();
      }
    }
  } // Remove focus to dismiss the keypad for mobile/phone

  function handleMobileTap(
    animationFunctionArr,
    executionFunctionsArr,
    tapModeAllMedia
  ) {
    const isMobile = window.matchMedia(
      '(max-width: 1280px) and (pointer: coarse)'
    ).matches;

    let timeout1 = null;
    let timeout2 = null;

    if (isMobile || tapModeAllMedia) {
      animationFunctionArr[0]();

      timeout1 = setTimeout(() => {
        animationFunctionArr[1]();
      }, 250); //returns animation state to default

      timeout2 = setTimeout(() => {
        executionFunctionsArr?.forEach((func) => func());
      }, 400);
    } else {
      executionFunctionsArr?.forEach((func) => func());
    }

    return () => {
      if (timeout1 !== null) {
        clearTimeout(timeout1);
      }

      if (timeout2 !== null) {
        clearTimeout(timeout2);
      }
    };
  }

  useEffect(() => {
    if (
      setTabNavigationHeading &&
      location &&
      (contentLanguage || accountContentLanguage)
    ) {
      setTabNavigationHeading(
        contentLanguage,
        accountContentLanguage,
        location.pathname,
        window.location?.host?.split('.')[0]
      );
    }
  }, [
    setTabNavigationHeading,
    location,
    contentLanguage,
    accountContentLanguage,
  ]); //sets tab navigation

  return (
    <SettingsContext.Provider
      value={{
        cards,
        contentLanguage,
        dismissKeypad,
        handleMobileTap,
        height,
        isTouchDevice,
        navbar,
        nonce,
        openedDropdownMenuEl,
        openSlideMenu,
        setContentLanguage,
        setNavbar,
        setOpenedDropdownMenuEl,
        setOpenSlideMenu,
        today,
        width,
        windowSettingsSize,
        year,
        isSafari,
        isChrome,
        isMobileDevice,
        openMobileNotificationsMenu,
        setOpenMobileNotificationsMenu,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
