import { apiSlice } from '../../../../app/api/apiSlice';
import { setAccountEvents } from '../../account/accountSlice';
import { addReduxApiStatus } from '../../apiStatus/apiStatusSlice';

export const eventParticipantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Event General //
    updateMyEventInviteParticipantStatus: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/updateMyEventInviteParticipantStatus`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/updateMyEventInviteParticipantStatus',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    updateParticipantAccountEvents: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/updateParticipantAccountEvents`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { accountEvents } = data;
          dispatch(setAccountEvents(accountEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'eventParticipantsApiSlice/addEventToInviteParticipant',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['MyEvents', 'Event', 'OrganizerEvent'],
    }),
    withdrawAsParticipant: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/withdrawAsParticipant`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'eventParticipantsApiSlice/withdrawAsParticipant',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['MyEvents'],
    }),
    organizerRemoveAcceptedParticipantUpdateAccountEvents: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/organizerRemoveAcceptedParticipantUpdateAccountEvents`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/organizerRemoveAcceptedParticipantUpdateAccountEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent'],
    }),

    //Event Draft//
    updateInviteContactParticipantDraftEventsFromDraftRemoval: builder.mutation(
      {
        query: (body) => ({
          url: `/api/eventParticipants/event/updateInviteContactParticipantDraftEventsFromDraftRemoval`,
          method: 'PATCH',
          body,
        }),
        transformErrorResponse: (response, meta, arg) => {
          return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data?.error) {
              throw data.error;
            }
          } catch (error) {
            const err = {
              id: Date.now(),
              status: 'error',
              error: error.error,
              origin:
                'eventParticipantsApiSlice/updateInviteContactParticipantDraftEventsFromDraftRemoval',
            };
            return dispatch(addReduxApiStatus(err));
          }
        },
        invalidatesTags: ['Draft'],
      }
    ),

    //Event Publication//
    updateInviteContactParticipantEventsFromEventRemoval: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/updateInviteContactParticipantEventsFromEventRemoval`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSliceupdateInviteContactParticipantEventsFromEventRemoval',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
    updateInviteContactParticipantEventsFromPublication: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/updateInviteContactParticipantEventsFromPublication`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/updateInviteContactParticipantEventsFromPublication',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent'],
    }),

    //Notices General//
    inviteContactEventParticipant: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/inviteContactEventParticipant`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'eventParticipantsApiSlice/inviteContactEventParticipant',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),

    eventParticipationInviteResponse: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/eventParticipationInviteResponse`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/eventParticipationInviteResponse',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    participantWithdrew: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/participantWithdrew`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'eventParticipantsApiSlice/participantWithdrew',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['MyEvents', 'OrganizerEvent'],
    }),
    organizerRemovePendingParticipant: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/organizerRemovePendingParticipant`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/organizerRemovesPendingParticipant',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft', 'OrganizerEvent'],
    }),
    organizerRemoveParticipantIssueNoticeToParticipant: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/organizerRemoveParticipantIssueNoticeToParticipant`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/organizerRemoveParticipantIssueNoticeToParticipant',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),

    //Notices Draft//
    eventDraftRemovalNoticesUpdateForInviteContactParticipants:
      builder.mutation({
        query: (body) => ({
          url: `/api/eventParticipants/notices/eventDraftRemovalNoticesUpdateForInviteContactParticipants`,
          method: 'PATCH',
          body,
        }),
        transformErrorResponse: (response, meta, arg) => {
          return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data?.error) {
              throw data.error;
            }
          } catch (error) {
            const err = {
              id: Date.now(),
              status: 'error',
              error: error.error,
              origin:
                'eventParticipantsApiSlice/eventDraftRemovalNoticesUpdateForInviteContactParticipants',
            };
            return dispatch(addReduxApiStatus(err));
          }
        },
      }),

    //Notices Publication//
    eventRemovalNoticesUpdateForInviteContactParticipants: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/eventRemovalNoticesUpdateForInviteContactParticipants`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/eventRemovalNoticesUpdateForInviteContactParticipants',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    changePendingNoticesForInviteContactsFromEventPublication: builder.mutation(
      {
        query: (body) => ({
          url: `/api/eventParticipants/notices/changePendingNoticesForInviteContactsFromEventPublication`,
          method: 'PATCH',
          body,
        }),
        transformErrorResponse: (response, meta, arg) => {
          return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data?.error) {
              throw data.error;
            }
          } catch (error) {
            const err = {
              id: Date.now(),
              status: 'error',
              error: error.error,
              origin:
                'OrganizedEventApiSlice/changePendingNoticesForInviteContactsFromEventPublication',
            };
            return dispatch(addReduxApiStatus(err));
          }
        },
      }
    ),
    notifyInviteContactsEventPublished: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/notifyInviteContactsEventPublished`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/notifyInviteContactsEventPublished',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    sendParticipantEmailInvitation: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/sendParticipantEmailInvitation`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'eventParticipantsApiSlice/sendParticipantEmailInvitation',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),

    //Translations
    inviteContactEventParticipantTranslations: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/inviteContactEventParticipantTranslations`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/inviteContactEventParticipantTranslations',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    sendParticipantEmailTranslations: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/sendParticipantEmailTranslations`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/sendParticipantEmailTranslations',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    eventParticipationTranslationsResponse: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/eventParticipationTranslationsResponse`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/eventParticipationTranslationsResponse',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    updateEventParticipantTranslationsStatus: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/updateEventParticipantTranslationsStatus`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/updateEventParticipantTranslationsStatus',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    sendInviteContactTranslationsData: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/event/sendInviteContactTranslationsData`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/sendInviteContactTranslationsData',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    notifyOrganizerTranslationsDataSubmitted: builder.mutation({
      query: (body) => ({
        url: `/api/eventParticipants/notices/notifyOrganizerTranslationsDataSubmitted`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin:
              'eventParticipantsApiSlice/notifyOrganizerTranslationsDataSubmitted',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  //event - general//
  useUpdateMyEventInviteParticipantStatusMutation,
  useUpdateParticipantAccountEventsMutation,
  useOrganizerRemoveAcceptedParticipantUpdateAccountEventsMutation,
  useWithdrawAsParticipantMutation,
  useSendParticipantEmailInvitationMutation,

  //event - draft //
  useUpdateInviteContactParticipantDraftEventsFromDraftRemovalMutation,

  //event - publication //
  useUpdateInviteContactParticipantEventsFromPublicationMutation,
  useUpdateInviteContactParticipantEventsFromEventRemovalMutation,

  //event - translations//
  useUpdateEventParticipantTranslationsStatusMutation,
  useSendInviteContactTranslationsDataMutation,

  //notices - general//
  useInviteContactEventParticipantMutation,
  useEventParticipationInviteResponseMutation,
  useParticipantWithdrewMutation,
  useOrganizerRemovePendingParticipantMutation,
  useOrganizerRemoveParticipantIssueNoticeToParticipantMutation,

  //notices - translations //
  useInviteContactEventParticipantTranslationsMutation,
  useSendParticipantEmailTranslationsMutation,
  useEventParticipationTranslationsResponseMutation,
  useNotifyOrganizerTranslationsDataSubmittedMutation,

  //notices - draft//
  useEventDraftRemovalNoticesUpdateForInviteContactParticipantsMutation,

  //notices - publication//
  useEventRemovalNoticesUpdateForInviteContactParticipantsMutation,
  useChangePendingNoticesForInviteContactsFromEventPublicationMutation,
  useNotifyInviteContactsEventPublishedMutation,
} = eventParticipantsApiSlice;
