import { useEffect, useState } from 'react';

//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../../../../language/useLanguageComponents';

//components

//utils
import { img_exclamation } from '../../../../../../../assets/icons';

const DispatchesInvitationStatus = ({
  p,
  invitationHasExpired,
  allDispatchesIssued,
  translationsEnabled,
}) => {
  //hooks
  const { ParticipantListItem, ParticipantListItemCompletionStatusDispatches } =
    useLanguageOrganize();
  const { IconAlts } = useLanguageComponents();

  //state
  const [dispatchStatus, setDispatchStatus] = useState();

  useEffect(() => {
    if (allDispatchesIssued) {
      setDispatchStatus('allDispatchesIssued');
    } else if (
      p.dispatches.invitationStatus === 'dispatching' ||
      p.dispatches.translationsStatus === 'dispatching'
    ) {
      setDispatchStatus('dispatching');
    } else {
      if (
        translationsEnabled &&
        (!p.dispatches.inviteDispatched || !p.dispatches.translationsDispatched)
      ) {
        setDispatchStatus('dispatchesAvailable');
      } else if (!p.dispatches.inviteDispatched) {
        setDispatchStatus('dispatchesAvailable');
      }
    }
  }, [p, allDispatchesIssued, translationsEnabled]);

  return (
    <>
      {invitationHasExpired ? (
        <>
          {ParticipantListItem.emailInvitationExpired}
          <img
            src={img_exclamation}
            alt={IconAlts.iconExclamation}
            style={{ height: '18px' }}
            className="mrg-auto-tb mrg-l12 filter-red"
          />
        </>
      ) : (
        <>{ParticipantListItemCompletionStatusDispatches[dispatchStatus]}</>
      )}
    </>
  );
};

export default DispatchesInvitationStatus;
