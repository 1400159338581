import useSettings from '../../context/useSettings';

const useLanguageEvents = () => {
  const { contentLanguage } = useSettings();

  const EventsGrid = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        searchCompleted: 'Search Completed',
        loadMore: 'Load More',
        noEvents: 'No Events',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        searchCompleted: 'Recherche terminée',
        loadMore: 'Charger plus',
        noEvents: `Pas d'événements`,
      };
    }

    return translation;
  };

  const EventCard = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        displayImage: 'display image',
        ignoreButton: 'ignore button',
        clickToUnignore: 'Click to Unignore',
        clickToIgnore: 'Click to Ignore',
        saveButton: 'save button',
        clickToSave: 'Click to Save',
        clickToUnsave: 'Click to Unsave',
        dateIcon: 'date icon',
        locationIcon: 'location icon',
        organizerIcon: 'organizer logo',
        paidEvent: 'Paid Event',
        accessibility: 'Accessibility',
        followedAccount: 'Includes a Followed Account',
        savedEvent: 'Saved Event',
        ignoredEvent: 'Ignored Event',
        registration: 'Registration',
        remove: 'Remove',
        removeFromArchive: 'Remove from Archive',
        platform: 'Platform',
        pendingApproval: 'Pending',
        registered: 'Registered',
        clickToOpenAnnouncements: 'Click to open event announcements',
        openAnnouncements: 'Open Announcements',
        full: 'Full',
        almostFull: 'Almost Full',
        open: 'Open',
        inPersonIcon: 'In Person Icon',
        virtualIcon: 'Virtual Icon',
        organizer: 'Organizer',
        participant: 'Participant',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        displayImage: `afficher l'image`,
        ignoreButton: `bouton d'ignorer`,
        clickToUnignore: `Cliquez pour annuler l'ignorance`,
        clickToIgnore: 'Cliquez pour ignorer',
        saveButton: `bouton d'enregistrement`,
        clickToSave: 'Cliquez pour vous inscrire',
        clickToUnsave: `Cliquez pour supprimer l'enregistrement`,
        dateIcon: 'icône de date',
        locationIcon: 'icône de localisation',
        organizerIcon: `logo de l'organisateur`,
        paidEvent: 'Événement payant',
        accessibility: 'Accessibilité',
        followedAccount: 'Comprend un compte suivi',
        savedEvent: 'Événements sauvegardés',
        ignoredEvent: 'Événement ignoré',
        registration: 'Inscription',
        remove: 'Supprimer',
        removeFromArchive: 'Supprimer des archives',
        platform: 'Plate-forme',
        pendingApproval: 'En validation',
        registered: 'Enregistré',
        clickToOpenAnnouncements: `Cliquez pour ouvrir les annonces d'événements`,
        openAnnouncements: 'Annonces ouvertes',
        full: 'Plein',
        almostFull: 'Presque plein',
        open: 'Ouverte',
        inPersonIcon: 'Icône En personne',
        virtualIcon: 'Icône virtuelle',
        organizer: 'Organisateur',
        participant: 'Participant',
      };
    }

    return translation;
  };

  const RemoveArchivedEventModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        yes: 'Yes',
        no: 'No',
        removeArchivedEventWarning:
          'Are you sure you want to delete this archived event?',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        yes: 'Oui',
        no: 'Non',
        removeArchivedEventWarning:
          'Êtes-vous sûr de vouloir supprimer cet événement archivé ?',
      };
    }

    return translation;
  };

  const EventPage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        aboutThisEvent: 'About this event',
        accessibility: 'Accessibility',
        addTagToAccount: 'Add tag to your account',
        agenda: 'Agenda',
        almostFull: '(Almost Full)',
        audience: 'Audience',
        calendarIcon: 'calendar icon',
        checkmark: 'Checkmark',
        clickToFollow: 'Click to Follow',
        clickToIgnore: 'Click to Ignore',
        clickToOpenAnnouncements: 'Click to open event announcements',
        clickToRegister: 'Click to Register',
        clickToSave: 'Click to Save',
        clickToShare: 'Click to Share',
        clickToSwitch: 'Click to Switch',
        clickToUnfollow: 'Click to Unfollow',
        clickToUnignore: 'Click to Unignore',
        clickToUnregister: 'Click to Unregister',
        clickToUnsave: 'Click to Unsave',
        clock: 'Clock',
        description: 'Description',
        displayImage: 'display image',
        duration: 'Duration',
        endTime: 'End time',
        eventType: 'Event Type',
        follow: 'Follow',
        format: 'Format',
        full: '(Full)',
        ignoreButton: 'ignore button',
        ignoredEvent: 'Ignored Event',
        inPerson: '(In Person)',
        inPersonAttendance: 'In-Person Attendance',
        joinWaitingList: 'Join Wait List',
        languages: 'Language(s)',
        link: 'Link',
        location: 'Location',
        locationIcon: 'location icon',
        locationName: 'Location Name',
        meetingId: 'Meeting Id',
        minutes: 'Minutes',
        moderator: 'Moderator',
        networkIcon: 'network icon',
        notes: 'Notes',
        open: '(Open)',
        openAnnouncements: 'Open Announcements',
        organizer: 'Organizer',
        organizerIcon: 'organizer icon',
        paidEvent: 'Paid Event',
        participants: 'Participants',
        passcode: 'Passcode',
        pendingApproval: 'Pending...',
        pendingApprovalInPerson: 'Pending In Person',
        pendingApprovalVirtual: 'Pending Virtual',
        platform: 'Platform',
        register: 'Register',
        registered: 'Registered',
        registerInPerson: 'Register In Person',
        registerVirtual: 'Register Virtual',
        registration: 'Registration',
        registrationAlmostFull: 'Registration (Almost Full)',
        registrationFull: 'Registration (Full)',
        registrationIcon: 'registration icon',
        registrationOpen: 'Registration (Open)',
        remove: 'Remove',
        removeFromArchive: 'Remove from Archive',
        removeTagFromAccount: 'Remove tag from your account',
        report: 'Report',
        return: 'Return',
        room: 'Room',
        saveButton: 'save button',
        savedEvent: 'Saved Event',
        shareButton: 'Share Button',
        showMap: 'Show Map',
        signLanguageInterpreter: 'Sign language interpreter',

        startTime: 'Start time',
        streetAddress: 'Street address',
        tags: 'Tags',
        virtual: '(Virtual)',
        virtualAttendance: 'Virtual Attendance',
        wheelchairAccessible: 'Wheelchair accessible',
        //
        eventParticipants: 'Event Participants',
        inviteContactPreviewOnly: `This participant's information is available in preview mode, but it will not be displayed publicly until their invitation is accepted.`,
        withdrawAsParticipant: 'Withdraw as Participant',

        valueHiddenForNonRegistered:
          'Value hidden for non-registered attendees',

        contact: 'Contact',
        email: 'Email',
        phoneNumber: 'Phone Number',
        roomCode: 'Room Code',
        attendeePassword: 'Attendee Password',
        myndfullTranslations: 'MyndFull Real-time Translations',
        availableLanguages: 'Available Languages',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        aboutThisEvent: 'À propos de cet événement',
        accessibility: 'Accessibilité',
        addTagToAccount: 'Ajoutez cette balise à votre compte',
        agenda: `Ordre du jour de l'événement`,
        almostFull: '(Presque plein)',
        audience: 'Public cible',
        calendarIcon: 'icône de calendrier',
        checkmark: 'Coche',
        clickToFollow: 'Click to Follow',
        clickToIgnore: 'Cliquez pour ignorer',
        clickToOpenAnnouncements: `Cliquez pour ouvrir les annonces d'événements`,
        clickToRegister: 'Cliquez pour vous inscrire',
        clickToSave: 'Cliquez pour vous inscrire',
        clickToShare: 'Cliquez pour partager',
        clickToSwitch: 'Cliquez pour changer',
        clickToUnfollow: 'Click to Unfollow',
        clickToUnignore: `Cliquez pour annuler l'ignorance`,
        clickToUnregister: 'Cliquez pour vous désinscrire',
        clickToUnsave: `Cliquez pour supprimer l'enregistrement`,
        clock: 'Horloge',
        description: 'Description',
        displayImage: `afficher l'image`,
        duration: 'Durée',
        endTime: 'Heure de fin',
        eventType: `Type d'événement`,
        follow: 'Suivre',
        format: 'Format',
        full: '(Plein)',
        ignoreButton: `bouton d'ignorer`,
        ignoredEvent: 'Événement ignoré',
        inPerson: '(en personne)',
        inPersonAttendance: 'Présence en personne',
        joinWaitingList: `Rejoindre la liste d'attente`,
        languages: 'Language(s)',
        link: 'Lien',
        location: 'Emplacement',
        locationIcon: 'icône de localisation',
        locationName: 'Nom de la localisation',
        meetingId: 'ID de réunion',
        minutes: 'Minutes',
        moderator: 'Modérateur',
        networkIcon: 'icône de réseau',
        notes: 'Notes',
        open: '(Ouverte)',
        openAnnouncements: 'Annonces ouvertes',
        organizer: 'Organisateur',
        organizerIcon: `icône de l'organisateur`,
        paidEvent: 'Événement payant',
        participants: 'Participants',
        passcode: `Code d'accès`,
        pendingApproval: 'En validation',
        pendingApprovalInPerson: 'En validation (en personne)',
        pendingApprovalVirtual: 'En validation (virtuellement)',
        platform: 'Plate-forme',
        register: 'Registre',
        registered: 'Enregistré',
        registerInPerson: 'Inscription en personne',
        registerVirtual: 'Inscription virtuelle',
        registration: 'Inscription',
        registrationAlmostFull: 'Inscription (Presque plein)',
        registrationFull: 'Inscription (Plein)',
        registrationIcon: 'icône de inscription',
        registrationOpen: 'Inscription (Ouverte)',
        remove: 'Supprimer',
        removeFromArchive: 'Supprimer des archives',
        removeTagFromAccount: 'Supprimez cette balise de votre compte',
        report: 'Signaler',
        return: 'Retour',
        room: 'Salle',
        saveButton: `bouton d'enregistrement`,
        savedEvent: 'Événements sauvegardés',
        shareButton: 'Bouton partager',
        showMap: 'Afficher la carte',
        signLanguageInterpreter: 'Interprète en langue des signes',
        startTime: 'Heure de début',
        streetAddress: 'Adresse',
        tags: 'Tags',
        virtual: '(virtuellement)',
        virtualAttendance: 'Participation virtuelle',
        wheelchairAccessible: 'Accessibilité en fauteuil roulant',
        eventParticipants: `Participants à l'événement`,
        inviteContactPreviewOnly: `Les informations de ce participant sont disponibles en mode aperçu, mais elles ne seront pas affichées publiquement tant que son invitation n'aura pas été acceptée.`,
        withdrawAsParticipant: 'Se retirer comme participant',
        valueHiddenForNonRegistered:
          'Valeur masquée pour les participants non inscrits',

        contact: 'Contact',
        email: 'E-mail',
        phoneNumber: 'Numéro de téléphone',
        roomCode: 'Code de la salle',
        attendeePassword: 'Mot de passe du participant',
        myndfullTranslations: 'MyndFull Traductions en temps réel',
        availableLanguages: 'Langues disponibles',
      };
    }

    return translation;
  };

  return {
    EventPage: EventPage(),
    EventsGrid: EventsGrid(),
    EventCard: EventCard(),
    RemoveArchivedEventModal: RemoveArchivedEventModal(),
  };
};

export default useLanguageEvents;
