import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generic: {
    accountIdVerification: '',
    lastRoomCode: '',
    persist: true,
    loginEmail: '',
    viewEventDetails: '',
  },
  account: {
    accountSettingsView: 'account',
  },
  searches: {
    general: {
      eventSearchCategory: 'date',
      eventSearchDirection: 'ascending',
      eventSearchFormat: 'inPerson',
      eventSearchView: 'bigtags',
    },
    bigTags: {
      selectedBigTags: [],
      bigTagsListOpened: true,
      bTQueryHasMore: false,
      bTQueryPage: 0,
      selectAllBTBtn: false,
    },
    myEvents: {
      selectedMyEvents: ['registered', 'saved', 'participationEvents'],
      myEventsListOpened: true,
      selectAllMyEventsBtn: true,
    },
    community: {
      selectedAccountsFollowingIds: [],
      selectedAccountsFollowingGroupIds: [],
      accountsFollowingListOpened: true,
      selectAllAccountsFollowingBtn: false,
      communityQueryHasMore: false,
      communityQueryPage: 0,
    },
    tinyTags: {
      selectAllTTBtn: false,
      selectedTinyTagGroupIds: [],
      selectedTinyTags: [],
      tinyTagsListOpened: true,
      tTQueryHasMore: false,
      tTQueryPage: 0,
    },
    findSpeakers: {
      findSpeakersHasMore: false,
      findSpeakersQueryPage: 0,
      searchSpeakerLanguages: [],
      searchSpeakerLanguagesNotListed: [],
      searchLocationType: '',
      searchCountries: [],
      searchCoordinates: [],
      distanceFilter: 20000,
      distanceMeasurement: 'km',
      searchBigTags: [],
      searchTinyTags: [],
    },
  },
  organize: {
    general: {
      organizeView: 'overview',
    },
    overview: {
      organizeOverviewSortCategory: 'date',
      organizeOverviewDirection: 'ascending',
      organizeOverviewFormat: 'cards', //cards, table, dash
      selectedOrganizers: [],
      selectAllOrganizersBtn: false,
      organizeOverviewListOpened: true,
      content: 'standalone',
      standaloneEvents: 'active',
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    //Generic
    settingsSetAccountIdVerification: (state, action) => {
      state.generic.accountIdVerification = action.payload;
    },
    settingsReset: (state) => {
      return initialState;
    },
    settingsSetLastRoomCode: (state, action) => {
      state.generic.lastRoomCode = action.payload;
    },
    settingsSetPersist: (state, action) => {
      state.generic.persist = action.payload;
    },
    settingsSetLoginEmail: (state, action) => {
      state.generic.loginEmail = action.payload;
    },
    settingsSetViewEventDetails: (state, action) => {
      state.generic.viewEventDetails = action.payload;
    },

    //Account
    settingsSetAccountSettingsView: (state, action) => {
      state.account.accountSettingsView = action.payload;
    },

    //Searches - general
    settingsSetEventSearchCategory: (state, action) => {
      state.searches.general.eventSearchCategory = action.payload;
    },
    settingsSetEventSearchDirection: (state, action) => {
      state.searches.general.eventSearchDirection = action.payload;
    },
    settingsSetEventSearchFormat: (state, action) => {
      state.searches.general.eventSearchFormat = action.payload;
    },
    settingsSetEventSearchView: (state, action) => {
      state.searches.general.eventSearchView = action.payload;
    },

    //Searches - bigTags
    settingsAddSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags.push(action.payload);
    },
    settingsRemoveAllSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags = [];
    },
    settingsRemoveSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags.splice(
        state.searches.bigTags.selectedBigTags.indexOf(action.payload),
        1
      );
    },
    settingsSetBigTagsListOpened: (state, action) => {
      state.searches.bigTags.bigTagsListOpened = action.payload;
    },
    settingsSetSelectAllBTBtn: (state, action) => {
      state.searches.bigTags.selectAllBTBtn = action.payload;
    },
    settingsSetSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags = action.payload;
    },
    settingsSetBTQueryHasMore: (state, action) => {
      state.searches.bigTags.bTQueryHasMore = action.payload;
    },
    settingsSetBTQueryPage: (state, action) => {
      state.searches.bigTags.bTQueryPage = action.payload;
    },

    //Searches - Community
    settingsSetSelectAllAccountsFollowingBtn: (state, action) => {
      state.searches.community.selectAllAccountsFollowingBtn = action.payload;
    },
    settingsSetAccountsFollowingListOpened: (state, action) => {
      state.searches.community.accountsFollowingListOpened = action.payload;
    },
    settingsSetCommunityQueryHasMore: (state, action) => {
      state.searches.community.communityQueryHasMore = action.payload;
    },
    settingsSetCommunityQueryPage: (state, action) => {
      state.searches.community.communityQueryPage = action.payload;
    },
    settingsSetSelectedAccountsFollowingIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingIds = action.payload;
    },
    settingsAddSelectedAccountsFollowingId: (state, action) => {
      state.searches.community.selectedAccountsFollowingIds.push(
        action.payload
      );
    },
    settingsRemoveAllSelectedAccountsFollowingIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingIds = [];
    },
    settingsRemoveSelectedAccountsFollowingId: (state, action) => {
      state.searches.community.selectedAccountsFollowingIds.splice(
        state.searches.community.selectedAccountsFollowingIds.indexOf(
          action.payload
        ),
        1
      );
    },

    //Searches Community Groups
    settingsAddSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingGroupIds.push(
        action.payload
      );
    },
    settingsRemoveAllSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingGroupIds = [];
    },
    settingsRemoveSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingGroupIds.splice(
        state.searches.community.selectedAccountsFollowingGroupIds.indexOf(
          action.payload
        ),
        1
      );
    },
    settingsSetSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.community.selectedAccountsFollowingGroupIds =
        action.payload;
    },

    //Searches - myEvents
    settingsSetSelectedMyEvents: (state, action) => {
      state.searches.myEvents.selectedMyEvents = action.payload;
    },
    settingsSetMyEventsListOpened: (state, action) => {
      state.searches.myEvents.myEventsListOpened = action.payload;
    },
    settingsSetSelectAllMyEventsBtn: (state, action) => {
      state.searches.myEvents.selectAllMyEventsBtn = action.payload;
    },

    //Searches - tinyTags
    settingsSetTinyTagsListOpened: (state, action) => {
      state.searches.tinyTags.tinyTagsListOpened = action.payload;
    },
    settingsSetTTQueryHasMore: (state, action) => {
      state.searches.tinyTags.tTQueryHasMore = action.payload;
    },
    settingsSetTTQueryPage: (state, action) => {
      state.searches.tinyTags.tTQueryPage = action.payload;
    },
    settingsSetSelectAllTTBtn: (state, action) => {
      state.searches.tinyTags.selectAllTTBtn = action.payload;
    },

    settingsAddSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags.push(action.payload);
    },
    settingsRemoveSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags.splice(
        state.searches.tinyTags.selectedTinyTags.indexOf(action.payload),
        1
      );
    },
    settingsRemoveAllSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags = [];
    },
    settingsSetSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags = action.payload;
    },

    //Tiny Tag groups
    settingsAddSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds.push(action.payload);
    },
    settingsRemoveAllSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds = [];
    },
    settingsRemoveSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds.splice(
        state.searches.tinyTags.selectedTinyTagGroupIds.indexOf(action.payload),
        1
      );
    },
    settingsSetSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds = action.payload;
    },

    //searches find speakers
    settingsResetFindSpeakers: (state, action) => {
      state.searches.findSpeakers = {
        findSpeakersHasMore: false,
        findSpeakersQueryPage: 0,
        searchSpeakerLanguages: [],
        searchSpeakerLanguagesNotListed: [],
        searchLocationType: '',
        searchCountries: [],
        searchCoordinates: [],
        distanceFilter: 20000,
        distanceMeasurement: 'km',
        searchBigTags: [],
        searchTinyTags: [],
      };
    },
    settingsSetFindSpeakersHasMore: (state, action) => {
      state.searches.findSpeakers.findSpeakersHasMore = action.payload;
    },
    settingsSetFindSpeakersQueryPage: (state, action) => {
      state.searches.findSpeakers.findSpeakersQueryPage = action.payload;
    },
    settingsSetFindSpeakersSearchSpeakerLanguages: (state, action) => {
      state.searches.findSpeakers.searchSpeakerLanguages = action.payload;
    },
    settingsSetFindSpeakersSearchSpeakerLanguagesNotListed: (state, action) => {
      state.searches.findSpeakers.searchSpeakerLanguagesNotListed =
        action.payload;
    },
    settingsSetFindSpeakersSearchLocationType: (state, action) => {
      state.searches.findSpeakers.searchLocationType = action.payload;
    },
    settingsSetFindSpeakersSearchCountries: (state, action) => {
      state.searches.findSpeakers.searchCountries = action.payload;
    },
    settingsSetFindSpeakersSearchCoordinates: (state, action) => {
      state.searches.findSpeakers.searchCoordinates = action.payload;
    },
    settingsSetFindSpeakersDistanceFilter: (state, action) => {
      state.searches.findSpeakers.distanceFilter = action.payload;
    },
    settingsSetFindSpeakersDistanceMeasurement: (state, action) => {
      state.searches.findSpeakers.distanceMeasurement = action.payload;
    },
    settingsSetFindSpeakersSearchBigTags: (state, action) => {
      state.searches.findSpeakers.searchBigTags = action.payload;
    },
    settingsSetFindSpeakersSearchTinyTags: (state, action) => {
      state.searches.findSpeakers.searchTinyTags = action.payload;
    },

    //Organize
    settingsSetOrganizeView: (state, action) => {
      state.organize.general.organizeView = action.payload;
    },
    settingsSetOrganizeOverviewSortCategory: (state, action) => {
      state.organize.overview.organizeOverviewSortCategory = action.payload;
    },
    settingsSetOrganizeOverviewDirection: (state, action) => {
      state.organize.overview.organizeOverviewDirection = action.payload;
    },
    settingsSetOrganizeOverviewFormat: (state, action) => {
      state.organize.overview.organizeOverviewFormat = action.payload;
    },
    settingsSetSelectAllOrganizersBtn: (state, action) => {
      state.organize.overview.selectAllOrganizersBtn = action.payload;
    },
    settingsSetStandaloneGridEvents: (state, action) => {
      state.organize.overview.standaloneEvents = action.payload;
    },
    settingsSetOrganizeOverviewListOpened: (state, action) => {
      state.organize.overview.organizeOverviewListOpened = action.payload;
    },
    settingsAddSelectedOrganizer: (state, action) => {
      state.organize.overview.selectedOrganizers.push(action.payload);
    },
    settingsRemoveSelectedOrganizer: (state, action) => {
      state.organize.overview.selectedOrganizers.splice(
        state.organize.overview.selectedOrganizers.indexOf(action.payload),
        1
      );
    },
    settingsSetSelectedOrganizers: (state, action) => {
      state.organize.overview.selectedOrganizers = action.payload;
    },
    settingsSetOrganizeOverviewContent: (state, action) => {
      state.organize.overview.content = action.payload;
    },
  },
});

export const {
  //Generic
  settingsSetAccountIdVerification,
  settingsSetLastRoomCode,
  settingsReset,
  settingsSetPersist,
  settingsSetLoginEmail,
  settingsSetViewEventDetails,

  //Account
  settingsSetAccountSettingsView,

  //Searches - general
  settingsSetEventSearchCategory,
  settingsSetEventSearchDirection,
  settingsSetEventSearchFormat,
  settingsSetEventSearchView,

  //Searches - big tags
  settingsAddSelectedBigTags,
  settingsRemoveAllSelectedBigTags,
  settingsRemoveSelectedBigTags,
  settingsSetBigTagsListOpened,
  settingsSetSelectAllBTBtn,
  settingsSetSelectedBigTags,
  settingsSetBTQueryHasMore,
  settingsSetBTQueryPage,

  //Searches - myEvents
  settingsSetSelectedMyEvents,
  settingsSetMyEventsListOpened,
  settingsSetSelectAllMyEventsBtn,

  //Searches - community
  settingsAddSelectedAccountsFollowingId,
  settingsSetCommunityQueryHasMore,
  settingsSetCommunityQueryPage,
  settingsRemoveAllSelectedAccountsFollowingIds,
  settingsRemoveSelectedAccountsFollowingId,
  settingsSetAccountsFollowingListOpened,
  settingsSetSelectedAccountsFollowingIds,
  settingsSetSelectAllAccountsFollowingBtn,

  //Searches - community groups
  settingsAddSelectedAccountsFollowingGroupIds,
  settingsRemoveAllSelectedAccountsFollowingGroupIds,
  settingsRemoveSelectedAccountsFollowingGroupIds,
  settingsSetSelectedAccountsFollowingGroupIds,

  //Searches - tiny tags
  settingsAddSelectedTinyTags,
  settingsRemoveAllSelectedTinyTags,
  settingsRemoveSelectedTinyTags,
  settingsSetSelectedTinyTags,
  settingsSetTinyTagsListOpened,
  settingsSetTTQueryHasMore,
  settingsSetTTQueryPage,
  settingsSetSelectAllTTBtn,

  //Searches - tiny tag groups
  settingsAddSelectedTinyTagGroupIds,
  settingsRemoveAllSelectedTinyTagGroupIds,
  settingsRemoveSelectedTinyTagGroupIds,
  settingsSetSelectedTinyTagGroupIds,

  //Searches Find Speakers
  settingsResetFindSpeakers,
  settingsSetFindSpeakersHasMore,
  settingsSetFindSpeakersQueryPage,
  settingsSetFindSpeakersSearchSpeakerLanguages,
  settingsSetFindSpeakersSearchSpeakerLanguagesNotListed,
  settingsSetFindSpeakersSearchLocationType,
  settingsSetFindSpeakersSearchCountries,
  settingsSetFindSpeakersSearchCoordinates,
  settingsSetFindSpeakersDistanceFilter,
  settingsSetFindSpeakersDistanceMeasurement,
  settingsSetFindSpeakersSearchBigTags,
  settingsSetFindSpeakersSearchTinyTags,

  //Organize
  settingsSetOrganizeView,
  settingsSetOrganizeOverviewSortCategory,
  settingsSetOrganizeOverviewDirection,
  settingsSetOrganizeOverviewFormat,
  settingsSetSelectAllOrganizersBtn,
  settingsSetOrganizeOverviewListOpened,
  settingsAddSelectedOrganizer,
  settingsRemoveSelectedOrganizer,
  settingsSetSelectedOrganizers,
  settingsSetOrganizeOverviewContent,
  settingsSetStandaloneGridEvents,
} = settingsSlice.actions;

export default settingsSlice.reducer;

//Generic
export const selectSettingsAccountIdVerification = (state) =>
  state.settings?.generic?.accountIdVerification;

export const selectSettingsLastRoomCode = (state) =>
  state.settings?.generic?.lastRoomCode;

export const selectSettingsPersist = (state) =>
  state.settings?.generic?.persist;

export const selectSettingsLoginEmail = (state) =>
  state.settings?.generic?.loginEmail;

export const selectSettingsSetViewEventDetails = (state) =>
  state.settings?.generic?.viewEventDetails;

//Account
export const selectSettingsAccountSettingsView = (state) =>
  state.settings?.account?.accountSettingsView;

//Searches - General
export const selectSettingsEventSearchCategory = (state) =>
  state.settings?.searches?.general?.eventSearchCategory;

export const selectSettingsEventSearchDirection = (state) =>
  state.settings?.searches?.general?.eventSearchDirection;

export const selectSettingsEventSearchFormat = (state) =>
  state.settings?.searches?.general?.eventSearchFormat;

export const selectSettingsEventSearchView = (state) =>
  state.settings?.searches?.general?.eventSearchView;

//Searches - BigTags
export const selectSettingsSelectedBigTags = (state) =>
  state.settings?.searches?.bigTags?.selectedBigTags;

export const selectSettingsBigTagsListOpened = (state) =>
  state.settings?.searches?.bigTags?.bigTagsListOpened;

export const selectSettingsBTQueryHasMore = (state) =>
  state.settings?.searches?.bigTags?.bTQueryHasMore;

export const selectSettingsBTQueryPage = (state) =>
  state.settings?.searches?.bigTags?.bTQueryPage;

export const selectSettingsSelectAllBTBtn = (state) =>
  state.settings?.searches?.bigTags?.selectAllBTBtn;

//Searches - Community
export const selectSettingsSelectedAccountsFollowingIds = (state) =>
  state.settings?.searches?.community?.selectedAccountsFollowingIds;

export const selectSettingsAccountsFollowingListOpened = (state) =>
  state.settings?.searches?.community?.accountsFollowingListOpened;

export const selectSettingsCommunityQueryHasMore = (state) =>
  state.settings?.searches?.community?.communityQueryHasMore;

export const selectSettingsCommunityQueryPage = (state) =>
  state.settings?.searches?.community?.communityQueryPage;

export const selectSettingsSelectAllAccountsFollowingBtn = (state) =>
  state.settings?.searches?.community?.selectAllAccountsFollowingBtn;

export const selectSettingsSelectedAccountsFollowingGroupIds = (state) =>
  state.settings?.searches?.community?.selectedAccountsFollowingGroupIds;

//Searches - MyEvents
export const selectSettingsMyEventsListOpened = (state) =>
  state.settings?.searches?.myEvents?.myEventsListOpened;

export const selectSettingsMyEventsSelectedMyEvents = (state) =>
  state.settings?.searches?.myEvents?.selectedMyEvents;

export const selectSettingsMyEventsSelectAllMyEventsBtn = (state) =>
  state?.settings?.searches?.myEvents?.selectAllMyEventsBtn;

//Searches - Tiny Tags
export const selectSettingsSelectAllTTBtn = (state) =>
  state.settings?.searches?.tinyTags?.selectAllTTBtn;

export const selectSettingsSelectedTinyTagGroupIds = (state) =>
  state.settings?.searches?.tinyTags?.selectedTinyTagGroupIds;

export const selectSettingsSelectedTinyTags = (state) =>
  state.settings?.searches?.tinyTags?.selectedTinyTags;

export const selectSettingsTinyTagsListOpened = (state) =>
  state.settings?.searches?.tinyTags?.tinyTagsListOpened;

export const selectSettingsTTQueryHasMore = (state) =>
  state.settings?.searches?.tinyTags?.tTQueryHasMore;

export const selectSettingsTTQueryPage = (state) =>
  state.settings?.searches?.tinyTags?.tTQueryPage;

export const selectSettingsFindSpeakersHasMore = (state) =>
  state.settings?.searches?.findSpeakers?.findSpeakersHasMore;

export const selectSettingsFindSpeakersQueryPage = (state) =>
  state.settings?.searches?.findSpeakers?.findSpeakersQueryPage;

export const selectSettingsFindSpeakersSearchSpeakerLanguages = (state) =>
  state.settings?.searches?.findSpeakers?.searchSpeakerLanguages;

export const selectSettingsFindSpeakersSearchSpeakerLanguagesNotListed = (
  state
) => state.settings?.searches?.findSpeakers?.searchSpeakerLanguagesNotListed;

export const selectSettingsFindSpeakersSearchLocationType = (state) =>
  state.settings?.searches?.findSpeakers?.searchLocationType;

export const selectSettingsFindSpeakersSearchCountries = (state) =>
  state.settings?.searches?.findSpeakers?.searchCountries;

export const selectSettingsFindSpeakersSearchCoordinates = (state) =>
  state.settings?.searches?.findSpeakers?.searchCoordinates;

export const selectSettingsFindSpeakersDistanceFilter = (state) =>
  state.settings?.searches?.findSpeakers?.distanceFilter;

export const selectSettingsFindSpeakersDistanceMeasurement = (state) =>
  state.settings?.searches?.findSpeakers?.distanceMeasurement;

export const selectSettingsFindSpeakersSearchBigTags = (state) =>
  state.settings?.searches?.findSpeakers?.searchBigTags;

export const selectSettingsFindSpeakersSearchTinyTags = (state) =>
  state.settings?.searches?.findSpeakers?.searchTinyTags;

//Organize
export const selectSettingsOrganizeView = (state) =>
  state.settings?.organize?.general?.organizeView;

export const selectSettingsOrganizeOverviewSortCategory = (state) =>
  state.settings?.organize?.overview?.organizeOverviewSortCategory;

export const selectSettingsOrganizeOverviewDirection = (state) =>
  state.settings?.organize?.overview?.organizeOverviewDirection;

export const selectSettingsOrganizeOverviewFormat = (state) =>
  state.settings?.organize?.overview?.organizeOverviewFormat;

export const selectSettingsOrganizeSelectedOrganizers = (state) =>
  state.settings?.organize?.overview?.selectedOrganizers;

export const selectSettingsOrganizeSelectAllOrganizersBtn = (state) =>
  state.settings?.organize?.overview?.selectAllOrganizersBtn;

export const selectSettingsOrganizeOverviewStandaloneGridEvents = (state) =>
  state.settings?.organize?.overview?.standaloneEvents;

export const selectSettingsOrganizeOverviewListOpened = (state) =>
  state.settings?.organize?.overview?.organizeOverviewListOpened;

export const selectSettingsOrganizeOverviewContent = (state) =>
  state.settings?.organize?.overview?.content;
