//hooks
//components
import InfoCard from './InfoCards/InfoCard';

//utils
import { nav_arrow } from '../../../assets/icons';

const AdminCardsRowSupport = ({
  cardsRowIds,
  setCardsRowIds,
  currentSearchIndex,
  setCurrentSearchIndex,
  adminTickets,
  adminSupportEventVerifications,
  adminSearchQueries,
}) => {
  //hooks
  //UI
  //variables
  const cardStyle = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${currentSearchIndex * 1024}px)`,
  };

  //variables

  //UI
  const handlePrevCard = () => {
    setCurrentSearchIndex((prevIndex) =>
      prevIndex === 0 ? cardsRowIds.length - 1 : prevIndex - 1
    );
  };

  const handleNextCard = () => {
    setCurrentSearchIndex((prevIndex) =>
      prevIndex === cardsRowIds.length - 1 ? 0 : prevIndex + 1
    );
  };

  function handleCloseInfoCard(removeId) {
    let newArr = [...cardsRowIds];
    const reducedArr = newArr.filter((cardRowId) => {
      return removeId !== cardRowId;
    });
    setCardsRowIds(reducedArr);
    if (currentSearchIndex > 0) {
      setCurrentSearchIndex(currentSearchIndex - 1);
    } else {
      setCurrentSearchIndex(0);
    }
  }

  return (
    <div className="flex-column">
      <div className="flex-column flex-center align-center mrg-b24">
        <p className="flex-center fs21 fwb">Cards ({cardsRowIds?.length})</p>
        <div className="ticket-table-dots mrg-t24">
          {cardsRowIds?.map((_, index) => {
            return (
              <div
                key={`search-dot-${index}`}
                className={`dot mrg-r6 ${
                  currentSearchIndex === index
                    ? 'bg-color-theme'
                    : 'bg-color-lightgray'
                }`}
              />
            );
          })}
        </div>
      </div>
      <div className="flex-row full-width">
        {cardsRowIds?.length > 0 ? (
          <>
            <button className="table-ticket-arrow" onClick={handlePrevCard}>
              <img src={nav_arrow} alt="arrow" className="rotate-180" />
            </button>
            <button className="table-ticket-arrow" onClick={handleNextCard}>
              <img src={nav_arrow} alt="arrow" />
            </button>
          </>
        ) : (
          <div className="cards-row-empty-divider" />
        )}
      </div>
      <div className="ticket-row" style={cardStyle}>
        {cardsRowIds?.length > 0 &&
          cardsRowIds?.map((id, index) => {
            const ticket =
              adminTickets?.find((ticket) => ticket._id === id) ||
              adminSearchQueries?.find(
                (ticket) => ticket._id === id && ticket?.meta?.ticketReference
              );

            if (ticket) {
              return (
                <InfoCard
                  key={`searchResultTicket-${ticket.meta.ticketReference}`}
                  infoCardType={'ticket'}
                  idData={ticket?._id}
                  metaData={ticket?.meta}
                  supportData={ticket?.support}
                  ticketData={ticket?.ticketData}
                  title={'Ticket'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  cardsRowIds={cardsRowIds}
                  setCardsRowIds={setCardsRowIds}
                />
              );
            }

            const eventVerification = adminSupportEventVerifications?.find(
              (evv) => evv._id === id
            );
            if (eventVerification) {
              return (
                <InfoCard
                  key={`searchResultEvent-${eventVerification?._id}`}
                  infoCardType={'eventVerification'}
                  infoCardData={eventVerification}
                  title={'Event Verification'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  idData={eventVerification?._id}
                  metaData={eventVerification?.event?.eventId?.meta}
                  eventGenericData={eventVerification?.event?.eventId?.generic}
                  verificationsData={eventVerification?.verifications}
                  verificationHistoryData={
                    eventVerification?.verificationHistory
                  }
                  verificationEventData={eventVerification?.event}
                  cardsRowIds={cardsRowIds}
                  setCardsRowIds={setCardsRowIds}
                />
              );
            }

            const account = adminSearchQueries?.find(
              (acct) => acct._id === id && acct?.meta?.accountReference
            );

            if (account) {
              return (
                <InfoCard
                  key={`searchResultEvent-${account?._id}`}
                  infoCardType={'account'}
                  infoCardData={account}
                  title={'Account'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  idData={account?._id}
                  metaData={account?.meta}
                  ticketData={account?.meta?.tickets}
                  accountPersonalData={account}
                  appSettingsData={account?.appSettings}
                  attendeeData={account?.attendee}
                  accountEventsData={account?.accountEvents}
                  tagsData={account?.attendee?.tags}
                  communityData={account?.community}
                />
              );
            }

            const event = adminSearchQueries?.find(
              (ev) => ev._id === id && ev?.meta?.eventReference
            );

            if (event) {
              return (
                <InfoCard
                  key={`searchResultEvent-${event.meta?.eventReference}`}
                  infoCardType={'event'}
                  title={'Event'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  idData={event?._id}
                  metaData={event?.meta}
                  accountPersonalData={event?.organizers?.organizerId[0]}
                  ticketData={event?.meta?.tickets}
                  tagsData={event?.tags}
                  eventGenericData={event?.generic}
                  eventImagesData={event.generic}
                  eventDateTimeData={event?.dateTime}
                  eventHostingData={event?.hosting}
                  eventParticipantsData={event?.participants}
                  eventRegistrationsData={event?.registrationData}
                  eventInteractionsData={event?.eventInteractions}
                  eventScheduleData={event?.schedule}
                />
              );
            }

            const adminAccount = adminSearchQueries?.find(
              (adminAcct) =>
                adminAcct._id === id && adminAcct?.meta?.adminReference
            );

            if (adminAccount) {
              return (
                <InfoCard
                  key={`searchResultEvent-${adminAccount.meta?.adminReference}`}
                  infoCardType={'admin'}
                  title={'Admin Account'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  idData={adminAccount?._id}
                  metaData={adminAccount?.meta}
                  accountPersonalData={adminAccount}
                  appSettingsData={adminAccount?.appSettings}
                  companyData={adminAccount?.company}
                  ticketData={adminAccount?.company?.tickets}
                />
              );
            }

            const technicalLog = adminSearchQueries?.find(
              (technicalLog) =>
                technicalLog._id === id &&
                technicalLog?.meta?.technicalLogReference
            );

            if (technicalLog) {
              return (
                <InfoCard
                  key={`searchResultTicket-${technicalLog.meta.technicalLogReference}`}
                  infoCardType={'technicalLog'}
                  idData={technicalLog?._id}
                  metaData={technicalLog?.meta}
                  errorData={technicalLog.error}
                  requestData={technicalLog.request}
                  traceData={technicalLog.trace}
                  title={'Technical Log'}
                  handleCloseInfoCard={handleCloseInfoCard}
                  cardsRowIds={cardsRowIds}
                  setCardsRowIds={setCardsRowIds}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default AdminCardsRowSupport;
