import { useState, useEffect } from 'react';

//hooks

//components
import CommunityAccounts from './CommunityAccounts';
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';

const CommunityContent = ({
  accountCommunityPopulated,
  handleRefreshAccountCommunityLists,
  getAccountCommunityListsIsLoading,
}) => {
  //hooks
  const [communityAccountIsLoading, setCommunityAccountIsLoading] =
    useState(false);

  //state
  useEffect(() => {
    if (getAccountCommunityListsIsLoading) {
      setCommunityAccountIsLoading(true);
    }
  }, [getAccountCommunityListsIsLoading]);

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      {communityAccountIsLoading ? (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          loadCheck1={true}
          setLoadComplete={() => setCommunityAccountIsLoading(false)}
        />
      ) : (
        <>
          <CommunityAccounts
            accountCommunityPopulated={accountCommunityPopulated}
            handleRefreshAccountCommunityLists={
              handleRefreshAccountCommunityLists
            }
          />
        </>
      )}

      <div className="h48" />
    </div>
  );
};

export default CommunityContent;
