//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
import useApiStatus from '../../context/useApiStatus';

//Components

//Utility
import { img_location } from '../../assets/icons';

const AutocompletePlaces = ({
  setAutocompleteLocation,
  setAutocompleteCoordinates,
  clearAddressFields,
}) => {
  //Hooks
  const { handleError } = useApiStatus();
  const { dismissKeypad, windowSettingsSize } = useSettings();
  const { AutocompletePlaces: autocompletePlacesTranslation } =
    useLanguageComponents();

  //Component variables
  let autocomplete;
  let roomField = document.getElementById('room');

  //UI functions
  function setFocus() {
    roomField.focus();
  }

  //component functions
  function initAutocomplete() {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        componentRestrictions: { country: ['ca', 'us'] },
        fields: ['place_id', 'name', 'formatted_address', 'address_components'],
      }
    );
    autocomplete.addListener('place_changed', fillInAddress);
  }

  function fillInAddress() {
    if (clearAddressFields) {
      clearAddressFields();
    }
    const geocoder = new window.google.maps.Geocoder();
    const place = autocomplete.getPlace();
    if (!place?.place_id) {
      return;
    }

    let address = place.formatted_address.split(',');
    //remove white space in array
    for (let i = 0; i < address.length; i++) address[i] = address[i].trim();

    let placeId = place.place_id;
    let streetAddress = '';
    let country = '';

    //check to see whether place is in Canada or the US
    address.forEach((element, index) =>
      element.match('Canada')
        ? (country = 'canada') && address.splice(index, 1)
        : element.match('USA')
        ? (country = 'unitedStates') && address.splice(index, 1)
        : element.match('United States')
        ? (country = 'unitedStates') && address.splice(index, 1)
        : ''
    );

    //check which element starts with number --> becomes streetAddress
    function startsWithNumber(str) {
      return /^\d/.test(str);
    }

    address.forEach((element, index) =>
      startsWithNumber(element)
        ? (streetAddress = element) && address.splice(index, 1)
        : ''
    );

    let city = address.length === 3 ? address[1] : address[0];
    let postalCode = address.length === 3 ? address[2] : address[1];

    //eliminate building name if its a duplicate of streetaddress
    let placeName = place.name;

    if (placeName === streetAddress) {
      placeName = '';
    }

    setAutocompleteLocation({
      room: '',
      locationName: placeName,
      streetAddress: streetAddress,
      city: city,
      postalCode: postalCode,
      country: country,
      placeId: placeId,
    });

    place.name = document.getElementById('locationName').value;
    streetAddress = document.getElementById('streetAddress').value;
    city = document.getElementById('city').value;

    geocoder.geocode({ placeId: place.place_id }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        let latt = results[0].geometry.location.lat();
        let long = results[0].geometry.location.lng();

        setAutocompleteCoordinates({
          lat: latt,
          lng: long,
        });
      } else {
        handleError(
          {
            message: 'geocodeUnsuccessful',
          },
          'AutocompletePlaces.js/fillinAddress'
        );
      }
    });
    setFocus();
  }

  function removePAC() {
    let pac = document.getElementsByClassName('pac-container');
    if (pac.length > 0) {
      pac[0].remove();
    }
  }

  return (
    <div className="autocomplete__wrapper">
      <div>
        <input
          id="autocomplete"
          className="autocomplete-input"
          type="text"
          onKeyDown={(e) => {
            if (e.key === 'Enter') dismissKeypad(e);
          }}
          onFocus={initAutocomplete}
          onBlur={removePAC}
          placeholder={
            windowSettingsSize !== 'phone' &&
            windowSettingsSize !== 'phone--narrow'
              ? autocompletePlacesTranslation.autocompleteLocationLong
              : autocompletePlacesTranslation.autocompleteLocationShort
          }
          aria-label={autocompletePlacesTranslation.genericInputInstructions}
          enterKeyHint="search"
        />
        <img
          className="input-label location"
          src={img_location}
          alt="location"
        />
      </div>
    </div>
  );
};

export default AutocompletePlaces;
