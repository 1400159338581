import { useEffect, useState } from 'react';
import {
  useLazyMyOrganizedEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
  useLazyMyOrganizedArchivedEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
} from './organizeApiSlice';

//Hooks
import useSettings from '../../../context/useSettings';
import useApiStatus from '../../../context/useApiStatus';
import useReCaptcha from '../../../hooks/useReCaptcha';
import {
  useCreateDraftEventMutation,
  useLazyGetMyDraftQuery,
  useLazyMyDraftEventsQuery,
  useRemoveDraftEventMutation,
  useUpdateDraftEventMutation,
} from './draftEventApiSlice';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { useOrganizeHandlers } from './useOrganizeHandlers';
import { useOrganizeEventsSocket } from '../../../context/sockets/OrganizeEventsSocketProvider';
import { useCreateEventReduxHandlers } from './create/useCreateEventHandlers';
import { useEventParticipantsHandlers } from './eventParticipants/useEventParticipantsHandlers';

//Components
import SettingsOrganize from '../settings/Organize/SettingsOrganize';
import CreateEvent from './create/CreateEvent';
import OrganizeOverview from './OrganizeOverview';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import EventPage from '../events/EventPage/EventPage';
import EditEvent from './edit/EditEvent';
import FindSpeakersContent from './findSpeakers/FindSpeakersContent';
import ScrollTo from '../../../utils/UI/ScrollTo';

//Utility
import { checkImagePath } from '../../../utils/images/checkImagePath';
import {
  useEventRemovalNoticesUpdateForInviteContactParticipantsMutation,
  useUpdateInviteContactParticipantEventsFromEventRemovalMutation,
} from './eventParticipants/eventParticipantsApiSlice';

const Organize = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleError, handleSuccess } = useApiStatus();
  const { accountId, accountAffiliations } = useAccountReduxHandlers();
  const {
    settingsOrganizeView: organizeView,
    settingsOrganizeSelectedOrganizers: selectedOrganizers,
    handleSettingsSetSelectedOrganizers,
    handleSettingsSetSelectAllOrganizersBtn,
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
  } = useSettingsHandlers();
  const {
    handleRemoveOrganizerEvent,
    handleOrganizerEventFormData,
    handleRemoveEventImagesForAPIFailure,
  } = useOrganizeHandlers();

  const {
    handleSendEventParticipantInviteDispatches,
    handlePublishedEventParticipationInvites,
    handleRemoveParticipationInvite,
    eventDataHasAcceptedInviteContactParticipants,
    eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts,
    //translations
    handleSendEventParticipantTranslationsDispatches,
  } = useEventParticipantsHandlers();

  const { handleCreateEventReset, handleCreateEventSetId, createEventId } =
    useCreateEventReduxHandlers();

  //Socket
  const { connectOrganizeEventsSocket, closeOrganizeEventsSocket } =
    useOrganizeEventsSocket();

  //API Queries
  const [getMyOrgEvents, { isUninitialized: myOrgEventsisUninitialized }] =
    useLazyMyOrganizedEventsQuery(accountId);

  const [
    getMyOrgScheduledEvents,
    { isUninitialized: myOrgScheduledEventsisUninitialized },
  ] = useLazyMyOrganizedScheduledEventsQuery(accountId);
  const [
    getMyOrgArchivedEvents,
    { isUninitialized: myOrgArchivedEventsisUninitialized },
  ] = useLazyMyOrganizedArchivedEventsQuery(accountId);

  const [getDraftEvents, { isUninitialized: myDraftEventsIsUninitialized }] =
    useLazyMyDraftEventsQuery(accountId);

  //Api Event
  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  //API Draft
  const [updateDraft] = useUpdateDraftEventMutation();
  const [createDraftEvent] = useCreateDraftEventMutation();
  const [getMyDraft] = useLazyGetMyDraftQuery();
  const [removeDraft] = useRemoveDraftEventMutation();
  const [updateInviteContactParticipantEventsFromEventRemoval] =
    useUpdateInviteContactParticipantEventsFromEventRemovalMutation();
  const [eventRemovalNoticesUpdateForInviteContactParticipants] =
    useEventRemovalNoticesUpdateForInviteContactParticipantsMutation();

  //Component state
  const [editEvent, setEditEvent] = useState();
  const [eventPreview, setEventPreview] = useState(false);
  const [
    removedParticipantsWithDispatchedInvites,
    setRemovedParticipantsWithDispatchedInvites,
  ] = useState([]);

  //ui
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [organizeIsLoading, setOrganizeIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  //Image state
  const [resetCroppedImages, setResetCroppedImages] = useState(false);
  const [eventImageDisplayCropped, setEventImageDisplayCropped] =
    useState(null);
  const [participantImagesCropped, setParticipantImagesCropped] = useState([]);
  const [eventAdditionalImagesCropped, setEventAdditionalImagesCropped] =
    useState([]);

  //Initialize functions
  useEffect(() => {
    setNavbar('organize');
    setEventPageLoadComplete(false);
    /*eslint-disable-next-line*/
  }, []); //initialize state

  useEffect(() => {
    connectOrganizeEventsSocket();

    return () => {
      closeOrganizeEventsSocket();
    };
  }, []); //web socket for organizedEvents

  useEffect(() => {
    if (myOrgEventsisUninitialized && accountId) {
      getMyOrgEvents(accountId);
    }

    if (myOrgScheduledEventsisUninitialized && accountId) {
      getMyOrgScheduledEvents(accountId);
    }

    if (myOrgArchivedEventsisUninitialized && accountId) {
      getMyOrgArchivedEvents(accountId);
    }
    if (myDraftEventsIsUninitialized && accountId) {
      getDraftEvents(accountId);
    }

    /*eslint-disable-next-line*/
  }, [
    myDraftEventsIsUninitialized,
    myOrgEventsisUninitialized,
    myOrgScheduledEventsisUninitialized,
    myOrgArchivedEventsisUninitialized,
    accountId,
  ]); //get my events

  useEffect(() => {
    if (selectedOrganizers?.length === 0) {
      let newSelections = [];
      accountAffiliations?.map((aA) => newSelections.push(aA.affiliation._id));
      newSelections.push(accountId);
      handleSettingsSetSelectedOrganizers(newSelections);
      handleSettingsSetSelectAllOrganizersBtn(true);
    }
  }, []); //selects accountId and affiliate organizers for sorting if none are selected upon load

  useEffect(() => {
    if (resetCroppedImages) {
      setResetCroppedImages(false);
      setEventImageDisplayCropped(null);
      setParticipantImagesCropped([]);
      setEventAdditionalImagesCropped([]);
    }
  }, [resetCroppedImages]); //reset cropped images state

  //API functions
  async function handleSubmitEvent({ eventData, handleType, exit }) {
    const recaptchaVerified = await handleReCaptchaVerify(
      'SUBMIT_EVENT',
      false
    );
    if (recaptchaVerified !== true) return false;

    let res;
    const draftId = createEventId;

    setSubmitIsLoading(true);
    //draft
    const formData = await handleOrganizerEventFormData({
      handleType,
      eventData,
      participantImagesCropped,
      eventImageDisplayCropped,
      eventAdditionalImagesCropped,
    });

    try {
      if (handleType === 'editScheduled' || handleType === 'editPublished') {
        if (removedParticipantsWithDispatchedInvites?.length > 0) {
          await handleRemoveParticipationInvite({
            accountId,
            updateEventData: {
              eventType: 'event',
              eventId: eventData?.meta?._id,
            },
            removedParticipantsWithDispatchedInvites,
          });
          setRemovedParticipantsWithDispatchedInvites([]);
        }

        res = await updateEvent(formData);

        if (!res.error) {
          const eventData = res?.data?.data?.updatedEvent;
          const eventType =
            handleType === 'editPublished' ? 'event' : 'scheduledEvent';

          await handleSendEventParticipantInviteDispatches({
            eventData,
            eventType,
          });

          if (eventData?.translations?.translationsEnabled) {
            await handleSendEventParticipantTranslationsDispatches({
              eventData,
              eventType,
            });
          }
        }

        setEditEvent();
      } else if (
        handleType === 'publish' ||
        handleType === 'publishScheduled'
      ) {
        res = await createEvent({
          body: formData,
          handleRemoveEventImagesForAPIFailure,
        });

        if (!res.error) {
          //add event to invite participants and notices
          const eventData = res?.data?.data?.newEvent;
          const eventType =
            handleType === 'publish' ? 'event' : 'scheduledEvent';

          await handlePublishedEventParticipationInvites({
            eventId: eventData._id,
            draftId,
            eventParticipants: eventData?.participants,
            handleType,
          }); //handle invites from draft

          await handleSendEventParticipantInviteDispatches({
            eventData,
            eventType,
          }); //sends undispatched invites + updates event;

          if (eventData?.translations?.translationsEnabled) {
            await handleSendEventParticipantTranslationsDispatches({
              eventData,
              eventType,
            });
          }

          //remove associated event draft
          if (draftId) {
            await removeDraft({ accountId, draftId });
          }

          if (handleType === 'publish' || handleType === 'publishScheduled') {
            handleSuccess({
              message: 'eventCreated',
              id: Date.now(),
              origin: 'CreateEvent.js/handleSubmitEvent',
            });
          } else if (
            handleType === 'editPublished' ||
            handleType === 'editScheduled'
          ) {
            handleSuccess({
              message: 'eventUpdated',
              id: Date.now(),
              origin: 'CreateEvent.js/handleSubmitEvent',
            });
          }

          //need to get new dispatches data.
          await getMyOrgEvents(accountId);

          if (exit) {
            //need to check for image path
            if (
              res.data.data.newEvent?.generic?.eventImageDisplay?.imagePath &&
              res.data.data.newEvent?.generic?.eventImageDisplay?.imagePath !==
                'event_img_default.png'
            ) {
              await checkImagePath(
                res.data.data.newEvent.generic.eventImageDisplay?.imagePath,
                'event'
              );
            }
            handleCreateEventReset();
            eventData?.schedule?.enabled
              ? handleSettingsSetOrganizeOverviewStandaloneGridEvents(
                  'scheduled'
                )
              : handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');

            handleSettingsSetOrganizeOverviewContent('standalone');
            handleSettingsSetOrganizeView('overview');
          }
        }
      }
      setSubmitIsLoading(false);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('error', error);
      }

      setSubmitIsLoading(false);
      handleError(
        {
          message: 'tryAgain',
          statusCode: 500,
        },
        'CreateEvent/handleSubmitEvent'
      );
    }
  }

  async function handleSubmitEventDraft({ eventData, handleType, exit }) {
    try {
      const recaptchaVerified = await handleReCaptchaVerify(
        'SUBMIT_DRAFT',
        false
      );
      if (recaptchaVerified !== true) return false;

      let res;
      let newDraft;

      setSubmitIsLoading(true);
      //draft
      const formData = await handleOrganizerEventFormData({
        handleType,
        eventData,
        participantImagesCropped,
        eventImageDisplayCropped,
        eventAdditionalImagesCropped,
      });

      if (handleType === 'createDraft') {
        res = await createDraftEvent({
          body: formData,
          handleRemoveEventImagesForAPIFailure,
        });
        //set createEventId for 'save' action on a new doc
        handleCreateEventSetId(res?.data?.data?._id);
      } else if (handleType === 'updateDraft') {
        if (removedParticipantsWithDispatchedInvites?.length > 0) {
          await handleRemoveParticipationInvite({
            accountId,
            updateEventData: {
              eventType: 'draft',
              eventId: eventData?.meta?._id,
            },
            removedParticipantsWithDispatchedInvites,
          });
          setRemovedParticipantsWithDispatchedInvites([]);
        }

        res = await updateDraft(formData);
      }

      if (res?.data?.data) {
        newDraft = res?.data?.data;

        await handleSendEventParticipantInviteDispatches({
          eventData: newDraft,
          eventType: 'draft',
        });

        if (newDraft?.translations?.translationsEnabled) {
          await handleSendEventParticipantTranslationsDispatches({
            eventData: newDraft,
            eventType: 'draft',
          });
        }
      }

      if (!res.error) {
        handleSuccess({
          message: 'draftSaved',
          id: Date.now(),
          origin: 'CreateEvent.js/handleSubmitEvent',
        });

        if (exit) {
          handleCreateEventReset();
          setEventPageLoadComplete(false);

          //prep settings slice for navigation
          handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
          handleSettingsSetOrganizeOverviewContent('standalone');
          handleSettingsSetOrganizeView('overview');
        } else {
          //need to get event again with updated data then set create slice.
          await getMyDraft({
            accountId,
            draftId: newDraft?._id,
          });
        }
      }
      setSubmitIsLoading(false);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('error', error);
      }
      setSubmitIsLoading(false);
      handleError(
        {
          message: 'tryAgain',
          statusCode: 500,
        },
        'CreateEvent/handleSubmitEvent'
      );
    }
  }

  async function handleRemoveEvent({
    eventId,
    organizerId,
    eventType,
    participants,
  }) {
    //eventTypes based on settings redux property: 'active', 'scheduled', 'archived',
    if (!submitIsLoading) {
      try {
        setSubmitIsLoading(true);

        const hasAcceptedInviteContactParticipants =
          eventDataHasAcceptedInviteContactParticipants(participants);

        if (hasAcceptedInviteContactParticipants) {
          // remove event from accountEvent.participantEvents
          await updateInviteContactParticipantEventsFromEventRemoval({
            accountId,
            eventId,
            participants,
          });
        }

        const hasAcceptedOrPendingInviteContactParticipants =
          eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts(
            participants
          );

        if (hasAcceptedOrPendingInviteContactParticipants) {
          //remove pending notices for participants + notify accepted participants that event was removed
          await eventRemovalNoticesUpdateForInviteContactParticipants({
            accountId,
            eventId,
          });
        }

        await handleRemoveOrganizerEvent({ eventId, organizerId, eventType });
        setEditEvent();
        setSubmitIsLoading(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('handleRemoveEvent error', error);
        }
      }
    }
  }

  function handleReturnFromPreview(scrollToLabel) {
    setEventPreview();
    const animationTimer = setTimeout(() => {
      ScrollTo(scrollToLabel, 'instant', false, scrollToLabel);
    }, 100);

    return () => clearTimeout(animationTimer);
  }

  return (
    <>
      {!eventPageLoadComplete ? (
        <LoadingScreenContent
          numberOfLoadChecks={3}
          setLoadComplete={() => {
            setEventPageLoadComplete(true);
          }}
          loadCheck1={accountId ? true : false}
          loadCheck2={organizeView ? true : false}
          loadCheck3={!eventPageLoadComplete ? true : false}
          customWrapper={'min-full-height'}
        />
      ) : (
        <>
          {eventPreview && (
            <EventPage
              handleCustomReturn={handleReturnFromPreview}
              organizerViewOn={true}
              previewEventData={eventPreview}
              //cropped images
              participantImagesCropped={participantImagesCropped}
              eventDisplayImageCropped={eventImageDisplayCropped}
              eventAdditionalImagesCropped={eventAdditionalImagesCropped}
            />
          )}

          <div className={`organize ${eventPreview ? 'remove' : ''}`}>
            {!eventPreview && !editEvent && <SettingsOrganize />}

            {organizeView === 'overview' && !editEvent && (
              <OrganizeOverview
                setEditEvent={setEditEvent}
                handleRemoveEvent={handleRemoveEvent}
                setEventPreview={setEventPreview}
              />
            )}

            {organizeView === 'create' && !editEvent && (
              <CreateEvent
                eventPreview={eventPreview}
                setEventPreview={setEventPreview}
                handleSubmitEvent={handleSubmitEvent}
                handleSubmitEventDraft={handleSubmitEventDraft}
                removedParticipantsWithDispatchedInvites={
                  removedParticipantsWithDispatchedInvites
                }
                setRemovedParticipantsWithDispatchedInvites={
                  setRemovedParticipantsWithDispatchedInvites
                }
                //loading state
                organizeIsLoading={organizeIsLoading}
                setOrganizeIsLoading={setOrganizeIsLoading}
                submitIsLoading={submitIsLoading}
                //cropped imgs
                setResetCroppedImages={setResetCroppedImages}
                participantImagesCropped={participantImagesCropped}
                setParticipantImagesCropped={setParticipantImagesCropped}
                eventImageDisplayCropped={eventImageDisplayCropped}
                setEventImageDisplayCropped={setEventImageDisplayCropped}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                setEventAdditionalImagesCropped={
                  setEventAdditionalImagesCropped
                }
              />
            )}

            {editEvent && (
              <EditEvent
                eventData={editEvent}
                setEditEvent={setEditEvent}
                handleRemoveEvent={handleRemoveEvent}
                setEventPreview={setEventPreview}
                eventPreview={eventPreview}
                handleSubmitEvent={handleSubmitEvent}
                removedParticipantsWithDispatchedInvites={
                  removedParticipantsWithDispatchedInvites
                }
                setRemovedParticipantsWithDispatchedInvites={
                  setRemovedParticipantsWithDispatchedInvites
                }
                //loading state
                organizeIsLoading={organizeIsLoading}
                setOrganizeIsLoading={setOrganizeIsLoading}
                submitIsLoading={submitIsLoading}
                //cropped imgs
                participantImagesCropped={participantImagesCropped}
                setParticipantImagesCropped={setParticipantImagesCropped}
                eventImageDisplayCropped={eventImageDisplayCropped}
                setEventImageDisplayCropped={setEventImageDisplayCropped}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                setEventAdditionalImagesCropped={
                  setEventAdditionalImagesCropped
                }
              />
            )}

            {organizeView === 'speakers' && <FindSpeakersContent />}
          </div>
        </>
      )}
    </>
  );
};

export default Organize;
