import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import PhoneInput from '../../../../../components/TextArea/PhoneInput';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//Utility

const Phone = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountPhoneNumber: loadPhoneNumber,
    accountCountryCode: loadCountryCode,
    accountPersonal,
  } = useAccountReduxHandlers();

  //Component state
  const [phoneNumber, setPhoneNumber] = useState(loadPhoneNumber);
  const [countryCode, setCountryCode] = useState(loadCountryCode);
  const [phoneDataValid, setPhoneDataValid] = useState(false);
  const [phoneNumberSaveValid, setPhoneNumberSaveValid] = useState(false);

  useEffect(() => {
    if (!phoneDataValid) {
      return setPhoneNumberSaveValid(false);
    } else {
      if (
        (phoneNumber !== loadPhoneNumber || countryCode !== loadCountryCode) &&
        phoneDataValid
      ) {
        setPhoneNumberSaveValid(true);
      } else {
        setPhoneNumberSaveValid(false);
      }
    }
  }, [
    phoneNumber,
    countryCode,
    phoneDataValid,
    loadCountryCode,
    loadPhoneNumber,
  ]); //check save valid

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="phoneLabel"
      >
        {Account.account.phone}
      </label>

      <div className="h24" />
      <PhoneInput
        id={'accountPhoneInput'}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setPhoneDataValid={setPhoneDataValid}
      />
      <div className="h24" />
      <SaveButton
        saveValid={phoneNumberSaveValid}
        handler={() =>
          saveAccountChange(
            {
              personal: {
                ...accountPersonal,
                countryCode,
                phoneNumber,
              },
            },
            'phone'
          )
        }
        saveAriaLanguage={Account.account.phone}
        isLoading={loadingSaveBtnUI === 'phone'}
      />
      <div className="h36" />
    </div>
  );
};

export default Phone;
