import { useState, useEffect, useRef } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Utility
import { visible_off, visible } from '../../assets/icons/index';
import handleInputClick from './handleInputClick';

const PasswordConfirmInput = ({
  asterisk,
  customWrapper,
  customWidth,
  handleInput,
  id,
  newPassword,
  passwordConfirm,
  passwordsMatch,
  setPasswordsMatch,
  reset,
  loadValue,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();
  const { PasswordInputs } = useLanguageComponents();

  //Component state
  const [showPassword, setShowPassword] = useState(false);
  const [checkRan, setCheckRan] = useState(false);

  //UI state
  const [activeWarning, setActiveWarning] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [tapHighlightShowPassword, setTapHighlightShowPassword] =
    useState(false);

  //Component varibales
  const passwordInput = useRef();

  //initialize
  useEffect(() => {
    setShowPassword(false);
    setCheckRan(false);
    setActiveWarning(false);
    setFocusHighlight(false);
  }, [reset]);

  useEffect(() => {
    if (loadValue?.length > 0) {
      validation(loadValue);
    }
  }, [loadValue]);

  //funct
  function validation(value) {
    if (newPassword === value) {
      setPasswordsMatch(true);
    } else {
      setActiveWarning(true);
      setPasswordsMatch(false);
    }

    setCheckRan(true);
    handleInput(value);
  }

  useEffect(() => {
    if (checkRan && passwordConfirm?.length > 0) {
      validation(passwordConfirm);
    }
  }, [newPassword]);

  return (
    <div className="input-error__wrapper">
      <form
        className={`text-input__wrapper ${
          customWrapper ? customWrapper : ''
        } }`}
        style={customWidth ? { width: `${customWidth}` } : null}
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        onMouseEnter={() => setFocusHighlight(true)}
        onMouseLeave={() => setFocusHighlight(false)}
      >
        <label
          id={`${id}-label`}
          className="text-input-label no-select"
          onClick={() => handleInputClick(passwordInput.current)}
        >
          <p>
            {PasswordInputs.confirmNewPassword}&nbsp;
            {asterisk && <span className="highlight">*</span>}
          </p>
        </label>
        <input
          id={id}
          name={id}
          ref={passwordInput}
          defaultValue={loadValue}
          autoComplete="new-password"
          maxLength="255"
          enterKeyHint="done"
          type={!showPassword ? 'password' : 'text'}
          className={`text-input text-input-password ${
            focusHighlight
              ? 'highlight-ob-theme--thin--active'
              : 'highlight-ob-theme--thin'
          } ${
            checkRan &&
            !passwordsMatch &&
            passwordInput.current?.value?.length > 0
              ? 'text-input-warning'
              : ''
          } `}
          aria-label={PasswordInputs.confirmNewPassword}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setFocusHighlight(false);
              dismissKeypad(e);
            }
          }}
          onBlur={() => setFocusHighlight(false)}
          onFocus={() => setFocusHighlight(true)}
          onChange={(e) => validation(e.target.value)}
        />
        <button
          className={`highlight-i-lgt access-ob access-o6 ${
            showPassword ? 'show-password-btn' : 'show-password-btn visible-off'
          } ${tapHighlightShowPassword ? 'highlight-i-theme--tap' : ''}`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightShowPassword(true),
                () => setTapHighlightShowPassword(false),
              ],
              [() => setShowPassword(!showPassword)]
            )
          }
          tabIndex="0"
          type="button"
          id={`${id}-show-password`}
        >
          {showPassword ? (
            <img src={visible} alt={PasswordInputs.showPasswordOn} />
          ) : (
            <img src={visible_off} alt={PasswordInputs.showPasswordOff} />
          )}
        </button>
      </form>
      <p
        className={`input-error access-ob ${
          !passwordsMatch && activeWarning && checkRan
            ? 'input-error-animation'
            : activeWarning && checkRan
            ? 'input-error-hide'
            : ''
        }`}
        tabIndex={!passwordsMatch ? '0' : '-1'}
      >
        {PasswordInputs.passwordsDoNotMatch}
      </p>
    </div>
  );
};

export default PasswordConfirmInput;
