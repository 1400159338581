import { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useGenericModal from '../../../../context/useGenericModal';
import useSettings from '../../../../context/useSettings';
//Components

//Utility
import GetFlag from '../../../../utils/images/GetFlag';
import { cancel, trash } from '../../../../assets/icons';

const CountryCodesModal = ({
  countryCode,
  setCountryCode,
  CountryCodeOpts,
}) => {
  //Hooks
  const { Generic, PhoneNumberInput } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap, width } = useSettings();

  //Component state
  const [filteredList, setFilteredList] = useState();
  const [selectedObj, setSelectedObj] = useState();

  //UI state
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightRemoveBtn, setTapHighlightRemoveBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();
  const [hoverHighlightTrash, setHoverHighlightTrash] = useState(false);

  //Component variables
  let tagsList;

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('countryCodeOpenListBtn');
  }, []); //set id for focus when modal closes

  //Component functions
  useEffect(() => {
    if (countryCode in CountryCodeOpts) {
      const value = CountryCodeOpts[countryCode];
      setSelectedObj(value);
    }
  }, [countryCode]);

  useEffect(() => {
    if (countryCode) {
      const filteredList = Object.keys(CountryCodeOpts).reduce((acc, key) => {
        if (!countryCode.includes(key)) {
          acc[key] = CountryCodeOpts[key];
        }
        return acc;
      }, {});
      setFilteredList(filteredList);
    } else {
      setFilteredList(CountryCodeOpts);
    }
  }, [countryCode, CountryCodeOpts]); //removes selected tags

  useEffect(() => {
    if (filteredList) {
      document.getElementById(`countryCodesLabel`).focus();
    }
  }, [filteredList]);

  //JSX conditional
  if (filteredList) {
    let lastItemIndex = Object.entries(filteredList).length - 1;

    tagsList = (
      <>
        {Object.entries(filteredList).map(([key, value], index) => {
          const isLastItem = index === lastItemIndex;
          return (
            <li
              key={`${key}`}
              style={isLastItem ? { marginBottom: '160px' } : null}
            >
              <button
                className={`item fs16 fwsb access-ob access-o6 ${
                  tapHighlightSelectedKey === key ? 'item-selected' : ''
                } `}
                key={`list-item-${index}`}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightSelectedKey(key),
                      () => setTapHighlightSelectedKey(),
                    ],
                    [() => setCountryCode(key), () => handleCloseModal()]
                  )
                }
                type="button"
                tabIndex="0"
                aria-label={value.country}
                id={`list-item-${index}`}
              >
                {GetFlag(key)} &nbsp;&nbsp;{value.country}&nbsp;({value.code})
              </button>
            </li>
          );
        })}
      </>
    );
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="countryCodesModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id="countryCodesLabel"
          >
            {PhoneNumberInput.countryCodes}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob access-o6  ${
            tapHighlightCloseBtn ? 'highlight-i-lgr--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBtn(true),
                () => setTapHighlightCloseBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={Generic.close} className="svg" />
        </button>
      </div>
      <ul
        className="modal-basic__container list-style-none"
        id="countryCodesList"
      >
        {tagsList}
      </ul>
      <div className="modal-basic__footer">
        <button
          type="button"
          className="highlight-t-theme highlight-i-red min-full-width access-ob"
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightRemoveBtn(true),
                () => setTapHighlightRemoveBtn(false),
              ],
              [() => setCountryCode(), () => handleCloseModal()]
            )
          }
        >
          {countryCode ? (
            <div
              className="min-full-width flex-row flex-center no-select"
              onMouseEnter={() => setHoverHighlightTrash(true)}
              onMouseLeave={() => setHoverHighlightTrash(false)}
            >
              <div className="flex-row align-center item fwsb fs16 flex-center">
                {GetFlag(countryCode)}&nbsp;&nbsp;
                <p className="color-black">
                  {selectedObj?.country}&nbsp;(
                  {selectedObj?.code})
                </p>
                <img
                  src={trash}
                  alt={Generic.remove}
                  className={`trashbin mrg-l12 filter-gray highlight-i-hover--red ${
                    tapHighlightRemoveBtn ? 'filter-red' : ''
                  } ${hoverHighlightTrash && width > 480 ? 'filter-red' : ''}`}
                />
              </div>
            </div>
          ) : (
            <p
              className={`fs18 fwsb highlight-it-theme access-ob access-o6  ${
                tapHighlightRemoveBtn ? 'highlight-it-theme--tap' : ''
              }`}
            >
              {Generic.close}
            </p>
          )}
        </button>
      </div>
    </div>
  );
};

export default CountryCodesModal;
