//hooks
import useSettings from '../../../../../context/useSettings';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import ArchivedEvents from './sections/ArchivedEvents';
import AttendeeRoleToggle from './sections/AttendeeRoleToggle';
import BigTags from './sections/BigTags';
import EventSearchInPerson from './sections/EventSearchInPerson';
import EventSearchOnline from './sections/EventSearchOnline';
import IgnoredEvents from './sections/IgnoredEvents';
import CommunityEventSearch from './sections/CommunityEventSearch';
import SearchLanguages from './sections/SearchLanguages';
import TinyTags from './sections/TinyTags';

//utils
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';

const AttendeeManager = ({
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
  accountCommunityPopulated,
  handleRefreshAccountCommunityLists,
}) => {
  //hooks
  const { width } = useSettings();
  const { Account } = useLanguageAccount();

  return (
    <>
      <AttendeeRoleToggle />
      <div className="h36" />

      <BigTags
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <TinyTags
        Account={Account}
        saveAccountChange={saveAccountChange}
        updateAccountIsSuccess={updateAccountIsSuccess}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <SearchLanguages
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <EventSearchInPerson
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <EventSearchOnline
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <div className="h36" />

      <CommunityEventSearch
        Account={Account}
        accountCommunityPopulated={accountCommunityPopulated}
        handleRefreshAccountCommunityLists={handleRefreshAccountCommunityLists}
      />
      <div className="h36" />

      <IgnoredEvents
        Account={Account}
        saveAccountChange={saveAccountChange}
        width={width}
      />
      <div className="h36" />

      <ArchivedEvents Account={Account} width={width} />

      <div className="h36" />
      <ScrollToTop focusId={'attendeeActivationLabel'} />
    </>
  );
};

export default AttendeeManager;
