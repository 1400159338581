import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useGenericModal from '../../context/useGenericModal';
import useSettings from '../../context/useSettings';

//components
import ItemButton from '../ItemButton/ItemButton';
import ListModal from '../Modal/ModalComponents/ListModals/ListModal';

//utils
import { cancel } from '../../assets/icons';

const SelectionsModal = ({
  addButtonText,
  customWrapper,
  DataOpts,
  DataOptsSubTextTranslations,
  handleAdd,
  iconModal,
  iconModalClass,
  iconOff,
  id,
  limit,
  limitIcon,
  limitIconAlt,
  limitIconClass,
  limitText,
  modalTitle,
  selectionState,
  setSelectionState,
  subTextOn,
  itemButtonCustomWrapper,
}) => {
  //hooks
  const { SelectionsModal } = useLanguageComponents();
  const {
    setGenericModal,
    genericModal,
    genericModalSelectionState,
    setGenericModalSelectionState,
    setGenericModalSelectionStateId,
    genericModalSelectionStateId,
  } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightTag, setTapHighlightTag] = useState();

  useEffect(() => {
    if (
      !genericModal &&
      genericModalSelectionState &&
      genericModalSelectionStateId === id
    ) {
      handleAddOptsModal(genericModalSelectionState);
      setGenericModalSelectionState();
      setGenericModalSelectionStateId();
    }
  }, [genericModal, genericModalSelectionState]);

  function handleSeeFullList() {
    setGenericModal(
      <ListModal
        handleAddOptsModal={handleAddOptsModal}
        iconModal={iconModal}
        iconModalClass={iconModalClass}
        id={id}
        limit={limit}
        listData={DataOpts}
        modalTitle={modalTitle}
        selectionState={selectionState}
        subTextOn={subTextOn}
        addButtonText={addButtonText}
      />
    );
  } //open modal component

  function handleAddOptsModal(opts) {
    let prevOptsArr = [...selectionState];
    let newOptsArr = [];
    opts?.map((opt) => {
      if (!prevOptsArr.includes(opt)) {
        return newOptsArr.push(opt);
      }
    });
    if (!setSelectionState) {
      newOptsArr.map((tag) => handleAdd(tag));
    } else {
      newOptsArr = [...newOptsArr, ...prevOptsArr];
      newOptsArr.sort().reverse();
      setSelectionState(newOptsArr);
    }
  }

  function handleRemoveOpt(removeOpt) {
    let newArr = selectionState.filter((opt) => opt !== removeOpt);
    setSelectionState(newArr);
  }

  return (
    <div className={`full-width ${customWrapper}`}>
      <ItemButton
        handler={handleSeeFullList}
        text={addButtonText}
        id={`${id}-itemButton`}
        customWrapper={itemButtonCustomWrapper}
      />

      <div className="dropdown-tags__wrapper">
        <div className="flex-row flex-center mrg-t12">
          {!iconOff && (
            <img
              className={`${limitIconClass ? limitIconClass : ''}`}
              src={limitIcon}
              alt={limitIconAlt || ''}
            />
          )}
          {limit && (
            <p
              className={`fs16 fwn access-ob access-o6 no-select `}
              tabIndex="0"
            >
              {limitText} {selectionState?.length} / {limit}
            </p>
          )}
        </div>
        {selectionState?.length > limit && (
          <div className="banners__notice align-center full-width fs16 fwsb">
            {SelectionsModal.limitExceeded}
          </div>
        )}

        {selectionState?.length > 0 && <div className="h12" />}

        <ul className={`tags__container mrg-t12`}>
          {selectionState
            ?.map((selection, index) => {
              return (
                <li key={`${id}-${index}`}>
                  <button
                    tabIndex="0"
                    aria-label={`${SelectionsModal.remove} ${selection}`}
                    className={`tag__list-item highlight-bthin-theme fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                      tapHighlightTag === selection
                        ? 'highlight-b-theme--tap'
                        : 'highlight-i-lgr'
                    }`}
                    id={selection}
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setTapHighlightTag(selection),
                          () => setTapHighlightTag(),
                        ],
                        [() => handleRemoveOpt(selection)]
                      )
                    }
                  >
                    {subTextOn ? (
                      <p id={selection}>
                        {DataOptsSubTextTranslations[selection]}
                      </p>
                    ) : (
                      <p id={selection}>{DataOpts[selection]}</p>
                    )}
                    <img
                      src={cancel}
                      alt={SelectionsModal.remove}
                      className={`tag-remove-btn-img ${
                        tapHighlightTag === selection
                          ? 'filter-red'
                          : 'filter-lightgray'
                      }`}
                    />
                  </button>
                </li>
              );
            })
            .reverse()}
        </ul>
      </div>
    </div>
  );
};

export default SelectionsModal;
