import { useEffect, useState } from 'react';

//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

//components
import ParticipantListItemCompletionStatus from '../Generic/ParticipantListItemCompletionStatus';
import ParticipantListItemInvitationStatus from '../Generic/ParticipantListItemInvitationStatus';

//utils

const ParticipantListItemStatusParticipant = ({ p }) => {
  //hooks
  const { ParticipantListItem } = useLanguageOrganize();

  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.emailInvitation?.tokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
      setIsDispatched(p?.dispatches?.inviteDispatched);
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'create' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.completionStatus}
            organizedAs={p.organizedAs}
          />
        </div>
      )}

      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.completionStatus}
            organizedAs={p.organizedAs}
            inviteIsDispatched={isDispatched}
          />
          {p?.dispatches?.inviteDispatched && (
            <>
              |{' '}
              <ParticipantListItemInvitationStatus
                invitationStatus={p.dispatches.invitationStatus}
              />
            </>
          )}
        </div>
      )}
      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.completionStatus}
            duplicateEmail={p?.emailInvitation?.duplicateEmail}
            invalidEmail={
              p?.emailInvitation?.email?.length > 0 &&
              p?.emailInvitation?.emailValid === false
            }
            organizedAs={p.organizedAs}
            inviteIsDispatched={isDispatched}
          />
          {p?.dispatches?.inviteDispatched && (
            <>
              |{' '}
              <ParticipantListItemInvitationStatus
                invitationStatus={p.dispatches.invitationStatus}
                invitationHasExpired={invitationHasExpired}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusParticipant;
