import { useEffect, useRef, useState } from 'react';

//hooks
import useSettings from '../../context/useSettings';

//component
import Spinner from '../Spinner/Spinner';

//utils
import { textUI } from '../../utils/UI/textUI';

const ButtonTextSimple = ({
  text,
  handler,
  ariaText,
  id,
  iconOn,
  icon,
  iconAlt,
  iconClass,
  iconRight,
  iconStyle,
  customClassText,
  customStyle,
  customWrapper,
  hideBorderOn,
  title,
  isLoading,
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI
  const [tapHighlightOrderBtn, setTapHighlightOrderBtn] = useState(false);
  const [btnElWidth, setBtnElWidth] = useState(0);
  const [btnElHeight, setBtnElHeight] = useState(0);

  //variables
  const btnEl = useRef();

  //ui
  useEffect(() => {
    if (btnEl.current.clientWidth && btnEl.current.clientHeight) {
      setBtnElWidth(btnEl.current.clientWidth - 24);
      setBtnElHeight(btnEl.current.clientHeight - 12);
    }
  }, [btnEl]);

  //func
  function handleBtn() {
    handleMobileTap(
      [
        () => setTapHighlightOrderBtn(true),
        () => setTapHighlightOrderBtn(false),
      ],
      [() => handler(id)],
      true
    );
  }

  return (
    <button
      onClick={handleBtn}
      className={`flex-row align-center fs16 fwsb br--standard fit-content pad-6 pad-l12 pad-r12 no-wrap  ${
        tapHighlightOrderBtn && !hideBorderOn
          ? 'button-text-simple-border--tap '
          : !hideBorderOn
          ? 'button-text-simple-border'
          : ''
      } ${customWrapper ? customWrapper : ''}`}
      aria-label={`${ariaText}`}
      style={customStyle}
      title={title || null}
      ref={btnEl}
    >
      {isLoading && (
        <Spinner
          minWidth={`${btnElWidth}px`}
          maxWidth={`${btnElWidth}px`}
          minHeight={`${btnElHeight}px`}
          maxHeight={`${btnElHeight}px`}
        />
      )}
      <div
        className={`flex-row nowrap align-center ${isLoading ? 'remove' : ''}`}
      >
        {iconOn && !iconRight && (
          <img
            src={icon}
            alt={iconAlt || ''}
            className={iconClass}
            style={iconStyle ? iconStyle : null}
          />
        )}
        {text && <div className="">{textUI(text, customClassText, true)}</div>}
        {iconOn && iconRight && (
          <img
            src={icon}
            alt={iconAlt || ''}
            className={iconClass}
            style={iconStyle ? iconStyle : null}
          />
        )}
      </div>
    </button>
  );
};

export default ButtonTextSimple;
