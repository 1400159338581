import { apiSlice } from '../../../app/api/apiSlice';
import { setNotices } from '../account/accountSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';

export const noticesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountNotices: builder.query({
      query: (accountId) => {
        return {
          url: `/api/notices/${accountId}`,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setNotices(data?.notices));
        } catch (error) {
          //no account error - too frequent with refresh
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('noticesApiSlice, myAccount error:', error);
          }
        }
      },
    }),
    setAccountNoticesSeen: builder.mutation({
      query: (body) => ({
        url: `/api/notices/setAccountNoticesSeen`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          //data does need to be set here because notices socket will not track individual field changes and we want the notice changes to render immediately
          dispatch(setNotices(data?.notices));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'noticesApiSlice/setAccountNoticeSeen',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    ignoreRequestSpeakerContact: builder.mutation({
      query: (body) => ({
        url: `/api/notices/ignoreRequestSpeakerContact`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'noticesApiSlice/ignoreRequestSpeakerContact',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    archiveNotice: builder.mutation({
      query: (body) => ({
        url: `/api/notices/archiveNotice`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'noticesApiSlice/archiveNotice',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeMyNotice: builder.mutation({
      query: (body) => ({
        url: `/api/notices/removeMyNotice`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'noticesApiSlice/removeMyNotice',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeAllNotices: builder.mutation({
      query: (body) => ({
        url: `/api/notices/removeAllNotices`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'noticesApiSlice/removeAllNotices',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  useLazyGetAccountNoticesQuery,
  useSetAccountNoticesSeenMutation,
  useIgnoreRequestSpeakerContactMutation,
  useArchiveNoticeMutation,
  useRemoveMyNoticeMutation,
  useRemoveAllNoticesMutation,
} = noticesApiSlice;
