import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditEvent,
  resetEditEvent,
  setEditEvent,
  selectEditEventChangeList,
  setEditEventChangeList,

  //contact
  selectEditEventContactProvideContact,
  selectEditEventContactContactEmail,
  selectEditEventContactContactPhoneNumber,
  selectEditEventContactCountryCode,
  setEditEventContactProvideContact,
  setEditEventContactEmail,
  setEditEventContactPhoneNumber,
  setEditEventContactCountryCode,

  //dateTime
  selectEditEventDateTimeDate,
  selectEditEventDateTimeTimeEnd,
  selectEditEventDateTimeTimeStart,
  selectEditEventDateTimeTimeZone,
  setEditEventDateTimeDate,
  setEditEventDateTimeTimeEnd,
  setEditEventDateTimeTimeStart,
  setEditEventDateTimeTimeZone,

  //generic
  selectEditEventGenericAccessibilityNotes,
  selectEditEventGenericAgendaItems,
  selectEditEventGenericAgendaFormat,
  selectEditEventGenericAudienceType,
  selectEditEventGenericDescription,
  selectEditEventGenericEventImageDisplay,
  selectEditEventGenericEventAdditionalImages,
  selectEditEventGenericEventType,
  selectEditEventGenericLanguages,
  selectEditEventGenericTitle,
  selectEditEventGenericWheelchairAccessibility,
  selectEditEventGenericSignLanguageInterpreter,
  setEditEventGenericAccessibilityNotes,
  setEditEventGenericAgendaItems,
  setEditEventGenericAgendaFormat,
  setEditEventGenericAudienceType,
  setEditEventGenericDescription,
  setEditEventGenericEventType,
  setEditEventGenericLanguages,
  setEditEventGenericSignLanguageInterpreter,
  setEditEventGenericTitle,
  setEditEventGenericWheelchairAccessibility,
  setEditEventGenericEventImageDisplay,
  setEditEventGenericEventAdditionalImages,

  //hosting
  selectEditEventHostingInPersonGeoPositionCoordinates,
  selectEditEventHostingHostingOption,
  selectEditEventHostingInPersonLocation,
  selectEditEventHostingVirtualMeetingId,
  selectEditEventHostingVirtualPasscode,
  selectEditEventHostingVirtualPlatform,
  selectEditEventHostingVirtualLink,
  setEditEventHostingGeoPositionCoordinates,
  setEditEventHostingHostingOption,
  setEditEventHostingInPersonLocation,
  setEditEventHostingVirtualMeetingId,
  setEditEventHostingVirtualPasscode,
  setEditEventHostingVirtualPlatform,
  setEditEventHostingVirtualLink,

  //meta
  selectEditEventMetaLastModified,
  selectEditEventMetaSaveName,
  setEditEventMetaSaveName,

  //participants
  setEditEventParticipants,
  selectEditEventParticipants,

  //registrations
  selectEditEventRegistrationDataApproveRegistrations,
  selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  selectEditEventRegistrationDataPrivateDetailsEnabled,
  selectEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  selectEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  selectEditEventRegistrationDataPrivateDetails,
  selectEditEventRegistrationDataRegistrationsActive,
  setEditEventRegistrationDataApproveRegistrations,
  setEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setEditEventRegistrationDataPrivateDetailsEnabled,
  setEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  setEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  setEditEventRegistrationDataPrivateDetails,
  setEditEventRegistrationDataRegistrationsActive,

  //schedule
  selectEditEventSchedulePublishDate,
  selectEditEventSchedulePublishTime,
  selectEditEventSchedulePublishTimeZone,
  setEditEventSchedulePublishDate,
  setEditEventSchedulePublishTime,
  setEditEventSchedulePublishTimeZone,
  setEditEventScheduleEnabled,
  selectEditEventScheduleEnabled,

  //tags
  selectEditEventTagsBigTags,
  selectEditEventTagsTinyTags,
  addEditEventTagsBigTag,
  addEditEventTagsTinyTag,
  removeEditEventTagsBigTag,
  removeEditEventTagsTinyTag,
  setEditEventTagsBigTags,
  setEditEventTagsTinyTags,
  reverseEditEventHostingGeoPositionCoordinates,
  selectEditEventId,

  //Translations
  setEditEventTranslationsTranslationsEnabled,
  setEditEventTranslationsRoomCodeId,
  setEditEventTranslationsJoinRoomPasswordToggle,
  setEditEventTranslationsPassword,
  setEditEventTranslationsRoomLanguages,
  selectEditEventTranslationsTranslationsEnabled,
  selectEditEventTranslationsRoomCodeId,
  selectEditEventTranslationsJoinRoomPasswordToggle,
  selectEditEventTranslationsPassword,
  selectEditEventTranslationsRoomLanguages,
} from './editEventSlice';

//Hooks

//Components

//Utility

export const useEditEventReduxHandlers = () => {
  const dispatch = useDispatch();

  const isEditEventStateInitialized = Boolean(selectEditEvent);
  const editEventChangeList = useSelector(selectEditEventChangeList);

  //contact
  const contactProvideContact = useSelector(
    selectEditEventContactProvideContact
  );
  const contactContactEmail = useSelector(selectEditEventContactContactEmail);
  const contactContactPhoneNumber = useSelector(
    selectEditEventContactContactPhoneNumber
  );
  const contactContactCountryCode = useSelector(
    selectEditEventContactCountryCode
  );

  //dateTime
  const date = useSelector(selectEditEventDateTimeDate);
  const timeEnd = useSelector(selectEditEventDateTimeTimeEnd);
  const timeStart = useSelector(selectEditEventDateTimeTimeStart);
  const timeZone = useSelector(selectEditEventDateTimeTimeZone);

  //Generic
  const accessibilityNotes = useSelector(
    selectEditEventGenericAccessibilityNotes
  );
  const agendaItems = useSelector(selectEditEventGenericAgendaItems);
  const agendaFormat = useSelector(selectEditEventGenericAgendaFormat);
  const audienceType = useSelector(selectEditEventGenericAudienceType);
  const description = useSelector(selectEditEventGenericDescription);
  const eventImageDisplay = useSelector(
    selectEditEventGenericEventImageDisplay
  );
  const eventAdditionalImages = useSelector(
    selectEditEventGenericEventAdditionalImages
  );
  const eventType = useSelector(selectEditEventGenericEventType);
  const languages = useSelector(selectEditEventGenericLanguages);
  const signLanguageInterpreter = useSelector(
    selectEditEventGenericSignLanguageInterpreter
  );
  const title = useSelector(selectEditEventGenericTitle);
  const wheelchairAccessibility = useSelector(
    selectEditEventGenericWheelchairAccessibility
  );

  //Hosting
  const hostingOption = useSelector(selectEditEventHostingHostingOption);
  const coordinates = useSelector(
    selectEditEventHostingInPersonGeoPositionCoordinates
  );
  const virtualMeetingId = useSelector(selectEditEventHostingVirtualMeetingId);
  const virtualPasscode = useSelector(selectEditEventHostingVirtualPasscode);
  const virtualPlatform = useSelector(selectEditEventHostingVirtualPlatform);
  const virtualLink = useSelector(selectEditEventHostingVirtualLink);
  const location = useSelector(selectEditEventHostingInPersonLocation);

  //Meta
  const lastModified = useSelector(selectEditEventMetaLastModified);
  const saveName = useSelector(selectEditEventMetaSaveName);
  const editEventData = useSelector(selectEditEvent);
  const editEventId = useSelector(selectEditEventId);

  //Participants
  const editEventParticipants = useSelector(selectEditEventParticipants);

  //RegistrationData
  const approveRegistrations = useSelector(
    selectEditEventRegistrationDataApproveRegistrations
  );
  const maxRegistrationsInPersonEnabled = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled
  );
  const maxRegistrationsVirtualEnabled = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled
  );
  const maxRegistrationsInPersonAmount = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsInPersonAmount
  );
  const maxRegistrationsVirtualAmount = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsVirtualAmount
  );
  const registrationsActive = useSelector(
    selectEditEventRegistrationDataRegistrationsActive
  );
  const privateDetails = useSelector(
    selectEditEventRegistrationDataPrivateDetails
  );
  const enablePrivateDetails = useSelector(
    selectEditEventRegistrationDataPrivateDetailsEnabled
  );

  //SchedulePublish
  const scheduleEnabled = useSelector(selectEditEventScheduleEnabled);
  const schedulePublishDate = useSelector(selectEditEventSchedulePublishDate);
  const schedulePublishTime = useSelector(selectEditEventSchedulePublishTime);
  const schedulePublishTimeZone = useSelector(
    selectEditEventSchedulePublishTimeZone
  );

  //Tags
  const bigTags = useSelector(selectEditEventTagsBigTags);
  const tinyTags = useSelector(selectEditEventTagsTinyTags);

  //Translations
  const editEventTranslationsTranslationsEnabled = useSelector(
    selectEditEventTranslationsTranslationsEnabled
  );
  const editEventTranslationsRoomCodeId = useSelector(
    selectEditEventTranslationsRoomCodeId
  );
  const editEventTranslationsJoinRoomPasswordToggle = useSelector(
    selectEditEventTranslationsJoinRoomPasswordToggle
  );
  const editEventTranslationsPassword = useSelector(
    selectEditEventTranslationsPassword
  );
  const editEventTranslationsRoomLanguages = useSelector(
    selectEditEventTranslationsRoomLanguages
  );

  ////HANDLERS
  function handleEditEventReset() {
    dispatch(resetEditEvent());
  }

  function handleEditEventSetEvent(eventData) {
    dispatch(setEditEvent(eventData));
  }
  function handleEditEventChangeList(arr) {
    dispatch(setEditEventChangeList(arr));
  }

  //contact
  function handleEditEventSetProvideContact(boolean) {
    dispatch(setEditEventContactProvideContact(boolean));
  }
  function handleEditEventSetContactEmail(str) {
    dispatch(setEditEventContactEmail(str));
  }
  function handleEditEventSetContactPhoneNumber(str) {
    dispatch(setEditEventContactPhoneNumber(str));
  }
  function handleEditEventSetContactCountryCode(str) {
    dispatch(setEditEventContactCountryCode(str));
  }

  //dateTime
  function handleEditEventSetDate(str) {
    dispatch(setEditEventDateTimeDate(str));
  }
  function handleEditEventSetTimeZone(str) {
    dispatch(setEditEventDateTimeTimeZone(str));
  }
  function handleEditEventSetTimeStart(str) {
    dispatch(setEditEventDateTimeTimeStart(str));
  }
  function handleEditEventSetTimeEnd(str) {
    dispatch(setEditEventDateTimeTimeEnd(str));
  }

  //Generic
  function handleEditEventSetAgendaFormat(str) {
    dispatch(setEditEventGenericAgendaFormat(str));
  }

  function handleEditEventSetAgendaItems(arr) {
    dispatch(setEditEventGenericAgendaItems(arr));
  }

  function handleEditEventSetTitle(str) {
    dispatch(setEditEventGenericTitle(str));
  }
  function handleEditEventSetDescription(str) {
    dispatch(setEditEventGenericDescription(str));
  }
  function handleEditEventSetEventType(str) {
    dispatch(setEditEventGenericEventType(str));
  }
  function handleEditEventSetEventImageDisplay(obj) {
    dispatch(setEditEventGenericEventImageDisplay(obj));
  }

  function handleEditEventSetEventAdditionalImages(arr) {
    dispatch(setEditEventGenericEventAdditionalImages(arr));
  }

  function handleEditEventSetAudienceType(str) {
    dispatch(setEditEventGenericAudienceType(str));
  }

  function handleEditEventSetLanguages(arr) {
    dispatch(setEditEventGenericLanguages(arr));
  }

  function handleEditEventSetWheelchairAccessibility(boolean) {
    dispatch(setEditEventGenericWheelchairAccessibility(boolean));
  }

  function handleEditEventSetSignLanguageInterpreter(signLanguageInterpreter) {
    dispatch(
      setEditEventGenericSignLanguageInterpreter(signLanguageInterpreter)
    );
  }

  function handleEditEventSetAccessibilityNotes(str) {
    dispatch(setEditEventGenericAccessibilityNotes(str));
  }

  //Hosting

  function handleEditEventSetHostingOption(str) {
    dispatch(setEditEventHostingHostingOption(str));
  }

  function handleEditEventSetVirtualPlatform(str) {
    dispatch(setEditEventHostingVirtualPlatform(str));
  }

  function handleEditEventSetVirtualMeetingId(str) {
    dispatch(setEditEventHostingVirtualMeetingId(str));
  }

  function handleEditEventSetVirtualMeetingLink(str) {
    dispatch(setEditEventHostingVirtualLink(str));
  }

  function handleEditEventSetVirtualPasscode(str) {
    dispatch(setEditEventHostingVirtualPasscode(str));
  }

  function handleEditEventSetLocation(obj) {
    dispatch(setEditEventHostingInPersonLocation(obj));
  }

  function handleEditEventClearLocation() {
    dispatch(
      setEditEventHostingInPersonLocation({
        room: '',
        locationName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        placeId: '',
      })
    );
    dispatch(setEditEventHostingGeoPositionCoordinates([0, 0]));
  }

  function handleEditEventSetCoordinates(arr) {
    dispatch(setEditEventHostingGeoPositionCoordinates(arr));
  }

  function handleReverseEditEventHostingGeoPositionCoordinates() {
    dispatch(reverseEditEventHostingGeoPositionCoordinates());
  }

  //Meta
  function handleEditEventSetSaveName(saveName) {
    dispatch(setEditEventMetaSaveName(saveName));
  }

  //Participants
  function handleEditEventSetParticipants(arr) {
    dispatch(setEditEventParticipants(arr));
  }

  //RegistrationData

  function handleEditEventSetRegistrations(boolean) {
    dispatch(setEditEventRegistrationDataRegistrationsActive(boolean));
  }

  function handleEditEventSetApproveRegistrations(boolean) {
    dispatch(setEditEventRegistrationDataApproveRegistrations(boolean));
  }

  function handleEditEventSetMaxRegistrationsInPersonEnabled(boolean) {
    dispatch(
      setEditEventRegistrationDataMaxRegistrationsInPersonEnabled(boolean)
    );
  }

  function handleEditEventSetMaxRegistrationsInPersonAmount(num) {
    dispatch(setEditEventRegistrationDataMaxRegistrationsInPersonAmount(num));
  }

  function handleEditEventSetMaxRegistrationsVirtualEnabled(boolean) {
    dispatch(
      setEditEventRegistrationDataMaxRegistrationsVirtualEnabled(boolean)
    );
  }

  function handleEditEventSetMaxRegistrationsVirtualAmount(num) {
    dispatch(setEditEventRegistrationDataMaxRegistrationsVirtualAmount(num));
  }

  function handleEditEventSetEnablePrivateDetails(enablePrivateDetails) {
    dispatch(
      setEditEventRegistrationDataPrivateDetailsEnabled(enablePrivateDetails)
    );
  }

  function handleEditEventSetPrivateDetails(id, val) {
    dispatch(setEditEventRegistrationDataPrivateDetails({ id, val }));
  }

  //SchedulePublish
  function handleEditEventSetScheduleEnabled(boolean) {
    dispatch(setEditEventScheduleEnabled(boolean));
  }
  function handleEditEventSetSchedulePublishDate(str) {
    dispatch(setEditEventSchedulePublishDate(str));
  }
  function handleEditEventSetSchedulePublishTime(str) {
    dispatch(setEditEventSchedulePublishTime(str));
  }
  function handleEditEventSetSchedulePublishTimeZone(str) {
    dispatch(setEditEventSchedulePublishTimeZone(str));
  }

  //Tags
  function handleEditEventAddBigTag(str) {
    dispatch(addEditEventTagsBigTag(str));
  }

  function handleEditEventRemoveBigTag(str) {
    dispatch(removeEditEventTagsBigTag(str));
  }

  function handleEditEventAddTinyTag(str) {
    dispatch(addEditEventTagsTinyTag(str));
  }

  function handleEditEventRemoveTinyTag(str) {
    dispatch(removeEditEventTagsTinyTag(str));
  }

  function handleEditEventSetBigTags(arr) {
    dispatch(setEditEventTagsBigTags(arr));
  }

  function handleEditEventSetTinyTags(arr) {
    dispatch(setEditEventTagsTinyTags(arr));
  }

  //translations

  //Translations
  function handleEditEventSetTranslationsTranslationsEnabled(boolean) {
    dispatch(setEditEventTranslationsTranslationsEnabled(boolean));
  }
  function handleEditEventSetTranslationsRoomCodeId(objId) {
    dispatch(setEditEventTranslationsRoomCodeId(objId));
  }
  function handleEditEventSetTranslationsJoinRoomPasswordToggle(boolean) {
    dispatch(setEditEventTranslationsJoinRoomPasswordToggle(boolean));
  }
  function handleEditEventSetTranslationsPassword(str) {
    dispatch(setEditEventTranslationsPassword(str));
  }
  function handleEditEventSetTranslationsRoomLanguages(arr) {
    dispatch(setEditEventTranslationsRoomLanguages(arr));
  }

  return {
    isEditEventStateInitialized,
    editEventData,
    editEventChangeList,
    handleEditEventChangeList,
    handleEditEventReset,
    handleEditEventSetEvent,

    //contact
    contactProvideContact,
    contactContactEmail,
    contactContactPhoneNumber,
    contactContactCountryCode,
    handleEditEventSetProvideContact,
    handleEditEventSetContactEmail,
    handleEditEventSetContactPhoneNumber,
    handleEditEventSetContactCountryCode,

    //dateTime
    date,
    timeEnd,
    timeStart,
    timeZone,
    handleEditEventSetTimeEnd,
    handleEditEventSetTimeStart,
    handleEditEventSetTimeZone,
    handleEditEventSetDate,

    //generic
    accessibilityNotes,
    agendaFormat,
    agendaItems,
    audienceType,
    description,
    eventImageDisplay,
    eventAdditionalImages,
    eventType,
    languages,
    signLanguageInterpreter,
    title,
    wheelchairAccessibility,
    handleEditEventSetAccessibilityNotes,
    handleEditEventSetAgendaFormat,
    handleEditEventSetAgendaItems,
    handleEditEventSetAudienceType,
    handleEditEventSetDescription,
    handleEditEventSetEventType,
    handleEditEventSetLanguages,
    handleEditEventSetSignLanguageInterpreter,
    handleEditEventSetTitle,
    handleEditEventSetWheelchairAccessibility,
    handleEditEventSetEventImageDisplay,
    handleEditEventSetEventAdditionalImages,

    //hosting
    hostingOption,
    location,
    coordinates,
    virtualLink,
    virtualMeetingId,
    virtualPasscode,
    virtualPlatform,
    handleEditEventClearLocation,
    handleEditEventSetCoordinates,
    handleEditEventSetHostingOption,
    handleEditEventSetLocation,
    handleReverseEditEventHostingGeoPositionCoordinates,

    //meta
    lastModified,
    saveName,
    handleEditEventSetSaveName,
    editEventId,

    //participants
    editEventParticipants,
    handleEditEventSetParticipants,

    //registrations
    enablePrivateDetails,
    privateDetails,
    registrationsActive,
    maxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount,
    approveRegistrations,
    handleEditEventSetEnablePrivateDetails,
    handleEditEventSetPrivateDetails,
    handleEditEventSetRegistrations,
    handleEditEventSetVirtualMeetingId,
    handleEditEventSetVirtualPasscode,
    handleEditEventSetVirtualPlatform,
    handleEditEventSetVirtualMeetingLink,
    handleEditEventSetMaxRegistrationsInPersonEnabled,
    handleEditEventSetMaxRegistrationsInPersonAmount,
    handleEditEventSetMaxRegistrationsVirtualEnabled,
    handleEditEventSetMaxRegistrationsVirtualAmount,
    handleEditEventSetApproveRegistrations,

    //schedule
    schedulePublishDate,
    schedulePublishTime,
    schedulePublishTimeZone,
    scheduleEnabled,
    handleEditEventSetScheduleEnabled,
    handleEditEventSetSchedulePublishDate,
    handleEditEventSetSchedulePublishTime,
    handleEditEventSetSchedulePublishTimeZone,

    //tags
    bigTags,
    tinyTags,
    handleEditEventAddBigTag,
    handleEditEventAddTinyTag,
    handleEditEventRemoveBigTag,
    handleEditEventRemoveTinyTag,
    handleEditEventSetBigTags,
    handleEditEventSetTinyTags,

    //translations
    editEventTranslationsTranslationsEnabled,
    editEventTranslationsRoomCodeId,
    editEventTranslationsJoinRoomPasswordToggle,
    editEventTranslationsPassword,
    editEventTranslationsRoomLanguages,
    handleEditEventSetTranslationsTranslationsEnabled,
    handleEditEventSetTranslationsRoomCodeId,
    handleEditEventSetTranslationsJoinRoomPasswordToggle,
    handleEditEventSetTranslationsPassword,
    handleEditEventSetTranslationsRoomLanguages,
  };
};
