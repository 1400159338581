import { useState } from 'react';

//Hooks
import useLanguageAccount from '../../../../language/features/useLanguageAccount';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useSettings from '../../../../context/useSettings';
import { useAuthReduxHandlers } from '../../auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../useAccountHandlers';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';

//scrolls to element / enter resets focus
//closes settingsDropdown menu

export const SubAccountMenus = ({ setOpenSettingsDropdown }) => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { settingsAccountSettingsView } = useSettingsHandlers();
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { accountBigTags, accountSpeakerSignUpComplete } =
    useAccountReduxHandlers();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlight, setTapHighlight] = useState();

  //Component variables
  let subselections;

  //Component functions
  function handleScrollToClick(scrollToId) {
    if (setOpenSettingsDropdown) {
      setOpenSettingsDropdown(false);
    }
    ScrollTo(scrollToId, 'smooth', false, scrollToId);
  }

  function handleKeyDown(e, id) {
    if (e.key === 'Enter') {
      document.getElementById(`${id}`).focus();

      if (setOpenSettingsDropdown) {
        setOpenSettingsDropdown(false);
      }
      ScrollTo(id, 'smooth', false, id);
    }
  }

  //JSX conditional
  if (settingsAccountSettingsView === 'general') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'appSettings' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('appSettings'), () => setTapHighlight()],
                [() => handleScrollToClick('appSettingsLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.attendee.bigTags}.`}
            onKeyDown={(e) => handleKeyDown(e, 'appSettingsLabel')}
          >
            {Account.general.appSettings}
          </button>
        </li>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'account') {
    subselections = (
      <ul
        className="sub-selections"
        aria-label={Account.account.ariaSecondaryMenu}
      >
        <li className="flex-row">
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'profilePreview' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('profilePreview'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('profilePreviewLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.profilePreview}.`}
            onKeyDown={(e) => handleKeyDown(e, 'profilePreviewLabel')}
          >
            {Account.account.profilePreview}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'banner' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('banner'), () => setTapHighlight()],
                [() => handleScrollToClick('bannerLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.banner}.`}
            onKeyDown={(e) => handleKeyDown(e, 'bannerLabel')}
          >
            {Account.account.banner}
          </button>
        </li>
        <li className="flex-row">
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'profilePhoto' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('profilePhoto'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('profilePhotoLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.profilePhoto}.`}
            onKeyDown={(e) => handleKeyDown(e, 'profilePhotoLabel')}
          >
            {Account.account.profilePhoto}
          </button>
        </li>

        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'personalBio' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('personalBio'), () => setTapHighlight()],
                [() => handleScrollToClick('personalBioLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.personalBio}.`}
            onKeyDown={(e) => handleKeyDown(e, 'personalBioLabel')}
          >
            {Account.account.personalBio}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'socialLinks' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('socialLinks'), () => setTapHighlight()],
                [() => handleScrollToClick('socialLinksLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.socialLinks}.`}
            onKeyDown={(e) => handleKeyDown(e, 'socialLinksLabel')}
          >
            {Account.account.socialLinks}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme  access-ob access-o6  ${
              tapHighlight === 'email' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('email'), () => setTapHighlight()],
                [() => handleScrollToClick('emailLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.emailAddress}.`}
            onKeyDown={(e) => handleKeyDown(e, 'emailLabel')}
          >
            {Account.account.emailAddress}
          </button>
        </li>
        {/* <li className="flex-row">
          <button
            className={`secondary-heading highlight-btreg-theme ${
              tapHighlight === 'badges' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('badges'), () => setTapHighlight()],
                [() => handleScrollToClick('badgesLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.badges.label}.`}
            onKeyDown={(e) => handleKeyDown(e, 'badgesLabel')}
          >
            {Account.account.badges.label}
          </button>
        </li> */}
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'name' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('name'), () => setTapHighlight()],
                [() => handleScrollToClick('nameLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.name}.`}
            onKeyDown={(e) => handleKeyDown(e, 'nameLabel')}
          >
            {Account.account.name}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6  ${
              tapHighlight === 'password' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('password'), () => setTapHighlight()],
                [() => handleScrollToClick('passwordLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.password}.`}
            onKeyDown={(e) => handleKeyDown(e, 'passwordLabel')}
          >
            {Account.account.password}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'phone' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('phone'), () => setTapHighlight()],
                [() => handleScrollToClick('phoneLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.account.phone}.`}
            onKeyDown={(e) => handleKeyDown(e, 'phoneLabel')}
          >
            {Account.account.phone}
          </button>
        </li>
      </ul>
    );
  }
  if (settingsAccountSettingsView === 'billing') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'paymentMethods' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('paymentMethods'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('paymentMethodsLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.billing.billing}.`}
            onKeyDown={(e) => handleKeyDown(e, 'paymentMethodsLabel')}
          >
            {Account.billing.paymentMethods}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'billingSummary' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('billingSummary'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('billingSummaryLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.billing.billingSummary}.`}
            onKeyDown={(e) => handleKeyDown(e, 'billingSummaryLabel')}
          >
            {Account.billing.billingSummary}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'translationRoomsBilling'
                ? 'highlight-tb-theme--tap'
                : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('translationRoomsBilling'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('translationRoomsBillingLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.billing.billing}.`}
            onKeyDown={(e) => handleKeyDown(e, 'translationRoomsBillingLabel')}
          >
            {Account.billing.translationsUsage}
          </button>
        </li>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'community') {
    subselections = (
      <ul className="sub-selections">
        <>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'communityAccounts'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('communityAccounts'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('communityAccountsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.community.communityAccounts}.`}
              onKeyDown={(e) => handleKeyDown(e, 'communityAccountsLabel')}
            >
              {Account.community.communityAccounts}
            </button>
          </li>
        </>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'emailPreferences') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'emailPreferences'
                ? 'highlight-tb-theme--tap'
                : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('emailPreferences'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('emailPreferencesLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.emailPreferences.email}.`}
            onKeyDown={(e) => handleKeyDown(e, 'emailPreferencesLabel')}
          >
            MyndFull
          </button>
        </li>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'attendee') {
    if (
      !authCurrentRoles?.includes(process.env.REACT_APP_ATTENDEE) &&
      (accountBigTags?.length === 0 || !accountBigTags)
    ) {
      subselections = (
        <ul className="sub-selections">
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'attendeeSignUp'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('attendeeSignUp'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('attendeeSignUpLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.attendeeSignUp}.`}
              onKeyDown={(e) => handleKeyDown(e, 'attendeeSignUpLabel')}
            >
              {Account.attendee.attendeeSignUp}
            </button>
          </li>
        </ul>
      );
    } else {
      subselections = (
        <ul className="sub-selections">
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'attendeeActivation'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('attendeeActivation'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('attendeeActivationLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.activation}.`}
              onKeyDown={(e) => handleKeyDown(e, 'attendeeActivationLabel')}
            >
              {Account.attendee.activation}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'bigTags' ? 'highlight-tb-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [() => setTapHighlight('bigTags'), () => setTapHighlight()],
                  [() => handleScrollToClick('bigTagsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.bigTags}.`}
              onKeyDown={(e) => handleKeyDown(e, 'bigTagsLabel')}
            >
              {Account.attendee.bigTags}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'tinyTags' ? 'highlight-tb-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [() => setTapHighlight('tinyTags'), () => setTapHighlight()],
                  [() => handleScrollToClick('tinyTagsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.tinyTags}.`}
              onKeyDown={(e) => handleKeyDown(e, 'tinyTagsLabel')}
            >
              {Account.attendee.tinyTags}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'searchLanguages'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('searchLanguages'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('searchLanguagesLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.searchLanguages}.`}
              onKeyDown={(e) => handleKeyDown(e, 'searchLanguagesLabel')}
            >
              {Account.attendee.searchLanguages}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'eventSearchInPerson'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('eventSearchInPerson'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('eventSearchInPersonLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.eventSearchInPerson}.`}
              onKeyDown={(e) => handleKeyDown(e, 'eventSearchInPersonLabel')}
            >
              {Account.attendee.eventSearchInPerson}
            </button>
          </li>

          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'eventSearchOnline'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('eventSearchOnline'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('eventSearchOnlineLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.attendee.eventSearchOnline}.`}
              onKeyDown={(e) => handleKeyDown(e, 'eventSearchOnlineLabel')}
            >
              {Account.attendee.eventSearchOnline}
            </button>
          </li>

          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'community' ? 'highlight-tb-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [() => setTapHighlight('community'), () => setTapHighlight()],
                  [() => handleScrollToClick('communityLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.account.installWebApp}.`}
              onKeyDown={(e) => handleKeyDown(e, 'communityLabel')}
            >
              {Account.attendee.community}
            </button>
          </li>

          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'ignoredEvents'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('ignoredEvents'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('ignoredEventsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.account.installWebApp}.`}
              onKeyDown={(e) => handleKeyDown(e, 'ignoredEventsLabel')}
            >
              {Account.attendee.ignoredEvents}
            </button>
          </li>

          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'archivedEvents'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('archivedEvents'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('archivedEventsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.account.installWebApp}.`}
              onKeyDown={(e) => handleKeyDown(e, 'archivedEventsLabel')}
            >
              {Account.attendee.archivedEvents}
            </button>
          </li>
        </ul>
      );
    }
  }

  if (settingsAccountSettingsView === 'speaker') {
    if (
      !authCurrentRoles?.includes(process.env.REACT_APP_SPEAKER) &&
      !accountSpeakerSignUpComplete
    ) {
      subselections = (
        <ul className="sub-selections">
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerSignUp'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerSignUp'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerSignUpLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerSignUp}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerSignUpLabel')}
            >
              {Account.speaker.speakerSignUp}
            </button>
          </li>
        </ul>
      );
    } else {
      subselections = (
        <ul className="sub-selections">
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerRoleToggle'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerRoleToggle'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerRoleToggleLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerActivation}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerRoleToggleLabel')}
            >
              {Account.speaker.speakerActivation}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'profilePreviewLabel'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('profilePreviewLabel'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('profilePreviewLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.account.profilePreview}.`}
              onKeyDown={(e) => handleKeyDown(e, 'profilePreviewLabel')}
            >
              {Account.account.profilePreview}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerLanguages'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerLanguages'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerLanguagesLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerLanguages}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerLanguagesLabel')}
            >
              {Account.speaker.speakerLanguages}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerLocation'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerLocation'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerLocationLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerLocation}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerLocationLabel')}
            >
              {Account.speaker.speakerLocation}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerCredentials'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerCredentials'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerCredentialsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerCredentials}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerCredentialsLabel')}
            >
              {Account.speaker.speakerCredentials}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerBigTags'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerBigTags'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerBigTagsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerBigTags}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerBigTagsLabel')}
            >
              {Account.speaker.speakerBigTags}
            </button>
          </li>
          <li>
            <button
              className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
                tapHighlight === 'speakerTinyTags'
                  ? 'highlight-tb-theme--tap'
                  : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlight('speakerTinyTags'),
                    () => setTapHighlight(),
                  ],
                  [() => handleScrollToClick('speakerTinyTagsLabel')]
                )
              }
              tabIndex="0"
              aria-label={`${Account.speaker.speakerTinyTags}.`}
              onKeyDown={(e) => handleKeyDown(e, 'speakerTinyTagsLabel')}
            >
              {Account.speaker.speakerTinyTags}
            </button>
          </li>
        </ul>
      );
    }
  }

  if (settingsAccountSettingsView === 'organizer') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'organizer' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('organizer'), () => setTapHighlight()],
                [() => handleScrollToClick('organizerActivationLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.organizer.organizerActivation}.`}
            onKeyDown={(e) => handleKeyDown(e, 'organizerActivationLabel')}
          >
            {Account.organizer.organizerActivation}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'organizer' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('organizer'), () => setTapHighlight()],
                [() => handleScrollToClick('organizerCurrentPlanLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.organizer.organizerCurrentPlan}.`}
            onKeyDown={(e) => handleKeyDown(e, 'organizerCurrentPlanLabel')}
          >
            {Account.organizer.organizerCurrentPlan}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'organizer' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('organizer'), () => setTapHighlight()],
                [
                  () =>
                    handleScrollToClick(
                      'organizerCommunityDisplayContactLabel'
                    ),
                ]
              )
            }
            tabIndex="0"
            aria-label={`${Account.organizer.communitySettings}.`}
            onKeyDown={(e) =>
              handleKeyDown(e, 'organizerCommunityDisplayContactLabel')
            }
          >
            {Account.organizer.communitySettings}
          </button>
        </li>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'translationRooms') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'newRoomCodeSubscriptions'
                ? 'highlight-tb-theme--tap'
                : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('newRoomCodeSubscriptions'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('newRoomCodeSubscriptionsLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.translationRooms.newRoomCodeSubscriptions}.`}
            onKeyDown={(e) => handleKeyDown(e, 'newRoomCodeSubscriptionsLabel')}
          >
            {Account.translationRooms.newRoomCodeSubscriptions}
          </button>
        </li>
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'translationRooms'
                ? 'highlight-tb-theme--tap'
                : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlight('translationRooms'),
                  () => setTapHighlight(),
                ],
                [() => handleScrollToClick('translationRoomsLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.translationRooms.roomCodeManager}.`}
            onKeyDown={(e) => handleKeyDown(e, 'translationRoomsLabel')}
          >
            {Account.translationRooms.roomCodeManager}
          </button>
        </li>
      </ul>
    );
  }

  if (settingsAccountSettingsView === 'help') {
    subselections = (
      <ul className="sub-selections">
        <li>
          <button
            className={`secondary-heading highlight-btreg-theme access-ob access-o6 ${
              tapHighlight === 'help' ? 'highlight-tb-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [() => setTapHighlight('help'), () => setTapHighlight()],
                [() => handleScrollToClick('helpLabel')]
              )
            }
            tabIndex="0"
            aria-label={`${Account.attendee.bigTags}.`}
            onKeyDown={(e) => handleKeyDown(e, 'helpLabel')}
          >
            Test
          </button>
        </li>
      </ul>
    );
  }

  return subselections;
};

{
  /* <li>
          <button
            className={`secondary-heading  ${tapHighlight === '' && 'highlight-tb-theme--tap'}`}
            onClick={() => handleScrollToClick('installWebAppLabel')}
            tabIndex="0"
            aria-label={`${Account.account.installWebApp}.`}
            onKeyDown={(e) => handleKeyDown(e, 'installWebAppLabel')}
          >
            {Account.account.installWebApp}
          </button>
        </li> */
}
