import { useEffect, useState } from 'react';

//hooks
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//components
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utils

const SpeakerCommunitySettings = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { SpeakerCommunitySettings } = useLanguageAccount();
  const {
    accountSpeakerSignUpComplete,
    accountSpeakerCommunitySettingsAttendeeFollowRequests,
    handleAccountSpeakerSetCommunitySettingsAttendeeFollowRequests,
  } = useAccountReduxHandlers();

  //state
  const [attendeeFollowRequests, setAttendeeFollowRequests] = useState(
    accountSpeakerCommunitySettingsAttendeeFollowRequests
  );
  const [saveValid, setSaveValid] = useState(false);

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      if (
        attendeeFollowRequests !==
        accountSpeakerCommunitySettingsAttendeeFollowRequests
      ) {
        setSaveValid(true);
      } else {
        setSaveValid(false);
      }
    }
  }, [
    accountSpeakerSignUpComplete,
    attendeeFollowRequests,
    accountSpeakerCommunitySettingsAttendeeFollowRequests,
  ]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="speakerCommunityLabel"
      >
        {SpeakerCommunitySettings.communitySettings}
      </label>
      <div className="h12" />
      <p className="banners__notice align-center full-width fs16" tabIndex="0">
        {SpeakerCommunitySettings.note}
      </p>
      <div className="h24" />
      <p className="description-text" tabIndex="0">
        {SpeakerCommunitySettings.description}
      </p>

      <ButtonSwitch
        id={'speakerCommunityAttendeeToggle'}
        loadValue={accountSpeakerCommunitySettingsAttendeeFollowRequests}
        loadTrigger={true}
        handleSwitch={
          accountSpeakerSignUpComplete
            ? setAttendeeFollowRequests
            : handleAccountSpeakerSetCommunitySettingsAttendeeFollowRequests
        }
        reset={accountSpeakerCommunitySettingsAttendeeFollowRequests}
        customWrapper={'mrg-t12'}
        horizontal={true}
        title={SpeakerCommunitySettings.allowFollowers}
      />
      <div className="h24" />
      <SaveButton
        saveValid={saveValid}
        handler={() =>
          saveAccountChange(
            {
              communitySettings: {
                attendeeFollowRequests,
              },
            },
            'speakerCommunityAttendeeFollow'
          )
        }
        isLoading={loadingSaveBtnUI === 'speakerCommunityAttendeeFollow'}
        saveAriaLanguage={SpeakerCommunitySettings.communitySettings}
      />
      <div className="h36" />
    </div>
  );
};

export default SpeakerCommunitySettings;
