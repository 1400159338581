//hooks
import useGenericModal from '../../../../../context/useGenericModal';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import ProfilePersonalModal from '../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
import { preview } from '../../../../../assets/icons';

const ProfilePreview = ({ previewMode, speakerPreview }) => {
  const { setGenericModal } = useGenericModal();
  const { accountPersonal, accountAppSettings, accountSpeaker, accountId } =
    useAccountReduxHandlers();
  const { ProfilePreview } = useLanguageAccount();

  //state

  function handleProfilePreview() {
    let personalAccount = {
      _id: accountId,
      personal: { ...accountPersonal },
      appSettings: { ...accountAppSettings },
      speaker: { ...accountSpeaker },
    };

    if (speakerPreview) {
      personalAccount.speaker.speakerModeActive = true;
    }

    setGenericModal(
      <ProfilePersonalModal
        closeModalFocusId={'previewPersonalProfileBtn'}
        profileData={personalAccount}
        previewMode={previewMode}
      />
    );
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="profilePreviewLabel"
        tabIndex="0"
      >
        {ProfilePreview.profilePreview}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {speakerPreview
          ? ProfilePreview.speakerPreviewDescription
          : ProfilePreview.description}
      </p>
      <div className="h24" />

      <ItemButton
        handler={handleProfilePreview}
        text={ProfilePreview.preview}
        iconOn={true}
        icon={preview}
        fullWidth={true}
        id={'previewPersonalProfileBtn'}
      />

      <div className="h36" />
    </div>
  );
};

export default ProfilePreview;
