import { useState, useEffect, useRef } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility
import handleInputClick from './handleInputClick';

const TextArea = ({
  ariaText,
  asterisk,
  customWidth,
  disableEnter,
  customMinHeight,
  handleChange,
  hideWordCount,
  id,
  labelText,
  large,
  medium,
  limit,
  loadTrigger,
  loadValue,
  onBlurHandlerOff,
  placeHolder,
  refTrigger,
  reload,
  tabIndex,
  customWrapper,
  customKeyDownHandler,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { dismissKeypad } = useSettings();

  //Component State
  const [initialLoadValue, setInitialLoadValue] = useState();
  const [inputText, setInputText] = useState();
  const [charCount, setCharCount] = useState(0);
  const [reRender, setReRender] = useState(false);

  //UI state
  const [customStyle, setCustomStyle] = useState();
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);
  const [shakeCharLimit, setShakeCharLimit] = useState(false);

  //Component variables
  const textarea = useRef();
  const wrapperEl = useRef();
  const effectRan = useRef(false);
  const adjustInputRan = useRef(false);

  let charCounter;

  //Initialize functions
  useEffect(() => {
    let newStyle = {};
    if (customWidth) {
      newStyle.width = `${customWidth}`;
    }

    if (medium) {
      newStyle.minHeight = '146px';
    }
    if (large) {
      newStyle.minHeight = '196px';
    }
    setCustomStyle(newStyle);
  }, [customWidth, medium, large]);

  useEffect(() => {
    if (textarea?.current && wrapperEl.current) {
      if (adjustInputRan.current === false) {
        const animationTimer = setTimeout(() => {
          if (loadValue?.length > 0) {
            if (textarea?.current && !customMinHeight) {
              textarea.current.style.height = 'auto';

              let scrollHeight = textarea.current.scrollHeight;

              if (medium && scrollHeight < 146) {
                textarea.current.style.height = `${scrollHeight}px`;
              } else if (large && scrollHeight < 196) {
                textarea.current.style.height = `${scrollHeight}px`;
              } else {
                textarea.current.style.height = `${scrollHeight}px`;
              }
            }
          }

          setReRender(!reRender);
        }, 100);

        return () => clearTimeout(animationTimer);
      }
    }
  }, [textarea?.current, wrapperEl.current]);

  useEffect(() => {
    if (tabIndex) {
      textarea.current.setAttribute('tabIndex', tabIndex);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (refTrigger) {
      textarea.current.focus();
    }
  }, [refTrigger]);

  useEffect(() => {
    if (loadTrigger && loadValue) {
      if (effectRan.current === false) {
        handleInput(loadValue);
      }
      effectRan.current = true;
    }
  }, [loadTrigger, loadValue]);

  //UI Functions
  useEffect(() => {
    if (charCount === limit && focusHighlight) {
      setShakeCharLimit(true);
    }
    if (!focusHighlight || charCount !== limit) {
      setShakeCharLimit(false);
    }
    /*eslint-disable-next-line*/
  }, [charCount, focusHighlight]);

  useEffect(() => {
    if (!inputText && charCount > 0) {
      setCharCount(0);
    }
  }, [inputText]); //will reset charCount if input value is wiped by external function

  //Component Functions
  function handleInput(text) {
    setInputText(text);
    setCharCount(text.length);

    if (textarea?.current && !customMinHeight) {
      textarea.current.style.height = 'auto';
      let scrollHeight = textarea.current.scrollHeight;
      textarea.current.style.height = `${scrollHeight}px`;
    }
  }

  function handleKeyDown(e) {
    if (disableEnter) {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
      if (customKeyDownHandler) {
        customKeyDownHandler(e);
      }
      dismissKeypad(e);
    }
  }

  useEffect(() => {
    if (reload) {
      setInitialLoadValue(loadValue || '');
      setInputText(loadValue || '');
    }
  }, [reload]);

  useEffect(() => {
    if (loadValue !== initialLoadValue && reload) {
      setInitialLoadValue(loadValue);
      setInputText(loadValue);
    }
  }, [loadValue]);

  //JSX conditional
  if (focusHighlight && !hideWordCount) {
    charCounter = (
      <p
        className={`charCounter ${charCount === limit ? 'maximum' : ''} ${
          shakeCharLimit ? 'shake' : ''
        }`}
      >
        {charCount}/{limit}
      </p>
    );
  } //add word limit when close, shake on reaching max

  if (hideWordCount && charCount && focusHighlight && charCount >= limit) {
    charCounter = (
      <p
        className={`charCounter ${charCount === limit ? 'maximum' : ''} ${
          shakeCharLimit ? 'shake' : ''
        }`}
      >
        {charCount}/{limit}
      </p>
    );
  }

  return (
    <div
      className={`textarea__wrapper ${
        focusHighlight ? 'textarea__wrapper--focus' : ''
      } ${customWrapper ? customWrapper : ''}`}
      style={customStyle}
      onMouseEnter={() => setBorderHighlight(true)}
      onMouseLeave={() => setBorderHighlight(false)}
      onClick={() => handleInputClick(textarea.current)}
      ref={wrapperEl}
    >
      <span
        className="textarea-label no-select"
        onClick={() => handleInputClick(textarea.current)}
      >
        <p>
          {labelText}&nbsp;
          {asterisk && <span className="highlight">*</span>}
        </p>
        {charCounter}
      </span>
      <textarea
        id={id}
        rows={1}
        onInput={(e) => handleInput(e.target.value)}
        name={id}
        type="text"
        className={`textarea `}
        value={inputText}
        maxLength={`${limit}`}
        onFocus={() => {
          setFocusHighlight(true);
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setInputText(e.target.value);
          setCharCount(e.target.value.length);
          if (onBlurHandlerOff) {
            handleChange(e.target.value, id);
          }
        }}
        onBlur={(e) => {
          setFocusHighlight(false);
          if (!onBlurHandlerOff) {
            handleChange(e.target.value, id);
          }
        }}
        ref={textarea}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeHolder ? placeHolder : null}
        tabIndex="0"
        aria-label={
          asterisk
            ? `${id}. ${Generic.fieldIsRequired}. ${
                limit ? Generic.characterLimit + limit + '.' : ''
              } ${
                charCount > 0
                  ? Generic.currentTextCharacters + charCount + '.'
                  : ''
              } ${ariaText ? ariaText : ''}`
            : `${id}. ${limit ? Generic.characterLimit + limit + '.' : ''} ${
                ariaText ? ariaText : ''
              }`
        }
        enterKeyHint={`${disableEnter ? 'done' : 'enter'}`}
      />
    </div>
  );
};

export default TextArea;
