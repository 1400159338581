import { useState, useEffect, useRef } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//Utility
import useLanguageData from '../../../../../../language/useLanguageData';
import { img_location } from '../../../../../../assets/icons';

const EventSearchOnline = ({
  Account,
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const { CountriesOpts } = useLanguageData();
  const {
    accountEventSearchOnlineCountries: loadEventSearchOnlineCountries,
    accountAttendee,
  } = useAccountReduxHandlers();

  //Component state
  const [eventSearchOnlineCountries, setEventSearchOnlineCountries] = useState(
    loadEventSearchOnlineCountries
  );
  const [
    eventSearchOnlineCountriesSaveValid,
    setEventSearchOnlineCountriesSaveValid,
  ] = useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (effectRan.current === false) {
      setEventSearchOnlineCountries(loadEventSearchOnlineCountries);

      effectRan.current = true;
    }
  }, [loadEventSearchOnlineCountries]);

  //Component functions

  useEffect(() => {
    const sameContents =
      eventSearchOnlineCountries.length ===
        loadEventSearchOnlineCountries.length &&
      eventSearchOnlineCountries.every(
        (value, index) => value === loadEventSearchOnlineCountries[index]
      );
    if (!sameContents && !eventSearchOnlineCountries.includes('select')) {
      setEventSearchOnlineCountriesSaveValid(true);
    } else {
      setEventSearchOnlineCountriesSaveValid(false);
    }
  }, [eventSearchOnlineCountries, loadEventSearchOnlineCountries]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="eventSearchOnlineLabel"
      >
        {Account.attendee.eventSearchOnline}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.eventSearchOnlineDescription}
      </p>

      <div className="h24" />
      <SelectionsModal
        addButtonText={Account.attendee.addCountries}
        DataOpts={CountriesOpts}
        fullWidth={true}
        iconModal={img_location}
        iconModalClass={'filter-theme'}
        iconOff={true}
        modalTitle={Account.attendee.eventSearchOnline}
        selectionState={eventSearchOnlineCountries}
        setSelectionState={setEventSearchOnlineCountries}
        subTextOn={false}
        id={'locationCountrySelections'}
        itemButtonCustomWrapper={'item-button-full'}
      />

      <div className="h24" />

      <SaveButton
        saveValid={eventSearchOnlineCountriesSaveValid}
        handler={() =>
          saveAccountChange(
            {
              attendee: {
                ...accountAttendee,
                eventSearchOnlineCountries,
              },
            },
            'eventSearchOnline'
          )
        }
        saveAriaLanguage={Account.attendee.eventSearchOnline}
        isLoading={loadingSaveBtnUI === 'eventSearchOnline'}
      />
      <div className="h36" />
    </div>
  );
};

export default EventSearchOnline;
