import { useState, useRef, useEffect } from 'react';
//Hooks
import useSettings from '../../context/useSettings';
import useApiStatus from '../../context/useApiStatus';

//Components

//Utility

const CheckBox = ({
  visible,
  selectionObj,
  handler,
  loadTrigger,
  loadValue,
  mrgCenter,
  accountId,
  handleAccountId,
  maxWidth,
  reload,
  customWrapper,
  labelOff,
  temporaryDisable,
  temporaryDisableMessage,
  id,
  secondaryTrigger,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();

  //Component state
  const [val, setVal] = useState(false);

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  //Component variables
  const effectRan = useRef(false);
  let content;

  //Initialize
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          setVal(loadValue);
        }
        effectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  useEffect(() => {
    if (secondaryTrigger) {
      setVal(!val);
      handler(Object.keys(selectionObj), !val, accountId);
    }
    /*eslint-disable-next-line*/
  }, [secondaryTrigger]);

  useEffect(() => {
    if (reload) {
      setVal(loadValue);
    }
  }, [loadValue, reload]);

  //Component functions
  function handleCheckBox(selection) {
    if (temporaryDisable) {
      return handleWarning(
        {
          message: temporaryDisableMessage,
          statusCode: 500,
        },
        `Checkbox.js/${id}`
      );
    }

    handleMobileTap(
      [() => setTapHighlight(true), () => setTapHighlight(false)],
      [
        () => {
          if (!handleAccountId) {
            setVal(!val);
            handler(selection, !val);
          } else {
            setVal(!val);
            handler(selection, !val, accountId);
          }
        },
      ]
    );
  }

  function handleKeyDownCheckBox(e, selection) {
    if (temporaryDisable) {
      return handleWarning(
        {
          message: temporaryDisableMessage,
          statusCode: 500,
        },
        `Checkbox.js/${id}`
      );
    }
    if (e.key === 'Enter' || e.key === ' ') {
      handleCheckBox(selection);
    }
  }

  //JSX conditional
  if (visible) {
    content = (
      <div
        className={`checkbox__container highlight-it-theme fs16 fwsb ${
          tapHighlight ? 'highlight-it-theme--tap' : ''
        } ${mrgCenter ? 'mrg-auto-lr' : ''} ${
          customWrapper ? customWrapper : ''
        } ${temporaryDisable ? 'opacity-3' : ''}`}
      >
        <input
          type="checkbox"
          id={`checkbox-${Object.keys(selectionObj)}`}
          checked={val}
          aria-checked={val}
          tabIndex="0"
          onKeyDown={(e) => handleKeyDownCheckBox(e, Object.keys(selectionObj))}
          aria-label={Object.values(selectionObj)}
          className={`${
            tapHighlight ? 'highlight-o-theme--tap' : 'highlight-o-theme'
          }`}
          onChange={() => handleCheckBox(Object.keys(selectionObj))}
        />
        {!labelOff && (
          <label
            htmlFor={`checkbox-${Object.keys(selectionObj)}`}
            className={`no-select`}
            onClick={(e) => e.stopPropagation()}
            style={maxWidth ? { maxWidth: `${maxWidth}` } : null}
          >
            {Object.values(selectionObj)}
          </label>
        )}
      </div>
    );
  }

  return content;
};

export default CheckBox;
