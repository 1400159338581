//note, imageType should match bucket name.

const getDBImageBlob = async (imagePath, imageType, setImage) => {
  //variables
  let fullImagePath;

  if (
    imagePath === 'myndfull_account_image.png' ||
    imagePath === 'myndfull_event_image.png' ||
    !imagePath
  ) {
    return null;
  }

  fullImagePath = `https://storage.googleapis.com/myndfull_${imageType}_images${
    process.env.REACT_APP_ENV === 'development' ? '_development' : ''
  }/${imagePath}`;

  try {
    let response = await fetch(fullImagePath);

    if (!response) return null;

    if (response.status !== 200) {
      throw new Error();
    }

    const blob = await response.blob();
    const urlBlob = URL.createObjectURL(blob);

    return setImage({
      imagePreview: urlBlob,
      croppedImage: '',
    });
  } catch (error) {
    return false;
  }
};

export default getDBImageBlob;
