//hooks
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import { return_curved_arrow, trash } from '../../../../../assets/icons';

const ParticipantActionBtns = ({
  participant,
  handleSaveAndReturn,
  handleRemoveParticipant,
}) => {
  //hooks
  const { EventParticipant } = useLanguageOrganize();

  return (
    <div className="flex-row full-width space-evenly">
      <ButtonTextSimple
        handler={() => handleSaveAndReturn({ ...participant })}
        icon={return_curved_arrow}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={EventParticipant.saveAndReturn}
        id={'returnButton'}
        customWrapper={'mrg-auto-lr text-gray highlight-it-theme'}
      />
      <ButtonTextSimple
        handler={() => {
          handleRemoveParticipant(participant?.id);
          ScrollTo('participantsLabel', 'smooth', false, 'participantsLabel');
        }}
        icon={trash}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={EventParticipant.removeParticipant}
        id={'removeButton'}
        customWrapper={'mrg-auto-lr text-gray highlight-it-theme'}
      />
    </div>
  );
};

export default ParticipantActionBtns;
