import { useEffect, useState } from 'react';

//Components
import { SubAccountMenus } from './SubAccountMenus';

//Utility
export const AccountSubMenuNav = ({ windowSettingsSize, submenuOpened }) => {
  //hooks
  //Component state
  const [progressOffset, setProgressOffset] = useState();
  const [progressBarHeightInPercentage, setProgressBarHeightInPercentage] =
    useState(0);
  const [contentEl, setContentEl] = useState();

  //Component variables
  const progressTrack = document.getElementById('progress-track');
  const progressBar = document.getElementById('progress-bar-fill');

  //Initialize & UI functions
  useEffect(() => {
    const contentElement = document.getElementById('account-content');
    setContentEl(contentElement);
  }, []);

  useEffect(() => {
    if (contentEl) {
      handleSetProgressBarSize();
    }
    /*eslint-disable-next-line*/
  }, [contentEl]);

  useEffect(() => {
    handleProgressTrack();
    /*eslint-disable-next-line*/
  }, [windowSettingsSize]);

  window.addEventListener('scroll', function () {
    handleProgressTrack();
  });

  //Component functions
  function handleSetProgressBarSize() {
    const contentHeight = contentEl.offsetHeight;
    const viewportHeight = window.innerHeight;
    const accountContentAsPercOfScreen = Math.round(
      (viewportHeight / contentHeight) * 100
    );
    setProgressBarHeightInPercentage(accountContentAsPercOfScreen);
  }

  function handleProgressTrack() {
    if (progressTrack && progressBarHeightInPercentage) {
      const progressTrackHeightInPixels = progressTrack.offsetHeight;
      const progressBarHeightInPixels = progressBar.offsetHeight;
      const totalProgressTrackOffset =
        progressTrackHeightInPixels - progressBarHeightInPixels;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = Math.round((scrollTop / scrollHeight) * 100);

      const progressTrackAdjustment =
        (scrolled * totalProgressTrackOffset) / 100;
      setProgressOffset(progressTrackAdjustment);
    }
  }

  return (
    <>
      {submenuOpened && (
        <div className="sub-selection__container" id="sub-selection__container">
          <SubAccountMenus />
          <div
            className="progress-track"
            id="progress-track"
            aria-hidden="true"
          >
            <div
              className="progress-bar-fill"
              id="progress-bar-fill"
              style={
                progressBarHeightInPercentage
                  ? {
                      height: `${progressBarHeightInPercentage}%`,
                      marginTop: `${progressOffset}px`,
                    }
                  : null
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
