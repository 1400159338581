import { useEffect, useState } from 'react';
import { useCreateEventReduxHandlers } from '../create/useCreateEventHandlers';
import { useRemoveDraftEventMutation } from '../draftEventApiSlice';

//Hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useApiStatus from '../../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useOrganizeHandlers } from '../useOrganizeHandlers';
import useDelayedHander from '../../../../hooks/useDelayedHandler';
import { useEventParticipantsHandlers } from '../eventParticipants/useEventParticipantsHandlers';
import {
  useUpdateInviteContactParticipantDraftEventsFromDraftRemovalMutation,
  useEventDraftRemovalNoticesUpdateForInviteContactParticipantsMutation,
} from '../eventParticipants/eventParticipantsApiSlice';

//Components
import SelectionList from '../../../../components/SelectionList/SelectionList';
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import TextArea from '../../../../components/TextArea/TextArea';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';

//Utility
import {
  img_create,
  img_empty_box,
  img_load,
  img_restore,
  save,
} from '../../../../assets/icons';
import { DateFromTimeStamp } from '../../../../utils/dateTime/DateCalc';

const Initialize = ({
  setInitialize,
  handleSubmitEventDraft,
  initialize,
  setOrganizeIsLoading,
  participants,
  setResetCroppedImages,
}) => {
  //Hooks
  const { Generic, IconAlts } = useLanguageComponents();
  const { CreateEvent } = useLanguageOrganize();
  const { handleError, handleSuccess, handleWarning } = useApiStatus();
  const { delayedHandler } = useDelayedHander();
  const {
    handleCreateEventReturnEventData,
    handleCreateEventReset,
    handleCreateEventSetEvent,
    handleCreateEventSetSaveName,
    lastModified,
    title,
    saveName,
    createEventId,
    createEventEventReference,
  } = useCreateEventReduxHandlers();
  const { accountContentLanguage: locale, accountId } =
    useAccountReduxHandlers();
  const { organizeMyDraftEvents } = useOrganizeHandlers();
  const {
    eventDataHasAcceptedInviteContactParticipants,
    eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts,
    handleResetParticipantInvitesAndTranslations,
  } = useEventParticipantsHandlers();

  //API
  const [removeDraft] = useRemoveDraftEventMutation();
  const [updateInviteContactParticipantDraftEventsFromDraftRemoval] =
    useUpdateInviteContactParticipantDraftEventsFromDraftRemovalMutation();

  const [eventDraftRemovalNoticesUpdateForInviteContactParticipants] =
    useEventDraftRemovalNoticesUpdateForInviteContactParticipantsMutation();

  //Component state
  const [initialSaveName, setInitialSaveName] = useState();

  const [loadDraft, setLoadDraft] = useState();
  const [deleteDraftData, setDeleteDraftData] = useState();
  const [selectionBtn, setSelectionBtn] = useState('');

  //ui
  const [renderQuickSaveButton, setRenderQuickSaveButton] = useState(false);
  const [participantInviteResetWarning, setParticipantInviteResetWarning] =
    useState(false);

  //Component variables
  let draftEventsList;

  const isMatch = organizeMyDraftEvents.some(
    (obj) => obj.meta.saveName === saveName
  );

  const lastModifiedConverted = DateFromTimeStamp(
    lastModified,
    {
      weekday: 'off',
    },
    locale
  );

  //UI
  useEffect(() => {
    if (
      lastModified &&
      createEventId &&
      saveName &&
      selectionBtn !== 'saveAs'
    ) {
      //handle case where the user had a save file but deleted it
      const draftSaveExists = organizeMyDraftEvents.some(
        (draft) =>
          draft?._id === createEventId && draft?.meta?.saveName === saveName
      );
      setRenderQuickSaveButton(draftSaveExists);
    } else {
      setRenderQuickSaveButton(false);
    }
  }, [lastModified, createEventId, saveName, selectionBtn]);

  //API functions
  async function draftSave() {
    setSelectionBtn();

    setOrganizeIsLoading(true);
    let createEventSliceData = handleCreateEventReturnEventData();
    let draftData = JSON.parse(JSON.stringify(createEventSliceData));

    //ensure save name is set from the doc data not create event slice
    organizeMyDraftEvents.map((obj) => {
      if (obj?.meta?.eventReference === createEventEventReference) {
        draftData.meta.saveName = obj.meta.saveName;
      }
    });

    await handleSubmitEventDraft({
      eventData: draftData,
      handleType: 'updateDraft',
      exit: false,
    });
  }

  async function draftSaveAs(exit) {
    let draftData = handleCreateEventReturnEventData();

    //determine if this doc is overwriting another doc via save name
    const currentEventDataId = draftData?.meta?._id?.toString();
    let overwriteIdentified;

    organizeMyDraftEvents.map((obj) => {
      if (draftData?.meta?.saveName === obj?.meta?.saveName) {
        if (obj?._id?.toString() !== currentEventDataId) {
          overwriteIdentified = obj?._id;
        }
      }
    });

    if (overwriteIdentified) {
      return handleWarning({
        message: 'cannotOverwriteFile',
        origin: 'Initialize.js/draftSaveAs',
        id: Date.now(),
      });
    }

    if (organizeMyDraftEvents?.length >= 5) {
      return handleWarning({
        message: 'draftLimit',
        origin: 'Initialize.js/draftSaveAs',
        id: Date.now(),
      });
    }

    //determine whether to reset dispatches
    if (initialSaveName !== saveName) {
      draftData = await handleResetParticipantInvitesAndTranslations(draftData);
    }

    setOrganizeIsLoading(true);
    await handleSubmitEventDraft({
      eventData: draftData,
      handleType: isMatch ? 'updateDraft' : 'createDraft',
      exit,
    });

    setSelectionBtn();
    setInitialSaveName(saveName);
  }

  async function deleteDraft(accountId, draftId) {
    try {
      const draftParticipants = deleteDraftData?.participants;

      const hasAcceptedInviteContactParticipants =
        eventDataHasAcceptedInviteContactParticipants(
          deleteDraftData?.participants
        );

      if (hasAcceptedInviteContactParticipants) {
        //remove draft from accountEvent.participantDrafts
        await updateInviteContactParticipantDraftEventsFromDraftRemoval({
          accountId,
          draftId,
          draftParticipants,
        });
      }
      const hasAcceptedOrPendingDispatches =
        eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts(
          deleteDraftData?.participants
        );

      if (hasAcceptedOrPendingDispatches) {
        //remove pending notices for participants +notify accepted participants that draft was removed
        await eventDraftRemovalNoticesUpdateForInviteContactParticipants({
          accountId,
          draftId,
        });
      }

      const res = await removeDraft({ accountId, draftId });
      setDeleteDraftData();

      if (!res?.error) {
        handleSuccess({
          message: 'success',
          id: Date.now(),
          origin: 'Initialize.js/deleteDraft',
        });
      }
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'Initialize.js/deleteDraft',
      });
    }
  }

  // functions
  function changeSaveDraftName(v) {
    handleParticipantInviteResetWarning(v);
    handleCreateEventSetSaveName(v);
  }

  function handleParticipantInviteResetWarning(selectedSaveName) {
    if (selectedSaveName?.length > 0 && participants?.length > 0) {
      let resetParticipantInvitesWarningValid = false;

      if (selectedSaveName !== initialSaveName) {
        participants?.map((p) => {
          if (
            p.organizedAs === 'inviteContact' ||
            p.organizedAs === 'emailInvitation'
          ) {
            if (p?.dispatches?.inviteDispatched === true) {
              resetParticipantInvitesWarningValid = true;
            }
          }
        });
        return setParticipantInviteResetWarning(
          resetParticipantInvitesWarningValid
        );
      } else {
        return setParticipantInviteResetWarning(false);
      }
    } else {
      return setParticipantInviteResetWarning(false);
    }
  } //check if participant invites have been dispatched; used to ensure a new draft or overwrite will clear dispatched status and new invites will be created

  function handleLoadDraftConfirm() {
    setResetCroppedImages(true);
    handleCreateEventReset();
    setOrganizeIsLoading(true);
    handleCreateEventSetEvent(loadDraft);
    setSelectionBtn();
    setInitialize(true);
    setInitialSaveName(loadDraft?.meta?.saveName);
    setLoadDraft();
    setDeleteDraftData();
  }

  function handleMetaBtnSelection(selection) {
    if (selection === 'saveAs') {
      if (selectionBtn === 'saveAs') {
        //need to reset the saveName when leaving saveAs
        handleCreateEventSetSaveName(initialSaveName);
      } else {
        handleParticipantInviteResetWarning('');
        handleCreateEventSetSaveName('');
      }
    }

    if (selection === 'load' && selectionBtn !== 'load') {
      handleCreateEventSetSaveName('');
    } else {
      handleCreateEventSetSaveName(initialSaveName);
    } //need to ensure the menu doesnt start with a selection -- selection list uses saveName

    if (selection === selectionBtn) {
      setSelectionBtn();
    } else {
      setSelectionBtn(selection);
      setLoadDraft();
      setDeleteDraftData();
    }
  }

  function handleCreateNewConfirm() {
    handleCreateEventReset();
    setResetCroppedImages(true);
    setOrganizeIsLoading(true);
    setInitialize(true);
    setSelectionBtn();
    setInitialSaveName();
  } //resets components then calls load function

  function cancelSaveDraft() {
    setLoadDraft();
    handleCreateEventSetSaveName('');
  }

  function handleSelectDraft(draft, selectionType) {
    if (selectionType === 'select') {
      if (selectionBtn === 'load') {
        setDeleteDraftData();
        handleCreateEventSetSaveName();
        setLoadDraft(draft);
      } else if (selectionBtn === 'saveAs') {
        handleCreateEventSetSaveName(draft.meta.saveName);
        handleParticipantInviteResetWarning(draft.meta.saveName);
      }
    } else if (selectionType === 'delete') {
      setLoadDraft();
      setDeleteDraftData(draft);
    }
  } //clicking draft entry in table

  function setRestoreData() {
    setOrganizeIsLoading(true);
    setInitialize(true);
    setSelectionBtn();

    organizeMyDraftEvents?.map((ev) => {
      if (ev._id === createEventId) {
        setInitialSaveName(ev.meta?.saveName);
        handleCreateEventSetSaveName(ev.meta?.saveName);
      }
    });
  }

  //JSX conditional
  if (organizeMyDraftEvents?.length > 0) {
    draftEventsList = (
      <SelectionList
        dataList={organizeMyDraftEvents}
        id={'draft'}
        col1Heading={CreateEvent.initialize.draftFiles}
        col1DataPath1={'meta'}
        col1DataPath2={'saveName'}
        col2DataPath1={'meta'}
        col2DataPath2={'lastModified'}
        handler={handleSelectDraft}
        itemSelection={
          loadDraft ? loadDraft : deleteDraftData ? deleteDraftData : null
        }
        ariaText={CreateEvent.initialize}
        deleteSelection={deleteDraftData}
        selectedFile={saveName}
        activeFile={initialSaveName}
      />
    );
  } else {
    draftEventsList = (
      <div
        className="flex-column flex-center access-ob access-o6 fit-content mrg-auto-lr"
        tabIndex="0"
      >
        <img
          src={img_empty_box}
          alt={IconAlts.iconEmptyBox}
          className="mrg-b12 filter-gray"
          style={{ height: '48px' }}
        />
        <p className="fs18 fwsb color-gray">
          {CreateEvent.initialize.noDrafts}
        </p>
      </div>
    );
  } //add reload button?

  return (
    <fieldset className="fieldset" id="fieldset-initialize">
      <legend id="aria-fieldset-instructions-initialize">
        {CreateEvent.initialize.ariaFieldsetInstructions}
      </legend>
      <div className="h0-ph24" />
      <label
        className="label"
        id="initializeLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-initialize"
      >
        {CreateEvent.initialize.initialize}
      </label>

      <div className="flex-column full-width">
        <div
          className={`button-meta__container space-evenly ${
            selectionBtn ? 'mrg-b24' : ''
          }`}
        >
          {lastModified && !initialize && (
            <ButtonMeta
              handleClick={() => handleMetaBtnSelection('restore')}
              btnText={CreateEvent.initialize.restore}
              ariaLabel={CreateEvent.initialize.restore}
              activeStateDependency={selectionBtn === 'restore'}
              icon={img_restore}
              iconAlt={Generic.iconRestore}
            />
          )}
          {initialize && renderQuickSaveButton && (
            <ButtonMeta
              handleClick={() => delayedHandler(draftSave)}
              btnText={CreateEvent.initialize.save}
              ariaLabel={CreateEvent.initialize.save}
              activeStateDependency={selectionBtn === 'save'}
              icon={save}
              iconAlt={Generic.iconSave}
            />
          )}

          {initialize && lastModified && (
            <ButtonMeta
              handleClick={() => handleMetaBtnSelection('saveAs')}
              btnText={CreateEvent.initialize.saveAs}
              ariaLabel={CreateEvent.initialize.saveAs}
              activeStateDependency={selectionBtn === 'saveAs'}
              icon={save}
              iconAlt={Generic.iconSave}
            />
          )}

          <ButtonMeta
            handleClick={() => handleMetaBtnSelection('load')}
            btnText={CreateEvent.initialize.load}
            ariaLabel={CreateEvent.initialize.load}
            activeStateDependency={selectionBtn === 'load'}
            icon={img_load}
            iconAlt={Generic.iconLoad}
          />

          <ButtonMeta
            handleClick={
              !initialize && !lastModified
                ? () => {
                    setOrganizeIsLoading(true);
                    handleCreateNewConfirm();
                  }
                : () => {
                    handleMetaBtnSelection('new');
                  }
            }
            btnText={CreateEvent.initialize.create}
            ariaLabel={CreateEvent.initialize.create}
            activeStateDependency={selectionBtn === 'new'}
            icon={img_create}
            iconAlt={Generic.iconCreate}
          />
        </div>

        {selectionBtn === 'restore' && (
          <>
            <div className="divider mrg-t24" />
            <ConfirmSelection
              text={CreateEvent.initialize.restoreDataMessage}
              text2={`${CreateEvent.initialize.lastModified}: ${lastModifiedConverted}`}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={CreateEvent.initialize.restore}
              btn2Color={'pos'}
              btn2Handler={setRestoreData}
              ignoreMaxWidth={true}
              customWrapper={'mrg-t24'}
            />
          </>
        )}

        {selectionBtn === 'new' && (
          <>
            <div className="divider mrg-t24 mrg-b12" />
            <ConfirmSelection
              text={CreateEvent.initialize.createNewEventAndLoseProgress}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => {
                setSelectionBtn('');
              }}
              btn2Text={CreateEvent.initialize.create}
              btn2Color={'pos'}
              btn2Handler={() => {
                setOrganizeIsLoading(true);
                handleCreateNewConfirm();
              }}
              ignoreMaxWidth={true}
              customWrapper={'mrg-t24'}
            />
          </>
        )}

        {(selectionBtn === 'load' || selectionBtn === 'saveAs') && (
          <>
            <div className="divider mrg-tb24 mrg-b12" />
            {draftEventsList}

            {/*CONFIRMATION BTNS & INFO */}
            {selectionBtn === 'saveAs' && !deleteDraftData && (
              <>
                <ul>
                  {participantInviteResetWarning && (
                    <li
                      className="banners__notice fs16 fwn access-ob access-o6 mrg-t24"
                      tabIndex="0"
                    >
                      {initialSaveName ? (
                        <>
                          <span className="fwsb no-wrap">
                            "{initialSaveName}":
                          </span>
                          &nbsp;
                          {
                            CreateEvent.initialize
                              .participantInvitesResetWarning
                          }
                        </>
                      ) : (
                        <>
                          {/*Occurrs when user restores saved data from a deleted file */}
                          {
                            CreateEvent.initialize
                              .participantInvitesResetWarningWithNoSaveName
                          }
                        </>
                      )}
                    </li>
                  )}
                </ul>
                <div className="h24" />
                <TextArea
                  handleChange={changeSaveDraftName}
                  limit={75}
                  id={'saveAsText'}
                  labelText={CreateEvent.initialize.saveAs}
                  disableEnter={true}
                  defaultInput={title ? title : null}
                  loadValue={saveName}
                  reload={true}
                  onBlurHandlerOff={true}
                />

                {saveName && (
                  <>
                    <div className="h24" />
                    <ConfirmSelection
                      btn1Text={CreateEvent.initialize.cancel}
                      btn1Color={'neutral'}
                      btn1Handler={cancelSaveDraft}
                      btn2Text={CreateEvent.initialize.save}
                      btn2Color={'pos'}
                      btn2Handler={() => draftSaveAs(false)}
                      btn3Text={CreateEvent.initialize.saveAndExit}
                      btn3Color={'pos'}
                      btn3Handler={() => draftSaveAs(true)}
                      ignoreMaxWidth={true}
                    />
                  </>
                )}
              </>
            )}

            {loadDraft && (
              <>
                <ConfirmSelection
                  text={`${CreateEvent.initialize.loadDraftPt1}${loadDraft.meta.saveName}${CreateEvent.initialize.loadDraftPt2}`}
                  btn1Color={'neutral'}
                  btn1Handler={() => {
                    setDeleteDraftData();
                    setLoadDraft();
                  }}
                  btn1Text={CreateEvent.initialize.cancel}
                  btn1Id={'cancelLoadBtn'}
                  btn2Color={'pos'}
                  btn2Handler={() => {
                    handleLoadDraftConfirm();
                    setDeleteDraftData();
                  }}
                  btn2Text={CreateEvent.initialize.load}
                  btn2Id={'confirmLoadBtn'}
                  ignoreMaxWidth={true}
                  customWrapper={'mrg-t24'}
                />
              </>
            )}

            {deleteDraftData && (
              <>
                {selectionBtn === 'saveAs' && <div className="h12" />}
                <ConfirmSelection
                  text={`${CreateEvent.initialize.delete} '${deleteDraftData.meta.saveName}' ?`}
                  btn1Text={CreateEvent.initialize.cancel}
                  btn1Color={'neutral'}
                  btn1Handler={() => {
                    setLoadDraft();
                    setDeleteDraftData();
                  }}
                  btn2Text={CreateEvent.initialize.delete}
                  btn2Color={'neg'}
                  btn2Handler={() => {
                    setLoadDraft();
                    deleteDraft(accountId, deleteDraftData._id);
                  }}
                  // isLoading={removeDraftisLoading}
                  ignoreMaxWidth={true}
                  customWrapper={'mrg-t24'}
                />
              </>
            )}
          </>
        )}
      </div>
    </fieldset>
  );
};

export default Initialize;
