import { useState } from 'react';

//hooks
import useAdminTechnicalHandlers from '../../../technical/useAdminTechnicalHandlers';
import useApiStatus from '../../../../../context/useApiStatus';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import TextArea from '../../../../../components/TextArea/TextArea';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';

//ui
import {
  cancel,
  edit,
  img_archived,
  img_compass,
} from '../../../../../assets/icons';

const InfoCardActionsTechnicalLogArchive = ({ idData, archiveData }) => {
  //hooks
  const {
    handleChangeTechnicalLogArchive,
    handleRemoveTechnicalLogArchive,
    changeTechnicalLogArchiveIsLoading,
    removeTechnicalLogArchiveIsLoading,
  } = useAdminTechnicalHandlers();
  const { handleWarning } = useApiStatus();
  //state
  const [notes, setNotes] = useState();
  const [archivePath, setArchivePath] = useState();
  const [adminActionSelection, setAdminActionSelection] = useState('');

  //ui

  //functions
  async function handleAdminActionSubmit() {
    if (adminActionSelection === 'changePath') {
      if (!archivePath) {
        return handleWarning({
          message: 'requiresArchivePath',
          origin:
            'InfoCardActionsTechnicalLogArchive.js/handleAdminActionSubmit',
          id: Date.now(),
        });
      }
      await handleChangeTechnicalLogArchive(idData, {
        archivePath,
      });
    }

    if (adminActionSelection === 'addNote') {
      if (!notes) {
        return handleWarning({
          message: 'incompleteInformation',
          origin:
            'InfoCardActionsTechnicalLogArchive.js/handleAdminActionSubmit',
          id: Date.now(),
        });
      }
      await handleChangeTechnicalLogArchive(idData, {
        notes,
      });
    }

    if (adminActionSelection === 'delete') {
      await handleRemoveTechnicalLogArchive(idData);
    }

    setAdminActionSelection('');
  }

  return (
    <>
      {changeTechnicalLogArchiveIsLoading ||
      removeTechnicalLogArchiveIsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex-row">
            <div
              className={`support-ticket__actions ${
                adminActionSelection !== 'archive' ? 'mrg-b24' : 'mrg-b48'
              }`}
            >
              <ButtonMeta
                handleClick={() => {
                  if (adminActionSelection === 'changePath') {
                    return setAdminActionSelection('');
                  } else {
                    setAdminActionSelection('changePath');
                  }
                }}
                activeStateDependency={adminActionSelection === 'changePath'}
                btnText={'Change Path'}
                customClassName={'mrg-r24'}
                iconCustomClassName={'filter-grey'}
                icon={img_compass}
              />
              <ButtonMeta
                handleClick={() => {
                  if (adminActionSelection === 'addNote') {
                    return setAdminActionSelection('');
                  } else {
                    setAdminActionSelection('addNote');
                  }
                }}
                activeStateDependency={adminActionSelection === 'addNote'}
                btnText={'Add Note'}
                customClassName={'mrg-r24'}
                icon={edit}
              />
              <ButtonMeta
                handleClick={() => {
                  if (adminActionSelection === 'delete') {
                    return setAdminActionSelection('');
                  } else {
                    setAdminActionSelection('delete');
                  }
                }}
                activeStateDependency={adminActionSelection === 'delete'}
                btnText={'Delete'}
                icon={cancel}
              />
            </div>
          </div>

          {adminActionSelection && (
            <div className="flex-column mrg-b48">
              {adminActionSelection === 'changePath' && (
                <div className="flex-row full-width mrg-tb12">
                  <img
                    src={img_archived}
                    alt="editIcon"
                    className="mrg-r12 filter-gray mrg-auto-bottom"
                    style={{ height: '28px' }}
                  />
                  <TextArea
                    id={'archivePath'}
                    limit={255}
                    hideWordCount={true}
                    labelText={'New Archive Path'}
                    placeHolder={`ex. buildv${process.env.REACT_APP_REDUX_V}/registrationController/validationBug`}
                    handleChange={setArchivePath}
                    loadTrigger={true}
                    loadValue={archiveData?.archivePath}
                    disableEnter={true}
                  />
                </div>
              )}

              {adminActionSelection === 'addNote' && (
                <div className="flex-row full-width mrg-tb12">
                  <img
                    src={edit}
                    alt="editIcon"
                    className="mrg-r12 filter-gray mrg-auto-bottom"
                  />
                  <TextArea
                    id={'reportNotes'}
                    limit={10000}
                    hideWordCount={true}
                    labelText={'Action Notes'}
                    handleChange={setNotes}
                  />
                </div>
              )}

              <ConfirmSelection
                btn1Text={'Cancel'}
                btn1Color={'neutral'}
                btn1Handler={() => setAdminActionSelection('')}
                btn2Text={'Confirm'}
                btn2Color={'pos'}
                btn2Handler={handleAdminActionSubmit}
                ignoreMaxWidth={true}
                customWrapper={'mrg-t24'}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfoCardActionsTechnicalLogArchive;
