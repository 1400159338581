//hooks

//components
import SpeakerCommunitySettings from './sections/SpeakerCommunitySettings';
import SpeakerCredentials from './sections/SpeakerCredentials';
import SpeakerLanguages from './sections/SpeakerLanguages';
import SpeakerLocation from './sections/SpeakerLocation';
import SpeakerRoleToggle from './sections/SpeakerRoleToggle';
import SpeakerTags from './sections/SpeakerTags';
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';
import ProfilePreview from '../ProfilePreview/ProfilePreview';

//utils

const SpeakerManager = ({
  saveAccountSpeakerUpdate,
  speakerLoadingSaveBtnUI,
}) => {
  return (
    <>
      <SpeakerRoleToggle />

      <div className="h36" />
      <SpeakerCommunitySettings
        saveAccountChange={saveAccountSpeakerUpdate}
        loadingSaveBtnUI={speakerLoadingSaveBtnUI}
      />

      <div className="h36" />
      <ProfilePreview speakerPreview={true} previewMode={true} />

      <div className="h36" />
      <SpeakerLanguages
        saveAccountChange={saveAccountSpeakerUpdate}
        loadingSaveBtnUI={speakerLoadingSaveBtnUI}
      />

      <div className="h36" />
      <SpeakerLocation
        saveAccountChange={saveAccountSpeakerUpdate}
        loadingSaveBtnUI={speakerLoadingSaveBtnUI}
      />

      <div className="h36" />
      <SpeakerCredentials
        saveAccountChange={saveAccountSpeakerUpdate}
        loadingSaveBtnUI={speakerLoadingSaveBtnUI}
      />

      <div className="h36" />
      <SpeakerTags
        saveAccountChange={saveAccountSpeakerUpdate}
        loadingSaveBtnUI={speakerLoadingSaveBtnUI}
      />

      <div className="h36" />
      <div className="h36" />
      <ScrollToTop focusId={'speakerToggleLabel'} />
      <div className="h72" />
    </>
  );
};

export default SpeakerManager;
