import { useEffect, useState } from 'react';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

const EventAdditionalImages = ({
  eventData,
  eventAdditionalImagesCropped,
  previewMode,
}) => {
  //for preview mode, need to combine data and cropped image
  const [evAdditionalImages, setEvAdditionalImages] = useState([]);

  useEffect(() => {
    if (eventAdditionalImagesCropped?.length > 0) {
      let newArr = [];

      eventAdditionalImagesCropped?.map((croppedObj, index) => {
        let evImgData;

        if (previewMode) {
          evImgData = eventData?.generic?.eventAdditionalImages?.find(
            (evImgData) => {
              if (evImgData?.id === croppedObj.id) return evImgData;
            }
          );
          evImgData = { ...evImgData, ...croppedObj };
        }

        newArr.push(evImgData);
      });
      setEvAdditionalImages(newArr);
    } else if (eventData?.generic?.eventAdditionalImages?.length > 0) {
      setEvAdditionalImages(eventData?.generic?.eventAdditionalImages);
    }
  }, [eventAdditionalImagesCropped, eventData]);

  return (
    <div className="flex-column">
      {evAdditionalImages?.map((evImgObj, index) => {
        return (
          <div key={`ev-additional-${index}`} className="mrg-b24">
            <LoadBucketImage
              altAriaText={evImgObj?.alt}
              backupPath={'event_img_default.png'}
              containerClassName={'ev-additional-container'}
              croppedImage={evImgObj?.croppedImage?.croppedImage}
              imagePreview={evImgObj?.imagePreview}
              imagePath={evImgObj?.imagePath}
              imgClassName={'ev-additional-image'}
              partialPath={'myndfull_event_additional_images'}
            />
            {evImgObj?.altCaptionToggle === 'displayCaption' &&
              evImgObj?.altCaption?.length > 0 && (
                <p className="fs18 mrg-t12 full-width flex-center">
                  {evImgObj?.altCaption}
                </p>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default EventAdditionalImages;
