//Hooks

//Components
import DragDropList from '../../../../components/DragDropList/DragDropList';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';

//Utility

const Agenda = ({
  initialize,
  CreateEvent,
  skipToId,
  skipToLabel,
  //state
  loadAgendaItems,
  loadAgendaFormat,

  //handlers
  handleSetAgendaFormat,
  handleSetAgendaItems,
}) => {
  return (
    <fieldset className="fieldset" id="fieldset-general">
      <legend id="aria-fieldset-instructions-general">
        {CreateEvent.general.eventAgendaDescription}
      </legend>
      <label
        className="label"
        tabIndex="0"
        id="agendaLabel"
        aria-describedby="aria-fieldset-instructions-general"
      >
        {CreateEvent.general.eventAgenda}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      <DragDropList
        itemLimit={30}
        handleItems={handleSetAgendaItems}
        handleFormat={handleSetAgendaFormat}
        loadTrigger={initialize}
        loadValueItems={loadAgendaItems}
        loadValueFormat={loadAgendaFormat}
        title={CreateEvent.general.agendaItem}
        itemBtnId={'addAgendaItemBtn'}
        autoScrollId={'agendaLabel'}
      />
    </fieldset>
  );
};

export default Agenda;
