import { useEffect, useRef, useState } from 'react';

//hooks
import {
  useGetTranslationRoomCodePasswordMutation,
  useUpdateTranslationRoomsHostPasswordMutation,
  useUpdateTranslationRoomsAttendeePasswordMutation,
  useChangeRoomCodeSubscriptionMutation,
  useRemoveRoomCodeSubscriptionMutation,
  useRestoreRoomCodeSubscriptionMutation,
} from '../../accountApiSlice';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import PasswordInput from '../../../../../components/TextArea/PasswordInput';
import Selections from '../../../../../components/Selections/Selections';
import AddTranslationRoomInput from '../../../../../components/AddTranslationRoomInput/AddTranslationRoomInput';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';

//Utils
import {
  cancel_lightgray,
  checkmark_green,
  dropdown_arrow,
  lock_locked,
} from '../../../../../assets/icons';

const TranslationRoomCard = ({
  handleResetPage,
  translationRoomCode,
  customWrapper,
}) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { TranslationRoomCard } = useLanguageAccount();

  const { RoomCodeManagerActions, RoomCodeManagerActionDescriptions } =
    useLanguageData();

  //API
  const [getTranslationRoomCodePassword] =
    useGetTranslationRoomCodePasswordMutation();
  const [updateTranslationRoomsHostPassword] =
    useUpdateTranslationRoomsHostPasswordMutation();
  const [updateTranslationRoomsAttendeePassword] =
    useUpdateTranslationRoomsAttendeePasswordMutation();
  const [changeRoomCodeSubscription] = useChangeRoomCodeSubscriptionMutation();
  const [removeRoomCodeSubscription] = useRemoveRoomCodeSubscriptionMutation();
  const [restoreRoomCodeSubscription] =
    useRestoreRoomCodeSubscriptionMutation();

  //state
  const [initialTranslationRoomCodeData, setInitialTranslationRoomCodeData] =
    useState();
  const [saveValid, setSaveValid] = useState(false);
  const [updatedRoomCodeManagerActions, setUpdatedRoomCodeManagerActions] =
    useState();

  //host password
  const [newHostPassword, setNewHostPassword] = useState();
  const [validHostPasswordLength, setValidHostPasswordLength] = useState(false);

  //attendee password
  const [newAttendeePassword, setNewAttendeePassword] = useState();
  const [validAttendeePasswordLength, setValidAttendeePasswordLength] =
    useState(false);

  //changeRoomCode
  const [newRoomCode, setNewRoomCode] = useState();
  const [resetNewRoomCode, setResetNewRoomCode] = useState(false);

  //UI
  const [isLoading, setIsLoading] = useState(false); //separate loadin, redux loading flickers
  const [openCard, setOpenCard] = useState(false);
  const [openSelection, setOpenSelection] = useState('');

  //variables
  const effectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (translationRoomCode?.password && accountId) {
      if (effectRan.current === false) {
        effectRan.current = true;
        getDecryptedPassword(translationRoomCode.password);
      }
    }
  }, [accountId, translationRoomCode]);

  useEffect(() => {
    if (accountId && RoomCodeManagerActions) {
      if (translationRoomCode.status === 'active') {
        let newRoomCodeManagerActions = RoomCodeManagerActions;
        delete newRoomCodeManagerActions.restoreSubscription;
        setUpdatedRoomCodeManagerActions(RoomCodeManagerActions);
      } else {
        let newRoomCodeManagerActions = RoomCodeManagerActions;
        delete newRoomCodeManagerActions.remove;
        setUpdatedRoomCodeManagerActions(newRoomCodeManagerActions);
      }
    }
  }, [accountId, translationRoomCode]); // adjust TranslationRoomCard actions

  //UI
  function handleEnterPress(e) {
    if (e.key === 'Enter') {
      handleSave();
    }
  }

  //functions
  useEffect(() => {
    if (newHostPassword?.length < 8) {
      setValidHostPasswordLength(false);
    } else {
      setValidHostPasswordLength(true);
    }
  }, [newHostPassword]); //check length validity of host password

  useEffect(() => {
    if (newAttendeePassword?.length < 1) {
      setValidAttendeePasswordLength(false);
    } else {
      setValidAttendeePasswordLength(true);
    }
  }, [newAttendeePassword]); //check length validity of host password

  useEffect(() => {
    if (openSelection === 'hostPassword') {
      if (
        newHostPassword !== initialTranslationRoomCodeData?.password &&
        validHostPasswordLength
      ) {
        return setSaveValid(true);
      }
    }

    if (openSelection === 'attendeePassword') {
      if (
        newAttendeePassword !==
          initialTranslationRoomCodeData?.defaultAttendeePassword &&
        validAttendeePasswordLength
      ) {
        return setSaveValid(true);
      }

      if (
        newAttendeePassword !==
        initialTranslationRoomCodeData?.defaultAttendeePassword
      ) {
        if (
          !newAttendeePassword &&
          initialTranslationRoomCodeData?.defaultAttendeePassword === undefined
        ) {
          return setSaveValid(false);
        }
        return setSaveValid(true);
      }
    }

    return setSaveValid(false);

    /*eslint-disable-next-line*/
  }, [
    newHostPassword,
    validHostPasswordLength,
    openSelection,
    initialTranslationRoomCodeData,
    newAttendeePassword,
    validAttendeePasswordLength,
  ]); //check save valid

  function handleTranslationCardSelection(selection) {
    if (openSelection === selection) {
      setOpenSelection();
    } else {
      setOpenSelection(selection);
    }
  }

  //add function to also decrypt attendee password if available.
  async function getDecryptedPassword(pwd) {
    if (accountId) {
      const response = await getTranslationRoomCodePassword({
        accountId,
        translationRoomCodePwd: pwd,
      });
      if (response.data?.decryptedPassword) {
        let newRoomCodeData = {
          ...translationRoomCode,
          password: response.data?.decryptedPassword,
          defaultAttendeePassword:
            response.data?.decryptedDefaultAttendeePassword,
        };
        setInitialTranslationRoomCodeData(newRoomCodeData);
      }
    }
  }

  function handleSave() {
    if (openSelection === 'hostPassword' && saveValid) {
      handleUpdateTranslationRoomsHostPassword();
    }
    if (openSelection === 'attendeePassword' && saveValid) {
      handleUpdateTranslationRoomsAttendeePassword();
    }

    if (openSelection === 'changeRoomCode' && saveValid) {
      handleChangeSubscriptionRoomCode();
    }
  }

  async function handleUpdateTranslationRoomsHostPassword() {
    if (!isLoading) {
      try {
        setIsLoading(true);
        let newTranslationRoomsRoomCodeData = {
          ...translationRoomCode,
          password: newHostPassword,
        };

        const response = await updateTranslationRoomsHostPassword({
          accountId,
          newTranslationRoomsRoomCodeData,
        });

        await getDecryptedPassword(response.data?.encryptedPassword);
        setSaveValid(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error(
            `DEV/CLIENT, handleUpdateTranslationRoomsHostPassword:`,
            error
          );
        }
      }

      setIsLoading(false);
    }
  }

  async function handleUpdateTranslationRoomsAttendeePassword() {
    if (!isLoading) {
      try {
        setIsLoading(true);
        let newTranslationRoomsRoomCodeData = {
          ...translationRoomCode,
          defaultAttendeePassword: newAttendeePassword,
        };
        const response = await updateTranslationRoomsAttendeePassword({
          accountId,
          newTranslationRoomsRoomCodeData,
        });

        await getDecryptedPassword(response.data?.encryptedPassword);

        setSaveValid(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error(
            `DEV/CLIENT, handleUpdateTranslationRoomsAttendeePassword:`,
            error
          );
        }
      }
      setIsLoading(false);
    }
  }

  async function handleChangeSubscriptionRoomCode() {
    if (!isLoading && newRoomCode?.length > 0) {
      try {
        setIsLoading(true);

        let newRoomCodeFormatted = newRoomCode.toUpperCase();

        await changeRoomCodeSubscription({
          accountId,
          newRoomCode: newRoomCodeFormatted,
          oldRoomCode: initialTranslationRoomCodeData.roomCode,
        });

        setSaveValid(false);
        setInitialTranslationRoomCodeData({
          ...initialTranslationRoomCodeData,
          roomCode: newRoomCodeFormatted,
        });
        setResetNewRoomCode(!resetNewRoomCode);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error(`DEV/CLIENT, handleChangeRoomCode`, error);
        }
      }
      handleResetPage();
      setIsLoading(false);
    }
  }

  async function handleRemoveRoomCode() {
    if (!isLoading) {
      try {
        setIsLoading(true);
        await removeRoomCodeSubscription({
          accountId,
          roomCode: initialTranslationRoomCodeData.roomCode,
        });
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error(`DEV: handleRemoveRoomCode`, error);
        }
      }

      setOpenSelection();
      setIsLoading(false);
      handleResetPage();
    }
  }

  async function handleRestoreRoomCode() {
    if (!isLoading) {
      try {
        setIsLoading(true);

        await restoreRoomCodeSubscription({
          accountId,
          roomCode: initialTranslationRoomCodeData.roomCode,
        });
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error(`DEV: handleRemoveRoomCode`, error);
        }
      }
      setOpenSelection();
      setIsLoading(false);
      handleResetPage();
    }
  }

  return (
    <div
      className={`account-card__container ${
        customWrapper ? customWrapper : ''
      }`}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {translationRoomCode?.status !== 'locked' ? (
            <button
              className={`flex-column highlight-i-gt align-center full-width ${
                openCard ? 'mrg-b12' : ''
              }`}
              onClick={() => {
                setOpenCard(!openCard);
                setNewRoomCode();
                setNewHostPassword();
                setValidHostPasswordLength(false);
                setNewAttendeePassword();
                setValidAttendeePasswordLength(false);
                setOpenSelection();
              }}
            >
              <div className="flex-row full-width space-between">
                <div className="flex-row align-center">
                  <p className="fs18 fwsb">{TranslationRoomCard.roomCode}:</p>
                  &nbsp;
                  <p className="fs21 fwn">{translationRoomCode.roomCode}</p>
                </div>

                <img
                  src={dropdown_arrow}
                  alt="arrow"
                  className={`dropdown-arrow ${
                    openCard ? 'reverse-vertical' : ''
                  }`}
                />
              </div>
              {translationRoomCode?.isEnding && (
                <p className="fs14 color-red mrg-t12">
                  {TranslationRoomCard.subscriptionEnding}
                </p>
              )}
            </button>
          ) : (
            <div className={`flex-column  align-center full-width`}>
              <div className="flex-row full-width space-between">
                <div className="flex-row align-center">
                  <p className="fs18 fwsb">{TranslationRoomCard.roomCode}:</p>
                  &nbsp;
                  <p className="fs21 fwn">{translationRoomCode.roomCode}</p>
                </div>

                <img
                  src={lock_locked}
                  alt="arrow"
                  style={{ height: '24px' }}
                  className="filter-gray mrg-auto-bottom mrg-t6"
                />
              </div>
              {translationRoomCode?.isEnding && (
                <p className="fs14 color-red mrg-t12">
                  {TranslationRoomCard.subscriptionEnding}
                </p>
              )}
            </div>
          )}

          {translationRoomCode?.status === 'locked' && (
            <div
              className={`banners__notice align-center full-width ${
                openCard ? 'mrg-b24' : 'mrg-t12'
              }`}
            >
              <p className="fs18 mrg-l12">
                {TranslationRoomCard.roomCodeLocked}
              </p>
            </div>
          )}
          {openCard && (
            <Selections
              id={'roomCodeManager'}
              options={updatedRoomCodeManagerActions}
              handleSelection={handleTranslationCardSelection}
              whatsThis={true}
              viewVertical={true}
              whatsThisDescriptions={RoomCodeManagerActionDescriptions}
              loadValue={openSelection}
              loadTrigger={updatedRoomCodeManagerActions}
            />
          )}

          {openSelection === 'hostPassword' && (
            <>
              <div className="full-width divider-dotted mrg-tb24" />

              <PasswordInput
                defaultValue={initialTranslationRoomCodeData?.password}
                handleInput={setNewHostPassword}
                preventDefaultOff={true}
                id={'passwordInputHost'}
                customTitle={TranslationRoomCard.hostPassword}
                customKeyDownHandler={handleEnterPress}
              />

              <ul
                className="inputfield-validation-list mrg-t12 access-ob access-o6"
                tabIndex="0"
                aria-label={`${
                  validHostPasswordLength
                    ? TranslationRoomCard.ariaValidLengthSuccess
                    : TranslationRoomCard.ariaValidLengthFail
                } `}
              >
                <li className="flex-row">
                  {validHostPasswordLength ? (
                    <img
                      src={checkmark_green}
                      alt={TranslationRoomCard.valid}
                      aria-label="hidden"
                    />
                  ) : (
                    <img
                      src={cancel_lightgray}
                      alt={TranslationRoomCard.invalid}
                      aria-label="hidden"
                      className={
                        newHostPassword?.length > 0 ? 'filter-red' : ''
                      }
                    />
                  )}
                  <p
                    className={`${
                      validHostPasswordLength
                        ? 'valid'
                        : newHostPassword?.length > 0
                        ? 'invalid'
                        : ''
                    } `}
                  >
                    {TranslationRoomCard.passwordRequirements}
                  </p>
                </li>
              </ul>
            </>
          )}
          {openSelection === 'attendeePassword' && (
            <>
              <div className="full-width divider-dotted mrg-tb24" />
              <PasswordInput
                defaultValue={
                  initialTranslationRoomCodeData?.defaultAttendeePassword
                }
                handleInput={setNewAttendeePassword}
                preventDefaultOff={true}
                id={'passwordInputDefaultAttendee'}
                noAsterisk={true}
                customTitle={TranslationRoomCard.attendeePassword}
                customWrapper={`mrg-b24`}
                customKeyDownHandler={handleEnterPress}
              />
            </>
          )}

          {openSelection === 'changeRoomCode' && (
            <>
              <div className="full-width divider-dotted mrg-tb24" />

              <AddTranslationRoomInput
                handleEnter={handleSave}
                setRoomCode={setNewRoomCode}
                roomCode={newRoomCode}
                roomCodeLabelText={TranslationRoomCard.newRoomCode}
                setValid={setSaveValid}
                roomCodeButtonOff={true}
              />

              <SaveButton
                saveValid={saveValid}
                handler={() => handleSave()}
                isLoading={isLoading}
                customWrapper={'mrg-t24'}
              />
            </>
          )}

          {openSelection === 'restoreSubscription' && (
            <>
              <div className="full-width divider-dotted mrg-t24" />
              <ConfirmSelection
                btn1Text={`${TranslationRoomCard.restore} "${initialTranslationRoomCodeData.roomCode}" ${TranslationRoomCard.subscription}`}
                btn1Color={'pos'}
                btn1Handler={handleRestoreRoomCode}
                ignoreMaxWidth={true}
                isLoading={isLoading}
                customWrapper={'mrg-t24'}
              />
            </>
          )}

          {openSelection === 'remove' && (
            <>
              <div className="full-width divider-dotted mrg-t24" />
              <ConfirmSelection
                btn1Text={`${TranslationRoomCard.remove} "${initialTranslationRoomCodeData.roomCode}" ${TranslationRoomCard.subscription}`}
                btn1Color={'neg'}
                btn1Handler={handleRemoveRoomCode}
                ignoreMaxWidth={true}
                isLoading={isLoading}
                customWrapper={'mrg-t24'}
              />
            </>
          )}

          {(openSelection === 'hostPassword' ||
            openSelection === 'attendeePassword') && (
            <SaveButton
              saveValid={saveValid}
              handler={() => handleSave()}
              isLoading={isLoading}
              customWrapper={'mrg-t24'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TranslationRoomCard;
