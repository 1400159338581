import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//Components

//Utility
import {
  bigtag,
  dropdown_arrow,
  folder,
  social,
  tinytag,
  warning,
} from '../../../../assets/icons';

const TagsToggle = ({ view }) => {
  //Hooks
  const { IconAlts } = useLanguageComponents();
  const { SettingsEventsLanguage } = useLanguageSettings();
  const {
    //Big Tags
    settingsBigTagsListOpened,
    handleSettingsSetBigTagsListOpened,
    settingsSelectedBigTags,

    //My events
    settingsSelectedMyEvents,
    settingsMyEventsListOpened,
    handleSettingsSetMyEventsListOpened,

    //Tiny Tags
    settingsTinyTagsListOpened,
    handleSettingsSetTinyTagsListOpened,
    settingsSelectedTinyTags,
    settingsSelectedTinyTagGroupIds,

    //Community
    settingsSelectedAccountsFollowingIds,
    handleSettingsSetAccountsFollowingListOpened,
    settingsAccountsFollowingListOpened,
    settingsSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  const {
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();

  //Component state
  const [viewSelectionHeading, setViewSelectionHeading] = useState();

  //Component variables
  let tagsToggle;

  useEffect(() => {
    if (view === 'myevents') {
      setViewSelectionHeading(`${SettingsEventsLanguage.myEvents}`);
    }
    if (view === 'bigtags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.bigTags}`);
    }
    if (view === 'tinytags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.tinyTags}`);
    }
    if (view === 'community') {
      setViewSelectionHeading(`${SettingsEventsLanguage.communityEvents}`);
    }
  }, [view]); //sets translated viewSelectionHeading

  //JSX conditional
  if (view === 'myevents') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <button
          className="tags-toggle-btn color-gray align-center fs18 fwsb access-ob access-o6"
          onClick={() =>
            handleSettingsSetMyEventsListOpened(!settingsMyEventsListOpened)
          }
          tabIndex="0"
          aria-label={SettingsEventsLanguage.ariaMyEventsSelectionToggle}
          aria-expanded={settingsMyEventsListOpened}
        >
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsMyEventsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </button>

        {!settingsMyEventsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            {settingsSelectedMyEvents.includes('saved') && (
              <h2
                className="fs18 fwsb access-ob access-o6"
                tabIndex="0"
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionSavedEvents
                }
              >
                {SettingsEventsLanguage.saved}
                {settingsSelectedMyEvents?.length > 1 && `,`}&nbsp;
              </h2>
            )}
            {settingsSelectedMyEvents.includes('registered') && (
              <h2
                className="fs18 fwsb access-ob access-o6"
                tabIndex="0"
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionRegisteredEvents
                }
              >
                {SettingsEventsLanguage.registered}
                {settingsSelectedMyEvents.includes('participationEvents') &&
                  `,`}
                &nbsp;
              </h2>
            )}
            {settingsSelectedMyEvents.includes('participationEvents') && (
              <h2
                className="fs18 fwsb access-ob access-o6"
                tabIndex="0"
                aria-label={
                  SettingsEventsLanguage.ariaSelectionParticipationEvents
                }
              >
                {SettingsEventsLanguage.participation}
              </h2>
            )}
            {settingsSelectedMyEvents?.length === 0 && (
              <>
                <img
                  src={warning}
                  alt={IconAlts.iconWarning}
                  style={{ height: '24px' }}
                  className="mrg-r12"
                />
                <h2 className="fs18 fwsb access-ob access-o6" tabIndex="0">
                  {SettingsEventsLanguage.noEventsSelected}
                </h2>
              </>
            )}
          </>
        )}
      </div>
    );
  } else if (view === 'bigtags') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <button
          className="tags-toggle-btn align-center fs18 fwsb color-gray access-ob access-o6"
          onClick={() =>
            handleSettingsSetBigTagsListOpened(!settingsBigTagsListOpened)
          }
          tabIndex="0"
          aria-label={SettingsEventsLanguage.ariaBigTagsSelectionToggle}
          aria-expanded={settingsBigTagsListOpened}
        >
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsBigTagsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </button>
        {!settingsBigTagsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div
              className="tags-sum fs21 fwsb access-ob"
              tabIndex="0"
              aria-label={`${SettingsEventsLanguage.ariaBigTagsSelectionSummary} ${settingsSelectedBigTags?.length}.`}
            >
              <img src={bigtag} alt={IconAlts.iconBigTag} />
              <p className="color-theme fwsb pad-r6">
                {settingsSelectedBigTags?.length}
                {settingsSelectedBigTags?.length < accountBigTags?.length &&
                  `/${accountBigTags?.length}`}
              </p>
            </div>
          </>
        )}
      </div>
    );
  } else if (view === 'tinytags') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <button
          className="tags-toggle-btn align-center fs18 fwsb color-gray access-ob access-o6"
          onClick={() =>
            handleSettingsSetTinyTagsListOpened(!settingsTinyTagsListOpened)
          }
          tabIndex="0"
          aria-expanded={settingsTinyTagsListOpened}
          aria-label={SettingsEventsLanguage.ariaTinyTagsSelectionToggle}
        >
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsTinyTagsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </button>
        {!settingsTinyTagsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div
              className="flex-row access-ob"
              tabIndex="0"
              aria-label={`${
                SettingsEventsLanguage.ariaTinyTagsSelectionSummaryFolders +
                settingsSelectedTinyTagGroupIds?.length
              }.`}
            >
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconTinyTagGroup}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {settingsSelectedTinyTagGroupIds?.length}
                  {settingsSelectedTinyTagGroupIds?.length <
                    accountTinyTagGroups?.length &&
                    `/${accountTinyTagGroups?.length}`}
                </p>
              </div>
              <div className="tags-sum fs21 fwsb">
                <img src={tinytag} alt={IconAlts.tinyTags} aria-hidden="true" />
                <p className="color-theme pad-r12">
                  {settingsSelectedTinyTags?.length}
                  {settingsSelectedTinyTags?.length < accountTinyTags?.length &&
                    `/${accountTinyTags?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else if (view === 'community') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <button
          className="tags-toggle-btn align-center fs18 fwsb color-gray access-ob access-o6"
          onClick={() =>
            handleSettingsSetAccountsFollowingListOpened(
              !settingsAccountsFollowingListOpened
            )
          }
          tabIndex="0"
          aria-expanded={settingsAccountsFollowingListOpened}
          aria-label={SettingsEventsLanguage.ariaCommunitySelectionToggle}
        >
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsAccountsFollowingListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </button>
        {!settingsAccountsFollowingListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div
              className="flex-row access-ob"
              tabIndex="0"
              aria-label={`${
                SettingsEventsLanguage.ariaCommunitySelectionSummaryFolders +
                settingsSelectedAccountsFollowingGroupIds?.length
              }.`}
            >
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconCommunity}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {settingsSelectedAccountsFollowingGroupIds?.length}
                  {settingsSelectedAccountsFollowingGroupIds?.length <
                    accountCommunityFollowingGroups?.length &&
                    `/${accountCommunityFollowingGroups?.length}`}
                </p>
              </div>
              <div
                className="tags-sum fs21 fwsb access-ob"
                tabIndex="0"
                aria-label={`${SettingsEventsLanguage.ariaCommunitySelectionSummary} ${settingsSelectedAccountsFollowingIds?.length}.`}
              >
                <img src={social} alt={IconAlts.iconCommunity} />
                <p className="color-theme fwsb pad-r6">
                  {settingsSelectedAccountsFollowingIds?.length}
                  {settingsSelectedAccountsFollowingIds?.length <
                    accountCommunityFollowing?.length &&
                    `/${accountCommunityFollowing?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return tagsToggle;
};

export default TagsToggle;
