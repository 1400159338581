export function getEventInvitationType(n, GetEventInvitationType) {
  if (n.noticeType === 'eventParticipationInvite') {
    if (n?.eventInvitation?.draftId) {
      return <p>{GetEventInvitationType.eventDraftInvitation}</p>;
    }

    if (n?.eventInvitation?.eventId || n?.eventInvitation?.scheduledEventId) {
      return <p>{GetEventInvitationType.activeEventInvitation}</p>;
    }
  }
}
