import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import {
  useSaveSpeakerSignUpMutation,
  useUpdateRolesMutation,
} from '../../../accountApiSlice';

//components
import ButtonMeta from '../../../../../../components/ButtonMeta/ButtonMeta';
import ConfirmSelection from '../../../../../../components/ConfirmSelection/ConfirmSelection';
import ScrollToComponent from '../../../../../../components/ScrollToComponent/ScrollToComponent';

//utils
import { save, trash, img_arrow_stubby } from '../../../../../../assets/icons';
import { getCountryISO } from '../../../../../../utils/spatial/getCountryISO';

const SpeakerMeta = ({ setResetSpeaker, setSpeakerSignUpIsLoading }) => {
  //hooks
  const { SpeakerMeta } = useLanguageAccount();
  const { IconAlts } = useLanguageComponents();
  const {
    speakerSignUpValidation,
    accountSpeakerLastModified,
    handleAccountSpeakerReset,
    accountSpeakerGeoPositionCoordinates,
    accountId,
    accountSpeaker,
  } = useAccountReduxHandlers();

  //Api
  const [saveSpeakerSignUp, { isLoading: saveSpeakerSignUpIsLoading }] =
    useSaveSpeakerSignUpMutation();
  const [updateRoles] = useUpdateRolesMutation();

  //state
  const [selectionBtn, setSelectionBtn] = useState();
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();

  //initialize
  useEffect(() => {
    if (accountSpeakerLastModified > verifiedTimestamp || !verifiedTimestamp) {
      setVerifiedSuccessful(false);
      setSelectionBtn();
    }
  }, [accountSpeakerLastModified, verifiedTimestamp]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  function handleResetSpeaker() {
    handleAccountSpeakerReset();
    handleResetDraft();
    setResetSpeaker(true);
    setSelectionBtn();
  }

  async function handleSaveDraft() {
    if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();
      await saveSpeakerSignUp({ accountId, speakerData: accountSpeaker });
    }
  }

  async function handleResetDraft() {
    if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();
      await saveSpeakerSignUp({ accountId, speakerData: {} });
    }
  }

  async function finalizeSpeakerSignUp() {
    if (!verifiedSuccessful || accountSpeakerLastModified > verifiedTimestamp) {
      return;
    } else if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();

      const countryISO = await getCountryISO(
        accountSpeakerGeoPositionCoordinates
      );
      try {
        const response = await saveSpeakerSignUp({
          accountId,
          speakerData: {
            ...accountSpeaker,
            speakerCountryISO: countryISO,
          },
          signUpComplete: true,
        });

        if (response?.error) {
          throw new Error();
        }

        await updateRoles({
          accountId,
          newData: {
            speakerRoleChange: true,
          },
        });
        setSpeakerSignUpIsLoading(true);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('finalizeSpeakerSignUp error,', error);
        }
      }
    }
  }

  function handleGetPublishValidations() {
    const speakerDataOrValidationsArray = speakerSignUpValidation();

    if (Array.isArray(speakerDataOrValidationsArray)) {
      setValidations(speakerDataOrValidationsArray);
      setVerifiedSuccessful(false);
    } else {
      setVerifiedTimestamp(Date.now());
      setVerifiedSuccessful(true);
      setValidations();
    }
  }

  return (
    <div className="flex-column mrg-t12 mrg-b36">
      <div
        className={`button-meta__container space-between access-ob access-o6 ${
          selectionBtn ? 'mrg-b24' : ''
        }`}
        id="speakerMetaBtns"
        tabIndex="0"
      >
        <ButtonMeta
          handleClick={() => {
            if (selectionBtn === 'restart') {
              setSelectionBtn('');
            } else {
              setSelectionBtn('restart');
            }
          }}
          btnText={SpeakerMeta.restart}
          ariaLabel={SpeakerMeta.ariaRestart}
          activeStateDependency={selectionBtn === 'restart'}
          icon={trash}
          iconAlt={IconAlts.iconTrash}
        />
        <ButtonMeta
          handleClick={() => {
            if (selectionBtn === 'saveDraft') {
              setSelectionBtn('');
            } else {
              setSelectionBtn('saveDraft');
            }
          }}
          btnText={SpeakerMeta.saveDraft}
          ariaLabel={SpeakerMeta.ariaSaveDraft}
          activeStateDependency={selectionBtn === 'saveDraft'}
          icon={save}
          iconAlt={IconAlts.iconSave}
        />

        <ButtonMeta
          handleClick={() => {
            if (selectionBtn === 'publish') {
              setSelectionBtn();
            } else {
              setSelectionBtn('publish');
              handleGetPublishValidations();
            }
          }}
          btnText={SpeakerMeta.publish}
          ariaLabel={SpeakerMeta.ariaPublish}
          activeStateDependency={selectionBtn === 'publish'}
          icon={img_arrow_stubby}
          iconCustomClassName={'reverse-vertical'}
          iconAlt={IconAlts.iconArrow}
        />
      </div>
      <>
        {selectionBtn === 'restart' && (
          <>
            <div className="divider mrg-t12 mrg-b24--ph" />
            <ConfirmSelection
              text2={SpeakerMeta.removeAllSpeakerSignUpData}
              btn1Text={SpeakerMeta.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={SpeakerMeta.restart}
              btn2Color={'neg'}
              btn2Handler={handleResetSpeaker}
              ignoreMaxWidth={true}
              btnDescriptionTextVertical={true}
              btnBannerNotice={true}
              customWrapper={'mrg-t24'}
            />
          </>
        )}
        {selectionBtn === 'saveDraft' && (
          <>
            <div className="divider mrg-t12 mrg-b24--ph" />
            <ConfirmSelection
              btn1Text={SpeakerMeta.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={SpeakerMeta.save}
              btn2Color={'pos'}
              btn2Handler={() => handleSaveDraft()}
              ignoreMaxWidth={true}
              isLoading={saveSpeakerSignUpIsLoading}
              customWrapper={'mrg-t24'}
            />
          </>
        )}

        {selectionBtn === 'publish' && validations && (
          <div className="validations__wrapper">
            <div className="validations-title mrg-t24 fs16 fwsb color-red ">
              <p className="access-ob  access-o6" tabIndex="0">
                {SpeakerMeta.fieldsRequireCompletion}
              </p>
            </div>
            <ul className="scrollToComponent__container mrg-t24">
              {validations.map((item, index) => {
                const validationLabel = Object.values(item);
                const element = Object.keys(item);
                return (
                  <div key={`scrollToComponent-${index}`}>
                    <ScrollToComponent
                      text={validationLabel[0]}
                      elementId={element[0]}
                    />
                  </div>
                );
              })}
            </ul>
          </div>
        )}

        {selectionBtn === 'publish' && verifiedSuccessful && (
          <>
            <div className="divider mrg-t12" />

            <ConfirmSelection
              btn1Text={SpeakerMeta.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={SpeakerMeta.complete}
              btn2Color={'pos'}
              btn2Handler={finalizeSpeakerSignUp}
              ignoreMaxWidth={true}
              btnDescriptionTextVertical={true}
              isLoading={saveSpeakerSignUpIsLoading}
              customWrapper={'mrg-t24'}
            />
          </>
        )}
      </>
    </div>
  );
};

export default SpeakerMeta;
