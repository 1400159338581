import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageLayout from '../../language/useLanguageLayout';
import useLanguageData from '../../language/useLanguageData';
//Components

//Utils
import { dropdown_arrow, exchange, login, register } from '../../assets/icons';

const NavLoggedOut = () => {
  //Hooks
  const {
    navbar,
    setNavbar,
    contentLanguage,
    setContentLanguage,
    handleMobileTap,
  } = useSettings();
  const { NavbarLoggedOut } = useLanguageLayout();
  const { ContentLanguage, LanguageOpts } = useLanguageData();

  //Component state
  const [showLanguageOpts, setShowLanguageOpts] = useState(false);
  const [highlightTapLanguageOpt, setHighlightTapLanguageOpt] = useState(false);
  //Initialize functions
  useEffect(() => {
    const handleClick = (e) => {
      const allowedIds = [
        'language-selection',
        'language-selection-text',
        'language-selection-img',
      ];

      if (showLanguageOpts && !allowedIds.includes(e.target.id)) {
        setShowLanguageOpts(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClick);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [showLanguageOpts]); //closes language selection menu if opened

  return (
    <div className="nav__links no-select">
      <button
        className="modal-dropdown fs18 fwb color-white access-ob access-o6"
        onClick={() => setShowLanguageOpts(!showLanguageOpts)}
        id="language-selection"
        tabIndex="0"
        aria-expanded={showLanguageOpts}
        aria-label={`${NavbarLoggedOut.selectSiteLanguage} ${ContentLanguage}`}
        aria-controls="modal-dropdown__button"
      >
        <img
          id="language-selection-img"
          src={dropdown_arrow}
          alt="arrow"
          className={`${showLanguageOpts ? 'reverse-vertical' : ''}`}
          aria-hidden="true"
        />
        {ContentLanguage}
      </button>
      {showLanguageOpts && (
        <ul className="modal-dropdown__button modal-dropdown__button--header">
          <li>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleMobileTap(
                  [
                    () => setHighlightTapLanguageOpt('en-CA'),
                    () => setHighlightTapLanguageOpt(),
                  ],
                  [
                    () => setContentLanguage('en-CA'),
                    () => setShowLanguageOpts(false),
                  ]
                );
              }}
              className={`fs16 fwsb access-ob access-o-6 ${
                contentLanguage === 'en-CA' ? 'selected' : ''
              } `}
              tabIndex="0"
            >
              {LanguageOpts['en-CA']}
            </button>
          </li>
          <li>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleMobileTap(
                  [
                    () => setHighlightTapLanguageOpt('fr-CA'),
                    () => setHighlightTapLanguageOpt(),
                  ],
                  [
                    () => setContentLanguage('fr-CA'),
                    () => setShowLanguageOpts(false),
                  ]
                );
              }}
              className={`fs16 fwsb access-ob access-o-6 ${
                contentLanguage === 'fr-CA' ? 'selected' : ''
              }`}
              tabIndex="0"
            >
              {LanguageOpts['fr-CA']}
            </button>
          </li>
        </ul>
      )}
      <Link
        to={{ pathname: '/tr' }}
        onClick={() => {
          if (navbar === 'translations') {
            setNavbar();
          } else {
            setNavbar('translations');
          }
        }}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img
            src={exchange}
            alt={'img'}
            aria-hidden="true"
            className="filter-white"
          />
          <span
            className={`${
              navbar === 'translations' ? 'active fadeIn' : 'inactive'
            }`}
          />
        </div>
        {NavbarLoggedOut.translations}
      </Link>

      <Link
        to={{ pathname: '/register' }}
        onClick={() => {
          if (navbar === 'register') {
            setNavbar();
          } else {
            setNavbar('register');
          }
        }}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img
            src={register}
            alt={NavbarLoggedOut.register}
            className="filter-white"
            aria-hidden="true"
          />
          <span
            className={`${
              navbar === 'register' ? 'active fadeIn' : 'inactive'
            }`}
          />
        </div>
        {NavbarLoggedOut.register}
      </Link>

      <Link
        to={{ pathname: '/login' }}
        onClick={() => {
          if (navbar === 'login') {
            setNavbar();
          } else {
            setNavbar('login');
          }
        }}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <span
            className={`${navbar === 'login' ? 'active fadeIn' : 'inactive'}`}
          />
          <img
            src={login}
            alt={NavbarLoggedOut.decorativeEnterIcon}
            aria-hidden="true"
          />
        </div>
        {NavbarLoggedOut.signIn}
      </Link>
    </div>
  );
};

export default NavLoggedOut;
