import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//hooks
import useGenericModal from '../../../../../context/useGenericModal';
import useApiStatus from '../../../../../context/useApiStatus';
import useSettings from '../../../../../context/useSettings';
import { useOrganizeHandlers } from '../../useOrganizeHandlers';
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';

//components
import QueryFilterDesktop from './QueryFilterDesktop';
import QueryFilterMobile from './QueryFilterMobile';
import ImportEventDataModal from '../../../../../components/Modal/ModalComponents/FolderModals/ImportEventDataModal';
import ViewContactedSpeakersModal from '../../../../../components/Modal/ModalComponents/FolderModals/ViewContactedSpeakersModal';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import { countryKeyToCountryISO } from '../../../../../utils/data/countryKeyToCountryISO';

const FindSpeakersQueryFilters = ({
  handleFindSpeakersQuery,
  handleViewSpeakerFromModalSelection,
  queryIsLoading,
  setViewSpeaker,
  setPaginatedData,
}) => {
  //hooks
  const { FindSpeakersQueryFilters } = useLanguageOrganize();
  const {
    organizeMyDraftEvents,
    organizeSortedMyOrganizedEvents,
    organizeSortedMyScheduledOrganizedEvents,
    organizeSortedMyArchivedOrganizedEvents,
  } = useOrganizeHandlers();
  const {
    handleSettingsResetFindSpeakers,
    handleSettingsSetFindSpeakersDistanceFilter,
    handleSettingsSetFindSpeakersSearchBigTags,
    handleSettingsSetFindSpeakersSearchCoordinates,
    handleSettingsSetFindSpeakersSearchCountries,
    handleSettingsSetFindSpeakersSearchLocationType,
    handleSettingsSetFindSpeakersSearchSpeakerLanguages,
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed,
    handleSettingsSetFindSpeakersSearchTinyTags,
    settingsFindSpeakersDistanceFilter,
    settingsFindSpeakersSearchLocationType,
    settingsFindSpeakersSearchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed,
  } = useSettingsHandlers();

  const { handleWarning } = useApiStatus();
  const { width } = useSettings();
  const { setGenericModal } = useGenericModal();

  //STATE
  const [isLoaded, setIsLoaded] = useState(false);
  const [reset, setReset] = useState(false);

  //Variables
  const effectRan = useRef(false);
  const initialDistanceFilter = useRef();

  //initialize
  useEffect(() => {
    return () => handleResetFilters();
  }, []);

  //functions
  function handleResetFilters() {
    handleSettingsResetFindSpeakers();
    setDistanceFilterCheck(20000, 'km');
    setReset(!reset);
    setViewSpeaker();
    setPaginatedData([]);
    ScrollTo('findSpeakersQueryFiltersLabel', 'auto', true);
  }

  //LANGUAGES
  useEffect(() => {
    if (settingsFindSpeakersSearchSpeakerLanguages.includes('notListed')) {
      if (
        settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length === 0 ||
        !settingsFindSpeakersSearchSpeakerLanguagesNotListed
      ) {
        handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else if (
      !settingsFindSpeakersSearchSpeakerLanguages.includes('notListed') &&
      settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length > 0
    ) {
      handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed([]);
    }
  }, [
    settingsFindSpeakersSearchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed,
  ]); //sets textarea component for registered

  function handleAddNotListedLanguage() {
    if (settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length > 5)
      return handleWarning({
        message: 'limitReached',
        origin: 'FindSpeakersQueryFilters/addNotListedLanguage',
        id: Date.now(),
      });

    let newArr = [...settingsFindSpeakersSearchSpeakerLanguagesNotListed];
    newArr.push({ id: uuidv4(), language: '' });
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed(newArr);
  }

  function handleRemoveNotListedLanguage(id) {
    let newNotListedLanguages =
      settingsFindSpeakersSearchSpeakerLanguagesNotListed.filter(
        (lang) => lang.id !== id
      );
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed(
      newNotListedLanguages
    );
    if (newNotListedLanguages?.length === 0) {
      let newArr = [...settingsFindSpeakersSearchSpeakerLanguages];

      newArr = newArr.filter((lang) => lang !== 'notListed');
      handleSettingsSetFindSpeakersSearchSpeakerLanguages(newArr);
    }
  }

  function handleNotListedLanguages(text, langId) {
    let newNotListedLanguages =
      settingsFindSpeakersSearchSpeakerLanguagesNotListed?.map((lang) => {
        if (lang.id === langId) {
          return { ...lang, language: text?.trim() };
        }
        return lang;
      });
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed(
      newNotListedLanguages
    );
  }

  //LOCATION
  useEffect(() => {
    if (!settingsFindSpeakersSearchLocationType) {
      handleSettingsSetFindSpeakersSearchCountries([]);
    }
  }, [settingsFindSpeakersSearchLocationType]);

  function handleAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    handleSettingsSetFindSpeakersSearchCoordinates(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  useEffect(() => {
    if (effectRan.current === false) {
      setDistanceFilterCheck(20000, 'km');
      setIsLoaded(true);
      effectRan.current = true;
    }
  }, [settingsFindSpeakersDistanceFilter]);

  function setDistanceFilterCheck(distanceInMeters, measurement) {
    let convertedDistance;
    if (measurement === 'km') {
      convertedDistance = distanceInMeters / 1000;
      initialDistanceFilter.current = convertedDistance;
      handleSettingsSetFindSpeakersDistanceFilter(convertedDistance);
    } else if (measurement === 'mi') {
      convertedDistance = distanceInMeters / 1609.344;
      convertedDistance = Number(convertedDistance.toFixed(1));
      initialDistanceFilter.current = convertedDistance;
      handleSettingsSetFindSpeakersDistanceFilter(convertedDistance);
    }
  }

  function handleImportDraftSelectionKey(eventId, eventTypeSelection) {
    let selectedDraft;

    if (eventTypeSelection === 'draft') {
      for (const draft of organizeMyDraftEvents) {
        if (draft._id === eventId) {
          selectedDraft = draft;
          break;
        }
      }
    } else if (eventTypeSelection === 'active') {
      for (const activeEvent of organizeSortedMyOrganizedEvents) {
        if (activeEvent._id === eventId) {
          selectedDraft = activeEvent;
          break;
        }
      }
    } else if (eventTypeSelection === 'scheduled') {
      for (const scheduledEvent of organizeSortedMyScheduledOrganizedEvents) {
        if (scheduledEvent._id === eventId) {
          selectedDraft = scheduledEvent;
          break;
        }
      }
    } else if (eventTypeSelection === 'archived') {
      for (const archivedEvent of organizeSortedMyArchivedOrganizedEvents) {
        if (archivedEvent._id === eventId) {
          selectedDraft = archivedEvent;
          break;
        }
      }
    }

    if (!selectedDraft) return;

    handleSettingsSetFindSpeakersSearchBigTags(selectedDraft.tags?.bigTags);
    handleSettingsSetFindSpeakersSearchTinyTags(selectedDraft.tags?.tinyTags);
    handleSettingsSetFindSpeakersSearchSpeakerLanguages(
      selectedDraft.generic?.languages
    );

    let draftCoords =
      selectedDraft?.hosting?.inPerson?.geoPosition?.coordinates;

    if (eventTypeSelection !== 'draft' && draftCoords) {
      draftCoords = [draftCoords[1], draftCoords[0]];
    } //need to reverse coords for published events but not draft

    if (selectedDraft.hosting.hostingOption === 'online') {
      handleSettingsSetFindSpeakersSearchLocationType('locationCountry');
      if (selectedDraft.hosting?.inPerson?.location?.country) {
        if (selectedDraft.hosting.inPerson.location.country) {
          const countryISO = countryKeyToCountryISO(
            selectedDraft.hosting.inPerson.location.country
          );
          handleSettingsSetFindSpeakersSearchCountries([countryISO]);
        }
      }
    } else if (
      selectedDraft.hosting.hostingOption === 'inPerson' ||
      selectedDraft.hosting.hostingOption === 'hybrid'
    ) {
      handleSettingsSetFindSpeakersSearchLocationType('locationMap');
      if (
        draftCoords[0] &&
        draftCoords[0] !== 0 &&
        draftCoords[1] !== 0 &&
        draftCoords[1]
      ) {
        handleSettingsSetFindSpeakersSearchCoordinates(draftCoords);
      }
    }
  }

  function handleImportEventDraft() {
    let eventDraftOpts = {};

    organizeMyDraftEvents?.map((draftData, index) => {
      const id = draftData._id;
      eventDraftOpts = {
        ...eventDraftOpts,
        [id]: draftData.meta.saveName,
      };
    });

    setGenericModal(
      <ImportEventDataModal handler={handleImportDraftSelectionKey} />
    );
  }

  function handleViewContactedSpeakers() {
    setGenericModal(
      <ViewContactedSpeakersModal
        handler={handleViewSpeakerFromModalSelection}
      />
    );
  }

  return (
    <div className="fs-search-filters__wrapper">
      <label
        className="fs-search-results--label fs-search-results--label-filters"
        id="findSpeakersQueryFiltersLabel"
        tabIndex="0"
      >
        {FindSpeakersQueryFilters.searchFilters}
      </label>

      {width > 1280 ? (
        <QueryFilterDesktop
          isLoaded={isLoaded}
          FindSpeakersQueryFilters={FindSpeakersQueryFilters}
          reset={reset}
          handleResetFilters={handleResetFilters}
          handleImportEventDraft={handleImportEventDraft}
          handleFindSpeakersQuery={handleFindSpeakersQuery}
          handleNotListedLanguages={handleNotListedLanguages}
          handleRemoveNotListedLanguage={handleRemoveNotListedLanguage}
          handleAutocompleteCoordinates={handleAutocompleteCoordinates}
          handleAddNotListedLanguage={handleAddNotListedLanguage}
          handleViewContactedSpeakers={handleViewContactedSpeakers}
          queryIsLoading={queryIsLoading}
        />
      ) : (
        <QueryFilterMobile
          isLoaded={isLoaded}
          FindSpeakersQueryFilters={FindSpeakersQueryFilters}
          reset={reset}
          handleResetFilters={handleResetFilters}
          handleImportEventDraft={handleImportEventDraft}
          handleFindSpeakersQuery={handleFindSpeakersQuery}
          handleNotListedLanguages={handleNotListedLanguages}
          handleRemoveNotListedLanguage={handleRemoveNotListedLanguage}
          handleAutocompleteCoordinates={handleAutocompleteCoordinates}
          handleAddNotListedLanguage={handleAddNotListedLanguage}
          handleViewContactedSpeakers={handleViewContactedSpeakers}
          queryIsLoading={queryIsLoading}
        />
      )}
    </div>
  );
};

export default FindSpeakersQueryFilters;
