import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//components
import ProfilePersonalInfo from './sections/ProfilePersonalInfo';
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';
import ItemButton from '../ItemButton/ItemButton';
import ProfileBio from './sections/ProfileBio';

//utils
import ScrollTo from '../../utils/UI/ScrollTo';
import ProfileSpeakerInfo from './sections/ProfileSpeakerInfo';
import ProfileContact from './sections/ProfileContact';
import ProfileLocation from './sections/ProfileLocation';
import ProfileSocials from './sections/ProfileSocials';
import ProfileInteractions from './sections/ProfileInteractions';

const ProfilePersonalBase = ({
  customWrapper,
  handleCloseModal,
  previewMode,
  profileData,
  reopenNotices,
  scrollToOn,
  bordersOn,
  customExitHandler,
  customProfileInteractionHandler,
  interactionsOff,
}) => {
  //hooks
  const {
    accountId,
    accountOrganizerOrganizerModeActive,
    accountCommunityContacts,
  } = useAccountReduxHandlers();
  const { Generic, ProfilePersonal } = useLanguageComponents();
  const { setOpenMobileNotificationsMenu } = useSettings();

  //state
  const [profile, setProfile] = useState();
  const [speakerLocation, setSpeakerLocation] = useState();
  const [isViewerAccountHolder, setIsViewerAccountHolder] = useState(false);
  const [isContact, setIsContact] = useState(false);

  //ui
  const [hasSocialLink, setHasSocialLink] = useState(false);

  //initialize
  useEffect(() => {
    if (profileData && accountId) {
      setProfile(profileData);

      if (profileData?.speaker) {
        getSpeakerLocation();
      }
      if (profileData?._id === accountId) {
        setIsViewerAccountHolder(true);
      }

      if (profileData?.personal?.links) {
        const links = Object.values(profileData?.personal?.links);
        for (const link of links) {
          if (link?.length > 0) {
            setHasSocialLink(true);
            break;
          }
        }
      }

      if (accountCommunityContacts?.includes(profile?._id)) {
        setIsContact(true);
      }
    }
  }, [accountId, profileData]); //set profile, speaker location, current user

  //UI
  useEffect(() => {
    if (scrollToOn) {
      ScrollTo('', 'auto', true);
    }
  }, [scrollToOn]);

  //functions
  async function getSpeakerLocation() {
    if (
      !profileData?.speaker?.speakerGeoPosition?.coordinates[0] ||
      !profileData?.speaker?.speakerGeoPosition?.coordinates[1]
    ) {
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    let latLng = {};

    if (previewMode) {
      latLng = {
        lat: profileData?.speaker?.speakerGeoPosition?.coordinates[0],
        lng: profileData?.speaker?.speakerGeoPosition?.coordinates[1],
      };
    } else {
      latLng = {
        lat: profileData?.speaker?.speakerGeoPosition?.coordinates[1],
        lng: profileData?.speaker?.speakerGeoPosition?.coordinates[0],
      };
    }

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        const addressComponents = results[0]?.address_components;

        let city = '';
        let country = '';

        addressComponents.forEach((component) => {
          if (component.types.includes('locality')) {
            city = component?.long_name;
          }
          if (component.types.includes('country')) {
            country = component?.long_name;
          }
        });

        const loc = `${city}, ${country}`;
        setSpeakerLocation(loc);
      } else {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error('geolocation error.');
        }
      }
    });
  }

  return (
    <>
      <LoadBucketImage
        containerClassName={`account-profile-banner`}
        imgClassName={`account-profile-banner--image`}
        backupPath={'banner_img_default.png'}
        partialPath={'myndfull_banner_images'}
        imagePath={profile?.personal?.bannerImage}
        title={'banner'}
      />
      <div
        className={`account-profile__wrapper ${
          customWrapper ? customWrapper : ''
        } ${bordersOn ? 'account-profile__wrapper--borders' : ''}`}
      >
        <div className={`account-profile-content mrg-lr12`}>
          <ProfilePersonalInfo
            profile={profile}
            customWrapper={customWrapper}
            ProfilePersonal={ProfilePersonal}
          />

          {!interactionsOff && !isViewerAccountHolder && (
            <ProfileInteractions
              profile={profile}
              isViewerAccountHolder={isViewerAccountHolder}
              previewMode={previewMode}
              customProfileInteractionHandler={customProfileInteractionHandler}
            />
          )}
          {isViewerAccountHolder && (
            <p
              className={`flex-center fs18 italic  ${
                previewMode ? 'mrg-b24' : 'mrg-b12'
              }`}
            >
              {ProfilePersonal.isAccountHolder}
            </p>
          )}

          {profile?.speaker?.speakerModeActive &&
            speakerLocation &&
            (accountOrganizerOrganizerModeActive || previewMode) && (
              <ProfileLocation
                profile={profile}
                previewMode={previewMode}
                speakerLocation={speakerLocation}
                ProfilePersonal={ProfilePersonal}
              />
            )}

          {isContact &&
            profile?.speaker?.speakerModeActive &&
            (accountOrganizerOrganizerModeActive || previewMode) && (
              <ProfileContact
                profile={profile}
                previewMode={previewMode}
                ProfilePersonal={ProfilePersonal}
              />
            )}
          {hasSocialLink && (
            <ProfileSocials
              profile={profile}
              ProfilePersonal={ProfilePersonal}
            />
          )}

          {profile?.personal?.bio?.length > 0 && (
            <ProfileBio profile={profile} ProfilePersonal={ProfilePersonal} />
          )}

          {profile?.speaker?.speakerModeActive &&
            (accountOrganizerOrganizerModeActive || previewMode) && (
              <ProfileSpeakerInfo
                profile={profile}
                previewMode={previewMode}
                ProfilePersonal={ProfilePersonal}
              />
            )}

          <ItemButton
            handler={() => {
              if (handleCloseModal) {
                handleCloseModal();
              }
              if (reopenNotices) {
                setOpenMobileNotificationsMenu(true);
              } //keep here because button is necessary to click for mobile
              if (customExitHandler) {
                customExitHandler();
              }
            }}
            text={Generic.exit}
            iconOn={true}
            fullWidth={true}
            customWrapper={
              'account-profile-exit-btn mrg-auto-top min-full-width'
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProfilePersonalBase;
