//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useSettings from '../../../../context/useSettings';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
//Utility
import {
  building2,
  calendar,
  clock,
  globe2,
  hybrid2,
  img_archived,
} from '../../../../assets/icons';
import {
  GetTimeRangeFromEventObject,
  GetTimeFromPublishObject,
  useDateTimeCalculations,
} from '../../../../utils/dateTime/DateCalc';

const InfoContainer = ({
  eventData,
  organizeType,
  index,
  setImageLoadConfirmation,
}) => {
  //Hooks
  const { getDateTimeSummary, getDateShortMonth } = useDateTimeCalculations();
  const { accountContentLanguage, accountTimeZone } = useAccountReduxHandlers();
  const { OrganizerEventCard } = useLanguageOrganize();
  const { width } = useSettings();

  //Component Variables
  let addressContent;

  if (eventData.hosting.hostingOption === 'hybrid') {
    addressContent = (
      <p>
        <span>
          {eventData.hosting.virtual.platform?.charAt(0).toUpperCase() +
            eventData.hosting.virtual.platform?.slice(1)}
          , <br />
        </span>

        {eventData.hosting.inPerson.location?.room &&
        eventData.hosting.inPerson.location?.locationName ? (
          <span>
            {eventData.hosting.inPerson.location?.room},{' '}
            {eventData.hosting.inPerson.location?.locationName}
          </span>
        ) : (
          <span>
            {eventData.hosting.inPerson?.location?.streetAddress},{' '}
            {eventData.hosting.inPerson?.location?.city}
          </span>
        )}
      </p>
    );
  }

  if (eventData.hosting.hostingOption === 'online') {
    addressContent = (
      <p>
        <span>
          {eventData.hosting.virtual.platform?.charAt(0).toUpperCase() +
            eventData.hosting.virtual.platform?.slice(1)}
        </span>
      </p>
    );
  }

  if (eventData.hosting.hostingOption === 'inPerson') {
    addressContent = (
      <p>
        {eventData.hosting.inPerson.location?.room && (
          <span>{eventData.hosting.inPerson.location?.room}, </span>
        )}
        {eventData.hosting.inPerson.location?.locationName && (
          <span>
            {eventData.hosting.inPerson.location?.locationName}, <br />
          </span>
        )}

        {eventData.hosting.inPerson?.location?.streetAddress && (
          <span>{eventData.hosting.inPerson?.location?.streetAddress}, </span>
        )}

        {eventData.hosting.inPerson?.location?.city && (
          <span>{eventData.hosting.inPerson?.location?.city}</span>
        )}
      </p>
    );
  }

  return (
    <div className="quick-info__container">
      <div className="flex-column full-width">
        <div className="title-row">
          <h1 className="title fs18 access-ob access-o6" tabIndex="0">
            {eventData.generic.title}
          </h1>
        </div>

        {/* Date/Time */}
        <div className="info-row">
          {organizeType === 'active' && (
            <img
              src={calendar}
              alt={OrganizerEventCard.calendarIcon}
              className="icon-container pad-2"
              title={OrganizerEventCard.eventDate}
            />
          )}
          {organizeType === 'scheduled' && (
            <img
              src={clock}
              alt={OrganizerEventCard.clockIcon}
              className="icon-container filter-theme pad-2"
              title={OrganizerEventCard.eventPublication}
            />
          )}
          {organizeType === 'archived' && (
            <img
              src={img_archived}
              alt={OrganizerEventCard.archivedIcon}
              className="icon-container filter-theme pad-2"
              title={OrganizerEventCard.archivedEvent}
            />
          )}

          <div className="full-width align-center space-between">
            <div className="flex-column access-ob access-o6" tabIndex="0">
              <p>
                {(organizeType === 'active' || organizeType === 'archived') &&
                  getDateShortMonth(
                    eventData.dateTime?.date,
                    accountContentLanguage
                  )}

                {organizeType === 'scheduled' &&
                  getDateShortMonth(
                    eventData?.schedule?.publishDate,
                    accountContentLanguage
                  )}
              </p>
              <p>
                {organizeType === 'active' &&
                  GetTimeRangeFromEventObject(eventData?.dateTime)}

                {organizeType === 'scheduled' &&
                  GetTimeFromPublishObject(eventData?.schedule)}
              </p>
            </div>
            <div
              className="text-right color-theme fwsb access-ob access-o6"
              tabIndex="0"
            >
              {getDateTimeSummary(eventData.dateTime, accountTimeZone, true)}
            </div>
          </div>
        </div>

        {/* Address */}
        <div className="info-row ">
          {eventData.hosting.hostingOption === 'inPerson' && (
            <img
              src={building2}
              alt={OrganizerEventCard.inPersonIcon}
              className="icon-container pad-3"
              title={OrganizerEventCard.inPersonEvent}
            />
          )}
          {eventData.hosting.hostingOption === 'online' && (
            <img
              src={globe2}
              alt={OrganizerEventCard.virtualIcon}
              className="icon-container filter-theme"
              title={OrganizerEventCard.virtualEvent}
            />
          )}
          {eventData.hosting.hostingOption === 'hybrid' && (
            <img
              src={hybrid2}
              alt={OrganizerEventCard.hybridIcon}
              className="icon-container"
              title={OrganizerEventCard.hybridEvent}
            />
          )}
          <div
            className="address-content flex-column access-ob access-o6"
            tabIndex="0"
          >
            {addressContent}
          </div>
        </div>
        <div className="info-row  full-width space-between">
          <div
            className="flex-row align-center access-ob access-o6"
            tabIndex="0"
          >
            <LoadBucketImage
              imagePath={
                eventData.organizers.organizerId[0].personal.profileImage
              }
              imgClassName="organizer-profile-img mrg-r12"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
              title={OrganizerEventCard.organizer}
            />

            <p>
              {eventData.organizers.organizerId[0].personal.firstName}{' '}
              {eventData.organizers.organizerId[0].personal.lastName}
            </p>
          </div>
        </div>
      </div>
      {width > 480 && (
        <div className="event-display">
          <LoadBucketImage
            imagePath={eventData.generic.eventImageDisplay?.imagePath}
            imgClassName="event-image "
            backupPath={'event_img_default.png'}
            partialPath={'myndfull_event_images'}
            index={index}
            setImageLoadConfirmation={setImageLoadConfirmation}
          />
        </div>
      )}
    </div>
  );
};

export default InfoContainer;
