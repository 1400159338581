import { useEffect, useState } from 'react';

//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

//components
import ParticipantListItemCompletionStatus from '../Generic/ParticipantListItemCompletionStatus';
import ParticipantListItemInvitationStatus from '../Generic/ParticipantListItemInvitationStatus';

//utils

const ParticipantListItemStatusTranslations = ({ p }) => {
  //hooks
  const { ParticipantListItem } = useLanguageOrganize();

  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.dispatches?.translationsTokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
      setIsDispatched(p?.dispatches?.translationsDispatched);
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'create' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.translationsCompletionStatus}
            organizedAs={p.organizedAs}
            translationsLanguageConflict={p.translationsLanguageConflict}
            translationsRoomRequiresLanguage={
              p.translationsRoomRequiresLanguage
            }
          />
        </div>
      )}

      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.translationsCompletionStatus}
            organizedAs={p.organizedAs}
            translationsLanguageConflict={p.translationsLanguageConflict}
            translationsRoomRequiresLanguage={
              p.translationsRoomRequiresLanguage
            }
            translationsIsDispatched={isDispatched}
          />
          {p?.dispatches?.translationsDispatched && (
            <>
              |{' '}
              <ParticipantListItemInvitationStatus
                invitationStatus={p.dispatches.translationsStatus}
                translationsListItem={true}
              />
            </>
          )}
        </div>
      )}

      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <ParticipantListItemCompletionStatus
            completionStatus={p.completionStatus}
            duplicateEmail={p?.emailInvitation?.duplicateEmail}
            invalidEmail={
              p?.emailInvitation?.email?.length > 0 &&
              p?.emailInvitation?.emailValid === false
            }
            organizedAs={p.organizedAs}
            translationsListItem={true}
            translationsLanguageConflict={p.translationsLanguageConflict}
            translationsRoomRequiresLanguage={
              p.translationsRoomRequiresLanguage
            }
            translationsIsDispatched={isDispatched}
          />
          {p?.dispatches?.translationsDispatched && (
            <>
              |{' '}
              <ParticipantListItemInvitationStatus
                invitationStatus={p.dispatches.translationsStatus}
                invitationHasExpired={invitationHasExpired}
                translationsListItem={true}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusTranslations;
