import { useEffect, useState } from 'react';

//hooks
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//components
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utils

const OrganizerCommunitySettings = ({
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //hooks
  const { OrganizerCommunitySettings } = useLanguageAccount();
  const { accountOrganizerDisplayContact } = useAccountReduxHandlers();

  //state
  const [organizerDisplayContact, setOrganizerDisplayContact] = useState(
    accountOrganizerDisplayContact
  );
  const [saveValid, setSaveValid] = useState(false);

  useEffect(() => {
    if (organizerDisplayContact !== accountOrganizerDisplayContact) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [organizerDisplayContact, accountOrganizerDisplayContact]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="organizerCommunityDisplayContactLabel"
      >
        {OrganizerCommunitySettings.communitySettings}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {OrganizerCommunitySettings.description}
      </p>

      <ButtonSwitch
        id={'organizerCommunityDisplayContactToggle'}
        loadValue={accountOrganizerDisplayContact}
        loadTrigger={true}
        handleSwitch={setOrganizerDisplayContact}
        reset={accountOrganizerDisplayContact}
        customWrapper={'mrg-t24'}
        title={OrganizerCommunitySettings.publicContactInfo}
        horizontal={true}
      />
      <div className="h24" />
      <SaveButton
        saveValid={saveValid}
        handler={() =>
          saveAccountChange(
            {
              organizer: {
                communitySettings: {
                  displayContact: organizerDisplayContact,
                },
              },
            },
            'organizerCommunityDisplayContact'
          )
        }
        isLoading={loadingSaveBtnUI === 'organizerCommunityDisplayContact'}
        saveAriaLanguage={OrganizerCommunitySettings.communitySettings}
      />
      <div className="h36" />
    </div>
  );
};

export default OrganizerCommunitySettings;
