import {
  img_muffin,
  img_drink,
  img_signing,
  img_clock2,
  img_microphone_on,
  img_pie_chart,
  img_social,
  img_lightbulb,
  star,
  img_play,
  img_enter,
  img_tools,
  img_comment,
  exchange,
  img_hourglass,
  img_question,
  img_beaker,
  img_award,
} from '../../assets/icons';

export const getAgendaItemIcon = (icon) => {
  switch (icon) {
    case 'muffin':
      return img_muffin;
    case 'hourglass':
      return img_hourglass;
    case 'drink':
      return img_drink;
    case 'speechBubble':
      return img_comment;
    case 'exchangeArrows':
      return exchange;
    case 'penSigning':
      return img_signing;
    case 'clock':
      return img_clock2;
    case 'microphone':
      return img_microphone_on;
    case 'pieChart':
      return img_pie_chart;
    case 'people':
      return img_social;
    case 'lightBulb':
      return img_lightbulb;
    case 'star':
      return star;
    case 'playButton':
      return img_play;
    case 'enterDoor':
      return img_enter;
    case 'tools':
      return img_tools;
    case 'questionMark':
      return img_question;
    case 'beaker':
      return img_beaker;
    case 'award':
      return img_award;
    default:
      return null;
  }
};
