//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

const ParticipantListItemName = ({ participant }) => {
  //hooks
  const { ParticipantListItem } = useLanguageOrganize();

  return (
    <div className="flex-row">
      <p className="flex-row fs16">
        {ParticipantListItem.participant} {participant?.index + 1}
      </p>

      <p className="flex-row fs16">
        {participant?.participantData?.eventRole &&
          ` | ${participant?.participantData?.eventRole}`}
      </p>

      <p className="flex-row fs16">
        {participant.organizedAs === 'emailInvitation' &&
          participant?.emailInvitation?.email?.length > 0 &&
          ` | ${participant?.emailInvitation?.email}`}
      </p>

      <p className="flex-row fs16">
        {(participant?.participantData?.personal?.prefix ||
          participant?.participantData?.personal?.firstName ||
          participant?.participantData?.personal?.lastName) &&
          ' | '}
        {participant?.participantData?.personal?.prefix &&
          `${participant?.participantData?.personal?.prefix} `}
        {participant?.participantData?.personal?.firstName &&
          `${participant?.participantData?.personal?.firstName} `}
        {participant?.participantData?.personal?.lastName &&
          ` ${participant?.participantData?.personal?.lastName}`}
      </p>
    </div>
  );
};

export default ParticipantListItemName;
