//hooks
import useLanguageComponents from '../../../language/useLanguageComponents';

//components

//utils
import { img_location } from '../../../assets/icons';

const ProfileLocation = ({ ProfilePersonal, speakerLocation, previewMode }) => {
  //hooks
  const { IconAlts } = useLanguageComponents();
  return (
    <>
      <div className={`${previewMode ? 'account-previewMode__outline' : ''}`}>
        {previewMode && (
          <div className="account-previewMode--outline-header">
            <p>{ProfilePersonal.viewableByOrganizers}</p>
          </div>
        )}

        <label
          className="content-heading-styled text-gray"
          tabIndex="0"
          id="tagsLabel"
        >
          {ProfilePersonal.location}
        </label>
        <div className="h12" />

        <div className="flex-row align-center" title={ProfilePersonal.location}>
          <img
            src={img_location}
            alt={IconAlts.iconLocationPin}
            style={{ height: '28px', width: '24px' }}
            className="mrg-r12"
          />
          <p className="fs16 access-ob" tabIndex="0">
            {speakerLocation}
          </p>
        </div>
      </div>

      <div className="h36" />
    </>
  );
};

export default ProfileLocation;
