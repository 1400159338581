import { useEffect, useState } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useSettings from '../../../../context/useSettings';
import { useSettingsHandlers } from '../useSettingsHandlers';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Components
import TagsList from './TagsList';
//Utility
import {
  date,
  distance,
  direction,
  bigtag,
  tinytag,
  folder,
  globe,
  building,
  social,
} from '../../../../assets/icons';

const FiltersMobile = () => {
  //Hooks
  const { SettingsEventsLanguage } = useLanguageSettings();
  const {
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    settingsSelectedBigTags: selectedBigTags,
    settingsSelectedTinyTags: selectedTinyTags,
    settingsSelectedTinyTagGroupIds: selectedTinyTagGroups,
    settingsSelectedAccountsFollowingIds: selectedAccountsFollowing,
    settingsSelectedAccountsFollowingGroupIds: selectedAccountsFollowingGroups,
    handleSettingsSetEventSearchDirection,
    handleSettingsSetEventSearchCategory,
    handleSettingsSetEventSearchFormat,
    settingsSelectedMyEvents,
  } = useSettingsHandlers();
  const {
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();
  const { handleMobileTap } = useSettings();

  //Component state
  const [openSearchSettings, setOpenSearchSettings] = useState(false);
  const [tapHighlightSettingsBorder, setTapHighlightSettingsBorder] =
    useState(false);

  //Component variables
  let searchDirectionExplained;

  //Component functions
  useEffect(() => {
    setOpenSearchSettings(false);
  }, [view]);

  function handleSettingsMenuClick(e) {
    handleMobileTap(
      [
        () => setTapHighlightSettingsBorder(true),
        () => setTapHighlightSettingsBorder(false),
      ],
      [() => handleSearchOptionsMenu(e)]
    );
  }

  function handleSearchOptionsMenu(e) {
    if (e.target.id) {
      setOpenSearchSettings(!openSearchSettings);
    }
  }

  //JSX conditional
  if (searchDirection) {
    if (searchDirection === 'ascending') {
      if (searchCategory === 'date') {
        searchDirectionExplained = `${SettingsEventsLanguage.earliest}`;
      }

      if (searchCategory === 'distance') {
        searchDirectionExplained = `${SettingsEventsLanguage.closest}`;
      }
    }

    if (searchDirection === 'descending') {
      if (searchCategory === 'date') {
        searchDirectionExplained = `${SettingsEventsLanguage.latest}`;
      }
      if (searchCategory === 'distance') {
        searchDirectionExplained = `${SettingsEventsLanguage.furthest}`;
      }
    }
  }

  let tinyTagsAria = `${
    SettingsEventsLanguage.ariaTinyTagsSelectionSummaryFolders +
    selectedTinyTagGroups?.length
  }. ${
    SettingsEventsLanguage.ariaTinyTagsSelectionSummaryTags +
    selectedTinyTags?.length
  }.`; //needed for period/pauses

  let communityAria = `${
    SettingsEventsLanguage.ariaCommunitySelectionSummaryFolders +
    selectedAccountsFollowingGroups?.length
  }. ${
    SettingsEventsLanguage.ariaCommunitySelectionSummary +
    selectedAccountsFollowing?.length
  }.`; //needed for period/pauses

  //remember, ids used to trigger opening modal.
  return (
    <div
      role="button"
      className={`filters-mobile filters-mobile-border br--standard no-select access-ob access-o6 ${
        tapHighlightSettingsBorder ? 'filters-mobile-border--tap' : ''
      }`}
      onClick={(e) => handleSettingsMenuClick(e)}
      id="view-selections--mobile"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSettingsMenuClick(e);
        }
      }}
      aria-expanded={openSearchSettings}
      aria-label={`${SettingsEventsLanguage.ariaEventFilters} 
      ${
        view === 'myevents' && settingsSelectedMyEvents.includes('saved')
          ? SettingsEventsLanguage.savedEvents
          : ''
      }
      ${
        view === 'myevents' && settingsSelectedMyEvents.includes('registered')
          ? SettingsEventsLanguage.registeredEvents
          : ''
      }

     ${
       view === 'myevents' &&
       settingsSelectedMyEvents.includes('participationEvents')
         ? SettingsEventsLanguage.participationEvents
         : ''
     }
      ${
        view === 'bigtags'
          ? SettingsEventsLanguage.ariaBigTagsSelectionSummary +
            selectedBigTags?.length
          : ''
      }
      ${view === 'tinytags' ? tinyTagsAria : ''}
      ${view === 'community' ? communityAria : ''}
      `}
    >
      <div
        className="top-btn-bar fs18 fwsb full-width pad-6 disable-select"
        id="top-btn-bar"
      >
        <div className="flex-row flex-center">
          <div
            className={`arrow mrg-l12 ${
              !openSearchSettings ? 'rotate-ccw90' : ''
            }`}
            id="arrow"
          />
          <p id="search-options-title" className="mrg-l12">
            {!openSearchSettings && SettingsEventsLanguage.filters}
          </p>
        </div>
        <div className="icon-summary">
          {view === 'myevents' && (
            <p className="fs21 fwb color-theme mrg-r12">
              {`${settingsSelectedMyEvents?.length} / 3`}
            </p>
          )}
          {view === 'bigtags' && (
            <>
              <h1 className="fs21 fwb color-theme" id="tag-sum">
                {selectedBigTags?.length}
                {selectedBigTags?.length < accountBigTags?.length &&
                  `/${accountBigTags?.length}`}
              </h1>
              <img
                id="bT-icon"
                src={bigtag}
                title={SettingsEventsLanguage.numberOfTagsSelected}
                alt={SettingsEventsLanguage.numberOfTagsSelected}
                className="bT-icon svg"
              />
            </>
          )}
          {view === 'tinytags' && (
            <>
              <h1 className="fs21 fwb color-theme">
                {selectedTinyTagGroups?.length}
                {selectedTinyTagGroups?.length < accountTinyTagGroups?.length &&
                  `/${accountTinyTagGroups?.length}`}
              </h1>
              <img
                id="tG-icon"
                src={folder}
                className="tG-icon filter-theme mrg-lr12 svg"
                title={SettingsEventsLanguage.numberOfTagGroupsSelected}
                alt={SettingsEventsLanguage.numberOfTagGroupsSelected}
              />
              <h1 className="fs21 fwb color-theme">
                {selectedTinyTags?.length}
                {selectedTinyTags?.length < accountTinyTags?.length &&
                  `/${accountTinyTags?.length}`}
              </h1>
              <img
                id="tT-icon"
                src={tinytag}
                title={SettingsEventsLanguage.numberOfTagsSelected}
                alt={SettingsEventsLanguage.numberOfTagsSelected}
                className="tT-icon svg"
              />
            </>
          )}
          {view === 'community' && (
            <>
              <h1 className="fs21 fwb color-theme">
                {selectedAccountsFollowingGroups?.length}
                {selectedAccountsFollowingGroups?.length <
                  accountCommunityFollowingGroups?.length &&
                  `/${accountCommunityFollowingGroups?.length}`}
              </h1>
              <img
                id="tG-icon"
                src={folder}
                className="tG-icon filter-theme mrg-lr12 svg"
                title={SettingsEventsLanguage.numberOfCommunityGroupsSelected}
                alt={SettingsEventsLanguage.numberOfCommunityGroupsSelected}
              />
              <h1 className="fs21 fwb color-theme">
                {selectedAccountsFollowing?.length}
                {selectedAccountsFollowing?.length <
                  accountCommunityFollowing?.length &&
                  `/${accountCommunityFollowing?.length}`}
              </h1>
              <img
                id="tT-icon"
                src={social}
                title={SettingsEventsLanguage.numberOfCommunityAccountsSelected}
                alt={SettingsEventsLanguage.numberOfCommunityAccountsSelected}
                className="tT-icon svg"
              />
            </>
          )}
        </div>
      </div>
      {openSearchSettings && (
        <div className="mrg-lr16 mrg-b16" id="filtersMobile-container">
          {view !== 'myevents' && (
            <>
              <h2 className="fs16 mrg-b12 color-black fit-content">
                {searchFormat === 'inPerson'
                  ? SettingsEventsLanguage.searchFormatInPerson
                  : SettingsEventsLanguage.searchFormatOnline}
              </h2>
              <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
                <li>
                  <button
                    className="access-ob access-o6"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSettingsSetEventSearchFormat('inPerson');
                    }}
                    title={SettingsEventsLanguage.searchFormatInPerson}
                    aria-pressed={searchFormat === 'inPerson'}
                  >
                    <img
                      src={building}
                      alt={SettingsEventsLanguage.searchFormatInPerson}
                      className={`svg ${
                        searchFormat === 'inPerson' ? 'selected' : ''
                      }`}
                    />
                  </button>
                </li>
                <li>
                  <button
                    className="access-ob access-o6"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSettingsSetEventSearchFormat('online');
                      handleSettingsSetEventSearchCategory('date');
                    }}
                    title={SettingsEventsLanguage.searchFormatOnline}
                    aria-pressed={searchFormat === 'online'}
                  >
                    <img
                      src={globe}
                      alt={SettingsEventsLanguage.searchFormatOnline}
                      className={`svg ${
                        searchFormat === 'online' ? 'selected' : ''
                      }`}
                    />
                  </button>
                </li>
              </ul>
            </>
          )}

          <h2 className="fs16 mrg-b12 color-black fit-content">
            {searchDirectionExplained}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSettingsSetEventSearchDirection('ascending');
                }}
                title={SettingsEventsLanguage.searchAscending}
                aria-pressed={searchDirection === 'ascending'}
              >
                <img
                  src={direction}
                  alt={SettingsEventsLanguage.ascending}
                  className={`svg ${
                    searchDirection === 'ascending' ? 'selected' : ''
                  } reverse`}
                />
              </button>
            </li>
            <li>
              <button
                className="access-ob access-o6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSettingsSetEventSearchDirection('descending');
                }}
                title={SettingsEventsLanguage.searchDescending}
                aria-pressed={searchDirection === 'descending'}
              >
                <img
                  src={direction}
                  alt={SettingsEventsLanguage.descending}
                  className={`svg ${
                    searchDirection === 'descending' ? 'selected' : ''
                  }`}
                />
              </button>
            </li>
          </ul>
          <h2 className="fs16 mrg-b12 color-black fit-content">
            {searchCategory === 'date'
              ? SettingsEventsLanguage.date
              : SettingsEventsLanguage.distance}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSettingsSetEventSearchCategory('date');
                }}
                title={SettingsEventsLanguage.searchDate}
                aria-pressed={searchCategory === 'date'}
              >
                <img
                  src={date}
                  alt={SettingsEventsLanguage.searchDate}
                  className={`svg ${
                    searchCategory === 'date' ? 'selected' : ''
                  }`}
                />
              </button>
            </li>
            {searchFormat === 'inPerson' && (
              <li>
                <button
                  className="access-ob access-o6"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSettingsSetEventSearchCategory('distance');
                  }}
                  title={SettingsEventsLanguage.searchDistance}
                  aria-pressed={searchCategory === 'distance'}
                >
                  <img
                    src={distance}
                    alt={SettingsEventsLanguage.searchDistance}
                    className={`svg ${
                      searchCategory === 'distance' ? 'selected' : ''
                    }`}
                  />
                </button>
              </li>
            )}
          </ul>
          <TagsList />
        </div>
      )}
    </div>
  );
};

export default FiltersMobile;
