import React from 'react';

//hooks
import { useRegistrationHandlers } from '../useRegistrationHandlers';
import useLanguageRegistration from '../../../../language/features/useLanguageRegistration';

//state

//utils
import {
  checkmark_green,
  exchange,
  icon_profile,
  img_credit_card,
  mail,
  search_dark,
} from '../../../../assets/icons';

const CompletionTracker = React.memo(() => {
  const {
    registrationPageStatus,
    registrationPageIndex,
    registrationComponentArr,
  } = useRegistrationHandlers();
  const { CompletionTracker } = useLanguageRegistration();

  function returnIcon(iconIndex) {
    if (iconIndex === 'personalAccountInfo') {
      return (
        <img
          className="filter-gray progress-col-icon"
          src={icon_profile}
          alt={CompletionTracker.account}
          title={CompletionTracker.account}
        />
      );
    } else if (iconIndex === 'translationRoomCodeRegistration') {
      return (
        <img
          className="filter-gray progress-col-icon"
          src={exchange}
          alt={CompletionTracker.translationRoom}
          title={CompletionTracker.translationRoom}
        />
      );
    } else if (iconIndex === 'billing') {
      return (
        <img
          className="filter-gray progress-col-icon"
          src={img_credit_card}
          alt={CompletionTracker.billing}
          title={CompletionTracker.billing}
        />
      );
    } else if (iconIndex === 'personalSearchSettings') {
      return (
        <img
          className="filter-gray progress-col-icon"
          src={search_dark}
          alt={CompletionTracker.eventSearches}
          title={CompletionTracker.eventSearches}
        />
      );
    } else if (iconIndex === 'emailPreferences') {
      return (
        <img
          className="filter-gray progress-col-icon"
          src={mail}
          alt={CompletionTracker.emailPreferences}
          title={CompletionTracker.emailPreferences}
        />
      );
    }
  }

  return (
    <>
      <div className="registration-progress-tracker flex-row full-width flex-center mrg-t12 mrg-b24">
        {registrationPageStatus?.map((registrationStatus, index) => {
          if (registrationPageIndex - 3 === index) {
            return (
              <div
                className={`progress-col ${index !== 0 ? 'mrg-l24' : ''} `}
                key={`dot-${index}`}
              >
                <div
                  className={`dot mrg-b12 ${
                    registrationPageIndex - 3 === index
                      ? 'bg-color-theme'
                      : 'bg-color-lightgray'
                  }  `}
                />
                {returnIcon(registrationComponentArr[index])}
              </div>
            );
          }
          if (registrationStatus === 'complete') {
            return (
              <div
                className={`progress-col ${index !== 0 ? 'mrg-l24' : ''} `}
                key={`dot-${index}`}
              >
                <img
                  alt={CompletionTracker.checkmark}
                  src={checkmark_green}
                  className={`mrg-b12`}
                  key={`checkmark-${index}`}
                />
                {returnIcon(registrationComponentArr[index])}
              </div>
            );
          } else {
            return (
              <div
                className={`progress-col ${index !== 0 ? 'mrg-l24' : ''} `}
                key={`dot-${index}`}
              >
                <div
                  className={`dot mrg-b12 ${
                    registrationPageIndex - 3 === index
                      ? 'bg-color-theme'
                      : 'bg-color-lightgray'
                  }  `}
                />
                {returnIcon(registrationComponentArr[index])}
              </div>
            );
          }
        })}
      </div>
      <div className="divider mrg-b24" />
    </>
  );
});

export default CompletionTracker;
