import { useState } from 'react';
//Hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';

//Components

//Utility
import ScrollTo from '../../utils/UI/ScrollTo';
import { double_arrow } from '../../assets/icons';

const ScrollToTop = ({ large, focusId }) => {
  //Hooks
  const { BackToTop } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  //Component functions
  function handleClick() {
    ScrollTo(focusId, 'smooth', true);
  }

  return (
    <button
      type="button"
      className={`flex-row flex-center color-gray mrg-auto-lr highlight-bthin-theme br--small pad-tb12 pad-lr12 fit-content fwsb   fwn br--standard access-ob access-o6 fs18 ${
        tapHighlight ? 'highlight-b-theme--tap' : ''
      }`}
      onClick={() =>
        handleMobileTap(
          [() => setTapHighlight(true), () => setTapHighlight(false)],
          [() => handleClick()]
        )
      }
      tabIndex="0"
      aria-label={BackToTop.ariaBackToTop}
    >
      {BackToTop.backToTop}
      <img
        src={double_arrow}
        className="mrg-l8 filter-gray"
        alt="arrow"
        style={{ height: `18px`, marginTop: '1px' }}
      />
    </button>
  );
};

export default ScrollToTop;
