import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//components
import TextArea from '../../../../../components/TextArea/TextArea';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//utils

const PersonalBio = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { accountPersonalBio: loadPersonalBio, accountPersonal } =
    useAccountReduxHandlers();

  const [newPersonalBio, setNewPersonalBio] = useState(loadPersonalBio);
  const [saveValid, setSaveValid] = useState(false);

  useEffect(() => {
    if (newPersonalBio?.length > 0 && newPersonalBio !== loadPersonalBio) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [newPersonalBio, loadPersonalBio]);

  useEffect(() => {
    if (loadPersonalBio !== newPersonalBio) {
      setNewPersonalBio(loadPersonalBio);
    }
  }, [loadPersonalBio]); //set new values after save

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="personalBioLabel"
        tabIndex="0"
      >
        {Account.account.personalBio}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.account.personalBioDescription}
      </p>

      <TextArea
        handleChange={setNewPersonalBio}
        limit={3000}
        id={'personalBio'}
        large={true}
        loadTrigger={true}
        loadValue={loadPersonalBio}
        labelText={Account.account.personalBio}
        disableEnter={false}
        customWrapper={'mrg-t24'}
      />

      <div className="h24" />
      <SaveButton
        saveValid={saveValid}
        saveAriaLanguage={Account.account.personalBio}
        handler={() =>
          saveAccountChange(
            {
              personal: {
                ...accountPersonal,
                bio: newPersonalBio,
              },
            },
            'personalBio'
          )
        }
        isLoading={loadingSaveBtnUI === 'personalBio'}
      />

      <div className="h36" />
    </div>
  );
};

export default PersonalBio;

// Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere nostrum dolorum aspernatur quidem ducimus, culpa eum vel, consequuntur maiores cum totam doloremque adipisci, sed soluta et dolorem minus excepturi animi.

// Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius distinctio nesciunt laboriosam ipsa aperiam reiciendis earum tempora! Odit saepe sequi, non mollitia, reprehenderit magni est praesentium error, accusantium illum necessitatibus?
