import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useApiStatus from '../../../../../context/useApiStatus';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import DistanceInput from '../../../../../components/DistanceInput/DistanceInput';
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';

//utils
import {
  bigtag_nobg,
  cancel,
  checkmark_green,
  img_language,
  img_location,
} from '../../../../../assets/icons';

const AttendeeSignUp = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { PersonalSearchSettings } = useLanguageRegistration();
  const {
    SearchLanguageOpts,
    CountriesOpts,
    BigTagsOpts,
    BigTagsOptsWithSubText,
  } = useLanguageData();
  const { Generic } = useLanguageComponents();
  const { handleError } = useApiStatus();
  const { AttendeeSignUp } = useLanguageAccount();

  //state
  const [attendeeSignUpValid, setAttendeeSignUpValid] = useState(false);

  //state - fields
  const [bigTags, setBigTags] = useState([]);
  const [coords, setCoords] = useState('');
  const [distanceFilter, setDistanceFilter] = useState(30);
  const [distanceMeasurement, setDistanceMeasurement] = useState('km');
  const [eventSearchOnlineCountries, setEventSearchOnlineCountries] = useState(
    []
  );
  const [placeCoordinates, setPlaceCoordinates] = useState({
    lat: '',
    lng: '',
  });
  const [searchLanguages, setSearchLanguages] = useState([]);
  const [tinyTagGroups, setTinyTagGroups] = useState([]);
  const [tinyTags, setTinyTags] = useState([]);

  //initialize
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, []); ///set default position to user's location

  useEffect(() => {
    if (
      bigTags?.length > 0 &&
      (tinyTags?.length > 0 ||
        tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) &&
      searchLanguages?.length > 0 &&
      !searchLanguages.includes('select') &&
      !eventSearchOnlineCountries.includes('select') &&
      coords
    ) {
      setAttendeeSignUpValid(true);
    } else {
      setAttendeeSignUpValid(false);
    }
    /*eslint-disable-next-line*/
  }, [bigTags, tinyTags, coords, searchLanguages]); //valid data for continue

  useEffect(() => {
    if (!coords && placeCoordinates?.lat && placeCoordinates?.lng) {
      let newCoords = [placeCoordinates?.lat, placeCoordinates?.lng];
      setCoords(newCoords);
    }
    /*eslint-disable-next-line*/
  }, [placeCoordinates]);

  //functions
  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setPlaceCoordinates(currentPosition);
  };

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  async function formatAttendeeDataThenSave() {
    let newDistance;
    if (distanceMeasurement === 'km') {
      newDistance = distanceFilter * 1000;
    } else {
      newDistance = parseInt(distanceFilter * 1609.344);
    }

    if (!coords[0] || !coords[1]) {
      handleError({
        message: 'dataNotFound',
        id: Date.now(),
        origin: 'AttendeeSignUp/formatAttendeeDataThenSave',
      });
    }

    let backendGeoPosition = {
      type: 'Point',
      coordinates: [coords[0], coords[1]],
    };

    let newData = {
      attendee: {
        attendeeModeActive: true,
        eventSearchOnlineCountries,
        distanceFilter: newDistance,
        distanceMeasurement,
        geoPosition: backendGeoPosition,
        searchLanguages,
        tags: {
          bigTags,
          tinyTags,
          tinyTagGroups,
        },
      },
    };
    await saveAccountChange(newData, 'attendeeSignUp');
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="attendeeSignUpLabel"
      >
        {AttendeeSignUp.attendeeSignUp}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {AttendeeSignUp.instructions}
      </p>

      <div className="h36" />
      <div className="border-reg pad-24 br--standard" id="fieldset-access">
        <div className="h12" />
        <label
          aria-label={PersonalSearchSettings.ariaBigTags}
          className="content-heading-styled text-gray flex-row align-center full-width"
          id="bigTagsLabel"
          tabIndex="0"
        >
          {PersonalSearchSettings.bigTagsLabel}
          <p className="color-theme disable-select" aria-hidden="true">
            &nbsp;*
          </p>
          <div className="flex-row mrg-auto-left">
            <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
            {bigTags?.length > 0 && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>
        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={PersonalSearchSettings.ariaBigTagsInputInstructions}
        >
          {PersonalSearchSettings.bigTagsDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={`${Generic.add} ${Generic.bigTags}`}
          DataOpts={BigTagsOptsWithSubText}
          DataOptsSubTextTranslations={BigTagsOpts}
          fullWidth={true}
          iconModal={bigtag_nobg}
          iconOff={true}
          limit={20}
          modalTitle={Generic.bigTags}
          selectionState={bigTags}
          setSelectionState={setBigTags}
          subTextOn={true}
          id={'bigTagSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaTinyTags}
          className="content-heading-styled text-gray flex-row align-center full-width"
          id="tinyTagsLabel"
          tabIndex="0"
        >
          {PersonalSearchSettings.tinyTagsLabel}
          <p className="color-theme disable-select" aria-hidden="true">
            *
          </p>
          <div className="flex-row mrg-auto-left">
            <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
            {(tinyTags?.length > 0 ||
              tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.tinyTagsDescription}
        </p>
        <div className="h24" />
        <CustomTagsList
          id={'tinyTags'}
          limit={200}
          tinyTags={tinyTags}
          setTinyTags={setTinyTags}
          twoTagRows={true}
          tinyTagGroups={tinyTagGroups}
          setTinyTagGroups={setTinyTagGroups}
          bigTags={bigTags}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaEventSearchInPerson}
          className="content-heading-styled text-gray flex-row align-center full-width"
          id="searchLocationLabel"
          tabIndex="0"
        >
          {PersonalSearchSettings.eventSearchInPerson}
          <p className="color-theme disable-select" aria-hidden="true">
            &nbsp;*
          </p>
          <div className="flex-row mrg-auto-left">
            <p className="fs14 mrg-auto-left">{Generic.required}</p>
            {distanceMeasurement && placeCoordinates && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.eventSearchInPersonDescription}
        </p>
        <div className="h24" />
        {placeCoordinates && (
          <MapContainerWithDistanceFilter
            distanceFilter={distanceFilter}
            distanceMeasurement={distanceMeasurement}
            handler={setCoords}
            loadTrigger={true}
            loadValue={coords}
            placeCoordinates={placeCoordinates}
          />
        )}
        <div className="h24" />
        <AutocompletePlacesNoAddress
          setAutocompleteCoordinates={setAutocompleteCoordinates}
        />
        <div className="h24" />
        <DistanceInput
          distanceFilter={distanceFilter}
          setDistanceFilter={setDistanceFilter}
          distanceMeasurement={distanceMeasurement}
          setDistanceMeasurement={setDistanceMeasurement}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaEventSearchOnline}
          className="content-heading-styled text-gray flex-row"
          id="eventSearchOnlineLabel"
          tabIndex="0"
        >
          {PersonalSearchSettings.eventSearchOnlineLabel}
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.eventSearchOnlineDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={PersonalSearchSettings.addCountries}
          DataOpts={CountriesOpts}
          fullWidth={true}
          iconModal={img_location}
          iconModalClass={'filter-theme'}
          iconOff={true}
          modalTitle={PersonalSearchSettings.eventSearchOnlineLabel}
          selectionState={eventSearchOnlineCountries}
          setSelectionState={setEventSearchOnlineCountries}
          subTextOn={false}
          id={'locationCountrySelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaSearchLanguages}
          className="content-heading-styled text-gray flex-row align-center full-width"
          id="searchLanguagesLabel"
          tabIndex="0"
        >
          {PersonalSearchSettings.searchLanguagesLabel}
          <p className="color-theme disable-select" aria-hidden="true">
            &nbsp;*
          </p>
          <div className="flex-row mrg-auto-left">
            {searchLanguages?.length >= 2 &&
            searchLanguages.includes('select') ? (
              <p className="fs14 mrg-auto-left">{Generic.incomplete}</p>
            ) : (
              <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
            )}

            {searchLanguages?.length > 0 &&
            !searchLanguages.includes('select') ? (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            ) : searchLanguages?.length >= 2 &&
              searchLanguages.includes('select') ? (
              <img
                src={cancel}
                alt={Generic.checkmark}
                className="mrg-l6 filter-red"
              />
            ) : null}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.searchLanguagesDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={PersonalSearchSettings.addLanguages}
          DataOpts={SearchLanguageOpts}
          fullWidth={true}
          iconModal={img_language}
          iconModalClass={'filter-theme'}
          iconOff={true}
          modalTitle={PersonalSearchSettings.searchLanguagesLabel}
          selectionState={searchLanguages}
          setSelectionState={setSearchLanguages}
          subTextOn={false}
          id={'searchLanguagesSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />

        <div className="h36" />

        <SaveButton
          saveValid={attendeeSignUpValid}
          saveAriaLanguage={'Attendee Sign up'}
          handler={formatAttendeeDataThenSave}
          isLoading={loadingSaveBtnUI}
        />
        <div className="h12" />
      </div>
    </>
  );
};
export default AttendeeSignUp;
