import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useSettings from '../../../../context/useSettings';
import useGenericModal from '../../../../context/useGenericModal';
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';

//components

//utility
import {
  cancel,
  cancel_red,
  checkmark_green,
  exchange,
} from '../../../../assets/icons';

const TranslationsRoomGuideModal = () => {
  const { Generic } = useLanguageComponents();
  const { handleMobileTap, isSafari } = useSettings();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { TranslationRoomGuide } = useLanguageTranslationRoom();

  //state
  const [supportsSpeechRecognition, setSupportsSpeechRecogntion] = useState(
    'SpeechRecognition' in window || 'webkitSpeechRecognition' in window
      ? true
      : false
  );
  const [microphoneAccess, setMicrophoneAccess] = useState(false);

  //ui
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightRemoveBtn, setTapHighlightRemoveBtn] = useState(false);

  //variables
  const modalEl = document.getElementById('translationsRoomGuideModal');

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('translationRoomHeader');
  }, []);

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'microphone',
        });
        setMicrophoneAccess(permissionStatus.state === 'granted');
      } catch (error) {
        console.error('Error checking microphone permission:', error);
      }
    };

    checkMicrophonePermission(); // Check permission initially

    // Periodically check permission status
    const permissionInterval = setInterval(checkMicrophonePermission, 1000); // adjust interval as needed

    return () => {
      clearInterval(permissionInterval); // Clean up: clear interval
    };
  }, []);

  useEffect(() => {
    if (modalEl) {
      modalEl.focus();
    }
  }, [modalEl]);

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="translationsRoomGuideModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <img
            src={exchange}
            alt="arrows"
            className="svg mrg-r12 filter-gray"
            aria-hidden={true}
            style={{ width: '26px' }}
          />
          <label
            className="color-black fs18 fwsb"
            id={`selectionDropdownModalLabel`}
          >
            {TranslationRoomGuide.translationRoomGuide}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob ${
            tapHighlightCloseBtn ? 'filter-img-red' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBtn(true),
                () => setTapHighlightCloseBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img
            src={cancel}
            alt={Generic.close}
            className="svg"
            aria-hidden={true}
          />
        </button>
      </div>

      <div
        className="modal-basic__container translations-guide list-style-none"
        id="selectDropdownList"
      >
        <label
          htmlFor="requirements"
          className="access-ob access-o6 mrg-t12"
          tabIndex="0"
        >
          {TranslationRoomGuide.technicalChecklist}
        </label>
        <ul id="requirements list-style-none">
          <li className="flex-row mrg-t6 access-ob access-o6" tabIndex="0">
            {supportsSpeechRecognition ? (
              <img
                src={checkmark_green}
                alt="checkmark"
                className="mrg-r12 svg"
                aria-hidden={true}
              />
            ) : (
              <img
                src={cancel_red}
                alt="X"
                className="mrg-r12 svg"
                aria-hidden={true}
              />
            )}
            <p>{TranslationRoomGuide.supportedBrowsers}</p>
          </li>
          <li className="flex-row access-ob access-o6" tabIndex="0">
            {microphoneAccess ? (
              <img
                src={checkmark_green}
                alt="checkmark"
                className="mrg-r12 svg"
                aria-hidden={true}
              />
            ) : (
              <img
                src={cancel}
                alt="checkmark"
                className="mrg-r12 filter-red svg"
                aria-hidden={true}
              />
            )}
            <p>{TranslationRoomGuide.microphoneAccess}</p>
          </li>
        </ul>

        <label
          htmlFor="basics"
          className="mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          {TranslationRoomGuide.basics}
        </label>
        <ul id="basics" className="mrg-l24">
          <li className="mrg-t6 access-ob access-o6" tabIndex="0">
            <p>
              <span className="fwsb">{TranslationRoomGuide.roomCode}</span>{' '}
              {TranslationRoomGuide.roomCode2}
            </p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>
              <span className="fwsb">{TranslationRoomGuide.attendance}</span>{' '}
              {TranslationRoomGuide.attendance2}
            </p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>
              <span className="fwsb">{TranslationRoomGuide.attendees}</span>{' '}
              {TranslationRoomGuide.attendeeSelectTranslation}
            </p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>
              <span className="fwsb">
                {TranslationRoomGuide.translationText1}
              </span>{' '}
              {TranslationRoomGuide.translationText2}
            </p>
          </li>
        </ul>

        <label
          htmlFor="tips"
          className="mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          {TranslationRoomGuide.tips}
        </label>
        <ul id="tips" className="mrg-l24">
          <li className="mrg-t6 access-ob access-o6" tabIndex="0">
            <p>{TranslationRoomGuide.tips1}</p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>{TranslationRoomGuide.tips2}</p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>{TranslationRoomGuide.tips3}</p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>{TranslationRoomGuide.tips4}</p>
          </li>
          <li tabIndex="0" className="access-ob access-o6">
            <p>{TranslationRoomGuide.tips5}</p>
          </li>
        </ul>
        <div
          className={`${
            isSafari ? 'modal-bottom-spacer--safari' : 'modal-bottom-spacer'
          }`}
        />
      </div>
      <div className="modal-basic__footer">
        <button
          type="button"
          className={`mrg-auto color-black highlight-it-theme access-ob access-o6 fs18 fwsb ${
            tapHighlightRemoveBtn ? 'color-theme' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightRemoveBtn(true),
                () => setTapHighlightRemoveBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
        >
          <p>{Generic.close}</p>
        </button>
      </div>
    </div>
  );
};

export default TranslationsRoomGuideModal;
