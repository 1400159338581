import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useGenericModal from '../../../../context/useGenericModal';
import useSettings from '../../../../context/useSettings';

//components

//utils
import {
  cancel,
  checkmark,
  checkmark_green,
  warning,
} from '../../../../assets/icons';

const ListModal = ({
  addButtonText,
  handleAddOptsModal,
  iconModal,
  iconModalClass,
  id,
  limit,
  listData,
  modalTitle,
  selectionState,
  subTextOn,
}) => {
  //hooks
  const { Generic } = useLanguageComponents();
  const {
    handleCloseModal,
    setCloseModalFocusId,
    setGenericModalSelectionState,
    setGenericModalSelectionStateId,
  } = useGenericModal();
  const { handleMobileTap, width } = useSettings();

  //state
  const [filteredList, setFilteredList] = useState();
  const [addedSelections, setAddedSelections] = useState([]);
  const [limitWarning, setLimitWarning] = useState(false);

  //UI state
  const [tapHighlightComplete, setTapHighlightComplete] = useState(false);
  const [tapHighlightExitBtn, setTapHighlightExitBtn] = useState(false);

  //Component variables
  let optsList;

  //Initialize
  useEffect(() => {
    setCloseModalFocusId(`${id}-itemButton`);
  }, []); //focus on item button when modal closes

  useEffect(() => {
    setGenericModalSelectionStateId(id);
  }, [id]);

  useEffect(() => {
    if (selectionState) {
      const filteredList = Object.keys(listData).reduce((acc, key) => {
        if (!selectionState.includes(key)) {
          acc[key] = listData[key];
        }
        return acc;
      }, {});
      setFilteredList(filteredList);
    }
  }, [selectionState]); //removes selections from list

  //UI
  useEffect(() => {
    if (selectionState?.length + addedSelections?.length > limit) {
      setLimitWarning(true);
    } else {
      if (limitWarning) {
        setLimitWarning(false);
      }
    }
  }, [addedSelections]);

  useEffect(() => {
    if (filteredList && id) {
      document.getElementById(`${id}-label`).focus();
    }
  }, [filteredList]); //focus on modal label when opened

  //Component functions
  function handleClickOpt(key) {
    if (addedSelections?.includes(key)) {
      let updatedAddedSelections = addedSelections.filter(
        (existingTag) => existingTag !== key
      );
      return setAddedSelections(updatedAddedSelections);
    }

    let updatedAddedSelections = [...addedSelections, key];
    setAddedSelections(updatedAddedSelections);
  }

  useEffect(() => {
    if (setGenericModalSelectionState) {
      setGenericModalSelectionState(addedSelections);
    }
  }, [addedSelections]);

  //JSX conditional
  if (filteredList) {
    let lastItemIndex = Object.entries(filteredList).length - 1;

    optsList = (
      <>
        {Object.entries(filteredList).map(([key, value], index) => {
          const isLastItem = index === lastItemIndex;
          if (key === 'select') return;
          return (
            <li
              key={`${key}`}
              style={isLastItem ? { marginBottom: '160px' } : null}
            >
              <button
                className={`item item--w-subText space-between access-ob access-o6 ${
                  addedSelections.includes(key) ? 'item-selected-multiple' : ''
                }`}
                onClick={() => handleClickOpt(key)}
                type="button"
                tabIndex="0"
                aria-label={
                  subTextOn ? `${value.mainText} ${value.subText}` : value
                }
                id={`list-item-${index}`}
                aria-pressed={addedSelections.includes(key)}
              >
                {subTextOn ? (
                  <div className="flex-column">
                    <p className="fs18 fwsb">{value.mainText}</p>
                    <p className="fs16 italic">{value.subText}</p>
                  </div>
                ) : (
                  <div className="flex-column">
                    <p className="fs18 fwsb">{value}</p>
                  </div>
                )}

                {addedSelections?.includes(key) && (
                  <img
                    src={checkmark_green}
                    alt={Generic.checkmark}
                    className="mrg-r12"
                  />
                )}
              </button>
            </li>
          );
        })}
      </>
    );
  }

  return (
    <div className="modal-basic__wrapper access-ow access-o12" tabIndex="0">
      <div className="heading">
        <div className="flex-row flex-center">
          <img
            src={iconModal}
            alt={modalTitle}
            className={`mrg-r12 ${iconModalClass ? iconModalClass : ''}`}
          />
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`${id}-label`}
          >
            {modalTitle}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob  ${
            tapHighlightExitBtn ? 'filter-img-red' : 'filter-img-lightgray'
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightExitBtn(true),
                () => setTapHighlightExitBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={Generic.close} className="svg" />
        </button>
      </div>
      <ul className="modal-basic__container list-style-none" id="bigTagsList">
        {optsList}
      </ul>
      <div className="modal-basic__footer">
        {limitWarning && (
          <div className="modal-warning-banner access-ob" tabIndex="0">
            <div className="flex-row flex-center">
              <p className="fwsb fs18 mrg-r12">{Generic.overLimit}</p>
              <img
                src={warning}
                alt={Generic.warning}
                className="svg"
                style={{ maxHeight: '28px' }}
              />
            </div>
          </div>
        )}

        {!limitWarning && width <= 480 && (
          <button
            className={`color-black access-ob access-o6 highlight-i-lgg ${
              tapHighlightComplete ? 'filter-img-green' : 'filter-img-lightgray'
            }}`}
            onClick={
              filteredList
                ? () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [
                        () => handleAddOptsModal(addedSelections),
                        () => handleCloseModal(),
                      ]
                    )
                : () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [() => handleCloseModal()]
                    )
            }
            aria-label={`${addButtonText} ${addedSelections?.length || 0}`}
          >
            {filteredList ? (
              <>
                <p className="fs18 fwn ">
                  {addButtonText} ({addedSelections?.length || 0})
                </p>
                <img
                  src={checkmark}
                  alt={Generic.checkmark}
                  style={{ minHeight: '21px' }}
                  className="mrg-l12 svg"
                />
              </>
            ) : (
              <p className="fs18 fwsb access-ob access-o6">{Generic.close}</p>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default ListModal;
