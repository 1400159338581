import { apiSlice } from '../../../app/api/apiSlice';
import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectId } from '../account/accountSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import {
  setCreateEvent,
  setCreateEventMetaId,
} from './create/createEventSlice';

const draftEventsAdapter = createEntityAdapter({
  selectId: (account) => account._id,
});

const initialState = draftEventsAdapter.getInitialState();

export const draftEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyDraft: builder.query({
      query: ({ accountId, draftId }) => {
        return {
          url: `/api/organize/draft/myDraft`,
          params: {
            accountId,
            draftId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setCreateEvent(data?.data));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(error);
          }
        }
      },
    }),
    myDraftEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/draft/mydrafts',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return draftEventsAdapter.setAll(initialState, response.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/myDraftEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['Draft'],
      staleTime: 999999,
    }),
    createDraftEvent: builder.mutation({
      query: ({ body }) => {
        return {
          url: '/api/organize/draft',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(
        { handleRemoveEventImagesForAPIFailure },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setCreateEventMetaId(data?.data?.newDraft?._id));
        } catch (error) {
          if (error?.error?.error?.failureData) {
            const failureData = {
              eventImageDisplayPath:
                error?.error?.error?.failureData?.eventImageDisplayPath,
              eventAdditionalImagesPaths:
                error?.error?.error?.failureData?.eventAdditionalImagesPaths,
              participantImagesPaths:
                error?.error?.error?.failureData?.participantImagesPaths,
            };
            handleRemoveEventImagesForAPIFailure(failureData);
          }
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/createDraftEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
    updateDraftEvent: builder.mutation({
      query: (body) => ({
        url: `/api/organize/updateDraft`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/updateDraftEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
    removeDraftEvent: builder.mutation({
      query: ({ accountId, draftId }) => ({
        url: `/api/organize/draft`,
        method: 'DELETE',
        body: { accountId, draftId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/removeDraftEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
  }),
});

export const {
  useLazyGetMyDraftQuery,
  useMyDraftEventsQuery,
  useLazyMyDraftEventsQuery,
  useCreateDraftEventMutation,
  useUpdateDraftEventMutation,
  useRemoveDraftEventMutation,
} = draftEventsApiSlice;

export const useDraftSelectors = () => {
  const accountId = useSelector(selectId);

  const selectDraftEventsResult =
    draftEventsApiSlice.endpoints.myDraftEvents.select(accountId);

  const selectMyDraftEventsData = createSelector(
    selectDraftEventsResult,
    (result) => result.data
  );
  const {
    selectAll: selectAllDraftEvents,
    selectById: selectDraftEventById,
    selectIds: selectDraftEventIds,
  } = draftEventsAdapter.getSelectors(
    (state) => selectMyDraftEventsData(state) ?? initialState
  );

  return {
    selectAllDraftEvents,
    selectDraftEventById,
    selectDraftEventIds,
  };
};
