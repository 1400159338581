import { useEffect, useRef, useState } from 'react';

//Hooks
import useLanguageData from '../../../../language/useLanguageData';

//Components
import SelectDropdownTimezones from '../../../../components/SelectDropdown/SelectDropdownTimezones';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import TextArea from '../../../../components/TextArea/TextArea';
import Selections from '../../../../components/Selections/Selections';
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import SelectionsModal from '../../../../components/Selections/SelectionsModal';
import CalendarSimple from '../../../../components/CalendarSimple/CalendarSimple';

//Utility
import { timesData } from '../../../../data/timesData';
import { img_language } from '../../../../assets/icons';

const General = ({
  initialize,
  skipToId,
  skipToLabel,
  CreateEvent,
  //state
  loadTitle,
  loadDescription,
  loadEventType,
  loadAudienceType,
  loadDate,
  loadLanguages,
  loadTimeZone,
  loadTimeStart,
  loadTimeEnd,
  //handlers
  handleSetTitle,
  handleSetDescription,
  handleSetEventType,
  handleSetAudienceType,
  handleSetDate,
  handleSetTimeZone,
  handleSetTimeStart,
  handleSetTimeEnd,
  handleSetLanguages,
}) => {
  //Hooks
  const { LanguageSimpleNoNotListedOpts } = useLanguageData();
  const { AudienceTypes, EventTypes, AudienceTypesInfo, EventTypesInfo } =
    useLanguageData();

  //Component state
  const [newEndTimesData, setNewEndTimesData] = useState();

  //Component variable
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        if (loadTimeStart) {
          const filteredTimesData = Object.fromEntries(
            Object.entries(timesData).filter(
              ([timeKey]) => parseInt(timeKey) > loadTimeStart
            )
          );
          setNewEndTimesData(filteredTimesData);
        } //loads end times list without clearing saved end time
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //Component functions

  //uses index of array
  function handleSetEndTimes(selectedStartTime) {
    handleSetTimeEnd(null);
    const filteredTimesData = Object.fromEntries(
      Object.entries(timesData).filter(
        ([timeKey]) => parseInt(timeKey) > selectedStartTime
      )
    );
    setNewEndTimesData(filteredTimesData);
  }

  return (
    <fieldset className="fieldset" id="fieldset-general">
      <legend id="aria-fieldset-instructions-general">
        {CreateEvent.general.ariaFieldsetInstructions}
      </legend>
      <div className="h0-ph24" />
      <label
        className="label"
        id="generalLabel"
        aria-describedby="aria-fieldset-instructions-general"
        tabIndex="0"
      >
        {CreateEvent.general.general}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="eventTypeLabel"
      >
        {CreateEvent.general.titleDescription}
      </label>
      <div className="h24" />

      <TextArea
        handleChange={handleSetTitle}
        limit={75}
        id={'title'}
        labelText={CreateEvent.general.eventTitle}
        disableEnter={true}
        loadTrigger={initialize}
        loadValue={loadTitle}
        asterisk={true}
      />
      <div className="h24" />
      <TextArea
        handleChange={handleSetDescription}
        limit={3000}
        id={'description'}
        labelText={CreateEvent.general.description}
        large={true}
        loadTrigger={initialize}
        loadValue={loadDescription}
        asterisk={true}
      />
      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="eventTypeLabel"
      >
        {CreateEvent.general.eventType}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <Selections
        customWrapper={'mrg-t12'}
        handleSelection={handleSetEventType}
        id={'eventType'}
        loadTrigger={initialize ? true : false}
        loadValue={loadEventType}
        options={EventTypes}
        viewHorizontal={true}
        whatsThis={true}
        whatsThisDescriptions={EventTypesInfo}
        whatsThisType={CreateEvent.general.eventTypeWhatsThis}
      />
      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="audienceTypeLabel"
      >
        {CreateEvent.general.audienceType}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <Selections
        customWrapper={'mrg-t24'}
        handleSelection={handleSetAudienceType}
        id={'audience'}
        loadTrigger={initialize ? true : false}
        loadValue={loadAudienceType}
        options={AudienceTypes}
        viewHorizontal={true}
        whatsThis={true}
        whatsThisDescriptions={AudienceTypesInfo}
        whatsThisType={CreateEvent.general.audienceTypeWhatsThis}
      />
      <div className="h48" />

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="languageLabel"
      >
        {CreateEvent.general.languages}
        <p className="color-theme">&nbsp;*</p>
      </label>

      <div className="h24" />

      <SelectionsModal
        addButtonText={CreateEvent.general.addLanguages}
        DataOpts={LanguageSimpleNoNotListedOpts}
        iconModal={img_language}
        iconModalClass={'filter-theme'}
        iconOff={true}
        modalTitle={CreateEvent.general.addLanguages}
        selectionState={loadLanguages}
        setSelectionState={handleSetLanguages}
        subTextOn={false}
        id={'searchLanguage'}
        itemButtonCustomWrapper={'item-button-standard'}
      />

      <div className="h48" />

      <div className="flex-row">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="dateTimeLabel"
        >
          {CreateEvent.general.dateAndTime}
          <p className="color-theme">&nbsp;*</p>
        </label>
        <FocusSkip
          skipToLabel={CreateEvent.skip.skipCalendar}
          skipToId={'calendar-sum'}
        />
      </div>
      <div className="h12" />

      <div className="ce-row-col--mob">
        <div className="flex-column ce-align-left--mob full-width">
          <div className="h12" />
          <div className="ce-col-row--mob full-width ce-space-evenly--mob">
            <CalendarSimple
              handleSetDate={handleSetDate}
              loadDate={loadDate}
              initialize={initialize}
            />
            <div className="flex-column ">
              <div className="ce-h24--mob" />
              <SelectDropdownTimezones
                id={'timezone'}
                handleSelection={handleSetTimeZone}
                defaultValue={initialize && loadTimeZone ? loadTimeZone : null}
              />

              <div className="h24" />
              <SelectDropdown
                id={'startTime'}
                list={timesData}
                handleSelection={handleSetTimeStart}
                handleSelection2={handleSetEndTimes}
                defaultKey={initialize && loadTimeStart ? loadTimeStart : null}
                internalLabel={CreateEvent.general.startTime}
                asterisk={true}
              />

              <div className="h24" />
              <SelectDropdown
                id={'endTime'}
                list={newEndTimesData || timesData}
                handleSelection={handleSetTimeEnd}
                defaultKey={initialize && loadTimeEnd ? loadTimeEnd : null}
                internalLabel={CreateEvent.general.endTime}
                asterisk={true}
                tempDisable={!loadTimeStart}
                temporaryDisableMessage={'selectStartTime'}
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default General;
