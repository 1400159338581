import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useSettings from '../../../../context/useSettings';
import useApiStatus from '../../../../context/useApiStatus';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import OrganizeSelectionTags from './OrganizeSelectionTags';
//Utility
import {
  date,
  direction,
  organizer_cards,
  organizer_table,
  img_organize_active,
  img_organize_scheduled,
  img_organize_archived,
} from '../../../../assets/icons';
import { useOrganizeHandlers } from '../../organize/useOrganizeHandlers';

const OrganizeFiltersMobile = () => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { width, handleMobileTap } = useSettings();
  const { SettingsOrganizeLanguage } = useLanguageSettings();
  const {
    settingsOrganizeView: view,
    settingsOrganizeOverviewDirection: overviewDirection,
    settingsOrganizeOverviewSortCategory: sortCategory,
    settingsOrganizeOverviewFormat: format,
    handleSettingsSetOrganizeOverviewSortCategory,
    handleSettingsSetOrganizeOverviewDirection,
    handleSettingsSetOrganizeOverviewFormat,
    settingsOrganizeSelectedOrganizers: selectedOrganizers,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
    settingsOrganizeStandaloneGridEvents: standaloneEvents,
  } = useSettingsHandlers();
  const {
    organizeSortedMyOrganizedEvents,
    organizeSortedMyOrganizedScheduledEvents,
    organizeSortedMyOrganizedArchivedEvents,
  } = useOrganizeHandlers();

  const {
    accountId,
    accountAffiliations,
    accountProfileImage,
    accountFirstName,
    accountLastName,
  } = useAccountReduxHandlers();

  //Component state
  const [openSettings, setOpenSettings] = useState(false);
  const [tapHighlightSettingsBorder, setTapHighlightSettingsBorder] =
    useState(false);

  //Component variables
  let overviewDirectionExplained;
  let standaloneEventsExplained;
  let acctAffiliations;

  //initialize functions
  useEffect(() => {
    setOpenSettings(false);
    if (width < 1280 && format === 'table') {
      handleSettingsSetOrganizeOverviewFormat('cards');
    }
  }, [view, width]);

  if (accountAffiliations?.length > 0) {
    acctAffiliations = (
      <>
        {[...accountAffiliations]?.map((aA) => {
          if (selectedOrganizers.includes(aA.affiliation._id)) {
            return (
              <li key={aA._id} className="flex-row flex-center mrg-l6 ">
                {selectedOrganizers?.length === 1 && (
                  <p className="mrg-r12">
                    {aA.affiliation.institution.institutionTitle}
                  </p>
                )}
                <LoadBucketImage
                  imagePath={aA.affiliation.institution?.institutionLogo}
                  imgClassName="institutional-profile-img  tag-profile-community"
                  backupPath={'account_img_default.png'}
                  partialPath={'myndfull_account_images'}
                />
              </li>
            );
          }
        })}
      </>
    );
  }

  //component functions
  function handleSettingsMenuClick(e) {
    handleMobileTap(
      [
        () => setTapHighlightSettingsBorder(true),
        () => setTapHighlightSettingsBorder(false),
      ],
      [() => handleSearchOptionsMenu(e)]
    );
  }

  function handleSearchOptionsMenu(e) {
    if (e.target.id) {
      setOpenSettings(!openSettings);
    }
  }

  //JSX conditional
  if (overviewDirection) {
    if (overviewDirection === 'ascending') {
      if (sortCategory === 'date') {
        overviewDirectionExplained = `${SettingsOrganizeLanguage.earliest}`;
      }

      if (sortCategory === 'distance') {
        overviewDirectionExplained = `${SettingsOrganizeLanguage.closest}`;
      }
    }

    if (overviewDirection === 'descending') {
      if (sortCategory === 'date') {
        overviewDirectionExplained = `${SettingsOrganizeLanguage.latest}`;
      }
      if (sortCategory === 'distance') {
        overviewDirectionExplained = `${SettingsOrganizeLanguage.furthest}`;
      }
    }
  }

  if (standaloneEvents) {
    if (standaloneEvents === 'active') {
      standaloneEventsExplained = `${SettingsOrganizeLanguage.activeEvents}`;
    }
    if (standaloneEvents === 'scheduled') {
      standaloneEventsExplained = `${SettingsOrganizeLanguage.scheduledEvents}`;
    }
    if (standaloneEvents === 'archived') {
      standaloneEventsExplained = `${SettingsOrganizeLanguage.archivedEvents}`;
    }
  }

  return (
    <div
      role="button"
      className={`filters-mobile filters-mobile-border br--standard no-select access-ob access-o6 ${
        tapHighlightSettingsBorder ? 'filters-mobile-border--tap' : ''
      }`}
      onClick={(e) => handleSettingsMenuClick(e)}
      id="view-selections--mobile"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSettingsMenuClick(e);
        }
      }}
      aria-expanded={openSettings}
      aria-label={`${SettingsOrganizeLanguage.organizerSelections}`}
    >
      <div
        className="top-btn-bar fs18 fwsb full-width pad-6 disable-select "
        id="top-btn-bar"
      >
        <div className="flex-row flex-center">
          <button
            className={`arrow mrg-l12 ${!openSettings ? 'rotate-ccw90' : ''}`}
            id="arrow"
          />
          <p id="search-options-title" className="mrg-l12">
            {!openSettings && SettingsOrganizeLanguage.overview}
          </p>
        </div>
        <ul className="icon-summary list-style-none mrg-r8">
          {selectedOrganizers.includes(accountId) && (
            <li className="flex-row flex-center mrg-l6">
              {selectedOrganizers?.length === 1 && width > 480 && (
                <p className="mrg-r12">
                  {accountFirstName} {accountLastName}
                </p>
              )}
              <LoadBucketImage
                imagePath={accountProfileImage}
                imgClassName="tag-profile-img mrg-r6"
                backupPath={'account_img_default.png'}
                partialPath={'myndfull_account_images'}
              />
            </li>
          )}
          {acctAffiliations}
        </ul>
      </div>
      {openSettings && (
        <div className="mrg-lr16 mrg-b16" id="filtersMobile-container">
          <h2 className="fs16 mrg-b12 color-black fit-content">
            {format === 'cards'
              ? SettingsOrganizeLanguage.cards
              : format === 'table'
              ? SettingsOrganizeLanguage.table
              : SettingsOrganizeLanguage.dash}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={() => handleSettingsSetOrganizeOverviewFormat('cards')}
                title={SettingsOrganizeLanguage.cards}
                aria-pressed={format === 'cards'}
              >
                <img
                  src={organizer_cards}
                  alt={SettingsOrganizeLanguage.cards}
                  className={`svg ${format === 'cards' ? 'selected' : ''}`}
                />
              </button>
            </li>
            <li>
              <button
                className={`access-ob access-o6 ${
                  width < 1280 ? 'unavailable opacity-05' : ''
                }`}
                onClick={() => {
                  if (width < 1280) {
                    return handleWarning(
                      {
                        message: 'unavailbeOnScreenSize',
                        statusCode: 500,
                      },
                      'OrganizeFiltersMobile.js/tableViewClick'
                    );
                  } else {
                    handleSettingsSetOrganizeOverviewFormat('table');
                  }
                }}
                title={
                  width < 1280
                    ? SettingsOrganizeLanguage.tableOverviewUnavailable
                    : SettingsOrganizeLanguage.searchFormatOnline
                }
                aria-pressed={format === 'table'}
              >
                <img
                  src={organizer_table}
                  alt={SettingsOrganizeLanguage.searchFormatOnline}
                  className={`svg ${format === 'table' ? 'selected' : ''}`}
                />
              </button>
            </li>
          </ul>

          <h2 className="fs16 mrg-b12 color-black fit-content">
            {standaloneEventsExplained}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewStandaloneGridEvents(
                    'active'
                  )
                }
                title={SettingsOrganizeLanguage.activeEvents}
                aria-pressed={standaloneEvents === 'active'}
              >
                <img
                  src={img_organize_active}
                  alt={SettingsOrganizeLanguage.activeImg}
                  className={`svg ${
                    standaloneEvents === 'active' ? 'selected' : ''
                  }`}
                />
                <div className="summary-circle">
                  {organizeSortedMyOrganizedEvents?.length || 0}
                </div>
              </button>
            </li>
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewStandaloneGridEvents(
                    'scheduled'
                  )
                }
                title={SettingsOrganizeLanguage.scheduledEvents}
                aria-pressed={standaloneEvents === 'scheduled'}
              >
                <img
                  src={img_organize_scheduled}
                  alt={SettingsOrganizeLanguage.scheduledImg}
                  className={`svg ${
                    standaloneEvents === 'scheduled' ? 'selected' : ''
                  }`}
                />
                <div className="summary-circle">
                  {organizeSortedMyOrganizedScheduledEvents?.length || 0}
                </div>
              </button>
            </li>
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewStandaloneGridEvents(
                    'archived'
                  )
                }
                title={SettingsOrganizeLanguage.archivedEvents}
                aria-pressed={standaloneEvents === 'archived'}
              >
                <img
                  src={img_organize_archived}
                  alt={SettingsOrganizeLanguage.archivedImg}
                  className={`svg ${
                    standaloneEvents === 'archived' ? 'selected' : ''
                  }`}
                />
                <div className="summary-circle">
                  {organizeSortedMyOrganizedArchivedEvents?.length || 0}
                </div>
              </button>
            </li>
          </ul>

          <h2 className="fs16 mrg-b12 color-black fit-content">
            {overviewDirectionExplained}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewDirection('ascending')
                }
                title={SettingsOrganizeLanguage.ascending}
                aria-pressed={overviewDirection === 'ascending'}
              >
                <img
                  src={direction}
                  alt={SettingsOrganizeLanguage.ascending}
                  className={`svg ${
                    overviewDirection === 'ascending' ? 'selected' : ''
                  } reverse`}
                />
              </button>
            </li>
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewDirection('descending')
                }
                title={SettingsOrganizeLanguage.descending}
                aria-pressed={overviewDirection === 'descending'}
              >
                <img
                  src={direction}
                  alt={SettingsOrganizeLanguage.descending}
                  className={`svg ${
                    overviewDirection === 'descending' ? 'selected' : ''
                  }`}
                />
              </button>
            </li>
          </ul>
          <h2 className="fs16 mrg-b12 color-black fit-content">
            {SettingsOrganizeLanguage.date}
          </h2>
          <ul className="filter-category mrg-b12 fit-content access-ob access-o6">
            <li>
              <button
                className="access-ob access-o6"
                onClick={() =>
                  handleSettingsSetOrganizeOverviewSortCategory('date')
                }
                title={SettingsOrganizeLanguage.date}
                aria-pressed={sortCategory === 'date'}
              >
                <img
                  src={date}
                  alt={SettingsOrganizeLanguage.date}
                  className={`svg ${sortCategory === 'date' ? 'selected' : ''}`}
                />
              </button>
            </li>
          </ul>
          <div className="h12" />
          <OrganizeSelectionTags />
        </div>
      )}
    </div>
  );
};

export default OrganizeFiltersMobile;
