import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

//hooks
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useLazyGetAccountNoticesQuery } from '../../features/main/notices/noticesApiSlice';
import { useLazyGetAccountCommunityQuery } from '../../features/main/account/accountCommunityApiSlice';
import { useNoticeHandlers } from '../../features/main/notices/useNoticeHandlers';

const AccountPersonalSocketContext = createContext({});

export const AccountPersonalSocketProvider = ({ children }) => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const { checkNoticeDataForDataRefresh } = useNoticeHandlers();

  //api
  const [getAccountNotices] = useLazyGetAccountNoticesQuery();
  const [getAccountCommunity] = useLazyGetAccountCommunityQuery();

  //state
  const [accountPersonalSocketStatus, setAccountPersonalSocketStatus] =
    useState('closed');

  //variables
  const socketRef = useRef(null);
  const statusRef = useRef(accountPersonalSocketStatus);

  const logsOn = true;

  useEffect(() => {
    statusRef.current = accountPersonalSocketStatus;
  }, [accountPersonalSocketStatus]); // Update statusRef whenever accountPersonalSocketStatus changes

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === 'development') {
  //     const timer = setInterval(() => {
  //       console.log('accountPersonalSocketStatus:', statusRef.current);
  //     }, 3000);

  //     return () => clearInterval(timer);
  //   }
  // }, []);

  const connectAccountPersonalSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && accountPersonalSocketStatus !== 'connected') {
      let newSocket;

      const socketOptions = {
        query: { accountId },
        reconnection: false, // Disable built-in reconnection, handled manually
      };

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io(
          'http://localhost:5000/ws/accountPersonal',
          socketOptions
        );
      } else {
        newSocket = io('https://myndfull.com/ws/accountPersonal', {
          ...socketOptions,
          withCredentials: true,
        });
      }

      let reconnectAttempts = 0;
      const maxReconnectAttempts = 10;

      const reconnectWithBackoff = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000); //  max 30 seconds
          reconnectAttempts += 1;

          logsOn &&
            console.log(
              `Reconnecting attempt ${reconnectAttempts} in ${delay / 1000}s...`
            );

          setTimeout(() => {
            connectAccountPersonalSocket();
          }, delay);
        } else {
          logsOn && console.error('Max reconnection attempts reached.');
          setAccountPersonalSocketStatus('closed');
        }
      };
      newSocket.on('connect', async () => {
        logsOn && console.log('accountPersonalSocket connected');
        reconnectAttempts = 0;
        setAccountPersonalSocketStatus('connected');

        if (accountId) {
          logsOn && console.log('Fetching account notices on reconnect...');
          const accountNotices = await getAccountNotices(accountId);
          checkNoticeDataForDataRefresh(accountNotices?.data?.notices || []);

          getAccountCommunity({ accountId });
        }
      });

      newSocket.on(
        'accountPersonalNoticesData',
        async (accountPersonalNoticesData) => {
          if (accountPersonalNoticesData && accountId) {
            logsOn &&
              console.log(
                'Account Personal Socket: accountPersonalSocket, noticesData'
              );

            const accountNotices = await getAccountNotices(accountId);
            checkNoticeDataForDataRefresh(accountNotices?.data?.notices || []);
          }
        }
      );

      newSocket.on(
        'accountPersonalCommunityData',
        (accountPersonalCommunityData) => {
          if (accountPersonalCommunityData && accountId) {
            logsOn && console.log('accountPersonalSocket, communityData');
            getAccountCommunity({ accountId });
          }
        }
      );

      newSocket.on('connect_error', (error) => {
        logsOn && console.error('Connection error:', error);
        setAccountPersonalSocketStatus('closed');
        reconnectWithBackoff();
      });

      newSocket.on('disconnect', (reason) => {
        logsOn && console.error(`Disconnected: ${reason}`);
        setAccountPersonalSocketStatus('pending');
        if (reason !== 'io client disconnect') {
          reconnectWithBackoff();
        }
      });

      newSocket.on('error', (error) => {
        logsOn && console.error('Socket error:', error);
        setAccountPersonalSocketStatus('closed');
      });

      return (socketRef.current = newSocket);
    }
  };

  const getAccountPersonalSocket = () => {
    return socketRef.current;
  };

  const closeAccountPersonalSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setAccountPersonalSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed Account Personal Socket:');
      }
    }
  };

  return (
    <AccountPersonalSocketContext.Provider
      value={{
        connectAccountPersonalSocket,
        getAccountPersonalSocket,
        closeAccountPersonalSocket,
        accountPersonalSocketStatus,
      }}
    >
      {children}
    </AccountPersonalSocketContext.Provider>
  );
};

export const useAccountPersonalSocket = () => {
  return useContext(AccountPersonalSocketContext);
};
