import { useCallback, useEffect, useRef, useState } from 'react';

//hooks
import useApiStatus from '../../context/useApiStatus';
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';

//components

//utility
import { axiosLimited, axiosLimitedMultiPart } from '../../axios/axios';
import { retryWrapper } from '../../utils/logic/retryFns';
import { cancel, img_upload_file } from '../../assets/icons';

const AutocorrectKeywords = ({
  limit,
  index,
  speakersKeywordsList,
  setSpeakersKeywordsList,
}) => {
  //Hooks
  const { handleWarning, handleError } = useApiStatus();
  const { Generic, AutocorrectKeywords } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //Component state
  const [keywordsCount, setKeywordsCount] = useState(0);
  const [completedChunks, setCompletedChunks] = useState(0);
  const [requiredChunks, setRequiredChunks] = useState();

  //UI state
  const [limitShake, setLimitShake] = useState(false);
  const [inputField, setInputField] = useState();
  const [tapHighlightKeyword, setTapHighlightKeyword] = useState();
  const [dragOver, setDragOver] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  //variables
  const fileInputRef = useRef(null);
  let progressWidth =
    requiredChunks > 0 ? (completedChunks / requiredChunks) * 90 : 0;

  const temporaryKeywordsArr = useRef([]);

  //Initialize functions
  useEffect(() => {
    setInputField(document.getElementById(`autocorrect-keywords-${index}`));
  }, []);

  //UI functions
  function handleTagsClick(e, keyword) {
    handleMobileTap(
      [
        () => setTapHighlightKeyword(e.target.id),
        () => setTapHighlightKeyword(),
      ],
      [() => handleRemoveItem(e, keyword)]
    );
  }

  const uploadParentElementClick = () => {
    fileInputRef.current.click();
  };

  //Component functions
  useEffect(() => {
    if (speakersKeywordsList) {
      let keywords = 0;
      if (keywords === 0 || keywordsCount !== keywords) {
        let keywordsNum = keywords + speakersKeywordsList?.length;
        setKeywordsCount(keywordsNum);
      }

      if (keywordsCount === limit) {
        setLimitShake(true);
      }
      if (keywordsCount !== limit) {
        setLimitShake(false);
      }
    }

    /*eslint-disable-next-line*/
  }, [speakersKeywordsList]);

  function addKeyword(keyword) {
    if (keyword?.length > 150) {
      inputField.value = '';
      return handleWarning({
        message: 'keywordLengthInvalid',
        origin: 'AutocorrectKeywords.js/addKeyword-1',
        id: Date.now(),
      });
    }
    let newKeyword = inputField.value.trim();
    if (!newKeyword) {
      return null;
    }

    const keywordExists = speakersKeywordsList?.some(
      (keyword) => keyword.toLowerCase() === newKeyword.toLowerCase()
    );

    if (keywordExists) {
      inputField.value = '';
      handleWarning({
        message: 'keywordAlreadySelected',
        origin: 'AutocorrectKeywords.js/addKeyword-2',
        id: Date.now(),
      });
    } else {
      if (!limit || speakersKeywordsList?.length < limit) {
        setSpeakersKeywordsList([...speakersKeywordsList, newKeyword], index);
        inputField.value = '';
      } else {
        return handleWarning({
          message: 'tagLimitReached',
          origin: 'AutocorrectKeywords.js/addKeyword-3',
          id: Date.now(),
        });
      }
    }
  }

  function handleKeyDown(e) {
    if (
      e.code === 'Enter' ||
      e.code === 'NumpadEnter' ||
      e.key === 'Enter' ||
      e.key === 'Return' ||
      e.code === 'Return'
    ) {
      e.preventDefault();
      addKeyword(e.target.value);
    } else {
      const key = e.key;
      const regex = /[a-zA-Z0-9-. ]/;
      if (!regex.test(key)) {
        e.preventDefault();
      }
    }
  }

  function handleRemoveItem(e) {
    let newArr = [...speakersKeywordsList];
    let valueToRemove = e.target.id;
    newArr = speakersKeywordsList.filter((kw) => kw !== valueToRemove);
    setSpeakersKeywordsList(newArr, index);
  }

  //File Uploading
  const handleFileUpload = async (file) => {
    if (!loadingFile) {
      setLoadingFile(true);
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ];

      if (!allowedTypes.includes(file.type)) {
        setLoadingFile(false);
        return handleWarning({
          message: 'unsupportedFileType',
          origin: 'AutocorrectKeywords.js/handleFileUpload-1',
          id: Date.now(),
        });
      }

      //file size check.
      const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file.size > maxFileSize) {
        setLoadingFile(false);
        return handleWarning({
          message: 'fileSizeTooLarge',
          origin: 'AutocorrectKeywords.js/handleFileUpload-2',
          id: Date.now(),
        });
      }

      const fileData = new FormData();
      fileData.append('file', file);

      //Process docFile
      try {
        async function processDocFile() {
          try {
            const response = await axiosLimitedMultiPart.post(
              '/api/ai/processDocFile',
              fileData
            );
            return response;
          } catch (error) {
            throw error;
          }
        }

        const response = await retryWrapper(processDocFile);

        setRequiredChunks(response.data.data?.length);

        if (response.data.data?.length > 0) {
          try {
            for (
              let chunkIndex = 0;
              chunkIndex < response.data.data?.length;
              chunkIndex++
            ) {
              const chunk = response.data.data[chunkIndex];
              try {
                await getKeywords(chunk, chunkIndex, speakersKeywordsList);
              } catch (error) {
                throw error;
              }
            }
          } catch (error) {
            temporaryKeywordsArr.current = [];
            setCompletedChunks(0);
            setRequiredChunks();
            setLoadingFile(false);
            handleError({
              message: 'tryAgain',
              id: Date.now(),
              origin: 'AutocorrectKeywords.js/handleChunks',
            });
          }
        } else {
          temporaryKeywordsArr.current = [];
          setCompletedChunks(0);
          setRequiredChunks();
          setLoadingFile(false);
        }
      } catch (error) {
        setLoadingFile(false);
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'AutocorrectKeywords.js/handleFileUpload/processDocFile',
        });
      }
    }
  };

  const memoizedHandleFileUpload = useCallback(handleFileUpload, [
    speakersKeywordsList,
  ]);

  async function getKeywords(textChunk, chunkIndex, keywordsList) {
    if (keywordsCount >= 50) {
      return handleWarning({
        message: 'keywordLimitReached',
        origin: 'AutocorrectKeywords.js/getKeywords-1',
        id: Date.now(),
      });
    }

    try {
      async function retrieveExtractedKeywords() {
        try {
          const extractedKeywords = await axiosLimited.post(
            `/api/ai/extractKeywords?index=${chunkIndex}`,
            { text: textChunk }
          );
          return extractedKeywords;
        } catch (error) {
          throw error;
        }
      }

      const extractedKeywords = await retryWrapper(retrieveExtractedKeywords);

      if (extractedKeywords.data?.data?.length > 0) {
        extractedKeywords.data?.data.forEach((kw) => {
          //remove duplicates & trim
          const keywordExists = keywordsList?.some(
            (keyword) =>
              // console.log(keyword.trim().toLowerCase(), kw.trim().toLowerCase())
              keyword.trim().toLowerCase() === kw.trim().toLowerCase()
          );
          if (!keywordExists) {
            //make sure that keyword exists in the chunk
            if (textChunk?.includes(kw)) {
              let newKW = kw.trim();

              if (newKW?.length > 0) {
                temporaryKeywordsArr.current.push(newKW);
              }
            } else {
              if (process.env.ENV === 'development') {
                console.log('Keyword added but not found in text', kw);
              }
            }
          }
        });

        return setCompletedChunks(
          (prevCompletedChunks) => prevCompletedChunks + 1
        );
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (completedChunks === requiredChunks) {
      handleFilteredKeywords(temporaryKeywordsArr.current);
    }
  }, [temporaryKeywordsArr.current, requiredChunks, completedChunks]);

  async function handleFilteredKeywords(keywordsToFilter) {
    if (temporaryKeywordsArr.current?.length > 0) {
      try {
        async function getFilteredKeywords() {
          try {
            const filteredKeywords = await axiosLimited.post(
              '/api/ai/filteredKeywords',
              { keywordsToFilter, keywordsCount }
            );
            return filteredKeywords;
          } catch (error) {
            throw error;
          }
        }
        const filteredKeywords = await retryWrapper(getFilteredKeywords);

        progressWidth = 100;

        //need to remove duplicates
        const newFilteredKeywords = filteredKeywords.data.data.split(',');
        const newFilteredKeywordsFormatted = newFilteredKeywords.map((kw) =>
          kw.trim().replace(/\.$/, '')
        );

        let combinedKeywordsArray;

        if (speakersKeywordsList?.length > 0) {
          combinedKeywordsArray = [
            ...speakersKeywordsList,
            ...newFilteredKeywordsFormatted,
          ];
        } else {
          combinedKeywordsArray = [...newFilteredKeywordsFormatted];
        }

        temporaryKeywordsArr.current = [];
        setCompletedChunks(0);
        setRequiredChunks();
        setSpeakersKeywordsList(combinedKeywordsArray, index);
        setLoadingFile(false);
      } catch (error) {
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'AutocorrectKeywords.js/handleFilteredKeywords',
          error,
        });
        temporaryKeywordsArr.current = [];
        setCompletedChunks(0);
        setRequiredChunks();
        setLoadingFile(false);
      }
    } else {
      progressWidth = 100;
      const emptyKeywordsAnimationTimer = setTimeout(() => {
        temporaryKeywordsArr.current = [];
        setCompletedChunks(0);
        setRequiredChunks();
        setLoadingFile(false);
      }, 500);

      return () => clearTimeout(emptyKeywordsAnimationTimer);
    }
  }

  const handleDragOver = useCallback(
    (e) => {
      // Prevent default behavior (prevent file from being opened)
      e.preventDefault();
      if (!dragOver) setDragOver(true);
    },
    [dragOver]
  );

  const handleDragEnter = useCallback((e) => {
    // This is necessary to allow the drop event to fire.
    e.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setDragOver(false);
      const file = e.dataTransfer.files[0];
      if (file) {
        memoizedHandleFileUpload(file);
      }
    },
    [memoizedHandleFileUpload]
  );

  return (
    <div className="keywords-list__wrapper no-select">
      {loadingFile ? (
        <div className="keywords-list__loading-bar mrg-b24">
          <span
            style={{
              minWidth: '10px',
              width: `${progressWidth}%`,
            }}
            className={'keywords-list__loading-bar--progress'}
          />
        </div>
      ) : (
        <div
          className={`drag-and-drop-area bg-color-white fwn fs16 mrg-b24 highlight-it-theme access-ob ${
            dragOver && 'drag-and-drop--dragOver'
          }`}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          tabIndex="0"
          onClick={(e) => {
            e.stopPropagation();
            uploadParentElementClick();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              uploadParentElementClick();
            }
          }}
        >
          <img
            src={img_upload_file}
            alt=""
            className={`filter-lightgray mrg-t24 mrg-b12`}
          />
          <label
            htmlFor="keywords-file-input"
            className="pad-6 mrg-b12"
            onClick={(e) => e.stopPropagation()}
            tabIndex="-1"
          >
            {AutocorrectKeywords.uploadToAutodetect}
            <div className="h12" />( {AutocorrectKeywords.maximumSize} )
          </label>

          <input
            id="keywords-file-input"
            type="file"
            onChange={(e) => {
              e.stopPropagation();
              handleFileUpload(e.target.files[0]);
            }}
            className="keywords-file-input"
            ref={fileInputRef}
            onClick={(e) => {
              e.stopPropagation();
            }}
            tabIndex="-1"
          />
        </div>
      )}

      <textarea
        autoComplete="off"
        name={`autocorrect-keywords-${index}`}
        id={`autocorrect-keywords-${index}`}
        className="keywords-input fs18 fwn"
        maxLength="60"
        type="text"
        onKeyDown={(e) => handleKeyDown(e)}
        // aria-label={CustomTagsList.inputInstructions + limit}
        enterKeyHint="enter"
      />

      <div className="dropdown-tags__wrapper">
        <div className="flex-column full-width">
          <div className="flex-row full-width flex-center mrg-t12 space-between full-width mrg-b24">
            {limit && (
              <p
                className={`fs18 fwn no-wrap access-ob access-o6 no-select  ${
                  limitShake ? ' shake' : ''
                }`}
                tabIndex="0"
              >
                {AutocorrectKeywords.keywords} {keywordsCount} / {limit}
              </p>
            )}
          </div>
        </div>

        {speakersKeywordsList?.length > 0 && (
          <ul className="tags__container br--small mrg-t12">
            {speakersKeywordsList
              ?.map((tag, index) => (
                <li key={`${tag}-${index}`}>
                  <button
                    className={`tag__list-item highlight-bthin-theme fwsb fs16 fs14-phn no-select access-ob access-o6 highlight-i-lgr
           ${tapHighlightKeyword === tag ? 'highlight-b-theme--tap ' : ''}`}
                    tabIndex="0"
                    aria-label={`${tag}  ${Generic.remove}`}
                    name={index} //keep for removal
                    id={tag}
                    onClick={(e) => handleTagsClick(e, speakersKeywordsList)}
                  >
                    <p
                      name={index} //keep for removal & p tag needed for css text wrap
                      id={tag}
                      onClick={(e) => handleTagsClick(e, speakersKeywordsList)}
                    >
                      {tag}
                    </p>
                    <img
                      alt={'X'}
                      className={`disable-select tag-remove-btn-img`}
                      src={cancel}
                    />
                  </button>
                </li>
              ))
              .reverse()}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AutocorrectKeywords;
