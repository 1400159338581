import { useState } from 'react';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import Spinner from '../Spinner/Spinner';

//Utility

const ItemButton = ({
  addText,
  altText,
  bgColor,
  customWrapper,
  displayNone,
  fullWidth,
  handler,
  height,
  icon,
  iconCustomClass,
  iconOn,
  iconStyle,
  id,
  isLoading,
  lengthOn,
  limit,
  propertyLength,
  text,
  unavailable,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);

  //Component variables
  let content;
  let length;

  //Component functions
  function handleClick(e) {
    if (unavailable) return null;
    handleMobileTap(
      [() => setTapHighlightBtn(true), () => setTapHighlightBtn(false)],
      [() => handler(e)]
    );
  }

  //icon maxHeight use to be...
  // { maxHeight: '21px' }

  if ((text || icon) && (propertyLength !== limit || !limit)) {
    content = (
      <button
        id={id}
        className={`item-button access-ob access-o6 fwn fs16 br--small ${bgColor} ${height} ${
          fullWidth ? 'full-width' : ''
        }  ${tapHighlightBtn ? 'highlight-b-theme--tap' : ''} ${
          unavailable ? 'opacity-3 pointer-default ' : 'highlight-bthin-theme'
        } ${customWrapper ? customWrapper : ''}`}
        onClick={(e) => handleClick(e)}
        type="button"
        tabIndex="0"
        aria-label={`${addText && Generic.add} ${text}. ${
          limit && propertyLength + '/' + limit
        }`}
        style={displayNone ? { display: 'none' } : { display: 'flex' }}
      >
        <div className=" flex-row">
          {!isLoading && addText && Generic.add}&nbsp;
          {!isLoading && text}
        </div>
        {!isLoading && length && lengthOn && (
          <div className="flex-row">
            &nbsp;<p>{propertyLength}&nbsp; </p>/<p>&nbsp;{limit}</p>
            &nbsp;&nbsp;
            <img src={icon} alt={altText || 'icon'} />
          </div>
        )}
        {!isLoading && iconOn && (
          <img
            src={icon}
            alt={altText}
            className={`mrg-l12 filter-gray ${
              iconCustomClass ? iconCustomClass : ''
            }`}
            style={iconStyle}
          />
        )}
        {isLoading && <Spinner maxHeight={'100%'} />}
      </button>
    );
  } else {
    content = (
      <div
        className={`item-button-ghost fs18 no-select access-ob access-o6 ${
          customWrapper ? customWrapper : ''
        }`}
        tabIndex="0"
        id={`${id}`}
      >
        <div className="flex-row">
          {limit && <p>{Generic.limitReached}</p>}
          {length}
        </div>
      </div>
    );
  }

  return content;
};

export default ItemButton;
