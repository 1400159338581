import { useEffect, useState } from 'react';

//hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components

//utils
import { lock_locked } from '../../../../assets/icons';
import GetFlag from '../../../../utils/images/GetFlag';

const EventPageContact = ({
  eventData,
  EventPage,
  organizerViewOn,
  eventStatus,
}) => {
  //hooks
  const { IconAlts } = useLanguageComponents();

  //state
  const [flag, setFlag] = useState();

  //variables
  const privateDetails = eventData?.registrationData?.privateDetails?.enabled;
  const contactLocked = eventData?.registrationData?.privateDetails?.contact;

  let contactInfo;

  useEffect(() => {
    setFlag(GetFlag(eventData?.contact?.contactCountryCode));
  }, [eventData]);

  if (
    privateDetails &&
    contactLocked &&
    (eventStatus !== 'registeredInPerson' ||
      eventStatus !== 'registeredVirtual' ||
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' ||
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson') &&
    !organizerViewOn
  ) {
    contactInfo = (
      <>
        {eventData?.contact?.contactEmail && (
          <div className={`info-row access-ob access-o6`} tabIndex="0">
            <p>{EventPage.email}</p>
            <img
              src={lock_locked}
              alt={IconAlts.iconLock}
              className="lock-img mrg-l12"
            />
          </div>
        )}

        {eventData?.contact?.contactPhoneNumber &&
          eventData?.contact?.contactCountryCode && (
            <div className="info-row access-ob access-o6" tabIndex="0">
              <p>{EventPage.phoneNumber}</p>
              <img
                src={lock_locked}
                alt={IconAlts.iconLock}
                className="lock-img mrg-l12"
              />
            </div>
          )}
      </>
    );
  } else if (organizerViewOn && privateDetails && contactLocked) {
    contactInfo = (
      <>
        {eventData?.contact?.contactEmail && (
          <div className={`info-row access-ob access-o6 `} tabIndex="0">
            <p>{EventPage.email}</p>
            <img
              src={lock_locked}
              alt={IconAlts.iconLock}
              className="lock-img mrg-lr12"
            />
            <p className="previewMode-border outline-offset-2">
              {eventData?.contact?.contactEmail}
            </p>
          </div>
        )}

        {eventData?.contact?.contactPhoneNumber &&
          eventData?.contact?.contactCountryCode && (
            <div className="info-row access-ob access-o6" tabIndex="0">
              <p>{EventPage.phoneNumber}</p>
              <img
                src={lock_locked}
                alt={IconAlts.iconLock}
                className="lock-img mrg-lr12"
              />
              <div className="flex-row previewMode-border outline-offset-2">
                <div className="align-center">{flag}</div>
                <p className="mrg-l6">
                  {eventData?.contact?.contactPhoneNumber}
                </p>
              </div>
            </div>
          )}
      </>
    );
  } else {
    contactInfo = (
      <>
        {eventData?.contact?.contactEmail && (
          <div className={`info-row access-ob access-o6 `} tabIndex="0">
            <p>{EventPage.email}:</p>
            <p className="mrg-l12">{eventData?.contact?.contactEmail}</p>
          </div>
        )}
        {eventData?.contact?.contactPhoneNumber &&
          eventData?.contact?.contactCountryCode && (
            <div className="info-row access-ob access-o6" tabIndex="0">
              <p>{EventPage.phoneNumber}:</p>
              <div className="align-center mrg-l12">{flag}</div>
              <p className="mrg-l6">{eventData?.contact?.contactPhoneNumber}</p>
            </div>
          )}
      </>
    );
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="contactLabel"
      >
        Contact
      </label>
      {contactInfo}
      <div className="h24" />
    </>
  );
};

export default EventPageContact;
