import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

//Hooks
import { useAccountReduxHandlers } from './features/main/account/useAccountHandlers';
import { useAccountPersonalSocket } from './context/sockets/AccountPersonalProvider';
import { useAuthReduxHandlers } from './features/main/auth/useAuthHandlers';

//Features
import Login from './features/main/auth/Login';
import RequireAuth from './features/main/auth/RequireAuth';
import PersistLogin from './features/main/auth/PersistLogin';
import Events from './features/main/events/Events';
import Account from './features/main/account/Account';
import Organize from './features/main/organize/Organize';
// import EventPage from './features/main/events/EventPage/EventPage';
import Registration from './features/main/registration/Registration';
import { selectCurrentToken } from './features/main/auth/authSlice';
import ForgotPassword from './features/main/auth/ForgotPassword';
import ResetPassword from './features/main/auth/ResetPassword';
import VerifyAccountEmail from './features/main/auth/VerifyAccountEmail';

//Admin
import AdminLogin from './features/admin/auth/AdminLogin';
import { useAdminAccountReduxHandlers } from './features/admin/account/useAdminAccountHandlers';
import RequireAuthAdmin from './features/admin/auth/RequireAuthAdmin';
import { selectAdminCurrentToken } from './features/admin/auth/adminAuthSlice';
import PersistLoginAdmin from './features/admin/auth/PersistLoginAdmin';
import AdminLanding from './features/admin/landing/AdminLanding';
import AdminSupport from './features/admin/support/AdminSupport';
import AdminAnalytics from './features/admin/analytics/AdminAnalytics';
import AdminLayout from './layout/admin/AdminLayout';
import AdminManagement from './features/admin/management/AdminManagement';
import AdminAccount from './features/admin/account/AdminAccount';
import AdminTechnical from './features/admin/technical/AdminTechnical';

//Other
import PageNotFound from './layout/PageNotFound';
import Layout from './layout/main/Layout';
import Unauthorized from './layout/Unauthorized';
import Translations from './features/main/translations/Translations';
import RegistrationStripeConfirmation from './features/main/registration/Content/Hybrid/RegistrationStripeConfirmation';
import BillingStripeConfirmation from './features/main/account/Content/BillingContent/BillingStripeConfirmation';
import StandaloneEventPage from './features/main/events/EventPage/StandaloneEventPage';
import EventParticipantsEmailInvitationForm from './features/main/organize/eventParticipants/EventParticipantsEmailInvitationForm';
import TranslationsParticipationFormInviteContact from './features/main/organize/translations/TranslationsParticipationFormInviteContact';
import ParticipationTranslationsEmailForm from './features/main/organize/translations/ParticipationTranslationsEmailForm';

const ROLES = {
  //Main
  User: process.env.REACT_APP_USER,
  Attendee: process.env.REACT_APP_ATTENDEE,
  Speaker: process.env.REACT_APP_SPEAKER,
  Organizer: process.env.REACT_APP_ORGANIZER,

  //Admin
  AdminUser: process.env.REACT_APP_ADMIN_USER,
  AdminSupport: process.env.REACT_APP_ADMIN_SUPPORT,
  AdminAnalyst: process.env.REACT_APP_ADMIN_ANALYST,
  AdminTechnical: process.env.REACT_APP_ADMIN_TECHNICAL,
  AdminManagement: process.env.REACT_APP_ADMIN_MANAGEMENT,
};

//Used to delay prompt to install PWA
export let deferredPrompt;
window.addEventListener('beforeinstallprompt', function (event) {
  event.preventDefault();
  deferredPrompt = event;
});

function App() {
  //main
  const token = useSelector(selectCurrentToken);
  const { accountId } = useAccountReduxHandlers();
  //admin
  const adminToken = useSelector(selectAdminCurrentToken);
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { connectAccountPersonalSocket } = useAccountPersonalSocket();

  function handleHomePage() {
    if (authCurrentRoles?.includes(process.env.REACT_APP_ATTENDEE)) {
      return '/events';
    } else if (authCurrentRoles?.includes(process.env.REACT_APP_ORGANIZER)) {
      return '/organize';
    } else if (authCurrentRoles?.includes(process.env.REACT_APP_USER)) {
      return '/account';
    }
  }

  useEffect(() => {
    if (accountId && authCurrentRoles?.includes(process.env.REACT_APP_USER)) {
      connectAccountPersonalSocket();
    }
  }, [accountId, authCurrentRoles]);

  //ensure all paths are specified in app.js (server)
  if (
    (process.env.REACT_APP_ENV === 'development' &&
      window.location.host.split('.')[0] !== 'admin') ||
    (process.env.REACT_APP_ENV === 'production' &&
      window.location.host.split('.')[0] !== 'admin' &&
      window.location.host.split('.')[1] !== 'admin')
  ) {
    return (
      <Routes>
        <Route element={<PersistLogin />}>
          <Route path="/" element={<Layout />}>
            {/* Main -- Public Routes */}

            {!token || !accountId ? (
              <Route index element={<Login />} />
            ) : (
              <Route index element={<Navigate to={handleHomePage()} />} />
            )}
            {!token || !accountId ? (
              <Route path="login" element={<Login />} />
            ) : (
              <Route
                path="login"
                element={<Navigate to={handleHomePage()} />}
              />
            )}

            <Route
              path="register/paymentConfirmation"
              element={<RegistrationStripeConfirmation />}
            />
            {!token || !accountId ? (
              <Route path="register" element={<Registration />} />
            ) : (
              <Route
                path="register"
                element={<Navigate to={handleHomePage()} />}
              />
            )}

            {/* <Route path="contact" element={<SLRegistration />} /> */}
            <Route
              path="verifyaccountemail/:verifyaccountemailtoken"
              element={<VerifyAccountEmail />}
            />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route
              path="resetpassword/:resettoken"
              element={<ResetPassword />}
            />

            {/* <Route path="events/:eventId" element={<EventPage />} /> */}
            <Route
              path="events/sa/:eventId"
              element={<StandaloneEventPage />}
            />

            <Route
              path="emailParticipationInvite/:emailParticipationInviteToken"
              element={<EventParticipantsEmailInvitationForm />}
            />
            <Route
              path="emailParticipationTranslations/:participationTranslationsEmailToken"
              element={<ParticipationTranslationsEmailForm />}
            />

            <Route path="tr" element={<Translations />} />

            {/* Main -- Protected Routes */}
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route
                path="account/paymentConfirmation"
                element={<BillingStripeConfirmation />}
              />
              <Route
                path="tr/participationForm/:eventId/:noticeId"
                element={<TranslationsParticipationFormInviteContact />}
              />

              <Route path="account" element={<Account />} />
              <Route element={<RequireAuth allowedRoles={[ROLES.Attendee]} />}>
                <Route path="events" element={<Events />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Organizer]} />}>
                <Route path="organize" element={<Organize />} />
              </Route>
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route element={<PersistLoginAdmin />}>
          <Route path="/" element={<AdminLayout />}>
            {/* ADMIN - Public Routes */}

            {(!adminToken || !adminAccountId) && (
              <Route path="/" element={<AdminLogin />} />
            )}

            {/* ADMIN - Protected Routes */}
            <Route
              element={<RequireAuthAdmin allowedRoles={[ROLES.AdminUser]} />}
            >
              <Route path="/" element={<AdminLanding />} />

              <Route
                element={
                  <RequireAuthAdmin allowedRoles={[ROLES.AdminManagement]} />
                }
              >
                <Route path="/management" element={<AdminManagement />} />
              </Route>

              <Route
                element={
                  <RequireAuthAdmin allowedRoles={[ROLES.AdminAnalyst]} />
                }
              >
                <Route path="/analytics" element={<AdminAnalytics />} />
              </Route>

              <Route
                element={
                  <RequireAuthAdmin allowedRoles={[ROLES.AdminSupport]} />
                }
              >
                <Route path="/support" element={<AdminSupport />} />
              </Route>

              <Route
                element={
                  <RequireAuthAdmin allowedRoles={[ROLES.AdminTechnical]} />
                }
              >
                <Route path="/technical" element={<AdminTechnical />} />
              </Route>

              <Route path="/account" element={<AdminAccount />} />
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    );
  }
}
export default App;
