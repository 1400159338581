import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

//hooks
import { useRemoveMyNoticeMutation } from '../../noticesApiSlice';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import useGenericModal from '../../../../../context/useGenericModal';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useSettings from '../../../../../context/useSettings';
import { useEventParticipantsHandlers } from '../../../organize/eventParticipants/useEventParticipantsHandlers';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';
import Spinner from '../../../../../components/Spinner/Spinner';
import ProfilePersonalModal from '../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
import { cancel, checkmark, trash, visible } from '../../../../../assets/icons';

const NoticeInviteParticipant = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const navigate = useNavigate();
  const { accountId } = useAccountReduxHandlers();
  const { setGenericModal } = useGenericModal();
  const { IconAlts, NoticeSpeakerComponents } = useLanguageComponents();
  const { setOpenMobileNotificationsMenu, handleMobileTap } = useSettings();
  const { handleMyEventParticipationInviteResponse } =
    useEventParticipantsHandlers();
  //api
  const [removeNotice] = useRemoveMyNoticeMutation();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [highlightViewProfileBtnTap, setHighlightViewProfileBtnTap] =
    useState(false);
  const [highlightViewEventBtnTap, setHighlightViewEventBtnTap] =
    useState(false);

  function handleViewEvent(n) {
    let eventId;

    if (n?.eventInvitation?.draftId) {
      eventId = n?.eventInvitation?.draftId;
    }
    if (n?.eventInvitation?.eventId) {
      eventId = n?.eventInvitation?.eventId;
    }

    if (n?.eventInvitation?.scheduledEventId) {
      eventId = n?.eventInvitation?.scheduledEventId;
    }
    navigate(`/events/sa/${eventId}`);
  }

  return (
    <>
      {isLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container mrg-t12">
              {n?.noticeHistory ? (
                <>
                  <div className="divider-dotted mrg-b24" />
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  {n?.noticeHistory === 'rejected' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.rejected}
                    </p>
                  )}
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.removeFromArchive}
                    iconOn={true}
                    icon={trash}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                    customClassText={'color-darkgray'}
                    iconRight={true}
                    iconStyle={{ height: '18px' }}
                    iconClass={'mrg-l12 filter-gray highlight-i-hover--red'}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        removeNotice({ accountId, noticeId: n._id });
                        setOpenedNotice();
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.accept}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        handleMyEventParticipationInviteResponse({
                          accountId,
                          notice: n,
                          inviteResponse: 'accepted',
                        });
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={checkmark}
                    iconRight={true}
                    iconClass={'mrg-l12 filter-gray'}
                    customClassText={'color-gray'}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                  />

                  <ButtonTextSimple
                    text={NoticeSpeakerComponents.reject}
                    handler={() => {
                      if (!isLoading) {
                        setIsLoading(true);
                        handleMyEventParticipationInviteResponse({
                          accountId,
                          notice: n,
                          inviteResponse: 'rejected',
                        });
                        setOpenedNotice();
                      }
                    }}
                    iconOn={true}
                    icon={cancel}
                    iconRight={true}
                    iconClass={'mrg-l12 filter-gray'}
                    customClassText={'color-gray'}
                    customWrapper={
                      'noticeOverlay-action-btn min-full-width flex-center'
                    }
                  />
                  <button
                    className={`notice-history-summary color-gray highlight-t-theme access-ob access-o6 ${
                      highlightViewProfileBtnTap ? 'color-theme' : ''
                    }`}
                    tabIndex="0"
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setHighlightViewProfileBtnTap(true),
                          () => setHighlightViewProfileBtnTap(false),
                        ],
                        [
                          () => {
                            setOpenMobileNotificationsMenu(false);
                            setGenericModal(
                              <ProfilePersonalModal
                                closeModalFocusId={`noticeContactRequest-${n?.from?.account?._id}`}
                                profileData={n?.from?.account}
                                reopenNotices={true}
                              />
                            );
                          },
                        ]
                      )
                    }
                    id={`noticeContactRequest-${n?.from?.account?._id}`}
                  >
                    <img
                      src={visible}
                      alt={IconAlts.iconView}
                      className="filter-gray svg mrg-r12"
                    />
                    <p className="fwsb">
                      {NoticeSpeakerComponents.viewProfile}
                    </p>
                  </button>

                  <button
                    className={`notice-history-summary color-gray highlight-t-theme access-ob access-o6 ${
                      highlightViewEventBtnTap ? 'color-theme' : ''
                    }`}
                    tabIndex="0"
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setHighlightViewEventBtnTap(true),
                          () => setHighlightViewEventBtnTap(false),
                        ],
                        [
                          () => {
                            setOpenMobileNotificationsMenu(false);
                            handleViewEvent(n);
                          },
                        ]
                      )
                    }
                    id={`noticeContactRequest-${n?.from?.account?._id}`}
                  >
                    <img
                      src={visible}
                      alt={IconAlts.iconView}
                      className="filter-gray svg mrg-r12"
                    />
                    <p className="fwsb">{NoticeSpeakerComponents.viewEvent}</p>
                  </button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeInviteParticipant;
