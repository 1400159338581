import { useEffect, useState } from 'react';

//Hooks
import useApiStatus from '../../context/useApiStatus';
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
//Components
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';
import ItemButton from '../ItemButton/ItemButton';

//Utility
import {
  arrow_circular_hallow,
  cancel,
  checkmark,
  dropdown_arrow,
  edit,
  folder,
  trash,
} from '../../assets/icons';

const CommunityGroups = ({
  accountFollowing,
  setAccountFollowing,
  accountFollowingGroups,
  setAccountFollowingGroups,
  modifyGroup,
  setModifyGroup,
  handleTransferAccountFollowing,
  resetDefault,
}) => {
  //Hooks
  const { CommunityGroups, Generic } = useLanguageComponents();
  const { handleError } = useApiStatus();
  const { handleMobileTap, dismissKeypad } = useSettings();

  //Component State
  const [initialAccountFollowingGroups, setInitialAccountFollowingGroups] =
    useState(accountFollowingGroups || []);
  const [openedGroups, setOpenedGroups] = useState([]);

  //UI state
  const [tapHighlightGroupBorder, setTapHighlightGroupBorder] = useState(false);
  const [tapHighlightModify, setTapHighlightModify] = useState();
  const [tapHighlightRemoveGroup, setTapHighlightRemoveGroup] = useState();
  const [tapHighlightTagRemove, setTapHighlightTagRemove] = useState();

  //Component variables
  let tagsGroupComponent;

  //Initialize functions
  useEffect(() => {
    setInitialAccountFollowingGroups(accountFollowingGroups);
  }, [resetDefault]);

  //UI functions
  function handleRemoveAccountInGroupClick(accountObj, communityGroupId) {
    handleMobileTap(
      [
        () => setTapHighlightTagRemove(accountObj._id),
        () => setTapHighlightTagRemove(),
      ],
      [() => handleRemoveAccountInGroup(accountObj, communityGroupId)]
    );
  }

  function handleToggleOpenedGroupClick(e, communityGroupID, index) {
    e.stopPropagation();
    handleMobileTap(
      [
        () => setTapHighlightGroupBorder(communityGroupID),
        () => setTapHighlightGroupBorder(),
      ],
      [() => handleToggleTagGroupOpened(communityGroupID, index)]
    );
  }

  //Component functions
  function handleToggleTagGroupOpened(communityGroupID, index) {
    if (modifyGroup === communityGroupID) {
      return null;
    }

    if (openedGroups.includes(index)) {
      let newOpenedTagGroups = [...openedGroups].filter((i) => i !== index);
      setOpenedGroups(newOpenedTagGroups);
    } else {
      let newOpenedTagGroups = [...openedGroups, index];
      setOpenedGroups(newOpenedTagGroups);
    }
  }

  function handleRemoveGroupClick(e, index, communityGroup) {
    e.stopPropagation();
    handleMobileTap(
      [
        () => setTapHighlightRemoveGroup(true),
        () => setTapHighlightRemoveGroup(false),
      ],
      [() => handleRemoveGroup(index, communityGroup)]
    );
  }

  function handleToggleModifyGroupStateClick(e, communityGroup) {
    e.stopPropagation();
    handleMobileTap(
      [() => setTapHighlightModify(true), () => setTapHighlightModify(false)],
      [() => toggleModifyGroupState(communityGroup)]
    );
  }

  //functions
  function handleCreateGroup() {
    setAccountFollowingGroups([
      ...accountFollowingGroups,
      {
        id: Date.now(),
        label: `Group ${accountFollowingGroups.length + 1}`,
        following: [],
      },
    ]);
  }

  function handleRemoveGroup(index, communityGroup) {
    if (!modifyGroup) {
      const newTagGroup = [...accountFollowingGroups];
      const returnAccounts = communityGroup.following;

      const oldArr = [...accountFollowing];
      let newArr = oldArr.concat(returnAccounts);
      if (setAccountFollowing) {
        setAccountFollowing(newArr);
      }

      newTagGroup.splice(index, 1);
      setAccountFollowingGroups(newTagGroup);
    }
  }

  function toggleModifyGroupState(communityGroup) {
    if (modifyGroup === communityGroup.id) {
      //set autogen group name if finishing group tag modify
      let communityGroupLabel = communityGroup.label.trim();
      if (!communityGroupLabel) {
        let autoGen = `Group ${accountFollowingGroups.length}`;
        handleGroupLabelChange(autoGen, communityGroup.id);
      }
      if (communityGroup.label.length > 25) {
        return handleError(
          {
            message: 'accountFollowingGroupsLabelLimit',
          },
          'CommunityGroups/toggleModifyGroupState'
        );
      }
      setModifyGroup();
      document.getElementById(`communityGroup-${communityGroup.id}`).focus();
    } else if (modifyGroup) {
      return null;
    } else {
      setModifyGroup(communityGroup.id);
    }
  }

  function handleGroupLabelChange(value, communityGroupID) {
    const newTagGroup = [...accountFollowingGroups];
    const adjustedTagGroup = newTagGroup.map((communityGroup) => {
      if (communityGroupID === communityGroup.id) {
        return {
          ...communityGroup,
          label: value,
        };
      } else {
        return communityGroup;
      }
    });
    setAccountFollowingGroups(adjustedTagGroup);
  }

  function handleRemoveAccountInGroup(removeAccountObj, communityGroupId) {
    if (modifyGroup) {
      const newTagGroup = [...accountFollowingGroups];
      const adjustedTagGroup = newTagGroup.map((communityGroup) => {
        if (communityGroupId === communityGroup.id) {
          const updatedFollowing = communityGroup.following.filter(
            (acctObj) => acctObj._id !== removeAccountObj._id
          );
          return {
            ...communityGroup,
            following: updatedFollowing,
          };
        } else {
          return communityGroup;
        }
      });

      handleTransferAccountFollowing(removeAccountObj);
      setAccountFollowingGroups(adjustedTagGroup);
    }
  }

  //JSX conditional
  if (accountFollowingGroups) {
    tagsGroupComponent = (
      <>
        {/* Tag Groups Section */}
        <div className="flex-row full-width mrg-tb24">
          <ItemButton
            fullWidth={true}
            handler={handleCreateGroup}
            text={CommunityGroups.communityGroup}
            addText={true}
            id={'communityGroup-add-btn'}
          />
        </div>
        <ul className="tg full-width ">
          {accountFollowingGroups
            .map((communityGroup, index) => {
              return (
                <li
                  key={`${communityGroup.id}-${index}`}
                  role="button"
                  className={`tg__wrapper highlight-bthin-theme br--small access-ob access-o6 ${
                    modifyGroup === communityGroup.id
                      ? 'tg__wrapper--active'
                      : ''
                  } 
                  ${
                    tapHighlightGroupBorder === communityGroup.id
                      ? 'highlight-b-theme--tap'
                      : ''
                  }
                  `}
                  onClick={(e) =>
                    handleToggleOpenedGroupClick(e, communityGroup.id, index)
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter' &&
                    handleToggleOpenedGroupClick(e, communityGroup.id, index)
                  }
                  id={`communityGroup-${communityGroup.id}`}
                  tabIndex="0"
                  aria-label={`${CommunityGroups.communityGroup}. ${communityGroup.label}. ${communityGroup.tags?.length} ${Generic.tags}.`}
                  aria-expanded={openedGroups.includes(index)}
                >
                  <div
                    className={`tg-heading ${
                      modifyGroup === communityGroup.id
                        ? 'tg-heading--active'
                        : ''
                    } 
                    ${
                      openedGroups.includes(index)
                        ? 'tg-heading--opened mrg-b12'
                        : 'tg-heading--inactive'
                    }
                    `}
                  >
                    {modifyGroup === communityGroup.id ? (
                      <label
                        htmlFor={`tg-input-${index}`}
                        className="fs18 fwn mrg-r12 no-wrap align-center"
                      >
                        {CommunityGroups.communityGroup}
                        <span className="color-theme--bold">*</span>
                        &nbsp;:
                      </label>
                    ) : (
                      <div className="flex-row">
                        <img
                          alt={Generic.folder}
                          src={folder}
                          className="filter-theme svg"
                        />
                        <p className="group-name-ph fs18 fwsb">
                          {communityGroup.label}
                        </p>
                      </div>
                    )}

                    {modifyGroup === communityGroup.id ? (
                      <>
                        <input
                          type="text"
                          id={`tg-input-${index}`}
                          className="fs18"
                          onChange={(e) =>
                            handleGroupLabelChange(
                              e.target.value,
                              communityGroup.id
                            )
                          }
                          onKeyDown={(e) => dismissKeypad(e)}
                          value={communityGroup.label}
                          maxLength="25"
                          enterKeyHint="done"
                        />
                        <p className="fs14 fwn">
                          {communityGroup.label.length}/25
                        </p>
                      </>
                    ) : (
                      <div className="title-tags-row">
                        <p className="group-name fs18 fwsb">
                          {communityGroup.label}
                        </p>
                        <div className="flex-row space-between--ph full-width--ph">
                          <p className="fs18 fwsb">
                            {communityGroup.following?.length}{' '}
                            {Generic.accounts}
                          </p>
                          <img
                            src={dropdown_arrow}
                            alt={Generic.arrow}
                            className={`heading-arrow svg ${
                              !openedGroups.includes(index) ? 'rotate-180' : ''
                            } mrg-t4`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* render tags */}
                  {openedGroups.includes(index) && (
                    <div className="tg__container">
                      {modifyGroup === communityGroup.id && (
                        <>
                          <div className="h12" />
                          {communityGroup.following.map((accountObj, index) => (
                            <div
                              className="follow-tag-button "
                              key={`${communityGroup.id}-${index}`}
                            >
                              <LoadBucketImage
                                imagePath={accountObj?.personal?.profileImage}
                                imgClassName="tag-profile-img tag-profile-community mrg-r6"
                                backupPath={'account_img_default.png'}
                                partialPath={'myndfull_account_images'}
                              />
                              <button
                                className={`tg__tag highlight-bthin-theme br--small highlight-i-lgr fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                                  !initialAccountFollowingGroups.some((group) =>
                                    group.following.some(
                                      (acctObj) =>
                                        acctObj._id === accountObj._id &&
                                        communityGroup.id === group.id
                                    )
                                  )
                                    ? 'new-tag'
                                    : ''
                                } ${
                                  tapHighlightTagRemove === accountObj._id
                                    ? 'filter-red'
                                    : ''
                                }`}
                                tabIndex="0"
                                aria-label={`${Generic.remove} ${accountObj.personal.firstName} ${accountObj.personal.lastName}`}
                                name={index} //keep for removal
                                id={accountObj._id}
                                onClick={() =>
                                  handleRemoveAccountInGroupClick(
                                    accountObj,
                                    communityGroup.id
                                  )
                                }
                              >
                                <p>
                                  {accountObj.personal.firstName}{' '}
                                  {accountObj.personal.lastName}
                                </p>
                                {modifyGroup && (
                                  <img
                                    alt={'X'}
                                    className="disable-select svg"
                                    src={cancel}
                                  />
                                )}
                              </button>
                            </div>
                          ))}
                        </>
                      )}
                      {communityGroup.following?.length === 0 &&
                        modifyGroup === communityGroup.id && (
                          <div className="flex-column flex-center mrg-auto-left mrg-auto-right">
                            <img
                              src={arrow_circular_hallow}
                              alt={Generic.arrow}
                              className="tg-arrow svg"
                            />
                            <p className="fs18 fwn mrg-b12 mrg-auto-lr mrg-t24 text-center">
                              {CommunityGroups.addAccountFromYourList}
                            </p>
                          </div>
                        )}

                      {modifyGroup !== communityGroup.id && (
                        <ul
                          className="tG-summary-tags__container access-ob access-o6"
                          tabIndex={modifyGroup?.length > 1 ? '0' : '-1'}
                          aria-label={`${Generic.accounts}. ${communityGroup.tags}`}
                        >
                          {communityGroup.following.map((accountObj, index) => (
                            <li
                              className="account-tag-community-group fs18 fwn"
                              key={`${communityGroup.id}-${index}-li`}
                            >
                              <div className="follow-tag-button">
                                <LoadBucketImage
                                  imagePath={accountObj?.personal?.profileImage}
                                  imgClassName="tag-profile-img tag-profile-community mrg-r6"
                                  backupPath={'account_img_default.png'}
                                  partialPath={'myndfull_account_images'}
                                />

                                <p className="mrg-r12">
                                  {accountObj.personal.firstName}{' '}
                                  {accountObj.personal.lastName}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                      {/* tag group controls */}
                      <div className="flex-center full-width space-evenly mrg-t12 mrg-b12">
                        <button
                          onClick={(e) =>
                            handleToggleModifyGroupStateClick(e, communityGroup)
                          }
                          className="tg-button highlight-i-lgt access-ob access-o6"
                          id="tg-button-modify"
                          tabIndex="0"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleToggleModifyGroupStateClick(
                                e,
                                communityGroup
                              );
                            }
                          }}
                        >
                          {modifyGroup === communityGroup.id ? (
                            <img
                              src={checkmark}
                              alt={Generic.checkmark}
                              className={`edit svg ${
                                tapHighlightModify ? 'filter-theme' : ''
                              }`}
                              title={CommunityGroups.finish}
                              id="tg-button-modify-icon"
                            />
                          ) : (
                            <img
                              src={edit}
                              alt={CommunityGroups.modifyCommunityGroup}
                              className={`edit svg ${
                                tapHighlightModify ? 'filter-theme' : ''
                              }`}
                              title={CommunityGroups.modifyCommunityGroup}
                              id="tg-button-modify-edit"
                              aria-label={`${CommunityGroups.modifyCommunityGroup} ${communityGroup.label}`}
                            />
                          )}
                        </button>

                        {modifyGroup !== communityGroup.id && (
                          <button
                            onClick={(e) =>
                              handleRemoveGroupClick(e, index, communityGroup)
                            }
                            className="tg-button highlight-i-lgr access-ob access-o6"
                            title={Generic.remove}
                            id="tg-button-remove"
                            tabIndex="0"
                            aria-label={`${CommunityGroups.removeCommunityGroup} ${communityGroup.label}`}
                            onKeyDown={(e) =>
                              e.key === 'Enter'
                                ? handleRemoveGroupClick(
                                    e,
                                    index,
                                    communityGroup
                                  )
                                : null
                            }
                          >
                            <img
                              src={trash}
                              alt={CommunityGroups.removeCommunityGroup}
                              className={`trash svg ${
                                tapHighlightRemoveGroup ? 'filter-red' : ''
                              }`}
                              title={CommunityGroups.removeCommunityGroup}
                              id="tg-button-remove-icon"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            })
            .reverse()}
        </ul>
      </>
    );
  }
  return tagsGroupComponent;
};

export default CommunityGroups;
