import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

//hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useSettings from '../../../../../context/useSettings';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useApiStatus from '../../../../../context/useApiStatus';
import { useUpdateBannerImageMutation } from '../../accountApiSlice';

//components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import ChangeButton from '../../../../../components/ChangeButton/ChangeButton';

//utils
import convertBase64ToJPEG from '../../../../../utils/images/convertBase64ToJPEG';
import getDBImageBlob from '../../../../../utils/images/getDBImageBlob';

let ProfileBanner = ({ pauseCrop }, ref) => {
  //hooks
  const { width } = useSettings();
  const { ProfileBanner } = useLanguageAccount();
  const { handleError } = useApiStatus();
  const { accountPersonalBannerImage, accountId } = useAccountReduxHandlers();

  //api
  const [updateSpeakerBanner, { isLoading: updateSpeakerBannerIsLoading }] =
    useUpdateBannerImageMutation();

  //state
  const [changeBannerImage, setChangeBannerImage] = useState(false);
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });
  const [convertingImage, setConvertingImage] = useState(false);

  //Initialize functions
  useImperativeHandle(ref, () => ({
    handleGetSpeakerBannerImage() {
      if (croppedImg) {
        return croppedImg;
      }
    },
  }));

  useEffect(() => {
    if (
      accountPersonalBannerImage !== 'banner_img_default.png' &&
      changeBannerImage
    ) {
      getDBImageBlob(accountPersonalBannerImage, 'account', setCroppedImg);
    }
  }, [accountPersonalBannerImage, changeBannerImage]); //gets new blob for new bannerImage or if user cancels change

  useEffect(() => {
    return () => {
      handleCancelSaveImage();
    };
  }, []);

  function handleBannerImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  function handleCancelSaveImage() {
    if (croppedImg.imagePreview) {
      URL.revokeObjectURL(croppedImg.imagePreview);
    }
    setCroppedImg({
      croppedImage: '',
      fileName: '',
      imagePreview: '',
    });
    setChangeBannerImage(false);
  }

  async function saveAccountBannerImage(e) {
    e.preventDefault();

    if (!updateSpeakerBannerIsLoading) {
      const formData = new FormData();
      const speakerBannerImage = croppedImg;
      let bannerImageJPG;

      if (speakerBannerImage.croppedImage.croppedImage) {
        bannerImageJPG = await convertBase64ToJPEG(
          speakerBannerImage.croppedImage.croppedImage,
          'bannerImage.jpg'
        );
      }

      //5 mb max.
      if (bannerImageJPG?.size >= 5242880) {
        return handleError({
          message: 'imageSizeTooLarge',
          id: Date.now(),
          origin: 'ProfileBanner.js/saveAccountBannerImage',
        });
      }

      formData.append('bannerImage', bannerImageJPG);
      formData.append('accountId', accountId);

      await updateSpeakerBanner(formData);
      setChangeBannerImage(false);
    }
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="bannerLabel"
        tabIndex="0"
      >
        {ProfileBanner.bannerImage}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {ProfileBanner.description}
      </p>
      <div className="h24" />

      {changeBannerImage && !convertingImage && (
        <ImageCropper
          ariaImageDescription={ProfileBanner.ariaImageDescription}
          center={true}
          cover={'horizontal-cover'}
          handleImage={handleBannerImg}
          id={'speakerBannerCropper'}
          imagePreview={croppedImg.imagePreview}
          pauseCrop={pauseCrop}
          width={512}
          height={96}
          setHOActionBtnsUnavailable={setConvertingImage}
          customFileUploaderWrapper={'file-uploader__wrapper--speaker'}
          spinnerMinWidth={'96px'}
          spinnerMinHeight={'96px'}
        />
      )}

      {changeBannerImage && !convertingImage && (
        <ConfirmSelection
          btn1Text={ProfileBanner.cancel}
          btn1Color={'neutral'}
          btn1Handler={handleCancelSaveImage}
          btn2Text={ProfileBanner.save}
          btn2Color={'pos'}
          btn2Handler={width > 512 ? saveAccountBannerImage : null}
          center={true}
          customWrapper={'mrg-auto-lr mrg-t36'}
        />
      )}

      <div className="flex-center flex-column full-width ">
        {!changeBannerImage && (
          <div className="flex-column full-width mrg-auto-left mrg-auto-right justify-center align-center">
            <LoadBucketImage
              imagePath={accountPersonalBannerImage}
              containerClassName={'account-profile-banner--static mrg-auto-lr'}
              imgClassName={'account-profile-banner--image'}
              backupPath={'banner_img_default.png'}
              partialPath={'myndfull_banner_images'}
            />
            <div className="h24" />
            <ChangeButton handler={setChangeBannerImage} />
          </div>
        )}
      </div>
      <div className="h36" />
    </div>
  );
};

ProfileBanner = forwardRef(ProfileBanner);

export default ProfileBanner;
