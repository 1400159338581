export const countryKeyToCountryISO = (key) => {
  if (key === 'canada') {
    return 'CA';
  }

  if (key === 'mexico') {
    return 'MX';
  }

  if (key === 'unitedStates') {
    return 'US';
  }
};
