import { useState, useCallback } from 'react';

function useDelayedHandler() {
  const [isProcessing, setIsProcessing] = useState(false);

  const delayedHandler = useCallback(
    async (handler, ...args) => {
      if (isProcessing) {
        console.warn(
          'Handler is already processing. Ignoring additional calls.'
        );
        return;
      }

      setIsProcessing(true);

      try {
        await handler(...args);
      } catch (error) {
        console.error('Error in handler execution:', error);
      } finally {
        setIsProcessing(false);
      }
    },
    [isProcessing]
  );

  return { delayedHandler };
}

export default useDelayedHandler;
