import { useEffect, useState } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import AttendeeSignUp from './AttendeeSignUp';
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';

//Utility
import AttendeeManager from './AttendeeManager';

const AttendeeContent = ({
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
  rolesSignUpIsLoading,
  accountCommunityPopulated,
  handleRefreshAccountCommunityLists,
}) => {
  //Hooks
  const { accountBigTags, accountAttendeeModeActive } =
    useAccountReduxHandlers();

  //UI state
  const [attendeeRoleSignUpComplete, setAttendeeRoleSignUpComplete] =
    useState(true);

  useEffect(() => {
    if (rolesSignUpIsLoading) {
      setAttendeeRoleSignUpComplete(false);
    }
  }, [rolesSignUpIsLoading]);

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <div className="section min-full-height">
        {!accountAttendeeModeActive &&
        (accountBigTags?.length === 0 || !accountBigTags) ? (
          <AttendeeSignUp
            saveAccountChange={saveAccountChange}
            loadingSaveBtnUI={loadingSaveBtnUI}
          />
        ) : rolesSignUpIsLoading ? (
          <LoadingScreenContent
            numberOfLoadChecks={1}
            loadCheck1={true}
            setLoadComplete={() => setAttendeeRoleSignUpComplete(true)}
          />
        ) : (
          attendeeRoleSignUpComplete && (
            <AttendeeManager
              saveAccountChange={saveAccountChange}
              updateAccountIsSuccess={updateAccountIsSuccess}
              loadingSaveBtnUI={loadingSaveBtnUI}
              accountCommunityPopulated={accountCommunityPopulated}
              handleRefreshAccountCommunityLists={
                handleRefreshAccountCommunityLists
              }
            />
          )
        )}

        <div className="h48" />
      </div>
    </div>
  );
};

export default AttendeeContent;
