import { useRef, useState, useEffect } from 'react';

//hooks
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

import { useOrganizeHandlers } from '../useOrganizeHandlers';

//components
import PasswordInput from '../../../../components/TextArea/PasswordInput';
import SelectionsModal from '../../../../components/Selections/SelectionsModal';
import ButtonToggle from '../../../../components/ButtonSwitch/ButtonToggle';
import Selections from '../../../../components/Selections/Selections';
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import ParticipantListItem from './ParticipantSections/ParticipantListItem/ParticipantListItem';
import TranslationsSpeakerBase from './EventTranslationsSections/TranslationsSpeakerBase';

//utils
import { img_language } from '../../../../assets/icons';
import ScrollTo from '../../../../utils/UI/ScrollTo';

const EventTranslations = ({
  initialize,
  CreateEvent,
  skipToId,
  skipToLabel,

  //state
  participants,
  participantImagesCropped,
  translationsEnabled,
  translationsRoomCodeId,
  translationsJoinRoomPasswordToggle,
  translationsPassword,
  translationsRoomLanguages,

  //handlers
  handleSetParticipants,
  handleSetTranslationsTranslationsEnabled,
  handleSetTranslationsRoomCodeId,
  handleSetTranslationsJoinRoomPasswordToggle,
  handleSetTranslationsPassword,
  handleSetTranslationsRoomLanguages,
}) => {
  //hooks
  const { accountTranslationRoomsRoomCodes } = useAccountReduxHandlers();
  const { TranslationCreateRoomPassword, TranslationRoomCreateRoomLanguages } =
    useLanguageTranslationRoom();
  const { handleTranslationsSpeakerDataValidation } = useOrganizeHandlers();
  const { TranslationLanguageOpts } = useLanguageData();

  //state
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [showParticipantData, setShowParticipantData] = useState(false);
  // const [
  //   initialSpeakersDataForChangeList,
  //   setInitialSpeakersDataForChangeList,
  // ] = useState(false);

  //room code
  const [roomCodeOpts, setRoomCodeOpts] = useState({});
  //languages
  const [allLanguagesActive, setAllLanguagesActive] = useState(false);

  //variables
  const effectRan = useRef(false);

  //Initialize
  useEffect(() => {
    if (
      accountTranslationRoomsRoomCodes &&
      translationsRoomLanguages &&
      TranslationLanguageOpts &&
      effectRan.current === false
    ) {
      effectRan.current = true;
      let newOpts = {};

      accountTranslationRoomsRoomCodes?.map((rm) => {
        newOpts = { ...newOpts, [rm._id]: rm.roomCode };
      });
      setRoomCodeOpts(newOpts);

      //check languages
      if (
        translationsRoomLanguages?.length ===
        Object.entries(TranslationLanguageOpts)?.length - 1
      ) {
        // -1 for 'select' but not an option for user data
        setAllLanguagesActive(true);
      }

      checkValidRoomLanguagesForParticipants();
    }
  }, [
    accountTranslationRoomsRoomCodes,
    translationsRoomLanguages,
    TranslationLanguageOpts,
  ]); //set room code options based on subscriptions (room code + ids) + set room code languages

  useEffect(() => {
    if (selectedParticipant) {
      if (
        !participants?.some(
          (participant) => participant.id === selectedParticipant.id
        )
      ) {
        setSelectedParticipant();
        setShowParticipantData(false);
      }
    }
  }, [participants]); //change ui for selected participant

  useEffect(() => {
    if (participants) {
      checkValidRoomLanguagesForParticipants();
    }
  }, [translationsRoomLanguages]); //change translationsCompletionStatus to incomplete if invalid language selection

  //edit change list

  // const initializeSpeakersDataRan = useRef(false);

  //initialize
  // useEffect(() => {
  //   if (editEventMode && loadTranslationsSpeakersData) {
  //     if (initializeSpeakersDataRan.current === false) {
  //       initializeSpeakersDataRan.current = true;
  //       setInitialSpeakersDataForChangeList(loadTranslationsSpeakersData);
  //     }
  //   }
  // }, [loadTranslationsSpeakersData]);

  //Functions
  function handleRoomLanguages(allLanguages) {
    if (allLanguages) {
      let newRoomLanguages = [];
      const languageKeys = Object.keys(TranslationLanguageOpts);
      newRoomLanguages = languageKeys.filter((opt) => opt !== 'select');
      handleSetTranslationsRoomLanguages(newRoomLanguages);
      setAllLanguagesActive(true);
    } else {
      setAllLanguagesActive(false);
      handleSetTranslationsRoomLanguages([]);
    }
  }

  //General speaker actions
  function handleSelectedParticipant(participantId) {
    const newSelectedParticipant = participants.find(
      (p) => participantId === p?.id
    );
    setSelectedParticipant(newSelectedParticipant);
    setShowParticipantData(true);

    const delay = setTimeout(() => {
      ScrollTo(
        'translationsParticipantsLabel',
        'smooth',
        false,
        'translationsParticipantsLabel'
      );
    }, 250);

    return () => clearTimeout(delay);
  }

  function checkValidRoomLanguagesForParticipants() {
    if (participants?.length > 0) {
      if (
        translationsRoomLanguages?.length === 0 ||
        translationsRoomLanguages?.includes('select')
      ) {
        //room doesnt have languages -- set requires language warning.
        let newParticipants = [];

        participants?.map((p) => {
          let newP = JSON.parse(JSON.stringify(p));
          newP.translationsRoomRequiresLanguage = true;

          if (newP.organizedAs !== 'create') {
            if (newP.dispatches?.translationsStatus === 'dispatching') {
              newP.dispatches.translationsStatus = 'notDispatched';
            }
          } //prevent dispatch status

          newParticipants.push(newP);
        });
        handleSetParticipants(newParticipants);
      } else {
        let newParticipants = [];

        participants.map((p) => {
          let newP = JSON.parse(JSON.stringify(p));

          //participant has a language set not authorized by organizer
          if (
            newP.translationsSpeakerData.languageOpt &&
            newP.translationsSpeakerData.languageOpt !== 'select' &&
            !translationsRoomLanguages?.includes(
              newP.translationsSpeakerData.languageOpt
            )
          ) {
            newP.translationsRoomRequiresLanguage = false;
            newP.translationsLanguageConflict = true;

            if (newP.organizedAs !== 'create') {
              if (newP.dispatches?.translationsStatus === 'dispatching') {
                newP.dispatches.translationsStatus = 'notDispatched';
              }
            } //prevent dispatch status

            newParticipants.push(newP);
          } else {
            //room languages are set and participant does not have invalid language
            newP.translationsRoomRequiresLanguage = false;
            newP.translationsLanguageConflict = false;
            newParticipants.push(newP);
          }
        });
        handleSetParticipants(newParticipants);
      }
    }
  } //function to change translationsCompletionStatus to incomplete if invalid language selection

  function checkValidParticipantLanguageSelection(newParticipant) {
    let hasConflict = false;
    if (
      !newParticipant.translationsSpeakerData.languageOpt ||
      newParticipant.translationsSpeakerData.languageOpt === 'select'
    ) {
      return hasConflict;
    }
    if (
      newParticipant.translationsSpeakerData.languageOpt &&
      newParticipant.translationsSpeakerData.languageOpt !== 'select' &&
      !translationsRoomLanguages?.includes(
        newParticipant.translationsSpeakerData.languageOpt
      )
    ) {
      hasConflict = true;
    }
    return hasConflict;
  }

  function handleSaveAndReturn(participantData) {
    //only update relevant data to avoid stale data issues when data was loaded (dispatches/translations can change)
    const foundParticipant = participants.find(
      (p) => p.id === participantData.id
    );
    let newParticipant = JSON.parse(JSON.stringify(foundParticipant));
    newParticipant.translationsSpeakerData =
      participantData.translationsSpeakerData;

    let newParticipants = [];

    for (let p of participants) {
      if (p.id === newParticipant.id) {
        //check for language conflict
        const hasLanguageConflict =
          checkValidParticipantLanguageSelection(newParticipant);
        newParticipant.translationsLanguageConflict = hasLanguageConflict;

        //check for completion
        const isComplete = handleTranslationsSpeakerDataValidation(
          newParticipant.translationsSpeakerData
        );
        newParticipant.translationsCompletionStatus = isComplete
          ? 'complete'
          : 'incomplete';

        //save
        newParticipants.push(newParticipant);
      } else {
        newParticipants.push(p);
      }
    }

    handleSetParticipants(newParticipants);

    //clean up
    setSelectedParticipant();
    setShowParticipantData(false);
  }

  return (
    <fieldset className="fieldset" id="fieldset-translations">
      {/* <legend id="aria-fieldset-instructions-translations">
        {CreateEvent.contact.ariaFieldsetInstructions}
      </legend> */}
      <div className="h0-ph24" />
      <label
        className="label"
        id="translationsLabel"
        aria-describedby="aria-fieldset-instructions-translations"
        tabIndex="0"
      >
        {CreateEvent.eventTranslations.translations}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />

      <ButtonSwitch
        horizontal={true}
        id={'enableTranslations'}
        title={CreateEvent.eventTranslations.enableTranslations}
        handleSwitch={handleSetTranslationsTranslationsEnabled}
        loadTrigger={initialize}
        loadValue={translationsEnabled}
        temporaryDisable={
          accountTranslationRoomsRoomCodes?.length === 0 ||
          !accountTranslationRoomsRoomCodes
        }
        temporaryDisableMessage={'roomCodeSubscriptionRequired'}
      />
      {translationsEnabled && (
        <>
          <div className="h36" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="roomCodeLabel"
          >
            {CreateEvent.eventTranslations.roomCode}
            <p className="color-theme">&nbsp;*</p>
          </label>

          <Selections
            id={'hostingOption'}
            options={roomCodeOpts}
            handleSelection={handleSetTranslationsRoomCodeId}
            loadTrigger={initialize}
            loadValue={translationsRoomCodeId}
            customWrapper={'mrg-t24'}
            viewHorizontal={true}
          />
          <div className="h36" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="translationRoomLanguagesLabel"
          >
            {CreateEvent.eventTranslations.roomLanguages}
            <p className="color-theme">&nbsp;*</p>
          </label>
          <div className="h24" />
          <ButtonToggle
            textLeft={TranslationRoomCreateRoomLanguages.manual}
            textRight={TranslationRoomCreateRoomLanguages.allAvailableLanguages}
            handleSwitch={handleRoomLanguages}
            loadValue={allLanguagesActive}
            loadTrigger={allLanguagesActive}
          />
          {!allLanguagesActive && (
            <SelectionsModal
              addButtonText={TranslationRoomCreateRoomLanguages.addLanguages}
              DataOpts={TranslationLanguageOpts}
              fullWidth={true}
              iconModal={img_language}
              iconModalClass={'filter-theme'}
              iconOff={true}
              modalTitle={TranslationRoomCreateRoomLanguages.roomLanguages}
              selectionState={translationsRoomLanguages}
              setSelectionState={handleSetTranslationsRoomLanguages}
              subTextOn={false}
              id={'roomLanguages'}
              itemButtonCustomWrapper={'item-button-standard mrg-t24'}
            />
          )}

          <div className="h36" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="translationsPasswordLabel"
          >
            {CreateEvent.eventTranslations.password}
          </label>
          <div className="h12" />

          <ButtonSwitch
            id={'attendeePasswordToggle'}
            title={TranslationCreateRoomPassword.attendeesRequirePassword}
            handleSwitch={handleSetTranslationsJoinRoomPasswordToggle}
            loadValue={translationsJoinRoomPasswordToggle}
            loadTrigger={true}
            customWrapper={'mrg-b24'}
            horizontal={true}
          />

          {translationsJoinRoomPasswordToggle && (
            <PasswordInput
              defaultValue={translationsPassword}
              handleInput={handleSetTranslationsPassword}
              preventDefaultOff={true}
              id={'attendeePasswordInput'}
              customTitle={TranslationCreateRoomPassword.password}
              customKeyDownHandler={(e) => {
                if (e.key === 'Enter') {
                  handleSetTranslationsPassword(e.target.value);
                }
              }}
              customWrapper={'item-button-standard'}
            />
          )}

          <div className="h36" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="translationsParticipantsLabel"
          >
            {CreateEvent.eventTranslations.participants}
          </label>

          {selectedParticipant && showParticipantData ? (
            <>
              <div className="h24" />
              {selectedParticipant && (
                <TranslationsSpeakerBase
                  prevSpeaker={selectedParticipant}
                  handleSaveAndReturn={handleSaveAndReturn}
                  roomLanguages={translationsRoomLanguages}
                />
              )}
            </>
          ) : (
            <ul className="community__container">
              {participants?.length > 0 ? (
                <>
                  {participants?.map((participant, index) => {
                    const participantPreviewImage =
                      participantImagesCropped?.find((p) => {
                        if (p?.id === participant?.id) {
                          return p;
                        }
                      });

                    return (
                      <li key={`communityContact-${index}`}>
                        <ParticipantListItem
                          participant={participant}
                          handler={handleSelectedParticipant}
                          previewImage={participantPreviewImage}
                          customWrapper={'mrg-t12'}
                          listItemMode={'translations'}
                        />
                      </li>
                    );
                  })}
                </>
              ) : (
                <p className="fs21 fwn mrg-t12">
                  {CreateEvent.eventTranslations.noParticipants}
                </p>
              )}
            </ul>
          )}
        </>
      )}
    </fieldset>
  );
};

export default EventTranslations;
