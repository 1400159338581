import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';

const ProfilePersonalSkeleton = () => {
  return (
    <>
      <div className="full-width opacity-05">
        <LoadBucketImage
          containerClassName={'account-profile-banner '}
          imgClassName={'account-profile-banner--image'}
          backupPath={'banner_img_default.png'}
          partialPath={'myndfull_banner_images'}
        />
      </div>
      <div className="account-profile__wrapper account-profile__wrapper--empty">
        <div className="flex-row full-width mrg-b24" />
      </div>
    </>
  );
};

export default ProfilePersonalSkeleton;
