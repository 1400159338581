//hooks
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';

//components

//utils
import { img_exclamation } from '../../../../../assets/icons/index';

const TranslationsSpeakerData = ({ speakerData, roomLanguages }) => {
  //hooks
  const { VoiceOpts, TranslationLanguageOpts } = useLanguageData();
  const { TranslationsSpeakerData, IconAlts } = useLanguageComponents();

  return (
    <div className="border-reg br--standard bg-color-light pad-24 mrg-t12">
      <p className="fs21 fwsb full-width mrg-b12 bg-color-white br--standard pad-12">
        {TranslationsSpeakerData.participantTranslationData}
      </p>

      {!roomLanguages?.includes(speakerData?.languageOpt) &&
        speakerData?.languageOpt !== 'select' &&
        speakerData?.languageOpt && (
          <div className="banners__notice align-center fs18 full-width mrg-tb12">
            <img
              src={img_exclamation}
              alt={IconAlts.iconExclamation}
              style={{ height: '18px' }}
              className="align-center filter-red"
            />
            <p className="mrg-l12">
              {TranslationsSpeakerData.unsupportedLanguage}:{' '}
              {TranslationLanguageOpts[speakerData?.languageOpt]}
            </p>
          </div>
        )}

      <div className="flex-column fs18 bg-color-white br--standard pad-12">
        <p className="mrg-b6">
          {speakerData?.name
            ? speakerData?.name
            : TranslationsSpeakerData.nameNA}
        </p>
        <div className="mrg-b6 flex-column">
          <p>
            {speakerData?.languageOpt && speakerData?.languageOpt !== 'select'
              ? TranslationLanguageOpts[speakerData?.languageOpt]
              : TranslationsSpeakerData.languageNA}
          </p>
        </div>
        <p>
          {speakerData?.voiceOpt !== 'select'
            ? VoiceOpts[speakerData?.voiceOpt]
            : TranslationsSpeakerData.voiceNA}
        </p>
      </div>

      <div className="flex-column fs18 mrg-t12 bg-color-white br--standard pad-12">
        <p className="fwsb">{TranslationsSpeakerData.discussionContext}:</p>
        <p className="mrg-t6">
          {speakerData?.discussionContext
            ? speakerData?.discussionContext
            : TranslationsSpeakerData.na}
        </p>
      </div>

      <div className="flex-column fs18 mrg-t12 bg-color-white br--standard pad-12">
        <p className="fwsb">{TranslationsSpeakerData.keywords}:</p>
        <div className="dropdown-tags__wrapper mrg-t12">
          {speakerData?.keywordsList?.length > 0 ? (
            <ul className="tags__container">
              {speakerData?.keywordsList?.map((keyword, index) => {
                return (
                  <li
                    className="tag__list-item--no-btn border-thin fwsb fs16 fs14-phn no-select access-ob access-o6"
                    key={`participantTranslations-${index}`}
                  >
                    {keyword}
                  </li>
                );
              })}
            </ul>
          ) : (
            TranslationsSpeakerData.na
          )}
        </div>
      </div>
    </div>
  );
};

export default TranslationsSpeakerData;
