import { useState, useEffect, useRef } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//Utility
import { checkmark_green, img_language } from '../../../../../../assets/icons';

const SearchLanguages = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { accountSearchLanguages: loadSearchLanguages, accountAttendee } =
    useAccountReduxHandlers();
  const { LanguageSimpleNoNotListedOpts } = useLanguageData();
  const { Generic } = useLanguageComponents();

  //Component state
  const [searchLanguages, setSearchLanguages] = useState(loadSearchLanguages);
  const [searchLanguagesSaveValid, setSearchLanguagesSaveValid] =
    useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (effectRan.current === false) {
      setSearchLanguages(loadSearchLanguages);

      effectRan.current = true;
    }
  }, [loadSearchLanguages]);

  //Component functions
  useEffect(() => {
    const sameContents =
      searchLanguages.length === loadSearchLanguages.length &&
      searchLanguages.every(
        (value, index) => value === loadSearchLanguages[index]
      );
    if (
      !sameContents &&
      !searchLanguages.includes('select') &&
      searchLanguages?.length > 0
    ) {
      setSearchLanguagesSaveValid(true);
    } else {
      setSearchLanguagesSaveValid(false);
    }
  }, [searchLanguages, loadSearchLanguages]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row  align-center full-width"
        tabIndex="0"
        id="searchLanguagesLabel"
      >
        {Account.attendee.searchLanguages}
        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

          {searchLanguages?.length > 0 &&
            !searchLanguages.includes('select') && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.searchLanguagesDescription}
      </p>

      <div className="h24" />
      <SelectionsModal
        addButtonText={Account.attendee.addLanguages}
        DataOpts={LanguageSimpleNoNotListedOpts}
        fullWidth={true}
        iconModal={img_language}
        iconModalClass={'filter-theme'}
        iconOff={true}
        modalTitle={Account.attendee.searchLanguages}
        selectionState={searchLanguages}
        setSelectionState={setSearchLanguages}
        subTextOn={false}
        id={'searchLanguagesSelections'}
        itemButtonCustomWrapper={'item-button-full'}
      />

      <div className="h24" />
      <SaveButton
        saveValid={searchLanguagesSaveValid}
        handler={() =>
          saveAccountChange(
            {
              attendee: {
                ...accountAttendee,
                searchLanguages,
              },
            },
            'searchLanguages'
          )
        }
        saveAriaLanguage={Account.attendee.searchLanguages}
        isLoading={loadingSaveBtnUI === 'searchLanguages'}
      />
      <div className="h36" />
    </div>
  );
};

export default SearchLanguages;
