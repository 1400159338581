import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useSettings from '../../../../context/useSettings';
import { useSetAccountNoticesSeenMutation } from '../noticesApiSlice';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import NoticeBase from './notices/NoticeBase';
import NoticeSettings from './NoticeSettings';

//utils
import {
  img_archived,
  img_bell,
  return_curved_arrow,
  settings,
} from '../../../../assets/icons';

const NoticeOverlay = () => {
  //hooks
  const {
    width,
    handleMobileTap,
    contentLanguage,
    openMobileNotificationsMenu,
    setOpenMobileNotificationsMenu,
  } = useSettings();
  const { accountProfileImage, accountNotices, accountId } =
    useAccountReduxHandlers();
  const { IconAlts, NoticeOverlay, Generic } = useLanguageComponents();

  //api
  const [setAccountNoticesSeen] = useSetAccountNoticesSeenMutation();

  //state
  const [archiveMode, setArchiveMode] = useState(false);
  const [settingsMode, setSettingsMode] = useState(false);
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [noticesSeen, setNoticesSeen] = useState(false);

  //UI
  const [noticeOverlayIsOpened, setNoticeOverlayIsOpened] = useState(false);
  const [noticeOverlayIsChanging, setNoticeOverlayIsChanging] = useState(false);
  const [archivedNoticesNum, setArchivedNoticesNum] = useState(0);
  const [unseenNoticesNum, setUnseenNoticesSum] = useState();

  const [highlightNoticeHeaderTap, setHighlightNoticeHeaderTap] =
    useState(false);
  const [highlightArchiveReturnBtnTap, setHighlightArchiveReturnBtnTap] =
    useState(false);

  //initialize
  useEffect(() => {
    //set filtered
    let sortedNotices = [];
    if (accountNotices?.length > 0) {
      sortedNotices = [...accountNotices].sort(
        (a, b) => b.createdAt - a.createdAt
      );
    }

    if (!archiveMode) {
      let activeNotices = [];

      sortedNotices?.map((sortedNotice) => {
        if (
          sortedNotice?.noticeStatus !== 'archived' &&
          !sortedNotice?.noticeHistory
        ) {
          activeNotices.push(sortedNotice);
        }
      });

      setFilteredNotices(activeNotices);
    } else {
      let archivedNotices = [];
      sortedNotices?.map((sortedNotice) => {
        if (
          sortedNotice?.noticeStatus === 'archived' ||
          sortedNotice?.noticeHistory
        ) {
          archivedNotices.push(sortedNotice);
        }
      });
      setFilteredNotices(archivedNotices);
    }

    if (accountNotices) {
      //set unseen alert number
      let newUnseenNotices = 0;
      let newArchivedNoticesNum = 0;
      accountNotices?.map((n) => {
        if (n.noticeStatus === 'unseen') {
          newUnseenNotices++;
        }

        if (n.noticeStatus === 'archived' || n?.noticeHistory) {
          newArchivedNoticesNum++;
        }
      });

      setArchivedNoticesNum(newArchivedNoticesNum);
      setUnseenNoticesSum(newUnseenNotices);
    }
  }, [accountNotices, archiveMode]);

  //API
  useEffect(() => {
    if (noticeOverlayIsOpened) {
      setNoticesSeen(true);
    }
  }, [noticeOverlayIsOpened]);

  useEffect(() => {
    if (noticesSeen && !noticeOverlayIsOpened) {
      let includesUnseenNotices = false;
      accountNotices?.map((n) => {
        if (n?.noticeStatus === 'unseen') {
          return (includesUnseenNotices = true);
        }
      });

      if (includesUnseenNotices) {
        const delayTimer = setTimeout(() => {
          setAccountNoticesSeen({ accountId });
        }, 1000);

        return () => {
          clearTimeout(delayTimer);
        };
      }
      setNoticesSeen(false);
    }
  }, [noticesSeen, noticeOverlayIsOpened]);

  //UI
  useEffect(() => {
    if (width > 480) {
      setNoticeOverlayIsChanging(true);

      let animationTimeout = null;
      animationTimeout = setTimeout(() => {
        setNoticeOverlayIsChanging(false);
      }, 600); //must correspond with noticeOverlay__wrapper transition time
      return () => {
        clearTimeout(animationTimeout);
      };
    }
  }, [noticeOverlayIsOpened]); //used for the increase height on hover animation for when mouse is over notices

  useEffect(() => {
    if (openMobileNotificationsMenu && width < 480) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, [openMobileNotificationsMenu, noticeOverlayIsOpened, width]); //ensures that mobile view of notifications removes main scrollbar and avoids double scrollbars

  useEffect(() => {
    if (openMobileNotificationsMenu && !noticeOverlayIsOpened) {
      setNoticeOverlayIsOpened(true);
    }
  }, [openMobileNotificationsMenu]); //fixes sequencing problem where clicking navbar in mobile view leads first click of settings or archive to not open corresponding containers

  const settingsMenu = (
    <div className="noticeOverlay-settings__wrapper">
      {archiveMode || settingsMode ? (
        <button
          className={`noticeOverlay-settings fs18 color-gray access-ob ${
            highlightArchiveReturnBtnTap ? 'highlight-bg-lg--tap' : ''
          }`}
          tabIndex="0"
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightArchiveReturnBtnTap(true),
                () => setHighlightArchiveReturnBtnTap(false),
              ],
              [
                () => {
                  if (noticeOverlayIsOpened) {
                    setArchiveMode(false);
                    setSettingsMode(false);
                  } else {
                    setNoticeOverlayIsOpened(true);
                  }
                },
              ]
            )
          }
        >
          <img
            src={return_curved_arrow}
            alt={IconAlts.iconArrow}
            className={`filter-gray mrg-r8`}
          />
          <p className="fwsb">{Generic.return}</p>
        </button>
      ) : (
        <button
          className={`noticeOverlay-settings fs18 color-gray access-ob ${
            highlightArchiveReturnBtnTap ? 'highlight-bg-lg--tap' : ''
          }`}
          tabIndex="0"
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightArchiveReturnBtnTap(true),
                () => setHighlightArchiveReturnBtnTap(false),
              ],
              [
                () => {
                  if (noticeOverlayIsOpened) {
                    setArchiveMode(!archiveMode);
                    setSettingsMode(false);
                  } else {
                    setNoticeOverlayIsOpened(true);
                  }
                },
              ]
            )
          }
        >
          {archiveMode || settingsMode ? (
            <>
              <img
                src={return_curved_arrow}
                alt={IconAlts.iconArrow}
                className={`filter-gray mrg-r8 `}
              />
              <p className="fwsb">{Generic.return}</p>
            </>
          ) : (
            <>
              <img
                src={img_archived}
                alt={IconAlts.iconArchiveBox}
                className={`filter-gray mrg-r8`}
              />
              <p className="fwsb">({archivedNoticesNum})</p>
            </>
          )}
        </button>
      )}

      <button
        className={`noticeOverlay-settings fs18 color-gray ${
          settingsMode ? 'noticeOverlay-settings--selected' : ''
        } access-ob`}
        tabIndex="0"
        aria-expanded={settingsMode}
        onClick={() => {
          if (noticeOverlayIsOpened) {
            setSettingsMode(!settingsMode);
          } else {
            setNoticeOverlayIsOpened(true);
          }
        }}
      >
        <img
          src={settings}
          alt={IconAlts.iconSettings}
          className="filter-gray mrg-r12"
        />
        <p className="fwsb">{NoticeOverlay.settings}</p>
      </button>
    </div>
  );

  return (
    <>
      {width <= 480 && (
        <button
          className={`access-ow access-o-12 ${
            openMobileNotificationsMenu ? 'slidemenu__exit' : 'remove'
          }`}
          onClick={() => {
            setOpenMobileNotificationsMenu(false);
            setSettingsMode(false);
            setArchiveMode(false);
          }}
          tabIndex="0"
          aria-label={NoticeOverlay.ariaCloseNoticesMenu}
          id="overlay"
        />
      )}
      <div
        className={`noticeOverlay__wrapper access-ob access-o-6 ${
          (width > 1280 && noticeOverlayIsOpened) || openMobileNotificationsMenu
            ? 'noticeOverlay__wrapper--opened'
            : 'noticeOverlay__wrapper--closed'
        }
          ${
            width > 1280 && !noticeOverlayIsChanging && !noticeOverlayIsOpened
              ? 'noticeOverlay__wrapper--hover-rise'
              : ''
          }
          `}
      >
        <button
          className={`noticeOverlay__header access-ob ${
            highlightNoticeHeaderTap ? 'highlight-bg-lg--tap' : ''
          }`}
          tabIndex="0"
          aria-expanded={noticeOverlayIsOpened}
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightNoticeHeaderTap(true),
                () => setHighlightNoticeHeaderTap(false),
              ],
              [
                () => setNoticeOverlayIsOpened(!noticeOverlayIsOpened),
                () => setArchiveMode(false),
                () => setSettingsMode(false),
                () => setOpenMobileNotificationsMenu(false),
              ]
            )
          }
        >
          <div className="flex-row align-center mrg-auto-right text-left">
            {archiveMode && !settingsMode ? (
              <div className="flex-row align-center">
                <img
                  src={img_archived}
                  alt={IconAlts.iconBell}
                  className="filter-theme mrg-r12"
                  style={{ height: '24px' }}
                />
                <p className="fs18 ff2 mrg-t2">{NoticeOverlay.archive}</p>
              </div>
            ) : !settingsMode ? (
              <div className="flex-row align-center">
                <img
                  src={img_bell}
                  alt={IconAlts.iconBell}
                  className="filter-theme mrg-r12"
                  style={{ height: '24px' }}
                />
                {unseenNoticesNum > 0 && (
                  <div
                    className={`noticeOverlay__header-notice-alert mrg-r12 ${
                      unseenNoticesNum > 9
                        ? 'msg-notice-2digit'
                        : 'msg-notice-1digit'
                    }`}
                  >
                    <p className="mrg-t2">{unseenNoticesNum}</p>
                  </div>
                )}
                <p className="fs18 ff2 mrg-t2">{NoticeOverlay.notices}</p>
              </div>
            ) : (
              settingsMode && (
                <div className="flex-row align-center">
                  <img
                    src={settings}
                    alt={IconAlts.settings}
                    className="filter-theme mrg-r12"
                    style={{ height: '24px' }}
                  />
                  <p className="fs18 ff2 mrg-t2">{NoticeOverlay.settings}</p>
                </div>
              )
            )}
          </div>

          <LoadBucketImage
            imagePath={accountProfileImage}
            imgClassName={'noticeOverlay__header-profile-image'}
            backupPath={'account_img_default.png'}
            partialPath={'myndfull_account_images'}
          />
        </button>
        {width <= 480 && <>{settingsMenu}</>}

        {noticeOverlayIsOpened && (
          <div className="full-width flex-column">
            <div className="divider opacity-5" />
          </div>
        )}
        <div className="noticeOverlay__container">
          {settingsMode ? (
            <NoticeSettings />
          ) : (
            contentLanguage && (
              <ul className="noticeOverlay__container">
                {filteredNotices
                  ?.map((n) => {
                    return (
                      <NoticeBase
                        n={n}
                        setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
                        noticeOverlayIsOpened={noticeOverlayIsOpened}
                        key={`account-notice-${n._id}`}
                      />
                    );
                  })
                  .reverse()}
              </ul>
            )
          )}
        </div>
        {width > 480 && <>{settingsMenu}</>}
      </div>
    </>
  );
};
export default NoticeOverlay;
