import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useRemoveEventActiveMutation,
  useRemoveEventScheduledMutation,
  useAcceptPendingApprovalVirtualMutation,
  useRemoveRegistrationVirtualMutation,
  useAcceptPendingApprovalInPersonMutation,
  useRemoveRegistrationInPersonMutation,
  useAcceptPendingApprovalInPersonAllMutation,
  useRemoveEventActiveUpdateAccountsMutation,
  useRemoveEventScheduledUpdateAccountsMutation,
  useRemoveEventArchivedUpdateAccountsMutation,
  useDeleteEventImagesForAPIFailureMutation,
} from './organizeApiSlice';

import {
  selectMyOrganizedEvents,
  selectMyScheduledOrganizedEvents,
  selectMyArchivedOrganizedEvents,

  //
  setOrganizedEvent,

  //Sorted
  setSortedMyOrganizedEvents,
  setSortedMyScheduledOrganizedEvents,
  setSortedMyArchivedOrganizedEvents,
  selectSortedMyOrganizedEvents,
  selectSortedMyScheduledOrganizedEvents,
  selectSortedMyArchivedOrganizedEvents,
} from './organizeSlice';

import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { selectEditEvent } from './edit/editEventSlice';
import { selectCreateEvent } from './create/createEventSlice';

//Hooks
import useApiStatus from '../../../context/useApiStatus';
import useSanitize from '../../../hooks/useSanitize';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import useLanguageOrganize from '../../../language/features/useLanguageOrganize';
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useDraftSelectors } from './draftEventApiSlice';

//Components

//Utility
import convertBase64ToJPEG from '../../../utils/images/convertBase64ToJPEG';
import { sortEventsByDateTime } from '../../../utils/dateTime/DateCalc';
import convertBlobToJPEG from '../../../utils/images/convertBlobToJPEG';

export const useOrganizeHandlers = () => {
  //Hooks
  const dispatch = useDispatch();

  const { accountId } = useAccountReduxHandlers();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleSuccess, handleError } = useApiStatus();
  const { selectAllDraftEvents } = useDraftSelectors();

  const {
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    settingsOrganizeOverviewDirection,
  } = useSettingsHandlers();

  //API
  const [
    removeEventActiveUpdateAccount,
    { isLoading: removeEventActiveUpdateAccountisLoading },
  ] = useRemoveEventActiveUpdateAccountsMutation();

  const [
    removeEventScheduledUpdateAccount,
    { isLoading: removeEventScheduledUpdateAccountisLoading },
  ] = useRemoveEventScheduledUpdateAccountsMutation();
  const [
    removeEventArchivedUpdateAccount,
    { isLoading: removeEventArchivedUpdateAccountisLoading },
  ] = useRemoveEventArchivedUpdateAccountsMutation();

  const [removeEventActive, { isLoading: removeEventActiveisLoading }] =
    useRemoveEventActiveMutation();

  const [removeEventScheduled, { isLoading: removeEventScheduledisLoading }] =
    useRemoveEventScheduledMutation();

  const [
    removeEventImagesForAPIFailure,
    { isLoading: removeEventImagesIsLoading },
  ] = useDeleteEventImagesForAPIFailureMutation();

  //API Registrations
  const [acceptPendingApprovalInPerson] =
    useAcceptPendingApprovalInPersonMutation();
  const [removeRegistrationInPerson] = useRemoveRegistrationInPersonMutation();
  const [acceptPendingApprovalInPersonAll] =
    useAcceptPendingApprovalInPersonAllMutation();

  //API Registrations Virtual
  const [acceptPendingApprovalVirtual] =
    useAcceptPendingApprovalVirtualMutation();
  const [removeRegistrationVirtual] = useRemoveRegistrationVirtualMutation();

  //Selector Handlers
  const organizeMyOrganizedEvents = useSelector(selectMyOrganizedEvents);
  const organizeMyScheduledOrganizedEvents = useSelector(
    selectMyScheduledOrganizedEvents
  );
  const organizeMyArchivedOrganizedEvents = useSelector(
    selectMyArchivedOrganizedEvents
  );
  const organizeMyDraftEvents = useSelector(selectAllDraftEvents);

  //SORTED
  const organizeSortedMyOrganizedEvents = useSelector(
    selectSortedMyOrganizedEvents
  );
  const organizeSortedMyScheduledOrganizedEvents = useSelector(
    selectSortedMyScheduledOrganizedEvents
  );
  const organizeSortedMyArchivedOrganizedEvents = useSelector(
    selectSortedMyArchivedOrganizedEvents
  );

  //SORT FUNCTIONS
  const sortedMyOrganizedEvents = useMemo(() => {
    function sortMyOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(setSortedMyOrganizedEvents(sortedMyOrganizedEvents));
  }, [dispatch, sortedMyOrganizedEvents]);

  const sortedMyScheduledOrganizedEvents = useMemo(() => {
    function sortMyScheduledOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyScheduledOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyScheduledOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyScheduledOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(
      setSortedMyScheduledOrganizedEvents(sortedMyScheduledOrganizedEvents)
    );
  }, [dispatch, sortedMyScheduledOrganizedEvents]);

  const sortedMyArchivedOrganizedEvents = useMemo(() => {
    function sortMyArchivedOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyArchivedOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyArchivedOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyArchivedOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(
      setSortedMyArchivedOrganizedEvents(sortedMyArchivedOrganizedEvents)
    );
  }, [dispatch, sortedMyArchivedOrganizedEvents]);

  //redux handlers
  function handleOrganizeSetOrganizedEvent(obj) {
    dispatch(setOrganizedEvent(obj));
  }

  //Note: errors get caught in reduxApiSlice
  async function handleAcceptPendingApprovalInPerson(
    accountId,
    eventId,
    pendingApprovalInPersonId
  ) {
    await acceptPendingApprovalInPerson({
      accountId,
      eventId,
      pendingApprovalInPersonId,
    });
  }

  async function handleRemoveRegistrationInPerson(
    accountId,
    eventId,
    registeredAttendeeInPersonId
  ) {
    await removeRegistrationInPerson({
      accountId,
      eventId,
      registeredAttendeeInPersonId,
    });
  }

  async function handleApprovePendingApprovalInPersonAll(
    accountId,
    eventId,
    pendingApprovalInPersonAll
  ) {
    await acceptPendingApprovalInPersonAll({
      accountId,
      eventId,
      pendingApprovalInPersonAll,
    });
  }

  //Virtual
  async function handleAcceptPendingApprovalVirtual(
    accountId,
    eventId,
    pendingApprovalVirtualId
  ) {
    await acceptPendingApprovalVirtual({
      accountId,
      eventId,
      pendingApprovalVirtualId,
    });
  }

  async function handleRemoveRegistrationVirtual(
    accountId,
    eventId,
    registeredAttendeeVirtualId
  ) {
    await removeRegistrationVirtual({
      accountId,
      eventId,
      registeredAttendeeVirtualId,
    });
  }

  async function handleOrganizerEventFormData({
    handleType,
    eventData,
    participantImagesCropped,
    eventImageDisplayCropped,
    eventAdditionalImagesCropped,
    changeList,
  }) {
    const formData = new FormData();

    //handleTypes:
    // 'publish'
    // 'publishScheduled',
    // 'editPublished'
    // 'editScheduled'
    // 'updateDraft'
    // 'createDraft'

    formData.append('handleType', handleType);

    // if (eventData?.overwriteDraftDocId) {
    // formData.append('overwriteDraftDocId', eventData.overwriteDraftDocId);
    // } //used for image controller -- delete images of old doc when user is doing a doc overwrite

    //contact
    formData.append('provideContact', eventData.contact.provideContact);
    formData.append('contactEmail', eventData.contact.contactEmail);
    formData.append('contactPhoneNumber', eventData.contact.contactPhoneNumber);
    formData.append(
      'contactCountryCode',
      eventData.contact.contactCountryCode || ''
    );

    //dateTime
    if (eventData.dateTime.date) {
      formData.append('date', eventData.dateTime.date);
    }
    if (eventData.dateTime.timeStart) {
      formData.append('timeStart', eventData.dateTime.timeStart);
    }
    if (eventData.dateTime.timeEnd) {
      formData.append('timeEnd', eventData.dateTime.timeEnd);
    }
    if (eventData.dateTime.timeZone) {
      formData.append('timeZone', eventData.dateTime.timeZone);
    }

    //Generic
    if (eventData.generic.agenda?.items?.length > 0) {
      eventData.generic.agenda.items.forEach((item, index) => {
        formData.append(`agenda[${index}][id]`, item.id);
        formData.append(`agenda[${index}][text]`, item.text);
        formData.append(`agenda[${index}][value]`, item.value);
        formData.append(`agenda[${index}][startTime]`, item.startTime);
        formData.append(`agenda[${index}][endTime]`, item.endTime);
      });
      formData.append('agendaFormat', eventData.generic.agenda.format);
    }

    if (eventData.generic.accessibilityNotes) {
      formData.append(
        'accessibilityNotes',
        eventData.generic.accessibilityNotes
      );
    }
    if (eventData.generic.audienceType) {
      formData.append('audienceType', eventData.generic.audienceType);
    }

    formData.append('description', eventData.generic.description);

    if (eventData.generic.eventType) {
      formData.append('eventType', eventData.generic.eventType);
    }

    //Event Image Display
    if (eventData.generic.eventImageDisplay) {
      const eventImageDisplayStringified = JSON.stringify(
        eventData.generic.eventImageDisplay
      );
      formData.append('eventImageDisplayData', eventImageDisplayStringified);

      if (eventImageDisplayCropped?.croppedImage?.croppedImage) {
        const convertedEventImageDisplay = await convertBase64ToJPEG(
          eventImageDisplayCropped.croppedImage.croppedImage,
          `eventImageDisplay.jpg`
        );
        formData.append(`eventImageDisplay`, convertedEventImageDisplay);
      } else if (eventImageDisplayCropped?.imagePreview) {
        try {
          const convertedImage = await convertBlobToJPEG(
            eventImageDisplayCropped.imagePreview,
            `${eventImageDisplayCropped.id}.jpg`
          );
          formData.append(`eventImageDisplay`, convertedImage);
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'Dev error, handleOrganizerEventFormData, converting eventImageDisplayCropped',
              error
            );
          }
        }
      }
      //in current set up, existence of croppedImage ensures that if the same draft data is saved as a new file then it will still generate a new image, so if one of the drafts is removed, it wont remove the image for all
    }

    //Event Additional Images
    if (eventData.generic.eventAdditionalImages?.length > 0) {
      const eventAdditionalImagesStringified = JSON.stringify(
        eventData.generic.eventAdditionalImages
      );
      formData.append(
        'eventAdditionalImagesData',
        eventAdditionalImagesStringified
      );

      for (const eventAdditional of eventData.generic?.eventAdditionalImages) {
        const evAdditionalImage = eventAdditionalImagesCropped?.find(
          (img) => img?.id === eventAdditional?.id
        );

        if (evAdditionalImage?.croppedImage?.croppedImage) {
          const convertedImage = await convertBase64ToJPEG(
            evAdditionalImage?.croppedImage?.croppedImage,
            `${eventAdditional.id}.jpg`
          );
          formData.append(`eventAdditionalImages`, convertedImage);
        } else if (evAdditionalImage?.imagePreview) {
          try {
            const convertedImage = await convertBlobToJPEG(
              evAdditionalImage.imagePreview,
              `${eventAdditional.id}.jpg`
            );
            formData.append(`eventAdditionalImages`, convertedImage);
          } catch (error) {
            if (process.env.REACT_APP_ENV === 'development') {
              console.log(
                'Dev error, handleOrganizerEventFormData, converting evAdditionalImages',
                error
              );
            }
          }
        }
      }
    }

    if (eventData.generic.languages) {
      formData.append('languages', eventData.generic.languages);
    }

    formData.append(
      'wheelchairAccessibility',
      eventData.generic?.wheelchairAccessibility
    );

    formData.append(
      'signLanguageInterpreter',
      eventData.generic?.signLanguageInterpreter
    );

    formData.append('title', eventData.generic.title);

    //hosting
    if (eventData.hosting.inPerson.location.country) {
      formData.append(
        'location_country',
        eventData.hosting.inPerson.location.country
      );
    }

    if (eventData.hosting.hostingOption) {
      formData.append('hostingOption', eventData.hosting.hostingOption);
    }
    if (
      eventData.hosting.hostingOption === 'inPerson' ||
      eventData.hosting.hostingOption === 'hybrid'
    ) {
      formData.append(
        'location_room',
        eventData.hosting.inPerson.location.room
      );
      formData.append(
        'location_locationName',
        eventData.hosting.inPerson.location.locationName
      );
      formData.append(
        'location_streetAddress',
        eventData.hosting.inPerson.location.streetAddress
      );
      formData.append(
        'location_city',
        eventData.hosting.inPerson.location.city
      );
      formData.append(
        'location_postalCode',
        eventData.hosting.inPerson.location.postalCode
      );
      formData.append(
        'location_placeId',
        eventData.hosting.inPerson.location.placeId
      );
    }

    if (
      eventData.hosting.inPerson.geoPosition.coordinates[0] !== 0 &&
      eventData.hosting.inPerson.geoPosition.coordinates[1] !== 0
    ) {
      if (
        handleType === 'publish' ||
        handleType === 'publishScheduled' ||
        handleType === 'editPublished' ||
        handleType === 'editScheduled'
      ) {
        //reverse geoPosition to save to db for backend functionality
        let backendCoords = [
          eventData.hosting.inPerson.geoPosition.coordinates[1],
          eventData.hosting.inPerson.geoPosition.coordinates[0],
        ];

        formData.append('coordinates', backendCoords);
      } else {
        //keep coordinates as [lat, lng] since there is no backend functionality in draft
        formData.append(
          'coordinates',
          eventData.hosting.inPerson.geoPosition.coordinates
        );
      }
    }

    if (
      eventData.hosting.hostingOption === 'online' ||
      eventData.hosting.hostingOption === 'hybrid'
    ) {
      formData.append('virtualMeetingId', eventData.hosting.virtual.meetingId);
      formData.append('virtualPasscode', eventData.hosting.virtual?.passcode);
      formData.append('virtualPlatform', eventData.hosting.virtual?.platform);
      formData.append('virtualLink', eventData.hosting.virtual?.meetingLink);
    }

    //meta
    if (changeList) {
      formData.append('changeList', changeList);
    }

    formData.append('accountId', accountId);
    formData.append('lastModified', Date.now());

    if (handleType === 'updateDraft' || handleType === 'createDraft') {
      formData.append('saveName', eventData.meta?.saveName);
    }

    if (eventData?.meta?._id) {
      // if (eventData?.overwriteDraftDocId) {
      //   formData.append('eventId', eventData?.overwriteDraftDocId);
      // } else {
      formData.append('eventId', eventData.meta._id);
      // }
    }

    //participants
    for (const participant of eventData?.participants) {
      const participantImage = participantImagesCropped?.find(
        (img) => img?.id === participant?.id
      );
      if (participantImage?.croppedImage?.croppedImage) {
        const convertedImage = await convertBase64ToJPEG(
          participantImage.croppedImage.croppedImage,
          `${participant.id}.jpg`
        );

        formData.append(`participantImages`, convertedImage);
      } else if (participantImage?.imagePreview) {
        try {
          const convertedImage = await convertBlobToJPEG(
            participantImage.imagePreview,
            `${participantImage.id}.jpg`
          );
          formData.append(`participantImages`, convertedImage);
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'Dev error, handleOrganizerEventFormData, converting participantImages',
              error
            );
          }
        }
      }
    }

    //remove participantData.personal for inviteContacts
    let formattedParticipantsArr = [];
    //
    for (let participant of eventData?.participants) {
      if (participant?.organizedAs === 'inviteContact') {
        let newParticipant = {
          ...participant,
          participantData: {
            participantAccountId:
              participant?.participantData?.participantAccountId,
          },
        };
        formattedParticipantsArr.push(newParticipant);
      } else {
        formattedParticipantsArr.push(participant);
      }
    }

    const strigifiedParticipantsData = JSON.stringify(formattedParticipantsArr);
    formData.append('participantsData', strigifiedParticipantsData);

    //registrationData
    formData.append(
      'registrationsActive',
      eventData.registrationData.registrationsActive
    );

    formData.append(
      'maxRegistrationsInPersonEnabled',
      eventData.registrationData.maxRegistrationsInPersonEnabled
    );

    formData.append(
      'maxRegistrationsInPersonAmount',
      eventData.registrationData.maxRegistrationsInPersonAmount
    );

    formData.append(
      'maxRegistrationsVirtualEnabled',
      eventData.registrationData.maxRegistrationsVirtualEnabled
    );

    formData.append(
      'maxRegistrationsVirtualAmount',
      eventData.registrationData.maxRegistrationsVirtualAmount
    );

    formData.append(
      'privateDetails_enabled',
      eventData.registrationData.privateDetails.enabled
    );

    formData.append(
      'approveRegistrations',
      eventData.registrationData.approveRegistrations
    );
    formData.append(
      'privateDetails_room',
      eventData.registrationData.privateDetails.room
    );
    formData.append(
      'privateDetails_locationName',
      eventData.registrationData.privateDetails.locationName
    );
    formData.append(
      'privateDetails_streetAddress',
      eventData.registrationData.privateDetails.streetAddress
    );
    formData.append(
      'privateDetails_virtualMeetingID',
      eventData.registrationData.privateDetails.virtualMeetingId
    );
    formData.append(
      'privateDetails_virtualMeetingPasscode',
      eventData.registrationData.privateDetails.virtualMeetingPasscode
    );
    formData.append(
      'privateDetails_virtualMeetingLink',
      eventData.registrationData.privateDetails.virtualMeetingLink
    );
    formData.append(
      'privateDetails_translationsRoomCode',
      eventData.registrationData.privateDetails.translationsRoomCode
    );
    formData.append(
      'privateDetails_translationsRoomPassword',
      eventData.registrationData.privateDetails.translationsRoomPassword
    );
    formData.append(
      'privateDetails_contact',
      eventData.registrationData.privateDetails.contact
    );

    //schedule
    if (eventData.schedule.enabled) {
      formData.append('schedule_enabled', eventData.schedule.enabled);
      formData.append('schedule_publishDate', eventData.schedule.publishDate);
      formData.append('schedule_publishTime', eventData.schedule.publishTime);
      formData.append(
        'schedule_publishTimeZone',
        eventData.schedule.publishTimeZone
      );
    }

    //translations
    formData.append(
      'translationsEnabled',
      eventData.translations.translationsEnabled
    );

    if (eventData.translations.roomCodeId) {
      formData.append('roomCodeId', eventData.translations.roomCodeId);
    } //save error, event not found if attached without id

    formData.append(
      'joinRoomPasswordToggle',
      eventData.translations.joinRoomPasswordToggle
    );
    formData.append('translationsPassword', eventData.translations.password);

    formData.append('roomLanguages', eventData.translations.roomLanguages);

    //tags
    formData.append('bigTags', eventData.tags.bigTags);
    formData.append('tinyTags', eventData.tags.tinyTags);

    return formData;
  }

  async function handleRemoveOrganizerEvent({
    eventId,
    organizerId,
    eventType,
  }) {
    if (
      !removeEventActiveUpdateAccountisLoading ||
      !removeEventScheduledUpdateAccountisLoading ||
      !removeEventArchivedUpdateAccountisLoading ||
      !removeEventActiveisLoading ||
      !removeEventScheduledisLoading
    ) {
      const accountId = organizerId;
      const recaptchaVerified = await handleReCaptchaVerify(
        'REMOVE_EVENT',
        false
      );
      if (recaptchaVerified !== true) return false;

      try {
        if (eventType === 'active') {
          await removeEventActiveUpdateAccount({ accountId, eventId });
          await removeEventActive({ accountId, eventId });
        } else if (eventType === 'scheduled') {
          await removeEventScheduledUpdateAccount({ accountId, eventId });
          await removeEventScheduled({ accountId, eventId });
        } else if (eventType === 'archived') {
          await removeEventArchivedUpdateAccount({ accountId, eventId });
        }

        handleSuccess({
          message: 'eventRemoved',
          id: Date.now(),
          origin: 'Organize.js/handleRemoveEvent',
        });
      } catch (error) {
        handleError({
          message: 'error',
          id: Date.now(),
          origin: 'Organize.js/handleRemoveEvent',
        });
      }
    }
  }

  function handleSocketOrganizedEventData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev, Error parsing JSON:', error);
      }
    }

    const operationType = parsedData.operationType;

    // not handling delete or insert
    if (operationType) {
      if (parsedData.operationType === 'update') {
        handleOrganizeSetOrganizedEvent(parsedData.updatedEvent);
      }
    }
  }

  const handleRemoveEventImagesForAPIFailure = async (
    organizeRemoveImagesFromAPIFailure
  ) => {
    const eventImageDisplayPath =
      organizeRemoveImagesFromAPIFailure?.eventImageDisplayPath;
    const eventAdditionalImagesPaths =
      organizeRemoveImagesFromAPIFailure?.eventAdditionalImagesPaths;
    const participantImagesPaths =
      organizeRemoveImagesFromAPIFailure?.participantImagesPaths;

    if (
      accountId &&
      (eventImageDisplayPath ||
        eventAdditionalImagesPaths?.length > 0 ||
        participantImagesPaths?.length > 0)
    ) {
      try {
        if (!removeEventImagesIsLoading) {
          await removeEventImagesForAPIFailure({
            accountId,
            eventImageDisplayPath,
            eventAdditionalImagesPaths,
            participantImagesPaths,
          });
        }
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('HandleRemoveEventImagesForAPIFailure,', error);
        }
      }
    }
  };

  function handleTranslationsSpeakerDataValidation(speakerData, checkName) {
    if (checkName) {
      if (speakerData.name?.length === 0) {
        return false;
      }
    }
    if (speakerData.languageOpt === 'select' || !speakerData.languageOpt) {
      return false;
    }
    if (speakerData.voiceOpt === 'select' || !speakerData.voiceOpt) {
      return false;
    }
    console.log(
      'speakerData.discussionContext?.length',
      speakerData.discussionContext?.length
    );
    if (speakerData.discussionContext?.length < 25) {
      return false;
    }
    return true;
  }

  return {
    //Selectors
    organizeMyOrganizedEvents,
    organizeMyScheduledOrganizedEvents,
    organizeMyArchivedOrganizedEvents,
    organizeMyDraftEvents,

    //sorted
    organizeSortedMyOrganizedEvents,
    organizeSortedMyScheduledOrganizedEvents,
    organizeSortedMyArchivedOrganizedEvents,

    //redux handlers
    handleOrganizeSetOrganizedEvent,

    //API
    handleRemoveOrganizerEvent,
    handleAcceptPendingApprovalInPerson,
    handleRemoveRegistrationInPerson,
    handleApprovePendingApprovalInPersonAll,
    handleAcceptPendingApprovalVirtual,
    handleRemoveRegistrationVirtual,
    handleRemoveEventImagesForAPIFailure,

    //WS
    handleSocketOrganizedEventData,

    //Utility
    handleOrganizerEventFormData,
    handleTranslationsSpeakerDataValidation,
  };
};

export const useEventValidation = () => {
  const { CreateEvent } = useLanguageOrganize();
  const createEventData = useSelector(selectCreateEvent);
  const editEventData = useSelector(selectEditEvent);

  const { stringCheck } = useSanitize();

  let eventData;

  function handleEventValidation(saveType) {
    let validationsArrObj = [];

    if (saveType === 'create') {
      eventData = createEventData;
    } else if (saveType === 'edit') {
      eventData = editEventData;
    }

    //mandatory fields
    if (!eventData.generic.title) {
      validationsArrObj.push({
        generalLabel: `${CreateEvent.validations.eventTitle}`,
      });
    } else if (eventData.generic.title) {
      const check = stringCheck(eventData.generic.title);
      if (check) {
        validationsArrObj.push({
          generalLabel: `${CreateEvent.validations.eventTitle}`,
        });
      }
    }

    if (!eventData.generic.eventType) {
      validationsArrObj.push({
        eventTypeLabel: `${CreateEvent.validations.eventType}`,
      });
    }

    if (!eventData.generic.audienceType) {
      validationsArrObj.push({
        audienceTypeLabel: `${CreateEvent.validations.audienceType}`,
      });
    }

    if (!eventData.dateTime.date) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.date}`,
      });
    }

    if (!eventData.dateTime.timeZone) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.timeZone}`,
      });
    }
    if (!eventData.dateTime.timeStart) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.startTime}`,
      });
    }
    if (!eventData.dateTime.timeEnd) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.endTime}`,
      });
    }

    if (!eventData.hosting.hostingOption) {
      validationsArrObj.push({
        hostingOptionLabel: `${CreateEvent.validations.hostingOption}`,
      });
    }

    if (
      !eventData.generic.languages ||
      eventData.generic.languages?.length === 0 ||
      eventData.generic?.languages[0] === 'select'
    ) {
      validationsArrObj.push({
        languageLabel: `${CreateEvent.validations.language}`,
      });
    }
    if (eventData.tags.bigTags.length === 0) {
      validationsArrObj.push({
        bigTagsLabel: `${CreateEvent.validations.bigTags}`,
      });
    }
    if (eventData.tags.tinyTags.length === 0) {
      validationsArrObj.push({
        tinyTagsLabel: `${CreateEvent.validations.tinyTags}`,
      });
    }

    //conditional mandatory fields
    if (eventData.generic.agenda?.items?.length > 0) {
      let validation;

      eventData.generic.agenda.items.forEach((item) => {
        if (!item.text) {
          validation = true;
        }
      });
      if (validation) {
        validationsArrObj.push({
          agendaLabel: `${CreateEvent.validations.agendaItem}`,
        });
      }
    }

    //participants
    if (eventData?.participants?.length > 0) {
      for (const participant of eventData?.participants) {
        if (participant?.organizedAs === 'create') {
          if (participant?.completionStatus === 'incomplete') {
            validationsArrObj.push({
              participantsLabel: `${CreateEvent.validations.createdParticipants}`,
            });
          }
        }

        if (participant?.organizedAs === 'emailInvitation') {
          if (
            participant?.emailInvitation?.emailValid === false ||
            participant?.emailInvitation?.duplicateEmail === true ||
            participant?.completionStatus === 'incomplete'
          ) {
            validationsArrObj.push({
              participantsLabel: `${CreateEvent.validations.emailInvitationParticipants}`,
            });
          }
        }

        if (eventData.translations.translationsEnabled) {
          if (
            participant.translationsLanguageConflict ||
            participant.translationsRoomRequiresLanguage
          ) {
            validationsArrObj.push({
              translationRoomLanguagesLabel: `${CreateEvent.validations.translationsRoomLanguages}`,
            });
          }
        }
      }
    }

    if (eventData.hosting.hostingOption) {
      if (
        eventData.hosting.hostingOption === 'inPerson' ||
        eventData.hosting.hostingOption === 'hybrid'
      ) {
        if (!eventData.hosting.inPerson.location.streetAddress) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.streetAddress}`,
          });
        } else if (eventData.hosting.inPerson.location.streetAddress) {
          const check = stringCheck(
            eventData.hosting.inPerson.location.streetAddress
          );
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.streetAddress}`,
            });
          }
        }
        if (!eventData.hosting.inPerson.location.city) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.cityTown}`,
          });
        } else if (eventData.hosting.inPerson.location.city) {
          const check = stringCheck(eventData.hosting.inPerson.location.city);
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.cityTown}`,
            });
          }
        }
        if (!eventData.hosting.inPerson.location.country) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.country}`,
          });
        } else if (eventData.hosting.inPerson.location.country) {
          const check = stringCheck(
            eventData.hosting.inPerson.location.country
          );
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.country}`,
            });
          }
        }
      }
      if (
        eventData.hosting.hostingOption === 'online' ||
        eventData.hosting.hostingOption === 'hybrid'
      ) {
        if (!eventData.hosting.virtual.platform) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.onlinePlatform}`,
          });
        }

        if (!eventData.hosting.virtual.meetingId) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.virtualMeetingId}`,
          });
        } else if (eventData.hosting.virtual.meetingId) {
          const check = stringCheck(eventData.hosting.virtual.meetingId);
          if (check) {
            validationsArrObj.push({
              onlinePlatformLabel: `${CreateEvent.validations.virtualMeetingId}`,
            });
          }
        }

        if (!eventData.hosting.virtual.passcode) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.viritualPasscode}`,
          });
        } else if (eventData.hosting.virtual.passcode) {
          const check = stringCheck(eventData.hosting.virtual.passcode);
          if (check) {
            validationsArrObj.push({
              onlinePlatformLabel: `${CreateEvent.validations.viritualPasscode}`,
            });
          }
        }
      }

      if (eventData.hosting.hostingOption === 'online') {
        if (!eventData.hosting.inPerson.location.country) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.country}`,
          });
        }
      }
    }

    if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
      if (eventData.registrationData.maxRegistrationsInPersonAmount === 0) {
        validationsArrObj.push({
          registrationLabel: `${CreateEvent.validations.maxRegistrationsInPerson}`,
        });
      }
    }

    if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
      if (eventData.registrationData.maxRegistrationsVirtualAmount === 0) {
        validationsArrObj.push({
          registrationLabel: `${CreateEvent.validations.maxRegistrationsVirtual}`,
        });
      }
    }

    if (eventData.contact.provideContact) {
      const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const contactEmailCheckPassed = EMAIL_REGEX.test(
        eventData.contact.contactEmail
      );

      if (eventData.contact.contactEmail?.length > 0) {
        if (!contactEmailCheckPassed) {
          validationsArrObj.push({
            contactLabel: `${CreateEvent.validations.contact}`,
          });
        }
      }

      if (
        eventData.contact?.contactCountryCode ||
        eventData.contact?.contactPhoneNumber?.length > 0
      ) {
        const phoneNumberLength =
          eventData.contact?.contactPhoneNumber?.length || 0;
        const countryCode = eventData.contact?.contactCountryCode || '';

        let invalid = false;

        if (phoneNumberLength === 0 && !countryCode) {
          invalid = false;
        } else if (phoneNumberLength === 14 && countryCode) {
          invalid = false;
        } else {
          invalid = true;
        }
        if (invalid) {
          validationsArrObj.push({
            contactLabel: `${CreateEvent.validations.contact}`,
          });
        }
      }

      if (
        !contactEmailCheckPassed &&
        (!eventData.contact.contactCountryCode ||
          eventData.contact.contactPhoneNumber?.length !== 14)
      ) {
        validationsArrObj.push({
          contactLabel: `${CreateEvent.validations.contact}`,
        });
      } //if contact info provided, requires at least one valid contact field
    }

    if (eventData.translations.translationsEnabled) {
      if (!eventData.translations.roomCodeId) {
        validationsArrObj.push({
          roomCodeLabel: `${CreateEvent.validations.translationsRoomCode}`,
        });
      }
      if (
        eventData.translations.joinRoomPasswordToggle &&
        eventData.translations.password?.length === 0
      ) {
        validationsArrObj.push({
          translationsPasswordLabel: `${CreateEvent.validations.translationsPassword}`,
        });
      }

      if (eventData.translations.roomLanguages?.length === 0) {
        validationsArrObj.push({
          translationRoomLanguagesLabel: `${CreateEvent.validations.translationsRoomLanguages}`,
        });
      }
    }

    if (eventData.schedule.enabled) {
      if (
        !eventData.schedule.publishDate ||
        !eventData.schedule.publishTime ||
        !eventData.schedule.publishTimeZone
      ) {
        validationsArrObj.push({
          scheduleLabel: `${CreateEvent.validations.schedulePublication}`,
        });
      }
    }

    if (validationsArrObj.length === 0) {
      return eventData;
    } else if (validationsArrObj.length > 0) {
      return validationsArrObj;
    }
  }

  return { handleEventValidation };
};
