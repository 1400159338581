import { useEffect, useRef, useState } from 'react';

//hooks
import useLanguageData from '../../../../../language/useLanguageData';
import useApiStatus from '../../../../../context/useApiStatus';
import useSettings from '../../../../../context/useSettings';
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useRegistrationHandlers } from '../../../registration/useRegistrationHandlers';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useAddRoomCodeSubscriptonMutation } from '../../accountApiSlice';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import MinorActionBtn from '../../../../../components/MinorActionBtn/MinorActionBtn';
import AddTranslationRoomInput from '../../../../../components/AddTranslationRoomInput/AddTranslationRoomInput';
import Spinner from '../../../../../components/Spinner/Spinner';

//utils
import handleCalculateTaxesFrontEndVersion from '../../../../../utils/currency/handleCalculateTaxesFrontEndVersion';
import { priceLocaleConversion } from '../../../../../utils/currency/priceLocaleConversion';
import { image_room_key } from '../../../../../assets/images';
import {
  cancel,
  dropdown_arrow,
  img_arrow_stubby,
  img_cart,
  img_plus,
  warning,
} from '../../../../../assets/icons';

const NewRoomCodeSubscriptions = ({ handleResetPage }) => {
  const { contentLanguage } = useSettings();
  const { handleWarning, handleError } = useApiStatus();
  const { SalesTaxTitles } = useLanguageData();
  const { TranslationsNewRoomCodeSubscriptions } = useLanguageAccount();
  const { roomCodeRegistrationSearch } = useRegistrationHandlers();
  const {
    handleAccountFetchCardDetails,
    accountBillingCustomerId,
    accountId,
    accountFailedInvoices,
  } = useAccountReduxHandlers();
  const { TranslationRoomCodeRegistration, PersonalBilling } =
    useLanguageRegistration();

  //API
  const [addRoomCodeSubscripton] = useAddRoomCodeSubscriptonMutation();

  //state
  const [newRoomCodeSubscription, setNewRoomCodeSubscription] = useState([]);
  const [roomCodeInputValue, setRoomCodeInputValue] = useState();
  const [roomCodeSearchIsLoading, setRoomCodeSearchIsLoading] = useState();

  const [cardDetails, setCardDetails] = useState([]);
  const [isLoadingCardDetails, setIsLoadingCardDetails] = useState(false);
  const [hasDefaultCard, setHasDefaultCard] = useState(false);
  const [taxData, setTaxData] = useState([
    {
      taxTitle: '',
      taxRate: '',
      taxTotal: '',
    },
  ]);
  const [totalCost, setTotalCost] = useState('');
  const [subtotalCost, setSubtotalCost] = useState('');

  const [saveValid, setSaveValid] = useState(false);
  const [resetRoomCodeInput, setResetRoomCodeInput] = useState(false);

  //UI
  const [openRoomCodeUI, setOpenAddRoomCodeUI] = useState(false);
  const [
    addRoomCodeSubscriptionsIsLoading,
    setAddRoomCodeSubscriptionsIsLoading,
  ] = useState(false);

  //Variables
  const fetchCardDetailsRan = useRef(false);
  const checkDefaultRan = useRef(false);
  const roomCodePriceInCAD =
    process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD;

  useEffect(() => {
    if (cardDetails?.length > 0 && !checkDefaultRan.current) {
      checkDefaultRan.current = true;
      const accountHasDefaultCard = cardDetails.find(
        (c) => c.isDefault === true
      );

      if (accountHasDefaultCard) {
        setHasDefaultCard(true);
      }
    }
  }, [cardDetails]); //check user for mandatory default card for adding subs

  useEffect(() => {
    if (cardDetails?.length > 0) {
      cardDetails.map((c) => {
        if (c.isDefault) {
          caclulateBilling(c.billing_details, newRoomCodeSubscription);
        }
      });
    }
  }, [cardDetails, newRoomCodeSubscription]);

  useEffect(() => {
    let newSubtotal =
      process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD *
      newRoomCodeSubscription?.length;

    //Subtotal Calc
    setSubtotalCost(newSubtotal);
  }, [newRoomCodeSubscription, contentLanguage]); //calc subtotal

  async function handleOpenNewRoomCodeSubscriptions() {
    setOpenAddRoomCodeUI(!openRoomCodeUI);
    if (accountBillingCustomerId) {
      if (!fetchCardDetailsRan.current) {
        await handleAccountFetchCardDetails(
          isLoadingCardDetails,
          setIsLoadingCardDetails,
          setCardDetails
        ); //no retrywrapper for quick response.
      }
    }
  }

  async function caclulateBilling(taxCalculationAddress, roomCodes) {
    if (
      !taxCalculationAddress?.address?.city ||
      !taxCalculationAddress?.address?.country
    ) {
      return setTaxData();
    }

    try {
      const taxCalcObj = await handleCalculateTaxesFrontEndVersion(
        taxCalculationAddress,
        roomCodes
      );
      //Total Cost Calc
      setTaxData(taxCalcObj);
      setTotalCost(taxCalcObj?.newTotalCost);
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'PersonalBilling.js/caclulateBilling',
      });
      setTaxData();
    }
  }

  //Add/Remove Room Codes
  async function handleAddTranslationRoomCode(roomCodeInput) {
    //Not valid, sets unmet requirements as red with checkRan
    if (!saveValid || roomCodeInput?.length <= 3) {
      return handleWarning({
        message: 'invalidRoomCode',
        origin: 'NewRoomCodeSubscriptions.js/handleAddTranslationRoomCode',
        id: Date.now(),
      });
    }

    //make sure not already loading
    if (!roomCodeSearchIsLoading && roomCodeInput) {
      setRoomCodeSearchIsLoading(true);

      let formattedRoomCode = roomCodeInput.toUpperCase();
      //check is code is already reserved
      const roomCodeExists = await roomCodeRegistrationSearch(
        formattedRoomCode
      );
      setRoomCodeSearchIsLoading(false);

      if (roomCodeExists) {
        //code is already registered
        handleWarning({
          message: 'roomCodeRegistered',
          origin: 'NewRoomCodeSubscriptions.js/handleAddTranslationRoomCode',
          id: Date.now(),
        });
        return setResetRoomCodeInput(!resetRoomCodeInput);
      } else {
        //already included in list
        if (newRoomCodeSubscription?.includes(formattedRoomCode)) {
          setResetRoomCodeInput(!resetRoomCodeInput);
          return handleWarning({
            message: 'roomCodeAlreadyAdded',
            origin: 'NewRoomCodeSubscriptions.js/handleAddTranslationRoomCode',
            id: Date.now(),
          });
        }

        //doesnt exist
        let newRoomCodes = [];

        if (newRoomCodeSubscription?.length > 0) {
          newRoomCodes = [...newRoomCodeSubscription, formattedRoomCode];
        } else {
          newRoomCodes = [formattedRoomCode];
        }
        setNewRoomCodeSubscription(newRoomCodes);
        setResetRoomCodeInput(!resetRoomCodeInput);
      }
    }
  }

  function handleRemoveRoomCode(trCode) {
    const newRoomCodes = newRoomCodeSubscription.filter(
      (code) => code !== trCode
    );
    setNewRoomCodeSubscription(newRoomCodes);
  }

  //Add subscriptions to account
  async function handleAddRoomCodeSubscriptions() {
    if (
      !addRoomCodeSubscriptionsIsLoading &&
      newRoomCodeSubscription?.length > 0
    ) {
      setAddRoomCodeSubscriptionsIsLoading(true);

      try {
        await addRoomCodeSubscripton({
          accountId,
          newRoomCodes: newRoomCodeSubscription,
        });
        handleResetPage();
      } catch (error) {}
      setAddRoomCodeSubscriptionsIsLoading(false);
    }
  }

  return (
    <div className="section mrg-t24">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="newRoomCodeSubscriptionsLabel"
      >
        {TranslationsNewRoomCodeSubscriptions.newRoomCodeSubscriptions}
      </label>

      <p className="fs18 fwn mrg-t12 mrg-b24">
        {TranslationRoomCodeRegistration.description1}
      </p>

      <ItemButton
        handler={() => handleOpenNewRoomCodeSubscriptions()}
        fullWidth={true}
        iconOn={true}
        icon={dropdown_arrow}
        iconCustomClass={openRoomCodeUI ? 'reverse-vertical' : ''}
        customWrapper={'mrg-b24'}
      />
      {openRoomCodeUI && isLoadingCardDetails ? (
        <Spinner />
      ) : openRoomCodeUI &&
        !isLoadingCardDetails &&
        hasDefaultCard &&
        accountFailedInvoices?.length === 0 ? (
        <>
          <div className="flex-column">
            <div className="flex-row flex-column--ph">
              <ul className="tr-description-list mrg-t12 fs18">
                <li>{TranslationRoomCodeRegistration.description2}</li>
                <li>{TranslationRoomCodeRegistration.description3}</li>
                <li>{TranslationRoomCodeRegistration.description4}</li>
                <li>{TranslationRoomCodeRegistration.description5}</li>
              </ul>
              <div className="flex-column">
                <img
                  src={image_room_key}
                  alt="img"
                  className="room-key-img svg"
                  aria-hidden={true}
                />
                <MinorActionBtn
                  text={TranslationRoomCodeRegistration.learnMore}
                  customWrapper={'mrg-t12'}
                  // onClickHandler={() => {
                  //   return null;
                  // }}
                />
              </div>
            </div>
          </div>

          <AddTranslationRoomInput
            handleEnter={handleAddTranslationRoomCode}
            setRoomCode={setRoomCodeInputValue}
            roomCode={roomCodeInputValue}
            roomCodeLabelText={TranslationsNewRoomCodeSubscriptions.newRoomCode}
            roomCodeBtnText={TranslationRoomCodeRegistration.addRoomCodeToList}
            roomCodeBtnIcon={img_arrow_stubby}
            setValid={setSaveValid}
            reset={resetRoomCodeInput}
            customWrapper={'mrg-t24'}
            asterisk={true}
          />

          <div className="border-thin pad-24 br--standard full-width mrg-t48">
            <div className="account-card__wrapper flex-row space-between">
              <label
                className="content-label flex-row access-ob"
                id="newRoomCodeSubscriptionLabel"
                tabIndex="0"
              >
                {TranslationsNewRoomCodeSubscriptions.newRoomCodeSubscriptions}
              </label>
              <img
                src={img_cart}
                alt={PersonalBilling.cart}
                style={{ height: '24px' }}
                className="filter-gray"
              />
            </div>
            <ul className="flex-column list-style-none mrg-t24">
              {newRoomCodeSubscription?.map((roomCode, index) => {
                return (
                  <li
                    className="flex-row align-center full-width space-between mrg-b6"
                    key={`troom-code-${index}`}
                  >
                    <button
                      onClick={() => handleRemoveRoomCode(roomCode)}
                      className="flex-row align-center full-width space-between align-center fs18 highlight-t-theme"
                      title={TranslationsNewRoomCodeSubscriptions.remove}
                    >
                      <p>{roomCode}</p>

                      <div className="flex-row align-center">
                        <p>
                          {priceLocaleConversion(
                            roomCodePriceInCAD,
                            contentLanguage,
                            'cad',
                            true
                          )}
                        </p>

                        <img
                          src={cancel}
                          className="filter-red mrg-l12"
                          alt="X"
                          style={{ height: '16px' }}
                        />
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="full-width divider-dotted mrg-t24" />
            <div className="flex-column">
              <div className="flex-row space-between fs18 mrg-t12">
                <p>{PersonalBilling.subTotal}:</p>
                <p>
                  {priceLocaleConversion(
                    subtotalCost,
                    contentLanguage,
                    'cad',
                    true
                  )}
                </p>
              </div>

              <div className="flex-column">
                {!taxData?.newTaxCosts ? (
                  <div className="flex-row space-between fs18 mrg-t12">
                    <p>{PersonalBilling.taxes}:</p>
                    <p>{PersonalBilling.requiresAddress}</p>
                  </div>
                ) : (
                  <div className="flex-column full-width">
                    {taxData.newTaxCosts?.map((taxCostObj, index) => {
                      return (
                        <div
                          className="flex-row space-between fs18 mrg-t12"
                          key={`taxTitle-${index}`}
                        >
                          <p>
                            {SalesTaxTitles[taxCostObj?.taxTitle]}
                            &nbsp;
                          </p>
                          <p>
                            {priceLocaleConversion(
                              taxCostObj?.taxCost,
                              contentLanguage,
                              'cad',
                              true
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="flex-row space-between fs18 mrg-t12">
                  <p>{PersonalBilling.monthlySubscriptionTotal}:</p>
                  {taxData?.newTaxCosts ? (
                    <p>
                      {totalCost &&
                        priceLocaleConversion(
                          totalCost,
                          contentLanguage,
                          'cad',
                          true
                        )}
                    </p>
                  ) : (
                    <p>{PersonalBilling.requiresAddress}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ButtonMeta
            handleClick={handleAddRoomCodeSubscriptions}
            btnText={
              TranslationsNewRoomCodeSubscriptions.confirmNewSubscriptions
            }
            customClassName={`full-width mrg-tb48 mrg-auto-lr`}
            fullWidth={true}
            unavailable={newRoomCodeSubscription?.length === 0}
            availabilityToggle={true}
            isLoading={addRoomCodeSubscriptionsIsLoading}
            icon={img_plus}
            customIconStyle={{ height: '18px' }}
          />
          <div className="h48" />
        </>
      ) : openRoomCodeUI &&
        !isLoadingCardDetails &&
        !hasDefaultCard &&
        accountFailedInvoices?.length === 0 ? (
        <div className="flex-row flex-center full-width mrg-lr12">
          <img src={warning} alt="img" style={{ height: '28px' }} />
          <p className="fs18 pad-12 border-red">
            {TranslationsNewRoomCodeSubscriptions.accountPaymentMethodRequired}
          </p>
        </div>
      ) : (
        openRoomCodeUI &&
        !isLoadingCardDetails &&
        accountFailedInvoices?.length > 0 && (
          <p className="banners__notice align-center full-width fs18">
            {
              TranslationsNewRoomCodeSubscriptions.newRoomCodeSubscriptionsUnavailableWithUnpaidInvoices
            }
          </p>
        )
      )}
    </div>
  );
};

export default NewRoomCodeSubscriptions;
