import { useEffect, useState } from 'react';
import useGenericModal from '../../../../context/useGenericModal';
import useSettings from '../../../../context/useSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import { cancel, img_examine_page } from '../../../../assets/icons';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';

const TranslationSpeakerModal = ({ currentSpeaker }) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleCloseModal } = useGenericModal();
  const { handleMobileTap } = useSettings();
  const { VoiceOpts, TranslationLanguageOpts } = useLanguageData();
  const { TranslationSpeakerModal } = useLanguageTranslationRoom();

  //UI state
  const [tapHighlightCloseBottomBtn, setTapHighlightCloseBottomBtn] =
    useState(false);
  const [tapHighlightClose, setTapHighlightClose] = useState(false);

  //Initialize functions
  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  return (
    <div
      className="modal-basic__wrapper translations-modal access-ow access-o12"
      tabIndex="0"
      id="translationSpeakerModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <img
            src={img_examine_page}
            alt="img"
            style={{ height: '32px' }}
            className="mrg-r12 filter-gray"
          />
          <p className="fs18 fwsb">
            {TranslationSpeakerModal.currentSpeakerDetails}
          </p>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob  ${
            tapHighlightClose ? 'highlight-i-lgr--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightClose(true),
                () => setTapHighlightClose(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.cancel}
        >
          <img src={cancel} alt={Generic.cancel} className="svg" />
        </button>
      </div>

      <div className="modal-basic__container list-style-none" id="bigTagsList">
        <div className="flex-row align-center mrg-b12">
          <p className="fs18 fwsb">{TranslationSpeakerModal.currentSpeaker}</p>
          <p className="fs18 fwn">&nbsp;{currentSpeaker.name}</p>
        </div>

        <p className="fs18 fwsb mrg-b12">
          {TranslationLanguageOpts[currentSpeaker.languageOpt]}
        </p>
        <p className="fs18 fwsb mrg-b12">
          {VoiceOpts[currentSpeaker.voiceOpt]}
        </p>

        <p className="fs18 fwsb mrg-b6 mrg-t12">
          {TranslationSpeakerModal.discussionContext}
        </p>
        <p className="fs18 fwn mrg-b24">
          &nbsp;{currentSpeaker.discussionContext}
        </p>

        <p className="fs18 fwsb mrg-b6">
          {TranslationSpeakerModal.keywordsList} (
          {currentSpeaker.keywordsList?.length || 0}):
        </p>
        <ul className="translations-modal-keywordsList fs18 fwsb ">
          {currentSpeaker.keywordsList.map((kw, index) => {
            return (
              <li className="mrg-l24 mrg-b6 fs18 fwn" key={`kw-${index}`}>
                {kw}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="modal-basic__footer flex-center">
        <button
          className={`access-ob access-o6 highlight-it-theme ${
            tapHighlightCloseBottomBtn ? 'highlight-it-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBottomBtn(true),
                () => setTapHighlightCloseBottomBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
        >
          <p className="fs18 fwsb access-ob access-o6">{Generic.close}</p>
        </button>
      </div>
    </div>
  );
};

export default TranslationSpeakerModal;

// <button

// onClick={
//   () =>
//         handleMobileTap(
//           [
//             () => setTapHighlightComplete(true),
//             () => setTapHighlightComplete(false),
//           ],
//           [() => handleCloseModal()]
//         )
// }
// >
