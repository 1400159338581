import { useEffect, useState } from 'react';

//hooks
import { useTranslationsHandlers } from '../../features/main/translations/useTranslationsHandlers';
import useSettings from '../../context/useSettings';
import useLanguageTranslationRoom from '../../language/features/useLanguageTranslationRoom';

//components

//utils
import { img_play } from '../../assets/icons';

const ButtonPlay = ({ speaker, speakerVoiceOpt }) => {
  //hooks
  const { handleMobileTap } = useSettings();
  const { handleTestPlayBtn } = useTranslationsHandlers();
  const { CreateRoomSpeakersVoiceAndLanguage } = useLanguageTranslationRoom();

  //state
  const [testAudioIsPlaying, setTestAudioIsPlaying] = useState(false);

  //ui
  const [tapHighlightPlayBtn, setTapHighlightPlayBtn] = useState();

  //AUDIO
  useEffect(() => {
    if (testAudioIsPlaying) {
      const timer = setTimeout(() => {
        setTestAudioIsPlaying(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [testAudioIsPlaying]);

  return (
    <button
      className={`play-btn item-button-standard mrg-t24 access-ob access-o6 pad-12 highlight-bthin-theme ${
        tapHighlightPlayBtn === true ? 'highlight-b-theme--tap' : ''
      } ${
        !speakerVoiceOpt || speakerVoiceOpt === 'select'
          ? 'unavailable opacity-05'
          : ''
      }`}
      onClick={
        !speakerVoiceOpt || speakerVoiceOpt === 'select'
          ? null
          : () =>
              handleMobileTap(
                [
                  () => setTapHighlightPlayBtn(true),
                  () => setTapHighlightPlayBtn(false),
                ],
                [
                  () =>
                    handleTestPlayBtn({
                      speechLang: speaker?.translationsSpeakerData?.languageOpt,
                      voiceOption: speakerVoiceOpt,
                      testAudioIsPlaying,
                      setTestAudioIsPlaying,
                    }),
                ]
              )
      }
    >
      {!testAudioIsPlaying ? (
        <p className="fwn fs18 mrg-b3">
          {CreateRoomSpeakersVoiceAndLanguage.testVoice}
        </p>
      ) : (
        <p className="fwn fs18 mrg-b3">
          {CreateRoomSpeakersVoiceAndLanguage.playing}
        </p>
      )}
      <img
        src={img_play}
        alt="img"
        className="svg filter-gray mrg-l6"
        aria-hidden="true"
      />
    </button>
  );
};

export default ButtonPlay;
