import { useSelector, useDispatch } from 'react-redux';
import {
  //utils
  emptyAccountData,
  selectAccount,
  selectAccountAppSettings,
  selectAccountMeta,
  selectAccountBilling,
  selectAccountCommunity,
  selectAccountPersonal,
  selectAccountSpeaker,
  selectAccountAttendee,
  selectAccountAttendeeTags,
  selectId,

  //app settings
  selectContentLanguage,
  selectSoundEffects,
  selectTimeZone,
  setContentLanguage,
  setSoundEffects,
  setTimeZone,

  //account events
  selectMyEventsAll,
  selectOrganizedEvents,
  selectPendingApprovalInPerson,
  selectPendingApprovalVirtual,
  selectRegistrationsInPerson,
  selectRegistrationsVirtual,
  selectSavedEvents,
  setArchivedEvents,
  setOrganizedEvents,
  setPendingApprovalInPerson,
  setPendingApprovalVirtual,
  setRegistrationsInPerson,
  setRegistrationsVirtual,
  setSavedEvents,
  selectArchivedEvents,
  selectIgnoredEvents,
  setIgnoredEvents,

  //affiliations
  selectAffiliations,

  //attendee
  selectEventSearchOnlineCountries,
  selectDistanceFilter,
  selectDistanceMeasurement,
  selectGeoPosition,
  selectGeoPositionCoordinates,
  selectSearchLanguages,
  setDistanceFilter,
  setDistanceMeasurement,
  setEventSearchOnlineCountries,
  setSearchLanguages,
  setAttendeeModeActive,
  selectAttendeeModeActive,
  selectTinyTags,
  selectTinyTagGroups,
  setBigTags,
  selectBigTags,
  setTinyTags,
  setTinyTagGroups,

  //billing
  selectCustomerId,
  selectFailedInvoices,
  selectMyInvoices,
  selectPaymentMethodIds,
  selectRoomCodeSubscriptions,
  setCustomerId,
  setFailedInvoices,
  setMyInvoices,
  setPaymentMethodIds,
  setRoomCodeSubscriptions,

  //community
  selectCommunityFollowing,
  selectCommunityFollowingGroups,
  selectCommunityBlockedAccounts,
  selectCommunityContacts,
  selectCommunityContactsRequested,
  selectCommunityFollowers,
  setCommunityFollowing,
  setCommunityFollowingGroups,
  setCommunityBlockedAccounts,
  setCommunityContacts,
  setCommunityContactsRequested,
  setCommunityFollowers,

  //email preferences
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,
  selectEmailPreferencesMyndFullNewsletter,
  selectEmailPreferencesPromotions,

  //meta
  selectAccountReference,

  //notices
  setNotices,
  selectNotices,

  //organizer
  setAccountOrganizerCommunitySettingsDisplayContact,
  selectAccountOrganizerDisplayContact,
  setAccountOrganizerOrganizerModeActive,
  selectAccountOrganizerOrganizerModeActive,

  //personal
  selectCountryCode,
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhoneNumber,
  selectPrefix,
  selectProfileImage,
  selectPronouns,
  setCountryCode,
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setPrefix,
  setProfileImage,
  setPronouns,
  setAccountPersonalBannerImage,
  setAccountPersonalBio,
  setAccountPersonalLinkFacebook,
  setAccountPersonalLinkInstagram,
  setAccountPersonalLinkLinkedIn,
  setAccountPersonalLinkPersonalWebsite,
  setAccountPersonalLinkX,
  selectPersonalBio,
  selectPersonalBannerImage,
  selectPersonalLinkFacebook,
  selectPersonalLinkInstagram,
  selectPersonalLinkLinkedIn,
  selectPersonalLinkPersonalWebsite,
  selectPersonalLinkX,

  //speaker
  resetAccountSpeaker,
  setAccountSpeakerCommunitySettingsAttendeeFollowRequests,
  selectSpeakerCommunitySettingsAttendeeFollowRequests,
  selectSpeakerSignUpComplete,
  selectSpeakerLanguages,
  selectSpeakerGeoPosition,
  selectSpeakerGeoPositionCoordinates,
  selectSpeakerCredentials,
  selectSpeakerBigTags,
  selectSpeakerTinyTags,
  setAccountSpeakerLanguages,
  setAccountSpeakerGeoPosition,
  setAccountSpeakerCredentials,
  selectSpeakerTags,
  setAccountSpeakerBigTags,
  setAccountSpeakerTinyTags,
  setAccountSpeakerLanguagesNotListed,
  selectSpeakerLanguagesNotListed,
  setAccountSpeakerGeoPositionCoordinates,
  selectSpeakerCredentialsArtisticActivities,
  selectSpeakerCredentialsCertificatesLicenses,
  selectSpeakerCredentialsEducation,
  selectSpeakerCredentialsEmployment,
  selectSpeakerCredentialsHonorsAwards,
  selectSpeakerCredentialsJobExperience,
  selectSpeakerCredentialsPatentDesigns,
  selectSpeakerCredentialsPresentations,
  selectSpeakerCredentialsProjects,
  selectSpeakerCredentialsPublications,
  selectSpeakerCredentialsSkillsExpertise,
  selectSpeakerCredentialsVolunteerExperience,
  setAccountSpeakerCredentialsArtisticActivities,
  setAccountSpeakerCredentialsCertificatesLicenses,
  setAccountSpeakerCredentialsEducation,
  setAccountSpeakerCredentialsEmployment,
  setAccountSpeakerCredentialsHonorsAwards,
  setAccountSpeakerCredentialsJobExperience,
  setAccountSpeakerCredentialsPresentations,
  setAccountSpeakerCredentialsPatentDesigns,
  setAccountSpeakerCredentialsProjects,
  setAccountSpeakerCredentialsPublications,
  setAccountSpeakerCredentialsSkillsExpertise,
  setAccountSpeakerCredentialsVolunteerExperience,
  selectSpeakerLastModified,
  setAccountSpeakerSpeakerModeActive,
  selectSpeakerSpeakerModeActive,
  setAccountSpeakerCountryISO,
  selectSpeakerCountryISO,

  //translationRooms
  selectTranslationRoomsRoomCodes,
  setTranslationRoomsRoomCodes,
  setTranslationRoomsRoomCode,
  selectParticipantDrafts,
  selectParticipantEvents,
} from './accountSlice';
import {
  useLazyRetrieveAccountCustomerDetailsQuery,
  useUpdateCustomerMutation,
} from './accountApiSlice';
import useApiStatus from '../../../context/useApiStatus';
import useSanitize from '../../../hooks/useSanitize';
import useLanguageAccount from '../../../language/features/useLanguageAccount';

export const useAccountReduxHandlers = () => {
  //hooks
  const dispatch = useDispatch();
  const { handleError } = useApiStatus();
  const { stringCheck } = useSanitize();
  const { SpeakerSignUpValidation } = useLanguageAccount();

  //API
  const [
    updateCustomer,
    {
      isLoading: isLoadingUpdateCustomer,
      isFetching: isFetchingUpdateCustomer,
    },
  ] = useUpdateCustomerMutation();
  const [getCustomerDetails] = useLazyRetrieveAccountCustomerDetailsQuery();

  //Categories
  const accountId = useSelector(selectId);
  const account = useSelector(selectAccount);
  const accountAppSettings = useSelector(selectAccountAppSettings);
  const accountPersonal = useSelector(selectAccountPersonal);
  const accountBilling = useSelector(selectAccountBilling);
  const accountMeta = useSelector(selectAccountMeta);
  const accountSpeaker = useSelector(selectAccountSpeaker);
  const accountAttendee = useSelector(selectAccountAttendee);
  const accountAttendeeTags = useSelector(selectAccountAttendeeTags);
  const accountCommunity = useSelector(selectAccountCommunity);

  //App Settings
  const accountContentLanguage = useSelector(selectContentLanguage);
  const accountTimeZone = useSelector(selectTimeZone);
  const accountSoundEffects = useSelector(selectSoundEffects);

  ///Account Events
  const accountArchivedEvents = useSelector(selectArchivedEvents);
  const accountMyEventsAll = useSelector(selectMyEventsAll);
  const accountOrganizedEvents = useSelector(selectOrganizedEvents);
  const accountPendingApprovalInPerson = useSelector(
    selectPendingApprovalInPerson
  );
  const accountPendingApprovalVirtual = useSelector(
    selectPendingApprovalVirtual
  );
  const accountRegistrationsInPerson = useSelector(selectRegistrationsInPerson);
  const accountRegistrationsVirtual = useSelector(selectRegistrationsVirtual);
  const accountSavedEvents = useSelector(selectSavedEvents);
  const accountParticipantDrafts = useSelector(selectParticipantDrafts);
  const accountParticipantEvents = useSelector(selectParticipantEvents);

  //Affiliations
  const accountAffiliations = useSelector(selectAffiliations);

  //Attendee
  const accountAttendeeModeActive = useSelector(selectAttendeeModeActive);
  const accountGeoPosition = useSelector(selectGeoPosition);
  const accountGeoPositionCoordinates = useSelector(
    selectGeoPositionCoordinates
  );
  const accountDistanceFilter = useSelector(selectDistanceFilter);
  const accountDistanceMeasurement = useSelector(selectDistanceMeasurement);
  const accountEventSearchOnlineCountries = useSelector(
    selectEventSearchOnlineCountries
  );
  const accountSearchLanguages = useSelector(selectSearchLanguages);
  const accountBigTags = useSelector(selectBigTags);
  const accountTinyTags = useSelector(selectTinyTags);
  const accountTinyTagGroups = useSelector(selectTinyTagGroups);

  //Billing
  const accountBillingCustomerId = useSelector(selectCustomerId);
  const accountBillingPaymentMethodIds = useSelector(selectPaymentMethodIds);
  const accountBillingRoomCodeSubscriptions = useSelector(
    selectRoomCodeSubscriptions
  );
  const accountBillingMyInvoices = useSelector(selectMyInvoices);
  const accountFailedInvoices = useSelector(selectFailedInvoices);

  //Email Preferences
  const accountEmailPreferencesMyndFullNewsletter = useSelector(
    selectEmailPreferencesMyndFullNewsletter
  );
  const accountEmailPreferencesPromotions = useSelector(
    selectEmailPreferencesPromotions
  );

  //Meta
  const accountAccountReference = useSelector(selectAccountReference);
  const accountIgnoredEvents = useSelector(selectIgnoredEvents);

  //Community
  const accountCommunityFollowing = useSelector(selectCommunityFollowing);
  const accountCommunityFollowingGroups = useSelector(
    selectCommunityFollowingGroups
  );

  const accountCommunityBlockedAccounts = useSelector(
    selectCommunityBlockedAccounts
  );
  const accountCommunityContacts = useSelector(selectCommunityContacts);
  const accountCommunityContactsRequested = useSelector(
    selectCommunityContactsRequested
  );
  const accountCommunityFollowers = useSelector(selectCommunityFollowers);

  //Notices
  const accountNotices = useSelector(selectNotices);

  //Organizer
  const accountOrganizerDisplayContact = useSelector(
    selectAccountOrganizerDisplayContact
  );
  const accountOrganizerOrganizerModeActive = useSelector(
    selectAccountOrganizerOrganizerModeActive
  );

  //Personal
  const accountCountryCode = useSelector(selectCountryCode);
  const accountEmail = useSelector(selectEmail);
  const accountFirstName = useSelector(selectFirstName);
  const accountLastName = useSelector(selectLastName);
  const accountPhoneNumber = useSelector(selectPhoneNumber);
  const accountPrefix = useSelector(selectPrefix);
  const accountProfileImage = useSelector(selectProfileImage);
  const accountPronouns = useSelector(selectPronouns);

  const accountPersonalBio = useSelector(selectPersonalBio);
  const accountPersonalBannerImage = useSelector(selectPersonalBannerImage);
  const accountPersonalLinkFacebook = useSelector(selectPersonalLinkFacebook);
  const accountPersonalLinkInstagram = useSelector(selectPersonalLinkInstagram);
  const accountPersonalLinkLinkedIn = useSelector(selectPersonalLinkLinkedIn);
  const accountPersonalLinkPersonalWebsite = useSelector(
    selectPersonalLinkPersonalWebsite
  );
  const accountPersonalLinkX = useSelector(selectPersonalLinkX);

  //Speaker
  const accountSpeakerSignUpComplete = useSelector(selectSpeakerSignUpComplete);
  const accountSpeakerCommunitySettingsAttendeeFollowRequests = useSelector(
    selectSpeakerCommunitySettingsAttendeeFollowRequests
  );
  const accountSpeakerCountryISO = useSelector(selectSpeakerCountryISO);
  const accountSpeakerGeoPosition = useSelector(selectSpeakerGeoPosition);
  const accountSpeakerGeoPositionCoordinates = useSelector(
    selectSpeakerGeoPositionCoordinates
  );
  const accountSpeakerLanguages = useSelector(selectSpeakerLanguages);
  const accountSpeakerLanguagesNotListed = useSelector(
    selectSpeakerLanguagesNotListed
  );
  const accountSpeakerSpeakerModeActive = useSelector(
    selectSpeakerSpeakerModeActive
  );

  const accountSpeakerCredentials = useSelector(selectSpeakerCredentials);

  const accountSpeakerCredentialsArtisticActivities = useSelector(
    selectSpeakerCredentialsArtisticActivities
  );
  const accountSpeakerCredentialsCertificatesLicenses = useSelector(
    selectSpeakerCredentialsCertificatesLicenses
  );
  const accountSpeakerCredentialsEducation = useSelector(
    selectSpeakerCredentialsEducation
  );
  const accountSpeakerCredentialsEmployment = useSelector(
    selectSpeakerCredentialsEmployment
  );
  const accountSpeakerCredentialsHonorsAwards = useSelector(
    selectSpeakerCredentialsHonorsAwards
  );
  const accountSpeakerCredentialsJobExperience = useSelector(
    selectSpeakerCredentialsJobExperience
  );
  const accountSpeakerCredentialsPatentDesigns = useSelector(
    selectSpeakerCredentialsPatentDesigns
  );
  const accountSpeakerCredentialsPresentations = useSelector(
    selectSpeakerCredentialsPresentations
  );
  const accountSpeakerCredentialsProjects = useSelector(
    selectSpeakerCredentialsProjects
  );
  const accountSpeakerCredentialsPublications = useSelector(
    selectSpeakerCredentialsPublications
  );
  const accountSpeakerCredentialsSkillsExpertise = useSelector(
    selectSpeakerCredentialsSkillsExpertise
  );
  const accountSpeakerCredentialsVolunteerExperience = useSelector(
    selectSpeakerCredentialsVolunteerExperience
  );
  const accountSpeakerLastModified = useSelector(selectSpeakerLastModified);

  const accountSpeakerTags = useSelector(selectSpeakerTags);
  const accountSpeakerBigTags = useSelector(selectSpeakerBigTags);
  const accountSpeakerTinyTags = useSelector(selectSpeakerTinyTags);

  //Translation Rooms
  const accountTranslationRoomsRoomCodes = useSelector(
    selectTranslationRoomsRoomCodes
  );

  //Handler Functions
  //General
  function handleAccountEmptyAccountData() {
    dispatch(emptyAccountData());
  }

  //Account Events
  function handleAccountSetArchivedEvents(arr) {
    dispatch(setArchivedEvents(arr));
  }
  function handleAccountSetIgnoredEvents(arr) {
    dispatch(setIgnoredEvents(arr));
  }
  function handleAccountSetOrganizedEvents(arr) {
    dispatch(setOrganizedEvents(arr));
  }
  function handleAccountSetPendingApprovalInPerson(arr) {
    dispatch(setPendingApprovalInPerson(arr));
  }
  function handleAccountSetPendingApprovalVirtual(arr) {
    dispatch(setPendingApprovalVirtual(arr));
  }
  function handleAccountRegistrationsInPerson(arr) {
    dispatch(setRegistrationsInPerson(arr));
  }
  function handleAccountRegistrationsVirtual(arr) {
    dispatch(setRegistrationsVirtual(arr));
  }
  function handleAccountSetSavedEvents(arr) {
    dispatch(setSavedEvents(arr));
  }

  //App Settings
  function handleAccountSetContentLanguage(str) {
    dispatch(setContentLanguage(str));
  }
  function handleAccountSetSoundEffects(boolean) {
    dispatch(setSoundEffects(boolean));
  }
  function handleAccountSetTimeZone(str) {
    dispatch(setTimeZone(str));
  }

  //Attendee
  function handleAccountSetAttendeeModeActive(boolean) {
    dispatch(setAttendeeModeActive(boolean));
  }
  function handleAccountSetEventSearchOnlineCountries(arr) {
    dispatch(setEventSearchOnlineCountries(arr));
  }
  function handleAccountSetDistanceFilter(num) {
    dispatch(setDistanceFilter(num));
  }
  function handleAccountSetDistanceMeasurement(str) {
    dispatch(setDistanceMeasurement(str));
  }
  function handleAccountSetSearchLanguages(arr) {
    dispatch(setSearchLanguages(arr));
  }
  function handleAccountSetBigTags(str) {
    dispatch(setBigTags(str));
  }
  function handleAccountSetTinyTags(arr) {
    dispatch(setTinyTags(arr));
  }
  function handleAccountSetTinyTagGroups(arr) {
    dispatch(setTinyTagGroups(arr));
  }

  //Billing
  function handleAccountSetBillingCustomerId(str) {
    dispatch(setCustomerId(str));
  }
  function handleAccountSetMyInvoices(arr) {
    dispatch(setMyInvoices(arr));
  }
  function handleAccountSetBillingPaymentMethodIds(arr) {
    dispatch(setPaymentMethodIds(arr));
  }
  function handleAccountSetBillingRoomCodeSubscriptions(arr) {
    dispatch(setRoomCodeSubscriptions(arr));
  }
  function handleAccountSetFailedInvoices(arr) {
    dispatch(setFailedInvoices(arr));
  }

  //Community
  function handleAccountCommunitySetFollowing(arr) {
    dispatch(setCommunityFollowing(arr));
  }
  function handleAccountCommunitySetFollowingGroups(arr) {
    dispatch(setCommunityFollowingGroups(arr));
  }

  function handleAccountCommunitySetBlockedAccounts(arr) {
    dispatch(setCommunityBlockedAccounts(arr));
  }

  function handleAccountCommunitySetFollowers(arr) {
    dispatch(setCommunityFollowers(arr));
  }
  function handleAccountCommunitySetContacts(arr) {
    dispatch(setCommunityContacts(arr));
  }
  function handleAccountCommunitySetContactsRequested(arr) {
    dispatch(setCommunityContactsRequested(arr));
  }

  //Email Preferences
  function handleAccountSetEmailPreferencesMyndFullNewsletter(boolean) {
    dispatch(setEmailPreferencesMyndFullNewsletter(boolean));
  }
  function handleAccountSetEmailPreferencesPromotions(boolean) {
    dispatch(setEmailPreferencesPromotions(boolean));
  }

  //Meta

  //Notices
  function handleAccountSetNotices(arr) {
    dispatch(setNotices(arr));
  }

  //Organizer
  function handleAccountSetOrganizerCommunitySettingsDisplayContact(boolean) {
    dispatch(setAccountOrganizerCommunitySettingsDisplayContact(boolean));
  }
  function handleAccountOrganizerSetOrganizerModeActive(boolean) {
    dispatch(setAccountOrganizerOrganizerModeActive(boolean));
  }

  //Personal
  function handleAccountSetCountryCode(str) {
    dispatch(setCountryCode(str));
  }
  function handleAccountSetFirstName(str) {
    dispatch(setFirstName(str));
  }
  function handleAccountSetLastName(str) {
    dispatch(setLastName(str));
  }
  function handleAccountSetPrefix(str) {
    dispatch(setPrefix(str));
  }
  function handleAccountSetProfileImage(str) {
    dispatch(setProfileImage(str));
  }
  function handleAccountSetPronouns(str) {
    dispatch(setPronouns(str));
  }
  function handleAccountSetPhoneNumber(num) {
    dispatch(setPhoneNumber(num));
  }
  function handleAccountSetEmail(str) {
    dispatch(setEmail(str));
  }

  function handleAccountPersonalSetBannerImage(str) {
    dispatch(setAccountPersonalBannerImage(str));
  }
  function handleAccountPersonalSetBio(str) {
    dispatch(setAccountPersonalBio(str));
  }
  function handleAccountPersonalSetLinkFacebook(str) {
    dispatch(setAccountPersonalLinkFacebook(str));
  }
  function handleAccountPersonalSetLinkInstagram(str) {
    dispatch(setAccountPersonalLinkInstagram(str));
  }
  function handleAccountPersonalSetLinkLinkedIn(str) {
    dispatch(setAccountPersonalLinkLinkedIn(str));
  }
  function handleAccountPersonalSetLinkPersonalWebsite(str) {
    dispatch(setAccountPersonalLinkPersonalWebsite(str));
  }
  function handleAccountPersonalSetLinkX(str) {
    dispatch(setAccountPersonalLinkX(str));
  }

  //Speaker
  function handleAccountSpeakerReset() {
    dispatch(resetAccountSpeaker());
  }
  function handleAccountSpeakerCountryISO(str) {
    dispatch(setAccountSpeakerCountryISO(str));
  }
  function handleAccountSpeakerSetCommunitySettingsAttendeeFollowRequests(
    boolean
  ) {
    dispatch(setAccountSpeakerCommunitySettingsAttendeeFollowRequests(boolean));
  }
  function handleAccountSpeakerSetGeoPosition(obj) {
    dispatch(setAccountSpeakerGeoPosition(obj));
  }
  function handleAccountSpeakerSetGeoPositionCoordinates(arr) {
    dispatch(setAccountSpeakerGeoPositionCoordinates(arr));
  }
  function handleAccountSpeakerSetLanguages(arr) {
    dispatch(setAccountSpeakerLanguages(arr));
  }
  function handleAccountSpeakerSetLanguagesNotListed(arr) {
    dispatch(setAccountSpeakerLanguagesNotListed(arr));
  }
  function handleAccountSpeakerSetSpeakerModeActive(boolean) {
    dispatch(setAccountSpeakerSpeakerModeActive(boolean));
  }

  function handleAccountSpeakerSetCredentials(obj) {
    dispatch(setAccountSpeakerCredentials(obj));
  }
  function handleAccountSpeakerSetCredentialsArtisticActivities(str) {
    dispatch(setAccountSpeakerCredentialsArtisticActivities(str));
  }
  function handleAccountSpeakerSetCredentialsCertificatesLicenses(str) {
    dispatch(setAccountSpeakerCredentialsCertificatesLicenses(str));
  }
  function handleAccountSpeakerSetCredentialsEducation(str) {
    dispatch(setAccountSpeakerCredentialsEducation(str));
  }
  function handleAccountSpeakerSetCredentialsEmployment(str) {
    dispatch(setAccountSpeakerCredentialsEmployment(str));
  }
  function handleAccountSpeakerSetCredentialsHonorsAwards(str) {
    dispatch(setAccountSpeakerCredentialsHonorsAwards(str));
  }
  function handleAccountSpeakerSetCredentialsJobExperience(str) {
    dispatch(setAccountSpeakerCredentialsJobExperience(str));
  }
  function handleAccountSpeakerSetCredentialsPresentations(str) {
    dispatch(setAccountSpeakerCredentialsPresentations(str));
  }
  function handleAccountSpeakerSetCredentialsPatentDesigns(str) {
    dispatch(setAccountSpeakerCredentialsPatentDesigns(str));
  }
  function handleAccountSpeakerSetCredentialsProjects(str) {
    dispatch(setAccountSpeakerCredentialsProjects(str));
  }
  function handleAccountSpeakerSetCredentialsPublications(str) {
    dispatch(setAccountSpeakerCredentialsPublications(str));
  }
  function handleAccountSpeakerSetCredentialsSkillsExpertise(str) {
    dispatch(setAccountSpeakerCredentialsSkillsExpertise(str));
  }
  function handleAccountSpeakerSetCredentialsVolunteerExperience(str) {
    dispatch(setAccountSpeakerCredentialsVolunteerExperience(str));
  }

  function handleAccountSpeakerSetBigTags(arr) {
    dispatch(setAccountSpeakerBigTags(arr));
  }
  function handleAccountSpeakerSetTinyTags(arr) {
    dispatch(setAccountSpeakerTinyTags(arr));
  }

  //TranslationRooms
  function handleAccountSetTranslationRoomsRoomCodes(arr) {
    dispatch(setTranslationRoomsRoomCodes(arr));
  }

  function handleAccountSetTranslationRoomsRoomCode(obj) {
    dispatch(setTranslationRoomsRoomCode(obj));
  }

  async function handleUpdateCustomerStripeData(newData, updateCategory) {
    if (
      !accountBillingCustomerId ||
      isLoadingUpdateCustomer ||
      isFetchingUpdateCustomer
    )
      return;

    if (updateCategory === 'name') {
      const oldName = `${accountFirstName} ${accountLastName}`;
      const newName = `${newData?.personal?.firstName} ${newData?.personal?.lastName}`;

      if (oldName !== newName) {
        const { error } = await updateCustomer({ accountId, newName });
        if (error) return error;
      }
    }

    if (updateCategory === 'email') {
      const oldEmail = accountEmail;
      const newEmail = newData?.email;

      if (oldEmail !== newEmail) {
        const { error } = await updateCustomer({ accountId, newEmail });
        if (error) return error;
      }
    }

    if (updateCategory === 'phone') {
      const oldPhone = accountPhoneNumber;
      const newPhone = newData?.personal?.phoneNumber;

      if (oldPhone !== newPhone) {
        const { error } = await updateCustomer({
          accountId,
          newPhone,
        });
        if (error) return error;
      }
    }

    if (updateCategory === 'appSettings') {
      const oldContentLanguage = accountContentLanguage;
      const newContentLanguage = newData?.appSettings?.contentLanguage;

      if (oldContentLanguage !== newContentLanguage) {
        const { error } = await updateCustomer({
          accountId,
          newContentLanguage,
        });
        if (error) return error;
      }
    }
  }

  const handleAccountFetchCardDetails = async (
    isLoadingCardDetails,
    setIsLoadingCardDetails,
    setCardDetails
  ) => {
    if (!isLoadingCardDetails) {
      setIsLoadingCardDetails(true);
      try {
        const receivedCardDetails = await getCustomerDetails({
          accountId,
        });

        const cardDetailsArr = receivedCardDetails?.data?.paymentMethodData;

        if (cardDetailsArr?.length > 0) {
          const primaryCardSetToFirstInArray = [...cardDetailsArr]?.sort(
            (a, b) => {
              if (a.isDefault) return 1;
              if (b.isDefault) return -1;
              return 0;
            }
          );
          setCardDetails(primaryCardSetToFirstInArray);
        }
      } catch (error) {
        handleError(error);
      }
      setIsLoadingCardDetails(false);
    }
  };

  const speakerSignUpValidation = () => {
    let validationsArrObj = [];

    if (accountSpeakerLanguages?.length === 0) {
      validationsArrObj.push({
        speakerLanguagesLabel: `${SpeakerSignUpValidation.speakerLanguages}`,
      });
    }

    if (accountSpeakerLanguagesNotListed?.length > 0) {
      for (const notListedLang of accountSpeakerLanguagesNotListed) {
        const typeNotString = stringCheck(notListedLang.language);
        if (typeNotString) {
          validationsArrObj.push({
            speakerLanguagesLabel: `${SpeakerSignUpValidation.speakerLanguages}`,
          });
        }
      }
    }

    if (
      !accountSpeakerGeoPositionCoordinates ||
      !accountSpeakerGeoPositionCoordinates[0] ||
      !accountSpeakerGeoPositionCoordinates[1] ||
      accountSpeakerGeoPositionCoordinates[0] === 0 ||
      accountSpeakerGeoPositionCoordinates[1] === 0
    ) {
      validationsArrObj.push({
        speakerLocationLabel: `${SpeakerSignUpValidation.speakerLocation}`,
      });
    }

    if (accountSpeakerBigTags?.length === 0) {
      validationsArrObj.push({
        speakerTagsLabel: `${SpeakerSignUpValidation.bigTags}`,
      });
    }

    if (accountSpeakerTinyTags?.length === 0) {
      validationsArrObj.push({
        speakerTagsLabel: `${SpeakerSignUpValidation.tinyTags}`,
      });
    }

    if (validationsArrObj.length === 0) {
      return accountSpeaker;
    } else if (validationsArrObj.length > 0) {
      return validationsArrObj;
    }
  };

  return {
    //utils
    account,
    accountAppSettings,
    accountId,
    accountMeta,
    accountCommunity,
    accountPersonal,
    accountSpeaker,
    accountAttendeeTags,
    accountBilling,
    handleAccountEmptyAccountData,
    speakerSignUpValidation,

    //account events
    accountArchivedEvents,
    accountIgnoredEvents,
    accountMyEventsAll,
    accountOrganizedEvents,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountSavedEvents,
    accountParticipantDrafts,
    accountParticipantEvents,
    handleAccountRegistrationsInPerson,
    handleAccountRegistrationsVirtual,
    handleAccountSetArchivedEvents,
    handleAccountSetIgnoredEvents,
    handleAccountSetOrganizedEvents,
    handleAccountSetPendingApprovalInPerson,
    handleAccountSetPendingApprovalVirtual,
    handleAccountSetSavedEvents,

    //app settings
    accountContentLanguage,
    accountSoundEffects,
    accountTimeZone,
    handleAccountSetContentLanguage,
    handleAccountSetSoundEffects,
    handleAccountSetTimeZone,

    //affiliations
    accountAffiliations,

    //attendee
    accountAttendeeModeActive,
    handleAccountSetAttendeeModeActive,
    accountDistanceFilter,
    accountDistanceMeasurement,
    accountEventSearchOnlineCountries,
    accountGeoPosition,
    accountGeoPositionCoordinates,
    accountAttendee,
    accountSearchLanguages,
    handleAccountSetDistanceFilter,
    handleAccountSetDistanceMeasurement,
    handleAccountSetEventSearchOnlineCountries,
    handleAccountSetSearchLanguages,
    accountBigTags,
    accountTinyTagGroups,
    accountTinyTags,
    handleAccountSetBigTags,
    handleAccountSetTinyTagGroups,
    handleAccountSetTinyTags,

    //biiling
    accountBillingCustomerId,
    accountBillingPaymentMethodIds,
    accountBillingRoomCodeSubscriptions,
    handleAccountSetBillingCustomerId,
    handleAccountSetBillingPaymentMethodIds,
    handleAccountSetBillingRoomCodeSubscriptions,
    handleUpdateCustomerStripeData,
    handleAccountFetchCardDetails,
    accountBillingMyInvoices,
    handleAccountSetMyInvoices,
    accountFailedInvoices,
    handleAccountSetFailedInvoices,

    //community
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
    handleAccountCommunitySetFollowing,
    handleAccountCommunitySetFollowingGroups,
    accountCommunityBlockedAccounts,
    handleAccountCommunitySetBlockedAccounts,

    //new additions
    accountCommunityContacts,
    accountCommunityContactsRequested,
    accountCommunityFollowers,
    handleAccountCommunitySetContacts,
    handleAccountCommunitySetContactsRequested,
    handleAccountCommunitySetFollowers,

    //email preferences
    accountEmailPreferencesMyndFullNewsletter,
    accountEmailPreferencesPromotions,
    handleAccountSetEmailPreferencesMyndFullNewsletter,
    handleAccountSetEmailPreferencesPromotions,

    //meta
    accountAccountReference,

    //notices
    accountNotices,
    handleAccountSetNotices,

    //organizer
    accountOrganizerDisplayContact,
    handleAccountSetOrganizerCommunitySettingsDisplayContact,
    accountOrganizerOrganizerModeActive,
    handleAccountOrganizerSetOrganizerModeActive,

    //personal
    accountCountryCode,
    accountEmail,
    accountFirstName,
    accountLastName,
    accountPhoneNumber,
    accountPrefix,
    accountProfileImage,
    accountPronouns,
    accountPersonalBio,
    accountPersonalBannerImage,
    accountPersonalLinkFacebook,
    accountPersonalLinkInstagram,
    accountPersonalLinkLinkedIn,
    accountPersonalLinkPersonalWebsite,
    accountPersonalLinkX,
    handleAccountSetCountryCode,
    handleAccountSetEmail,
    handleAccountSetFirstName,
    handleAccountSetLastName,
    handleAccountSetPhoneNumber,
    handleAccountSetPrefix,
    handleAccountSetProfileImage,
    handleAccountSetPronouns,
    handleAccountPersonalSetBannerImage,
    handleAccountPersonalSetBio,
    handleAccountPersonalSetLinkFacebook,
    handleAccountPersonalSetLinkInstagram,
    handleAccountPersonalSetLinkLinkedIn,
    handleAccountPersonalSetLinkPersonalWebsite,
    handleAccountPersonalSetLinkX,

    //speaker
    accountSpeakerCountryISO,
    handleAccountSpeakerCountryISO,
    accountSpeakerBigTags,
    accountSpeakerCommunitySettingsAttendeeFollowRequests,
    accountSpeakerCredentials,
    accountSpeakerCredentialsArtisticActivities,
    accountSpeakerCredentialsCertificatesLicenses,
    accountSpeakerCredentialsEducation,
    accountSpeakerCredentialsEmployment,
    accountSpeakerCredentialsHonorsAwards,
    accountSpeakerCredentialsJobExperience,
    accountSpeakerCredentialsPatentDesigns,
    accountSpeakerCredentialsPresentations,
    accountSpeakerCredentialsProjects,
    accountSpeakerCredentialsPublications,
    accountSpeakerCredentialsSkillsExpertise,
    accountSpeakerCredentialsVolunteerExperience,
    accountSpeakerGeoPosition,
    accountSpeakerGeoPositionCoordinates,
    accountSpeakerLanguages,
    accountSpeakerLanguagesNotListed,
    accountSpeakerLastModified,
    accountSpeakerSignUpComplete,
    accountSpeakerTags,
    accountSpeakerTinyTags,
    handleAccountSpeakerReset,
    handleAccountSpeakerSetBigTags,
    accountSpeakerSpeakerModeActive,
    handleAccountSpeakerSetSpeakerModeActive,

    handleAccountSpeakerSetCommunitySettingsAttendeeFollowRequests,
    handleAccountSpeakerSetCredentials,
    handleAccountSpeakerSetCredentialsArtisticActivities,
    handleAccountSpeakerSetCredentialsCertificatesLicenses,
    handleAccountSpeakerSetCredentialsEducation,
    handleAccountSpeakerSetCredentialsEmployment,
    handleAccountSpeakerSetCredentialsHonorsAwards,
    handleAccountSpeakerSetCredentialsJobExperience,
    handleAccountSpeakerSetCredentialsPatentDesigns,
    handleAccountSpeakerSetCredentialsPresentations,
    handleAccountSpeakerSetCredentialsProjects,
    handleAccountSpeakerSetCredentialsPublications,
    handleAccountSpeakerSetCredentialsSkillsExpertise,
    handleAccountSpeakerSetCredentialsVolunteerExperience,
    handleAccountSpeakerSetGeoPosition,
    handleAccountSpeakerSetGeoPositionCoordinates,
    handleAccountSpeakerSetLanguages,
    handleAccountSpeakerSetLanguagesNotListed,
    handleAccountSpeakerSetTinyTags,

    //translationRooms
    accountTranslationRoomsRoomCodes,
    handleAccountSetTranslationRoomsRoomCode,
    handleAccountSetTranslationRoomsRoomCodes,
  };
};
