//hooks
import { useRemoveAllNoticesMutation } from '../noticesApiSlice';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//components
import Spinner from '../../../../components/Spinner/Spinner';
import ButtonTextSimple from '../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils

const NoticeSettings = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();

  //api
  const [removeAllNotices, { isLoading: removeAllNoticesIsLoading }] =
    useRemoveAllNoticesMutation();

  //state

  return (
    <li>
      <div className="noticeOverlay-action-btns__container mrg-tb24">
        {removeAllNoticesIsLoading ? (
          <Spinner
            minHeight={'44px'}
            maxHeight={'44px'}
            customClassName={' mrg-t12'}
          />
        ) : (
          <ButtonTextSimple
            text={'Clear Archive'}
            handler={() => {
              if (!removeAllNoticesIsLoading) {
                removeAllNotices({
                  accountId,
                });
              }
            }}
            iconRight={true}
            iconClass={'mrg-l12 filter-gray'}
            customClassText={'color-gray'}
            customWrapper={
              'noticeOverlay-action-btn mrg-t12 min-full-width flex-center'
            }
          />
        )}
      </div>
    </li>
  );
};

export default NoticeSettings;
