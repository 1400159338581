import { useEffect, useRef, useState } from 'react';

//Hooks
import { useEditEventReduxHandlers } from './useEditEventHandlers';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import General from '../eventSections/General';
import Access from '../eventSections/Access';
import Registration from '../eventSections/Registration';
import Images from '../eventSections/Images';
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import Schedule from '../eventSections/Schedule';
import Spinner from '../../../../components/Spinner/Spinner';
import EventTags from '../eventSections/EventTags';
import Agenda from '../eventSections/Agenda';
import Contact from '../eventSections/Contact';
import EventParticipants from '../eventSections/EventParticipants';
import EventTranslations from '../eventSections/EventTranslations';
import Dispatches from '../eventSections/Dispatches';
import EditOverview from '../eventSections/EditOverview';

//Utility

const EditEvent = ({
  eventData,
  setEditEvent,
  handleRemoveEvent,
  setEventPreview,
  eventPreview,
  handleSubmitEvent,
  removedParticipantsWithDispatchedInvites,
  setRemovedParticipantsWithDispatchedInvites,

  //loading state
  organizeIsLoading,
  setOrganizeIsLoading,
  submitIsLoading,

  //cropped imgs
  participantImagesCropped,
  setParticipantImagesCropped,
  eventImageDisplayCropped,
  setEventImageDisplayCropped,
  eventAdditionalImagesCropped,
  setEventAdditionalImagesCropped,
}) => {
  //Hooks
  const {
    //handlers
    handleEditEventReset,
    handleEditEventSetEvent,
    handleEditEventChangeList,

    //Contact
    handleEditEventSetProvideContact,
    handleEditEventSetContactEmail,
    handleEditEventSetContactPhoneNumber,
    handleEditEventSetContactCountryCode,

    //DateTime
    handleEditEventSetDate,
    handleEditEventSetTimeZone,
    handleEditEventSetTimeStart,
    handleEditEventSetTimeEnd,

    //Generic
    handleEditEventSetTitle,
    handleEditEventSetDescription,
    handleEditEventSetEventType,
    handleEditEventSetAudienceType,
    handleEditEventSetAgendaFormat,
    handleEditEventSetAgendaItems,
    handleEditEventSetLanguages,
    handleEditEventSetEventImageDisplay,
    handleEditEventSetEventAdditionalImages,

    //Access
    handleReverseEditEventHostingGeoPositionCoordinates,
    handleEditEventClearLocation,
    handleEditEventSetCoordinates,
    handleEditEventSetHostingOption,
    handleEditEventSetLocation,
    handleEditEventSetAccessibilityNotes,
    handleEditEventSetSignLanguageInterpreter,
    handleEditEventSetWheelchairAccessibility,

    //Schedule
    handleEditEventSetSchedulePublishDate,
    handleEditEventSetSchedulePublishTime,
    handleEditEventSetSchedulePublishTimeZone,
    handleEditEventSetScheduleEnabled,

    //Participants
    handleEditEventSetParticipants,

    //Hosting
    handleEditEventSetVirtualMeetingId,
    handleEditEventSetVirtualPasscode,
    handleEditEventSetVirtualPlatform,
    handleEditEventSetVirtualMeetingLink,

    //Registrations
    handleEditEventSetApproveRegistrations,
    handleEditEventSetMaxRegistrationsInPersonEnabled,
    handleEditEventSetMaxRegistrationsVirtualEnabled,
    handleEditEventSetEnablePrivateDetails,
    handleEditEventSetMaxRegistrationsInPersonAmount,
    handleEditEventSetMaxRegistrationsVirtualAmount,
    handleEditEventSetPrivateDetails,
    handleEditEventSetRegistrations,

    //Tags
    handleEditEventSetBigTags,
    handleEditEventSetTinyTags,

    //Translations
    handleEditEventSetTranslationsTranslationsEnabled,
    handleEditEventSetTranslationsRoomCodeId,
    handleEditEventSetTranslationsJoinRoomPasswordToggle,
    handleEditEventSetTranslationsPassword,
    handleEditEventSetTranslationsRoomLanguages,

    //edit
    lastModified,
    editEventData: editEventDataSlice,
    editEventChangeList: changeList,

    //Contact
    contactProvideContact: loadContactProvideContact,
    contactContactEmail: loadContactEmail,
    contactContactPhoneNumber: loadContactPhoneNumber,
    contactContactCountryCode: loadContactCountryCode,

    //Generic
    title: loadTitle,
    description: loadDescription,
    eventType: loadEventType,
    audienceType: loadAudienceType,
    date: loadDate,
    languages: loadLanguages,
    timeZone: loadTimeZone,
    timeStart: loadTimeStart,
    timeEnd: loadTimeEnd,
    agendaItems: loadAgendaItems,
    agendaFormat: loadAgendaFormat,
    signLanguageInterpreter: loadSignLanguageInterpreter,
    wheelchairAccessibility: loadWheelchairAccessibility,
    accessibilityNotes: loadAccessibilityNotes,
    eventImageDisplay: loadEventImageDisplay,
    eventAdditionalImages: loadEventAdditionalImages,

    //meta
    editEventId: loadEditEventId,

    //Hosting
    hostingOption: loadHostingOption,
    coordinates: loadCoordinates,
    virtualMeetingId: loadVirtualMeetingId,
    virtualPasscode: loadVirtualPasscode,
    virtualPlatform: loadVirtualPlatform,
    virtualLink: loadVirtualLink,
    location: loadLocation,

    //Participants
    editEventParticipants: loadEventParticipants,

    //Registrations
    approveRegistrations: loadApproveRegistrations,
    maxRegistrationsInPersonEnabled: loadMaxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled: loadMaxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount: loadMaxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount: loadMaxRegistrationsVirtualAmount,
    registrationsActive: loadRegistrationsActive,
    privateDetails: loadPrivateDetails,
    enablePrivateDetails: loadEnablePrivateDetails,

    //Schedule
    scheduleEnabled: loadScheduleEnabled,
    schedulePublishDate: loadSchedulePublishDate,
    schedulePublishTime: loadSchedulePublishTime,
    schedulePublishTimeZone: loadSchedulePublishTimeZone,

    //Tags
    bigTags: loadBigTags,
    tinyTags: loadTinyTags,

    //translations
    editEventTranslationsTranslationsEnabled:
      loadTranslationsTranslationsEnabled,
    editEventTranslationsRoomCodeId: loadTranslationsRoomCodeId,
    editEventTranslationsJoinRoomPasswordToggle:
      loadTranslationsJoinRoomPasswordToggle,
    editEventTranslationsPassword: loadTranslationsPassword,
    editEventTranslationsRoomLanguages: loadTranslationsRoomLanguages,
  } = useEditEventReduxHandlers();
  const { CreateEvent } = useLanguageOrganize();

  //Component state
  const [initialize, setInitialize] = useState(false);
  const [reset, setReset] = useState(false);
  const [pauseChangeList, setPauseChangeList] = useState(true);

  //change tracking state
  const [eventImageDisplayChangeTracker, setEventImageDisplayChangeTracker] =
    useState(false);
  const [eventAdditionalChangeTracker, setEventAdditionalImagesChangeTracker] =
    useState(false);
  const [participantChangeTracker, setParticipantChangeTracker] =
    useState(false);
  const [participantImagesChangeTracker, setParticipantImagesChangeTracker] =
    useState(false);

  //variables
  const effectRan = useRef(false);

  //Initialize Functions
  useEffect(() => {
    if (eventData && !initialize) {
      if (effectRan.current === false) {
        handleResetEditEvent();
        setInitialize(true);
        setOrganizeIsLoading(true);
        effectRan.current = true;
      }
    }
  }, [eventData]);

  useEffect(() => {
    if (organizeIsLoading && !submitIsLoading) {
      setEventImageDisplayCropped(null);
      setParticipantImagesCropped([]);
      setEventAdditionalImagesCropped([]);
      setParticipantImagesChangeTracker(false);

      let timeout1;

      timeout1 = setTimeout(() => {
        setReset(false);
        setPauseChangeList(false);
        setInitialize(true);
        setOrganizeIsLoading(false);
      }, 500);

      return () => {
        if (timeout1) {
          clearTimeout(timeout1);
        }
      };
    }
  }, [reset, organizeIsLoading, submitIsLoading]); //helps reset components

  //Component functions
  function handleResetEditEvent() {
    setPauseChangeList(true);
    handleEditEventReset();
    handleEditEventSetEvent(eventData);
    handleReverseEditEventHostingGeoPositionCoordinates();
    handleEditEventChangeList([]);

    //reset trackers
    setEventImageDisplayChangeTracker(false);
    setEventAdditionalImagesChangeTracker(false);
    setParticipantChangeTracker(false);
    setParticipantImagesChangeTracker(false);

    setPauseChangeList(false);
    setReset(true);
  } //before in api call this was before setEditEvent in hte handle submit, now it is after.

  return (
    <>
      <div className={`organize ${eventPreview ? 'remove' : ''}`}>
        <div className="create-event__wrapper">
          <div className="create-event">
            <div className="h24" />

            <EditOverview
              setEditEvent={setEditEvent}
              editEventData={editEventDataSlice}
              setEventPreview={setEventPreview}
              initialize={initialize}
              setOrganizeIsLoading={setOrganizeIsLoading}
              //change list
              changeList={changeList}
              pauseChangeList={pauseChangeList}
              setPauseChangeList={setPauseChangeList}
              handleEditEventChangeList={handleEditEventChangeList}
              //tracker state
              eventImageDisplayChangeTracker={eventImageDisplayChangeTracker}
              eventAdditionalChangeTracker={eventAdditionalChangeTracker}
              participantChangeTracker={participantChangeTracker}
              participantImagesChangeTracker={participantImagesChangeTracker}
              //actions
              handleRemoveEvent={handleRemoveEvent}
              handleUpdateEvent={handleSubmitEvent}
              handleEditEventReset={handleEditEventReset}
              handleReset={handleResetEditEvent}
              reset={reset}
              setReset={setReset}
              //meta
              eventId={eventData._id}
              lastModified={lastModified}
              //generic
              loadTitle={loadTitle}
              loadDescription={loadDescription}
              loadEventType={loadEventType}
              loadAudienceType={loadAudienceType}
              loadDate={loadDate}
              loadLanguages={loadLanguages}
              loadTimeZone={loadTimeZone}
              loadTimeStart={loadTimeStart}
              loadTimeEnd={loadTimeEnd}
              loadAgendaItems={loadAgendaItems}
              loadSignLanguageInterpreter={loadSignLanguageInterpreter}
              loadWheelchairAccessibility={loadWheelchairAccessibility}
              loadAccessibilityNotes={loadAccessibilityNotes}
              //hosting
              loadHostingOption={loadHostingOption}
              loadCoordinates={loadCoordinates}
              loadVirtualMeetingId={loadVirtualMeetingId}
              loadVirtualPasscode={loadVirtualPasscode}
              loadVirtualPlatform={loadVirtualPlatform}
              loadVirtualLink={loadVirtualLink}
              loadLocation={loadLocation}
              //participants
              loadParticipants={loadEventParticipants}
              //registrations
              loadApproveRegistrations={loadApproveRegistrations}
              loadMaxRegistrationsInPersonEnabled={
                loadMaxRegistrationsInPersonEnabled
              }
              loadMaxRegistrationsVirtualEnabled={
                loadMaxRegistrationsVirtualEnabled
              }
              loadMaxRegistrationsInPersonAmount={
                loadMaxRegistrationsInPersonAmount
              }
              loadMaxRegistrationsVirtualAmount={
                loadMaxRegistrationsVirtualAmount
              }
              loadRegistrationsActive={loadRegistrationsActive}
              loadPrivateDetails={loadPrivateDetails}
              //schedule
              loadSchedulePublishDate={loadSchedulePublishDate}
              loadSchedulePublishTime={loadSchedulePublishTime}
              loadSchedulePublishTimeZone={loadSchedulePublishTimeZone}
              //contact
              loadContactProvideContact={loadContactProvideContact}
              loadContactEmail={loadContactEmail}
              loadContactPhoneNumber={loadContactPhoneNumber}
              loadContactCountryCode={loadContactCountryCode}
              //tags
              loadBigTags={loadBigTags}
              loadTinyTags={loadTinyTags}
              //translations
              loadTranslationsEnabled={loadTranslationsTranslationsEnabled}
              loadTranslationsRoomCodeId={loadTranslationsRoomCodeId}
              loadTranslationsJoinRoomPasswordToggle={
                loadTranslationsJoinRoomPasswordToggle
              }
              loadTranslationsPassword={loadTranslationsPassword}
              loadTranslationsRoomLanguages={loadTranslationsRoomLanguages}
            />

            {initialize & !organizeIsLoading ? (
              <>
                {eventData?.schedule?.enabled && (
                  <Schedule
                    initialize={initialize}
                    editEventMode={true}
                    handleSetSchedulePublishDate={
                      handleEditEventSetSchedulePublishDate
                    }
                    handleSetSchedulePublishTime={
                      handleEditEventSetSchedulePublishTime
                    }
                    handleSetSchedulePublishTimeZone={
                      handleEditEventSetSchedulePublishTimeZone
                    }
                    handleSetScheduleEnabled={handleEditEventSetScheduleEnabled}
                    loadSchedulePublishDate={loadSchedulePublishDate}
                    loadSchedulePublishTime={loadSchedulePublishTime}
                    loadSchedulePublishTimeZone={loadSchedulePublishTimeZone}
                    loadScheduleEnabled={loadScheduleEnabled}
                  />
                )}
                <Dispatches
                  skipToId={'generalLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToGeneral}
                  CreateEvent={CreateEvent}
                  //state
                  participants={loadEventParticipants}
                  translationsEnabled={loadTranslationsTranslationsEnabled}
                  //handlers
                  handleSetParticipants={handleEditEventSetParticipants}
                />
                <General
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  skipToId={'contactLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToContact}
                  //state
                  loadTitle={loadTitle}
                  loadDescription={loadDescription}
                  loadEventType={loadEventType}
                  loadAudienceType={loadAudienceType}
                  loadDate={loadDate}
                  loadLanguages={loadLanguages}
                  loadTimeZone={loadTimeZone}
                  loadTimeStart={loadTimeStart}
                  loadTimeEnd={loadTimeEnd}
                  //handlers
                  handleSetTitle={handleEditEventSetTitle}
                  handleSetDescription={handleEditEventSetDescription}
                  handleSetEventType={handleEditEventSetEventType}
                  handleSetAudienceType={handleEditEventSetAudienceType}
                  handleSetDate={handleEditEventSetDate}
                  handleSetTimeZone={handleEditEventSetTimeZone}
                  handleSetTimeStart={handleEditEventSetTimeStart}
                  handleSetTimeEnd={handleEditEventSetTimeEnd}
                  handleSetLanguages={handleEditEventSetLanguages}
                />

                <Contact
                  initialize={initialize}
                  skipToId={'participantsLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToParticipants}
                  CreateEvent={CreateEvent}
                  //state
                  loadContactProvideContact={loadContactProvideContact}
                  loadContactEmail={loadContactEmail}
                  loadContactPhoneNumber={loadContactPhoneNumber}
                  loadContactCountryCode={loadContactCountryCode}
                  //handlers
                  handleEventSetProvideContact={
                    handleEditEventSetProvideContact
                  }
                  handleEventSetContactEmail={handleEditEventSetContactEmail}
                  handleEventSetContactPhoneNumber={
                    handleEditEventSetContactPhoneNumber
                  }
                  handleEventSetContactCountryCode={
                    handleEditEventSetContactCountryCode
                  }
                />

                <EventParticipants
                  //edit
                  editEventMode={true}
                  CreateEvent={CreateEvent}
                  skipToId={'translationsLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToTranslations}
                  pauseCrop={eventPreview ? true : false}
                  //state
                  eventId={loadEditEventId}
                  participants={loadEventParticipants}
                  participantImagesCropped={participantImagesCropped}
                  translationsRoomLanguages={loadTranslationsRoomLanguages}
                  removedParticipantsWithDispatchedInvites={
                    removedParticipantsWithDispatchedInvites
                  }
                  //handlers
                  handleSetParticipants={handleEditEventSetParticipants}
                  setParticipantChangeTracker={setParticipantChangeTracker}
                  setParticipantImagesChangeTracker={
                    setParticipantImagesChangeTracker
                  }
                  setParticipantImagesCropped={setParticipantImagesCropped}
                  setRemovedParticipantsWithDispatchedInvites={
                    setRemovedParticipantsWithDispatchedInvites
                  }
                />

                <EventTranslations
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  skipToId={'agendaLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToAgenda}
                  //state
                  participants={loadEventParticipants}
                  participantImagesCropped={participantImagesCropped}
                  translationsEnabled={loadTranslationsTranslationsEnabled}
                  translationsRoomCodeId={loadTranslationsRoomCodeId}
                  translationsJoinRoomPasswordToggle={
                    loadTranslationsJoinRoomPasswordToggle
                  }
                  translationsPassword={loadTranslationsPassword}
                  translationsRoomLanguages={loadTranslationsRoomLanguages}
                  //handlers
                  handleSetParticipants={handleEditEventSetParticipants}
                  handleSetTranslationsTranslationsEnabled={
                    handleEditEventSetTranslationsTranslationsEnabled
                  }
                  handleSetTranslationsRoomCodeId={
                    handleEditEventSetTranslationsRoomCodeId
                  }
                  handleSetTranslationsJoinRoomPasswordToggle={
                    handleEditEventSetTranslationsJoinRoomPasswordToggle
                  }
                  handleSetTranslationsPassword={
                    handleEditEventSetTranslationsPassword
                  }
                  handleSetTranslationsRoomLanguages={
                    handleEditEventSetTranslationsRoomLanguages
                  }
                />

                <Agenda
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  skipToId={'accessLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToAccess}
                  //state
                  loadAgendaItems={loadAgendaItems}
                  loadAgendaFormat={loadAgendaFormat}
                  //handlers
                  handleSetAgendaFormat={handleEditEventSetAgendaFormat}
                  handleSetAgendaItems={handleEditEventSetAgendaItems}
                />

                <Access
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  skipToId={'registrationLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToRegistration}
                  //state
                  loadHostingOption={loadHostingOption}
                  loadVirtualPlatform={loadVirtualPlatform}
                  loadVirtualMeetingId={loadVirtualMeetingId}
                  loadVirtualPasscode={loadVirtualPasscode}
                  loadVirtualLink={loadVirtualLink}
                  loadLocation={loadLocation}
                  loadCoordinates={loadCoordinates}
                  loadWheelchairAccessibility={loadWheelchairAccessibility}
                  loadSignLanguageInterpreter={loadSignLanguageInterpreter}
                  loadAccessibilityNotes={loadAccessibilityNotes}
                  //handlers
                  handleSetAccessibilityNotes={
                    handleEditEventSetAccessibilityNotes
                  }
                  handleClearLocation={handleEditEventClearLocation}
                  handleSetCoordinates={handleEditEventSetCoordinates}
                  handleSetHostingOption={handleEditEventSetHostingOption}
                  handleSetLocation={handleEditEventSetLocation}
                  handleSetSignLanguageInterpreter={
                    handleEditEventSetSignLanguageInterpreter
                  }
                  handleSetVirtualMeetingId={handleEditEventSetVirtualMeetingId}
                  handleSetVirtualPasscode={handleEditEventSetVirtualPasscode}
                  handleSetVirtualPlatform={handleEditEventSetVirtualPlatform}
                  handleSetVirtualMeetingLink={
                    handleEditEventSetVirtualMeetingLink
                  }
                  handleSetWheelchairAccessibility={
                    handleEditEventSetWheelchairAccessibility
                  }
                />
                <Registration
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  skipToId={'imagesLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToImages}
                  //state
                  translationsEnabled={loadTranslationsTranslationsEnabled}
                  contactEnabled={loadContactProvideContact}
                  loadHostingOption={loadHostingOption}
                  loadMaxRegistrationsInPersonEnabled={
                    loadMaxRegistrationsInPersonEnabled
                  }
                  loadMaxRegistrationsVirtualEnabled={
                    loadMaxRegistrationsVirtualEnabled
                  }
                  loadEnablePrivateDetails={loadEnablePrivateDetails}
                  loadRegistrationsActive={loadRegistrationsActive}
                  loadApproveRegistrations={loadApproveRegistrations}
                  loadMaxRegistrationsInPersonAmount={
                    loadMaxRegistrationsInPersonAmount
                  }
                  loadMaxRegistrationsVirtualAmount={
                    loadMaxRegistrationsVirtualAmount
                  }
                  loadPrivateDetails={loadPrivateDetails}
                  //handlers
                  handleSetApproveRegistrations={
                    handleEditEventSetApproveRegistrations
                  }
                  handleSetMaxRegistrationsInPersonEnabled={
                    handleEditEventSetMaxRegistrationsInPersonEnabled
                  }
                  handleSetMaxRegistrationsVirtualEnabled={
                    handleEditEventSetMaxRegistrationsVirtualEnabled
                  }
                  handleSetEnablePrivateDetails={
                    handleEditEventSetEnablePrivateDetails
                  }
                  handleSetMaxRegistrationsInPersonAmount={
                    handleEditEventSetMaxRegistrationsInPersonAmount
                  }
                  handleSetMaxRegistrationsVirtualAmount={
                    handleEditEventSetMaxRegistrationsVirtualAmount
                  }
                  handleSetPrivateDetails={handleEditEventSetPrivateDetails}
                  handleSetRegistrations={handleEditEventSetRegistrations}
                />
                <Images
                  initialize={initialize}
                  CreateEvent={CreateEvent}
                  editEventMode={true}
                  pauseCrop={eventPreview ? true : false}
                  skipToId={'tagsLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToTags}
                  //state
                  loadEventImageDisplay={loadEventImageDisplay}
                  loadEventAdditionalImages={loadEventAdditionalImages}
                  eventImageDisplayCropped={eventImageDisplayCropped}
                  eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                  //handlers
                  setEventImageDisplayChangeTracker={
                    setEventImageDisplayChangeTracker
                  }
                  setEventAdditionalImagesChangeTracker={
                    setEventAdditionalImagesChangeTracker
                  }
                  handleSetEventImageDisplay={
                    handleEditEventSetEventImageDisplay
                  }
                  handleSetEventAdditionalImages={
                    handleEditEventSetEventAdditionalImages
                  }
                  setEventImageDisplayCropped={setEventImageDisplayCropped}
                  setEventAdditionalImagesCropped={
                    setEventAdditionalImagesCropped
                  }
                />
                <EventTags
                  CreateEvent={CreateEvent}
                  skipToId={'editOverviewLabel'}
                  skipToLabel={CreateEvent.skipTo.skipToEditOverview}
                  //state
                  loadTinyTags={loadTinyTags}
                  loadBigTags={loadBigTags}
                  //handlers
                  handleSetBigTags={handleEditEventSetBigTags}
                  handleSetTinyTags={handleEditEventSetTinyTags}
                />

                <ScrollToTop focusId={'initializeLabel'} />
              </>
            ) : (
              organizeIsLoading && <Spinner />
            )}

            <div className="h48" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEvent;
