/*eslint-disable*/
import { useEffect, useState } from 'react';

//Hooks
import { useEventValidation } from '../useOrganizeHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useSettings from '../../../../context/useSettings';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import ItemButton from '../../../../components/ItemButton/ItemButton';
import ScrollToComponent from '../../../../components/ScrollToComponent/ScrollToComponent';
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';

//Utility
import { deepEqual } from '../../../../utils/logic/deepEqual';
import {
  cancel,
  preview,
  refresh,
  return_curved_arrow,
  save,
} from '../../../../assets/icons';

const EditOverview = ({
  editEventData,
  eventId,
  reset,
  setEditEvent,
  setEventPreview,
  handleEditEventReset,
  handleRemoveEvent,
  handleReset,
  handleUpdateEvent,
  setOrganizeIsLoading,

  //change tracker state
  changeList,
  handleEditEventChangeList,
  pauseChangeList,
  setPauseChangeList,
  participantChangeTracker,
  eventImageDisplayChangeTracker,
  eventAdditionalChangeTracker,
  participantImagesChangeTracker,
  //eventdata
  lastModified,
  loadAccessibilityNotes,
  loadAgendaItems,
  loadApproveRegistrations,
  loadAudienceType,
  loadBigTags,
  loadCoordinates,
  loadDate,
  loadDescription,
  loadEventType,
  loadHostingOption,
  loadLanguages,
  loadLocation,
  loadMaxRegistrationsInPersonAmount,
  loadMaxRegistrationsInPersonEnabled,
  loadMaxRegistrationsVirtualAmount,
  loadMaxRegistrationsVirtualEnabled,
  loadPrivateDetails,
  loadRegistrationsActive,
  loadSchedulePublishDate,
  loadSchedulePublishTime,
  loadSchedulePublishTimeZone,
  loadSignLanguageInterpreter,
  loadTimeEnd,
  loadTimeStart,
  loadTimeZone,
  loadTinyTags,
  loadTitle,
  loadVirtualLink,
  loadVirtualMeetingId,
  loadVirtualPasscode,
  loadVirtualPlatform,
  loadWheelchairAccessibility,

  //translations
  loadTranslationsEnabled,
  loadTranslationsRoomCodeId,
  loadTranslationsJoinRoomPasswordToggle,
  loadTranslationsPassword,
  loadTranslationsRoomLanguages,

  //contact
  loadContactProvideContact,
  loadContactEmail,
  loadContactPhoneNumber,
  loadContactCountryCode,
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();
  const { EditOverview } = useLanguageOrganize();
  const { Generic } = useLanguageComponents();
  const { accountId } = useAccountReduxHandlers();

  const { settingsOrganizeStandaloneGridEvents } = useSettingsHandlers();
  const { handleEventValidation } = useEventValidation();

  //Component State
  const [initialData, setInitialData] = useState();
  const [confirmSelectionBtn, setConfirmSelectionBtn] = useState();
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();

  //UI state
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);

  //Initialize functions
  useEffect(() => {
    setInitialData(editEventData);
  }, []);

  useEffect(() => {
    if (reset) {
      setInitialData(editEventData);
    }
  }, [reset]);

  //Component functions
  function handleReturnClick() {
    setPauseChangeList(true);
    handleMobileTap(
      [() => setTapHighlightReturn(true), () => setTapHighlightReturn(false)],
      [() => handleEditEventReset(), () => setEditEvent()],
      true
    );
  }

  useEffect(() => {
    if (lastModified > verifiedTimestamp && confirmSelectionBtn) {
      setVerifiedSuccessful(false);
      setConfirmSelectionBtn();
      setVerifiedTimestamp();
    }
  }, [lastModified, verifiedTimestamp]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  //Component functions

  async function handleSaveEditChanges() {
    setPauseChangeList(true);
    setOrganizeIsLoading(true);
    setConfirmSelectionBtn();
    await handleUpdateEvent({
      eventData: editEventData,
      handleType: editEventData?.schedule?.scheduleEnabled
        ? 'editScheduled'
        : 'editPublished',
    });
    handleEditEventReset();
  }

  function handleSaveBtnClick() {
    if (confirmSelectionBtn === 'save') {
      setConfirmSelectionBtn();
    } else {
      setConfirmSelectionBtn('save');
      const validationsCheck = handleEventValidation('edit');

      if (Array.isArray(validationsCheck)) {
        setValidations(validationsCheck);
        setVerifiedSuccessful(false);
      } else {
        setVerifiedTimestamp(Date.now());
        setVerifiedSuccessful(true);
        setValidations();
      }
    }
  }

  //GENERIC checks
  useEffect(() => {
    if (!pauseChangeList) {
      if (
        eventImageDisplayChangeTracker &&
        !changeList.includes('eventImageDisplay')
      ) {
        handleEditEventChangeList([...changeList, 'eventImageDisplay']);
      } else if (!eventImageDisplayChangeTracker) {
        let newList = changeList.filter((item) => item !== 'eventImageDisplay');
        handleEditEventChangeList(newList);
      }
    }
  }, [eventImageDisplayChangeTracker]); //event image + speaker images

  useEffect(() => {
    if (!pauseChangeList) {
      if (
        eventAdditionalChangeTracker &&
        !changeList.includes('eventAdditionalImages')
      ) {
        handleEditEventChangeList([...changeList, 'eventAdditionalImages']);
      } else if (!eventAdditionalChangeTracker) {
        let newList = changeList.filter(
          (item) => item !== 'eventAdditionalImages'
        );
        handleEditEventChangeList(newList);
      }
    }
  }, [eventAdditionalChangeTracker]); //event image + speaker images

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTitle !== initialData?.generic?.title) {
          if (!changeList.includes('title')) {
            handleEditEventChangeList([...changeList, 'title']);
          }
        } else {
          if (changeList.includes('title')) {
            let newList = changeList.filter((item) => item !== 'title');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTitle]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadDescription !== initialData?.generic?.description) {
          if (!changeList.includes('description')) {
            handleEditEventChangeList([...changeList, 'description']);
          }
        } else {
          if (changeList.includes('description')) {
            let newList = changeList.filter((item) => item !== 'description');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadDescription]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadEventType !== initialData?.generic?.eventType) {
          if (!changeList.includes('eventType')) {
            handleEditEventChangeList([...changeList, 'eventType']);
          }
        } else {
          if (changeList.includes('eventType')) {
            let newList = changeList.filter((item) => item !== 'eventType');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadEventType]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadAudienceType !== initialData?.generic?.audienceType) {
          if (!changeList.includes('audienceType')) {
            handleEditEventChangeList([...changeList, 'audienceType']);
          }
        } else {
          if (changeList.includes('audienceType')) {
            let newList = changeList.filter((item) => item !== 'audienceType');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAudienceType]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadDate !== initialData?.dateTime?.date) {
          if (!changeList.includes('date')) {
            handleEditEventChangeList([...changeList, 'date']);
          }
        } else {
          if (changeList.includes('date')) {
            let newList = changeList.filter((item) => item !== 'date');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadDate]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        const equalityCheck = deepEqual(
          loadLanguages,
          initialData?.generic?.languages
        );
        if (!equalityCheck) {
          if (!changeList.includes('languages')) {
            handleEditEventChangeList([...changeList, 'languages']);
          }
        } else {
          if (changeList.includes('languages')) {
            let newList = changeList.filter((item) => item !== 'languages');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadLanguages]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeZone !== initialData?.dateTime?.date) {
          if (!changeList.includes('date')) {
            handleEditEventChangeList([...changeList, 'date']);
          }
        } else {
          if (changeList.includes('date')) {
            let newList = changeList.filter((item) => item !== 'date');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeZone]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeStart !== initialData?.dateTime?.timeStart) {
          if (!changeList.includes('timeStart')) {
            handleEditEventChangeList([...changeList, 'timeStart']);
          }
        } else {
          if (changeList.includes('timeStart')) {
            let newList = changeList.filter((item) => item !== 'timeStart');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeStart]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeEnd !== initialData?.dateTime?.timeEnd) {
          if (!changeList.includes('timeEnd')) {
            handleEditEventChangeList([...changeList, 'timeEnd']);
          }
        } else {
          if (changeList.includes('timeEnd')) {
            let newList = changeList.filter((item) => item !== 'timeEnd');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeEnd]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        const equalityCheck = deepEqual(
          loadAgendaItems,
          initialData?.generic?.agenda?.items
        );

        if (!equalityCheck) {
          if (!changeList.includes('agenda')) {
            handleEditEventChangeList([...changeList, 'agenda']);
          }
        } else {
          if (changeList.includes('agenda')) {
            let newList = changeList.filter((item) => item !== 'agenda');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAgendaItems]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadSignLanguageInterpreter !==
          initialData?.generic?.signLanguageInterpreter
        ) {
          if (!changeList.includes('signLanguageInterpreter')) {
            handleEditEventChangeList([
              ...changeList,
              'signLanguageInterpreter',
            ]);
          }
        } else {
          if (changeList.includes('signLanguageInterpreter')) {
            let newList = changeList.filter(
              (item) => item !== 'signLanguageInterpreter'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSignLanguageInterpreter]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadWheelchairAccessibility !==
          initialData?.generic?.wheelchairAccessibility
        ) {
          if (!changeList.includes('wheelchairAccessibility')) {
            handleEditEventChangeList([
              ...changeList,
              'wheelchairAccessibility',
            ]);
          }
        } else {
          if (changeList.includes('wheelchairAccessibility')) {
            let newList = changeList.filter(
              (item) => item !== 'wheelchairAccessibility'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadWheelchairAccessibility]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadAccessibilityNotes !== initialData?.generic?.accessibilityNotes
        ) {
          if (!changeList.includes('accessibilityNotes')) {
            handleEditEventChangeList([...changeList, 'accessibilityNotes']);
          }
        } else {
          if (changeList.includes('accessibilityNotes')) {
            let newList = changeList.filter(
              (item) => item !== 'accessibilityNotes'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAccessibilityNotes]);

  //Hosting
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadHostingOption !== initialData?.hosting?.hostingOption) {
          if (!changeList.includes('hostingOption')) {
            handleEditEventChangeList([...changeList, 'hostingOption']);
          }
        } else {
          if (changeList.includes('hostingOption')) {
            let newList = changeList.filter((item) => item !== 'hostingOption');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadHostingOption]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadCoordinates[0] !==
            initialData?.hosting?.inPerson?.geoPosition?.coordinates[0] &&
          loadCoordinates[1] !==
            initialData?.hosting?.inPerson?.geoPosition?.coordinates[1]
        ) {
          if (!changeList.includes('coordinates')) {
            handleEditEventChangeList([...changeList, 'coordinates']);
          }
        } else {
          if (changeList.includes('coordinates')) {
            let newList = changeList.filter((item) => item !== 'coordinates');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadCoordinates]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualMeetingId !== initialData?.hosting?.virtual?.meetingId) {
          if (!changeList.includes('virtualMeetingId')) {
            handleEditEventChangeList([...changeList, 'virtualMeetingId']);
          }
        } else {
          if (changeList.includes('virtualMeetingId')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingId'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualMeetingId]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualPasscode !== initialData?.hosting?.virtual?.passcode) {
          if (!changeList.includes('virtualMeetingPasscode')) {
            handleEditEventChangeList([
              ...changeList,
              'virtualMeetingPasscode',
            ]);
          }
        } else {
          if (changeList.includes('virtualMeetingPasscode')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingPasscode'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualPasscode]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualPlatform !== initialData?.hosting?.virtual?.platform) {
          if (!changeList.includes('virtualPlatform')) {
            handleEditEventChangeList([...changeList, 'virtualPlatform']);
          }
        } else {
          if (changeList.includes('virtualPlatform')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualPlatform'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualPlatform]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualLink !== initialData?.hosting?.virtual?.meetingLink) {
          if (!changeList.includes('virtualMeetingLink')) {
            handleEditEventChangeList([...changeList, 'virtualMeetingLink']);
          }
        } else {
          if (changeList.includes('virtualMeetingLink')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingLink'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualLink]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadLocation,
          initialData?.hosting?.inPerson?.location
        );
        if (!equalityCheck) {
          if (!changeList.includes('location')) {
            handleEditEventChangeList([...changeList, 'location']);
          }
        } else {
          if (changeList.includes('location')) {
            let newList = changeList.filter((item) => item !== 'location');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadLocation]);

  //Participants
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (participantChangeTracker && !changeList.includes('participants')) {
          handleEditEventChangeList([...changeList, 'participants']);
        } else {
          if (changeList.includes('participants')) {
            let newList = changeList.filter((item) => item !== 'participants');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [participantChangeTracker]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          participantImagesChangeTracker &&
          !changeList.includes('participantImages')
        ) {
          handleEditEventChangeList([...changeList, 'participantImages']);
        } else {
          if (changeList.includes('participantImages')) {
            let newList = changeList.filter(
              (item) => item !== 'participantImages'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [participantImagesChangeTracker]);

  //Registrations
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadRegistrationsActive !==
          initialData?.registrationData?.registrationsActive
        ) {
          if (!changeList.includes('requireRegistrations')) {
            handleEditEventChangeList([...changeList, 'requireRegistrations']);
          }
        } else {
          if (changeList.includes('requireRegistrations')) {
            let newList = changeList.filter(
              (item) => item !== 'requireRegistrations'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    } //particular behaviour requires change list to be added as a dependency. otehr registration buttons are dependent on the require registrations/active registrations toggle, but there are race conditions and the dependent states will call the change list handler but ultimately wipe out the requireRegistrations item before it can be passed down.
  }, [loadRegistrationsActive, changeList]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadApproveRegistrations !==
          initialData?.registrationData?.approveRegistrations
        ) {
          if (!changeList.includes('approveRegistrations')) {
            handleEditEventChangeList([...changeList, 'approveRegistrations']);
          }
        } else {
          if (changeList.includes('approveRegistrations')) {
            let newList = changeList.filter(
              (item) => item !== 'approveRegistrations'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadApproveRegistrations]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadMaxRegistrationsInPersonAmount !==
            initialData?.registrationData?.maxRegistrationsInPersonAmount ||
          loadMaxRegistrationsInPersonEnabled !==
            initialData?.registrationData?.maxRegistrationsInPersonEnabled
        ) {
          if (!changeList.includes('maxRegistrationsInPerson')) {
            handleEditEventChangeList([
              ...changeList,
              'maxRegistrationsInPerson',
            ]);
          }
        } else {
          if (changeList.includes('maxRegistrationsInPerson')) {
            let newList = changeList.filter(
              (item) => item !== 'maxRegistrationsInPerson'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadMaxRegistrationsInPersonAmount, loadMaxRegistrationsInPersonEnabled]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadMaxRegistrationsVirtualEnabled !==
            initialData?.registrationData?.maxRegistrationsVirtualEnabled ||
          loadMaxRegistrationsVirtualAmount !==
            initialData?.registrationData?.maxRegistrationsVirtualAmount
        ) {
          if (!changeList.includes('maxRegistrationsVirtual')) {
            handleEditEventChangeList([
              ...changeList,
              'maxRegistrationsVirtual',
            ]);
          }
        } else {
          if (changeList.includes('maxRegistrationsVirtual')) {
            let newList = changeList.filter(
              (item) => item !== 'maxRegistrationsVirtual'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadMaxRegistrationsVirtualEnabled, loadMaxRegistrationsVirtualAmount]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadPrivateDetails,
          initialData?.registrationData?.privateDetails
        );

        if (!equalityCheck) {
          if (!changeList.includes('privateDetails')) {
            handleEditEventChangeList([...changeList, 'privateDetails']);
          }
        } else {
          if (changeList.includes('privateDetails')) {
            let newList = changeList.filter(
              (item) => item !== 'privateDetails'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadPrivateDetails]);

  //Schedule
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadSchedulePublishDate !== initialData?.schedule?.publishDate) {
          if (!changeList.includes('schedulePublishDate')) {
            handleEditEventChangeList([...changeList, 'schedulePublishDate']);
          }
        } else {
          if (changeList.includes('schedulePublishDate')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishDate'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishDate]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadSchedulePublishTimeZone !== initialData?.schedule?.publishTimeZone
        ) {
          if (!changeList.includes('schedulePublishTimeZone')) {
            handleEditEventChangeList([
              ...changeList,
              'schedulePublishTimeZone',
            ]);
          }
        } else {
          if (changeList.includes('schedulePublishTimeZone')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishTimeZone'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishTimeZone]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadSchedulePublishTime !== initialData?.schedule?.publishTime) {
          if (!changeList.includes('schedulePublishTime')) {
            handleEditEventChangeList([...changeList, 'schedulePublishTime']);
          }
        } else {
          if (changeList.includes('schedulePublishTime')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishTime'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishTime]);

  //Tags
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(loadBigTags, initialData?.tags?.bigTags);

        if (!equalityCheck) {
          if (!changeList.includes('bigTags')) {
            handleEditEventChangeList([...changeList, 'bigTags']);
          }
        } else {
          if (changeList.includes('bigTags')) {
            let newList = changeList.filter((item) => item !== 'bigTags');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadBigTags]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadTinyTags,
          initialData?.tags?.tinyTags
        );

        if (!equalityCheck) {
          if (!changeList.includes('tinyTags')) {
            handleEditEventChangeList([...changeList, 'tinyTags']);
          }
        } else {
          if (changeList.includes('tinyTags')) {
            let newList = changeList.filter((item) => item !== 'tinyTags');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTinyTags]);

  //translations
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsEnabled !==
          initialData?.translations?.translationsEnabled
        ) {
          if (!changeList.includes('translationsEnabled')) {
            handleEditEventChangeList([...changeList, 'translationsEnabled']);
          }
        } else {
          if (changeList.includes('translationsEnabled')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsEnabled'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsEnabled]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsRoomCodeId?.toString() !==
          initialData?.translations?.roomCodeId.toString()
        ) {
          if (!changeList.includes('translationsRoomCode')) {
            handleEditEventChangeList([...changeList, 'translationsRoomCode']);
          }
        } else {
          if (changeList.includes('translationsRoomCode')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsRoomCode'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsRoomCodeId]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsJoinRoomPasswordToggle !==
            initialData?.translations?.joinRoomPasswordToggle ||
          (loadTranslationsJoinRoomPasswordToggle !==
            initialData?.translations?.joinRoomPasswordToggle &&
            loadTranslationsPassword !== initialData?.translations?.password)
        ) {
          if (!changeList.includes('translationsPassword')) {
            handleEditEventChangeList([...changeList, 'translationsPassword']);
          }
        } else {
          if (changeList.includes('translationsPassword')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsPassword'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsJoinRoomPasswordToggle, loadTranslationsPassword]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadTranslationsRoomLanguages,
          initialData?.translations?.roomLanguages
        );

        if (!equalityCheck) {
          if (!changeList.includes('translationsRoomLanguages')) {
            handleEditEventChangeList([
              ...changeList,
              'translationsRoomLanguages',
            ]);
          }
        } else {
          if (changeList.includes('translationsRoomLanguages')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsRoomLanguages'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsRoomLanguages]);

  //contact
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadContactProvideContact !== initialData?.contact?.provideContact
        ) {
          if (!changeList.includes('provideContact')) {
            handleEditEventChangeList([...changeList, 'provideContact']);
          }
        } else {
          let newList = changeList.filter((item) => item !== 'provideContact');
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactProvideContact]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadContactEmail !== initialData?.contact?.contactEmail) {
          if (!changeList.includes('contactEmail')) {
            handleEditEventChangeList([...changeList, 'contactEmail']);
          }
        } else {
          let newList = changeList.filter((item) => item !== 'contactEmail');
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactEmail]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadContactPhoneNumber !== initialData?.contact?.contactPhoneNumber ||
          loadContactCountryCode !== initialData?.contact?.contactCountryCode
        ) {
          if (!changeList.includes('contactPhoneNumber')) {
            handleEditEventChangeList([...changeList, 'contactPhoneNumber']);
          }
        } else {
          let newList = changeList.filter(
            (item) => item !== 'contactPhoneNumber'
          );
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactPhoneNumber, loadContactCountryCode]);

  return (
    <>
      <ul className="editActions-btns">
        <li>
          <button
            className={`action-btn highlight-i-theme 
            ${
              tapHighlightReturn
                ? 'highlight-i-theme--tap click-scaledown-90'
                : ''
            }`}
            onClick={() => handleReturnClick()}
            title={Generic.return}
          >
            <img
              src={return_curved_arrow}
              alt={Generic.arrow}
              className="filter-lightgray"
            />
          </button>
        </li>
      </ul>

      <div className="fieldset mrg-t48" id="fieldset-editactions">
        {/* <legend id="aria-fieldset-instructions-editOverview"> */}
        {/* {CreateEvent.general.ariaFieldsetInstructions} */}
        {/* </legend> */}
        <div className="h0-ph24" />
        <label
          className="label"
          id="editOverviewLabel"
          aria-describedby="aria-fieldset-instructions-editOverview"
          tabIndex="0"
        >
          {EditOverview.editOverview}
        </label>

        <div className="flex-row">
          <div className="flex-column">
            <p className="fs18 fwsb">{EditOverview.changeList}:</p>

            <ul className="mrg-l24 mrg-t24">
              {changeList?.map((item, index) => (
                <li className="fs18 fwsb mrg-b6" key={`changeList-${index}`}>
                  {EditOverview[item]}
                </li>
              ))}
            </ul>
            {changeList?.length === 0 && (
              <ul className="mrg-l24 ">
                <li className="fs18 fwsb mrg-b6">{EditOverview.noChange}</li>
              </ul>
            )}
          </div>
        </div>
        <div className="edit-divider mrg-tb48 opacity-8" />

        <div className={`button-meta__container flex-center space-evenly `}>
          <ButtonMeta
            handleClick={handleSaveBtnClick}
            btnText={EditOverview.saveChanges}
            icon={save}
            iconAlt={Generic.iconSave}
            activeStateDependency={confirmSelectionBtn === 'save'}
            unavailable={changeList?.length === 0 ? true : false}
            availabilityToggle={true}
          />

          <ButtonMeta
            handleClick={() => {
              if (confirmSelectionBtn === 'reset') {
                setConfirmSelectionBtn();
              } else {
                setConfirmSelectionBtn('reset');
              }
            }}
            btnText={EditOverview.undoChanges}
            icon={refresh}
            iconAlt={Generic.iconReset}
            activeStateDependency={confirmSelectionBtn === 'reset'}
            unavailable={changeList?.length === 0 ? true : false}
            availabilityToggle={true}
          />
          <ButtonMeta
            handleClick={() => {
              if (confirmSelectionBtn === 'cancel') {
                setConfirmSelectionBtn();
              } else {
                setConfirmSelectionBtn('cancel');
              }
            }}
            btnText={EditOverview.cancelEvent}
            icon={cancel}
            iconAlt={'X'}
            activeStateDependency={confirmSelectionBtn === 'cancel'}
          />
        </div>
        <div className="pad-24 mrg-t24">
          <ItemButton
            handler={() => setEventPreview(editEventData)}
            text={EditOverview.previewEvent}
            iconOn={true}
            icon={preview}
            fullWidth={true}
          />
        </div>
        {confirmSelectionBtn === 'save' && verifiedSuccessful && (
          <>
            <ConfirmSelection
              text={EditOverview.saveConfirmation}
              btn1Text={EditOverview.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setConfirmSelectionBtn()}
              btn2Text={EditOverview.save}
              btn2Color={'pos'}
              btn2Handler={handleSaveEditChanges}
              ignoreMaxWidth={true}
              customWrapper={'mrg-t24'}
            />
          </>
        )}

        {confirmSelectionBtn === 'save' && validations && (
          <div className="validations__wrapper">
            <div className="h24" />
            <div className="validations-title fs16 fwsb color-red">
              <p className="access-ob access-o6" tabIndex="0">
                {EditOverview.fieldsThatRequireCompletion}
              </p>
            </div>
            <div className="h24" />
            <ul className="scrollToComponent__container">
              {validations.map((item, index) => {
                const validationLabel = Object.values(item);
                const element = Object.keys(item);
                return (
                  <div key={`scrollToComponent-${index}`}>
                    <ScrollToComponent
                      text={validationLabel[0]}
                      elementId={element[0]}
                    />
                  </div>
                );
              })}
            </ul>
          </div>
        )}

        {confirmSelectionBtn === 'reset' && (
          <ConfirmSelection
            text={EditOverview.undoConfirmation}
            btn1Text={EditOverview.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setConfirmSelectionBtn()}
            btn2Text={EditOverview.confirm}
            btn2Color={'pos'}
            btn2Handler={() => {
              setPauseChangeList(true);
              setConfirmSelectionBtn();
              handleReset();
            }}
            customWrapper={'mrg-t24'}
            ignoreMaxWidth={true}
          />
        )}
        {confirmSelectionBtn === 'cancel' && (
          <ConfirmSelection
            btn1Text={EditOverview.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setConfirmSelectionBtn()}
            btn2Text={EditOverview.deletePermanently}
            btn2Color={'neg'}
            btn2Handler={() => {
              setConfirmSelectionBtn();
              setOrganizeIsLoading(true);
              handleRemoveEvent({
                eventId,
                organizerId: accountId,
                eventType: settingsOrganizeStandaloneGridEvents,
                participants: initialData?.participants || [],
              });
            }}
            customWrapper={'mrg-t24'}
            ignoreMaxWidth={true}
          />
        )}
      </div>
    </>
  );
};

export default EditOverview;
