import { useCallback, useState, useRef, useEffect } from 'react';
import heic2any from 'heic2any';
import { fileTypeFromBlob } from 'file-type';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';
import useApiStatus from '../../context/useApiStatus';

//Components
import Cropper from 'react-easy-crop';
import Spinner from '../Spinner/Spinner';

//Utility
import { trash, profile_frame } from '../../assets/icons';
import getCroppedImg from '../../utils/images/cropImage';
import { textUI } from '../../utils/UI/textUI';

//cover types: 'contain', 'horizontal-cover', 'vertical-cover' or 'auto-cover'
//used when state needs to be lifted to parent; main effect is to lift imagePreview to parent state and have that image render in this component; also it ensures that the imagePreview URL is revoked when removing the speaker container

//ignoreRemoveImageOnDismount, used for when the image cropper is used for conditionally rendered components.
const ImageCropper = ({
  center,
  cover,
  handleImage,
  height,
  id,
  imagePreview,
  width,
  profileFrame,
  HOCrop,
  setHOCrop,
  HOZoom,
  setHOZoom,
  ariaImageDescription,
  pauseCrop,
  setHOActionBtnsUnavailable,
  customFileUploaderWrapper,
  customFileUploaderContainer,
  ignoreRemoveImageOnDismount,
  saveCroppedPos,
  loadCroppedPos,
  trashButtonSpacerOn,
  setFileUploadTracker,
}) => {
  //Hooks
  const { ImageCropper: ImageCropperTranslation } = useLanguageComponents();
  const { width: phoneWidth, nonce, handleMobileTap } = useSettings();
  const { handleError } = useApiStatus();

  //Component state
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState();

  //UI state
  const [showGrid, setShowGrid] = useState(false);
  const [tapHighlightRemove, setTapHighlightRemove] = useState(false);
  const [convertingHEIC, setConvertingHEIC] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  //Component variables
  const fileInput = useRef();
  const customSize = {
    height: `${height}px`,
    width: `${width}px`,
  };

  //initialize
  useEffect(() => {
    const initializationTimer = setTimeout(() => {
      setIsInitializing(false);
    }, 1200);

    onCropComplete(
      {},
      {
        width,
        height,
        x: loadCroppedPos?.crop?.x || 0,
        y: loadCroppedPos?.crop?.y || 0,
      }
    );
    setCrop({
      x: loadCroppedPos?.crop?.x || 0,
      y: loadCroppedPos?.crop?.y || 0,
    });
    setZoom(loadCroppedPos?.zoom || 1);

    return () => {
      clearTimeout(initializationTimer);

      if (!ignoreRemoveImageOnDismount) {
        removeImage();
      }
    };
  }, []); //very crucial for event image croppers & saving; calling onCropComplete is used to create a new croppedImage when the user loads a draft, so the new draft creates a new file to save to the db for the images if the data is used for a new save; without the new cropped image, the path for the image will be used across multiple drafts/events, and if one is deleted it is removed for all.

  const initializationCropRan = useRef(false);

  //Component functions
  async function handleFileInput(e) {
    let mime;

    try {
      const file = e.target.files[0];

      if (!file) return;
      if (setFileUploadTracker) {
        setFileUploadTracker(true);
      }

      URL.revokeObjectURL(imagePreview);

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        if (
          file.type === 'image/heic' ||
          file.type === 'image/heif' ||
          file.name.endsWith('.heic') ||
          file.name.endsWith('.heif')
        ) {
          mime = 'image/heic';
        } else {
          mime = file.type;
        }
      } else {
        // Use `fileTypeFromBlob` for non-iOS Safari
        const response = await fileTypeFromBlob(file);
        mime = response.mime;
      }

      // Detect if the file is HEIC and convert it to JPEG
      if (mime === 'image/heic' || mime === 'image/heif') {
        setConvertingHEIC(true); // Set state to true to indicate conversion start
        if (setHOActionBtnsUnavailable) {
          setHOActionBtnsUnavailable(true);
        }

        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });
          const convertedFile = new File(
            [convertedBlob],
            file.name.replace(/\.[^/.]+$/, '') + '.jpeg',
            { type: 'image/jpeg' }
          );
          const imgPreview = URL.createObjectURL(convertedFile);

          let imgData = {
            croppedImage: { croppedImage },
            imagePreview: imgPreview,
          };

          handleImage(imgData, id, 'image');

          initializationCropRan.current = false;

          // Reset states after successful conversion
          if (setHOActionBtnsUnavailable) {
            setHOActionBtnsUnavailable(false);
          }

          setConvertingHEIC(false);
        } catch (error) {
          if (setHOActionBtnsUnavailable) {
            setHOActionBtnsUnavailable(false);
          }
          setConvertingHEIC(false);
          throw error;
        }
      } else {
        // Non-HEIC file handling
        const imgPreview = URL.createObjectURL(file);
        let imgData = {
          croppedImage: { croppedImage },
          imagePreview: imgPreview,
        };
        handleImage(imgData, id, 'image');
      }
    } catch (error) {
      await handleError({
        message: 'imageUploadFailure',
        id: Date.now(),
        origin: 'ImageCropper/handleFileInput',
        createClientLog: error,
      });
    }
  }

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    if (!pauseCrop) {
      try {
        const croppedImage = await getCroppedImg(
          imagePreview,
          croppedAreaPixels
        );

        setCroppedImage(croppedImage);
        let imgData = {
          croppedImage: { croppedImage },
          imagePreview: imagePreview,
        };

        if (saveCroppedPos) {
          imgData.crop = crop;
          imgData.zoom = zoom;
        }

        handleImage(imgData, id, 'image');
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('error', error);
          // handleError({
          //   message: 'error',
          //   id: Date.now(),
          //   origin: 'ImageCropper/onCropComplete',
          //   createClientLog: error,
          // });
        }

        return null;
      }
    }

    /*eslint-disable-next-line*/
    [croppedAreaPixels, croppedArea];
  });

  function removeImage() {
    const imageInput = document.getElementById(`${id}-uploader`);
    if (imageInput) {
      imageInput.value = '';
    }

    if (setFileUploadTracker) {
      setFileUploadTracker(true);
    }

    URL.revokeObjectURL(imagePreview);
    URL.revokeObjectURL(croppedImage?.croppedImage);
    setCroppedImage();

    let imgData = {
      croppedImage: {
        croppedImage: '',
      },
      imagePreview: '',
    };
    if (saveCroppedPos) {
      imgData.crop = crop;
      imgData.zoom = zoom;
    }
    handleImage(imgData, id, 'image');
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  }

  function handleFileUploader(e) {
    if (fileInput.current && e.key === 'Enter') {
      fileInput.current && fileInput.current.click();
    }
  }

  function keyboardImageControls(e) {
    if (!showGrid) {
      setShowGrid(true);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();

      if (HOCrop) {
        setHOCrop({
          ...HOCrop,
          y: HOCrop?.y + 1,
        });
      } else {
        setCrop({
          ...crop,
          y: crop.y + 1,
        });
      }
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (HOCrop) {
        setHOCrop({
          ...HOCrop,
          y: HOCrop?.y - 1,
        });
      } else {
        setCrop({
          ...crop,
          y: crop.y - 1,
        });
      }
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      if (HOCrop) {
        setHOCrop({
          ...HOCrop,
          x: HOCrop?.x - 1,
        });
      } else {
        setCrop({
          ...crop,
          x: crop?.x - 1,
        });
      }
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      if (HOCrop) {
        setHOCrop({
          ...HOCrop,
          x: HOCrop?.x + 1,
        });
      } else {
        setCrop({
          ...crop,
          x: crop?.x + 1,
        });
      }
    }

    if (e.key === '+') {
      e.preventDefault();
      if (HOZoom) {
        setHOZoom(HOZoom + 0.1);
      } else {
        setZoom(zoom + 0.1);
      }
    }

    if (e.key === '-') {
      e.preventDefault();
      if (HOZoom) {
        setHOZoom(HOZoom - 0.1);
      } else {
        setZoom(zoom - 0.1);
      }
    }
  }

  return (
    <>
      {width > phoneWidth ? (
        <div className="banners__notice align-center full-width fs16 text-center">
          {textUI(ImageCropperTranslation.screenSizeIncompatible)}
        </div>
      ) : (
        <div
          key={id}
          className={`image-cropper__wrapper ${center ? 'cropper-center' : ''}`}
        >
          {imagePreview && handleImage && (
            <div>
              <div
                className={`cropper__container__container access-ob access-o6`}
                style={customSize}
                onMouseEnter={() => setShowGrid(true)}
                onMouseLeave={() => setShowGrid(false)}
                onTouchStart={() => setShowGrid(true)}
                onTouchEnd={() => setShowGrid(false)}
                tabIndex="0"
                onKeyDown={(e) => keyboardImageControls(e)}
                id={`${id}-cropper-container`}
                aria-label={ImageCropperTranslation.ariaInstructions}
                onBlur={() => setShowGrid(false)}
              >
                <Cropper
                  image={imagePreview}
                  cropSize={{
                    width: width,
                    height: height,
                  }}
                  crop={HOCrop ? HOCrop : crop}
                  onCropChange={
                    !pauseCrop && setHOCrop
                      ? setHOCrop
                      : !pauseCrop
                      ? setCrop
                      : function () {}
                  }
                  onCropAreaChange={onCropComplete}
                  objectFit={cover}
                  onZoomChange={setHOZoom ? setHOZoom : setZoom}
                  zoom={HOZoom ? HOZoom : zoom}
                  minZoom={1}
                  maxZoom={10}
                  zoomSpeed={0.1}
                  showGrid={showGrid}
                  nonce={`${nonce}`}
                />
                {profileFrame && (
                  <img
                    src={profile_frame}
                    alt="frame"
                    className="profile-circle-frame"
                  />
                )}
              </div>

              <button
                className="cropper-button highlight-i-lgr mrg-tb24 access-ob access-o6"
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightRemove(true),
                      () => setTapHighlightRemove(false),
                    ],
                    [() => removeImage()]
                  )
                }
                style={{ width: `${width}px` }}
                type="button"
                aria-label={ImageCropperTranslation.ariaDeleteImage}
                title={ImageCropperTranslation.remove}
              >
                <img
                  src={trash}
                  alt={ImageCropperTranslation.remove}
                  className={`${
                    tapHighlightRemove ? 'filter-red' : 'filter-lightgray'
                  }`}
                />
              </button>
            </div>
          )}

          {!convertingHEIC && (
            <>
              <div
                className={`${
                  imagePreview || convertingHEIC
                    ? 'remove'
                    : `file-uploader__wrapper ${customFileUploaderWrapper}`
                }
          `}
                onClick={() => fileInput.current && fileInput.current.click()}
                onKeyDown={(e) => handleFileUploader(e)}
              >
                <div
                  className={`file-uploader__container ${
                    customFileUploaderContainer
                      ? customFileUploaderContainer
                      : ''
                  }`}
                  style={customSize}
                  tabIndex="0"
                  aria-label={`${ImageCropperTranslation.ariaUploadImageInstructions} ${ariaImageDescription}`}
                  id={`${id}-file-uploader`}
                >
                  <input
                    id={`${id}-uploader`}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) => handleFileInput(e)}
                    ref={fileInput}
                    accept="image/*"
                    enterKeyHint="done"
                  />
                </div>
              </div>
              {!imagePreview && trashButtonSpacerOn && (
                <div className="mrg-tb24" style={{ height: '26px' }} />
              )}
            </>
          )}
          {convertingHEIC && (
            <div className="flex-column fs18 fwsb">
              <Spinner />
              <p className=" mrg-auto-lr access-ob access-o6" tabIndex="0">
                {ImageCropperTranslation.convertingImageType}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ImageCropper;
