//Hooks
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components

//Utility
import { lock_locked } from '../../../../assets/icons';

const HostingInPersonContent = ({
  eventData,
  EventPage,
  eventStatus,
  organizerViewOn,
}) => {
  //Hooks
  const { OnlinePlatformOptions } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //Component Variables
  const privateDetails = eventData.registrationData.privateDetails.enabled;
  const meetingIdLocked =
    eventData.registrationData.privateDetails.virtualMeetingId;
  const passcodeLocked =
    eventData.registrationData.privateDetails.virtualMeetingPasscode;
  const linkLocked =
    eventData.registrationData.privateDetails.virtualMeetingLink;

  let meetingIdInfo;
  let passcodeInfo;
  let linkInfo;

  //JSX conditional

  if (eventData.hosting?.virtual?.meetingId) {
    if (
      privateDetails &&
      meetingIdLocked &&
      eventStatus !== 'registeredInPerson' &&
      eventStatus !== 'registeredVirtual' &&
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
      !organizerViewOn
    ) {
      meetingIdInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.meetingId}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-l12"
            title={EventPage.valueHiddenForNonRegistered}
          />
        </div>
      );
    } else if (organizerViewOn && privateDetails && meetingIdLocked) {
      meetingIdInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.meetingId}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
            title={EventPage.valueHiddenForNonRegistered}
          />
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.virtual.meetingId}
          </p>
        </div>
      );
    } else {
      meetingIdInfo = (
        <p
          className="info-row access-ob access-o6"
          tabIndex="0"
        >{`${EventPage.meetingId}: ${eventData.hosting.virtual.meetingId}`}</p>
      );
    }
  }

  if (eventData.hosting?.virtual?.passcode) {
    if (
      privateDetails &&
      passcodeLocked &&
      eventStatus !== 'registeredInPerson' &&
      eventStatus !== 'registeredVirtual' &&
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
      !organizerViewOn
    ) {
      passcodeInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.passcode}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-l12"
            title={EventPage.valueHiddenForNonRegistered}
          />
        </div>
      );
    } else if (organizerViewOn && privateDetails && passcodeLocked) {
      passcodeInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.passcode}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
            title={EventPage.valueHiddenForNonRegistered}
          />
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.virtual.passcode}
          </p>
        </div>
      );
    } else {
      passcodeInfo = (
        <p
          className="info-row access-ob access-o6"
          tabIndex="0"
        >{`${EventPage.passcode}: ${eventData.hosting.virtual.passcode}`}</p>
      );
    }
  }

  if (eventData.hosting?.virtual?.meetingLink) {
    if (
      privateDetails &&
      linkLocked &&
      eventStatus !== 'registeredInPerson' &&
      eventStatus !== 'registeredVirtual' &&
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
      !organizerViewOn
    ) {
      linkInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.link}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-l12"
            title={EventPage.valueHiddenForNonRegistered}
          />
        </div>
      );
    } else if (organizerViewOn && privateDetails && linkLocked) {
      linkInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.link}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
            title={EventPage.valueHiddenForNonRegistered}
          />
          <p className="previewMode-border outline-offset-2">{`${eventData.hosting.virtual.meetingLink}`}</p>
        </div>
      );
    } else {
      linkInfo = (
        <p
          className="info-row access-ob access-o6"
          tabIndex="0"
        >{`${EventPage.link}: ${eventData.hosting.virtual.meetingLink}`}</p>
      );
    }
  }

  return (
    <>
      <div className="h24" />
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="virtualAttendanceLabel"
      >
        {EventPage.virtualAttendance}
      </label>
      <div className="flex-column">
        <p className="info-row access-ob access-o6" tabIndex="0">
          {OnlinePlatformOptions[eventData.hosting?.virtual?.platform]}
        </p>
        {meetingIdInfo}
        {passcodeInfo}
        {linkInfo}
        <div className="h24" />
      </div>
    </>
  );
};

export default HostingInPersonContent;
