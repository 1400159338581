//hooks

//components

import LoadBucketImage from '../../../../../../components/LoadBucketImage/LoadBucketImage';

//utils

const InfoCardContainerEventImages = ({ eventImagesData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Event Images</p>
      <div className="scale-image-down">
        <p className="fs18 fwsb mrg-t24 mrg-b6">Main Display: </p>
        <LoadBucketImage
          imagePath={eventImagesData?.eventImageDisplay?.imagePath}
          imgClassName="ec-display-image"
          backupPath={'event_img_default.png'}
          partialPath={'myndfull_event_images'}
        />
      </div>
    </div>
  );
};

export default InfoCardContainerEventImages;
