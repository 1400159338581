//hooks

//components

//utils
import { textUI } from '../../../utils/UI/textUI';

const ProfileBio = ({ ProfilePersonal, profile }) => {
  //hooks

  return (
    <>
      {profile?.personal?.bio && (
        <>
          <label
            className="content-heading-styled text-gray mrg-b12"
            tabIndex="0"
            id="tagsLabel"
          >
            {ProfilePersonal.bio}
          </label>
          <div className="h12" />
          {textUI(profile?.personal?.bio)}
          <div className="h36" />
        </>
      )}
    </>
  );
};

export default ProfileBio;
