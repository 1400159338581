import { useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../context/useSettings';
import { useTranslationRoomSocket } from '../../../context/sockets/TranslationRoomSocketProvider';

//Components
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import TranslationInitialize from './Content/TranslationInitialize';
import TranslationCreateRoom from './Content/CreateRoom/TranslationCreateRoom';
import TranslationJoinRoom from './Content/JoinRoom/TranslationJoinRoom';
import Spinner from '../../../components/Spinner/Spinner';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';

const Translations = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const { translationRoomSocketStatus, closeTranslationRoomSocket } =
    useTranslationRoomSocket();

  //Component state
  const [page, setPage] = useState(1);
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [isLoadingTranslationPage, setIsLoadingTranslationPage] =
    useState(false);

  //Initialize
  useEffect(() => {
    setNavbar('translations');

    return () => {
      closeTranslationRoomSocket();
    };
  }, []);

  //UI
  useEffect(() => {
    ScrollTo('', 'auto', true);
    if (translationRoomSocketStatus !== 'closed') {
      closeTranslationRoomSocket();
    }

    if (page === 1) {
      setEventPageLoadComplete(false);
    }
    if (page !== 1) {
      setIsLoadingTranslationPage(true);
    }
  }, [page]);

  useEffect(() => {
    const pageTransitionTimer = setTimeout(() => {
      setIsLoadingTranslationPage(false);
    }, 500);

    return () => clearTimeout(pageTransitionTimer);
  }, [isLoadingTranslationPage]);

  return (
    <>
      {eventPageLoadComplete && page === 1 && (
        <TranslationInitialize setPage={setPage} />
      )}
      {eventPageLoadComplete && !isLoadingTranslationPage && page === 2 && (
        <TranslationJoinRoom setPage={setPage} />
      )}
      {eventPageLoadComplete && !isLoadingTranslationPage && page === 3 && (
        <TranslationCreateRoom setPage={setPage} />
      )}
      {isLoadingTranslationPage && (
        <Spinner minHeight={'100%'} minWidth={'100%'} />
      )}
      {!eventPageLoadComplete && (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setEventPageLoadComplete(true)}
          loadCheck1={true}
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default Translations;
