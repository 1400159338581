import { useEffect, useState } from 'react';

//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

//components
import DispatchesInvitationStatus from './DispatchesInvitationStatus';

//utils

const ParticipantListItemStatusDispatches = ({
  p,
  totalDispatchesAvailable,
  totalCurrentDispatches,
  allDispatchesIssued,
  translationsEnabled,
}) => {
  //hooks
  const { ParticipantListItem } = useLanguageOrganize();

  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.emailInvitation?.tokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <DispatchesInvitationStatus
            p={p}
            invitationHasExpired={invitationHasExpired}
            allDispatchesIssued={allDispatchesIssued}
            translationsEnabled={translationsEnabled}
          />
          {totalCurrentDispatches > 0 &&
            ` ${totalCurrentDispatches} / ${totalDispatchesAvailable}`}
        </div>
      )}
      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row fs16">
          {ParticipantListItem[p.organizedAs]} |{' '}
          <DispatchesInvitationStatus
            p={p}
            invitationHasExpired={invitationHasExpired}
            allDispatchesIssued={allDispatchesIssued}
            translationsEnabled={translationsEnabled}
          />
          {totalCurrentDispatches > 0 &&
            ` ${totalCurrentDispatches} / ${totalDispatchesAvailable}`}
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusDispatches;
