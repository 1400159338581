import React, { useState } from 'react';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';
import useGenericModal from '../../context/useGenericModal';

//Components
import DragDropTextarea from './DragDropTextarea';
import IconSelectModal from '../Modal/ModalComponents/IconSelectModal/IconSelectModal';

//Utility
import { trash, arrow_long } from '../../assets/icons/index';
import { getAgendaItemIcon } from '../../utils/images/agendaItems';

const MemoizedListItem = React.memo(function ListItem({
  item,
  items,
  handleItemChange,
  index,
  handleMoveItemUp,
  handleMoveItemDown,
  dragOverIndex,
  draggingItem,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleDragEnter,
  handleDragEnd,
  handleRemoveItem,
  toggle,
  charLimit,
  title,
}) {
  //Hooks
  const { DragDropList } = useLanguageComponents();
  const { handleMobileTap, windowSettingsSize, dismissKeypad, width } =
    useSettings();
  const { setGenericModalMobileFloat } = useGenericModal();
  const { IconAlts, AgendaIconAlts } = useLanguageComponents();

  //UI state
  const [tapHighlightRemove, setTapHighlightRemove] = useState(false);
  const [tapHighlightUpArrow, setTapHighlightUpArrow] = useState(false);
  const [tapHighlightDownArrow, setTapHighlightDownArrow] = useState(false);

  //Component variables
  let numberInput = document.getElementById(`number-input-${index}`);
  if (numberInput) {
    numberInput.readOnly = false;
  }
  if (numberInput) {
    numberInput.addEventListener('input', () => {
      let value = numberInput.value;
      if (value.length > 3) {
        numberInput.value = value.slice(0, 3);
      } else if (value > 999) {
        numberInput.value = 999;
      } else if (value < 0) {
        numberInput.value = 0;
      }
    });
  }

  //Component functions
  function selectText(id) {
    const el = document.getElementById(id);
    el.focus();
    el.select();
  }

  function handleItemTextChange(text, index) {
    let newItems = [...items];
    newItems[index] = { ...newItems[index], text };
    handleItemChange(newItems);
  }

  function handleItemValueChange(e, index) {
    e.preventDefault();
    let newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      value: e.target.value,
    };
    handleItemChange(newItems);
  }

  function handleStartTimeChange(e, index) {
    let time = e.target.value;
    const newItems = items.map((item, i) =>
      i === index ? { ...item, startTime: time } : item
    );
    handleItemChange(newItems);
  }

  function handleEndTimeChange(e, index) {
    let time = e.target.value;
    const newItems = items.map((item, i) =>
      i === index ? { ...item, endTime: time } : item
    );
    handleItemChange(newItems);
  }

  function handleSelectIcon(index) {
    function selectIcon(iconCode, itemIndex) {
      const newItems = items.map((item, i) =>
        i === itemIndex ? { ...item, icon: iconCode } : item
      );
      handleItemChange(newItems);
    }

    setGenericModalMobileFloat(
      <IconSelectModal
        closeModalFocusId={`${index}-iconSelect-btn`}
        handleSelectIcon={selectIcon}
        itemIndex={index}
        currentItemTitle={item?.text}
      />
    );
  }

  return (
    <li
      id={item.id}
      className={`memoized-item__wrapper bg-color-light border-thin mrg-b24 access-ob access-o6 ${
        dragOverIndex === index ? 'placeholder' : ''
      } ${
        tapHighlightUpArrow || tapHighlightDownArrow
          ? 'highlight-b-theme--tap'
          : ''
      }`}
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={(e) => handleDragOver(e, index)}
      onDrop={(e) => handleDrop(e)}
      onDragEnter={(e) => handleDragEnter(e, index)}
      onDragEnd={(e) => handleDragEnd(e, index)}
      onDragLeave={null}
      draggable={
        windowSettingsSize === 'desktop' ||
        windowSettingsSize === 'desktop--narrow'
          ? true
          : false
      }
      tabIndex="0"
      aria-label={`${title}.  ${item.text}.`}
      key={item.id}
    >
      <div
        className={
          dragOverIndex === index
            ? 'memoized-item--selected'
            : 'memoized-item__container'
        }
      >
        <div className="move-arrows no-select">
          <button
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightUpArrow(true),
                  () => setTapHighlightUpArrow(false),
                ],
                [() => handleMoveItemUp(index, item)]
              )
            }
            tabIndex={index === 0 ? '-1' : '0'}
            className={`highlight-i-lgt access-ob ${
              tapHighlightUpArrow ? 'filter-theme' : ''
            }`}
          >
            <img
              src={arrow_long}
              alt={DragDropList.moveUp}
              className={`${index === 0 ? 'arrow-up hidden' : 'arrow-up'}`}
            />
          </button>
          <button
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightDownArrow(true),
                  () => setTapHighlightDownArrow(false),
                ],
                [() => handleMoveItemDown(index, item)]
              )
            }
            tabIndex={index === items.length - 1 ? '-1' : '0'}
            className={`highlight-i-lgt access-ob ${
              tapHighlightDownArrow ? 'filter-theme' : ''
            }`}
          >
            <img
              src={arrow_long}
              alt={DragDropList.moveDown}
              className={`${
                index === items.length - 1 ? 'arrow-down hidden' : 'arrow-down'
              }`}
            />
          </button>
        </div>
        <div className="memoized-item-fields__wrapper">
          <div className="memoized-item-fields__container">
            <div className="agenda-icon-row">
              {width <= 824 && (
                <p className="fs18 mrg-r12">{DragDropList.icon}</p>
              )}
              <button
                className="ddl-select-icon-btn highlight-bthin-theme fs18 access-ob access-o6"
                tabIndex="0"
                onClick={() => handleSelectIcon(index)}
                id={`${index}-iconSelect-btn`}
              >
                {!item.icon ? (
                  <p className="flex flex-center mrg-auto fs18">/</p>
                ) : (
                  <img
                    className="filter-gray"
                    src={getAgendaItemIcon(item.icon)}
                    alt={AgendaIconAlts[item.icon]}
                  />
                )}
              </button>
              {width > 824 && (
                <p className="fs18 mrg-l12 mrg-r24">{DragDropList.icon}</p>
              )}
            </div>
            {toggle === 'minutes' && (
              <div
                className="minutes-input"
                onClick={() => selectText(`number-input-${index}`)}
              >
                <input
                  type="number"
                  maxLength="3"
                  max="999"
                  min="0"
                  onChange={(e) => handleItemValueChange(e, index)}
                  onKeyDown={(e) => dismissKeypad(e)}
                  placeholder="0"
                  id={`number-input-${index}`}
                  defaultValue={item.value ? item.value : 0}
                  aria-label={DragDropList.minutes}
                  className="fs16 highlight-breg-theme"
                  enterKeyHint="done"
                />
                <p className="fs18 mrg-l12">{DragDropList.minutes}</p>
              </div>
            )}
            {width > 824 && toggle !== 'minutes' && (
              <div className="flex-row align-center">
                <div className="">
                  <input
                    type="time"
                    id={`startTime-${index}`}
                    onChange={(e) => handleStartTimeChange(e, index)}
                    onKeyDown={(e) => dismissKeypad(e)}
                    value={item.startTime}
                    aria-label={DragDropList.startTime}
                    className="highlight-breg-theme"
                    enterKeyHint="done"
                  />
                </div>
                <div className="clocks-dash" />
                <div className="">
                  <input
                    type="time"
                    id={`endTime-${index}`}
                    className="highlight-breg-theme"
                    onChange={(e) => handleEndTimeChange(e, index)}
                    onKeyDown={(e) => dismissKeypad(e)}
                    value={item.endTime}
                    aria-label={DragDropList.endTime}
                    enterKeyHint="done"
                  />
                </div>
              </div>
            )}

            <div className="flex-row full-width">
              <button
                className={`trashbin-ddl highlight-i-lgr flex-center access-ob access-o6 ${
                  tapHighlightRemove ? 'highlight-i-lgr--tap' : ''
                }`}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightRemove(true),
                      () => setTapHighlightRemove(false),
                    ],
                    [() => handleRemoveItem(index)]
                  )
                }
                title={DragDropList.remove}
                tabIndex="0"
                aria-label={DragDropList.remove + title}
              >
                <img src={trash} alt={IconAlts.iconTrash} />
              </button>
            </div>
          </div>

          {width <= 824 && toggle !== 'minutes' && (
            <>
              <div className="clock-container ">
                <p className="fs18">Start Time</p>
                <input
                  type="time"
                  id={`startTime-${index}`}
                  onChange={(e) => handleStartTimeChange(e, index)}
                  onKeyDown={(e) => dismissKeypad(e)}
                  value={item.startTime}
                  aria-label={DragDropList.startTime}
                  className="highlight-breg-theme"
                  enterKeyHint="done"
                />
              </div>
              <div className="clock-container mrg-tb12 ">
                <p className="fs18">End Time</p>
                <input
                  type="time"
                  id={`endTime-${index}`}
                  className="highlight-breg-theme"
                  onChange={(e) => handleEndTimeChange(e, index)}
                  onKeyDown={(e) => dismissKeypad(e)}
                  value={item.endTime}
                  aria-label={DragDropList.endTime}
                  enterKeyHint="done"
                />
              </div>
            </>
          )}

          <DragDropTextarea
            item={item}
            index={index}
            handleItemTextChange={handleItemTextChange}
            maxChar={charLimit}
          />
        </div>
      </div>
    </li>
  );
});

export default MemoizedListItem;
