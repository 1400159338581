import { useEffect, useRef, useState } from 'react';

//hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';

//components

//utils
import { visible, visible_off } from '../../assets/icons';
import handleInputClick from './handleInputClick';

const PasswordInput = ({
  handleInput,
  customKeyDownHandler,
  id,
  preventDefaultOff,
  resetTrigger,
  defaultValue,
  customTitle,
  noAsterisk,
  customWrapper,
  maxLength,
}) => {
  //hooks
  const { PasswordInputs } = useLanguageComponents();
  const { dismissKeypad, handleMobileTap } = useSettings();

  //state
  const [password, setPassword] = useState();

  //UI
  const [showPassword, setShowPassword] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);
  const [tapHighlightShowPassword, setTapHighlightShowPassword] =
    useState(false);

  //variables
  const passwordRef = useRef();

  //functions
  useEffect(() => {
    handleInput(password);
  }, [password]);

  useEffect(() => {
    if (!defaultValue) {
      setPassword('');
      passwordRef.current.value = '';
    } else {
      setPassword(defaultValue);
      passwordRef.current.value = defaultValue;
    }
  }, [resetTrigger]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      if (!preventDefaultOff) {
        e.preventDefault();
      }
    }
    if (customKeyDownHandler) {
      customKeyDownHandler(e);
    }
    dismissKeypad(e);
  }

  return (
    <div
      className={`text-input__wrapper ${customWrapper ? customWrapper : ''}`}
      onMouseEnter={() => setBorderHighlight(true)}
      onMouseLeave={() => setBorderHighlight(false)}
    >
      <label
        id={`password-label`}
        className="text-input-label no-select"
        onClick={() => handleInputClick(passwordRef.current)}
      >
        <p>
          {!customTitle ? PasswordInputs.password : customTitle}&nbsp;
          <span className="highlight">{!noAsterisk && '*'}</span>
        </p>
      </label>

      <input
        id={id ? id : 'password'}
        type={!showPassword ? 'password' : 'text'}
        className={`text-input text-input-password ${
          borderHighlight ? 'highlight-o-theme--tap' : ''
        } ${
          focusHighlight
            ? 'highlight-ob-theme--thin--active'
            : 'highlight-ob-theme--thin'
        }`}
        ref={passwordRef}
        defaultValue=""
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e, 'password')}
        autoComplete="new-password"
        aria-label={
          `${PasswordInputs.currentPassword}` +
          `${PasswordInputs.ariaFieldIsRequired}`
        }
        maxLength={maxLength ? maxLength : '255'}
        tabIndex="0"
        enterKeyHint="done"
        onBlur={() => setFocusHighlight(false)}
        onFocus={() => setFocusHighlight(true)}
      />
      <button
        className={`highlight-i-lgt access-ob access-o6 ${
          showPassword ? 'show-password-btn' : 'show-password-btn visible-off'
        } ${tapHighlightShowPassword ? 'highlight-i-theme--tap' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightShowPassword(true),
              () => setTapHighlightShowPassword(false),
            ],
            [() => setShowPassword(!showPassword)]
          )
        }
        tabIndex="0"
        type="button"
        id={`show-password`}
      >
        {showPassword ? (
          <img src={visible} alt={PasswordInputs.showPasswordOn} />
        ) : (
          <img src={visible_off} alt={PasswordInputs.showPasswordOff} />
        )}
      </button>
    </div>
  );
};

export default PasswordInput;
