import { useState, useEffect } from 'react';

//hooks
import { useUpdateRolesMutation } from '../../../accountApiSlice';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//components
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utility

const OrganizerRoleToggle = () => {
  //hooks
  const {
    accountId,
    accountOrganizerOrganizerModeActive: loadOrganizerModeActive,
  } = useAccountReduxHandlers();
  const { OrganizerRoleToggle } = useLanguageAccount();

  //api
  const [updateRoles, { isLoading: updateAccountRolesIsLoading }] =
    useUpdateRolesMutation();

  //component state
  const [rolesChangeValid, setRolesChangeValid] = useState(false);
  const [newOrganizerModeActive, setNewOrganizerModeActive] = useState(
    loadOrganizerModeActive
  );

  //API functions
  async function handleSaveRoleChange() {
    if (rolesChangeValid) {
      if (!updateAccountRolesIsLoading) {
        await updateRoles({
          accountId,
          newData: {
            organizerRoleChange: newOrganizerModeActive,
          },
        });
        setRolesChangeValid(false);
      }
    }
  }

  useEffect(() => {
    if (newOrganizerModeActive !== loadOrganizerModeActive) {
      setRolesChangeValid(true);
    } else {
      setRolesChangeValid(false);
    }
  }, [newOrganizerModeActive, loadOrganizerModeActive]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="organizerActivationLabel"
      >
        {OrganizerRoleToggle.organizerActivation}
      </label>

      <ButtonSwitch
        id={'organizerToggle'}
        loadValue={loadOrganizerModeActive}
        loadTrigger={true}
        handleSwitch={() => setNewOrganizerModeActive(!newOrganizerModeActive)}
        customWrapper={'mrg-t12'}
      />
      <div className="h24" />

      <SaveButton
        saveValid={rolesChangeValid}
        saveAriaLanguage={OrganizerRoleToggle.organizerActivation}
        handler={handleSaveRoleChange}
        isLoading={updateAccountRolesIsLoading}
      />
      <div className="h36" />
    </div>
  );
};

export default OrganizerRoleToggle;
