import { useEffect, useState } from 'react';

//hooks
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//components

//utils
import { lock_locked } from '../../../../assets/icons';

const EventPageTranslations = ({
  eventData,
  EventPage,
  eventStatus,
  organizerViewOn,
}) => {
  //hooks
  const { IconAlts } = useLanguageComponents();
  const { TranslationLanguageOpts } = useLanguageData();
  const { accountTranslationRoomsRoomCodes } = useAccountReduxHandlers();
  //state
  const [roomCode, setRoomCode] = useState();

  useEffect(() => {
    if (organizerViewOn && accountTranslationRoomsRoomCodes) {
      const newRoomCode = accountTranslationRoomsRoomCodes?.find((obj) => {
        if (
          obj?._id?.toString() ===
          eventData?.translations?.roomCodeId?.toString()
        ) {
          return obj?.roomCode;
        }
      });
      setRoomCode(newRoomCode?.roomCode);
    }
  }, [organizerViewOn, eventData]);

  //variables
  let translationsRoomCodeInfo;
  let translationsRoomPasswordInfo;
  //
  const privateDetailsEnabled =
    eventData.registrationData.privateDetails.enabled;
  const translationsRoomCodeLocked =
    eventData.registrationData.privateDetails.translationsRoomCode;
  const translationsRoomPasswordLocked =
    eventData.registrationData.privateDetails.translationsRoomPassword;

  if (eventData?.translations?.roomCode || roomCode) {
    if (
      privateDetailsEnabled &&
      translationsRoomCodeLocked &&
      eventStatus !== 'registeredInPerson' &&
      eventStatus !== 'registeredVirtual' &&
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
      !organizerViewOn
    ) {
      translationsRoomCodeInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.roomCode}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-l12"
            title={EventPage.valueHiddenForNonRegistered}
          />
        </div>
      );
    } else if (
      organizerViewOn &&
      privateDetailsEnabled &&
      translationsRoomCodeLocked
    ) {
      translationsRoomCodeInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.roomCode}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
            title={EventPage.valueHiddenForNonRegistered}
          />

          <p className="previewMode-border outline-offset-2">
            {eventData?.translations?.roomCode || roomCode}
          </p>
        </div>
      );
    } else {
      translationsRoomCodeInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.roomCode}:</p>
          <p className="mrg-l6">
            {eventData?.translations?.roomCode || roomCode}
          </p>
        </div>
      );
    }
  }

  if (
    eventData?.translations?.joinRoomPasswordToggle &&
    eventData?.translations?.password?.length > 0
  ) {
    if (
      privateDetailsEnabled &&
      translationsRoomPasswordLocked &&
      eventStatus !== 'registeredInPerson' &&
      eventStatus !== 'registeredVirtual' &&
      eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
      eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
      !organizerViewOn
    ) {
      translationsRoomPasswordInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.attendeePassword}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-l12"
            title={EventPage.valueHiddenForNonRegistered}
          />
        </div>
      );
    } else if (
      organizerViewOn &&
      privateDetailsEnabled &&
      translationsRoomPasswordLocked
    ) {
      translationsRoomPasswordInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.attendeePassword}</p>

          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
            title={EventPage.valueHiddenForNonRegistered}
          />

          <p className="previewMode-border outline-offset-2">
            {eventData?.translations?.password}
          </p>
        </div>
      );
    } else {
      translationsRoomPasswordInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.attendeePassword}:</p>
          <p className="mrg-l6">{eventData?.translations?.password}</p>
        </div>
      );
    }
  }

  return (
    <>
      <div className="h24" />
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="translationsLabel"
      >
        {EventPage.myndfullTranslations}
      </label>
      {translationsRoomCodeInfo}
      {translationsRoomPasswordInfo}
      <div className="info-column access-ob access-o6" tabIndex="0">
        {EventPage.availableLanguages}:
        <ul className="flex-column mrg-t12">
          {eventData?.translations?.roomLanguages?.map((lang, index) => {
            return (
              <li
                className="full-width mrg-l24 mrg-b6"
                key={`translationLang-${index}`}
              >
                {TranslationLanguageOpts[lang]}
              </li>
            );
          })}
        </ul>
        <div className="h24" />
      </div>
      <div className="h24" />
    </>
  );
};

export default EventPageTranslations;

{
  /* <ButtonTextSimple
  text={'View'}
  icon={visible_off}
  iconOn={true}
  iconRight={true}
  iconStyle={{ height: '18px' }}
  iconClass={'mrg-l6 filter-gray'}
  customWrapper={'mrg-l12 text-gray'}
  handler={() => function () {}}
/>; */
}
