import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility

//Designers note: Edit Event slice not redundant over create event slice, because create event slice restore cant be overriden

const initialState = {
  editEventChangeList: [],
  contact: {
    provideContact: false,
    contactEmail: '',
    contactPhoneNumber: '',
    contactCountryCode: '',
  },
  dateTime: {
    date: null,
    timeStart: null,
    timeEnd: null,
    timeZone: null,
    dateTimeStartLocal: null,
    dateTimeStartUTC: null,
    dateTimeEndLocal: null,
    dateTimeEndUTC: null,
    tzdb: null,
  },
  generic: {
    agenda: {
      format: 'minutes',
      items: [],
    },
    accessibilityNotes: '',
    audienceType: null,
    description: '',
    eventImageDisplay: {
      imagePath: null,
      alt: '',
    },
    eventAdditionalImages: [],
    eventType: null,
    languages: ['select'],
    signLanguageInterpreter: false,
    title: '',
    wheelchairAccessibility: false,
  },
  hosting: {
    hostingOption: null,
    inPerson: {
      geoPosition: {
        type: 'Point',
        coordinates: [0, 0],
      },
      location: {
        city: '',
        country: '',
        locationName: '',
        placeId: '',
        postalCode: '',
        room: '',
        streetAddress: '',
      },
    },
    virtual: {
      meetingId: '',
      passcode: '',
      platform: '',
      meetingLink: '',
    },
  },
  meta: {
    lastModified: '',
    saveName: '',
    _id: '',
  },
  participants: [],
  registrationData: {
    registrationsActive: false,
    approveRegistrations: false,
    maxRegistrationsInPersonEnabled: false,
    maxRegistrationsInPersonAmount: 0,
    maxRegistrationsVirtualEnabled: false,
    maxRegistrationsVirtualAmount: 0,
    privateDetails: {
      enabled: false,
      room: false,
      locationName: false,
      streetAddress: false,
      virtualMeetingId: false,
      virtualMeetingPasscode: false,
      virtualMeetingLink: false,
      translationsRoomCode: false,
      translationsRoomPassword: false,
      contact: false,
    },
  },
  schedule: {
    enabled: false,
    publishDate: null,
    publishTime: null,
    publishTimeZone: null,
    publishDateTimeLocal: null,
    publishDateTimeUTC: null,
    tzdb: null,
  },
  tags: {
    bigTags: [],
    tinyTags: [],
  },
  translations: {
    translationsEnabled: false,
    roomCodeId: null,
    joinRoomPasswordToggle: false,
    password: '',
    roomLanguages: [],
  },
};

const editEventSlice = createSlice({
  name: 'editEvent',
  initialState: initialState,
  reducers: {
    setEditEventChangeList: (state, action) => {
      state.editEventChangeList = action.payload;
    },

    //contact
    setEditEventContactProvideContact: (state, action) => {
      state.contact.provideContact = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventContactEmail: (state, action) => {
      state.contact.contactEmail = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventContactPhoneNumber: (state, action) => {
      state.contact.contactPhoneNumber = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventContactCountryCode: (state, action) => {
      state.contact.contactCountryCode = action.payload;
      state.meta.lastModified = Date.now();
    },

    //dateTime
    setEditEventDateTimeDate: (state, action) => {
      state.dateTime.date = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeEnd: (state, action) => {
      state.dateTime.timeEnd = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeStart: (state, action) => {
      state.dateTime.timeStart = action.payload;
      state.dateTime.timeEnd = null;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeZone: (state, action) => {
      state.dateTime.timeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Generic
    setEditEventGenericAccessibilityNotes: (state, action) => {
      state.generic.accessibilityNotes = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAgendaItems: (state, action) => {
      //read only Typeerror from strict mode avoided with code below, need to spread nested
      const newItems = [...action.payload.map((item) => ({ ...item }))];
      state.generic.agenda.items = newItems;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAgendaFormat: (state, action) => {
      state.generic.agenda.format = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAudienceType: (state, action) => {
      state.generic.audienceType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericDescription: (state, action) => {
      state.generic.description = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericEventType: (state, action) => {
      state.generic.eventType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericEventImageDisplay: (state, action) => {
      state.generic.eventImageDisplay = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericEventAdditionalImages: (state, action) => {
      state.generic.eventAdditionalImages = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericLanguages: (state, action) => {
      state.generic.languages = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericSignLanguageInterpreter: (state, action) => {
      state.generic.signLanguageInterpreter = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericTitle: (state, action) => {
      state.generic.title = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericWheelchairAccessibility: (state, action) => {
      state.generic.wheelchairAccessibility = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Hosting
    setEditEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = action.payload;
      state.meta.lastModified = Date.now();
    },
    reverseEditEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = [
        state.hosting.inPerson.geoPosition.coordinates[1],
        state.hosting.inPerson.geoPosition.coordinates[0],
      ];
      //dont modify save
    },
    setEditEventHostingHostingOption: (state, action) => {
      state.hosting.hostingOption = action.payload;
      if (!action.payload) {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
      }
      if (action.payload === 'online') {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
      }
      if (action.payload === 'inPerson') {
        state.registrationData.maxRegistrationsVirtualEnabled = false;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingInPersonLocation: (state, action) => {
      state.hosting.inPerson.location = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualMeetingId: (state, action) => {
      state.hosting.virtual.meetingId = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualPasscode: (state, action) => {
      state.hosting.virtual.passcode = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualPlatform: (state, action) => {
      state.hosting.virtual.platform = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualLink: (state, action) => {
      state.hosting.virtual.meetingLink = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Meta
    setEditEventMetaSaveName: (state, action) => {
      state.meta.saveName = action.payload;
    },

    //Participants
    setEditEventParticipants: (state, action) => {
      state.participants = action.payload;
      state.meta.lastModified = Date.now();
    },

    //RegistrationData
    setEditEventRegistrationDataRegistrationsActive: (state, action) => {
      state.registrationData.registrationsActive = action.payload;
      if (action.payload === false) {
        state.registrationData.approveRegistrations = false;
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
        state.registrationData.maxRegistrationsInPersonAmount = 0;
        state.registrationData.maxRegistrationsVirtualAmount = 0;
        state.registrationData.privateDetails = {
          enabled: false,
          room: false,
          locationName: false,
          streetAddress: false,
          virtualMeetingId: false,
          virtualMeetingPasscode: false,
          virtualMeetingLink: false,
          translationsRoomCode: false,
          translationsRoomCodePassword: false,
          contact: false,
        };
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataApproveRegistrations: (state, action) => {
      state.registrationData.approveRegistrations = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsInPersonEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsInPersonAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsVirtualEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsVirtualAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },

    setEditEventRegistrationDataPrivateDetailsEnabled: (state, action) => {
      state.registrationData.privateDetails.enabled = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsInPersonAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsVirtualAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataPrivateDetails: (state, action) => {
      state.registrationData.privateDetails = {
        ...state.registrationData.privateDetails,
        [action.payload.id]: action.payload.val,
      };
      state.meta.lastModified = Date.now();
    },

    //SchedulePublish
    setEditEventScheduleEnabled: (state, action) => {
      if (action.payload === false) {
        state.schedule.enabled = false;
        state.schedule.publishDate = null;
        state.schedule.publishTime = null;
        state.schedule.publishTimeZone = null;
        state.schedule.publishDateTimeLocal = null;
        state.schedule.publishDateTimeUTC = null;
        state.schedule.tzdb = null;
      } else {
        state.schedule.enabled = true;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishDate: (state, action) => {
      state.schedule.publishDate = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishTime: (state, action) => {
      state.schedule.publishTime = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishTimeZone: (state, action) => {
      state.schedule.publishTimeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Tags
    addEditEventTagsBigTag: (state, action) => {
      state.tags.bigTags.push(action.payload);
      state.meta.lastModified = Date.now();
    },
    addEditEventTagsTinyTag: (state, action) => {
      if (state.tags.tinyTags.length < 20) {
        state.tags.tinyTags = state.tags.tinyTags.concat([action.payload]);
        state.meta.lastModified = Date.now();
      }
    },
    removeEditEventTagsBigTag: (state, action) => {
      state.tags.bigTags = state.tags.bigTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    removeEditEventTagsTinyTag: (state, action) => {
      state.tags.tinyTags = state.tags.tinyTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    setEditEventTagsBigTags: (state, action) => {
      state.tags.bigTags = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTagsTinyTags: (state, action) => {
      state.tags.tinyTags = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Translations
    setEditEventTranslationsTranslationsEnabled: (state, action) => {
      state.translations.translationsEnabled = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTranslationsRoomCodeId: (state, action) => {
      state.translations.roomCodeId = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTranslationsJoinRoomPasswordToggle: (state, action) => {
      state.translations.joinRoomPasswordToggle = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTranslationsPassword: (state, action) => {
      state.translations.password = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTranslationsRoomLanguages: (state, action) => {
      state.translations.roomLanguages = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Set/Reset
    resetEditEvent: (state) => {
      return initialState;
    },
    setEditEvent: (state, action) => {
      return {
        ...state,
        contact: {
          provideContact: action.payload.contact?.provideContact || false,
          contactEmail: action.payload.contact?.contactEmail || '',
          contactPhoneNumber: action.payload.contact?.contactPhoneNumber || '',
          contactCountryCode: action.payload.contact?.contactCountryCode || '',
        },
        dateTime: {
          date: action.payload.dateTime.date || null,
          timeStart: action.payload.dateTime.timeStart || null,
          timeEnd: action.payload.dateTime.timeEnd || null,
          timeZone: action.payload.dateTime.timeZone || null,
          dateTimeStartLocal:
            action.payload.dateTime.dateTimeStartLocal || null,
          dateTimeStartUTC: action.payload.dateTime.dateTimeStartUTC || null,
          dateTimeEndLocal: action.payload.dateTime.dateTimeEndLocal || null,
          dateTimeEndUTC: action.payload.dateTime.dateTimeEndUTC || null,
          tzdb: action.payload.dateTime.tzdb || null,
        },
        generic: {
          agenda: {
            format: action.payload.generic.agenda.format || 'minutes',
            items:
              action.payload.generic.agenda.items.map((item) => ({
                id: item.id,
                text: item.text || '',
                value: item.value || 0,
                startTime: item.startTime || '00:00',
                endTime: item.endTime || '00:00',
              })) || [],
          },
          accessibilityNotes: action.payload.generic.accessibilityNotes || '',
          audienceType: action.payload.generic.audienceType || null,
          description: action.payload.generic.description || '',
          eventImageDisplay: {
            imagePath:
              action.payload.generic.eventImageDisplay.imagePath || null,
            alt: action.payload.generic.eventImageDisplay.alt || '',
          },
          eventAdditionalImages:
            action.payload.generic.eventAdditionalImages || [],
          eventType: action.payload.generic.eventType || null,
          languages: action.payload.generic.languages || [],
          signLanguageInterpreter:
            action.payload.generic.signLanguageInterpreter || false,
          title: action.payload.generic.title || '',
          wheelchairAccessibility:
            action.payload.generic.wheelchairAccessibility || false,
        },
        hosting: {
          hostingOption: action.payload.hosting.hostingOption || null,
          inPerson: {
            geoPosition: {
              type: 'Point',
              coordinates: action.payload.hosting?.inPerson.geoPosition
                .coordinates || [0, 0],
            },
            location: {
              city: action.payload.hosting?.inPerson?.location?.city || '',
              country:
                action.payload.hosting?.inPerson?.location?.country || '',
              locationName:
                action.payload.hosting?.inPerson?.location?.locationName || '',
              placeId:
                action.payload.hosting?.inPerson?.location?.placeId || '',
              postalCode:
                action.payload.hosting?.inPerson?.location?.postalCode || '',
              room: action.payload.hosting?.inPerson?.location?.room || '',
              streetAddress:
                action.payload.hosting?.inPerson?.location?.streetAddress || '',
            },
          },
          virtual: {
            meetingId: action.payload.hosting?.virtual?.meetingId || '',
            passcode: action.payload.hosting?.virtual?.passcode || '',
            platform: action.payload.hosting?.virtual?.platform || '',
            meetingLink: action.payload.hosting?.virtual?.meetingLink || '',
          },
        },
        meta: {
          _id: action.payload._id,
          lastModified: action.payload.meta.lastModified,
        },
        participants: action.payload.participants || [],
        registrationData: {
          registrationsActive:
            action.payload.registrationData.registrationsActive || false,
          approveRegistrations:
            action.payload.registrationData.approveRegistrations || false,
          maxRegistrationsInPersonEnabled:
            action.payload.registrationData.maxRegistrationsInPersonEnabled ||
            false,
          maxRegistrationsInPersonAmount:
            action.payload.registrationData.maxRegistrationsInPersonAmount || 0,
          maxRegistrationsVirtualEnabled:
            action.payload.registrationData.maxRegistrationsVirtualEnabled ||
            false,
          maxRegistrationsVirtualAmount:
            action.payload.registrationData.maxRegistrationsVirtualAmount || 0,
          privateDetails: {
            enabled:
              action.payload.registrationData.privateDetails.enabled || false,
            room: action.payload.registrationData.privateDetails.room || false,
            locationName:
              action.payload.registrationData.privateDetails.locationName ||
              false,
            streetAddress:
              action.payload.registrationData.privateDetails.streetAddress ||
              false,
            virtualMeetingId:
              action.payload.registrationData.privateDetails.virtualMeetingId ||
              false,
            virtualMeetingPasscode:
              action.payload.registrationData.privateDetails
                .virtualMeetingPasscode || false,
            virtualMeetingLink:
              action.payload.registrationData.privateDetails
                .virtualMeetingLink || false,
            translationsRoomCode:
              action.payload.registrationData.privateDetails
                .translationsRoomCode || false,
            translationsRoomPassword:
              action.payload.registrationData.privateDetails
                .translationsRoomPassword || false,
            contact: action.payload.registrationData.contact || false,
          },
        },
        schedule: {
          enabled: action.payload?.schedule?.enabled || false,
          publishDate: action.payload?.schedule?.publishDate || null,
          publishTime: action.payload?.schedule?.publishTime || null,
          publishTimeZone: action.payload?.schedule?.publishTimeZone || null,
          publishDateTimeLocal:
            action.payload?.schedule?.publishDateTimeLocal || null,
          publishDateTimeUTC:
            action.payload?.schedule?.publishDateTimeUTC || null,
          tzdb: action.payload?.schedule?.tzdb || null,
        },
        tags: {
          bigTags: action.payload.tags.bigTags || [],
          tinyTags: action.payload.tags.tinyTags || [],
        },
        translations: {
          translationsEnabled:
            action.payload?.translations?.translationsEnabled || false,
          roomCodeId: action.payload?.translations?.roomCodeId || null,
          joinRoomPasswordToggle:
            action.payload?.translations?.joinRoomPasswordToggle || false,
          password: action.payload?.translations?.password || '',
          roomLanguages: action.payload?.translations?.roomLanguages || [],
        },
      };
    },
  },
});

export const {
  resetEditEvent,
  setEditEvent,
  setEditEventChangeList,

  //contact
  setEditEventContactProvideContact,
  setEditEventContactEmail,
  setEditEventContactPhoneNumber,
  setEditEventContactCountryCode,

  //dateTime
  setEditEventDateTimeDate,
  setEditEventDateTimeTimeEnd,
  setEditEventDateTimeTimeStart,
  setEditEventDateTimeTimeZone,

  //generic
  setEditEventGenericAccessibilityNotes,
  setEditEventGenericAgendaItems,
  setEditEventGenericAgendaFormat,
  setEditEventGenericAudienceType,
  setEditEventGenericDescription,
  setEditEventGenericEventType,
  setEditEventGenericLanguages,
  setEditEventGenericSignLanguageInterpreter,
  setEditEventGenericTitle,
  setEditEventGenericWheelchairAccessibility,
  setEditEventGenericEventImageDisplay,
  setEditEventGenericEventAdditionalImages,

  //hosting
  setEditEventHostingGeoPositionCoordinates,
  setEditEventHostingHostingOption,
  setEditEventHostingInPersonLocation,
  setEditEventHostingVirtualMeetingId,
  setEditEventHostingVirtualPasscode,
  setEditEventHostingVirtualPlatform,
  setEditEventHostingVirtualLink,
  reverseEditEventHostingGeoPositionCoordinates,

  //meta
  setEditEventMetaSaveName,

  //participants
  setEditEventParticipants,

  //registrations
  setEditEventRegistrationDataApproveRegistrations,
  setEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setEditEventRegistrationDataPrivateDetailsEnabled,
  setEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  setEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  setEditEventRegistrationDataPrivateDetails,
  setEditEventRegistrationDataRegistrationsActive,

  //schedule
  setEditEventScheduleEnabled,
  setEditEventSchedulePublishDate,
  setEditEventSchedulePublishTime,
  setEditEventSchedulePublishTimeZone,

  //tags
  addEditEventTagsBigTag,
  addEditEventTagsTinyTag,
  removeEditEventTagsBigTag,
  removeEditEventTagsTinyTag,
  setEditEventTagsBigTags,
  setEditEventTagsTinyTags,

  //translations
  setEditEventTranslationsTranslationsEnabled,
  setEditEventTranslationsRoomCodeId,
  setEditEventTranslationsJoinRoomPasswordToggle,
  setEditEventTranslationsPassword,
  setEditEventTranslationsRoomLanguages,
} = editEventSlice.actions;

export default editEventSlice.reducer;

export const selectEditEvent = (state) => state.editEvent;
export const selectEditEventChangeList = (state) =>
  state.editEvent.editEventChangeList;

//contact
export const selectEditEventContactProvideContact = (state) =>
  state.editEvent?.contact?.provideContact;
export const selectEditEventContactContactEmail = (state) =>
  state.editEvent?.contact?.contactEmail;
export const selectEditEventContactContactPhoneNumber = (state) =>
  state.editEvent?.contact?.contactPhoneNumber;
export const selectEditEventContactCountryCode = (state) =>
  state.editEvent?.contact?.contactCountryCode;

//dateTime
export const selectEditEventDateTimeDate = (state) =>
  state.editEvent.dateTime.date;
export const selectEditEventDateTimeTimeEnd = (state) =>
  state.editEvent.dateTime.timeEnd;
export const selectEditEventDateTimeTimeStart = (state) =>
  state.editEvent.dateTime.timeStart;
export const selectEditEventDateTimeTimeZone = (state) =>
  state.editEvent.dateTime.timeZone;

//Generic
export const selectEditEventGenericAccessibilityNotes = (state) =>
  state.editEvent?.generic?.accessibilityNotes;
export const selectEditEventGenericAgendaItems = (state) =>
  state.editEvent?.generic?.agenda.items;
export const selectEditEventGenericAgendaFormat = (state) =>
  state.editEvent?.generic?.agenda.format;
export const selectEditEventGenericAudienceType = (state) =>
  state.editEvent?.generic?.audienceType;
export const selectEditEventGenericDescription = (state) =>
  state.editEvent?.generic?.description;
export const selectEditEventGenericEventImageDisplay = (state) =>
  state.editEvent?.generic?.eventImageDisplay;
export const selectEditEventGenericEventAdditionalImages = (state) =>
  state.editEvent?.generic?.eventAdditionalImages;
export const selectEditEventGenericEventType = (state) =>
  state.editEvent?.generic?.eventType;
export const selectEditEventGenericLanguages = (state) =>
  state.editEvent?.generic?.languages;
export const selectEditEventGenericSignLanguageInterpreter = (state) =>
  state.editEvent?.generic?.signLanguageInterpreter;
export const selectEditEventGenericTitle = (state) =>
  state.editEvent?.generic?.title;
export const selectEditEventGenericWheelchairAccessibility = (state) =>
  state.editEvent?.generic?.wheelchairAccessibility;

//Hosting
export const selectEditEventHostingInPersonGeoPositionCoordinates = (state) =>
  state.editEvent?.hosting?.inPerson?.geoPosition?.coordinates;
export const selectEditEventHostingHostingOption = (state) =>
  state.editEvent?.hosting?.hostingOption;
export const selectEditEventHostingInPersonLocation = (state) =>
  state.editEvent?.hosting?.inPerson?.location;
export const selectEditEventHostingVirtualMeetingId = (state) =>
  state.editEvent?.hosting?.virtual?.meetingId;
export const selectEditEventHostingVirtualPasscode = (state) =>
  state.editEvent?.hosting?.virtual?.passcode;
export const selectEditEventHostingVirtualPlatform = (state) =>
  state.editEvent?.hosting?.virtual?.platform;
export const selectEditEventHostingVirtualLink = (state) =>
  state.editEvent?.hosting?.virtual?.meetingLink;

//Meta
export const selectEditEventMetaLastModified = (state) =>
  state.editEvent?.meta?.lastModified;
export const selectEditEventMetaSaveName = (state) =>
  state.editEvent?.meta?.saveName;
export const selectEditEventId = (state) => state.editEvent?.meta?._id;

//Participants
export const selectEditEventParticipants = (state) =>
  state.editEvent?.participants;

//RegistrationData
export const selectEditEventRegistrationDataRegistrationsActive = (state) =>
  state.editEvent?.registrationData?.registrationsActive;

export const selectEditEventRegistrationDataApproveRegistrations = (state) =>
  state.editEvent?.registrationData?.approveRegistrations;

export const selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsInPersonEnabled;

export const selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsVirtualEnabled;
export const selectEditEventRegistrationDataPrivateDetailsEnabled = (state) =>
  state.editEvent?.registrationData?.privateDetails.enabled;
export const selectEditEventRegistrationDataMaxRegistrationsInPersonAmount = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsInPersonAmount;
export const selectEditEventRegistrationDataMaxRegistrationsVirtualAmount = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsVirtualAmount;
export const selectEditEventRegistrationDataPrivateDetails = (state) =>
  state.editEvent?.registrationData?.privateDetails;

//SchedulePublish
export const selectEditEventScheduleEnabled = (state) =>
  state.editEvent?.schedule?.enabled;
export const selectEditEventSchedulePublishDate = (state) =>
  state.editEvent?.schedule?.publishDate;
export const selectEditEventSchedulePublishTime = (state) =>
  state.editEvent?.schedule?.publishTime;
export const selectEditEventSchedulePublishTimeZone = (state) =>
  state.editEvent?.schedule?.publishTimeZone;

//Tags
export const selectEditEventTagsBigTags = (state) =>
  state.editEvent?.tags?.bigTags;
export const selectEditEventTagsTinyTags = (state) =>
  state.editEvent?.tags?.tinyTags;

//Translations
export const selectEditEventTranslationsTranslationsEnabled = (state) =>
  state.editEvent?.translations?.translationsEnabled;
export const selectEditEventTranslationsRoomCodeId = (state) =>
  state.editEvent?.translations?.roomCodeId;
export const selectEditEventTranslationsJoinRoomPasswordToggle = (state) =>
  state.editEvent?.translations?.joinRoomPasswordToggle;
export const selectEditEventTranslationsPassword = (state) =>
  state.editEvent?.translations?.password;
export const selectEditEventTranslationsRoomLanguages = (state) =>
  state.editEvent?.translations?.roomLanguages;
