import { useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import { useSettingsHandlers } from '../useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
//Utility

const OrganizeSelectionTags = () => {
  //Hooks
  const { width } = useSettings();
  const { SettingsOrganizeLanguage } = useLanguageSettings();

  const {
    settingsOrganizeSelectedOrganizers: selectedOrganizers,
    settingsOrganizeSelectAllOrganizersBtn: selectAll,
    handleSettingsSetSelectedOrganizers,
    handleSettingsSetSelectAllOrganizersBtn,
    handleSettingsAddSelectedOrganizer,
    handleSettingsRemoveSelectedOrganizer,
  } = useSettingsHandlers();

  const {
    accountId,
    accountAffiliations,
    accountProfileImage,
    accountFirstName,
    accountLastName,
  } = useAccountReduxHandlers();

  //Component functions
  useEffect(() => {
    if (selectedOrganizers?.length === 0 && selectAll) {
      handleSettingsSetSelectAllOrganizersBtn(!selectAll);
    }
    if (
      selectedOrganizers?.length === accountAffiliations?.length + 1 &&
      !selectAll
    ) {
      handleSettingsSetSelectAllOrganizersBtn(!selectAll);
    }
    /*eslint-disable-next-line*/
  }, [selectedOrganizers]); //automatically adjusts select all btn when selecting or unselecting all tags individually

  function handleSelectAll() {
    if (selectAll) {
      handleSettingsSetSelectedOrganizers([]);
    } else if (!selectAll) {
      let newSelections = [];
      accountAffiliations?.map((aA) => newSelections.push(aA.affiliation._id));
      newSelections.push(accountId);

      handleSettingsSetSelectedOrganizers(newSelections);
    }
    handleSettingsSetSelectAllOrganizersBtn(!selectAll);
  } //select all btn

  function handleOrganizerSelection(organizerId) {
    if (selectedOrganizers.includes(organizerId)) {
      handleSettingsRemoveSelectedOrganizer(organizerId);
    } else {
      handleSettingsAddSelectedOrganizer(organizerId);
    }
  } //add/remove organizer id

  return (
    <>
      {width <= 1280 && (
        <h2 className="fs16 mrg-b12 color-black">
          {SettingsOrganizeLanguage.organizerSelection}
        </h2>
      )}
      <div
        className={`settings-tags__wrapper  ${width > 1280 ? 'mrg-t16' : ''}`}
        id="tags__wrapper"
      >
        <ul
          className="settings-tags__container access-ob access-o6"
          tabIndex="0"
          aria-label={SettingsOrganizeLanguage.ariaOrganizersFilter}
          id="tags-container"
        >
          <li key="select-all-organizers-li">
            <button
              className={`settings-tag settings-tag--large ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                selectAll ? 'selected' : ''
              }`}
              onClick={() => handleSelectAll(selectAll)}
            >
              {selectAll ? (
                <p>{SettingsOrganizeLanguage.unselectAll}</p>
              ) : (
                <p>{SettingsOrganizeLanguage.selectAll}</p>
              )}
            </button>
          </li>

          <>
            <li key="personal-li">
              <button
                className={`settings-tag settings-tag--large ff2 color-theme mrg-r12 pad-8 access-ob access-o6 
                    ${selectedOrganizers.includes(accountId) ? 'selected' : ''}
                    `}
                onClick={() => handleOrganizerSelection(accountId)}
                aria-pressed={selectedOrganizers.includes(accountId)}
                aria-label={`${accountFirstName} ${accountLastName}`}
              >
                <LoadBucketImage
                  imagePath={accountProfileImage}
                  imgClassName="tag-profile-img tag-profile-community mrg-r6"
                  backupPath={'account_img_default.png'}
                  partialPath={'myndfull_account_images'}
                  containerClassName={'align-center'}
                />
                <p>
                  {accountFirstName} {accountLastName}
                </p>
              </button>
            </li>
            {accountAffiliations?.length > 0 &&
              [...accountAffiliations].map((aA) => {
                //note aA._id is not the same as aA.affiliation._id
                return (
                  <li key={`${aA._id}-li`}>
                    <button
                      className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                        selectedOrganizers.includes(aA.affiliation._id)
                          ? 'selected'
                          : ''
                      }`}
                      key={aA._id}
                      onClick={() =>
                        handleOrganizerSelection(aA.affiliation._id)
                      }
                      aria-pressed={selectedOrganizers.includes(
                        aA.affiliation._id
                      )}
                      aria-label={aA.affiliation.institution.institutionTitle}
                    >
                      <LoadBucketImage
                        imagePath={aA.affiliation.institution?.institutionLogo}
                        imgClassName="institutional-profile-img  tag-profile-community"
                        backupPath={'account_img_default.png'}
                        partialPath={'myndfull_account_images'}
                      />
                      <p>{aA.affiliation.institution.institutionTitle}</p>
                    </button>
                  </li>
                );
              })}
          </>
        </ul>
      </div>
    </>
  );
};

export default OrganizeSelectionTags;
