import { useEffect } from 'react';

//hooks
import useGenericModal from '../../../../context/useGenericModal';

//components
import ProfilePersonalBase from '../../../Profiles/ProfilePersonalBase';

//utils

const ProfilePersonalModal = ({
  profileData,
  customWrapper,
  closeModalFocusId,
  previewMode,
  reopenNotices,
  customProfileInteractionHandler,
  interactionsOff,
}) => {
  //Hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();

  //Initialize functions
  useEffect(() => {
    if (profileData) {
      setCloseModalFocusId(closeModalFocusId);
    }
  }, [profileData]); //set id for focus when modal closes

  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12 min-full-height flex-grow"
      tabIndex="0"
      id="float-modal"
    >
      <div className="flex-column scroll-y-no-x hide-scrollbar flex-grow full-height">
        <ProfilePersonalBase
          profileData={profileData}
          customWrapper={customWrapper}
          handleCloseModal={handleCloseModal}
          previewMode={previewMode}
          reopenNotices={reopenNotices}
          customProfileInteractionHandler={customProfileInteractionHandler}
          interactionsOff={interactionsOff}
        />
      </div>
    </div>
  );
};

export default ProfilePersonalModal;
