import { useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useGenericModal from '../../../../context/useGenericModal';
//Components
import Spinner from '../../../Spinner/Spinner';

//Utility
import { cancel, search_dark, trash } from '../../../../assets/icons';

const SelectDropdownTimezonesModal = ({
  id,
  selectionKey,
  selectionValue,
  handleModalSelection,
  modalLabel,
  index,
  SelectDropdown,
  timezoneObjList,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap, dismissKeypad } = useSettings();

  //Component state
  const [searchText, setSearchText] = useState('');
  const [searchFilteredData, setSearchFilteredData] = useState(null);

  //UI state
  const [tapHighlightRemoveBtn, setTapHighlightRemoveBtn] = useState(false);
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //Component variables
  let tagsList;

  //UI functions
  useEffect(() => {
    setCloseModalFocusId(`${id}-dropdown-main`);
  }, []); //set id for focus when modal closes

  //Component functions
  const handleTextSearchChange = (searchValue) => {
    setSearchText(searchValue);
    const newSearchFilteredList = Object.values(timezoneObjList).filter((obj) =>
      obj.countryName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchFilteredData(newSearchFilteredList);
  }; // Filters the timezoneObjList based on the countryName

  //JSX conditional
  if (timezoneObjList && !searchFilteredData) {
    tagsList = (
      <>
        {Object.values(timezoneObjList).map((obj, index) => {
          return obj.zones.map((zoneObj, zoneIndex) => {
            const [zoneKey, zoneData] = Object.entries(zoneObj)[0];

            const isLastItem =
              index === Object.values(timezoneObjList).length - 1 &&
              zoneIndex === obj.zones.length - 1;

            return (
              <li
                key={`${zoneKey}`}
                style={isLastItem ? { marginBottom: '264px' } : null}
                className="list-none"
              >
                <button
                  className={`item fs16 fwsb text-left pad-r12 ${
                    tapHighlightSelectedKey === zoneKey ? 'item-selected' : ''
                  }`}
                  key={`list-item-${index}`}
                  onClick={() => {
                    handleMobileTap(
                      [
                        () => setTapHighlightSelectedKey(zoneKey),
                        () => setTapHighlightSelectedKey(),
                      ],
                      [
                        () => handleModalSelection(zoneKey),
                        () => handleCloseModal(),
                      ]
                    );
                  }}
                  type="button"
                  tabIndex="0"
                  aria-label={`${zoneData.countryName} / ${zoneData.identifier}`}
                  id={`list-item-${index}`}
                >
                  {zoneData.countryName} / {zoneData.identifier}
                </button>
              </li>
            );
          });
        })}
      </>
    );
  } else if (searchFilteredData) {
    tagsList = (
      <>
        {searchFilteredData.map((obj, index) => {
          return obj.zones.map((zoneObj, zoneIndex) => {
            const [zoneKey, zoneData] = Object.entries(zoneObj)[0];

            const isLastItem =
              index === Object.values(searchFilteredData).length - 1 &&
              zoneIndex === obj.zones.length - 1;

            return (
              <li
                key={`${zoneKey}`}
                style={isLastItem ? { marginBottom: '264px' } : null}
                className="list-none"
              >
                <button
                  className={`item fs16 fwsb text-left pad-r12 ${
                    tapHighlightSelectedKey === zoneKey ? 'item-selected' : ''
                  }`}
                  key={`list-item-${index}`}
                  onClick={() => {
                    handleMobileTap(
                      [
                        () => setTapHighlightSelectedKey(zoneKey),
                        () => setTapHighlightSelectedKey(),
                      ],
                      [
                        () => handleModalSelection(zoneKey),
                        () => handleCloseModal(),
                      ]
                    );
                  }}
                  type="button"
                  tabIndex="0"
                  aria-label={`${zoneData.countryName} / ${zoneData.identifier}`}
                  id={`list-item-${index}`}
                >
                  {zoneData.countryName} / {zoneData.identifier}
                </button>
              </li>
            );
          });
        })}
      </>
    );
  } else {
    tagsList = <Spinner minHeight={'100%'} />;
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="selectDropdownTimezonesModal"
      key={`selectdropdownmodal-${index}`}
    >
      <div className="heading pos-rel" style={{ minHeight: 'fit-content' }}>
        <div className="flex-column full-width">
          <div className=" mrg-t12 space-between">
            <div className="flex-row flex-center">
              <label
                className="fs18 fwsb access-ob access-o6"
                tabIndex="0"
                id={`selectionDropdownModalLabel-${id}`}
              >
                {modalLabel}
              </label>
            </div>
            <button
              className={`close-btn highlight-i-lgr access-ob ${
                tapHighlightCloseBtn ? 'filter-img-red' : 'filter-img-lightgray'
              } `}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightCloseBtn(true),
                    () => setTapHighlightCloseBtn(false),
                  ],
                  [() => handleCloseModal()]
                )
              }
              type="button"
              title={Generic.close}
            >
              <img src={cancel} alt={Generic.close} className="svg" />
            </button>
          </div>

          <div className="heading__text-input">
            <img
              src={search_dark}
              alt={SelectDropdown.searchIcon}
              className="filter-gray svg"
            />
            <input
              type="text"
              className="fs18 fs16--phn fwn "
              placeholder={SelectDropdown.searchByCountry}
              id="searchByCountry-input"
              onChange={(e) => handleTextSearchChange(e.target.value)}
              onKeyDown={(e) => dismissKeypad(e)}
              value={searchText}
              aria-label={SelectDropdown.ariaTimezoneInput}
              tabIndex="0"
              enterKeyHint="done"
            />
          </div>
        </div>
      </div>
      <ul
        className="modal-basic__container list-style-none "
        id="selectDropdownTimezonesList"
      >
        {tagsList}
      </ul>
      <div className="modal-basic__footer">
        <button
          type="button"
          className={`min-full-width highlight-i-lgr color-black access-ob ${
            tapHighlightRemoveBtn ? 'filter-img-red' : 'filter-img-lightgray'
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightRemoveBtn(true),
                () => setTapHighlightRemoveBtn(false),
              ],
              [() => handleModalSelection(null), () => handleCloseModal()]
            )
          }
        >
          {selectionValue && selectionKey !== 'select' ? (
            <>
              <div className="flex-row item fwsb fs16">
                {selectionValue?.countryName} / {selectionValue?.identifier}
              </div>
              <img
                src={trash}
                alt={Generic.remove}
                className="trashbin svg mrg-l12"
              />
            </>
          ) : (
            <div className="flex-row">
              <p
                className={`fs18 fwsb access-ob access-o6 ${
                  tapHighlightRemoveBtn ? 'color-theme' : 'color-black'
                }`}
              >
                {Generic.close}
              </p>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default SelectDropdownTimezonesModal;
