import { useState } from 'react';

//hooks
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';

//components
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import DistanceInput from '../../../../../components/DistanceInput/DistanceInput';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import RadialButtonList from '../../../../../components/RadialButtonList/RadialButtonList';
import TextArea from '../../../../../components/TextArea/TextArea';
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';
import MobileFilterSelections from './MobileFilterSelections';
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils
import {
  bigtag_nobg,
  checkmark_green,
  img_download,
  img_language,
  img_location,
  img_refresh_two_circular,
  star,
  tinytag_nobg,
  trash,
} from '../../../../../assets/icons';

const QueryFilterMobile = ({
  isLoaded,
  reset,
  FindSpeakersQueryFilters,
  queryIsLoading,
  //functions
  handleResetFilters,
  handleImportEventDraft,
  handleFindSpeakersQuery,
  handleAutocompleteCoordinates,
  handleRemoveNotListedLanguage,
  handleNotListedLanguages,
  handleAddNotListedLanguage,
  handleViewContactedSpeakers,
}) => {
  const {
    handleSettingsSetFindSpeakersDistanceFilter,
    handleSettingsSetFindSpeakersDistanceMeasurement,
    handleSettingsSetFindSpeakersSearchBigTags,
    handleSettingsSetFindSpeakersSearchCoordinates,
    handleSettingsSetFindSpeakersSearchCountries,
    handleSettingsSetFindSpeakersSearchLocationType,
    handleSettingsSetFindSpeakersSearchSpeakerLanguages,
    handleSettingsSetFindSpeakersSearchTinyTags,
    settingsFindSpeakersDistanceFilter,
    settingsFindSpeakersDistanceMeasurement,
    settingsFindSpeakersSearchBigTags,
    settingsFindSpeakersSearchCoordinates,
    settingsFindSpeakersSearchCountries,
    settingsFindSpeakersSearchLocationType,
    settingsFindSpeakersSearchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed,
    settingsFindSpeakersSearchTinyTags,
  } = useSettingsHandlers();
  const {
    LanguageSimpleOpts,
    CountryISOCodesSupported,
    BigTagsOptsWithSubText,
    BigTagsOpts,
    FindSpeakersLocationOpts,
  } = useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();

  //state
  const [filterSelection, setFilterSelection] = useState();

  return (
    <div className="fs-mob-con">
      <MobileFilterSelections
        filterSelection={filterSelection}
        setFilterSelection={setFilterSelection}
        distanceFilter={settingsFindSpeakersDistanceFilter}
        distanceMeasurement={settingsFindSpeakersDistanceMeasurement}
        searchLocationType={settingsFindSpeakersSearchLocationType}
        searchCountries={settingsFindSpeakersSearchCountries}
        searchSpeakerLanguages={settingsFindSpeakersSearchSpeakerLanguages}
        searchBigTags={settingsFindSpeakersSearchBigTags}
        searchTinyTags={settingsFindSpeakersSearchTinyTags}
      />
      {filterSelection === 'location' && (
        <>
          <div className="divider mrg-tb24 opacity-5" />

          <div className="query-location mrg-t24">
            <div className="sub-label">
              <img
                src={img_location}
                alt={IconAlts.iconLocationPin}
                className="filter-theme mrg-r12"
              />
              <p>{FindSpeakersQueryFilters.searchByLocation}</p>
            </div>

            <RadialButtonList
              id={'locationSearch'}
              options={FindSpeakersLocationOpts}
              onChange={handleSettingsSetFindSpeakersSearchLocationType}
              reset={reset}
              loadValue={settingsFindSpeakersSearchLocationType}
              loadTrigger={true}
            />
            <div className="h12" />

            {settingsFindSpeakersSearchCoordinates[0] !== 0 &&
              settingsFindSpeakersSearchCoordinates[0] &&
              settingsFindSpeakersSearchLocationType === 'locationMap' && (
                <>
                  {settingsFindSpeakersSearchCoordinates && (
                    <MapContainerWithDistanceFilter
                      loadTrigger={true}
                      loadValue={settingsFindSpeakersSearchCoordinates}
                      handler={handleSettingsSetFindSpeakersSearchCoordinates}
                      distanceFilter={settingsFindSpeakersDistanceFilter}
                      distanceMeasurement={
                        settingsFindSpeakersDistanceMeasurement
                      }
                    />
                  )}
                  <div className="h24" />
                </>
              )}
            {settingsFindSpeakersSearchLocationType === 'locationMap' && (
              <>
                <AutocompletePlacesNoAddress
                  setAutocompleteCoordinates={handleAutocompleteCoordinates}
                />
                {isLoaded && (
                  <>
                    <div className="h24" />
                    <DistanceInput
                      distanceFilter={settingsFindSpeakersDistanceFilter}
                      setDistanceFilter={
                        handleSettingsSetFindSpeakersDistanceFilter
                      }
                      distanceMeasurement={
                        settingsFindSpeakersDistanceMeasurement
                      }
                      setDistanceMeasurement={
                        handleSettingsSetFindSpeakersDistanceMeasurement
                      }
                    />
                    <div className="h24" />
                  </>
                )}
              </>
            )}
            {settingsFindSpeakersSearchLocationType === 'locationCountry' && (
              <div className="min-full-width">
                <div className="h12" />
                <SelectionsModal
                  addButtonText={FindSpeakersQueryFilters.addCountries}
                  DataOpts={CountryISOCodesSupported}
                  fullWidth={true}
                  iconModal={img_location}
                  iconModalClass={'filter-theme'}
                  iconOff={true}
                  limit={5}
                  modalTitle={FindSpeakersQueryFilters.searchByCountry}
                  selectionState={settingsFindSpeakersSearchCountries}
                  setSelectionState={
                    handleSettingsSetFindSpeakersSearchCountries
                  }
                  subTextOn={false}
                  id={'locationCountrySelections'}
                />
                <div className="h24" />
              </div>
            )}

            <div className="required-label">
              <p>{Generic.required}</p>
              {settingsFindSpeakersSearchLocationType === 'locationMap' &&
                settingsFindSpeakersSearchCoordinates[0] &&
                settingsFindSpeakersSearchCoordinates[0] !== 0 && (
                  <img src={checkmark_green} alt={Generic.checkmark} />
                )}
              {settingsFindSpeakersSearchLocationType === 'locationCountry' &&
                settingsFindSpeakersSearchCountries?.length > 0 && (
                  <img src={checkmark_green} alt={Generic.checkmark} />
                )}
            </div>
          </div>
        </>
      )}

      {filterSelection === 'language' && (
        <>
          <div className="divider mrg-tb24 opacity-5" />

          <div className="query-category ">
            <div className="sub-label">
              <img
                src={img_language}
                alt={IconAlts.iconLanguage}
                className="filter-theme mrg-r12"
              />
              <p>{FindSpeakersQueryFilters.languages}</p>
            </div>

            <SelectionsModal
              addButtonText={FindSpeakersQueryFilters.addLanguages}
              DataOpts={LanguageSimpleOpts}
              fullWidth={true}
              iconModal={img_language}
              iconModalClass={'filter-theme'}
              iconOff={true}
              limit={5}
              modalTitle={FindSpeakersQueryFilters.languages}
              selectionState={settingsFindSpeakersSearchSpeakerLanguages}
              setSelectionState={
                handleSettingsSetFindSpeakersSearchSpeakerLanguages
              }
              subTextOn={false}
              id={'languageSelections'}
              customWrapper={'mrg-b24'}
            />

            <>
              {settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length >
                0 && (
                <div className="mrg-t36">
                  {settingsFindSpeakersSearchSpeakerLanguagesNotListed?.map(
                    (languageObj) => {
                      return (
                        <div
                          className={`flex-row align-center ${
                            settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length >
                            1
                              ? 'mrg-b24'
                              : ''
                          }`}
                          key={languageObj.id}
                        >
                          <TextArea
                            limit={100}
                            id={languageObj.id}
                            labelText={FindSpeakersQueryFilters.language}
                            disableEnter={true}
                            customWidth={'100%'}
                            handleChange={(text) =>
                              handleNotListedLanguages(text, languageObj.id)
                            }
                            loadValue={languageObj.language}
                            loadTrigger={true}
                            onBlurHandlerOff={true}
                            ariaText={
                              FindSpeakersQueryFilters.ariaNotListedLanguageTextInput
                            }
                            hideWordCount={true}
                          />
                          <button
                            className="mrg-l24 min44 highlight-i-lgr align-center access-ob access-o6"
                            onClick={() =>
                              handleRemoveNotListedLanguage(languageObj.id)
                            }
                          >
                            <img src={trash} alt="X" className="filter-gray" />
                          </button>
                        </div>
                      );
                    }
                  )}
                  <ItemButton
                    handler={handleAddNotListedLanguage}
                    text={FindSpeakersQueryFilters.addAnotherNonListedLanguage}
                    fullWidth={true}
                    customWrapper={'mrg-tb24'}
                    unavailable={
                      settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length >
                      5
                    }
                  />
                </div>
              )}
            </>
            <div className="required-label">
              <p>1 {Generic.required}</p>
              {!settingsFindSpeakersSearchSpeakerLanguages?.includes(
                'notListed'
              ) &&
                settingsFindSpeakersSearchSpeakerLanguages?.length > 0 && (
                  <img src={checkmark_green} alt={Generic.checkmark} />
                )}
              {settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length >
                0 &&
                settingsFindSpeakersSearchSpeakerLanguagesNotListed.some(
                  (nL) => nL.language && nL.language?.length > 0
                ) && <img src={checkmark_green} alt={Generic.checkmark} />}
            </div>
          </div>
        </>
      )}

      {filterSelection === 'bigTags' && (
        <>
          <div className="divider mrg-tb24 opacity-5" />
          <div className="query-category ">
            <div className="sub-label">
              <img
                src={bigtag_nobg}
                alt={IconAlts.iconBigTag}
                className="mrg-r12"
                style={{ padding: '2px' }}
              />
              <p>{FindSpeakersQueryFilters.bigTags}</p>
            </div>
            <SelectionsModal
              addButtonText={FindSpeakersQueryFilters.addBigTags}
              DataOpts={BigTagsOptsWithSubText}
              DataOptsSubTextTranslations={BigTagsOpts}
              fullWidth={true}
              iconModal={bigtag_nobg}
              iconOff={true}
              limit={10}
              modalTitle={FindSpeakersQueryFilters.bigTags}
              selectionState={settingsFindSpeakersSearchBigTags}
              setSelectionState={handleSettingsSetFindSpeakersSearchBigTags}
              subTextOn={true}
              id={'bigTagSelections'}
              customWrapper={'mrg-b24'}
            />
            <div className="required-label">
              <p>1 {Generic.required}</p>
              {settingsFindSpeakersSearchBigTags?.length > 0 && (
                <img src={checkmark_green} alt={Generic.checkmark} />
              )}
            </div>
          </div>
        </>
      )}

      {filterSelection === 'tinyTags' && (
        <>
          <div className="divider mrg-tb24 opacity-5" />
          <div className="query-category">
            <div className="sub-label">
              <img
                src={tinytag_nobg}
                alt={IconAlts.iconTinyTag}
                className="mrg-r12"
              />
              <p className="sub-label">{FindSpeakersQueryFilters.tinyTags}</p>
            </div>
            <CustomTagsList
              limit={100}
              id={'tinyTags'}
              tinyTags={settingsFindSpeakersSearchTinyTags}
              setTinyTags={handleSettingsSetFindSpeakersSearchTinyTags}
              twoTagRows={true}
              bigTags={settingsFindSpeakersSearchBigTags}
              tagGroupsOff={true}
              iconOff={true}
            />
          </div>
        </>
      )}

      <div className="divider mrg-tb24 opacity-5" />
      <ButtonTextSimple
        text={FindSpeakersQueryFilters.resetFilters}
        icon={img_refresh_two_circular}
        customWrapper={'mrg-b12 min-44 highlight-t-theme text-gray'}
        handler={handleResetFilters}
        iconOn={true}
        iconClass={'admin-btn-img mrg-r12 filter-gray'}
        hideBorderOn={true}
      />
      <ButtonTextSimple
        text={FindSpeakersQueryFilters.importEventData}
        icon={img_download}
        customWrapper={'mrg-b12 min-44  highlight-t-theme text-gray'}
        handler={handleImportEventDraft}
        iconOn={true}
        iconClass={'admin-btn-img mrg-r12 filter-gray'}
        hideBorderOn={true}
        id={'findspeakers-importEventData'}
      />

      <ButtonTextSimple
        text={FindSpeakersQueryFilters.viewContacts}
        icon={star}
        customWrapper={'mrg-b12 min-44 highlight-t-theme text-gray'}
        handler={handleViewContactedSpeakers}
        iconOn={true}
        iconClass={'admin-btn-img mrg-r12 filter-gray '}
        hideBorderOn={true}
      />
      <ItemButton
        handler={handleFindSpeakersQuery}
        text={FindSpeakersQueryFilters.search}
        customWrapper={'mrg-t12'}
        fullWidth={true}
        isLoading={queryIsLoading}
      />
    </div>
  );
};

export default QueryFilterMobile;
