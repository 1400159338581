import { useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility
import { nav_arrow } from '../../assets/icons';

const NavigatePages = ({
  handlePageDecrease,
  handlePageIncrease,
  finalArrow,
  firstArrow,
  customWrapper,
}) => {
  //Hooks
  const { NavigationArrows, Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightNavIncrease, setTapHighlightNavIncrease] = useState(false);
  const [tapHighlightNavDecrease, setTapHighlightNavDecrease] = useState(false);

  return (
    <div
      className={`nav-arrows flex-row space-between full-width ${
        customWrapper ? customWrapper : ''
      }`}
    >
      {!firstArrow && (
        <button
          className={`flex-center fwb  ${
            tapHighlightNavDecrease ? 'highlight-it-theme--tap' : ''
          } highlight-it-theme color-gray access-ob access-o6`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightNavDecrease(true),
                () => setTapHighlightNavDecrease(false),
              ],
              [() => handlePageDecrease()]
            )
          }
          type="button"
          tabIndex="0"
          aria-label={`${NavigationArrows.previous}.`}
        >
          <div className="arrow rotate-180 mrg-r12 svg">
            <img src={nav_arrow} alt={Generic.arrow} className="filter-gray" />
          </div>
          {NavigationArrows.previous}
        </button>
      )}
      {!finalArrow && (
        <button
          type="button"
          className={`flex-center fwb ${firstArrow ? 'mrg-auto-left' : ''}  ${
            tapHighlightNavIncrease ? 'highlight-it-theme--tap' : ''
          } highlight-it-theme color-gray access-ob access-o6`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightNavIncrease(true),
                () => setTapHighlightNavIncrease(false),
              ],
              [() => handlePageIncrease()]
            )
          }
          tabIndex="0"
          aria-label={`${NavigationArrows.next}.`}
        >
          {NavigationArrows.next}
          <div className="arrow svg mrg-l12">
            <img src={nav_arrow} alt={Generic.arrow} className="filter-gray" />
          </div>
        </button>
      )}
    </div>
  );
};

export default NavigatePages;
