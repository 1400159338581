import { useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useGenericModal from '../../../../context/useGenericModal';
import { useOrganizeHandlers } from '../../../../features/main/organize/useOrganizeHandlers';

//Components

//Utility
import {
  arrow_circular_hallow,
  cancel,
  img_empty_box,
  img_folder_closed,
  img_folder_open,
  bigtag_nobg,
  tinytag_nobg,
  img_language,
  img_building3,
  img_virtual,
  hybrid_icon,
} from '../../../../assets/icons';

const ImportEventDataModal = ({ handler }) => {
  //Hooks
  const { Generic, IconAlts, ImportEventDataModal } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  const {
    organizeMyDraftEvents,
    organizeSortedMyOrganizedEvents,
    organizeSortedMyScheduledOrganizedEvents,
    organizeSortedMyArchivedOrganizedEvents,
  } = useOrganizeHandlers();

  //Component state
  const [openedEventsFolder, setOpenedEventsFolder] = useState();
  const [filteredList, setFilteredList] = useState([]);

  //UI state
  const [tapHighlightRemoveBtn, setTapHighlightRemoveBtn] = useState(false);
  const [tapHighlightExitBtn, setTapHighlightExitBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('findspeakers-importEventData');
    document.getElementById(`importEventDataModal`).focus();
  }, []);

  //Component functions
  useEffect(() => {
    if (openedEventsFolder === 'draft') {
      setFilteredList(organizeMyDraftEvents);
    }
    if (openedEventsFolder === 'active') {
      setFilteredList(organizeSortedMyOrganizedEvents);
    }
    if (openedEventsFolder === 'scheduled') {
      setFilteredList(organizeSortedMyScheduledOrganizedEvents);
    }
    if (openedEventsFolder === 'archived') {
      setFilteredList(organizeSortedMyArchivedOrganizedEvents);
    }
    if (!openedEventsFolder) {
      setFilteredList();
    }
  }, [openedEventsFolder]);

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="importEventDataModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`importEventDataModalLabel`}
          >
            {ImportEventDataModal.importEventData}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob ${
            tapHighlightExitBtn ? 'filter-img-red' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightExitBtn(true),
                () => setTapHighlightExitBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={Generic.close} className="svg" />
        </button>
      </div>

      <div className="modal-basic__container list-style-none hide-scrollbar">
        <button
          onClick={() => {
            if (openedEventsFolder === 'draft') {
              setOpenedEventsFolder();
            } else {
              setOpenedEventsFolder('draft');
            }
          }}
          aria-pressed={openedEventsFolder === 'draft'}
          tabIndex="0"
          className="access-ob"
        >
          <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
            {openedEventsFolder === 'draft' ? (
              <img
                src={img_folder_open}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            ) : (
              <img
                src={img_folder_closed}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            )}
            <p className="mrg-t2">
              {ImportEventDataModal.eventDrafts} (
              {organizeMyDraftEvents?.length})
            </p>
          </div>
        </button>

        <button
          onClick={() => {
            if (openedEventsFolder === 'active') {
              setOpenedEventsFolder();
            } else {
              setOpenedEventsFolder('active');
            }
          }}
          aria-pressed={openedEventsFolder === 'active'}
          tabIndex="0"
          className="access-ob"
        >
          <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
            {openedEventsFolder === 'active' ? (
              <img
                src={img_folder_open}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            ) : (
              <img
                src={img_folder_closed}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            )}
            <p className="mrg-t2">
              {ImportEventDataModal.activeEvents} (
              {organizeSortedMyOrganizedEvents?.length})
            </p>
          </div>
        </button>

        <button
          onClick={() => {
            if (openedEventsFolder === 'scheduled') {
              setOpenedEventsFolder();
            } else {
              setOpenedEventsFolder('scheduled');
            }
          }}
          aria-pressed={openedEventsFolder === 'scheduled'}
          tabIndex="0"
          className="access-ob"
        >
          <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
            {openedEventsFolder === 'scheduled' ? (
              <img
                src={img_folder_open}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            ) : (
              <img
                src={img_folder_closed}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            )}
            <p className="mrg-t2">
              {ImportEventDataModal.scheduledEvents} (
              {organizeSortedMyScheduledOrganizedEvents?.length})
            </p>
          </div>
        </button>

        <button
          onClick={() => {
            if (openedEventsFolder === 'archived') {
              setOpenedEventsFolder();
            } else {
              setOpenedEventsFolder('archived');
            }
          }}
          aria-pressed={openedEventsFolder === 'archived'}
          tabIndex="0"
          className="access-ob"
        >
          <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
            {openedEventsFolder === 'archived' ? (
              <img
                src={img_folder_open}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            ) : (
              <img
                src={img_folder_closed}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            )}
            <p className="mrg-t2">
              {ImportEventDataModal.archivedEvents} (
              {organizeSortedMyArchivedOrganizedEvents?.length})
            </p>
          </div>
        </button>

        <div className="divider mrg-t12 mrg-b24 opacity-5" />
        <ul
          className="import-events-data hide-scrollbar pad-l12 pad-r12 scroll-y-no-x list-style-none"
          id="selectDropdownList"
        >
          {filteredList?.length > 0 ? (
            <>
              {filteredList?.map((ev, index) => {
                const eventId = ev._id;
                const isLastItem = filteredList?.length === index + 1;
                const bigTagsCount = ev?.tags?.bigTags?.length || 0;
                const tinyTagsCount = ev?.tags?.tinyTags?.length;
                const languagesCount = ev?.generic?.languages?.length;
                const hostingOption = ev?.hosting?.hostingOption;

                return (
                  <li
                    key={`importEventData-${index}`}
                    style={
                      isLastItem
                        ? { marginBottom: '160px' }
                        : { marginBottom: '12px' }
                    }
                    className="flex-column"
                  >
                    <button
                      className={`item fs16 fwsb ${
                        tapHighlightSelectedKey === eventId
                          ? 'item-selected'
                          : ''
                      }`}
                      key={`list-item-${index}`}
                      onClick={() =>
                        handleMobileTap(
                          [
                            () => setTapHighlightSelectedKey(eventId),
                            () => setTapHighlightSelectedKey(),
                          ],
                          [
                            () => handler(eventId, openedEventsFolder),
                            () => handleCloseModal(),
                          ]
                        )
                      }
                      type="button"
                      tabIndex="0"
                      aria-label={
                        openedEventsFolder === 'draft'
                          ? ev.meta?.saveName
                          : ev.generic?.title
                      }
                      id={`list-item-${index}`}
                    >
                      {openedEventsFolder === 'draft'
                        ? ev.meta?.saveName
                        : ev.generic?.title}

                      <div className="flex-row full-width align-center space-evenly mrg-auto-top mrg-auto-bottom">
                        {hostingOption === 'inPerson' ? (
                          <div className="flex-row align-center mrg-r6">
                            <img
                              src={img_building3}
                              alt={IconAlts.iconLanguage}
                              className="filter-theme"
                              style={{ height: '24px' }}
                            />
                          </div>
                        ) : hostingOption === 'online' ? (
                          <div className="flex-row align-center  mrg-r6">
                            <img
                              src={img_virtual}
                              alt={IconAlts.iconLanguage}
                              className="filter-theme"
                              style={{ height: '24px' }}
                            />
                          </div>
                        ) : (
                          hostingOption === 'hybrid' && (
                            <div className="flex-row align-center">
                              <img
                                src={hybrid_icon}
                                alt={IconAlts.iconLanguage}
                                style={{ height: '48px' }}
                              />
                            </div>
                          )
                        )}
                        <div className="flex-row align-center">
                          <img
                            src={img_language}
                            alt={IconAlts.iconLanguage}
                            className="filter-theme"
                          />
                          <p className="mrg-l6 fs18">{languagesCount}</p>
                        </div>

                        <div className="flex-row align-center">
                          <img
                            src={bigtag_nobg}
                            alt={IconAlts.iconBigTag}
                            style={{ height: '21px' }}
                          />
                          <p className="mrg-l6 fs18">{bigTagsCount}</p>
                        </div>

                        <div className="flex-row align-center">
                          <img
                            src={tinytag_nobg}
                            alt={IconAlts.iconTinyTag}
                            style={{ height: '26px' }}
                          />
                          <p className="mrg-l6 fs18">{tinyTagsCount}</p>
                        </div>
                      </div>
                    </button>
                  </li>
                );
              })}
            </>
          ) : openedEventsFolder ? (
            <div className="flex-row align-center justify-center">
              <img
                src={img_empty_box}
                alt={IconAlts.iconArrow}
                className="mrg-r12 filter-gray svg"
                style={{ height: '28px' }}
              />
              <p className="fs18 fwn">{ImportEventDataModal.emptyFolder}</p>
            </div>
          ) : (
            <div className="flex-row align-center justify-center">
              <img
                src={arrow_circular_hallow}
                alt={IconAlts.iconArrow}
                className="mrg-r12 filter-gray svg"
                style={{ height: '28px' }}
              />
              <p className="fs18 fwn">{ImportEventDataModal.selectFolder}</p>
            </div>
          )}
        </ul>
      </div>
      <div className="modal-basic__footer">
        <button
          type="button"
          className={`min-full-width highlight-t-theme access-ob  ${
            tapHighlightRemoveBtn ? 'filter-img-red' : 'filter-img-lightgray'
          }`}
          aria-label={Generic.close}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightRemoveBtn(true),
                () => setTapHighlightRemoveBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
        >
          <p
            className={`fs18 fwsb access-ob access-o6 ${
              tapHighlightRemoveBtn ? 'color-theme' : ''
            }`}
          >
            {Generic.close}
          </p>
        </button>
      </div>
    </div>
  );
};

export default ImportEventDataModal;
