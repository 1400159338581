//hooks
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../../../../language/useLanguageComponents';

//components

//utils
import {
  cancel_red,
  checkmark_green,
  img_exclamation,
  img_hourglass,
} from '../../../../../../../assets/icons';

const ParticipantListItemInvitationStatus = ({
  invitationStatus,
  invitationHasExpired,
  dispatchesOnly,
  isDispatching,
  translationsListItem,
}) => {
  //hooks
  const { ParticipantListItem, ParticipantListItemCompletionStatusDispatches } =
    useLanguageOrganize();
  const { IconAlts } = useLanguageComponents();
  return (
    <>
      {invitationHasExpired ? (
        <>
          {ParticipantListItem.emailInvitationExpired}
          <img
            src={img_exclamation}
            alt={IconAlts.iconExclamation}
            style={{ height: '18px' }}
            className="mrg-auto-tb mrg-l12 filter-red"
          />
        </>
      ) : (
        <>
          {dispatchesOnly ? (
            <>
              {
                ParticipantListItemCompletionStatusDispatches[
                  isDispatching ? 'dispatching' : invitationStatus
                ]
              }
            </>
          ) : translationsListItem ? (
            <>{ParticipantListItem[invitationStatus]}</> //could change this to submitted instead of accepted
          ) : (
            <>{ParticipantListItem[invitationStatus]}</>
          )}
          {invitationStatus === 'pending' && (
            <img
              src={img_hourglass}
              alt={IconAlts.iconHourglass}
              style={{ height: '18px' }}
              className="mrg-auto-tb mrg-l12 filter-theme"
            />
          )}
          {invitationStatus === 'accepted' && (
            <img
              src={checkmark_green}
              alt={IconAlts.iconCheckmark}
              style={{ height: '12px' }}
              className="mrg-auto-tb mrg-l12"
            />
          )}
          {(invitationStatus === 'rejected' ||
            invitationStatus === 'withdrew') && (
            <img
              src={cancel_red}
              alt={'X'}
              style={{ height: '12px' }}
              className="mrg-auto-tb mrg-l12"
            />
          )}
        </>
      )}
    </>
  );
};

export default ParticipantListItemInvitationStatus;
