import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import useSettings from '../../context/useSettings';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import useLanguageLayout from '../../language/useLanguageLayout';
import { useLazyLogoutQuery } from '../../features/main/auth/authApiSlice';
import { useSettingsHandlers } from '../../features/main/settings/useSettingsHandlers';

//Components
import LoadBucketImage from '../../components/LoadBucketImage/LoadBucketImage';

//Utils
import {
  img_refresh_two_circular,
  logOut,
  organize,
  search,
} from '../../assets/icons';

const NavLoggedIn = ({ currentRoles, displaySocketSpinner }) => {
  //api
  const [logout] = useLazyLogoutQuery();

  //Hooks
  const { NavbarLoggedIn } = useLanguageLayout();
  const { navbar, setNavbar } = useSettings();
  const { accountProfileImage } = useAccountReduxHandlers();
  const { handleSettingsSetViewEventDetails } = useSettingsHandlers();

  //ui
  const [profileImage, setProfileImage] = useState(accountProfileImage);

  useEffect(() => {
    setProfileImage(accountProfileImage);
  }, [accountProfileImage]);

  return (
    <div className="nav__links no-select">
      {displaySocketSpinner && (
        <img
          alt="connection spinner"
          src={img_refresh_two_circular}
          className={`mrg-r12 filter-white socket-status-connecting`}
          title={NavbarLoggedIn.attemptingToReconnect}
        />
      )}
      {currentRoles.includes(process.env.REACT_APP_ATTENDEE) && (
        <Link
          to={{ pathname: '/events' }}
          onClick={() => {
            handleSettingsSetViewEventDetails();
            setNavbar('events');
          }}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img src={search} alt={NavbarLoggedIn.events} aria-hidden="true" />
            <span
              className={`${
                navbar === 'events' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          {NavbarLoggedIn.events}
        </Link>
      )}
      {currentRoles.includes(process.env.REACT_APP_ORGANIZER) && (
        <Link
          to={{ pathname: '/organize' }}
          onClick={() => {
            handleSettingsSetViewEventDetails();
            setNavbar('organize');
          }}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={organize}
              alt={NavbarLoggedIn.organize}
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'organize' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          {NavbarLoggedIn.organize}
        </Link>
      )}
      <Link
        to={{ pathname: '/account' }}
        onClick={() => setNavbar('account')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <div style={{ zIndex: '7' }}>
            <LoadBucketImage
              imagePath={profileImage}
              imgClassName={'profile-image'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
          </div>
          <span
            className={`${navbar === 'account' ? 'active fadeIn' : 'inactive'}`}
            style={{ zIndex: '2' }}
          />
        </div>
        {NavbarLoggedIn.account}
      </Link>
      <Link
        to={{ pathname: '/' }}
        onClick={() => logout()}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img aria-hidden="true" src={logOut} alt={NavbarLoggedIn.signOut} />
          <span
            className={`${navbar === 'logout' ? 'active fadeIn' : 'inactive'}`}
          />
        </div>
        {NavbarLoggedIn.signOut}
      </Link>
    </div>
  );
};

export default NavLoggedIn;
