//Hooks
import useLanguageData from '../../../../language/useLanguageData';
//Components

//Utility
import {
  GetDurationWithoutUTC,
  GetDurationWithUTC,
} from '../../../../utils/dateTime/GetDuration';
import { textUI } from '../../../../utils/UI/textUI';

const GeneralContent = ({ eventData, EventPage }) => {
  //Hooks
  const { AudienceTypesEventPage, EventTypes, LanguageOpts, HostingOptions } =
    useLanguageData();

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="descriptionLabel"
      >
        {EventPage.description}
      </label>
      <div className="fs18 access-ob access-o6" tabIndex="0">
        {textUI(eventData.generic.description)}
      </div>

      <div className="h48" />

      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="aboutLabel"
      >
        {EventPage.aboutThisEvent}
      </label>
      <div className="info-row access-ob access-o6" tabIndex="0">
        {HostingOptions[eventData.hosting.hostingOption]}
      </div>
      <div className="info-row access-ob access-o6" tabIndex="0">
        {EventTypes[eventData.generic.eventType]}
      </div>
      <div className="info-row access-ob access-o6" tabIndex="0">
        {AudienceTypesEventPage[eventData.generic.audienceType]}
      </div>
      <div className="info-row access-ob access-o6" tabIndex="0">
        {eventData.dateTime?.dateTimeStartUTC &&
        eventData.dateTime?.dateTimeEndUTC
          ? `${EventPage.duration} ${GetDurationWithUTC(eventData.dateTime)}`
          : eventData.dateTime?.timeStart && eventData.dateTime?.timeEnd
          ? `${EventPage.duration} ${GetDurationWithoutUTC(eventData.dateTime)}`
          : null}
      </div>
      <div className="info-row access-ob access-o6" tabIndex="0">
        {eventData.generic?.languages?.map((lang, index) => {
          return (
            <p key={lang}>
              {LanguageOpts[lang]}
              {eventData.generic?.languages?.length - 1 > index && ','}
              &nbsp;
            </p>
          );
        })}
      </div>

      <div className="h24" />
    </>
  );
};

export default GeneralContent;
