import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import useLanguageLayout from '../../language/useLanguageLayout';
import useSettings from '../../context/useSettings';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useSettingsHandlers } from '../../features/main/settings/useSettingsHandlers';
import { useOrganizeEventsSocket } from '../../context/sockets/OrganizeEventsSocketProvider';
import { useAccountPersonalSocket } from '../../context/sockets/AccountPersonalProvider';

//Components
import NavLoggedOut from './NavLoggedOut';
import NavLoggedIn from './NavLoggedIn';

//Utils
import {
  img_bell,
  img_hamburger,
  img_myndfull_podium,
  img_refresh_two_circular,
} from '../../assets/icons';

const Navbar = ({ layoutIsMounting }) => {
  //Hooks
  const {
    setOpenSlideMenu,
    openSlideMenu,
    width,
    setOpenMobileNotificationsMenu,
    openMobileNotificationsMenu,
    handleMobileTap,
  } = useSettings();
  const { accountId, accountNotices } = useAccountReduxHandlers();
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { NavbarLoggedOut, NavbarLoggedIn } = useLanguageLayout();
  const { handleSettingsSetViewEventDetails } = useSettingsHandlers();
  const { accountPersonalSocketStatus } = useAccountPersonalSocket();
  const { organizeEventsSocketStatus } = useOrganizeEventsSocket();
  const location = useLocation();

  //state
  const [componentIsMounting, setComponentIsMounting] = useState(true);
  const [authRoles, setAuthRoles] = useState([]);
  const [unseenNoticesSum, setUnseenNoticesSum] = useState(0);

  //ui
  const [displaySocketSpinner, setDisplaySocketSpinner] = useState(false);
  const [locationIsOrganize, setLocationIsOrganize] = useState(false);
  const [renderOrganizeSocketStatus, setRenderOrganizeSocketStatus] =
    useState(false);

  const [highlightNotificationsIconTap, setHighlightNotificationsIconTap] =
    useState(false);
  const [highlightHamburgerIconTap, setHighlightHamburgerIconTap] =
    useState(false);

  //initialize
  useEffect(() => {
    setAuthRoles(authCurrentRoles);
  }, [authCurrentRoles]);

  useEffect(() => {
    if (location.pathname === '/organize') {
      setLocationIsOrganize(true);
      handleOrganizeSocketStatusRenderDelay();
    } else {
      setRenderOrganizeSocketStatus(false);
      setLocationIsOrganize(false);
    }
  }, [location]); //check for displaying disconnect in organize view

  useEffect(() => {
    if (accountNotices) {
      let newUnseenNotices = 0;
      accountNotices?.map((n) => {
        if (n.noticeStatus === 'unseen') {
          newUnseenNotices++;
        }
      });

      setUnseenNoticesSum(newUnseenNotices);
    }
  }, [accountNotices]);

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  function handleOrganizeSocketStatusRenderDelay() {
    let timeout = null;
    timeout = setTimeout(() => {
      setRenderOrganizeSocketStatus(true);
    }, 2000);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }

  //UI
  useEffect(() => {
    if (accountPersonalSocketStatus !== 'connected') {
      console.log(
        'navbar account personal socket status:',
        accountPersonalSocketStatus
      );
      setDisplaySocketSpinner(true);
    } else if (
      renderOrganizeSocketStatus &&
      organizeEventsSocketStatus !== 'connected' &&
      locationIsOrganize
    ) {
      console.log(
        'navbar organize events socket status:',
        organizeEventsSocketStatus
      );
      setDisplaySocketSpinner(true);
    } else {
      setDisplaySocketSpinner(false);
    }
  }, [
    accountPersonalSocketStatus,
    renderOrganizeSocketStatus,
    organizeEventsSocketStatus,
    locationIsOrganize,
  ]);

  return (
    <header
      className={`${
        componentIsMounting ? 'offscreen' : 'navbar onscreen-fade-in-100ms'
      }`}
      id="navbar"
      aria-label={NavbarLoggedOut.header}
    >
      {width <= 1280 ? (
        <div className="navbar__container">
          <Link
            to={'/'}
            onClick={() => {
              if (accountId && authRoles) {
                handleSettingsSetViewEventDetails();
              }
            }}
            className="logo__link access-ob access-o12 pos-rel"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            {width > 280 ? (
              <>
                <div className="logo__color1 disable-select" aria-hidden="true">
                  Mynd
                </div>
                <div className="logo__color2 disable-select" aria-hidden="true">
                  Full
                </div>

                {authRoles?.length > 0 &&
                  (accountPersonalSocketStatus !== 'connected' ||
                    (organizeEventsSocketStatus !== 'connected' &&
                      locationIsOrganize &&
                      renderOrganizeSocketStatus)) && (
                    <img
                      alt="connection spinner"
                      src={img_refresh_two_circular}
                      className={`filter-white socket-status-connecting--mobile`}
                      title={NavbarLoggedIn.attemptingToReconnect}
                    />
                  )}
              </>
            ) : (
              <img
                src={img_myndfull_podium}
                alt={NavbarLoggedOut.logoAlt}
                style={{ height: '36px', marginBottom: '3px' }}
              />
            )}
          </Link>
          <div className="flex-row">
            {authRoles?.length > 0 && (
              <button
                className="nav-bar__notices access-ob access-o6 mrg-r36"
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setHighlightNotificationsIconTap(true),
                      () => setHighlightNotificationsIconTap(false),
                    ],
                    [
                      () =>
                        setOpenMobileNotificationsMenu(
                          !openMobileNotificationsMenu
                        ),
                    ]
                  )
                }
                aria-pressed={openMobileNotificationsMenu}
              >
                <img
                  src={img_bell}
                  alt={NavbarLoggedIn.iconBell}
                  className={`nav-bar__notices-bell  ${
                    highlightNotificationsIconTap
                      ? 'filter-white--tap'
                      : 'filter-white'
                  }`}
                />
                {unseenNoticesSum > 0 && (
                  <div
                    className={`nav-bar__notices-alert ${
                      unseenNoticesSum > 9
                        ? 'nav-bar__msg-notice-2digit'
                        : 'nav-bar__msg-notice-1digit'
                    }`}
                  >
                    {unseenNoticesSum}
                  </div>
                )}
              </button>
            )}
            <button
              className={'hamburger access-ob access-o6'}
              tabIndex="0"
              aria-label={NavbarLoggedOut.navigationMenu}
              aria-expanded={openSlideMenu}
              id="hamburger"
              onClick={() =>
                handleMobileTap(
                  [
                    () => setHighlightHamburgerIconTap(true),
                    () => setHighlightHamburgerIconTap(false),
                  ],
                  [() => setOpenSlideMenu(!openSlideMenu)]
                )
              }
            >
              <img
                src={img_hamburger}
                alt={NavbarLoggedOut.menuIcon}
                aria-hidden="true"
                className={`${
                  highlightHamburgerIconTap
                    ? 'filter-white--tap'
                    : 'filter-white'
                }`}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="navbar__container">
          <Link
            to={'/'}
            onClick={() => {
              if (accountId && authRoles) {
                handleSettingsSetViewEventDetails();
              }
            }}
            className="logo__link access-ob access-o12"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            <div className="logo__color1 disable-select" aria-hidden="true">
              Mynd
            </div>
            <div className="logo__color2 disable-select" aria-hidden="true">
              Full
            </div>
          </Link>
          {authRoles ? (
            <NavLoggedIn
              currentRoles={authRoles}
              displaySocketSpinner={displaySocketSpinner}
            />
          ) : (
            <NavLoggedOut />
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
