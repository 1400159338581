//hooks

//components
import InfoCardActions from './InfoCardActions/InfoCardActions';
import InfoCardContainerMeta from './InfoCardContainers/InfoCardContainerMeta';
import InfoCardContainerSupport from './InfoCardContainers/InfoCardContainerSupport';
import InfoCardContainerSupportTickets from './InfoCardContainers/InfoCardContainerSupportTickets';
import InfoCardAssignmentIcon from './InfoCardIcons/InfoCardAssignmentIcon';
import InfoCardStatusIcon from './InfoCardIcons/InfoCardStatusIcon';
import InfoCardContainerTickets from './InfoCardContainers/InfoCardContainerTickets';
import InfoCardContainerVerifications from './InfoCardContainers/InfoCardContainerVerifications';
import InfoCardContainerVerificationHistory from './InfoCardContainers/InfoCardContainerVerificationHistory';
import InfoCardContainerAccountPersonal from './InfoCardContainers/Accounts/InfoCardContainerAccountPersonal';
import InfoCardContainerAppSettings from './InfoCardContainers/Accounts/InfoCardContainerAccountAppSettings';
import InfoCardContainerAttendee from './InfoCardContainers/Accounts/InfoCardContainerAttendee';
import InfoCardContainerEvents from './InfoCardContainers/Accounts/InfoCardContainerAccountEvents';
import InfoCardContainerTags from './InfoCardContainers/InfoCardContainerTags';
import InfoCardContainerNetwork from './InfoCardContainers/Accounts/InfoCardContainerAccountNetwork';
import InfoCardContainerEventGeneric from './InfoCardContainers/Events/InfoCardContainerEventGeneric';
import InfoCardContainerCompany from './InfoCardContainers/Accounts/InfoCardContainerAccountCompany';
import InfoCardContainerEventImages from './InfoCardContainers/Events/InfoCardContainerEventImages';
import InfoCardContainerEventHosting from './InfoCardContainers/Events/InfoCardContainerEventHosting';
import InfoCardContainerEventParticipants from './InfoCardContainers/Events/InfoCardContainerEventParticipants';
import InfoCardContainerEventRegistrations from './InfoCardContainers/Events/InfoCardContainerEventRegistrations';
import InfoCardContainerEventInteractions from './InfoCardContainers/Events/InfoCardContainerEventInteractions';
import InfoCardContainerEventSchedule from './InfoCardContainers/Events/InfoCardContainerEventSchedule';
import InfoCardHeader from './InfoCardContainers/InfoCardHeader';
import InfoCardActionsEventVerification from './InfoCardActions/InfoCardActionsEventVerification';
import InfoCardActionsTechnicalLog from './InfoCardActions/InfoCardActionsTechnicalLog';
import InfoCardContainerError from './InfoCardContainers/TechnicalLog/InfoCardContainerError';
import InfoCardContainerRequest from './InfoCardContainers/TechnicalLog/InfoCardContainerRequest';
import InfoCardContainerTrace from './InfoCardContainers/TechnicalLog/InfoCardContainerTrace';
import InfoCardActionsTechnicalLogArchive from './InfoCardActions/InfoCardActionsTechnicalLogArchive';
import InfoCardContainerArchive from './InfoCardContainers/TechnicalLog/InfoCardContainerArchive';

//utils

const InfoCard = ({
  accountEventsData,
  accountPersonalData,
  appSettingsData,
  cardsRowIds,
  companyData,
  eventDateTimeData,
  eventGenericData,
  eventHostingData,
  eventImagesData,
  eventInteractionsData,
  eventParticipantsData,
  eventRegistrationsData,
  eventReference,
  eventScheduleData,
  handleCloseInfoCard,
  idData,
  infoCardType,
  metaData,
  communityData,
  attendeeData,
  setCardsRowIds,
  supportData,
  tagsData,
  ticketData,
  title,
  verificationEventData,
  verificationHistoryData,
  verificationsData,
  errorData,
  traceData,
  requestData,
  technicalArchiveData,
}) => {
  //hooks

  return (
    <>
      {idData && (
        <div className="support-ticket__wrapper">
          {/* Heading */}
          <InfoCardHeader
            infoCardType={infoCardType}
            idData={idData}
            handleCloseInfoCard={handleCloseInfoCard}
            title={title}
            ticketHeader={[ticketData?.ticketType, ticketData?.ticketKey]}
            accountHeader={accountPersonalData}
            eventHeader={eventGenericData}
            technicalLogHeader={`buildv${metaData?.serverBuild}/${
              traceData?.fnId
            }${traceData?.stage ? ';' : ''} stage ${traceData?.stage}`}
            technicalLogArchiveHeader={`${technicalArchiveData?.archivePath}`}
          />

          {/* Ticket Status */}
          {infoCardType === 'ticket' && (
            <div className="flex-row mrg-b48">
              {ticketData?.status !== 'resolved' && (
                <InfoCardAssignmentIcon
                  supportData={supportData}
                  ticketData={ticketData}
                />
              )}
              <InfoCardStatusIcon ticketData={ticketData} />
            </div>
          )}
          {/* ADMIN SUPPORT BUTTONS */}
          {infoCardType === 'ticket' && (
            <InfoCardActions
              idData={idData}
              supportData={supportData}
              ticketData={ticketData}
              infoCardType={infoCardType}
              cardsRowIds={cardsRowIds}
              setCardsRowIds={setCardsRowIds}
            />
          )}
          {infoCardType === 'eventVerification' && (
            <InfoCardActionsEventVerification
              idData={idData}
              supportData={supportData}
              ticketData={ticketData}
              infoCardType={infoCardType}
              verificationEventData={verificationEventData}
              cardsRowIds={cardsRowIds}
              setCardsRowIds={setCardsRowIds}
            />
          )}
          {infoCardType === 'technicalLog' && (
            <InfoCardActionsTechnicalLog idData={idData} metaData={metaData} />
          )}
          {infoCardType === 'technicalLogArchive' && (
            <InfoCardActionsTechnicalLogArchive
              idData={idData}
              archiveData={technicalArchiveData}
            />
          )}
          <InfoCardContainerMeta
            adminRef={metaData?.adminReference}
            ticketRef={metaData?.ticketReference}
            eventRef={metaData?.eventReference}
            accountRef={metaData?.accountReference}
            technicalLogRef={metaData?.technicalLogReference}
            metaData={metaData}
          />
          {infoCardType === 'ticket' && (
            <>
              <InfoCardContainerSupport supportData={supportData} />
              <InfoCardContainerSupportTickets
                ticketData={ticketData}
                metaData={metaData}
              />
            </>
          )}
          {infoCardType === 'eventVerification' && (
            <>
              <InfoCardContainerVerifications
                verificationsData={verificationsData}
              />
              <InfoCardContainerVerificationHistory
                verificationHistoryData={verificationHistoryData}
              />
            </>
          )}
          {infoCardType === 'account' && (
            <>
              <InfoCardContainerTickets ticketData={ticketData} />
              <InfoCardContainerAccountPersonal
                accountPersonalData={accountPersonalData}
                accountType={'Personal'}
              />
              <InfoCardContainerAppSettings appSettingsData={appSettingsData} />
              <InfoCardContainerAttendee attendeeData={attendeeData} />
              <InfoCardContainerEvents accountEventsData={accountEventsData} />
              <InfoCardContainerNetwork communityData={communityData} />
              <InfoCardContainerTags tagsData={tagsData} />
            </>
          )}

          {infoCardType === 'admin' && (
            <>
              <InfoCardContainerAccountPersonal
                accountPersonalData={accountPersonalData}
                accountType={'Personal'}
              />
              <InfoCardContainerAppSettings appSettingsData={appSettingsData} />
              <InfoCardContainerCompany companyData={companyData} />
              <InfoCardContainerTickets ticketData={ticketData} />
            </>
          )}

          {infoCardType === 'event' && (
            <>
              <InfoCardContainerAccountPersonal
                accountPersonalData={accountPersonalData}
                accountType={'Organizer'}
                metaData={metaData}
              />
              <InfoCardContainerTickets ticketData={ticketData} />
              <InfoCardContainerEventGeneric
                eventGenericData={eventGenericData}
              />
              {eventScheduleData?.enabled && (
                <InfoCardContainerEventSchedule
                  eventScheduleData={eventScheduleData}
                />
              )}

              <InfoCardContainerEventImages eventImagesData={eventImagesData} />
              <InfoCardContainerEventHosting
                eventHostingData={eventHostingData}
                eventDateTimeData={eventDateTimeData}
              />

              <InfoCardContainerEventParticipants
                eventParticipantsData={eventParticipantsData}
              />
              <InfoCardContainerEventRegistrations
                eventRegistrationsData={eventRegistrationsData}
              />
              <InfoCardContainerEventInteractions
                eventInteractionsData={eventInteractionsData}
              />

              <InfoCardContainerTags tagsData={tagsData} />
            </>
          )}

          {(infoCardType === 'technicalLog' ||
            infoCardType === 'technicalLogArchive') && (
            <>
              {infoCardType === 'technicalLogArchive' && (
                <InfoCardContainerArchive archiveData={technicalArchiveData} />
              )}
              <InfoCardContainerError errorData={errorData} />
              <InfoCardContainerRequest requestData={requestData} />
              <InfoCardContainerTrace traceData={traceData} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default InfoCard;
